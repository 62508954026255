import { LaLike, LaShare } from "../../svg-icons";

type Props = {};

export default function PostSkeleton({}: Props) {
  return (
    <div
      className="flex max-w-lg flex-col gap-3 rounded border border-grey-light px-2 py-4 shadow lg:rounded-2xl"
      aria-label="post-skeleton"
    >
      <div className="flex items-center gap-3">
        <div className="h-[40px] w-[40px] animate-pulse rounded-full bg-grey-light" />
        <div>
          <p className="w-[150px] animate-pulse bg-grey-light" />
          <p className="w-[70px] animate-pulse bg-grey-light" />
        </div>
      </div>

      <p className="w-[500px] animate-pulse bg-grey-light" />
      <p className="w-[500px] animate-pulse bg-grey-light" />
      <p className="w-[500px] animate-pulse bg-grey-light" />

      <div className="h-[280px] w-full animate-pulse rounded bg-grey-light" />
      <div className="flex items-center justify-start gap-3">
        <button className="flex items-center justify-center gap-2 text-lg">
          <LaLike fill={"none"} stroke={"#1a1a1a"} />
          <span className="w-[10px] animate-pulse bg-grey-light" />
        </button>
        <a href="#" className="text-lg">
          <LaShare />
        </a>
      </div>
    </div>
  );
}
