import moment from "moment";
import { capitalize } from "../../../utils/capitalize";
import { toSentenceCase } from "../../../utils/toSentenceCase";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";

type FixturesCardProps = {
  tournamentAuthor: string;
  round?: string;
  teamA?: {
    name: string;
    avatar: string;
  };
  teamB?: {
    name: string;
    avatar: string;
  };
  venue: string;
  date: string | null;
  time: string | null;
  id?: string;
};

function FixturesCard({
  round,
  teamA,
  teamB,
  venue,
  date,
  time,
  id,
  tournamentAuthor,
}: FixturesCardProps) {
  // const past = moment().diff(moment(time), "seconds") > 0;
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const isTournamentAuthor = myId === tournamentAuthor;
  return (
    <div className="flex flex-col items-center gap-4 rounded border border-grey-light p-3 shadow">
      <div className="grid w-full grid-cols-3 items-center justify-between lg:px-20">
        <div className="flex items-center gap-2 ">
          <img
            src={teamA?.avatar}
            alt="team avatar"
            className="h-8 w-8 rounded-full"
          />
          <p>{capitalize(teamA?.name)}</p>
        </div>

        <div className="flex flex-col items-center gap-4">
          <p className="gap-1 text-center text-xs font-medium">
            Match {round} <span> · </span>{" "}
            {date ? moment(date).format("ll") : "TBD"}
          </p>
          {isTournamentAuthor ? (
            <Link
              to={`/competitions/scores/edit/${id}`}
              className="text-primary-blue-darker"
            >
              Enter Scores
            </Link>
          ) : (
            <h3 className={"text-center font-medium text-primary-black-darker"}>
              {time ? moment(time).format("HH:mm a") : "TBD"}
            </h3>
          )}
          <p className="text-center text-sm font-medium">
            {toSentenceCase(venue)}
          </p>
        </div>

        <div className="flex items-center justify-end gap-2">
          <p>{capitalize(teamB?.name)}</p>
          <img
            src={teamB?.avatar}
            alt="team avatar"
            className="h-8 w-8 rounded-full"
          />
        </div>
      </div>
    </div>
  );
}

export default FixturesCard;
