import { cn } from "../../../utils/cn";

type Props = {
  teamAScore: number;
  teamBScore: number;
  teamAPenalties?: number;
  teamBPenalties?: number;
};

function ScoreDot({
  teamAScore,
  teamBScore,
  teamAPenalties,
  teamBPenalties,
}: Props) {
  return (
    <div
      className={cn("h-[8px] w-[8px] rounded-full", {
        "bg-green":
          teamAScore > teamBScore ||
          (teamAPenalties ?? 0) > (teamBPenalties ?? 0),
        "bg-grey": teamAScore === undefined || teamBScore === undefined,
        // "bg-primary-blue-darker": teamAScore === teamBScore,
        "bg-red":
          teamAScore < teamBScore ||
          (teamAPenalties ?? 0) < (teamBPenalties ?? 0),
      })}
    />
  );
}

export default ScoreDot;
