import { LaArrowLeft, LaPlusIcon } from "../svg-icons";
import { profiles } from "../../assets/data/profiles";
import BorderButton from "../ui/buttons/BorderButton";
import { SetURLSearchParams, useNavigate } from "react-router-dom";

type UserRoleProps = {
  setOnBoardingParams: SetURLSearchParams;
  role?: TRole;
  profiles?: TProfile[];
};

function UserRole(props: UserRoleProps) {
  const navigate = useNavigate();
  const userProfiles = props.profiles?.map((profile) => profile.role);

  const profilesCards = profiles.map((profile) => {
    const hasProfile = userProfiles?.includes(profile.role);
    return (
      <div
        className="flex max-w-[150px] flex-col items-center justify-between gap-2 lg:max-w-[200px]"
        key={profile.role}
      >
        <img
          src={profile.image}
          alt="default illustration"
          className="h-28 w-28 rounded-full lg:h-40 lg:w-40"
        />
        <p className="capitalize lg:text-2xl">{profile.role}</p>
        <p className="text-center text-sm lg:text-base">{profile.desc}</p>
        <BorderButton
          className="mt-2 flex items-center gap-2 rounded p-3 text-sm font-medium text-primary-blue-dark disabled:cursor-not-allowed disabled:border-grey-light disabled:text-grey-light disabled:hover:bg-white lg:text-base"
          disabled={hasProfile}
          onClick={() => {
            props.setOnBoardingParams({
              step: "2",
              role: profile.role,
            });
          }}
        >
          <span className="text-xl">
            <LaPlusIcon stroke={hasProfile ? "#bbb" : "#0045f6"} />
          </span>
          {profile.btn}
        </BorderButton>
      </div>
    );
  });

  return (
    <div className="onboarding__modal relative mx-auto mt-[40px] flex w-full flex-col items-center rounded py-7 md:mt-10 md:w-[85%] md:rounded-2xl md:py-12 lg:mt-14 lg:w-[74%] lg:py-[3.75rem]">
      <button
        className="absolute left-4 top-4 flex h-12 w-12 items-center justify-center self-start rounded-full bg-white text-2xl shadow lg:left-6 lg:top-6"
        onClick={() => {
          navigate(-1);
        }}
      >
        <LaArrowLeft />
      </button>

      <h2 className="text-2xl md:text-[2.5rem]">Add Profile </h2>
      <p className="mt-3 text-center text-base md:mt-4 md:text-2xl">
        Every account comes with 3 profiles for people who want to have
        different roles
      </p>
      <div className="mt-8 flex flex-wrap justify-center gap-14  lg:mt-14 lg:gap-24">
        {profilesCards}
      </div>
    </div>
  );
}
export default UserRole;
