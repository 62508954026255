import football from "../images/football.webp";
import tennis from "../images/tennis.webp";
import basketball from "../images/basketball.webp";
import paintball from "../images/paintball.webp";
import gaming from "../images/gaming.webp";

const carousel = [
  {
    src: football,
    text: "A sports events community for sports lovers and players.",
    alt: "football",
  },
  {
    src: tennis,
    text: "A sports events community for sports lovers and players.",
    alt: "tennis",
  },
  {
    src: basketball,
    text: "A sports events community for sports lovers and players.",
    alt: "basketball",
  },
  {
    src: paintball,
    text: "A sports events community for sports lovers and players.",
    alt: "paintball",
  },
  {
    src: gaming,
    text: "A sports events community for sports lovers and players.",
    alt: "gaming",
  },
];

export default carousel;
