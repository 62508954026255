import { useEffect, useState } from "react";
import banner from "../../assets/images/tournament-banner.webp";
import tournamentDefault from "../../assets/images/tournamentDefault.webp";
import { LaClose, LaGalleryEdit } from "../svg-icons";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { cn } from "../../utils/cn";
import { EditTournamentFormValues } from "../../pages/tournament/EditTournament";
import { useNavigate } from "react-router-dom";

interface EditTournamentBannerProps {
  register: UseFormRegister<any>;
  watchBanner?: FileList;
  // watchAvatar?: FileList;
  errors: FieldErrors<EditTournamentFormValues>;
  prevBanner: string;
  // prevAvatar: string;
}

function EditTournamentBanner({
  register,
  // watchAvatar,
  watchBanner,
  errors,
  prevBanner,
}: // prevAvatar,
EditTournamentBannerProps) {
  const [previewBanner, setPreviewBanner] = useState("");
  // const [previewAvatar, setPreviewAvatar] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (watchBanner?.length) {
      const reader = new FileReader();
      reader.readAsDataURL(watchBanner?.[0]);
      reader.onloadend = () => {
        setPreviewBanner(reader?.result as React.SetStateAction<string>);
      };
    }
    // if (watchAvatar?.length) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(watchAvatar?.[0]);
    //   reader.onloadend = () => {
    //     setPreviewAvatar(reader?.result as React.SetStateAction<string>);
    //   };
    // }
  });

  return (
    <form
      className="mb-[49.01px] h-[11.25rem] w-full md:h-[344px]"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="relative h-full w-full">
        <img
          src={previewBanner || prevBanner || banner}
          alt="tournament banner"
          className="h-full w-full object-cover"
        />
        <div
          className={`absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-[#0000004d] `}
        >
          <button
            className="absolute left-4 top-4 rounded-full bg-black p-3 lg:hidden"
            onClick={() => navigate(-1)}
            aria-label="back"
          >
            <LaClose stroke="#fff" />
          </button>

          <label className="absolute bottom-4 right-4 flex cursor-pointer flex-col items-center justify-center gap-y-1 lg:static">
            <span className="hidden text-3xl lg:block">
              <LaGalleryEdit stroke={errors.banner ? "#E71D36" : "#fff"} />
            </span>{" "}
            <input type="file" className="hidden" {...register("banner")} />
            <p
              className={cn("text-sm text-white lg:text-base", {
                "text-red": errors.banner,
              })}
            >
              Change Banner
            </p>
          </label>

          {/* <div
            className={cn(
              "absolute bottom-[calc(0%-2.5rem)] z-20 flex h-[5rem] w-[5rem] items-center  justify-center rounded-full border-2 border-white bg-[#0000004d] lg:bottom-[7%] lg:left-[10%]",
              {
                "border-red": errors.avatar,
              }
            )}
          >
            <div className="relative flex h-full w-full flex-col items-center justify-center">
              <img
                src={previewAvatar || prevAvatar || tournamentDefault}
                alt="avatar"
                className="flex h-full w-full flex-col items-center justify-center rounded-full object-cover"
              />

              <label className="absolute flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-full bg-[#00000045]">
                <span className="absolute -bottom-2 -left-2 rounded-full border border-grey-light bg-white p-2 text-2xl">
                  <LaGalleryEdit stroke={errors.avatar ? "#E71D36" : "#000"} />
                </span>{" "}
                <input type="file" className="hidden" {...register("avatar")} />
              </label>
            </div>
          </div> */}
        </div>
      </div>
    </form>
  );
}

export default EditTournamentBanner;
