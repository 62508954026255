function LaEmail() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.167 17.0833H5.83366C3.33366 17.0833 1.66699 15.8333 1.66699 12.9167V7.08332C1.66699 4.16666 3.33366 2.91666 5.83366 2.91666H14.167C16.667 2.91666 18.3337 4.16666 18.3337 7.08332V12.9167C18.3337 15.8333 16.667 17.0833 14.167 17.0833Z"
        stroke="#1A1A1A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1663 7.5L11.558 9.58333C10.6997 10.2667 9.29134 10.2667 8.433 9.58333L5.83301 7.5"
        stroke="#1A1A1A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaEmail;
