import moment, { MomentInput } from "moment";
import { useNavigate } from "react-router-dom";
import NotificationTexts from "./NotificationTexts";
import { useUpdateMeMutation } from "../../redux/services/userService";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../mocks/store";
import React from "react";
import { ConfirmPopup } from "../modals";

type Props = {
  notificationResponse: LANotification;
};

export default function ActionNotification({ notificationResponse }: Props) {
  const navigate = useNavigate();
  const [dispayModal, setDisplayModal] = React.useState(false);
  const timeStamp = moment(
    notificationResponse.updatedAt as MomentInput
  ).fromNow();
  const [updateUser] = useUpdateMeMutation();
  const { role } = useAppSelector((state: RootState) => state.auth);
  const roles: {
    [key: string]: string;
  } = {
    team_req_join: "team",
    team_inv_join: "player",
    tour_req_join: "organizer",
    tour_inv_join: "team",
  };
  const requiredRole = roles[notificationResponse?.type];

  function handleSwitchProfile() {
    updateUser({ role: requiredRole })
      .unwrap()
      .then(() => {
        window.location.replace(notificationResponse?.senderLink);
      });
  }
  return (
    <>
      <div className="flex items-start justify-between gap-3 border-b border-gray-500">
        {notificationResponse?.image?.path && (
          <img
            alt="new"
            src={notificationResponse?.image?.path}
            className="h-10 w-10 overflow-hidden rounded-full object-cover"
          />
        )}
        <div className="flex flex-1 gap-2 pb-3">
          <button
            onClick={() =>
              role !== requiredRole
                ? setDisplayModal(true)
                : navigate(notificationResponse?.senderLink)
            }
            aria-label="notification-link"
          >
            <NotificationTexts notificationResponse={notificationResponse} />
          </button>
          <span className="text-sm text-gray-500">{timeStamp}</span>
        </div>
      </div>
      {dispayModal && (
        <ConfirmPopup
          heading="Switch your profile"
          type="warning"
          description="You need to switch your profile to perform this action"
          buttons={[
            {
              text: "Proceed",
              handleClick: handleSwitchProfile,
              class: "primary",
            },
          ]}
        />
      )}
    </>
  );
}
