import React, { SetStateAction } from "react";
import { UseFormSetValue } from "react-hook-form";

type PhoneCodeDropdownType = {
  countryNamesAndFlags: {
    name: string;
    flag: string;
    code: string;
  }[];
  isDropdownOpen: boolean;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  darkMode?: boolean;
  setValue?: UseFormSetValue<any>;
  phonecodeName: string;
  setSelectedCode?: React.Dispatch<React.SetStateAction<string>>;
};

function PhoneCodeDropdown({
  countryNamesAndFlags,
  isDropdownOpen,
  setIsDropdownOpen,
  darkMode,
  setValue,
  phonecodeName,
  setSelectedCode,
}: PhoneCodeDropdownType) {
  function handleSelectCode(e: React.MouseEvent<HTMLButtonElement>) {
    const code = e.currentTarget.dataset.code;
    if (!code) return;
    setValue && setValue(phonecodeName, code);
    setSelectedCode && setSelectedCode(code);
    setIsDropdownOpen(false);
  }

  const codeList = countryNamesAndFlags.map((country) => (
    <button
      key={country.name}
      className={`flex w-full cursor-pointer items-center gap-2 px-4 py-2 ${
        darkMode ? "hover:bg-primary-black-lighter" : "hover:bg-primary-blue-lighter"
      }`}
      type="button"
      onClick={handleSelectCode}
      data-code={country.code}
    >
      <span>{country.flag}</span>
      <span>
        {country.name.length > 9 ? `${country.name.slice(0, 10)}...` : country.name}
      </span>
      <span
        className={`self-end ${
          darkMode ? "text-primary-black-darker" : "text-primary-blue-dark"
        } `}
      >
        ({country.code})
      </span>
    </button>
  ));

  return (
    <div
      className={`bg__shadow absolute top-16 z-50 max-h-[200px] w-full overflow-y-scroll rounded bg-white ${
        !isDropdownOpen && "hidden"
      }`}
    >
      {codeList}
    </div>
  );
}

export default PhoneCodeDropdown;
