import React from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { LaArrowLeft, LaClose } from "../../components/svg-icons";
import {
  MediaCardSkeleton,
  PostMediaCard,
} from "../../components/ui/cards/MediaCards";
import CommentInput from "../../components/ui/comment/CommentInput";
import CommentViewBox from "../../components/ui/comment/CommentViewBox";
import CommentReply from "../../components/ui/comment/CommentReply";
import moment from "moment";
import {
  useGetSingleSpotlightQuery,
  useGetSpotlightCommentsQuery,
} from "../../redux/services/spotLightService";
import { CiMenuKebab } from "react-icons/ci";
import { Spinner } from "../../components/ui";
import { formatDistanceToNow } from "date-fns";

export default function PlayerPostsId() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading: isLoadingSpotlight } = useGetSingleSpotlightQuery({
    id: id!,
  });

  const { data: comments } = useGetSpotlightCommentsQuery({ id: id! });

  if (isLoadingSpotlight) {
    return (
      <div
        className="flex min-h-screen items-center justify-center"
        aria-label="loading"
      >
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return <Navigate to="/404" />;
  }

  console.log(data);

  const role = data.profile;
  const userProfile = data.author.profiles.find(
    (profile) => profile.role === role
  );
  let isTournamentPost = false;
  if (role !== "player" && role !== "team") {
    isTournamentPost = true;
  }
  return (
    <div className="h-screen w-full">
      <div className="flex w-full flex-col items-center pb-24 lg:max-w-[87%]">
        <div className="flex w-full items-center gap-4 px-4 py-4 lg:mt-14">
          <h3 className="text-xl font-bold">Posts</h3>
        </div>

        <section className="relative flex w-full flex-col items-center justify-center">
          <button
            className="absolute left-5 top-10 aspect-square rounded-full bg-primary-blue-dark p-2 text-white"
            onClick={() => navigate(-1)}
          >
            <LaArrowLeft stroke="white" size={"md"} />
          </button>

          <div className="mx-auto w-full max-w-[536px] space-y-12 rounded-lg border border-grey px-6 py-[18px]">
            <header className="flex items-center justify-between gap-4">
              <div className="flex flex-1 items-start gap-2">
                <Link
                  to={
                    isTournamentPost
                      ? `/competitions/${data.tournament._id}?tab=posts`
                      : `/profiles/${role}/${userProfile?.name}?tab=post`
                  }
                >
                  <img
                    src={userProfile?.avatar ?? ""}
                    className="aspect-square h-[45.66px] w-[45.66px] rounded-full bg-black"
                  />
                </Link>

                <div className="flex flex-1 flex-col">
                  <p className="line-clamp-1 text-xl font-semibold capitalize text-[#101928]">
                    {isTournamentPost
                      ? data.tournament.name
                      : userProfile?.name}
                  </p>
                  <p className="line-clamp-1 text-sm text-[#344054]">
                    {data?.description ?? ""}
                  </p>
                </div>
                <p className="self-end text-sm text-[#344054]">
                  {formatDistanceToNow(new Date(data.createdAt), {
                    addSuffix: true,
                  })}
                </p>
              </div>
              <button>
                <CiMenuKebab size={18} />
              </button>
            </header>

            {isLoadingSpotlight && <MediaCardSkeleton />}
            {!isLoadingSpotlight && data && (
              <PostMediaCard data={data} type="image" />
            )}

            <section className="space-y-3 rounded-[25.96px] bg-[#F9F9F9] p-3">
              <div className="mb-3 flex items-center justify-between">
                <p className="text-sm text-[#1A1A1A]">
                  {data?.comments} comments
                </p>
                <button>
                  <LaClose className="text-sm" />
                </button>
              </div>

              <section className="space-y-3 overflow-y-auto">
                {comments?.map((comment: any, idx: any) => (
                  <CommentViewBox key={comment?._id} comment={comment} />
                ))}
              </section>

              {/* <CommentReply /> */}

              <CommentInput spotlightId={id} />
            </section>
          </div>
        </section>
      </div>
    </div>
  );
}
