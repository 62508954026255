import { LaClose } from "../../svg-icons";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";

type Props = {
  avatar: string;
  playerName: string;
  playerWing: string;
  designation?: string;
  appearance: number;
  assist: number;
  goals: number;
  deleteMode?: boolean;
  // role?: "org" | "ply"; // TODO: use value to determine designation color for admin organiser role
};

export default function PlayerBadge(props: Props) {
  const userRole = useAppSelector((state: RootState) => state.auth.role);

  return (
    <section
      className={`relative h-[211px] max-w-[143px] overflow-hidden transition-all duration-500 ${
        props.deleteMode && props.deleteMode === true ? "sepia" : "sepia-0"
      }`}
      id="playerbadge"
    >
      {userRole === "team" && props.designation && (
        <span
          className={`absolute -left-0 top-[25%] z-30 block w-full -translate-x-9 -translate-y-9 -rotate-45 bg-gray-100 p-[2px] text-center text-sm font-medium capitalize ${
            props.designation === "admin"
              ? " bg-primary-blue-dark text-white"
              : props.designation === "invited"
              ? "bg-yellow text-black"
              : props.designation === "attention"
              ? "bg-orange-600 text-black"
              : props.designation === "teammate"
              ? "bg-green text-white"
              : null
          }`}
        >
          {props.designation}
        </span>
      )}
      <img
        src={props.avatar}
        className="absolute left-0 top-0 -z-10 h-[40%] w-full object-cover"
      />
      <section className="flex h-full flex-col items-center justify-end bg-gradient-to-t from-primary-blue-dark from-60% via-[#0046f6d1] to-[#00000033] to-80% px-1 py-[34px] text-white">
        <div className="relative flex w-full items-center justify-center">
          <img
            src={props.avatar}
            crossOrigin="anonymous"
            className="h-[39.76px] w-[39.76px] overflow-hidden rounded-full border border-white bg-gray-500 object-contain"
          />
          <span className="absolute right-[10%] top-1/2 -translate-y-1/2 text-center font-medium uppercase text-white">
            {props.playerWing}
          </span>
        </div>
        <h3 className="text-center font-medium capitalize">
          {props.playerName}
        </h3>
        <div className="mb-1 h-[1px] w-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent" />
        <section className="flex w-full items-start justify-center gap-[2px] text-xs">
          <section className="flex flex-1 flex-col items-center gap-1 text-center">
            <div className="flex flex-col">
              <p>App</p>
              <p>{props.appearance}</p>
            </div>
            <div className="flex flex-col">
              <p>Assist</p>
              <p>{props.assist}</p>
            </div>
          </section>
          <div className="h-full w-[1px] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent" />
          <section className="flex flex-1 flex-col items-center gap-1 text-center">
            <div className="flex flex-col">
              <p>Goals</p>
              <p>{props.goals}</p>
            </div>
          </section>
        </section>
      </section>
      {/* Strips */}
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-0 right-0"
      />
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-3 right-0"
      />
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-6 right-0"
      />
      {props.deleteMode && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-10 grid place-content-center place-items-center">
          <button
            type="button"
            className="grid aspect-square h-8 w-8 items-center justify-center rounded-full bg-white"
          >
            <LaClose />
          </button>
        </div>
      )}
    </section>
  );
}
