export default function SearchSkeleton() {
  return (
    <div className="flex items-center gap-4">
      <div className="aspect-square h-10 w-10 animate-pulse rounded-full bg-gray-400" />
      <div className="flex flex-col gap-1 md:flex-row">
        <div className="h-4 w-[200px] animate-pulse rounded bg-gray-400" />
        <div className="flex items-center gap-4 md:ml-4">
          <div className="flex items-center gap-2 md:flex-col">
            <div className="h-4 w-[60px] animate-pulse rounded bg-gray-400" />
            <div className="h-2 w-[60px] animate-pulse rounded bg-gray-400" />
          </div>
          <div className="flex items-center gap-2 md:flex-col">
            <div className="h-4 w-[80px] animate-pulse rounded bg-gray-400" />
            <div className="h-2 w-full max-w-[80px] animate-pulse rounded bg-gray-400" />
          </div>
        </div>
      </div>
    </div>
  );
}
