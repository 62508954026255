import React, { useState } from "react";
import { LaCaretDown, LaCaretUp } from "../../svg-icons";
import { positions } from "../../../assets/data/positions";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { cn } from "../../../utils/cn";

interface PositionSelectProps {
  id: string;
  registerOpt?: "position" | "secondaryPosition";
  placeholder: string;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  setValue: UseFormSetValue<any>;
  value?: string;
}

function PositionSelect({
  id,
  placeholder,
  register,
  errors,
  value,
  registerOpt = "position",
  setValue,
}: PositionSelectProps) {
  const [flip, setFlip] = useState(false);
  const [short, setShort] = React.useState(value?.split(" ")[0]);

  function handleClick() {
    setFlip(!flip);
  }

  function handleSelect(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    const target = e.currentTarget as HTMLElement;

    const shortPosition = target?.textContent?.split(" ")[0];
    const rest = target?.textContent?.split(" ").slice(1).join(" ");
    const toDIsplay = `${rest}`;
    setShort(shortPosition);

    // setValue("position", toDIsplay);
    setValue(registerOpt, rest);
  }

  return (
    <fieldset className="relative cursor-pointer" onClick={handleClick}>
      <div className="relative">
        <input
          type="text"
          {...register(registerOpt)}
          // {...register("position")}
          id={id}
          placeholder={placeholder}
          readOnly
          defaultValue={value?.split(" ").slice(1).join(" ")}
          required
          className={cn(
            "mt-2 w-full resize-none rounded border border-grey-light py-3 pl-14 pr-4 text-base focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30 lg:text-xl",
            {
              "error border-red focus:border-red": errors.position,
              "pl-16": short?.length && short.length! > 2,
            }
          )}
        />
        <span className="absolute left-4 top-1/3 aspect-video  rounded bg-primary-blue-lighter px-[4px] py-[2px] font-bold uppercase text-primary-blue-dark">
          {/* {position.short} */}
          {short}
        </span>
      </div>
      {errors.position && (
        <span className="mt-2 text-sm italic text-red">
          {errors.position?.message as string}
        </span>
      )}

      {flip ? (
        <span className="absolute right-3 top-[35%]">
          <LaCaretUp stroke="#1A1A1A" />
        </span>
      ) : (
        <span className="absolute right-3 top-[35%]">
          <LaCaretDown stroke="#1A1A1A" />
        </span>
      )}
      <ul
        className={`${
          !flip && "hidden"
        } modern__scroll-bar absolute z-30 mt-2 max-h-[200px] w-full overflow-y-scroll rounded border border-grey-light bg-white py-2`}
      >
        {positions?.map((position, i: number) => (
          <li
            key={`${i}` + position}
            onClick={handleSelect}
            className="flex items-center gap-3 p-2"
          >
            <span className="rounded bg-primary-blue-lighter px-[4px] py-[2px] uppercase text-primary-blue-dark">
              {position.short}
            </span>{" "}
            <span>{position.name}</span>
          </li>
        ))}
      </ul>
    </fieldset>
  );
}

export default PositionSelect;
