import React from "react";
import Footer from "../../components/Footer";
import { NavBar } from "../../components/navigations";
import faq from "../../assets/data/faq";
import { LaCaretDown, LaCaretRight, LaCaretUp } from "../../components/svg-icons";
import { useAppSelector } from "../../redux/app/hooks";
import TextLink from "../../components/ui/text/TextLink";
import { RootState } from "../../redux/app/store";
import HelmetComponent from "../../components/HelmetComponent";

interface ClickEvent extends React.MouseEvent<HTMLDivElement, MouseEvent> {
  dataset: {
    index: number;
  };
}

export default function FAQ() {
  const [faqIndex, setFaqIndex] = React.useState(0);
  const role = useAppSelector((state: RootState) => state.auth.role)?.toLowerCase();

  function handleClick(e: ClickEvent) {
    const { index } = e.currentTarget.dataset;
    setFaqIndex(parseInt(index ?? "0"));
  }

  const faqList = faq.map((faq, index) => (
    <div
      className={`flex cursor-pointer items-center justify-between px-4 py-6 shadow-[0__0__1px__1px__rgba(0,0,0,0.15)] ${
        faqIndex === index ? "bg-[#F9F9F9]" : "bg-[#fff]"
      } hover:bg-[#f9f9f9]`}
      key={index}
      aria-label="faq"
      onClick={handleClick}
      data-index={index}
    >
      <div className="flex items-center gap-4">
        <div
          className={`h-[24px] w-[24px] rounded-full bg-[#ccdafd] ${
            faqIndex === index && "bg-primary-blue-dark"
          }`}
        />
        <li className="text-xl md:max-w-[275px] lg:max-w-max"> {faq.question}</li>
      </div>

      <LaCaretRight stroke={faqIndex === index ? "#0045F6" : "#AAC1FC"} />
    </div>
  ));

  const faqListMobile = faq.map((faq, index) => (
    <div key={index}>
      <div
        className={`flex cursor-pointer items-center justify-between bg-[#fff] py-3 pl-2 pr-3 shadow-[0__0__1px__1px__rgba(0,0,0,0.15)] ${
          faqIndex === index ? "bg-[#F9F9F9]" : "bg-[#fff]"
        } hover:bg-[#f9f9f9]`}
        onClick={handleClick}
        aria-label="faq"
        data-index={index}
      >
        <div className="flex items-center gap-2">
          <div
            className={`h-[24px] w-[24px] rounded-full ${
              faqIndex === index ? "bg-[#0045F6]" : "bg-[#AAC1FC]"
            }`}
          />
          <p className="text-base">{faq.question}</p>
        </div>
        {faqIndex === index ? (
          <LaCaretUp stroke="#0045F6" />
        ) : (
          <LaCaretDown stroke="#AAC1FC" />
        )}
      </div>
      <div
        className={`bg-[#CCDAFD66]  px-2 ${
          faqIndex !== index ? "h-0 py-0" : "py-3"
        } overflow-hidden`}
      >
        <p className="text-base">{faq.answer}</p>
      </div>
    </div>
  ));

  // open graph data
  const ogData = {
    title: "Leagues Arena - FAQ",
    description: "Frequently Asked Questions",
    url: `https://${window.location.host}/faqs`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <NavBar />
      {/* <NavHomeDes /> */}
      <main className="mx-auto mb-11 mt-10 w-full max-w-[1280px] md:mb-[100px] md:mt-16">
        <h3 className="px-2 text-center text-[28px] font-bold md:text-[36px] lg:text-[40px]">
          Frequently Asked Questions
        </h3>
        <div className="mt-4 hidden px-3 md:mb-12 md:mt-12 md:flex lg:mb-16 lg:mt-14">
          <div className="left relative flex items-center md:w-[20%] lg:w-1/2">
            <ul className="absolute w-[375px] lg:-right-12 lg:w-[100%]">{faqList}</ul>
          </div>
          <div className="right flex h-[560px] justify-end rounded-2xl bg-[#CCDAFD66] p-8 md:w-[80%] lg:h-[480px] lg:w-1/2">
            <p className="w-[320px] text-base lg:w-[85%]">{faq[faqIndex].answer}</p>
          </div>
        </div>

        <div className="mb-6 mt-8 px-4 md:hidden">
          <div className="dropdown">
            <div>{faqListMobile}</div>
          </div>
        </div>
        <p className="text-center text-base md:text-left md:text-[20px]">
          Couldn&apos;t find what you are looking for ?{" "}
          <TextLink to="/contact" role={role} className="">
            contact us
          </TextLink>
        </p>
      </main>
      <Footer />
    </>
  );
}
