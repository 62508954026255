import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { TournamentsInfoTab, UserInfo } from "../../components/user-profiles";
import { useNavigate, useParams } from "react-router-dom";
import { PreLoader } from "../../components/ui/spinner";
import { useGetOrganizerQuery } from "../../redux/services/organizerService";
import { LaArrowLeft } from "../../components/svg-icons";
import HelmetComponent from "../../components/HelmetComponent";

function OrganizerProfile() {
  const navigate = useNavigate();
  const { name } = useParams();
  const viewerId = useAppSelector((state: RootState) => state.auth.myId);
  const {
    data: organizer,
    isLoading,
    // isSuccess,
  } = useGetOrganizerQuery({
    name: name?.split("-").join(" "),
  });

  const isPrivate = viewerId === organizer?.user;
  // open graph data
  const ogData = {
    title: "Leagues Arena - Organizer Profile",
    description: `View the organizer profile of ${name} on Leagues Arena`,
    url: `https://${window.location.host}/profiles/organizer/${name}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {!isLoading ? (
        <>
          <div className="flex w-full flex-col items-center pb-24">
            <div className="w-full lg:max-w-[87%]">
              <div className="flex items-center gap-4 px-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
                <button
                  className="flex items-center gap-2 text-xl"
                  onClick={() => navigate(-1)}
                >
                  <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
                </button>
                <h3 className="text-xl font-bold">Organization Profile</h3>
              </div>

              <UserInfo
                organizer={organizer}
                isLoading={isLoading}
                isPrivate={isPrivate}
              />

              <TournamentsInfoTab userId={organizer?.user as string} />
            </div>
          </div>
        </>
      ) : (
        <PreLoader />
      )}
    </>
  );
}

export default OrganizerProfile;
