const plans = {
  organizer: [
    {
      name: "pro plan",
      isPopular: true,
      monthlyPrice: "₦35,000",
      yearlyPrice: "₦20,833",
      duration: "per month",
      description: "discounted from",
      discountedPrice: "₦50,000",
      discount: "40%",
      buttonText: "get plan",
      features: [
        "20 posts",
        "Organize up to 5 competitions",
        "Register up to 16 teams",
        "Assign match stats",
        "Collect online registration ",
        "2% online payment transaction fee",
        "Priority email support",
      ],
    },
    {
      name: "enterprise plan",
      isPopular: false,
      price: "Let's talk!",
      description: "Advanced features + unlimited users.",
      buttonText: "Talk to sales",
      features: [
        "Unlimited number of posts",
        "Organize unlimited competitions",
        "Register unlimited teams",
        "Assign match stats",
        "Collect online registration",
        "1% online payment transaction fee",
        "Premium email support",
      ],
    },
  ],
  teamOwners: [
    {
      name: "free plan",
      isPopular: false,
      price: "Free",
      description: "It’s free because we care",
      buttonText: "get plan",
      features: [
        "Up to 7 players",
        "1 competition per month",
        "Team stats",
        "Team competition portfolio",
      ],
    },
    {
      name: "pro plan",
      isPopular: true,
      monthlyPrice: "₦7,000",
      yearlyPrice: "₦4,167",
      duration: "per month",
      discount: "40%",
      description: "discounted from",
      discountedPrice: "₦9,000",
      buttonText: "get plan",
      features: [
        "Up to 22 players",
        "3 competition per month",
        "Team stats",
        "Team competition portfolio",
      ],
    },
    {
      name: "enterprise plan",
      isPopular: false,
      price: "Let's talk!",
      description: "Advanced features + unlimited users.",
      buttonText: "Talk to sales",
      features: ["Organize unlimited competitions", "Unlimited team members"],
    },
  ],
  players: [
    {
      name: "free plan",
      isPopular: false,
      description: "It’s free because we care",
      price: "Free",
      buttonText: "get plan",
      features: ["Join up to 1 team", "View player Competition portfolio"],
    },
    {
      name: "pro plan",
      isPopular: false,
      monthlyPrice: "₦800",
      yearlyPrice: "₦600",
      discountedPrice: "₦1,000",
      duration: "per month",
      description: "discounted from",
      discount: "25%",
      buttonText: "get started",
      features: ["Join up to 5 teams", "View player Competition portfolio"],
    },
    {
      name: "grow big plan",
      isPopular: true,
      monthlyPrice: "₦1,500",
      yearlyPrice: "₦1,250",
      duration: "per month",
      discount: "17%",
      description: "Advanced features + unlimited users.",
      buttonText: "get started",
      features: ["Join unlimited teams", "View player Competition portfolio"],
    },
  ],
};

export default plans;
