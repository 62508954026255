import { ScoreDot } from "../ui";

type Props = {
  matches: [KnockoutMatch, KnockoutMatch];
};

function FourthRound({ matches }: Props) {
  const [matchA, matchB] = matches ?? [];
  const [teamA, teamB] = matchA?.teams ?? [];
  const [teamC, teamD] = matchB?.teams ?? [];
  const teamAData = matchA?.teamData.find(
    (teamData: { team: string }) => teamData.team === teamA?._id
  );
  const teamBData = matchA?.teamData.find(
    (teamData: { team: string }) => teamData.team === teamB?._id
  );
  const teamCData = matchB?.teamData.find(
    (teamData: { team: string }) => teamData.team === teamC?._id
  );
  const teamDData = matchB?.teamData.find(
    (teamData: { team: string }) => teamData.team === teamD?._id
  );

  return (
    <div className="flex items-center">
      <div className="flex flex-col items-start gap-[505px]">
        <div className="flex items-center">
          <div className="h-1 w-10 border-l-0 border-t border-dashed border-primary-black-darker" />
          <div className="w-full min-w-[100px] rounded border border-grey-light bg-white px-2 py-1 shadow">
            {teamA ? (
              <div className="flex items-center justify-between border-b border-grey-light">
                <div className="flex items-center gap-2">
                  <img
                    src={teamA?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamA?.shortName}</p>
                </div>
                <ScoreDot
                  teamAScore={teamAData?.score}
                  teamBScore={teamBData?.score}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 border-b border-grey-light">
                <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
                <p className="uppercase">QF{matchA?.refMatch1[0]?.name}</p>
              </div>
            )}
            {teamB ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img
                    src={teamB?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamB?.shortName}</p>
                </div>
                <ScoreDot
                  teamAScore={teamBData?.score}
                  teamBScore={teamAData?.score}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
                <p className="uppercase">QF{matchA?.refMatch2[0]?.name}</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="h-1 w-10 border-l-0 border-t border-dashed border-primary-black-darker" />
          <div className="w-full min-w-[100px] rounded border border-grey-light bg-white px-2 py-1 shadow">
            {teamC ? (
              <div className="flex items-center justify-between border-b border-grey-light">
                <div className="flex items-center gap-2">
                  <img
                    src={teamC?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamC?.shortName}</p>
                </div>
                <ScoreDot
                  teamAScore={teamCData?.score}
                  teamBScore={teamDData?.score}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 border-b border-grey-light">
                <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
                <p className="uppercase">QF{matchB?.refMatch1[0]?.name}</p>
              </div>
            )}
            {teamD ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img
                    src={teamD?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamD?.shortName}</p>
                </div>
                <ScoreDot
                  teamAScore={teamDData?.score}
                  teamBScore={teamCData?.score}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
                <p className="uppercase">QF{matchB?.refMatch2[0]?.name}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[576px] w-10 border border-l-0 border-dashed border-primary-black-darker" />
    </div>
  );
}

export default FourthRound;
