import moment, { MomentInput } from "moment";
import { LaCalenderEdit, LaLocation, LaUpdates } from "../svg-icons";
import NotificationTexts from "./NotificationTexts";

type Props = {
  notificationResponse: LANotification;
};
export default function PopNotification({ notificationResponse }: Props) {
  const timeStamp = moment(
    notificationResponse.updatedAt as MomentInput
  ).format("LT");
  const month = moment(notificationResponse.updatedAt as MomentInput).format(
    "MMM DD"
  );

  return (
    <div className="flex items-start justify-between gap-3 border-b border-gray-500">
      {notificationResponse?.image?.path && (
        <img
          alt="new"
          src={notificationResponse?.image?.path}
          className="h-10 w-10 overflow-hidden rounded-full object-cover"
        />
      )}
      <div className="flex flex-1 flex-col gap-2 pb-3">
        <NotificationTexts notificationResponse={notificationResponse} />
        <div className="flex items-center justify-start gap-3">
          <span className="inline-flex items-center gap-2 stroke-gray-800 text-sm capitalize text-gray-600">
            <LaUpdates />
            {timeStamp}
          </span>
          <span className="inline-flex items-center gap-2 stroke-gray-800 text-sm capitalize text-gray-600">
            <LaCalenderEdit />
            {month}
          </span>
          {notificationResponse.location && (
            <span className="inline-flex items-center gap-2 stroke-gray-800 text-sm capitalize text-gray-600">
              <LaLocation /> {notificationResponse?.location}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
