function LaNavigation({ fill = "#1A1A1A" }: { stroke?: string, fill?: string }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#prefix__clip0_4702_6163)">
        <path
          d="M9 4.372l2.377 2.378 1.058-1.058L9 2.25 5.558 5.692 6.622 6.75 9 4.372zm0 9.255L6.622 11.25l-1.057 1.057L9 15.75l3.443-3.443-1.066-1.057L9 13.627z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_4702_6163">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LaNavigation;
