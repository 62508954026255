import React from "react";
import { useGetTeamTournamentsQuery } from "../../redux/services/tournamentService";
import { TeamMatches } from ".";
import TeamPlayersTab from "./TeamPlayersTab";
import TeamCompetitions from "./TeamCompetitions";
import { RootState } from "../../mocks/store";
import { useAppSelector } from "../../redux/app/hooks";
import Button from "../ui/buttons/Button";
import { cn } from "../../utils/cn";
import SpotlightGrid from "../user-profiles/SpotlightGrid";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

type TeamsTabs = "stats" | "players" | "matches" | "competitions" | "posts";

export default function TeamActionTabs({ team }: { team?: Team }) {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase();
  const myId = useAppSelector((state: RootState) => state.auth?.myId);
  const [tabParams] = useSearchParams();
  const activeTab = (tabParams.get("tab") || "stats") as TeamsTabs;
  const [currentTab, setCurrentTab] = React.useState<number>(2);
  const navigate = useNavigate();
  const { data: competitions, isLoading: loadingCompetitions } =
    useGetTeamTournamentsQuery(
      { teamId: team?._id },
      { skip: activeTab !== "competitions" }
    );
  const isTeamOwner = team?.author === myId && role === "team";
  const location = useLocation();

  return (
    <>
      {/* Tabs */}
      <section className="mt-4 grid w-full grid-cols-6 items-center  border-b border-gray-400 lg:gap-6 lg:pr-[40%]">
        <Button
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 px-2 py-4 hover:bg-white", {
            "border-b-2": activeTab === "stats",
            "text-primary-black-darker": activeTab !== "stats",
          })}
          onClick={() => navigate(`${location.pathname}?tab=stats`)}
        >
          Stats
        </Button>
        <Button
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "players",
            "text-primary-black-darker": activeTab !== "players",
          })}
          onClick={() => navigate(`${location.pathname}?tab=players`)}
        >
          Players
        </Button>
        <Button
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "matches",
            "text-primary-black-darker": activeTab !== "matches",
          })}
          onClick={() => navigate(`${location.pathname}?tab=matches`)}
        >
          Matches
        </Button>
        <Button
          intent="secondary"
          role={role}
          className={cn(
            "col-span-2 rounded-none border-0 py-4 hover:bg-white",
            {
              "border-b-2": activeTab === "competitions",
              "text-primary-black-darker": activeTab !== "competitions",
            }
          )}
          onClick={() => navigate(`${location.pathname}?tab=competitions`)}
        >
          Competitions
        </Button>
        <Button
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "posts",
            "text-primary-black-darker": activeTab !== "posts",
          })}
          onClick={() => navigate(`${location.pathname}?tab=posts`)}
        >
          Posts
        </Button>
      </section>
      {/* Tab Contents */}
      <section className="min-h-[30vh] pb-[90px]">
        {activeTab === "stats" && (
          <section className="p-4 lg:px-0">
            <section className="mb-6">
              <h3 className="text-base font-medium">Competitions Played</h3>

              <section className="mt-2 flex items-center gap-6 text-sm">
                <div className="flex flex-col gap-2">
                  <p>{team?.tournamentStats?.played}</p>
                  <p className="text-grey">Played</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p>{team?.tournamentStats?.wins}</p>
                  <p className="text-grey">Won</p>
                </div>
              </section>
            </section>

            <section className="flex w-full flex-col gap-3">
              <h3 className="text-base font-medium">Matches</h3>

              <section className="flex items-center gap-6 text-sm">
                <div className="flex flex-col gap-2">
                  <p className="text-grey">Played</p>
                  <p>{team?.played}</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-grey">Won</p>
                  <p>{team?.wins}</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-grey">Drawn</p>
                  <p>{team?.draws}</p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-grey">Lost</p>
                  <p>{team?.losses}</p>
                </div>
              </section>
            </section>
          </section>
        )}
        {activeTab === "players" && <TeamPlayersTab team={team} />}
        {activeTab === "matches" && <TeamMatches teamId={team?._id} />}
        {activeTab === "competitions" && (
          <TeamCompetitions
            competitions={competitions}
            loadingCompetitions={loadingCompetitions}
            isTeamOwner={isTeamOwner}
          />
        )}
        {activeTab === "posts" && team?.author && (
          <SpotlightGrid
            isPrivate={isTeamOwner}
            userId={team?.author}
            profile="team"
          />
        )}
      </section>
    </>
  );
}
