function LaProfileDefault() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 82 93" fill="none">
      <circle cx={40.92} cy={19.383} r={19.383} fill="#000" fillOpacity={0.3} />
      <ellipse
        cx={40.92}
        cy={68.456}
        rx={40.92}
        ry={23.69}
        fill="#000"
        fillOpacity={0.3}
      />
    </svg>
  );
}

export default LaProfileDefault;
