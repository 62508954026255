export default [
  "Women’s football",
  "Under 15",
  "Under 16",
  "Under 17",
  "Under 18",
  "Under 19",
  "Under 20",
  "Under 21",
  "Under 22",
  "Under 23",
  "Senior",
];
