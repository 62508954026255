import { css, styled } from "styled-components";

// className={`rounded  px-3 py-2 md:px-4 ${
//   isOrganizer ? "bg-primary-black-lighter" : "bg-primary-blue-lighter"
// }`}

interface PillsProps {
  $userRole?: string;
}

const Pills = styled.div<PillsProps>`
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  color: #1a1a1a;
  background: #ccdafd;
  @media (min-width: 768px) {
    padding: 0.5rem 1rem;
  }

  ${(props) =>
    props.$userRole === "organizer" &&
    css`
      background: #e6e6e6;
      color: #1a1a1a;
    `};
`;

export default Pills;
