import React from "react";

export type TList = (TPlayer | Team | Tournament)[];

type TArguments = {
  isFetching: boolean;
  isLoading: boolean;
  data: TList;
  list: TList;
  setList: React.Dispatch<React.SetStateAction<TList>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  inView: boolean;
};

export const usePaginateFetch = ({
  isFetching,
  isLoading,
  data,
  list,
  setList,
  setPage,
  page,
  inView,
}: TArguments) => {
  const [incPage, setIncPage] = React.useState<boolean>(false);

  React.useEffect(() => {
    let ignore = list[0]?._id === data[0]?._id;
    if (isFetching || isLoading) return;

    if (!ignore && data.length > 0) {
      setList([...list, ...data]);
      if (!(data!.length > 1)) {
        setIncPage(false);
        return;
      }
      setIncPage(true);
    }

    return () => {
      ignore = true;
    };
  }, [isLoading, isFetching, data]);

  React.useEffect(() => {
    if (isLoading || isFetching) return;
    if (inView && incPage) {
      setPage(page + 1);
      setIncPage(false);
    }
  }, [inView, isLoading, isFetching, incPage, setIncPage]);
};
