import React from "react";
import { cn } from "../../../utils/cn";

type Ref = HTMLParagraphElement;

interface ITextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  className?: string;
  role: string;
}

const Text = React.forwardRef<Ref, ITextProps>(
  ({ className, role, children }, ref) => {
    return (
      <p
        ref={ref}
        className={cn(
          {
            "text-primary-blue-darker": role === "player",
            "text-primary-black-darker": role === "organizer",
            "text-secondary-blue-600": role === "team",
          },
          className
        )}
      >
        {children}
      </p>
    );
  }
);

export default Text;
