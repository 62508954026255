function LaTrophyLarge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.15 16.5V18.6"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.14999 22H17.15V21C17.15 19.9 16.25 19 15.15 19H9.14999C8.04999 19 7.14999 19.9 7.14999 21V22V22Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M6.14999 22H18.15"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16C8.13 16 5 12.87 5 9V6C5 3.79 6.79 2 9 2H15C17.21 2 19 3.79 19 6V9C19 12.87 15.87 16 12 16Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.47001 11.65C4.72001 11.41 4.06001 10.97 3.54001 10.45C2.64001 9.45001 2.04001 8.25001 2.04001 6.85001C2.04001 5.45001 3.14001 4.35001 4.54001 4.35001H5.19001C4.99001 4.81001 4.89001 5.32001 4.89001 5.85001V8.85001C4.89001 9.85001 5.10001 10.79 5.47001 11.65Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.53 11.65C19.28 11.41 19.94 10.97 20.46 10.45C21.36 9.45001 21.96 8.25001 21.96 6.85001C21.96 5.45001 20.86 4.35001 19.46 4.35001H18.81C19.01 4.81001 19.11 5.32001 19.11 5.85001V8.85001C19.11 9.85001 18.9 10.79 18.53 11.65Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaTrophyLarge;
