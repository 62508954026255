import { Link } from "react-router-dom";
import PlayerBadge from "./PlayerBadge";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { Button } from "..";
import { LaClose, LaPlusIcon } from "../../svg-icons";
import { cn } from "../../../utils/cn";
import PlayerProfileBadge from "../../user-profiles/PlayerProfileBadge";
import { calculateAgeFromDob } from "../../../utils/dob";

type Props = {
  players?: TPlayer[];
  myId: string;
  authorTeam?: Team;
  setSelectedPlayers?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedPlayers?: string[];
  setSelectedCompetitionForTeam?: React.Dispatch<React.SetStateAction<string>>;
  selectedCompetitionForTeam?: string;
  competitionPlayersForTeam?: string[];
};

export default function PlayerCardDeck({
  players,
  myId,
  authorTeam,
  setSelectedPlayers,
  selectedPlayers,
  setSelectedCompetitionForTeam,
  selectedCompetitionForTeam,
  competitionPlayersForTeam,
}: Props) {
  const role = useAppSelector((state: RootState) => state.auth.role);
  // const members = authorTeam?.members as TPlayer[];
  const requests = authorTeam?.requests as TPlayer[];
  const ID_LENGTH = 24;

  return (
    <>
      {players?.map((player) => {
        const isInTournament = competitionPlayersForTeam?.includes(player._id);

        const designation =
          player._id === myId
            ? "admin"
            : isInTournament
            ? "teammate"
            : requests?.find((request) => request._id === player._id)
            ? "attention"
            : undefined;
        const isSelected =
          selectedPlayers && selectedPlayers?.includes(player._id);

        return (
          <div
            key={`${player.name}`}
            className={cn(
              "relative flex flex-col items-center justify-center gap-2"
            )}
          >
            <Link
              to={`/profiles/player/${player.name}`}
              className={cn("block min-w-[168.25px]", {
                "opacity-50": isSelected,
              })}
            >
              {/* <PlayerBadge
                avatar={player?.avatar}
                playerName={player?.name}
                appearance={player.apps}
                assist={player.assists}
                goals={player.goals}
                playerWing={player.position}
                designation={designation}
              /> */}
              <PlayerProfileBadge
                avatar={player.avatar}
                playerName={player?.name}
                appearance={player?.apps}
                assist={player?.assists}
                goals={player?.goals}
                playerWing={player?.position?.split(" ")[0]}
                favFoot={player?.favFoot}
                age={calculateAgeFromDob(player?.dob)}
                height={player?.height}
                jerseyNumb={player?.jerseyNumb}
                weight={player?.weight}
                designation={designation}
              />
            </Link>
            {isSelected && (
              <Button
                role={role}
                intent={"tertiary"}
                onClick={() => {
                  setSelectedPlayers &&
                    setSelectedPlayers((prev) =>
                      prev.filter((id) => id !== player?._id)
                    );
                }}
                className="absolute right-[39%] top-[30%] h-8 w-8 rounded-full p-0"
              >
                <LaClose />
              </Button>
            )}
            {role === "team" && (
              <Button
                role={role}
                intent={"tertiary"}
                disabled={!!isInTournament}
                onClick={() => {
                  setSelectedPlayers &&
                    (isSelected
                      ? setSelectedPlayers((prev) =>
                          prev.filter((id) => id !== player._id)
                        )
                      : setSelectedPlayers((prev) => [...prev, player._id]));
                  setSelectedCompetitionForTeam &&
                    selectedCompetitionForTeam &&
                    selectedCompetitionForTeam.length < ID_LENGTH &&
                    setSelectedCompetitionForTeam("");
                }}
              >
                <LaPlusIcon stroke="#00ACD4" />
                {isSelected
                  ? "Unselect"
                  : isInTournament
                  ? "Teammate"
                  : "Select"}
              </Button>
            )}
          </div>
        );
      })}
    </>
  );
}
