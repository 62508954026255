import React from "react";
import { IoMdMenu } from "react-icons/io";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { PiCaretDownLight, PiCaretRightLight } from "react-icons/pi";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../ui/DropDownMenu";

function MobileMenu() {
  const [toggleNav, setToggleNav] = React.useState(false);
  const [dropDown, setDropDown] = React.useState(false);
  const [dropDown2, setDropDown2] = React.useState(false);
  const navigate = useNavigate();
  const elRef = React.useRef(null);
  useOnClickOutside(elRef, () => {
    setToggleNav(false);
  });
  const elRef2 = React.useRef(null);
  useOnClickOutside(elRef, () => {
    setToggleNav(false);
  });

  return (
    <ul className="relative flex flex-col lg:hidden" ref={elRef}>
      <button
        onClick={() => {
          setToggleNav(!toggleNav);
        }}
        // className="ml-[50%]"
        // toggleNav={toggleNav}
      >
        <IoMdMenu className="h-10 w-10 text-primary-blue-darker" />
      </button>
      {toggleNav && (
        <ul className="absolute right-0 top-[100%] flex w-44 flex-col gap-3 rounded-xl bg-white px-[5px] py-[10px] text-xs shadow-md">
          <li>
            <NavLink
              to="/"
              bg-white
              className={({ isActive }) =>
                (isActive
                  ? "bg-gradient-to-br from-primary-blue-dark to-[#4F33F7B2] text-white"
                  : "") + " flex items-center rounded-[6px] p-[9.5px]"
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <button
              className="flex w-full items-center justify-between rounded-[6px] p-[9.5px]"
              onClick={() => setDropDown(!dropDown)}
            >
              Products
              {dropDown ? (
                <PiCaretDownLight className="h-6 w-6" />
              ) : (
                <PiCaretRightLight className="h-6 w-6" />
              )}
            </button>
          </li>
          {dropDown && (
            <>
              <a
                href="https://training.leaguesarena.com"
                className="flex items-center rounded-[6px] bg-[#F3F3F3] p-[9.5px]"
              >
                Leagues Training
              </a>
              <Link
                to="/home"
                className="flex items-center rounded-[6px] bg-[#F3F3F3] p-[9.5px]"
              >
                Leagues Competitions
              </Link>
            </>
          )}
          <li>
            <NavLink
              to="/contact"
              bg-white
              className={({ isActive }) =>
                (isActive
                  ? "bg-gradient-to-br from-primary-blue-dark to-[#4F33F7B2] text-white"
                  : "") + " flex items-center rounded-[6px] p-[9.5px]"
              }
            >
              Contact Us
            </NavLink>
          </li>
          <li>
            <button
              className={`flex w-full items-center rounded-[6px] p-[9.5px] ${
                dropDown2 && "bg-primary-blue-dark text-white"
              } `}
              onClick={() => {
                setDropDown2(!dropDown2);
              }}
            >
              Get Started
            </button>
            {dropDown2 && (
              <>
                <div className="mt-2 space-y-2">
                  <a
                    href="https://training.leaguesarena.com"
                    className="flex items-center rounded-[6px] bg-[#F3F3F3] p-[9.5px]"
                  >
                    Leagues Training
                  </a>
                  <Link
                    to="/home"
                    className="flex items-center rounded-[6px] bg-[#F3F3F3] p-[9.5px]"
                  >
                    Leagues Competitions
                  </Link>
                </div>
              </>
            )}
          </li>
        </ul>
      )}
    </ul>
  );
}

export default MobileMenu;
