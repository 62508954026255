import React, { useEffect } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import { LaArrowLeft, LaCaretRight } from "../../components/svg-icons";
import { useGetTournamentMatchQuery } from "../../redux/services/matchService";
import { ScorePlayersSelect } from "../../components/scores";
import { Button, Input, Spinner } from "../../components/ui";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  AddNewPlayersModal,
  PenaltyKickModal,
} from "../../components/tournament";
import { cn } from "../../utils/cn";
import HelmetComponent from "../../components/HelmetComponent";

export async function action({ request }: { request: any }) {
  const formData = await request.formData();

  const teamAScore = formData.get("teamAScore");
  const teamBScore = formData.get("teamBScore");
  const matchData = formData.get("matchData");

  // Convert FormDataEntryValue to string before parsing
  const teamADataString = formData.get("teamAData")?.toString() || "{}";
  const teamBDataString = formData.get("teamBData")?.toString() || "{}";

  // Parse the stringified JSON
  const teamAData = JSON.parse(teamADataString);
  const teamBData = JSON.parse(teamBDataString);

  return {
    matchData: JSON.parse(matchData as string),
    teamAData: { ...teamAData, score: teamAScore },
    teamBData: { ...teamBData, score: teamBScore },
    shots: {
      teamA: formData.get("shots-team-a"),
      teamB: formData.get("shots-team-b"),
    },
    corners: {
      teamA: formData.get("corners-team-a"),
      teamB: formData.get("corners-team-b"),
    },
    "shots-on-target": {
      teamA: formData.get("shots-on-target-team-a"),
      teamB: formData.get("shots-on-target-team-b"),
    },
    fouls: {
      teamA: formData.get("fouls-team-a"),
      teamB: formData.get("fouls-team-b"),
    },
    offside: {
      teamA: formData.get("offside-team-a"),
      teamB: formData.get("offside-team-b"),
    },
    possession: {
      teamA: formData.get("possession-team-a"),
      teamB: formData.get("possession-team-b"),
    },
    "passing-accuracy": {
      teamA: formData.get("passing-accuracy-team-a"),
      teamB: formData.get("passing-accuracy-team-b"),
    },
    tackles: {
      teamA: formData.get("tackles-team-a"),
      teamB: formData.get("tackles-team-b"),
    },
    "player-xg": {
      teamA: formData.get("player-xg-team-a"),
      teamB: formData.get("player-xg-team-b"),
    },
    "player-xa": {
      teamA: formData.get("player-xa-team-a"),
      teamB: formData.get("player-xa-team-b"),
    },
    "tackles-won": {
      teamA: formData.get("tackles-won-team-a"),
      teamB: formData.get("tackles-won-team-b"),
    },
    interceptions: {
      teamA: formData.get("interceptions-team-a"),
      teamB: formData.get("interceptions-team-b"),
    },
    clearances: {
      teamA: formData.get("clearances-team-a"),
      teamB: formData.get("clearances-team-b"),
    },
    "aerial-duels-won": {
      teamA: formData.get("aerial-duels-won-team-a"),
      teamB: formData.get("aerial-duels-won-team-b"),
    },
    "pass-completion-rate": {
      teamA: formData.get("pass-completion-rate-team-a"),
      teamB: formData.get("pass-completion-rate-team-b"),
    },
    "clean-sheets-gk": {
      teamA: formData.get("clean-sheets-gk-team-a"),
      teamB: formData.get("clean-sheets-gk-team-b"),
    },
    "save-percentage-gk": {
      teamA: formData.get("save-percentage-gk-team-a"),
      teamB: formData.get("save-percentage-gk-team-b"),
    },
    "total-saves-gk": {
      teamA: formData.get("total-saves-gk-team-a"),
      teamB: formData.get("total-saves-gk-team-b"),
    },
    "distribution-accuracy-gk": {
      teamA: formData.get("distribution-accuracy-gk-team-a"),
      teamB: formData.get("distribution-accuracy-gk-team-b"),
    },
    "sweeper-keeper-actions-gk": {
      teamA: formData.get("sweeper-keeper-actions-gk-team-a"),
      teamB: formData.get("sweeper-keeper-actions-gk-team-b"),
    },
    "penalty-saves-gk": {
      teamA: formData.get("penalty-saves-gk-team-a"),
      teamB: formData.get("penalty-saves-gk-team-b"),
    },
    "interceptions-gk": {
      teamA: formData.get("interceptions-gk-team-a"),
      teamB: formData.get("interceptions-gk-team-b"),
    },
  };
}

function EnterScore() {
  const navigate = useNavigate();
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;

  const { id } = useParams();
  const [teamAScore, setTeamAScore] = React.useState(0);
  const [teamBScore, setTeamBScore] = React.useState(0);
  const [addNewPlayersModal, setAddNewPlayersModal] = React.useState(false);

  const [inputError, setInputError] = React.useState({
    error: "",
    clearError: function (value: string) {
      setInputError((prev) => ({ ...prev, [value]: "" }));
    },
  });
  const [playerDropDown, setPlayerDropDown] = React.useState<
    | "goal-team-a"
    | "goal-team-b"
    | "own-goal-team-a"
    | "own-goal-team-b"
    | "assist-team-a"
    | "assist-team-b"
    | "red-card-team-a"
    | "red-card-team-b"
    | "yellow-card-team-a"
    | "yellow-card-team-b"
    | ""
  >("");

  const { data: match, isLoading: matchLoading } = useGetTournamentMatchQuery({
    id: id,
  });

  const [teamA, teamB] = match?.teamData ?? [];
  const [teamAScorers, setTeamAScorers] = React.useState<TPlayer[]>([]);
  const [teamAOwnGoalsScorers, setTeamAOwnGoalsScorers] = React.useState<
    TPlayer[]
  >([]);
  const [teamBScorers, setTeamBScorers] = React.useState<TPlayer[]>([]);
  const [teamBOwnGoalsScorers, setTeamBOwnGoalsScorers] = React.useState<
    TPlayer[]
  >([]);
  const [teamAAssisters, setTeamAAssisters] = React.useState<TPlayer[]>([]);
  const [teamBAssisters, setTeamBAssisters] = React.useState<TPlayer[]>([]);
  const [teamARedCards, setTeamARedCards] = React.useState<TPlayer[]>([]);
  const [teamBRedCards, setTeamBRedCards] = React.useState<TPlayer[]>([]);
  const [teamAYellowCards, setTeamAYellowCards] = React.useState<TPlayer[]>([]);
  const [teamBYellowCards, setTeamBYellowCards] = React.useState<TPlayer[]>([]);
  const [teamId, setTeamId] = React.useState<string | undefined>("");
  const [teamAPenalty, setTeamAPenalty] = React.useState(0);
  const [teamBPenalty, setTeamBPenalty] = React.useState(0);
  const tied =
    teamAScore === teamBScore &&
    match?.tournament?.format !== "season" &&
    match?.tournament?.stage !== "group";
  const [isATie, setIsATie] = React.useState(tied);

  const teamAPlayers =
    match?.tournament?.teams.find((team) => team?.team === teamA?.team?._id)
      ?.players ?? [];
  const teamBPlayers =
    match?.tournament?.teams.find((team) => team?.team === teamB?.team?._id)
      ?.players ?? [];

  function handleAddNewPlayers(teamId?: string) {
    setAddNewPlayersModal(true);
    setPlayerDropDown("");
    setTeamId(teamId);
  }

  useEffect(() => {
    setIsATie(tied);
  }, [teamAScore, teamBScore]);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Enter Score",
    description: "Enter score for the match",
    url: `https://${window.location.host}competitions/scores/edit${id}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {matchLoading ? (
        <div className="flex min-h-screen items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div
          className={cn("flex flex-col items-center justify-center pb-10", {
            "pb-60 lg:pb-80": isATie,
          })}
        >
          <div className="flex w-full flex-col items-center pb-[8rem] lg:max-w-[87%] lg:pb-0">
            <div className="flex w-full items-center gap-4 px-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
              <button
                className="flex items-center gap-2 text-xl"
                onClick={() => navigate(-1)}
                aria-label="back"
              >
                <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
              </button>
              <h3 className="text-xl font-bold">Enter Score</h3>
            </div>

            <Form
              className="mt-4 flex w-full flex-col items-center px-4 lg:mt-14 lg:w-[65%]"
              action="g"
              method="POST"
            >
              <fieldset className="flex w-full justify-between">
                <fieldset className="mt-4 flex w-[100px] flex-col items-center gap-2">
                  <label htmlFor="teamAScore" className="uppercase">
                    {teamA?.team?.shortName}
                  </label>
                  <img
                    src={teamA?.team?.avatar}
                    alt="team logo"
                    className="h-10 w-10 rounded-full"
                  />
                  <Input
                    role={role}
                    pattern="[0-9]*"
                    type="text"
                    id="teamAScore"
                    name="teamAScore"
                    className={`w-full rounded border border-grey-light px-2 py-1 text-center text-base lg:text-xl ${
                      inputError?.error
                        ? "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
                        : "focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30"
                    }`}
                    onInput={() => inputError.clearError("error")}
                    required
                    defaultValue={teamAScore}
                    onChange={(e) => setTeamAScore(+e.target.value)}
                  />
                </fieldset>
                <div className="flex flex-col items-center gap-4">
                  <h3>Match {match?.name}</h3>
                  <p>VS</p>
                </div>
                <fieldset className="mt-4 flex w-[100px] flex-col items-center gap-2">
                  <label htmlFor="teamBScore" className="uppercase">
                    {teamB?.team?.shortName}
                  </label>
                  <img
                    src={teamB?.team?.avatar}
                    alt="team logo"
                    className="h-10 w-10 rounded-full"
                  />
                  <Input
                    role={role}
                    pattern="[0-9]*"
                    type="text"
                    id="teamBScore"
                    name="teamBScore"
                    className={`w-full rounded border border-grey-light px-2 py-1 text-center text-base lg:text-xl ${
                      inputError?.error
                        ? "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
                        : "focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30"
                    }`}
                    onInput={() => inputError.clearError("error")}
                    required
                    defaultValue={teamBScore}
                    onChange={(e) => setTeamBScore(+e.target.value)}
                  />
                </fieldset>
              </fieldset>

              {isATie && (
                <div className="mt-8 flex w-full cursor-pointer items-center justify-between rounded-lg bg-[#E6E6E6] p-4">
                  <div className="flex flex-col text-left">
                    <h3 className="font-bold text-primary-black-darker">
                      Match ended in a tie?
                    </h3>
                    <p className="mt-1 text-sm font-medium">
                      Select winner after penalty kick
                    </p>
                  </div>
                  <LaCaretRight />
                </div>
              )}

              <div className="mt-10 flex flex-col items-center">
                <h3 className="border-b border-primary-black-darker px-8 pb-4 font-medium">
                  Match Stats
                </h3>
                <h3 className="pt-4 font-medium text-grey">Goal scorers</h3>
              </div>

              <div className="mt-4 flex w-full justify-between">
                <ScorePlayersSelect
                  dropDownOption="goal-team-a"
                  options={teamAPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamAScorers((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamAScorers((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  disabled={
                    teamAScore <= 0 ||
                    teamAScorers.length + teamAOwnGoalsScorers.length >=
                      teamAScore
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamA?.team?._id)
                  }
                />

                <ScorePlayersSelect
                  dropDownOption="goal-team-b"
                  options={teamBPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamBScorers((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamBScorers((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  disabled={
                    teamBScore <= 0 ||
                    teamBScorers.length + teamBOwnGoalsScorers.length >=
                      teamBScore
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamB?.team?._id)
                  }
                />
              </div>

              <h3 className="mt-6 font-medium text-grey">Assists</h3>

              <div className="mt-4 flex w-full justify-between">
                <ScorePlayersSelect
                  dropDownOption="assist-team-a"
                  options={teamAPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamAAssisters((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamAAssisters((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  disabled={teamAScore <= 0}
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamA?.team?._id)
                  }
                />

                <ScorePlayersSelect
                  dropDownOption="assist-team-b"
                  options={teamBPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamBAssisters((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamBAssisters((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  disabled={teamBScore <= 0}
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamB?.team?._id)
                  }
                />
              </div>

              <h3 className="mt-6 font-medium text-grey">Own Goals</h3>
              <div className="mt-4 flex w-full justify-between">
                <ScorePlayersSelect
                  dropDownOption="own-goal-team-a"
                  options={teamBPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamAOwnGoalsScorers((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamAOwnGoalsScorers((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  disabled={
                    teamAScore <= 0 ||
                    teamAScorers.length + teamAOwnGoalsScorers.length >=
                      teamAScore
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamB?.team?._id)
                  }
                />

                <ScorePlayersSelect
                  dropDownOption="own-goal-team-b"
                  options={teamAPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamBOwnGoalsScorers((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamBOwnGoalsScorers((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  disabled={
                    teamBScore <= 0 ||
                    teamBScorers.length + teamBOwnGoalsScorers.length >=
                      teamBScore
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamA?.team?._id)
                  }
                />
              </div>

              <h3 className="mt-6 font-medium text-grey">Red card</h3>

              <div className="mt-4 flex w-full justify-between">
                <ScorePlayersSelect
                  dropDownOption="red-card-team-a"
                  options={teamAPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamARedCards((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamARedCards((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamA?.team?._id)
                  }
                />
                <ScorePlayersSelect
                  dropDownOption="red-card-team-b"
                  options={teamBPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamBRedCards((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamBRedCards((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamB?.team?._id)
                  }
                />
              </div>

              <h3 className="mt-6 font-medium text-grey">Yellow card</h3>

              <div className="mt-4 flex w-full justify-between">
                <ScorePlayersSelect
                  dropDownOption="yellow-card-team-a"
                  options={teamAPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamAYellowCards((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamAYellowCards((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamA?.team?._id)
                  }
                />
                <ScorePlayersSelect
                  dropDownOption="yellow-card-team-b"
                  options={teamBPlayers as TPlayer[]}
                  playerDropDown={playerDropDown}
                  setPlayerDropDown={setPlayerDropDown}
                  handleSelection={(player: TPlayer) =>
                    setTeamBYellowCards((prev) => [...prev, player])
                  }
                  handleRemoveSelection={(index) =>
                    setTeamBYellowCards((prev) => {
                      const copy = [...(prev ?? [])];
                      copy.splice(index, 1);
                      return copy;
                    })
                  }
                  handleAddNewPlayers={() =>
                    handleAddNewPlayers(teamB?.team?._id)
                  }
                />
              </div>

              <h3 className="mt-6 font-medium text-grey">Shots</h3>
              <div className="flex w-full items-center justify-between gap-4">
                <input
                  type="number"
                  name={`shots-team-a`}
                  id={`shots-team-a`}
                  min={0}
                  defaultValue={0}
                  className="max-w-[200px] rounded border border-grey-light bg-white px-4 py-2 leading-6 text-grey disabled:cursor-not-allowed disabled:opacity-50"
                />
                <input
                  type="number"
                  name={`shots-team-b`}
                  id={`shots-team-b`}
                  min={0}
                  defaultValue={0}
                  className="max-w-[200px] rounded border border-grey-light bg-white px-4 py-2 leading-6 text-grey disabled:cursor-not-allowed disabled:opacity-50"
                />
              </div>
              <h3 className="mt-6 font-medium text-grey">Corners</h3>
              <NumberOnlyField name="corners" />
              <h3 className="mt-6 font-medium text-grey">Shots on Target</h3>
              <NumberOnlyField name="shots-on-target" />
              <h3 className="mt-6 font-medium text-grey">Fouls</h3>
              <NumberOnlyField name="fouls" />
              <h3 className="mt-6 font-medium text-grey">Offside</h3>
              <NumberOnlyField name="offside" />
              <h3 className="mt-6 font-medium text-grey">Possession</h3>
              <NumberOnlyField name="possession" />
              <h3 className="mt-6 font-medium text-grey">Pass Accuracy</h3>
              <NumberOnlyField name="passing-accuracy" />
              <h3 className="mt-6 font-medium text-grey">Tackles</h3>
              <NumberOnlyField name="tackles" />
              <h3 className="mt-6 font-medium text-grey">
                Player xG (Expected Goals)
              </h3>
              <NumberOnlyField name="player-xg" />
              <h3 className="mt-6 font-medium text-grey">
                Player xA (Expected Assist)
              </h3>
              <NumberOnlyField name="player-xa" />
              <h3 className="mt-6 font-medium text-grey">Tackles Won</h3>
              <NumberOnlyField name="tackles-won" />
              <h3 className="mt-6 font-medium text-grey">Interceptions</h3>
              <NumberOnlyField name="interceptions" />
              <h3 className="mt-6 font-medium text-grey">Clearances</h3>
              <NumberOnlyField name="clearances" />
              <h3 className="mt-6 font-medium text-grey">Aerial Duels Won</h3>
              <NumberOnlyField name="aerial-duels-won" />
              <h3 className="mt-6 font-medium text-grey">
                Pass Completion Rate
              </h3>
              <NumberOnlyField name="pass-completion-rate" />
              <h3 className="mt-6 font-medium text-grey">Clean Sheets (GK)</h3>
              <NumberOnlyField name="clean-sheets-gk" />
              <h3 className="mt-6 font-medium text-grey">
                Save Percentage (GK)
              </h3>
              <NumberOnlyField name="save-percentage-gk" />
              <h3 className="mt-6 font-medium text-grey">Total Saves (GK)</h3>
              <NumberOnlyField name="total-saves-gk" />
              <h3 className="mt-6 font-medium text-grey">
                Distribution Accuracy (GK)
              </h3>
              <NumberOnlyField name="distribution-accuracy-gk" />
              <h3 className="mt-6 font-medium text-grey">
                Sweeper Keeper Actions (GK)
              </h3>
              <NumberOnlyField name="sweeper-keeper-actions-gk" />
              <h3 className="mt-6 font-medium text-grey">Penalty Saves (GK)</h3>
              <NumberOnlyField name="penalty-saves-gk" />
              <h3 className="mt-6 font-medium text-grey">Interceptions (GK)</h3>
              <NumberOnlyField name="interceptions-gk" />

              <div className="hidden_inputs">
                <input
                  type="text"
                  name="matchData"
                  hidden
                  readOnly
                  value={JSON.stringify({ ...match })}
                />
                <input
                  type="text"
                  name="teamAData"
                  hidden
                  readOnly
                  value={JSON.stringify({
                    teamA: teamA,
                    ownGoals: teamAOwnGoalsScorers,
                    scorers: teamAScorers,
                    assisters: teamAAssisters,
                    redCards: teamARedCards,
                    yellowCards: teamAYellowCards,
                    penalties: teamAPenalty,
                  })}
                />
                <input
                  type="text"
                  name="teamBData"
                  hidden
                  readOnly
                  value={JSON.stringify({
                    teamB: teamB,
                    ownGoals: teamBOwnGoalsScorers,
                    scorers: teamBScorers,
                    assisters: teamBAssisters,
                    redCards: teamBRedCards,
                    yellowCards: teamBYellowCards,
                    penalties: teamBPenalty,
                  })}
                />
              </div>

              <div
                className={cn(
                  "fixed bottom-0 right-0 z-[60] grid w-full grid-cols-2 gap-4 border-t border-grey-light bg-white px-3 py-4 shadow lg:static lg:mt-[74px] lg:border-0 lg:px-0 lg:shadow-none",
                  {
                    hidden: isATie,
                  }
                )}
              >
                <Button
                  role={role}
                  intent="secondary"
                  // className="rounded border border-primary-black-darker py-4 font-medium"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  role={role}
                  intent="primary"
                  className="rounded py-3 font-medium text-white disabled:cursor-not-allowed disabled:opacity-50"
                  type="submit"
                  // disabled={
                  //   teamAScorers.length + teamAOwnGoalsScorers.length <
                  //     teamAScore ||
                  //   teamBScorers.length + teamBOwnGoalsScorers.length <
                  //     teamBScore ||
                  //   isATie
                  // }
                >
                  Confirm
                </Button>
              </div>
              {isATie && tied && (
                <PenaltyKickModal
                  setIsATie={setIsATie}
                  disabled={
                    teamAScorers.length < teamAScore ||
                    teamBScorers.length < teamBScore ||
                    teamAPenalty === teamBPenalty
                  }
                  teams={[
                    {
                      name: teamA?.team?.shortName,
                      avatar: teamA?.team?.avatar,
                    },
                    {
                      name: teamB?.team?.shortName,
                      avatar: teamB?.team?.avatar,
                    },
                  ]}
                  setTeamAPenalty={setTeamAPenalty}
                  setTeamBPenalty={setTeamBPenalty}
                />
              )}
            </Form>
          </div>
        </div>
      )}
      {addNewPlayersModal && (
        <AddNewPlayersModal
          setAddNewPlayersModal={setAddNewPlayersModal}
          tournamentId={match?.tournament?._id}
          teamId={teamId}
        />
      )}
    </>
  );
}

function NumberOnlyField({ name }: { name: string }) {
  return (
    <div className="flex w-full items-center justify-between gap-4">
      <input
        type="number"
        name={`${name}-team-a`}
        id={`${name}-team-a`}
        min={0}
        defaultValue={0}
        className="max-w-[200px] rounded border border-grey-light bg-white px-4 py-2 leading-6 text-grey disabled:cursor-not-allowed disabled:opacity-50"
      />
      <input
        type="number"
        name={`${name}-team-b`}
        id={`${name}-team-b`}
        min={0}
        defaultValue={0}
        className="max-w-[200px] rounded border border-grey-light bg-white px-4 py-2 leading-6 text-grey disabled:cursor-not-allowed disabled:opacity-50"
      />
    </div>
  );
}

export default EnterScore;
