import type { ReactElement } from "react";

export interface EmailSentProps {
  medium: "sms" | "email";
  closeModal: () => void;
}

export default function EmailSent({
  medium,
  closeModal,
}: EmailSentProps): ReactElement {
  function handleNavigate() {
    localStorage.removeItem("token");
    window.location.href = "/";
  }
  return (
    <div className="absolute bottom-0 right-0 top-0 min-h-screen w-[100vw] justify-center bg-black/20 lg:flex">
      <div className="flex h-screen w-full items-center justify-center px-4 lg:px-0">
        <div className="z-[60] flex w-full flex-col rounded-xl bg-white p-4 px-6 py-20 shadow lg:w-[28%]">
          <h3 className="text-center text-2xl font-bold">Done!</h3>
          <p className="mt-2 text-center">
            We have sent you an {medium} to reset your password. Follow the link
            in the {medium} to create a new password.
          </p>
          <div className="mt-4 flex items-center justify-center gap-2">
            <p>Didn’t get link? </p>

            <button
              className="font-medium text-primary-blue-darker"
              onClick={() => {
                closeModal();
              }}
            >
              Resend link
            </button>
          </div>
          <button
            className="mt-6 rounded-lg bg-primary-blue-darker py-3 text-base font-medium leading-[21px] text-white hover:bg-primary-blue-light hover:text-primary-black-darker lg:text-xl"
            onClick={handleNavigate}
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
}
