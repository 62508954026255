import React from "react";
import FixturesCard from "../ui/cards/FixtureCard";
import { CompletedCard } from "../tournament";
import { Spinner } from "../ui";
import { useGetAllTeamMatchesQuery } from "../../redux/services/matchService";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import Button from "../ui/buttons/Button";
import { cn } from "../../utils/cn";

type Props = {
  teamId?: string;
};

function TeamMatches({ teamId }: Props) {
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [activeTab, setActiveTab] = React.useState<"ongoing" | "completed">(
    "ongoing"
  );
  const [clickedIndex, setClickedIndex]: any = React.useState(null);

  const { data: matches = [], isLoading: loadingMatches } =
    useGetAllTeamMatchesQuery({
      teamId: teamId as string,
      status: activeTab,
      page: 1,
      count: 10,
    });

  const completedMatches = matches?.map((match, i) => {
    const [teamA, teamB] = match?.teamData ?? [];
    return (
      <CompletedCard
        key={match?._id}
        round={match?.name}
        venue={match?.location}
        date={match?.date}
        teamA={teamA}
        teamB={teamB}
        index={i}
        setClickedIndex={setClickedIndex}
        isOpen={clickedIndex === i}
        canEdit={false}
        id={match?._id}
      />
    );
  });

  const ongoingMatches = matches?.map((match, idx) => {
    const [teamA, teamB] = match.teamData ?? [];
    return (
      <FixturesCard
        {...match}
        key={idx}
        teamA={teamA?.team}
        teamB={teamB?.team}
        venue={match.location}
        round={match.name}
        tournamentAuthor={match?.tournament?.author}
      />
    );
  });

  return (
    <div>
      <div className="flex w-full items-center gap-4 border-b border-grey-light px-4 py-4 text-base font-medium lg:px-0">
        <Button
          intent="secondary"
          role={role}
          className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
            "border-grey-light text-grey": activeTab !== "completed",
          })}
          type="button"
          onClick={() => setActiveTab("completed")}
        >
          Completed
        </Button>
        <Button
          intent="secondary"
          role={role}
          className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
            "border-grey-light text-grey": activeTab !== "ongoing",
          })}
          type="button"
          onClick={() => setActiveTab("ongoing")}
        >
          Upcoming
        </Button>
      </div>

      <section className="flex flex-col gap-4 p-4 lg:px-0">
        {loadingMatches ? (
          <div className="flex w-full items-center justify-center py-4">
            <p>{matches[0]?.location}</p>
            <Spinner />
          </div>
        ) : (
          <>
            {activeTab === "completed" && (
              <>
                {completedMatches.length === 0 && (
                  <EmptyStateAuto
                    title="Nothing to see here"
                    description="This team does not have any completed matches. When they do it'll appear here"
                  />
                )}
                {completedMatches}
              </>
            )}
            {activeTab === "ongoing" && (
              <>
                {ongoingMatches.length === 0 && (
                  <EmptyStateAuto
                    title="Nothing to see here"
                    description="This team does not have any upcoming match. When they do it'll appear here"
                  />
                )}
                {ongoingMatches}
              </>
            )}
          </>
        )}
      </section>
    </div>
  );
}

export default TeamMatches;
