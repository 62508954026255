import { leaguesArenaApi } from "./leaguesArenaApiSlice";

const walletApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getWallet: builder.query<TWallet | undefined, undefined | void>({
      query: () => ({
        url: `/wallet`,
        method: "GET",
      }),
      providesTags: ["Wallet"],
      transformResponse: (response: { data: TWallet }) => response.data,
    }),
    sendWithdrawalRequest: builder.mutation({
      query: (body) => ({
        url: `/wallet/request-withdrawal`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Wallet"],
    }),
  }),
});

export const { useGetWalletQuery, useSendWithdrawalRequestMutation } =
  walletApi;
