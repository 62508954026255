import type { ReactElement } from "react";
import { PlayerCardDeck } from "./cards";
import { useGetAuthorsTeamQuery } from "../../redux/services/teamService";
import { LoadingDots } from ".";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";

export interface PaginatedPlayersProps {
  players: TPlayer[];
  myId: string;
  isLoading: boolean;
  isFetching: boolean;
  size: React.MutableRefObject<number>;
  refElement: (node?: Element | null | undefined) => void;
  setSelectedPlayers?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCompetitionForTeam?: React.Dispatch<React.SetStateAction<string>>;
  selectedCompetitionForTeam?: string;
  setCompetitionPlayersForTeam?: React.Dispatch<React.SetStateAction<string[]>>;
  competitionPlayersForTeam?: string[];
  selectedPlayers?: string[];
}

export default function PaginatedPlayers({
  players,
  myId,
  isLoading,
  isFetching,
  size,
  refElement,
  setSelectedPlayers,
  selectedPlayers,
  setSelectedCompetitionForTeam,
  selectedCompetitionForTeam,
  competitionPlayersForTeam,
}: PaginatedPlayersProps): ReactElement {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const { data: authorTeam } = useGetAuthorsTeamQuery({ id: myId });
  return (
    <>
      <div className="flex flex-row flex-wrap justify-between gap-y-6 bg-white p-4 pt-6 lg:justify-center lg:gap-6 lg:p-0">
        <PlayerCardDeck
          players={players}
          myId={myId}
          authorTeam={authorTeam}
          setSelectedPlayers={setSelectedPlayers}
          selectedPlayers={selectedPlayers}
          setSelectedCompetitionForTeam={setSelectedCompetitionForTeam}
          selectedCompetitionForTeam={selectedCompetitionForTeam}
          competitionPlayersForTeam={competitionPlayersForTeam}
        />
      </div>

      {!isLoading && !isFetching && players?.length > size.current - 2 && (
        <div ref={refElement} className="lg:pb-24 lg:pt-8" />
      )}
      {isFetching && <LoadingDots role={role} />}
    </>
  );
}
