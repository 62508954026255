function LaFeedback({ stroke = "#1A1A1A" }: { stroke?: string }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none">
      <g
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.083 8.75h5.834M5.833 15.358h3.334l3.708 2.466a.83.83 0 001.292-.691v-1.775c2.5 0 4.166-1.667 4.166-4.167v-5c0-2.5-1.666-4.167-4.166-4.167H5.833c-2.5 0-4.166 1.667-4.166 4.167v5c0 2.5 1.666 4.167 4.166 4.167z" />
      </g>
    </svg>
  );
}

export default LaFeedback;
