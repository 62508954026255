import * as React from "react";

function LaCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.3332 10.7513V14.2513C13.3332 17.168 12.1665 18.3346 9.24984 18.3346H5.74984C2.83317 18.3346 1.6665 17.168 1.6665 14.2513V10.7513C1.6665 7.83464 2.83317 6.66797 5.74984 6.66797H9.24984C12.1665 6.66797 13.3332 7.83464 13.3332 10.7513Z"
        stroke={props.stroke || "#fff"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3332 5.7513V9.2513C18.3332 12.168 17.1665 13.3346 14.2498 13.3346H13.3332V10.7513C13.3332 7.83463 12.1665 6.66797 9.24984 6.66797H6.6665V5.7513C6.6665 2.83464 7.83317 1.66797 10.7498 1.66797H14.2498C17.1665 1.66797 18.3332 2.83464 18.3332 5.7513Z"
        stroke={props.stroke || "#fff"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaCopy;
