function LaArrowRight({
  stroke = "#1A1A1A",
  strokeWidth = 1.5,
}: {
  stroke?: string;
  strokeWidth?: number;
}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.62 3.953L13.667 8 9.62 12.047M2.333 8h11.22"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaArrowRight;
