import React from "react";
import { CalendarSection, MatchCard, TrainingCard } from "../ui/cards";
import { LaCalendar } from "../svg-icons";
import { useGetDateMatchesQuery } from "../../redux/services/matchService";
import { useGetDateTrainingsQuery } from "../../redux/services/trainingService";
import { Spinner } from "../ui";

type Props = {};

function HomeMatches({}: Props) {
  const [date, setDate] = React.useState<string>(() =>
    new Date().toISOString()
  );
  const { data: matches = [], isLoading: matchesLoading } =
    useGetDateMatchesQuery({ date: date });
  const { data: trainingSessions = [], isLoading: trainingLoading } =
    useGetDateTrainingsQuery({
      date: date,
    });

  const trainingCards = trainingSessions?.map((training: TTraining, _i) => (
    <TrainingCard
      date={training?.date}
      location={training?.venue}
      name={training?.sessionName}
      noOfPlayers={training?.players?.length}
      time={training?.time}
      trainingId={training?._id}
      key={training?._id}
    />
  ));

  const matchCards = matches.map((match, i) => (
    <MatchCard key={i} match={match} />
  ));
  const allCards = [...trainingCards, ...matchCards];

  return (
    <div>
      <CalendarSection date={date} setMatchDate={setDate} />
      {allCards.length > 0 || matchesLoading || trainingLoading ? (
        <div className="flex flex-col gap-3 p-4 lg:px-0">
          {matchesLoading || trainingLoading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>{allCards}</>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center  p-4">
          <div className="my-24 flex flex-col items-center justify-center gap-2">
            <span className="text-8xl">
              <LaCalendar stroke="#D8D8D8" />
            </span>
            <h3 className="text-2xl">No Events</h3>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeMatches;
