import React from "react";
import { Link } from "react-router-dom";
import { LaEditPencil, LaLocation } from "../../components/svg-icons";
import moment from "moment";
import { useAppSelector } from "../../redux/app/hooks";
import { EditFixtureModal } from "../../components/modals";
import { RootState } from "../../redux/app/store";
import EmptyStateAuto from "../ui/EmptyStateAuto";

type FixturesProps = {
  fixtures: Match[];
  tournamentAuthorId?: string;
};

function SeasonFixtures({ fixtures, tournamentAuthorId }: FixturesProps) {
  const [isEditing, setIsEditing] = React.useState(false);
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const isTournamentAuthor = myId === tournamentAuthorId;

  const [matchData, setMatchData] = React.useState<{
    location?: string;
    id?: string;
    date?: string;
    time?: any;
    teamData: [
      { shortName: string; avatar: string },
      { shortName: string; avatar: string }
    ];
  }>({
    location: "",
    id: "",
    date: "",
    time: "",
    teamData: [
      { shortName: "", avatar: "" },
      { shortName: "", avatar: "" },
    ],
  });

  function handleEditFixtures(match: any) {
    const teamData = match.teamData.map((data: any) => data.team);

    setIsEditing(true);
    setMatchData((prev) => ({
      ...prev,
      id: match._id,
      location: match.location,
      date: match.date,
      time: match.time,
      teamData: teamData,
    }));
  }

  const fixtureCards = fixtures
    ?.filter((match) => match?.status === "ongoing")
    .sort(
      (a, b) => parseInt(a.name as string, 10) - parseInt(b.name as string, 10)
    )
    .map((match, i) => {
      const [teamA, teamB] = match.teamData;
      const past = moment().diff(moment(match.date), "seconds") > 0;

      return (
        <div className="rounded border border-grey-light" key={i}>
          <div className="flex items-center justify-between border-b border-grey-light p-3 text-xs">
            <p className="flex items-center gap-1">
              Match <span>{+(match.name as string) + 1}</span>
              <span>·</span>
              <span>
                {moment(match?.date).format("ll")} -{" "}
                {moment(match?.date).format("ll")}
              </span>
            </p>
            {isTournamentAuthor && (
              <button
                className="flex items-center gap-1"
                type="button"
                onClick={() => handleEditFixtures(match)}
              >
                <span className="text-base">
                  <LaEditPencil />
                </span>
                Edit
              </button>
            )}
          </div>
          <div className="flex items-center justify-between px-3 py-4 font-medium capitalize">
            <Link
              to={`/profiles/team/${teamA?.team?.name?.replace(/\s/g, "-")}`}
              className="flex items-center gap-2"
            >
              <img
                src={teamA?.team?.avatar}
                alt=""
                className="h-5 w-5 rounded-full"
              />
              <p>{teamA?.team?.shortName}</p>
            </Link>
            {past && isTournamentAuthor ? (
              <Link
                to={`/competitions/scores/edit/${match._id}`}
                className="text-primary-blue-darker"
              >
                Enter Scores
              </Link>
            ) : (
              <Link
                to={`/profiles/team/${teamA?.team?.name?.replace(/\s/g, "-")}`}
              >
                {match.time ? (
                  <div className="flex flex-col items-center justify-center text-sm">
                    <p className="uppercase">
                      {moment(match?.time).format("HH:mm a")}
                    </p>
                    <p>{moment(match?.date).format("ll")}</p>
                  </div>
                ) : (
                  <p className="text-sm">TBD</p>
                )}
              </Link>
            )}
            <div className="flex flex-row-reverse items-center gap-2">
              <img
                src={teamB?.team?.avatar}
                alt=""
                className="h-5 w-5 rounded-full"
              />
              <p>{teamB?.team?.shortName}</p>
            </div>
          </div>
          <div className="flex items-center gap-2 px-3 pb-3 text-xs font-medium">
            <LaLocation />
            <p>{match.location}</p>
          </div>
        </div>
      );
    });

  return (
    <>
      {fixtures?.length === 0 && (
        <EmptyStateAuto
          title="Nothing to see here"
          description="This tournament does not have any fixture yet. when it does it'll appear here"
        />
      )}
      <div className="mt-4 flex flex-col gap-4 px-4 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-5 lg:px-0">
        {fixtureCards}
      </div>

      {isEditing && (
        <EditFixtureModal matchData={matchData} setIsEditing={setIsEditing} />
      )}
    </>
  );
}

export default SeasonFixtures;
