import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const proSubscriptionApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation({
      query: ({ planType, body }) => ({
        url: `/subscriptions/${planType}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),
    getSubscription: builder.query({
      query: ({ role }) => ({
        url: `/subscriptions/${role}`,
      }),
      transformResponse: (response: any) => response.data,
    }),
  }),
});

export const { useCreateSubscriptionMutation, useGetSubscriptionQuery } =
  proSubscriptionApi;
