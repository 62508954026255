import errorImage from "../../assets/images/error.webp";

export default function ErrorPage() {
  return (
    <div className="cloud__bg flex min-h-screen flex-col items-center justify-center gap-3 bg-white px-4 py-20">
      <img src={errorImage} alt="error image" />
      <h3 className="text-center text-2xl  text-primary-blue-dark  md:text-5xl">
        Something went wrong
      </h3>
      <p className="text-center text-base text-primary-blue-dark md:text-2xl">
        We apologize for the inconvenience, but it seems that something
        unexpected has occurred.
      </p>

      <button
        className=" mt-10 rounded bg-primary-blue-dark px-20 py-4 text-base text-white hover:bg-primary-blue-light hover:text-black md:text-2xl"
        type="button"
        onClick={() => window.location.reload()}
      >
        Refresh the page
      </button>
    </div>
  );
}
