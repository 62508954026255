import PlayerBadgePlainXs from "./PlayerBadgeXs";
import { capitalize } from "../../../utils/capitalize";
import { LaPlusIcon } from "../../svg-icons";
import { cn } from "../../../utils/cn";

type PlayerCardProps = {
  id?: string;
  username?: string;
  avatar: string;
  appearance?: number;
  assist?: number;
  goals?: number;
  playerWing?: string;
  redCards?: number;
  yellowCards?: number;
  designation?: string;
  action?: string;
  handleClick?: (id?: string) => void;
  added?: boolean;
};

function PlayerCard({
  id,
  appearance,
  avatar,
  assist,
  goals,
  playerWing,
  redCards,
  username,
  yellowCards,
  action,
  designation,
  handleClick,
  added,
}: PlayerCardProps) {
  return (
    <div className="flex gap-2 rounded px-2 py-4 shadow odd:bg-grey-lighter even:bg-white">
      <div className="min-w-[70px]">
        <PlayerBadgePlainXs
          appearance={appearance}
          avatar={avatar}
          assist={assist}
          goals={goals}
          playerName={username}
          playerWing={playerWing}
          designation={designation}
        />
      </div>

      <div className="flex w-full flex-col gap-2">
        <div className="flex items-center justify-between">
          <h3 className="">{capitalize(username)}</h3>
          {action && handleClick && (
            <button
              className={cn(
                "rounded bg-[#E6EDFE] p-2 text-xs font-medium text-primary-blue-darker disabled:cursor-not-allowed disabled:opacity-50",
                {
                  "bg-grey-light text-primary-black-darker": added,
                }
              )}
              disabled={added}
              onClick={() => handleClick(id)}
            >
              <span className="flex items-center gap-1 ">
                <LaPlusIcon
                  strokeWidth={2}
                  stroke={added ? "#1a1a1a" : "#0045f6"}
                />
                {added ? "Added" : action}
              </span>
            </button>
          )}
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col items-center gap-2">
            <p className="text-xs text-grey">Goals</p>
            <p className="text-sm">{goals}</p>
          </div>
          <div className="flex flex-col items-center gap-2">
            <p className="text-xs text-grey">Assists</p>
            <p className="text-sm">{assist}</p>
          </div>
          <div className="flex flex-col items-center gap-2">
            <p className="text-xs text-grey">Red Cards</p>
            <p className="text-sm">{redCards}</p>
          </div>
          <div className="flex flex-col items-center gap-2">
            <p className="text-xs text-grey">Yellow Cards</p>
            <p className="text-sm">{yellowCards}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerCard;
