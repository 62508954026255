import React from "react";
import { LaClose, LaPlusIcon } from "../svg-icons";
import useOnClickOutside from "../../hooks/useClickedOutside";

type Props = {
  setPlayerDropDown: React.Dispatch<
    React.SetStateAction<
      | "goal-team-a"
      | "goal-team-b"
      | "own-goal-team-a"
      | "own-goal-team-b"
      | "assist-team-a"
      | "assist-team-b"
      | "red-card-team-a"
      | "red-card-team-b"
      | "yellow-card-team-a"
      | "yellow-card-team-b"
      | ""
    >
  >;
  dropDownOption:
    | "goal-team-a"
    | "goal-team-b"
    | "own-goal-team-a"
    | "own-goal-team-b"
    | "assist-team-a"
    | "assist-team-b"
    | "red-card-team-a"
    | "red-card-team-b"
    | "yellow-card-team-a"
    | "yellow-card-team-b";
  playerDropDown:
    | "goal-team-a"
    | "goal-team-b"
    | "own-goal-team-a"
    | "own-goal-team-b"
    | "assist-team-a"
    | "assist-team-b"
    | "red-card-team-a"
    | "red-card-team-b"
    | "yellow-card-team-a"
    | "yellow-card-team-b"
    | "";
  options?: TPlayer[];
  disabled?: boolean;
  handleSelection: (player: TPlayer) => void;
  handleRemoveSelection: (index: number) => void;
  handleAddNewPlayers: () => void;
};

function ScorePlayersSelect({
  setPlayerDropDown,
  dropDownOption,
  playerDropDown,
  options,
  handleSelection,
  handleRemoveSelection,
  handleAddNewPlayers,
  disabled,
}: Props) {
  const [selectedOption, setSelectedOption] = React.useState<
    TPlayer[] | undefined
  >([]);
  const insideEl = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(insideEl, () => setPlayerDropDown(""));

  function handleClick(player: TPlayer) {
    setSelectedOption((prev) => [...(prev ?? []), player]);
    setPlayerDropDown("");
    handleSelection(player);
  }

  function handleClosePill(index: number) {
    setSelectedOption((prev) => {
      const copy = [...(prev ?? [])];
      copy.splice(index, 1);
      return copy;
    });
    handleRemoveSelection(index);
  }

  return (
    <div className="relative">
      <button
        className="flex items-center gap-5 rounded border border-grey-light bg-white px-4 py-2 leading-6 text-grey disabled:cursor-not-allowed disabled:opacity-50"
        type="button"
        disabled={disabled}
        onClick={() => setPlayerDropDown(dropDownOption)}
      >
        Players
        <span className="text-3xl">
          <LaPlusIcon stroke={disabled ? "#ccc" : "#1a1a1a"} />
        </span>
      </button>
      <div className="mt-2 flex flex-col items-start gap-2">
        {selectedOption?.map((player, index) => (
          <div
            className="flex items-center gap-2 rounded bg-primary-blue-lighter p-2"
            key={index}
          >
            <p className="text-sm font-medium">{player.name}</p>
            <button
              type="button"
              className="cursor-pointer text-xs"
              onClick={() => handleClosePill(index)}
              aria-label="close-pill"
            >
              <LaClose strokeWidth={3} />
            </button>
          </div>
        ))}
      </div>
      {playerDropDown === dropDownOption && (
        <div
          className="absolute left-0 top-14 z-[90] max-h-[200px] w-[150px] overflow-y-scroll rounded border border-grey-light bg-white shadow"
          ref={insideEl}
        >
          <button
            className="flex w-full items-center gap-2 truncate p-2 text-left hover:border-l-2 hover:border-primary-black-darker hover:bg-primary-black-lighter"
            type="button"
            onClick={handleAddNewPlayers}
          >
            Add new player
            <LaPlusIcon stroke="#000" />
          </button>
          {options?.map((player, index) => (
            <button
              className="w-full truncate p-2 text-left hover:border-l-2 hover:border-primary-black-darker hover:bg-primary-black-lighter"
              key={index}
              type="button"
              onClick={() => handleClick(player)}
            >
              {player.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default ScorePlayersSelect;
