import React from "react";

const useLockBodyScroll = (isOpen: boolean) => {
  const savedOverflowStyle = React.useRef<string | null>(null);

  React.useEffect(() => {
    // Store the initial overflow style of the body element
    savedOverflowStyle.current = document.body.style.overflow;

    if (isOpen) {
      // Prevent scrolling when the modal is open
      document.body.style.overflow = "hidden";
    } else {
      // Restore the original overflow style when the modal is closed
      if (savedOverflowStyle.current !== null) {
        document.body.style.overflow = savedOverflowStyle.current;
      }
    }

    // Clean up function to ensure proper restoration when unmounting
    return () => {
      if (savedOverflowStyle.current !== null) {
        document.body.style.overflow = savedOverflowStyle.current;
      }
    };
  }, [isOpen]);
};

export default useLockBodyScroll;
