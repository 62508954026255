import { useEffect, useState } from "react";
import { cn } from "../../utils/cn";
import { Button } from "../ui";
import { LaCaretDown, LaCaretUp, LaCheck } from "../svg-icons";
import { Link } from "react-router-dom";
import SquadInfoModal from "../modals/SquadInfoModal";
import { useRemoveTeamsMutation } from "../../redux/services/tournamentService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useAppDispatch } from "../../redux/app/hooks";

interface ManageTeamsProps {
  teamData: Tournament["teams"];
  tournamentId: string;
  tournamentName: string;
  isTournamentOwner: boolean;
}

type TTabs = "all" | "registered" | "payment processing" | "invited";
type TActions = "delete teams";

export default function ManageTeams({
  teamData,
  tournamentId,
  tournamentName,
  isTournamentOwner,
}: ManageTeamsProps) {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TTabs>("all");
  const [actionsOpen, setActionsOpen] = useState(false);
  const [checked, setChecked] = useState<{
    [key: string]: boolean;
  }>({});
  const [clickedTeamData, setClickedTeamData] = useState<
    Tournament["teams"][0] | undefined
  >();
  const [removeTeams] = useRemoveTeamsMutation();
  const clickedTeam = clickedTeamData?.team as Team;
  console.log(teamData);
  const registered = teamData.filter(
    (teamData) => teamData?.registered === true
  );
  const invited = teamData.filter((teamData) => teamData?.registered === false);
  const tableData = teamData?.filter((data) => {
    const filters = {
      all: true,
      registered: data?.registered,
      "payment processing": data?.processingPayment,
      invited: !data.registered,
    };
    return filters[activeTab];
  });

  function handleDeleteTeam() {
    removeTeams({
      tournamentId,
      teams: Object.keys(checked).filter((item) => checked[item]),
    })
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  function handleClickAction(action: TActions) {
    const actions: Record<TActions, () => void> = {
      "delete teams": handleDeleteTeam,
    };

    return actions[action]();
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked: { [key: string]: boolean } = {};
    tableData.forEach((data) => {
      const team = data.team as Team;
      newChecked[team._id] = e.target.checked;
    });
    setChecked(newChecked);
  };

  // const handleTeamCheckboxChange = (e) => {
  //   const teamId = e.target.id;
  //   setChecked({
  //     ...checked,
  //     [teamId]: e.target.checked,
  //   });
  // };

  useEffect(() => {
    const newChecked: {
      [key: string]: boolean;
    } = {};
    tableData.forEach((data) => {
      const team = data.team as Team;
      newChecked[team._id] = false;
    });
    setChecked(newChecked);
  }, []);

  return (
    <>
      <div className="my-4 flex items-center gap-4 lg:mt-8">
        {isTournamentOwner && (
          <div className="relative">
            <button
              className="flex min-w-max items-center gap-2 rounded border border-primary-black-darker px-4 py-2"
              onClick={() => setActionsOpen(!actionsOpen)}
            >
              Bulk Actions {actionsOpen ? <LaCaretUp /> : <LaCaretDown />}
            </button>
            {actionsOpen && (
              <div className="absolute top-[calc(100%+8px)] w-full rounded border border-grey-light bg-white">
                {actions.map((action) => (
                  <button
                    key={action}
                    className="w-full border-primary-black-darker px-3 py-2 text-left capitalize hover:border-l-2 hover:bg-primary-black-lighter disabled:cursor-not-allowed disabled:opacity-50"
                    onClick={() => handleClickAction(action)}
                    disabled={!Object.values(checked).some((value) => value)}
                  >
                    {action}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="modern__scroll-bar flex items-center gap-4 overflow-x-scroll">
          {tabs.map((tab) => (
            <Button
              key={tab}
              intent="secondary"
              role={"organizer"}
              className={cn(
                "min-w-max rounded-full border border-grey-light py-2 capitalize hover:bg-white",
                {
                  "border-primary-black-darker": activeTab === tab,
                }
              )}
              onClick={() => setActiveTab(tab)}
            >
              {tab}{" "}
              <span className="flex h-5 w-5 items-center justify-center rounded-full bg-[#D8D8D8] text-[10px]">
                {tab === "all" && teamData.length}
                {tab === "registered" && registered.length}
                {tab === "payment processing" && 0}
                {tab === "invited" && invited.length}
              </span>
            </Button>
          ))}
        </div>
      </div>
      <div className="overflow-x-scroll">
        <div className="min-w-max rounded border border-grey-light">
          <ul className="grid w-full min-w-max grid-cols-3 bg-primary-black-lighter px-4 py-2">
            <li className="flex items-center gap-4">
              {isTournamentOwner && (
                <label htmlFor="all" className="cursor-pointer">
                  <input
                    type="checkbox"
                    name="all"
                    id="all"
                    className="peer"
                    // hidden
                    // checked={Object.values(checked).every((value) => value)}

                    // onChange={(e) => {
                    //   const newChecked: {
                    //     [key: string]: boolean;
                    //   } = {};
                    //   tableData.forEach((data) => {
                    //     const team = data.team as Team;
                    //     newChecked[team._id] = e.target.checked;
                    //   });
                    //   setChecked(newChecked);
                    // }}
                    onChange={handleSelectAllChange}
                  />
                  <LaCheck
                    className="h-5 w-5 rounded border-2 border-primary-black-darker p-[2px] text-primary-black-lighter peer-checked:text-primary-black-darker"
                    strokeWidth="3"
                  />
                </label>
              )}
              Teams
            </li>
            {tableHeadings.map((heading) => (
              <li
                className="min-w-max place-self-center text-center"
                key={heading}
              >
                {heading}
              </li>
            ))}
          </ul>
          <ul className="w-full min-w-max">
            {!tableData.length ? (
              <li className="p-4 text-center">No teams</li>
            ) : (
              tableData.map((data) => {
                const team = data.team as Team;
                return (
                  <li key={team?._id}>
                    <label
                      htmlFor={team.name}
                      className={cn(
                        "grid cursor-pointer grid-cols-3 border-b border-grey-light p-4",
                        {
                          "cursor-default": !isTournamentOwner,
                        }
                      )}
                    >
                      <div className="flex items-center gap-4">
                        {isTournamentOwner && (
                          <>
                            <input
                              type="checkbox"
                              name="all"
                              id={team?.name}
                              className="peer"
                              hidden
                              checked={checked[team._id] || false}
                              onChange={(e) =>
                                setChecked({
                                  ...checked,
                                  [team._id]: e.target.checked,
                                })
                              }
                            />
                            <LaCheck
                              className="h-5 w-5 rounded border-2 border-primary-black-darker p-[2px] text-white peer-checked:text-primary-black-darker"
                              strokeWidth="3"
                            />
                          </>
                        )}

                        <img
                          className="h-6 w-6 rounded-full bg-grey-light"
                          src={team?.avatar}
                        />
                        <div>
                          <h3 className="font-bold capitalize text-primary-black-darker">
                            {team.name}
                          </h3>
                          <button
                            className={cn(
                              "border-r border-primary-black-darker pr-2 text-xs",
                              {
                                "border-0": !isTournamentOwner,
                              }
                            )}
                            onClick={() => setClickedTeamData(data)}
                          >
                            Squad info
                          </button>
                          {isTournamentOwner && (
                            <Link
                              to={
                                data?.registered
                                  ? `/competitions/${tournamentId}/player-transfer`
                                  : "?tab=teams"
                              }
                              className="pl-2 text-xs"
                            >
                              Player transfer
                            </Link>
                          )}
                        </div>
                      </div>
                      <p className="place-self-center">
                        {data?.players?.length}
                      </p>
                      <p
                        className={cn(
                          "place-self-center rounded px-3 py-1 text-sm text-primary-black-darker",
                          {
                            "bg-green text-white": data.registered,
                            "bg-yellow text-primary-black-darker":
                              !data.registered,
                          }
                        )}
                      >
                        {data.registered ? "Registered" : "Invited"}
                      </p>
                    </label>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </div>
      {clickedTeamData && (
        <SquadInfoModal
          draws={clickedTeamData?.draws}
          losses={clickedTeamData?.losses}
          played={clickedTeamData?.played}
          setClickedTeamData={setClickedTeamData}
          teamId={clickedTeam?._id}
          teamName={clickedTeam?.name}
          tournamentId={tournamentId}
          tournamentName={tournamentName}
          wins={clickedTeamData?.wins}
        />
      )}
    </>
  );
}

const tabs: Array<TTabs> = [
  "all",
  "registered",
  "payment processing",
  "invited",
];

const actions: Array<TActions> = ["delete teams"];
const tableHeadings = ["Registered Players", "Registration Status"];
