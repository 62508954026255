import { LaArrowLeft, LaArrowRight, LaCaretRight } from "../svg-icons";
import ScrollContainer from "./ScrollContainer";
import React from "react";
import TextLink from "../ui/text/TextLink";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
type Props = {
  title: string;
  link: string;
};

export default function Discoverable({
  title,
  children,
  link,
}: React.PropsWithChildren<Props>) {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;

  const container = React.useRef<HTMLDivElement>(null);

  function scrollToRight() {
    if (container.current) {
      const element = container.current;
      const scrollAmount = 70; // Adjust the scroll amount as needed
      element.scrollBy(scrollAmount, 0);
    }
  }

  function scrollToLeft() {
    if (container.current) {
      const element = container.current;
      const scrollAmount = -70; // Adjust the scroll amount as needed
      element.scrollBy(scrollAmount, 0);
    }
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="font-medium lg:text-xl">{title}</h3>
        <div className="flex items-center gap-4">
          <TextLink
            role={role}
            to={link}
            className="flex items-center gap-2 text-sm font-medium"
          >
            View all
            <span className="lg:hidden">
              <LaCaretRight stroke="#0045F6" />
            </span>
          </TextLink>
          <div className="hidden items-center gap-2 lg:flex">
            <button
              className="flex h-8 w-8 items-center justify-center rounded-full border border-grey-light text-xl"
              onClick={() => scrollToLeft()}
              name="scroll left"
            >
              <LaArrowLeft stroke="#1a1a1a" />
            </button>
            <button
              className="flex h-8 w-8 items-center justify-center rounded-full border border-grey-light text-xl"
              onClick={() => scrollToRight()}
              name="scroll right"
            >
              <LaArrowRight stroke="#1a1a1a" />
            </button>
          </div>
        </div>
      </div>
      <ScrollContainer
        children={children}
        forwardedRef={container}
        link={link}
      />
    </>
  );
}
