import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  objectUrl: null,
};

const previewImageSlice = createSlice({
  name: "previewImage",
  initialState,
  reducers: {
    setObjectUrl(state, action) {
      state.objectUrl = action.payload;
    },
  },
});

export const { setObjectUrl } = previewImageSlice.actions;
export default previewImageSlice.reducer;
