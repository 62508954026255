function OrDivider() {
  return (
    <div className="flex w-full items-center justify-between ">
      <div className="h-[1px] w-[33%] bg-black"></div>
      <p className="text-sm">or</p>
      <div className="h-[1px] w-[33%] bg-black"></div>
    </div>
  );
}

export default OrDivider;
