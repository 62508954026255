import { useEffect, type ReactElement, useRef, useState } from "react";
import { Button } from "../ui";
import { LaClose, LaPlusIcon } from "../svg-icons";
import { useAppDispatch } from "../../redux/app/hooks";
import { useAddTeamsMutation } from "../../redux/services/tournamentService";
import {
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import LaSpinner from "../svg-icons/LaSpinner";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { ConfirmPopup } from "../modals";

export interface AddTeamsToCompetitionProps {
  selectedTeams: string[];
  shouldScroll?: boolean;
  selectedCompetition: string;
  setSelectedTeams: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCompetition: React.Dispatch<React.SetStateAction<string>>;
  setCompetitionTeams: React.Dispatch<
    React.SetStateAction<Tournament["teams"]>
  >;
  maxTeam: number;
  noOfTeamsJoined: number;
}

export default function AddTeamsToCompetition({
  selectedTeams,
  setSelectedTeams,
  setSelectedCompetition,
  setCompetitionTeams,
  shouldScroll = false,
  selectedCompetition,
  maxTeam,
  noOfTeamsJoined,
}: AddTeamsToCompetitionProps): ReactElement {
  const [modalToDisplay, setModalToDisplay] = useState<
    "incomplete" | "success" | ""
  >("");
  const dispatch = useAppDispatch();
  const [addTeams, { isLoading }] = useAddTeamsMutation();
  const navigate = useNavigate();
  const insideEl = useRef(null);

  function handleClick() {
    if (noOfTeamsJoined + selectedTeams.length < maxTeam)
      return setModalToDisplay("incomplete");
    return handleAddTeams();
  }

  function handleAddTeams() {
    addTeams({
      teams: selectedTeams,
      tournamentId: selectedCompetition,
    })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setModalToDisplay("success");
        // setSelectedCompetition("none");
        // setSelectedTeams([]);
        // if (selectedCompetition) {
        //   navigate(`/competitions/${selectedCompetition}`);
        // }
      })
      .catch((err) => {
        dispatch(setToastErrorText(err.data.message));
      });
  }

  function handleClose() {
    setSelectedTeams([]);
    setSelectedCompetition("none");
  }
  useOnClickOutside(insideEl, handleClose);

  useEffect(() => {
    shouldScroll &&
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
  }, []);

  return (
    <>
      <div className="fixed inset-0 left-auto top-auto z-[90] flex w-full flex-col  rounded border-t border-grey-light bg-white px-4 py-6 shadow lg:w-[82%] lg:rounded-[16px_16px_0_0] lg:px-20 lg:py-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <h3 className="flex items-center gap-2 font-medium lg:text-2xl">
              Add
              <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-blue-lighter text-xs">
                {selectedTeams.length}
              </span>
              teams
            </h3>
          </div>
          <button
            onClick={() => {
              setSelectedTeams([]);
              setCompetitionTeams([]);
              setSelectedCompetition("none");
            }}
          >
            <LaClose />
          </button>
        </div>
        <Button
          role="organizer"
          intent={"primary"}
          className="mt-4 items-center gap-1 lg:flex"
          onClick={handleClick}
          disabled={isLoading}
        >
          Send Invitation
          {isLoading && <LaSpinner className="animate-spin" />}
        </Button>
      </div>
      {modalToDisplay === "incomplete" && (
        <ConfirmPopup
          heading={"Incomplete team"}
          type="warning"
          description={`${
            noOfTeamsJoined + selectedTeams.length
          } out of ${maxTeam} remaining teams will be added. Do you want to proceed?`}
          warning="Teams must be complete to generate fixtures."
          buttons={[
            {
              text: "Add more teams",
              handleClick: () => setModalToDisplay(""),
              class: "primary",
              icon: <LaPlusIcon stroke="#fff" />,
            },
            {
              text: "Yes, I want to proceed",
              handleClick: () => {
                setModalToDisplay("");
                handleAddTeams();
              },
              class: "tertiary",
            },
          ]}
        />
      )}
      {modalToDisplay === "success" && (
        <ConfirmPopup
          heading={"Success!"}
          type="success"
          description={`Your Teams profiles are ready. They will be notified via contact number you provided.`}
          buttons={[
            {
              text: "Got it",
              handleClick: () => {
                setSelectedCompetition("none");
                setSelectedTeams([]);
                navigate(`/competitions/${selectedCompetition}`);
              },
              class: "primary",
            },
          ]}
        />
      )}
    </>
  );
}
