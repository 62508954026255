function LaArrowLeft({
  stroke = "#1A1A1A",
  size = "sm",
}: {
  stroke?: string;
  size?: "sm" | "md";
}) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      className={`${size === "md" && "h-8 w-8"}`}
    >
      <path
        d="M6.38 3.953L2.333 8l4.047 4.047M13.667 8H2.447"
        stroke={stroke}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaArrowLeft;
