import { css, styled } from "styled-components";

// className={`flex cursor-pointer bg-primary-blue-lighter p-1 ${
//   !toggled && "flex-row-reverse"
// } items-center justify-between rounded-full`}

const ToggleButton = styled.button<{
  $userRole: string;
  $toggled: boolean;
}>`
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  background: #ccdafd;
  padding: 0.25rem;
  border-radius: 9999px;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.$userRole === "organizer" &&
    css`
      background: #e6e6e6;
    `};

  ${(props) =>
    props.$toggled &&
    css`
      flex-direction: row;
    `}
`;

// className={`${
//   toggled ? "bg-primary-blue-dark" : "bg-grey"
// } aspect-square h-[30px] w-[30px] rounded-full`}

const Toggle = styled.span<{ $userRole: string; $toggled: boolean }>`
  height: 30px;
  width: 30px;
  border-radius: 9999px;
  aspect-ratio: 1 / 1;
  background: #0045f6;

  ${(props) =>
    props.$userRole === "organizer" &&
    css`
      background: #1a1a1a;
    `};

  ${(props) =>
    props.$toggled &&
    css`
      ${props.$userRole === "organizer"
        ? `
          background: #1a1a1a;
        `
        : `
          background: #0045F6;
        `}
    `};
`;

export { ToggleButton, Toggle };
