import type { ReactElement } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/app/store";
import {
  useGetMyOrganizerQuery,
  useUpdateOrganizerMutation,
} from "../../redux/services/organizerService";
import React from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { LaGalleryEdit } from "../svg-icons";
import { cn } from "../../utils/cn";
import { Button, Input, RegisteredSelect, Spinner, Textarea } from "../ui";

const EditOrganizerFormSchema = z.object({
  name: z.string().min(3, { message: "Organization name is required" }),
  bio: z.string().max(250).optional(),
  location: z.string().min(3, { message: "Location is required" }),
  avatar: z.any(),
});

export type TEditOrganizerForm = z.infer<typeof EditOrganizerFormSchema> & {
  avatar: FileList;
};

interface IEditOrganizerFormProps {}

export default function ({}: IEditOrganizerFormProps): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: myOrganizer, isLoading: isOrganizerLoading } =
    useGetMyOrganizerQuery();

  const role = useAppSelector(
    (state: RootState) => state.auth.role
  ).toLowerCase() as TRole;
  const [previewAvatar, setPreviewAvatar] = React.useState(myOrganizer?.avatar);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TEditOrganizerForm>({
    resolver: zodResolver(EditOrganizerFormSchema),
  });
  const watchAvatar = watch("avatar");
  const watchLocation = watch("location");
  const [updateOrganizer, { isLoading }] = useUpdateOrganizerMutation();

  const onSubmit = (data: TEditOrganizerForm) => {
    const form = new FormData();
    form.append("name", data.name);
    form.append("location", data.location);
    data.bio && form.append("bio", data.bio);
    data.avatar[0] && form.append("avatar", data.avatar[0]);

    updateOrganizer(form)
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        navigate(`/profiles/organizer/${data.name}`);
      })
      .catch((err: any) => {
        if (err?.data?.message?.includes("authorized")) {
          return dispatch(setToastErrorText("Switch role to edit profile"));
        }
        dispatch(setToastError(true));
      });
  };

  React.useEffect(() => {
    if (watchAvatar?.length) {
      const reader = new FileReader();
      reader.readAsDataURL(watchAvatar?.[0]);
      reader.onloadend = () => {
        setPreviewAvatar(reader?.result as string);
      };
    }
  });

  return (
    <div className="mt-6 flex flex-col items-center justify-center">
      {isOrganizerLoading ? (
        <Spinner />
      ) : (
        <div className="flex w-full flex-col gap-2 lg:items-center">
          <h3 className="lg:hidden">Profile photo</h3>
          <form
            className="flex flex-col gap-6 lg:w-[75%] lg:flex-row lg:gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <fieldset>
              <label
                htmlFor="avatar"
                className="relative flex w-[75px] cursor-pointer items-center justify-center"
              >
                <input
                  type="file"
                  id="avatar"
                  className="hidden"
                  {...register("avatar")}
                  accept="image/*"
                  aria-label="file-input"
                />
                <div className="absolute z-50 flex flex-col items-center justify-center">
                  <span className="text-2xl">
                    <LaGalleryEdit
                      stroke={errors.avatar ? "#E71D36" : "#fff"}
                    />
                  </span>
                  <p
                    className={cn("text-[0.75rem] text-white", {
                      "text-red": errors.avatar,
                    })}
                  >
                    Add Photo
                  </p>
                </div>
                <img
                  src={previewAvatar || myOrganizer?.avatar}
                  alt="avatar"
                  className="h-[75px] w-[75px] rounded-full"
                />
                <div className="absolute h-[75px] w-[75px] rounded-full bg-[#00000050]"></div>
              </label>
              {errors.avatar && (
                <p className="mt-2 text-sm text-red">
                  {errors.avatar.message as string}
                </p>
              )}
            </fieldset>

            <fieldset className="flex w-full flex-col gap-6">
              <fieldset className="flex flex-col gap-2">
                <label htmlFor="name" className="text-sm md:text-base">
                  Name of Organization<span className="text-red">*</span>
                </label>
                <Input
                  register={register}
                  role={role}
                  name="name"
                  placeholder="Enter the name of your organization"
                  defaultValue={myOrganizer?.name}
                />

                {errors.name && (
                  <span className="mt-2 text-sm italic text-red">
                    {errors.name?.message}
                  </span>
                )}
              </fieldset>

              <fieldset className="flex w-full flex-col items-stretch gap-2">
                <label htmlFor="location" className="text-sm md:text-base">
                  Location
                  <span className="text-red"> *</span>
                </label>
                <RegisteredSelect
                  placeholder="Select a location"
                  name="location"
                  id="location"
                  register={register}
                  setValue={setValue}
                  value={watchLocation}
                  error={errors.location?.message}
                  fit={false}
                  required="Please select a location"
                />
                {errors.location && (
                  <span className="text-xs italic text-red">
                    {errors.location.message}
                  </span>
                )}
              </fieldset>

              <fieldset>
                <label htmlFor="bio" className="text-sm md:text-base">
                  Bio <span className="text-yellow">(Optional)</span>
                </label>
                <Textarea
                  role={role}
                  register={register}
                  name="bio"
                  placeholder="Write anything..."
                  defaultValue={myOrganizer?.bio}
                />
                {errors.bio && (
                  <span className="mt-2 text-sm italic text-red">
                    {errors.bio?.message}
                  </span>
                )}
              </fieldset>

              <Button
                role={role}
                intent="primary"
                className="flex items-center justify-center rounded  p-4 text-base md:mt-6 md:text-2xl"
              >
                {isLoading ? (
                  <span className="animate-spin">
                    <Spinner />
                  </span>
                ) : (
                  "Update Profile"
                )}
              </Button>
            </fieldset>
          </form>
        </div>
      )}
    </div>
  );
}
