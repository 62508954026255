import * as React from "react";

function LaSoccerBall(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 7" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_7589_28640)">
        <path
          d="M3.88 2.46l.982.713m-.982-.714l-.982.714m.982-.714v-.774m.982 1.488l-.375 1.154H3.273m1.59-1.154l.695-.327m-2.66.327l.375 1.154m-.375-1.154l-.696-.327M3.88 1.685L3.01 1.06m.871.624l.872-.624m.806 1.785l.89.904m-.89-.904l.258-1.062M3.273 4.327l-.425.585m1.64-.585l.425.585m0 0l1.203-.13m-1.203.13l-.646 1.132m-1.42-1.132l-1.203-.13m1.204.13l.645 1.132m-1.29-3.198l-.891.904m.89-.904l-.258-1.062m4.518 1.708a2.581 2.581 0 11-5.163 0 2.581 2.581 0 015.163 0z"
          stroke="#49586A"
          strokeWidth={0.465}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_7589_28640">
          <path
            fill="#fff"
            transform="translate(.782 .393)"
            d="M0 0h6.196v6.196H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LaSoccerBall;
