import { useState } from "react";
import { cn } from "../../utils/cn";
import { ReButton as Button } from "../ui/buttons/ReButton";
import { TeamSelect } from "./team-select";

interface TabHeadingProps {
  title: string;
  description: string;
  tabs: string[];
}

export default function TabHeading({
  title,
  description,
  tabs,
}: TabHeadingProps) {
  const [indexTab, setIndexTab] = useState(0);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <h2 className="text-2xl font-semibold lg:text-xl">{title}</h2>
          <p className="text-xs text-grey lg:text-sm">{description}</p>
        </div>
        <TeamSelect options={[{ label: "CMT", value: "CMT" }]} />
      </div>
      <div className="mt-4 flex items-center">
        {tabs.map((tab) => (
          <Button
            key={tab}
            variant={"outline"}
            className={cn(
              "rounded-none border-0 border-primary-blue-darker text-primary-blue-darker",
              {
                "border-b-2": tabs.indexOf(tab) === indexTab,
              }
            )}
            onClick={() => setIndexTab(tabs.indexOf(tab))}
          >
            {tab}
          </Button>
        ))}
      </div>
    </>
  );
}
