import { useEffect, useState } from "react";
import banner from "../../assets/images/default-tournament-banner.png";
import { LaGalleryEdit } from "../svg-icons";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { cn } from "../../utils/cn";
import { EditTournamentFormValues } from "../../pages/tournament/EditTournament";

interface TournamentBannerProps {
  register: UseFormRegister<any>;
  watchBanner?: FileList;
  // watchAvatar?: FileList;
  errors: FieldErrors<EditTournamentFormValues>;
}

function TournamentBanner({
  register,
  // watchAvatar,
  watchBanner,
  errors,
}: TournamentBannerProps) {
  const [previewBanner, setPreviewBanner] = useState("");
  // const [previewAvatar, setPreviewAvatar] = useState("");

  useEffect(() => {
    if (watchBanner?.length) {
      const reader = new FileReader();
      reader.readAsDataURL(watchBanner?.[0]);
      reader.onloadend = () => {
        setPreviewBanner(reader?.result as React.SetStateAction<string>);
      };
    }
    // if (watchAvatar?.length) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(watchAvatar?.[0]);
    //   reader.onloadend = () => {
    //     setPreviewAvatar(reader?.result as React.SetStateAction<string>);
    //   };
    // }
  });

  return (
    <form
      className="mb-[49.01px] h-[11.25rem] w-full md:h-[344px]"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="relative h-full w-full">
        <img
          src={previewBanner || banner}
          alt="tournament banner"
          className="h-full w-full object-cover"
        />
        <div
          className={` absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-[#0000004d] `}
        >
          <label
            htmlFor="banner"
            className="flex cursor-pointer flex-col items-center justify-center gap-y-1"
          >
            <span className="text-3xl">
              <LaGalleryEdit stroke={errors.banner ? "#E71D36" : "#fff"} />
            </span>{" "}
            <input
              type="file"
              className="hidden"
              {...register("banner", { required: "please add a banner" })}
              id="banner"
            />
            <p
              className={cn("text-white", {
                "text-red": errors.banner,
              })}
            >
              Add Banner
            </p>
          </label>

          {/* <img
            src={previewAvatar}
            alt="avatar"
            className="absolute bottom-[7%] left-[10%] z-20 flex h-[5rem] w-[5rem] flex-col items-center justify-center rounded-full object-cover"
          />

          <div
            className={cn(
              "absolute bottom-[7%] left-[10%] z-20 flex h-[5rem]  w-[5rem] items-center justify-center rounded-full border-2 border-white bg-[#0000004d]",
              {
                "border-red": errors.avatar,
              }
            )}
          >
            <label
              htmlFor="avatar"
              className="flex cursor-pointer flex-col items-center justify-center"
            >
              <span className="text-2xl">
                <LaGalleryEdit stroke={errors.avatar ? "#E71D36" : "#fff"} />
              </span>{" "}
              <input
                type="file"
                className="hidden"
                {...register("avatar", {
                  required: "please add an avatar",
                })}
                id="avatar"
              />
              <span
                className={cn("text-[0.75rem] text-white", {
                  "text-red": errors.avatar,
                })}
              >
                Add Photo
              </span>
            </label>
          </div> */}
        </div>
      </div>
    </form>
  );
}

export default TournamentBanner;
