import { useState } from "react";
import { LaClose, LaDelete, LaEditPencil, LaPlusIcon } from "../svg-icons";
import { Button, Input } from "../ui";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { cn } from "../../utils/cn";
import { useCreatePlayersMutation } from "../../redux/services/playerService";
import RegisteredPhoneInput from "../ui/input/RegisteredPhoneInput";

const newPlayerSchema = z.object({
  name: z
    .string()
    .min(3, "name is too short")
    .max(50, "name is too long")
    .refine((name) => {
      return !name.includes(" ");
    }, "name should not contain space"),
  phoneNumber: z
    .string()
    .regex(/^[0-9]+$/, "Must be only digits")
    .max(11, "phone number is too long")
    .min(11, "phone number is too short"),
  phonecode: z.string().min(2, "phone code is too short"),
});

export type NewPlayerForm = z.infer<typeof newPlayerSchema>;

interface AddNewPlayersModalProps {
  setAddNewPlayersModal: React.Dispatch<React.SetStateAction<boolean>>;
  teamId?: string;
  tournamentId?: string;
}

export default function AddNewPlayersModal({
  setAddNewPlayersModal,
  teamId,
  tournamentId,
}: AddNewPlayersModalProps) {
  const dispatch = useAppDispatch();
  const [players, setPlayers] = useState<NewPlayerForm[]>([]);
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<NewPlayerForm>({
    resolver: zodResolver(newPlayerSchema),
  });
  const watchName = watch("name");
  const watchPhoneNumber = watch("phoneNumber");
  const watchPhonecode = watch("phonecode");

  const [createPlayers] = useCreatePlayersMutation();

  const handleDelete = (name: string) => {
    setPlayers((prev) => prev.filter((player) => player.name !== name));
  };

  const handleEdit = (name: string) => {
    setPlayers((prev) => prev.filter((player) => player.name !== name));
    reset({
      name: players.find((player) => player.name === name)?.name ?? "",
      phoneNumber:
        players.find((player) => player.name === name)?.phoneNumber ?? "",
      phonecode:
        players.find((player) => player.name === name)?.phonecode ?? "",
    });
  };

  const onSubmit = (data: NewPlayerForm) => {
    setPlayers((prev) => [...prev, data]);
  };

  const handleCreatePlayers = () => {
    if (!teamId || !tournamentId) {
      dispatch(setToastErrorText("Invalid team or tournament"));
      return;
    }
    createPlayers({
      players,
      teamId,
      paymentStatus: "pending",
      tournamentId,
    })
      .unwrap()
      .then(() => {
        setPlayers([]);
        dispatch(setToastSuccess(true));
        setAddNewPlayersModal(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  };

  return (
    <div className="fixed bottom-0 right-0 z-[80] flex w-full flex-col rounded border-t border-grey-light bg-white px-4 py-4 shadow lg:w-[82%] lg:rounded-2xl lg:px-20 lg:py-10">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h3 className="font-medium lg:text-2xl">Add new players</h3>
          <p className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-blue-lighter text-xs">
            {players.length}
          </p>
        </div>
        <button
          onClick={() => {
            setAddNewPlayersModal(false);
          }}
        >
          <LaClose />
        </button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <form
          action=""
          className="mt-6 flex flex-col gap-2  md:w-[71%] lg:w-[60%]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset>
            <label htmlFor="name" className="text-sm md:text-base">
              Player Name<span className="text-red">*</span>
            </label>
            <Input
              role="organizer"
              type="text"
              id="name"
              register={register}
              name={"name"}
              placeholder="Enter player name"
              required
              className={cn(
                "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-opacity-30 lg:text-xl",
                {
                  "error border-red focus:border-red": errors.name,
                }
              )}
            />
            {errors.name && (
              <span className="mt-2 text-sm italic text-red">
                {errors.name.message}
              </span>
            )}
          </fieldset>

          <fieldset>
            <label htmlFor="phone" className="text-sm md:text-base">
              Player’s phone number <span className="text-red">*</span>
            </label>
            <RegisteredPhoneInput
              name="phoneNumber"
              error={errors.phoneNumber?.message}
              phonecodeName={"phonecode"}
              register={register}
              required="Phone number is required"
              setValue={setValue}
              id="phone-number"
            />

            {errors.phoneNumber && (
              <span className="mt-2 text-sm italic text-red">
                {errors.phoneNumber.message}
              </span>
            )}
          </fieldset>
          <fieldset className="mt-2 flex items-center justify-between">
            <button
              className="flex items-center gap-2 text-primary-blue-darker disabled:cursor-not-allowed disabled:opacity-50"
              disabled={players.some(
                (player) =>
                  player.name === watchName ||
                  `${player.phonecode}${player.phoneNumber}` ===
                    `${watchPhonecode}${watchPhoneNumber}`
              )}
            >
              <LaPlusIcon />
              Add
              {players.length > 0 && " another player"}
            </button>
          </fieldset>
          <fieldset className="flex items-center justify-end">
            <Button
              role="organizer"
              intent="primary"
              className="min-w-[150px]"
              type="button"
              onClick={handleCreatePlayers}
              disabled={players.length === 0}
            >
              Save
            </Button>
          </fieldset>
        </form>
        <div className="mt-6 flex max-h-[250px] w-full  flex-col gap-2 overflow-y-scroll md:w-[71%] lg:w-[60%]">
          {players.map((player) => {
            return (
              <div
                className="flex items-center justify-between rounded bg-primary-black-lighter p-4"
                key={player.name}
              >
                <div className="text-sm">
                  <p className="font-bold">{player.name}</p>
                  <p>
                    {player.phonecode} {player.phoneNumber}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <button onClick={() => handleEdit(player.name)} type="button">
                    <LaEditPencil />
                  </button>
                  <button
                    onClick={() => handleDelete(player.name)}
                    type="button"
                  >
                    <LaDelete />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
