import { leaguesArenaApi } from "./leaguesArenaApiSlice";

const trainingApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    createTraining: builder.mutation({
      query: (body) => ({
        url: `/training/session`,
        method: "POST",
        body,
      }),
    }),
    joinTraining: builder.mutation({
      query: (body) => ({
        url: `/training/session`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Training"],
    }),
    checkInPlayer: builder.mutation({
      query: (body) => ({
        url: `/training/session`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Training"],
    }),
    getTraining: builder.query<TTraining | undefined, { trainingId?: string }>({
      query: ({ trainingId }) => ({
        url: `/training/${trainingId}`,
        method: "GET",
      }),
      providesTags: ["Training"],
      transformResponse: (response: { training: TTraining }) =>
        response.training,
    }),

    getPlayerTrainings: builder.query<
      TTraining[] | undefined,
      { userId: string }
    >({
      query: ({ userId }) => ({
        url: `/training/p/${userId}`,
        method: "GET",
      }),
      providesTags: ["Training"],
      transformResponse: (response: { trainings: TTraining[] }) =>
        response.trainings,
    }),
    getDateTrainings: builder.query<TTraining[] | undefined, { date: string }>({
      query: ({ date }) => ({
        url: `/training/date/${date}`,
        method: "GET",
      }),
      providesTags: ["Training"],
      transformResponse: (response: { trainings: TTraining[] }) =>
        response.trainings,
    }),
    getTopTrainingSessions: builder.query<TTraining[] | undefined, void>({
      query: () => ({
        url: `/training/top-sessions`,
        method: "GET",
      }),
      providesTags: ["Training"],
      transformResponse: (response: { data: TTraining[] }) => response.data,
    }),
    getTrainingSessions: builder.query({
      query: () => ({
        url: `/training/training-session`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useCreateTrainingMutation,
  useJoinTrainingMutation,
  useGetPlayerTrainingsQuery,
  useGetTrainingQuery,
  useGetDateTrainingsQuery,
  useGetTopTrainingSessionsQuery,
  useCheckInPlayerMutation,
  useGetTrainingSessionsQuery,
} = trainingApi;
