import { Props } from "./new-landing-page/trainee-badge";

export default function TraineeBadge(props: Props) {
  return (
    <>
      <section
        className="relative overflow-hidden transition-all duration-500 xs:h-[150px] xs:w-[80px] lg:h-[211px] lg:w-[143px]"
        id="playerbadge"
      >
        <img
          src={props.avatar}
          className="absolute left-0 top-0 -z-10 h-[40%] w-full bg-gray-500 object-cover"
          width={143}
          height={143}
          alt="player badge"
        />
        <section
          className={`flex h-full flex-col items-center justify-center px-1 text-white lg:py-[34px] ${props.badgeStyle}`}
        >
          <div className="relative flex w-full items-center justify-center">
            <img
              src={props.avatar}
              crossOrigin="anonymous"
              className="h-5 w-5 overflow-hidden rounded-full border border-white bg-gray-500 object-contain lg:h-[39.76px] lg:w-[39.76px]"
              width={40}
              height={40}
              alt="player avatar"
            />
            <span className="absolute right-[10%] top-1/2 -translate-y-1/2 text-center text-[8px] font-medium uppercase text-white lg:text-base">
              {props.playerWing}
            </span>
          </div>
          <h3 className="text-center text-[10px] font-medium capitalize lg:text-base">
            {props.playerName}
          </h3>
          <div className="h-[1px] w-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent lg:mb-1" />
          <section className="flex w-full items-start justify-center gap-[2px] text-xs">
            <section className="flex flex-1 flex-col items-center gap-[2px] text-center lg:gap-1">
              <div className="flex flex-col">
                <p className="xs:text-[8px] lg:text-base">Goals</p>
                <p className="xs:text-[10px] lg:text-base">{props.goals}</p>
              </div>
              <div className="flex flex-col">
                <p className="xs:text-[8px] lg:text-base">Games</p>
                <p className="xs:text-[10px] lg:text-base">{props.games}</p>
              </div>
            </section>
            <div className="h-full w-[1px] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent" />
            <section className="flex flex-1 flex-col items-center gap-1 text-center">
              <div className="flex flex-col">
                <p className="xs:text-[8px] lg:text-base">Assists</p>
                <p className="xs:text-[10px] lg:text-base">{props.assists}</p>
              </div>
            </section>
          </section>
        </section>
        {/* Strips */}
        <img
          src="/assets/badge-strip.png"
          className="absolute bottom-2 right-0 lg:bottom-6"
          width={47}
          height={55}
          alt=""
        />
        <img
          src="/assets/badge-strip.png"
          className="absolute bottom-0 right-0 lg:bottom-9"
          width={47}
          height={55}
          alt=""
        />
        <img
          src="/assets/badge-strip.png"
          className="absolute -bottom-2 right-0 lg:bottom-12"
          width={47}
          height={55}
          alt=""
        />
        <div
          className={`h-6 w-6 rounded-full lg:h-9 lg:w-9 ${props.badgeStyle} absolute bottom-8 right-2 flex items-center justify-center lg:bottom-8 lg:right-6`}
        >
          <img
            src={props.star}
            className="h-4 w-4 lg:h-5 lg:w-5"
            width={47}
            height={55}
            alt=""
          />
        </div>
        <div className="absolute top-0 -z-[1] h-full w-full bg-[#00000070]" />
      </section>
    </>
  );
}
