function LaCaretRight({ stroke = "#1a1a1a" }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 14" fill="none">
      <path
        d="M.94 12.28l4.347-4.347a1.324 1.324 0 000-1.867L.94 1.72"
        stroke={stroke}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaCaretRight;
