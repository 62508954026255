import TopPlayers from "./top-players";
import Leaderboard from "./leader-board";
import { useEffect, useState } from "react";
import { SessionStat, Trainee, TrainingSession } from "../../@types/training";

const trainingApiUrl = import.meta.env.VITE_LEAGUES_TRAINING_API_URL as string;
function LeaguesArenaTraining() {
  const [data, setData] = useState<
    (SessionStat & { trainee: Trainee; Session: TrainingSession })[]
  >([]);
  useEffect(() => {
    // repplace with leaguesTrainingApi env variable
    fetch(`${trainingApiUrl}/api`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((res: typeof data) => setData(res))
      .catch((err) => console.error("Training Error:", err));
  }, []);
  console.log({ trainingData: data });
  return (
    <div className="flex flex-col gap-6 px-4 py-6 md:px-10 lg:flex-row lg:gap-10 lg:px-20 lg:py-20">
      <TopPlayers data={data} />
      <Leaderboard data={data} />
    </div>
  );
}

export default LeaguesArenaTraining;
