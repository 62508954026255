import React, { useState } from "react";
import faq from "../../assets/data/faq";
import { LaCaretDown, LaCaretRight, LaCaretUp } from "../svg-icons";

function frequentlyAskedQuestions() {
  const [faqIndex, setFaqIndex] = useState(0);

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const { index } = e.currentTarget.dataset;
    if (index) setFaqIndex(parseInt(index));
  }

  const faqList = faq.map((faq, index) => (
    <div
      className={`flex cursor-pointer items-center justify-between bg-[#fff] px-4 py-6 shadow-[0__0__1px__1px__rgba(0,0,0,0.15)] ${faqIndex === index ? "bg-[#F9F9F9]" : "bg-[#fff]"
        } hover:bg-[#f9f9f9]`}
      key={index}
      onClick={handleClick}
      role="button"
      data-index={index}
    >
      <div className="flex items-center gap-4">
        <div
          className={`h-[24px] w-[24px] rounded-full ${faqIndex === index ? "bg-[#0045F6]" : "bg-[#AAC1FC]"
            }`}
        />
        <li className="md:max-w-[275px] text-xl lg:max-w-max">
          {" "}
          {faq.question}
        </li>
      </div>

      <LaCaretRight stroke={faqIndex === index ? "#0045F6" : "#AAC1FC"} />
    </div>
  ));

  const faqListMobile = faq.map((faq, index) => (
    <div key={index}>
      <div
        className={`flex cursor-pointer items-center justify-between bg-[#fff] py-3 pl-2 pr-3 shadow-[0__0__1px__1px__rgba(0,0,0,0.15)] ${faqIndex === index ? "bg-[#F9F9F9]" : "bg-[#fff]"
          } hover:bg-[#f9f9f9]`}
        onClick={handleClick}
        data-index={index}
      >
        <div className="flex items-center gap-2">
          <div
            className={`h-[24px] w-[24px] rounded-full ${faqIndex === index ? "bg-[#0045F6]" : "bg-[#AAC1FC]"
              }`}
          />
          <p className="text-base">{faq.question}</p>
        </div>
        {faqIndex === index ? (
          <LaCaretUp stroke="#0045F6" />
        ) : (
          <LaCaretDown stroke="#AAC1FC" />
        )}
      </div>
      <div
        className={`bg-[#CCDAFD66]  px-2 ${faqIndex !== index ? "h-0 py-0" : "py-3"
          } overflow-hidden`}
      >
        <p className="text-base">{faq.answer}</p>
      </div>
    </div>
  ));

  return (
    <div className="mt-14 w-full px-4 md:mt-20 md:px-8 lg:px-20">
      <h3 className="lg:text-[28px] text-center text-2xl lg:text-left">
        Frequently Asked Questions
      </h3>
      <div className="mt-4 hidden md:mt-8 md:flex">
        <div className="left md:w-[20%] lg:w-1/2 relative flex items-center">
          <ul className="lg:w-[100%] absolute w-[375px] lg:-right-12">
            {faqList}
          </ul>
        </div>
        <div className="right md:w-[80%] lg:h-[480px] lg:w-1/2 flex h-[560px] justify-end rounded-2xl bg-[#CCDAFD66] p-8">
          <p className="lg:w-[85%] w-[320px] text-base">
            {faq[faqIndex].answer}
          </p>
        </div>
      </div>

      <div className="mt-4 md:hidden">
        <div className="dropdown">
          <div>{faqListMobile}</div>
        </div>
      </div>
    </div>
  );
}

export default frequentlyAskedQuestions;
