import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const userApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizer: builder.query<
      TOrganizer | undefined,
      { name: string | undefined }
    >({
      query: ({ name }) => ({
        url: `/organizers/${name}`,
      }),
      transformResponse: (response: { data: TOrganizer }) => response.data,
      providesTags: ["Organizer"],
    }),

    getMyOrganizer: builder.query<TOrganizer | undefined, void>({
      query: () => ({
        url: `/organizers/me`,
      }),
      transformResponse: (response: { data: TOrganizer }) => response.data,
      providesTags: ["Organizer"],
    }),

    updateOrganizer: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: "/organizers",
        body,
      }),
      invalidatesTags: ["Organizer", "User"],
    }),
  }),
});

export const {
  useGetMyOrganizerQuery,
  useUpdateOrganizerMutation,
  useGetOrganizerQuery,
} = userApi;
