/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import { Button } from "../../components/ui";
import { cn } from "../../utils/cn";
import { LaArrowLeft, LaEditPencil, LaCaretDown } from "../../components/svg-icons";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { useSubmitGroupFixtureMutation } from "../../redux/services/tournamentService";
import LaSpinner from "../../components/svg-icons/LaSpinner";
import { alphabets } from "../../utils/groupStage";
import { useAppDispatch } from "../../redux/app/hooks";
import { setToastErrorText } from "../../redux/features/modal/displayModalSlice";
import HelmetComponent from "../../components/HelmetComponent";

export default function PublishGroupTable() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const { tournamentId } = useParams();
  const { data, isLoading } = useGetTournamentQuery({ id: tournamentId as string });
  const [assignedTeams, setAssignedTeams] = React.useState<unknown[]>([]);
  const [availableTeams, setAvailableTeams] = React.useState<unknown[]>([]);
  const [submitFixtureFn, { isLoading: submitting }] = useSubmitGroupFixtureMutation();
  const registedTeams = data?.teams?.filter((team) => team?.registered);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Publish Group Table",
    description: "Publish the group table for the tournament",
    url: `https://${window.location.host}/competitions/${tournamentId}/publish-group`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  React.useEffect(() => {
    if (!isLoading && registedTeams?.length) {
      setAvailableTeams(registedTeams);
    }
  }, [isLoading, data]);

  async function handleSubmit() {
    const fixture: Record<string, string> = {};
    const teamCount = assignedTeams
      .map((team: any) => team.groupIndex)
      .reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {} as Record<number, number>) as Record<number, number>;
    const isValidGrouping = Object.values(teamCount).every((count) => count > 1);

    if (!isValidGrouping) {
      dispatch(setToastErrorText("Cannot have a group with less than 2 teams"));
      return;
    }

    assignedTeams?.forEach((team: any) => {
      const groupIdx = team.groupIndex;
      const selectIdx = team.selectIndex;
      const teamId = team.team?._id;
      const teamName = team.team?.name;

      fixture[`${groupIdx}_${selectIdx}`] = JSON.stringify({
        id: teamId,
        name: teamName,
      });
    });

    submitFixtureFn({ fixture, tournamentId: tournamentId! })
      .unwrap()
      .then(() => {
        // console.log(d);
        navigateTo(`/competitions/${tournamentId}?tab=matches&subTab=group`, {
          replace: true,
        });
      })
      .catch((err) => {
        console.log("error");
        console.log(err);
      });
  }

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <section className="relative flex min-h-screen flex-col p-3">
        <div className="flex w-full items-center justify-start gap-4 pb-3">
          <button>
            <LaArrowLeft />
          </button>
          <h5 className="text-lg font-semibold">Group Stage Setup</h5>
        </div>

        <section className="flex-1">
          <div className="flex min-h-[200px] flex-wrap items-stretch justify-center gap-6">
            {isLoading && <p>Loading data.....</p>}
            {!isLoading && data?.groupSettings && (
              <>
                {Array(data?.groupSettings?.numbGroup)
                  .fill(null)
                  .map((_, idx) => (
                    <GroupTable
                      key={idx}
                      assignedTeams={assignedTeams}
                      availableTeams={availableTeams}
                      setAssignedTeams={setAssignedTeams}
                      setAvailableTeams={setAvailableTeams}
                      teamPerGroup={data?.groupSettings?.teamPerGroup ?? 0}
                      groupIdx={idx}
                    />
                  ))}
              </>
            )}
          </div>
        </section>

        <footer className="mb-20 mt-3 flex justify-end lg:mb-0">
          <Button
            disabled={submitting}
            type="button"
            onClick={handleSubmit}
            role={"organizer"}
            intent={"primary"}
            className={cn(
              "flex w-full min-w-[200px] items-center justify-center rounded p-3 text-base text-white md:w-auto md:text-xl"
            )}
          >
            {submitting ? <LaSpinner className="animate-spin" /> : "Publish Fixture"}
          </Button>
        </footer>
      </section>
    </>
  );
}

function GroupTable({
  groupIdx,
  teamPerGroup,
  assignedTeams,
  availableTeams,
  setAssignedTeams,
  setAvailableTeams,
}: {
  teamPerGroup: number;
  groupIdx: number;
  assignedTeams: unknown[];
  availableTeams: unknown[];
  setAvailableTeams: React.Dispatch<React.SetStateAction<unknown[]>>;
  setAssignedTeams: React.Dispatch<React.SetStateAction<unknown[]>>;
}) {
  return (
    <section className="min-h-[250px] w-full max-w-xs overflow-visible rounded-md border border-gray-400 pb-3">
      <div className="flex items-center gap-4 rounded-t-md bg-black/30 p-4">
        <h5>Group {alphabets[groupIdx as number]}</h5>
        <button>
          <LaEditPencil />
        </button>
      </div>
      <section className="">
        {
          <>
            {Array(teamPerGroup)
              .fill(null)
              .map((_, idx) => (
                <SelectComp
                  key={idx}
                  label="label"
                  name="Title"
                  availableTeams={availableTeams}
                  setAssignedTeams={setAssignedTeams}
                  setAvailableTeams={setAvailableTeams}
                  assignedTeams={assignedTeams}
                  groupIdx={groupIdx}
                  selectIdx={idx}
                />
              ))}
          </>
        }
      </section>
    </section>
  );
}

interface Props {
  label: string;
  name: string;
  availableTeams: unknown[];
  assignedTeams: unknown[];
  setAssignedTeams: React.Dispatch<React.SetStateAction<unknown[]>>;
  setAvailableTeams: React.Dispatch<React.SetStateAction<unknown[]>>;
  groupIdx: number;
  selectIdx: number;
}
function SelectComp(props: Props) {
  const [show, setShow] = React.useState(false);
  const [selected, setSelected] = React.useState<any>(null);
  const selectRef = React.useRef(null);

  useOnClickOutside(selectRef, () => setShow(false));

  function handleSelect(team: unknown) {
    handleUnselect();

    // @ts-expect-error ignore
    setSelected(team?.team);

    // Removes the newly selected team from availableTeams
    props.setAvailableTeams((prevAvailableTeams) =>
      // @ts-expect-error ignore
      prevAvailableTeams.filter((t: any) => t?.team?._id !== team?.team?._id)
    );

    // Adds the newly selected team to assignedTeams
    props.setAssignedTeams((prevAssignedTeams) => [
      ...prevAssignedTeams,
      { ...(team as object), groupIndex: props.groupIdx, selectIndex: props.selectIdx },
    ]);
    setShow(false);
  }

  function handleUnselect() {
    // If select already holds a selected team
    if (selected !== null) {
      // Removes the previously selected team from assignedTeams
      props.setAssignedTeams((prevAssignedTeams) => {
        const assigedTeam = prevAssignedTeams.filter(
          (t: any) => t?.team?._id !== selected?._id
        );
        return assigedTeam;
      });

      // Adds the previously selected team back to availableTeams
      props.setAvailableTeams((prevAvailableTeams) => {
        const selectedTeam = props.assignedTeams.find(
          (t: any) => t?.team?._id === selected._id
        );
        return [...prevAvailableTeams, selectedTeam];
      });
    }
  }

  return (
    <div className="w-full min-w-[230px] max-w-[400px] px-3" ref={selectRef}>
      <div className="relative mt-3">
        <button
          onClick={() => setShow(!show)}
          type="button"
          className="relative z-0 flex w-full cursor-default items-center justify-between rounded-sm bg-white p-3 text-left ring-1 ring-black focus:outline-none focus:ring-2 focus:ring-primary-black-darker"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
        >
          <div className="flex flex-1 items-center">
            {selected?.avatar && (
              <img
                src={selected?.avatar}
                className="h-6 w-6 overflow-hidden rounded-full object-center"
              />
            )}
            {selected?._id ? (
              <span className="ml-3 block truncate text-sm font-medium capitalize">
                {selected?.name}
              </span>
            ) : (
              <span className="ml-3 block truncate text-sm font-medium">Unselected</span>
            )}
          </div>
          <div className="text-xl">
            <LaCaretDown />
          </div>
        </button>
        {selected?._id && (
          <input
            type="text"
            name={`${props.groupIdx}_${selected?.name}`}
            id={`${props.groupIdx}_${selected?.name}`}
            value={selected?._id}
            readOnly
            hidden
          />
        )}

        {show && (
          <ul
            className="absolute z-30 mt-1 max-h-56 w-full overflow-auto rounded-sm border border-primary-black-darker bg-white py-1 sm:text-sm"
            tabIndex={-1}
            role="listbox"
          >
            <li
              className="relative cursor-default select-none border-l-2 border-white p-3 hover:bg-black/30"
              id="listbox-option-0"
              role="option"
              onClick={() => {
                handleUnselect();
                setSelected(null);
                setShow(false);
              }}
            >
              <div className="flex items-center">
                <span className="ml-3 block truncate font-normal">Unselected</span>
              </div>
            </li>
            {props.availableTeams.map((team: any) => (
              <li
                key={team?.team?._id}
                className={`relative cursor-default select-none border-l-2 border-black p-3 hover:bg-black/30 ${
                  selected?._id != team?.team?._id ? "border-white" : "border-black"
                }`}
                id="listbox-option-0"
                role="option"
                // onClick={() => handleSelect(team?.team)}
                onClick={() => handleSelect(team)}
              >
                <div className="flex flex-1 items-center">
                  {team?.team?.avatar && (
                    <img
                      src={team?.team?.avatar}
                      className="h-6 w-6 overflow-hidden rounded-full object-center"
                    />
                  )}
                  <span className="ml-3 block truncate font-medium capitalize">
                    {team?.team?.name}
                  </span>
                </div>
                {selected?._id === team?.team?._id && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-black-darker">
                    <svg
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                )}
              </li>
            ))}
            {/* Display assigned team */}
            {props.assignedTeams?.map((team: any) => (
              <li
                key={`${team?.team?._id}-unselected`}
                className={`relative cursor-default select-none border-l-2 border-black p-3 opacity-60 grayscale hover:bg-black/30`}
                id="listbox-option-0"
                role="option"
              >
                <div className="flex flex-1 items-center">
                  {team?.team?.avatar && (
                    <img
                      src={team?.team?.avatar}
                      className="h-6 w-6 overflow-hidden rounded-full object-center"
                    />
                  )}
                  <span className="ml-3 block truncate font-medium capitalize">
                    {team?.team?.name}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
