import { capitalize } from "../../utils/capitalize";
import Text from "../ui/text/Text";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import TextLink from "../ui/text/TextLink";

type Props = {
  notificationResponse: LANotification;
};
export default function NotificationTexts({ notificationResponse }: Props) {
  console.log(notificationResponse.senderLink);

  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const notificationResponses = {
    team_req_accept: (
      <p className="flex items-center gap-1">
        <Text role={role}>{notificationResponse.senderName}</Text>.
        <span>has accepted your invite</span>
      </p>
    ),
    team_req_join: (
      <p className="flex items-center gap-4">
        <span>
          @{notificationResponse.senderName} has requested to join your team.
        </span>
        <Text role={role} className="font-bold">
          View request
        </Text>
      </p>
    ),
    team_req_decline: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>has declined your request.</span>
      </p>
    ),
    team_inv_join: (
      <p className="flex items-center gap-4">
        <span>
          @{notificationResponse.senderName} sent you a request to join his/her
          team.
        </span>
        <Text role={role} className="font-bold">
          View invite
        </Text>
      </p>
    ),
    team_inv_accept: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>has accepted your invitation.</span>
      </p>
    ),
    team_inv_decline: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>has declined your invitation.</span>
      </p>
    ),
    team_rmv_player: (
      <p className="flex items-center gap-1">
        <span>You have been removed from</span>
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        .
      </p>
    ),
    tour_req_join: (
      <p className="flex items-center gap-2">
        <span>
          {notificationResponse.senderName} has requested to join your
          competition.
        </span>
        <Text role={role} className="font-bold">
          View request
        </Text>
      </p>
    ),
    tour_req_accept: (
      <p className="flex items-center gap-1">
        <span>You have been added to</span>
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>select lineup</span>
      </p>
    ),
    tour_req_decline: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>has declined request to join your competition.</span>
      </p>
    ),
    tour_inv_join: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>has invited your team to participate in</span>
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>competition</span>
      </p>
    ),
    tour_inv_accept: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>has officially joined your competition.</span>
      </p>
    ),
    tour_inv_decline: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        <span>has declined your invitation.</span>
      </p>
    ),
    // tour_rmv_team: "tourn name has removed you from the team",
    tour_rmv_team: (
      <p className="flex items-center gap-1">
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
        .<span>has removed your team from the competition</span>
      </p>
    ),
    leagues_arena: (
      <p className="flex items-center gap-1">
        <span>{notificationResponse.info}</span>
        <Text role={role} className="font-bold">
          {notificationResponse.senderName}
        </Text>
      </p>
    ),
    training: (
      <p className="flex items-center gap-1">
        <span>{capitalize(notificationResponse.info)}</span>
        <TextLink
          role={role}
          to={notificationResponse.senderLink}
          className="text-primary-Textlue-dark font-bold capitalize"
        >
          {notificationResponse.senderName}
        </TextLink>
      </p>
    ),
  };
  return notificationResponses[notificationResponse.type];
}
