import React from "react";
import { BorderBottom } from "../../components/ui/buttons";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  EditOrganizerForm,
  EditPlayerForm,
  EditTeamForm,
} from "../../components/user-profiles";
import {
  ChangePassword,
  EmailNotifications,
  SettingsTab,
} from "../../components/settings";
import { LaArrowLeft } from "../../components/svg-icons";
import { Navigate } from "react-router-dom";
import HelmetComponent from "../../components/HelmetComponent";

function Settings() {
  const isLoggedIn = useAppSelector((state: RootState) => state.auth.isLoggedIn);
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const [activeTab, setActiveTab] = React.useState<
    "edit-profile" | "change-password" | "email-notifications" | "settings"
  >("settings");
  const tabEl = React.useRef<HTMLDivElement>(null);
  const editProfileForms: {
    [key in TRole]: JSX.Element;
  } = {
    organizer: <EditOrganizerForm />,
    player: <EditPlayerForm />,
    team: <EditTeamForm />,
  };
  const editProfileForm = editProfileForms[role];

  React.useEffect(() => {
    if (tabEl.current) {
      const computedStyles = window.getComputedStyle(tabEl.current);
      computedStyles.display === "none" || computedStyles.visibility === "hidden"
        ? setActiveTab("settings")
        : setActiveTab("edit-profile");
    }
  }, []);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Settings",
    description: "Edit your profile settings on Leagues Arena",
    url: `https://${window.location.host}/settings`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {isLoggedIn ? (
        <div className="flex flex-col items-center justify-center pb-10">
          <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:pb-0">
            <h3 className="mt-4 hidden text-2xl font-medium lg:mt-16 lg:block">
              Settings
            </h3>

            <div
              className="mt-4 hidden items-center gap-6 border-b border-grey-light lg:flex"
              ref={tabEl}
            >
              <BorderBottom
                $userRole={role}
                $isActive={activeTab === "edit-profile"}
                className="pb-2"
                type="button"
                onClick={() => setActiveTab("edit-profile")}
              >
                Edit Profile
              </BorderBottom>
              <BorderBottom
                $userRole={role}
                $isActive={activeTab === "change-password"}
                className="pb-2"
                type="button"
                onClick={() => setActiveTab("change-password")}
              >
                Change Password
              </BorderBottom>
              <BorderBottom
                $userRole={role}
                $isActive={activeTab === "email-notifications"}
                className="pb-2"
                type="button"
                onClick={() => setActiveTab("email-notifications")}
              >
                Email Notifications
              </BorderBottom>
            </div>

            <div className="mt-4 lg:mt-8">
              {activeTab === "settings" && (
                <SettingsTab setActiveTab={setActiveTab} activeTab={activeTab} />
              )}
              {activeTab === "edit-profile" && (
                <>
                  <div className="flex items-center gap-2 lg:hidden">
                    <button
                      className="text-xl"
                      aria-label="back"
                      onClick={() => setActiveTab("settings")}
                    >
                      <LaArrowLeft />
                    </button>
                    <h3 className="text-xl font-bold lg:hidden">Edit Profile</h3>
                  </div>
                  {editProfileForm}
                </>
              )}
              {activeTab === "change-password" && (
                <>
                  <div className="mb-4 flex items-center gap-2 lg:hidden">
                    <button
                      aria-label="back"
                      className="text-xl"
                      onClick={() => setActiveTab("settings")}
                    >
                      <LaArrowLeft />
                    </button>
                    <h3 className="text-xl font-bold lg:hidden">Change Password</h3>
                  </div>
                  <ChangePassword />
                </>
              )}
              {activeTab === "email-notifications" && (
                <>
                  <div className="mb-4 flex items-center gap-2 lg:hidden">
                    <button
                      aria-label="back"
                      className="text-xl"
                      onClick={() => setActiveTab("settings")}
                    >
                      <LaArrowLeft />
                    </button>
                    <h3 className="text-xl font-bold lg:hidden">Email notification</h3>
                  </div>
                  <EmailNotifications />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/account/login" />
      )}
    </>
  );
}

export default Settings;
