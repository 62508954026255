import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LaPlusIcon, LaDelete } from "../svg-icons";
import PlayerBadge from "../ui/cards/PlayerBadge";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { useEjectPlayerMutation } from "../../redux/services/teamService";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { cn } from "../../utils/cn";
import Button from "../ui/buttons/Button";
import { ConfirmPopup } from "../modals";
import LaSpinner from "../svg-icons/LaSpinner";
import PlayerProfileBadge from "../user-profiles/PlayerProfileBadge";
import { calculateAgeFromDob } from "../../utils/dob";

function TeamPlayersTab({ team }: { team?: Team }) {
  const [activeTab, setActiveTab] = React.useState<
    "requests" | "teammates" | "invited"
  >("teammates");
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [deletingMode, setDeletingMode] = React.useState<boolean>(false);
  const [deleteVictim, setDeleteVictim] = React.useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase();
  const myId = useAppSelector((state: RootState) => state.auth?.myId);
  const isTeamOwner = team?.author === myId && role === "team";

  const members = team?.members as TPlayer[];
  const requests = team?.requests as TPlayer[];
  const invites = team?.invites as TPlayer[];

  const isPrivate = myId === team?.author && role === "team";

  const isTeamMate =
    members?.some((member) => member._id === myId) || isTeamOwner;

  const teamMembers = members?.map((player, idx) => {
    if (team?.author?._id === player?._id) {
      return (
        <div className="w-[143px]" key={idx}>
          {/* <PlayerBadge
            avatar={player?.avatar}
            playerName={player.name}
            playerWing={player.position}
            appearance={player.apps}
            assist={player.assists}
            goals={player.goals}
            designation="admin"
          /> */}
          <PlayerProfileBadge
            avatar={player.avatar}
            playerName={player?.name}
            appearance={player?.apps}
            assist={player?.assists}
            goals={player?.goals}
            playerWing={player?.position?.split(" ")[0]}
            favFoot={player?.favFoot}
            age={calculateAgeFromDob(player?.dob)}
            height={player?.height}
            jerseyNumb={player?.jerseyNumb}
            weight={player?.weight}
          />
        </div>
      );
    } else {
      return (
        <div
          role="button"
          aria-label="remove-player"
          onClick={() => {
            if (deletingMode) {
              setShowDeleteModal(true);
              setDeleteVictim({ name: player.name, id: player._id });
              setDeletingMode(false);
            } else {
              navigate(`/profiles/player/${player.name}`);
            }
          }}
          className="w-[143px]"
          key={idx}
        >
          <PlayerProfileBadge
            avatar={player.avatar}
            playerName={player?.name}
            appearance={player?.apps}
            assist={player?.assists}
            goals={player?.goals}
            playerWing={player?.position?.split(" ")[0]}
            favFoot={player?.favFoot}
            age={calculateAgeFromDob(player?.dob)}
            height={player?.height}
            jerseyNumb={player?.jerseyNumb}
            weight={player?.weight}
            designation={isTeamMate ? "teammate" : ""}
          />
          {/* <PlayerBadge
            avatar={player.avatar}
            playerName={player.name}
            playerWing={player.position}
            appearance={player.apps}
            assist={player.assists}
            goals={player.goals}
            designation={isTeamMate ? "teammate" : ""}
            deleteMode={deletingMode}
          /> */}
        </div>
      );
    }
  });

  const requestMembers = !isTeamOwner
    ? []
    : requests?.map((player, idx) => {
        return (
          <Link
            to={`/profiles/player/${player.name}`}
            className="w-[143px]"
            key={idx}
          >
            <PlayerBadge
              avatar={player.avatar}
              playerName={player.name}
              playerWing={player.position}
              appearance={player.apps}
              assist={player.assists}
              goals={player.goals}
              designation={isTeamOwner ? "attention" : ""}
            />
          </Link>
        );
      });

  const invitedMembers = !isTeamOwner
    ? []
    : invites?.map((player, idx) => {
        return (
          <div className="w-[143px]" key={idx}>
            <PlayerBadge
              avatar={player?.avatar}
              playerName={player?.name}
              playerWing={player?.position}
              appearance={player?.apps}
              assist={player?.assists}
              goals={player?.goals}
              designation="invited"
            />
          </div>
        );
      });

  const [ejectStubbonPlayer, { isLoading: ejectingThee }] =
    useEjectPlayerMutation();
  function ejectPlayerHandler() {
    ejectStubbonPlayer({ victimId: deleteVictim.id })
      .unwrap()
      .then((_) => {
        setDeleteVictim({ name: "", id: "" });
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log("error deleting player from team 👇");
        console.log(err);
      });
  }

  return (
    <>
      {isTeamOwner && (
        <div className="flex w-full items-center gap-4 border-b border-grey-light px-4 py-4 text-base font-medium lg:px-0">
          <Button
            intent="secondary"
            role={role}
            className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
              "border-grey-light text-grey": activeTab !== "requests",
            })}
            type="button"
            onClick={() => setActiveTab("requests")}
          >
            Requests
          </Button>

          <Button
            intent="secondary"
            role={role}
            className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
              "border-grey-light text-grey": activeTab !== "teammates",
            })}
            type="button"
            onClick={() => setActiveTab("teammates")}
          >
            Teammates
          </Button>

          <Button
            intent="secondary"
            role={role}
            className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
              "border-grey-light text-grey": activeTab !== "invited",
            })}
            type="button"
            onClick={() => setActiveTab("invited")}
          >
            Invited
          </Button>
        </div>
      )}
      {isTeamOwner && activeTab === "teammates" && (
        <section className="flex items-center justify-center gap-4 p-4 lg:justify-end">
          {teamMembers?.length > 0 && (
            <Button
              intent="secondary"
              role={role}
              onClick={() => setDeletingMode(!deletingMode)}
              className="border-red fill-red text-red hover:bg-red-lighter"
            >
              <LaDelete /> {!deletingMode ? "Remove Player" : "Exit Mode"}
            </Button>
          )}
          <Button
            intent="secondary"
            role={role}
            onClick={() => {
              navigate("/discover/players");
            }}
          >
            <LaPlusIcon stroke="#00ACD4" /> Add Players
          </Button>
        </section>
      )}
      {activeTab === "requests" && (
        <>
          {requestMembers?.length === 0 && (
            <EmptyStateAuto
              title="Nothing to see here"
              description="No requests to join your team yet"
            />
          )}
          <section className="flex w-full flex-wrap justify-between gap-y-6 p-4 lg:justify-between lg:gap-10 lg:px-0">
            {requestMembers}
          </section>
        </>
      )}
      {activeTab === "teammates" && (
        <>
          {!teamMembers?.length && (
            <EmptyStateAuto
              title="Nothing to see here"
              description={`${
                isPrivate ? "Your team" : "This team"
              } does not have any players yet`}
            />
          )}

          <section className="flex w-full flex-wrap justify-between gap-y-6 p-4 lg:justify-between lg:gap-10 lg:px-0">
            {teamMembers}
          </section>
        </>
      )}
      {activeTab === "invited" && (
        <>
          {invitedMembers?.length === 0 && (
            <EmptyStateAuto
              title="Nothing to see here"
              description="No invites sent yet"
            />
          )}
          <section className="flex w-full flex-wrap justify-between gap-y-6 p-4 lg:justify-between lg:gap-10 lg:px-0">
            {invitedMembers}
          </section>
        </>
      )}

      {/* Delete Player Modal */}
      {showDeleteModal && (
        <ConfirmPopup
          description="Are you sure you want to remove this player from your team?"
          heading="Remove Player"
          type="danger"
          buttons={[
            {
              text: "Remove player",
              class: "primary",
              handleClick: ejectPlayerHandler,
              ...(ejectingThee && {
                rIcon: (
                  <span className="animate-spin">
                    <LaSpinner />
                  </span>
                ),
              }),
            },
            {
              text: "Cancel",
              class: "secondary",
              handleClick: () => {
                setDeleteVictim({ name: "", id: "" });
                setShowDeleteModal(false);
              },
            },
          ]}
        />
      )}
    </>
  );
}

export default TeamPlayersTab;
