import React from "react";

function LaTag(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <g stroke="#3C8505" strokeWidth={1.5} strokeLinecap="round">
        <path
          d="M2.78 10.2l3.02 3.02a3.187 3.187 0 004.5 0l2.926-2.926a3.187 3.187 0 000-4.5L10.2 2.78a3.167 3.167 0 00-2.4-.927l-3.334.16a2.576 2.576 0 00-2.46 2.447l-.16 3.333c-.04.9.3 1.773.934 2.407z"
          strokeLinejoin="round"
        />
        <path d="M6.333 8a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.333z" />
      </g>
    </svg>
  );
}
export default LaTag;
