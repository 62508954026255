import { css, styled } from "styled-components";

// border-primary-blue-dark p-3 text-sm capitalize text-primary-blue-dark hover:bg-primary-blue-lighter md:text-base

interface BorderButtonProps {
  $userRole?: string | undefined;
}

const BorderButton = styled.button<BorderButtonProps>`
  border: 1px solid #0045f6;
  color: #0045f6;
  &:hover {
    background: #ccdafd;
  }
  &.svg__icon svg path {
    stroke: #0045f6;
  }

  ${(props) =>
    props.$userRole === "organizer" &&
    css`
      border: 1px solid #1a1a1a;
      color: #1a1a1a;
      &:hover {
        background: #e6e6e6;
      }
      &.svg__icon svg path {
        stroke: #1a1a1a;
      }
    `};
`;

export default BorderButton;
