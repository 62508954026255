import { Link, useNavigate } from "react-router-dom";
import { LaCheck, LaInfo } from "../svg-icons";
import { cn } from "../../utils/cn";
import { Button } from "../ui";
import { useState } from "react";
import CreateFixturesModal from "../modals/CreateFixturesModal";

type TournamentNavigationProps = {
  tournament?: Tournament;
  tournamentComplete?: boolean;
};

function TournamentNavigation({
  tournament,
  tournamentComplete,
}: TournamentNavigationProps) {
  const navigate = useNavigate();
  const registeredTeams = tournament?.teams?.filter(
    (team) => team.registered === true
  );

  const stages = [
    {
      condition: tournamentComplete,
      stage: 4,
    },
    {
      condition: tournament?.generatedFixtures,
      stage: 3,
    },

    {
      // condition: tournament?.teams.length === tournament?.maxTeam,
      condition: registeredTeams!.length >= tournament!.maxTeam,
      stage: 2,
    },
  ];
  const currentStage = stages.find((stage) => stage.condition);
  const stage = currentStage?.stage || 1;

  const groupStep = [
    { condition: tournamentComplete, stage: 6 },
    { condition: tournament?.generatedFixtures, stage: 5 },
    { condition: tournament?.stage !== "group", stage: 4 },
    {
      condition:
        tournament?.groupSettings &&
        tournament.groupTable &&
        tournament.groupTable?.length > 0,
      stage: 3,
    },
    // {
    //   condition:
    //     tournament?.teams?.length && tournament.teams.length >= tournament?.maxTeam,
    //   stage: 2,
    // },
    {
      condition: registeredTeams!.length >= tournament!.maxTeam,
      stage: 2,
    },
  ];
  const currentGroupStep = groupStep.find((stg) => stg.condition)?.stage ?? 1;
  const stagesLabel: {
    [key: number]: string;
  } =
    tournament?.format === "Group stage + Knockouts"
      ? {
          1: "Details",
          2: "Add teams",
          3: "Group Stage Fixture",
          4: "Knockout Fixture",
          5: "Enter Score",
        }
      : {
          1: "Details",
          2: "Invite teams",
          3: "Generate Fixtures",
          4: "Enter/Edit Score",
        };
  const [fixtureModal, setFixtureModal] = useState(false);

  function handleFixtures() {
    if (tournament?.format === "season") {
      navigate(`/competitions/setup-fixtures/${tournament?._id}`);
      return;
    }
    if (
      tournament?.stage === "knockout" &&
      tournament?.format === "Group stage + Knockouts"
    ) {
      navigate(`/competitions/create-fixtures/${tournament?._id}`);
      return;
    }
    setFixtureModal(true);
  }

  return (
    <>
      <div className="h__modern__scroll__bar mb-[36px] mt-4 rounded-lg border border-dotted border-grey-light bg-white bg-[url(/src/assets/images/football-effect.webp)] pb-4 pt-8">
        <div className="px-4">
          <h3 className="text-sm font-medium">
            To organize your competition,{" "}
          </h3>
          <h3 className="text-xs">follow these step-by-step instructions.</h3>
          <p className="mt-2 flex items-center gap-2 text-red">
            <LaInfo stroke={"#f00"} />
            Pending action: {stagesLabel[stage + 1]}
          </p>
        </div>
        {/* Group knockout navigator */}
        {tournament?.format == "Group stage + Knockouts" && (
          <div className="h__modern__scroll__bar overflow-x-scroll">
            <div className="grid min-w-max grid-cols-5 py-4 text-xs">
              <div className="flex w-full items-center">
                <div className="h-[2px]  flex-1 bg-primary-black-darker" />
                <button
                  className={cn(
                    "z-10 flex min-w-max items-center justify-center gap-2 rounded-full p-2 text-white lg:p-3",
                    {
                      "bg-grey": currentGroupStep && currentGroupStep > 1,
                      "bg-primary-black-darker":
                        currentGroupStep && currentGroupStep === 1,
                    }
                  )}
                >
                  Details{" "}
                  <span className="rounded-full border border-white p-1">
                    <LaCheck stroke="#fff" />
                  </span>
                </button>
                <div
                  className={cn("h-[2px] flex-1 bg-primary-black-lighter", {
                    "bg-primary-black-darker":
                      currentGroupStep && currentGroupStep > 1,
                  })}
                />
              </div>

              <div className="flex min-w-max flex-col items-center justify-center">
                <div className="flex w-full items-center">
                  <div
                    className={cn("h-[2px] flex-1", {
                      "bg-primary-black-darker": currentGroupStep >= 2,
                      "bg-primary-black-lighter": currentGroupStep < 2,
                    })}
                  />
                  <Link
                    to={currentGroupStep <= 1 ? "/discover/create-teams" : "#"}
                    className={cn(
                      "z-10 flex min-w-max items-center justify-center gap-2  rounded-full p-2 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white":
                          currentGroupStep === 2,
                        "cursor-not-allowed bg-grey text-white":
                          currentGroupStep > 2,
                        "border border-primary-black-darker text-primary-black-darker":
                          currentGroupStep === 1,
                      }
                    )}
                    preventScrollReset={currentGroupStep !== 1}
                  >
                    Add Teams{" "}
                    {currentGroupStep > 1 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Link>
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 2
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="flex w-full items-center">
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 2
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                  <Button
                    role={"organizer"}
                    intent={"primary"}
                    disabled={currentGroupStep !== 2}
                    className={cn(
                      "z-10 flex  min-w-max items-center justify-center gap-2 rounded-full bg-transparent p-2 text-xs  disabled:opacity-100 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white hover:bg-primary-black-darker":
                          currentGroupStep === 3,
                        "cursor-not-allowed bg-grey text-white":
                          currentGroupStep > 3,
                        "cursor-not-allowed border border-grey-light text-grey":
                          currentGroupStep < 2,
                        "border border-primary-black-darker text-primary-black-darker hover:text-white":
                          currentGroupStep === 2,
                      }
                    )}
                    onClick={handleFixtures}
                  >
                    Group Stage Fixture{" "}
                    {currentGroupStep > 2 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Button>
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 3
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="flex w-full items-center">
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 3
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                  <Button
                    role={"organizer"}
                    intent={"primary"}
                    disabled={currentGroupStep !== 3}
                    className={cn(
                      "z-10 flex  min-w-max items-center justify-center gap-2 rounded-full bg-transparent p-2 text-xs  hover:bg-transparent disabled:opacity-100 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white hover:bg-primary-black-darker":
                          currentGroupStep === 4,
                        "cursor-not-allowed bg-grey text-white":
                          currentGroupStep > 3,
                        "cursor-not-allowed border border-grey-light text-grey":
                          currentGroupStep < 3,
                        "border border-primary-black-darker text-primary-black-darker":
                          currentGroupStep === 3,
                      }
                    )}
                    onClick={() => {
                      // if (stage === 4)
                      navigate(`/competitions/scores/${tournament?._id}`);
                    }}
                  >
                    Group Scores{" "}
                    {currentGroupStep > 3 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Button>
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 4
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="flex w-full items-center">
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 4
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                  <Button
                    role={"organizer"}
                    intent={"primary"}
                    disabled={currentGroupStep !== 4}
                    className={cn(
                      "z-10 flex  min-w-max items-center justify-center gap-2 rounded-full bg-transparent p-2 text-xs  disabled:opacity-100 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white hover:bg-primary-black-darker":
                          currentGroupStep === 5,
                        "cursor-not-allowed bg-grey text-white":
                          currentGroupStep > 4,
                        "cursor-not-allowed border border-grey-light text-grey":
                          currentGroupStep < 4,
                        "border border-primary-black-darker text-primary-black-darker hover:text-white":
                          currentGroupStep === 4,
                      }
                    )}
                    onClick={handleFixtures}
                  >
                    Knockout Fixture{" "}
                    {currentGroupStep > 4 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Button>
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 4
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div>

              {/* <div className="flex flex-1 flex-col items-center justify-center">
                <div className="flex w-full items-center">
                  <div
                    className={`flex-1 ${
                      currentGroupStep > 5
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                  <Link
                    to={
                      currentGroupStep === 5
                        ? `/competitions/scores/${tournament?._id}`
                        : "#"
                    }
                    className={cn(
                      "z-10 flex min-w-max items-center justify-center gap-2  rounded-full p-2 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white":
                          currentGroupStep > 5,
                        "cursor-not-allowed border border-grey-light text-grey":
                          currentGroupStep <= 4,
                        "border border-primary-black-darker text-primary-black-darker":
                          currentGroupStep === 5,
                      }
                    )}
                    preventScrollReset={currentGroupStep !== 5}
                  >
                    Edit Scores
                    {currentGroupStep > 5 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Link>
                  <div
                    className={`flex-1  ${
                      currentGroupStep > 5
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div> */}
            </div>
          </div>
        )}

        {tournament?.format != "Group stage + Knockouts" && (
          <div className="h__modern__scroll__bar overflow-x-scroll">
            <div className="grid min-w-max grid-cols-4 py-4 text-xs">
              <div className="flex w-full items-center">
                <div className="h-[2px]  flex-1 bg-primary-black-darker" />
                <button
                  className={cn(
                    "z-10 flex min-w-max items-center justify-center gap-2 rounded-full p-2 text-white lg:p-3",
                    {
                      "bg-grey":
                        stage > 1 || (currentGroupStep && currentGroupStep > 1),
                      "bg-primary-black-darker":
                        stage === 1 ||
                        (currentGroupStep && currentGroupStep === 1),
                    }
                  )}
                >
                  Details{" "}
                  <span className="rounded-full border border-white p-1">
                    <LaCheck stroke="#fff" />
                  </span>
                </button>
                <div
                  className={cn("h-[2px] flex-1 bg-primary-black-lighter", {
                    "bg-primary-black-darker":
                      stage > 1 || (currentGroupStep && currentGroupStep > 1),
                  })}
                />
              </div>

              <div className="flex min-w-max flex-col items-center justify-center">
                <div className="flex w-full items-center">
                  <div
                    className={cn("h-[2px] flex-1", {
                      "bg-primary-black-darker": stage >= 2,
                      "bg-primary-black-lighter": stage < 2,
                    })}
                  />
                  <Link
                    to={stage <= 1 ? "/discover/create-teams" : "#"}
                    className={cn(
                      "z-10 flex min-w-max items-center justify-center gap-2  rounded-full p-2 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white":
                          stage === 2,
                        "cursor-not-allowed bg-grey text-white": stage > 2,
                        "border border-primary-black-darker text-primary-black-darker":
                          stage === 1,
                      }
                    )}
                    preventScrollReset={stage !== 1}
                  >
                    Invite Teams{" "}
                    {stage > 1 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Link>
                  <div
                    className={`flex-1  ${
                      stage > 2
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div>

              <div className="flex flex-col items-center justify-center">
                <div className="flex w-full items-center">
                  <div
                    className={`flex-1  ${
                      stage > 2
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                  <Button
                    role={"organizer"}
                    intent={"primary"}
                    disabled={stage !== 2}
                    className={cn(
                      "z-10 flex  min-w-max items-center justify-center gap-2 rounded-full bg-transparent p-2 text-xs  hover:bg-transparent disabled:opacity-100 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white hover:bg-primary-black-darker":
                          stage === 3,
                        "cursor-not-allowed bg-grey text-white": stage > 3,
                        "cursor-not-allowed border border-grey-light text-grey":
                          stage < 2,
                        "border border-primary-black-darker text-primary-black-darker":
                          stage === 2,
                      }
                    )}
                    onClick={handleFixtures}
                  >
                    Fixtures{" "}
                    {stage > 2 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Button>
                  <div
                    className={`flex-1  ${
                      stage > 3
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div>

              <div className="flex flex-1 flex-col items-center justify-center">
                {/* grid grid-cols-3 */}
                <div className="flex w-full items-center">
                  <div
                    className={`flex-1 ${
                      stage > 3
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                  <Link
                    to={
                      stage === 3
                        ? `/competitions/scores/${tournament?._id}`
                        : "#"
                    }
                    className={cn(
                      "z-10 flex min-w-max items-center justify-center gap-2  rounded-full p-2 lg:p-3",
                      {
                        "cursor-not-allowed bg-primary-black-darker text-white":
                          stage > 3,
                        "cursor-not-allowed border border-grey-light text-grey":
                          stage <= 3,
                        "border border-primary-black-darker text-primary-black-darker":
                          stage === 3,
                      }
                    )}
                    preventScrollReset={stage !== 3}
                  >
                    Edit Scores
                    {stage > 3 && (
                      <span className="rounded-full border border-white p-1">
                        <LaCheck stroke="#fff" />
                      </span>
                    )}
                  </Link>
                  <div
                    className={`flex-1  ${
                      stage > 3
                        ? "bg-primary-black-darker"
                        : "bg-primary-black-lighter"
                    } h-[2px]`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {fixtureModal && (
        <CreateFixturesModal
          setFixtureModal={setFixtureModal}
          tournament={tournament}
        />
      )}
    </>
  );
}

export default TournamentNavigation;
