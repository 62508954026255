import { Link } from "react-router-dom";
import { ScoreDot } from "../ui";
import winnerTrophy from "../../assets/icons/winner-throphy.png";

type Props = {
  match: KnockoutMatch;
  tournamentComplete?: boolean;
};

function FifthRound({ match, tournamentComplete }: Props) {
  const [teamA, teamB] = match?.teams || [];
  const teamAData = match?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamA?._id
  );
  const teamBData = match?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamB?._id
  );

  const teamATotalScore = (teamAData?.score ?? 0) + (teamAData?.penalties ?? 0);
  const teamBTotalScore = (teamBData?.score ?? 0) + (teamBData?.penalties ?? 0);
  const win = teamATotalScore > teamBTotalScore;
  const draw = teamATotalScore === teamBTotalScore;

  return (
    <div className="flex items-center">
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center">
          <div className="h-1 w-10 border-l-0 border-t border-dashed border-primary-black-darker" />
          <div className="w-full min-w-[200px] rounded border border-grey-light bg-white px-2 py-1 shadow">
            {teamA ? (
              <div className="flex items-center justify-between border-b border-grey-light">
                <Link
                  to={`/profiles/team/${teamA?.name?.replace(/\s/, "-")}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={teamA?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamA?.name}</p>
                </Link>
                <ScoreDot
                  teamAScore={teamAData?.score}
                  teamBScore={teamBData?.score}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 border-b border-grey-light py-1">
                <div className="rounded-full bg-grey p-2"></div>
                <div className="flex items-center">
                  <div className="w-4 border-2 border-t border-grey"></div>
                </div>
              </div>
            )}
            {teamB ? (
              <div className="flex items-center justify-between">
                <Link
                  to={`/profiles/team/${teamB?.name?.replace(/\s/, "-")}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={teamB?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamB?.name}</p>
                </Link>
                <ScoreDot
                  teamAScore={teamBData?.score}
                  teamBScore={teamAData?.score}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 py-1">
                <div className="rounded-full bg-grey p-2"></div>
                <div className="flex items-center">
                  <div className="w-4 border-2 border-t border-grey"></div>
                </div>
              </div>
            )}
          </div>
        </div>
        {tournamentComplete && (
          <>
            <div className="h-10 w-8 border-r border-dashed border-primary-black-darker" />
            <div className="ml-6 flex flex-col items-center">
              <img src={winnerTrophy} alt="" className="w-[56px]" />
              <p className="font-bold uppercase">
                {win ? teamA?.name : draw ? "Draw" : teamB?.name}
              </p>
              <p>Winner</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FifthRound;
