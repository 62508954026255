import * as React from "react";

function LaSecPlace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={38} height={38} fill="none" {...props}>
      <circle
        cx={19}
        cy={19}
        r={19}
        fill="url(#prefix__paint0_linear_8981_39920)"
      />
      <path
        d="M20.153 11.199l2.003 4.005a1 1 0 00.757.543l4.478.643a.993.993 0 01.832 1.22.99.99 0 01-.275.474l-3.24 3.117a.991.991 0 00-.288.88l.764 4.402a.981.981 0 01-.4.972 1.012 1.012 0 01-1.06.075l-3.997-2.078a1.013 1.013 0 00-.938 0l-4.01 2.077a1.015 1.015 0 01-1.06-.074.981.981 0 01-.4-.972l.765-4.403a.981.981 0 00-.288-.879l-3.24-3.117a.99.99 0 01-.255-1.018.993.993 0 01.309-.453c.143-.119.318-.196.503-.223l4.478-.643a1.017 1.017 0 00.757-.543L18.35 11.2a.997.997 0 01.37-.405 1.015 1.015 0 011.432.405z"
        fill="url(#prefix__paint1_linear_8981_39920)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_8981_39920"
          x1={19}
          y1={0}
          x2={19}
          y2={38}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A99A4" />
          <stop offset={0.484} stopColor="#10667B" />
          <stop offset={1} stopColor="#184A61" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_8981_39920"
          x1={19.252}
          y1={10.809}
          x2={19.252}
          y2={25.606}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.08} stopColor="#C8D0E5" />
          <stop offset={0.23} stopColor="#FAFAFA" />
          <stop offset={0.39} stopColor="#C8D1E2" />
          <stop offset={0.59} stopColor="#566B7C" />
          <stop offset={1} stopColor="#A9B8CF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LaSecPlace;
