type PlayerBadgeXsProps = {
  avatar?: string;
  playerName?: string;
  playerWing?: string;
  appearance?: number;
  assist?: number;
  goals?: number;
  designation?: string;
};
function PlayerBadgeXs({
  avatar,
  playerName,
  playerWing,
  appearance,
  assist,
  goals,
  designation,
}: PlayerBadgeXsProps) {
  return (
    <section
      className="relative h-[104px] min-w-[70px] overflow-hidden leading-[50%]"
      id="player-badge-plain-xs"
    >
      {designation && (
        <span
          className={`absolute left-[25%] top-[45%] z-30 block w-full -translate-x-9 -translate-y-9 -rotate-45 bg-gray-100 p-[2px] text-center text-[6px] font-bold capitalize ${
            designation === "teammate" && "bg-green-dark text-white"
          } ${
            designation === "invited" && "bg-yellow text-primary-black-darker"
          }`}
        >
          {designation}
        </span>
      )}
      <img
        src={avatar}
        crossOrigin="anonymous"
        alt="player avatar"
        className="absolute left-0 top-0 -z-10 h-[40%] w-full object-cover"
      />
      <section className="flex h-full flex-col items-center bg-gradient-to-t from-primary-blue-dark via-[#0046f6ea]  to-[#00000033] px-1 py-1 text-white">
        <div className="relative flex w-full items-center justify-center">
          <img
            src={avatar}
            alt=""
            crossOrigin="anonymous"
            className="h-[20px] w-[20px] overflow-hidden rounded-full border border-white bg-gray-500 object-contain"
          />
          <p className="absolute right-[10%] top-1/2 -translate-y-1/2 text-center text-[9px] font-medium text-white">
            {playerWing}
          </p>
        </div>
        <h3 className="text-center text-[8px] font-medium capitalize">
          {playerName}
        </h3>
        <div className="h-[1px] w-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent" />
        <section className="flex w-full items-start justify-center gap-[2px] text-xs">
          <section className="flex flex-1 flex-col items-center text-center">
            <div className="flex flex-col">
              <p className="text-[7px] font-medium">App</p>
              <p className="text-[8px] font-bold">{appearance}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-[7px] font-medium">Assist</p>
              <p className="text-[8px] font-bold">{assist}</p>
            </div>
          </section>
          <div className="h-full w-[1px] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent" />
          <section className="flex flex-1 flex-col items-center text-center">
            <div className="flex flex-col">
              <p className="text-[7px] font-medium">Goals</p>
              <p className="text-[8px] font-bold">{goals}</p>
            </div>
          </section>
        </section>
      </section>
      {/* Stripes */}
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-[0px] right-[-10px]"
      />
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-[8px] right-[-10px]"
      />
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-[-8px] right-[-10px]"
      />
    </section>
  );
}

export default PlayerBadgeXs;
