function LaProfile({
  stroke = "#111",
}: {
  stroke?: string;
}) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.25 12a5 5 0 100-10 5 5 0 000 10zM20.84 22c0-3.87-3.85-7-8.59-7s-8.59 3.13-8.59 7" />
      </g>
    </svg>
  );
}

export default LaProfile;
