import * as html2Image from "html-to-image";
import React from "react";
import info1 from "../assets/images/info-graphics/info1.png";
import info2 from "../assets/images/info-graphics/info2.png";
import info3 from "../assets/images/info-graphics/info3.png";
import logo from "../assets/images/logo.svg";
import stamp from "../assets/images/brand/la_stamp.png";
import dAvatar from "../assets/images/avatar-eight.webp";
import { Link } from "react-router-dom";
import { Spinner } from "./ui";
import { LaShare, LaDocumentDownload } from "./svg-icons";
import moment from "moment";

export default function MatchGraphic(props: {
  loc: string;
  fixture: any;
  tournament: Tournament;
  open: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [image, setImage] = React.useState<string | null>(null);
  const [generating, setGenerating] = React.useState({
    processing: false,
    failed: false,
  });
  console.log(props.fixture);

  const generateImg = React.useCallback(() => {
    if (ref.current === null) return;

    setGenerating((prev) => ({ ...prev, processing: true }));
    html2Image
      .toPng(ref.current, {
        backgroundColor: "#000000",
        // cacheBust: true,
        // canvasHeight: 1080,
        // height: 1080,
        // canvasWidth: 866,
        // width: 866,
      })
      .then((dataurl) => {
        setImage(dataurl);
        setGenerating((prev) => ({ ...prev, failed: false }));
      })
      .catch((err: any) => {
        console.log(err);
        setGenerating((prev) => ({ ...prev, failed: true }));
      })
      .finally(() => {
        setGenerating((prev) => ({ ...prev, processing: false }));
      });
  }, [ref]);

  const downloadImage = () => {
    const link = document.createElement("a");
    link.download = `match-graphics.png`;
    link.href = image!;
    link.click();
  };

  const shareImage = () => {
    // alert("none functioning");
  };

  React.useEffect(() => {
    if (props.open) generateImg();
  }, [props.open]);

  return (
    <div className="relative flex flex-col items-center gap-3 overflow-hidden">
      <h3 className="text-xl font-bold">Generated Fixtures</h3>
      <p className="max-w-md text-sm font-medium">
        🎉🎉 Congratulations! Your fixture is ready.
        <br /> You can download and share your fixture card.
      </p>
      {generating.processing && (
        <div className="grid h-[200px] place-items-center">
          <Spinner />
        </div>
      )}
      {generating.failed && !generating.processing && (
        <div className="grid h-[200px] place-items-center">
          <p className="font-bold capitalize">Failed to Generate...</p>
        </div>
      )}
      {image && !generating.processing && (
        <img
          src={image}
          alt="converted"
          className="max-h-56 w-full object-contain lg:max-h-[320px]"
        />
      )}
      <div className="absolute -z-50 overflow-hidden">
        <div className="overflow-hidden rounded-md" ref={ref}>
          <section className="relative flex h-[1080px] w-[866px] flex-col justify-end overflow-hidden bg-[#F6F7F8] pb-[23px]">
            <div className="absolute bottom-0 left-0 right-0 top-0">
              <img
                src={info1}
                className="absolute left-3 top-3 h-auto w-[151.27px]"
              />
              <img
                src={info2}
                className="absolute right-3 top-3 h-auto w-[183.24px]"
              />
              <img
                src={info3}
                className="absolute -left-1 bottom-3 h-auto w-[149.09px]"
              />
            </div>
            <section className="flex h-[90%] w-full flex-col content-end gap-4">
              <div className="flex flex-col items-center gap-1">
                <img
                  src={props.tournament.avatar ?? stamp}
                  className="aspect-square h-[100px] w-[100px] overflow-hidden rounded-full object-cover object-center"
                />
                <h2 className="w-full text-center text-[32px] font-bold capitalize">
                  {props.tournament.name}
                </h2>
                <p className="w-full text-center text-base capitalize">
                  {/* No. 15, Amazon Street, Kubwa Abuja */}
                  {props.tournament.venue}, {props.tournament.location}
                </p>
                <p className="w-full text-center text-[20px] font-medium capitalize">
                  {/* March 2023/2024 */}
                  {moment(props.tournament.datetime).format("MMMM YYYY")}
                </p>
              </div>
              <section className="mx-auto w-full max-w-[85%] flex-1 rounded-md bg-[#EDEFF5]">
                <h3 className="text-center text-[40px] font-bold text-primary-black-darker/90">
                  MATCH FIXTURES
                </h3>
                <p className="mt-[10px] text-center text-[20px] uppercase text-primary-black-darker/90">
                  KICK-OFF IS 9AM
                </p>
                {/* <div className="grid flex-1 grid-flow-col grid-rows-8 justify-between gap-x-[31px] gap-y-[14px] p-10"> */}
                <div className="flex h-full max-h-[434px] flex-1 flex-col flex-wrap items-center justify-start gap-x-[31px] gap-y-[14px] p-10">
                  {props.fixture?.map((d: any) => (
                    <VSect
                      key={`${d?.name}-${d?.date}`}
                      teamData={d?.teamData}
                    />
                  ))}
                </div>
              </section>
              <section className="flex items-center justify-between px-11">
                <div className="flex flex-1 flex-col items-center">
                  <p className="w-full font-bold">
                    Check scores & matches updates on
                  </p>
                  <p className="w-full text-primary-blue-dark">
                    www.leaguesarena.com
                  </p>
                </div>
                <div className="flex flex-1 items-center justify-end gap-1">
                  <p className="text-center text-sm">
                    Official stamp of impartial fixture.
                    <br /> Created on leaguesarena.com{" "}
                  </p>
                  <img
                    src={stamp}
                    alt="brand stamp"
                    className="aspect-square h-[68px] w-[68px] object-contain"
                  />
                </div>
              </section>
              <footer className="flex items-center justify-between px-11">
                <p className="flex-1 text-sm font-semibold">
                  Powered by Leagues Arena
                </p>
                <div className="flex flex-col items-center gap-1">
                  <img src={logo} className="max-w-[169px] object-contain" />
                  <p className="max-w-[169px] text-center text-xs font-medium">
                    NO. 1 DIGITAL COMPETITION PLATFORM IN AFRICA
                  </p>
                </div>
              </footer>
            </section>
          </section>
        </div>
      </div>

      <div className="z-0 mx-auto mb-3 flex w-full max-w-md items-center justify-center gap-4">
        <button
          onClick={downloadImage}
          className="flex min-w-[110px] cursor-pointer items-center justify-center gap-2 rounded-md border-2 border-black px-3 py-2.5 font-medium"
        >
          <LaDocumentDownload /> Download
        </button>
        <button
          onClick={shareImage}
          className="flex min-w-[110px] items-center justify-center gap-2 rounded-md border-2 border-black px-3 py-2.5 font-medium"
        >
          <LaShare /> Share
        </button>
      </div>
      <Link
        to={props.loc}
        className="mx-auto w-full max-w-xs rounded-md bg-black px-3 py-2.5 text-center font-medium text-white"
      >
        Go to competition
      </Link>
    </div>
  );
}

function VSect(props: { teamData: any }) {
  return (
    <div className="flex max-h-[33px] min-w-[270px] flex-[0.5] items-center justify-between gap-[37px]">
      <div className="flex flex-1 items-center gap-2">
        <img
          src={props.teamData?.[0]?.team?.avatar ?? dAvatar}
          className="h-8 w-8 overflow-hidden rounded-full object-cover"
        />
        <p className="w-[40px] overflow-hidden text-left uppercase">
          {props.teamData?.[0]?.team?.shortName ?? "Null"}
        </p>
      </div>
      <div className="grid h-[19px] w-[32px] items-center justify-center rounded-sm bg-white text-base font-semibold text-black">
        <span>VS</span>
      </div>
      <div className="flex flex-1 items-center gap-2">
        <p className="w-[40px] overflow-hidden text-right uppercase">
          {props.teamData?.[1]?.team?.shortName ?? "Null"}
        </p>
        <img
          src={props.teamData?.[1]?.team?.avatar ?? dAvatar}
          className="h-8 w-8 overflow-hidden rounded-full object-cover"
        />
      </div>
    </div>
  );
}
