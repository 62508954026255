import organizerSlideOne from "../../assets/images/organizer-slide-one.webp";
import organizerSlideOneM from "../../assets/images/organizer-slide-one-m.webp";
import organizerSlideTwo from "../../assets/images/organizer-slide-two.webp";
import organizerSlideTwoM from "../../assets/images/organizer-slide-two-m.webp";

export function OrganizerSlideOne() {
  return (
    <div className="relative h-full w-full">
      <img
        src={organizerSlideOne}
        alt="onboarding"
        className="hidden h-full w-full object-fill lg:block"
      />
      <img
        src={organizerSlideOneM}
        alt="onboarding"
        className="h-full w-full object-fill lg:hidden"
      />
    </div>
  );
}

export function OrganizerSlideTwo() {
  return (
    <div className="relative h-full w-full">
      <img
        src={organizerSlideTwo}
        alt="onboarding"
        className="hidden h-full w-full object-fill lg:block"
      />
      <img
        src={organizerSlideTwoM}
        alt="onboarding"
        className="h-full w-full object-fill lg:hidden"
      />
    </div>
  );
}
