import partners from "../../assets/partners";
import { Carousel, CarouselContent, CarouselItem } from "../ui/carousel";
import { Card } from "../ui/card";
import React from "react";

export default function Partners() {
  return (
    // <div
    //   className="flex items-center gap-10 overflow-x-scroll px-4 py-10 lg:justify-center"
    //   ref={elRef}
    // >
    <Carousel
      opts={{
        align: "end",
      }}
      className="mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg"
    >
      <CarouselContent>
        {partners.map((ptImg, index) => (
          <CarouselItem
            key={index}
            className="basis-1/4 md:basis-1/6 lg:basis-[9%]"
          >
            <Card className="relative aspect-square w-20 p-3">
              <img
                src={ptImg}
                alt={ptImg}
                className="h-full w-full object-contain"
              />
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
    // </div>
  );
}
