import React, { useState } from "react";
import { useGetTournamentMatchesQuery } from "../../redux/services/matchService";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { ButtonLink, Spinner } from "../ui";
import { Link, useSearchParams } from "react-router-dom";
import { cn } from "../../utils/cn";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";

function TournamentPlayers({ tournamentId }: { tournamentId: string }) {
  const { data: completedMatches, isLoading } = useGetTournamentMatchesQuery({
    id: tournamentId,
    status: "completed",
  });

  const [tabParams] = useSearchParams();
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [clickedIndex, setClickedIndex] = useState(Number);
  const activeTab = (tabParams.get("subTab") || "goals") as "goals" | "assists";
  if (isLoading) {
    return (
      <section className="flex flex-wrap justify-center gap-4 p-4">
        <Spinner />
      </section>
    );
  }

  if (!completedMatches)
    return (
      <EmptyStateAuto
        title="Nothing to see here"
        description="This tournament does not have any player stats. when it does it'll appear here"
      />
    );

  if (completedMatches.length === 0)
    return (
      <EmptyStateAuto
        title="Nothing to see here"
        description="This tournament does not have any player stats. when it does it'll appear here"
      />
    );

  const players: {
    player: { _id: string; name: string };
    team: { _id: string; name: string };
    goals: number;
    assists: number;
  }[] = [];

  for (const match of completedMatches) {
    match?.teamData.forEach((teamData) => {
      teamData?.scorers.forEach((scorers) => {
        const playerDataIndex = players.findIndex(
          (data) => data.player._id === scorers.player._id
        );
        if (playerDataIndex >= 0) {
          const playerData = players[playerDataIndex];
          playerData.goals += scorers.goals;
          players[playerDataIndex] = playerData;
        } else {
          players.push({
            player: { _id: scorers.player._id, name: scorers.player.name },
            assists: 0,
            goals: scorers.goals,
            team: {
              _id: teamData?.team?._id as string,
              name: teamData?.team?.name as string,
            },
          });
        }
      });
      teamData?.assisters.forEach((assisters) => {
        const playerDataIndex = players.findIndex(
          (data) => data.player._id === assisters.player._id
        );
        if (playerDataIndex >= 0) {
          const playerData = players[playerDataIndex];
          playerData.assists += assisters.assists;
          players[playerDataIndex] = playerData;
        } else {
          players.push({
            player: { _id: assisters.player._id, name: assisters.player.name },
            assists: assisters.assists,
            goals: 0,
            team: {
              _id: teamData?.team?._id as string,
              name: teamData?.team?.name as string,
            },
          });
        }
      });
    });
  }

  return (
    <>
      <div className="flex items-center gap-4 overflow-x-auto border-b border-grey-light px-2 py-4 text-base font-medium lg:px-0">
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=players&subTab=goals`}
          intent="secondary"
          role={role}
          className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
            "border-grey-light text-grey": activeTab !== "goals",
          })}
        >
          Goals
        </ButtonLink>

        <ButtonLink
          to={`/competitions/${tournamentId}?tab=players&subTab=assists`}
          intent="secondary"
          role={role}
          className={cn(
            "min-w-fit rounded-full border-2 px-4 py-2 capitalize ",
            {
              " border-grey-light text-grey": activeTab !== "assists",
            }
          )}
        >
          Assists
        </ButtonLink>
      </div>

      {activeTab === "goals" && (
        <div className="">
          <div className="grid w-full grid-cols-5 bg-primary-black-lighter p-3 py-4">
            <span className="col-span-2">Player</span>
            <span className="col-span-2">Team</span>
            <span>Goals</span>
          </div>
          <div className="">
            {players
              .sort((a, b) => {
                return b.goals - a.goals;
              })
              .filter((player) => player.goals > 0)
              .map((player, index) => (
                <Link
                  to={`/profiles/player/${player.player.name}`}
                  className="grid w-full grid-cols-5 border-b-2 border-primary-black-lighter p-2 py-4 capitalize"
                  key={player.player._id}
                >
                  <span className="col-span-2 flex gap-2">
                    <span>{index + 1}. </span> {player.player.name}
                  </span>
                  <span className="col-span-2">{player.team.name}</span>
                  <span>{player.goals}</span>
                </Link>
              ))}
          </div>
        </div>
      )}
      {activeTab === "assists" && (
        <div className="">
          <div className="grid w-full grid-cols-5 bg-primary-black-lighter p-3 py-4">
            <span className="col-span-2">Player</span>
            <span className="col-span-2">Team</span>
            <span>Assists</span>
          </div>
          <div className="">
            {players
              .sort((a, b) => {
                return b.assists - a.assists;
              })
              .filter((player) => player.assists > 0)
              .map((player, index) => (
                <Link
                  to={`/profiles/player/${player.player.name}`}
                  className="grid w-full grid-cols-5 border-b-2 border-primary-black-lighter p-2 py-4 capitalize"
                  key={player.player._id}
                >
                  <span className="col-span-2 flex gap-2">
                    <span>{index + 1}. </span> {player.player.name}
                  </span>
                  <span className="col-span-2">{player.team.name}</span>
                  <span>{player.assists}</span>
                </Link>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default TournamentPlayers;
