import { LuArrowUp, LuArrowDown } from "react-icons/lu";
import { cn } from "../../utils/cn";
import TabHeading from "./tab-heading";
import { Link } from "react-router-dom";
import { NewTabIcon } from "../icons";
import { SessionStat, Trainee, TrainingSession } from "../../@types/training";

export default function Leaderboard({
  data,
}: {
  data: (SessionStat & { trainee: Trainee; Session: TrainingSession })[];
}) {
  const traineesSession = data;

  return (
    <div className="w-full rounded-2xl bg-white px-3 pt-8 shadow lg:w-[49%] lg:rounded-[32px] lg:px-5 lg:pb-6 lg:pt-12">
      <TabHeading
        title={"Leaderboard"}
        description={"Best rated players from recent week"}
        tabs={["General"]}
      />
      <ul className="my-4 flex flex-col">
        {traineesSession.map((session, index) => (
          <SessionList
            curr={session.thisWeekPosition}
            name={session.trainee?.name}
            position={session?.trainee?.position}
            team={session.trainee.team}
            points={session.points}
            prev={session.lastWeekPosition}
            rank={index + 1}
            key={session.id}
          />
        ))}
      </ul>
      <div className="flex justify-end border-t border-[#D9D9D9] py-3">
        <a
          href="https://training.leaguesarena.com/table?team=CMT"
          className="flex items-center gap-2 text-primary-blue-darker"
        >
          Show More
          <NewTabIcon />
        </a>
      </div>
    </div>
  );
}

interface SessionListProps {
  name: string;
  position: string;
  team: string;
  points: number;
  prev: number;
  curr: number;
  rank: number;
}

function SessionList({
  name,
  position,
  team,
  points,
  prev,
  curr,
  rank,
}: SessionListProps) {
  return (
    <li key={name} className="flex items-center justify-between px-3 py-4">
      <div className="flex items-center gap-4">
        <p>{rank}</p>
        <div
          className={cn("text-red-500 flex items-center", {
            "text-red": prev < curr,
            "text-green-dark": prev > curr,
          })}
        >
          {prev < curr ? (
            <LuArrowUp />
          ) : prev > curr ? (
            <LuArrowDown />
          ) : (
            <div className="h-3 w-3 rounded-full bg-[#D9D9D9]" />
          )}
          {prev !== curr && <p>{Math.abs(prev - curr)}</p>}
        </div>
        <div className="flex items-center gap-2">
          <img
            src={
              "https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076677/Frame_6180_qxstfl.png"
            }
            alt=""
            width={40}
            height={40}
            className="h-10 w-10 rounded-full"
          />

          <div className="flex flex-col">
            <p className="leading-none">{name}</p>
            <div className="flex items-center gap-2">
              <p>{position}</p>
              <p>{team}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary-blue-darker">
        <p className="font-bold text-white">{points}</p>
      </div>
    </li>
  );
}

const sessions = [
  {
    name: "Mustapha Peter",
    position: "ST",
    team: "Arsenal",
    points: 24,
    prev: 3,
    curr: 1,
    rank: 1,
  },
  {
    name: "Jude Doe",
    position: "CB",
    team: "Alex Adams",
    points: 16,
    prev: 4,
    curr: 4,
    rank: 2,
  },
  {
    name: "Victor",
    position: "CM",
    team: "Chelsea",
    points: 20,
    prev: 1,
    curr: 2,
    rank: 3,
  },
  {
    name: "Peter Sulieman",
    position: "RW",
    team: "Liverpool",
    points: 14,
    prev: 5,
    curr: 5,
    rank: 4,
  },

  {
    name: "James Idior",
    position: "GK",
    team: "Manchester United",
    points: 18,
    prev: 2,
    curr: 3,
    rank: 5,
  },
];
