import { capitalize } from "../../utils/capitalize";
import { toSentenceCase } from "../../utils/toSentenceCase";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import TournamentAuthorOptions from "./TournamentAuthorOptions";
import PublicPlayerOptions from "./PublicPlayerOptions";
import { ConfirmPopup, RequestToJoinCompetitionModal } from "../modals";
import React from "react";
import { useGetAuthorsTeamQuery } from "../../redux/services/teamService";
import { LaInfo } from "../svg-icons";

type TournamentInfoProps = {
  tournament?: Tournament;
  tournamentCompleted?: boolean;
};

function TournamentInfo({
  tournament,
  tournamentCompleted,
}: TournamentInfoProps) {
  // const isTournamentOwner = tournament?.author === myId;
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const userRole = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const [showRequestToJoin, setShowRequestToJoin] = React.useState(false);
  const [showAcceptInvite, setShowAcceptInvite] = React.useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = React.useState(false);
  const { data: myTeam, isLoading: myTeamLoading } = useGetAuthorsTeamQuery({
    id: myId,
  });
  const hasTeam = myTeam !== undefined;
  const canJoinMoreTournaments = hasTeam && myTeam?.competitionLeftToJoin > 0;
  const isTournamentOwner =
    tournament?.author === myId && userRole === "organizer";
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  return (
    <>
      <div className="relative mb-4 flex flex-col items-center gap-2 px-4 pt-[35px] text-center lg:flex-row lg:items-start lg:justify-between lg:px-0 lg:pt-[70px] lg:text-left">
        <div className="flex flex-col gap-2 lg:max-w-sm lg:items-start">
          <h3 className="font-bold">{capitalize(tournament?.name)}</h3>
          <p className="text-sm text-grey">{tournament?.location}, Nigeria</p>
          <p className="">
            {tournament?.description?.length ?? 0 > 90
              ? `${toSentenceCase(tournament?.description).substring(0, 90)}...`
              : tournament?.description}
          </p>
        </div>
        <div className="mt-3 lg:mt-0">
          <div className="flex justify-center gap-4 lg:justify-end">
            {isTournamentOwner ? (
              <TournamentAuthorOptions
                tournamentId={tournament._id}
                tournamentCompleted={tournamentCompleted}
              />
            ) : (
              <>
                {!isTournamentOwner && (
                  <>
                    <PublicPlayerOptions
                      tournament={tournament}
                      setShowAcceptInvite={setShowAcceptInvite}
                      setShowRequestToJoin={setShowRequestToJoin}
                      canJoinMoreTournaments={canJoinMoreTournaments}
                      plan={myTeam?.plan}
                      hasTeam={hasTeam}
                      setShowConfirmPopup={setShowConfirmPopup}
                    />
                  </>
                )}
              </>
            )}
          </div>
          {userRole !== "team" && !isTournamentOwner && isLoggedIn && (
            <div className="my-2 flex items-center gap-2 bg-[#ffbb00]/10 px-3 py-2 text-sm text-[#ffbb00]">
              <LaInfo stroke="#ffbb00" className="text-2xl" />
              <p className="">
                Switch to a team profile to join this competition
              </p>
            </div>
          )}
        </div>
      </div>

      {showConfirmPopup && (
        <ConfirmPopup
          description="Please confirm slot availability from organizers before proceeding to payment."
          heading="Warning"
          type="warning"
          buttons={[
            {
              text: "Proceed",
              handleClick: () => {
                setShowConfirmPopup(false);
                setShowRequestToJoin(true);
              },
              class: "primary",
            },
          ]}
        />
      )}

      {showRequestToJoin && (
        <RequestToJoinCompetitionModal
          tournamentId={tournament?._id}
          setShowRequestToJoin={setShowRequestToJoin}
          setShowInviteToJoin={setShowAcceptInvite}
          type="request"
          registrationFee={tournament?.registrationFee ?? ""}
          myTeam={myTeam}
          myTeamLoading={myTeamLoading}
        />
      )}
      {showAcceptInvite && (
        <RequestToJoinCompetitionModal
          tournamentId={tournament?._id}
          setShowRequestToJoin={setShowRequestToJoin}
          setShowInviteToJoin={setShowAcceptInvite}
          registrationFee={tournament?.registrationFee ?? ""}
          type="invite"
          myTeam={myTeam}
          myTeamLoading={myTeamLoading}
        />
      )}
    </>
  );
}

export default TournamentInfo;
