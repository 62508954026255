import type { ReactElement } from "react";

export interface AvatarMenuProps {
  avatar?: string;
  handleToggle: () => void;
}

export default function AvatarMenu({
  avatar,
  handleToggle,
}: AvatarMenuProps): ReactElement {
  return (
    <button
      className="burger-menu z-40 lg:hidden"
      onClick={() => handleToggle()}
      role="button"
      aria-label="toggle"
    >
      <img src={avatar} alt="" className="h-10 w-10 rounded-full" />
    </button>
  );
}
