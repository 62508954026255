function LaPlusLarge({ stroke = "#fff" }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      className="stroke-primary-blue-dark"
    >
      <path
        d="M1.5 12h21M12 22.5v-21"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaPlusLarge;
