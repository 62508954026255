import React from "react";
import { capitalize } from "../../utils/capitalize";
import { LaArrowRight, LaPlayers } from "../svg-icons";
import { Link } from "react-router-dom";
import StatusInfo from "./StatusInfo";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { defaultProfileImages } from "../../utils/defaultProfileImages";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import useOnClickOutside from "../../hooks/useClickedOutside";
import PlayerBadge from "../ui/cards/PlayerBadge";
import { useFetchTeamPlayersInTournamentQuery } from "../../redux/services/teamService";
import { Spinner } from "../ui";

export type TeamCardProps = {
  name: string;
  userId?: string;
  played: number;
  wins: number;
  losses: number;
  draws: number;
  players?: number;
  avatar: string;
  id: string;
  color?: string;
  selectedTeams?: string[];
  setSelectedTeams?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCompetition?: React.Dispatch<React.SetStateAction<string>>;
  selectedCompetition?: string;
  competitionTeams?: Tournament["teams"];
  tournamentId?: string;
  tournamentName?: string;
} & (
  | {
      statusText: " " | "requested" | "selected";
    }
  | {
      statusText?: undefined;
      members: string[];
    }
) &
  (
    | {
        isTransfer: boolean;
        handleTransfer: () => void;
        disabled: boolean;
      }
    | {
        isTransfer?: undefined;
      }
  );

function TeamCard({
  name,
  played,
  wins,
  losses,
  draws,
  players,
  avatar,
  color,
  id,
  ...props
}: TeamCardProps) {
  const role = useAppSelector((state: RootState) => state.auth.role);
  const playerId = useAppSelector((state: RootState) => state.auth.playerId);
  const isPlayer = role === "player";
  const isOrganizer = role === "organizer";
  const isRegistered = (teamId: string, type: boolean) =>
    props.competitionTeams
      ?.filter((teamData) => teamData.registered === type)
      ?.map((teamData) => teamData?.team)
      ?.includes(teamId);

  const statusTexts =
    (!props.statusText && [
      {
        text: "joined",
        condition: isPlayer && props.members?.includes(playerId),
      },
      {
        text: "registered",
        condition: isRegistered(id, true),
      },
      {
        text: "invited",
        condition: isRegistered(id, false),
      },
      {
        text: "unselect",
        condition: props.selectedTeams?.includes(id),
      },
      {
        text: "register",
        condition: isOrganizer,
      },
    ]) ||
    [];

  const statusText = statusTexts.find(
    (statusText) => statusText.condition
  )?.text;

  return (
    <div
      className="min-w-[300px] rounded-lg bg-gradient-to-r pt-6"
      style={{ backgroundColor: color ? color : "gray" }}
    >
      <div className="relative flex flex-col items-start gap-2 rounded-lg border border-grey-light bg-white px-6 py-4 pt-6 shadow">
        <Link
          to={`/profiles/team/${name?.replace(/ /g, "-")}`}
          className="block"
        >
          <img
            src={avatar || defaultProfileImages["team"]}
            alt="team avatar"
            className="absolute top-[-15%] h-[40px] w-[40px] rounded-full"
          />
          <p className="max-w-[120px] truncate">{capitalize(name)}</p>
        </Link>
        <div className="flex gap-4">
          <div className="flex flex-col items-center">
            <p className="text-sm font-medium">{played}</p>
            <p className="text-xs font-medium">Played</p>
          </div>
          <div className="flex flex-col items-center border-l border-grey-light pl-4">
            <p className="text-sm font-medium">{wins}</p>
            <p className="text-xs font-medium">Won</p>
          </div>
          <div className="flex flex-col items-center border-l border-grey-light pl-4">
            <p className="text-sm font-medium">{losses}</p>
            <p className="text-xs font-medium">Lost</p>
          </div>
          <div className="flex flex-col items-center border-l border-grey-light pl-4">
            <p className="text-sm font-medium">{draws}</p>
            <p className="text-xs font-medium">Draw</p>
          </div>
        </div>
        <div className="mt-2 flex items-center gap-1">
          <LaPlayers />
          <p className="text-xs font-medium">
            {players ?? 0 > 1 ? `${players} Players` : `${players} Player`}
          </p>
        </div>
        {!props?.isTransfer && (
          <div className="absolute right-4 top-2">
            <StatusInfo
              statusText={props.statusText?.trim() || statusText}
              id={id}
              name={name}
              setSelectedTeams={props.setSelectedTeams}
              setSelectedCompetition={props.setSelectedCompetition}
              selectedCompetition={props.selectedCompetition}
              selectedTeams={props.selectedTeams}
            />
          </div>
        )}
        {!props.isTransfer && (
          <Link
            to={`/profiles/team/${name?.replace(/ /g, "-")}`}
            className="absolute bottom-4 right-4 flex items-center gap-2 text-xs"
          >
            View <LaArrowRight />
          </Link>
        )}
        {props.isTransfer && (
          <button
            className="absolute bottom-4 right-4 flex items-center gap-2 rounded bg-grey-lighter px-3 py-1 text-xs"
            onClick={props.handleTransfer}
            disabled={props.disabled}
            title="You can't transfer to same team"
          >
            Select
          </button>
        )}
      </div>
    </div>
  );
}

export default TeamCard;

export function TeamCardOnTournament(props: TeamCardProps) {
  const modelRef = React.useRef<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);
  useOnClickOutside(modelRef, () => setOpen(false));
  useLockBodyScroll(open);
  const { isFetching, data } = useFetchTeamPlayersInTournamentQuery(
    {
      teamId: props.id,
      // teamId: "65b38dc362f3a6f892e1e40b",
      tournamentId: props.tournamentId,
      // tournamentId: "65b30be3bb54ca736b8e382e",
    },
    { skip: !open }
  );

  return (
    <>
      <button
        className="relative min-w-[300px] rounded-lg bg-gradient-to-r pt-6"
        style={{ backgroundColor: props.color ? props.color : "gray" }}
        onClick={() => setOpen(true)}
      >
        <div className="relative flex flex-col items-start gap-2 rounded-lg border border-grey-light bg-white px-6 py-4 pt-6 shadow">
          <div className="block">
            <img
              src={props.avatar || defaultProfileImages["team"]}
              alt="team avatar"
              className="absolute top-[-15%] h-[40px] w-[40px] rounded-full"
            />
            <p className="max-w-[120px] truncate">{capitalize(props.name)}</p>
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col items-center">
              <p className="text-sm font-medium">{props.played}</p>
              <p className="text-xs font-medium">Played</p>
            </div>
            <div className="flex flex-col items-center border-l border-grey-light pl-4">
              <p className="text-sm font-medium">{props.wins}</p>
              <p className="text-xs font-medium">Won</p>
            </div>
            <div className="flex flex-col items-center border-l border-grey-light pl-4">
              <p className="text-sm font-medium">{props.losses}</p>
              <p className="text-xs font-medium">Lost</p>
            </div>
            <div className="flex flex-col items-center border-l border-grey-light pl-4">
              <p className="text-sm font-medium">{props.draws}</p>
              <p className="text-xs font-medium">Draw</p>
            </div>
          </div>
          <div className="invisible mt-2 flex items-center gap-1">
            <LaPlayers />
            <p className="text-xs font-medium">
              {props.players ?? 0 > 1
                ? `${props.players} Players`
                : `${props.players} Player`}
            </p>
          </div>
          <div className="absolute right-4 top-2">
            <StatusInfo
              statusText={props.statusText ?? "joined"}
              id={props.id}
              name={props.name}
            />
          </div>
          <Link
            to={`/profiles/team/${props.name?.replace(/ /g, "-")}`}
            className="absolute bottom-4 right-4 z-10 flex items-center gap-2 text-xs"
          >
            View <LaArrowRight />
          </Link>
        </div>
      </button>
      {open && (
        <section className="fixed bottom-0 left-0 right-0 top-0 z-40 flex h-screen place-content-center place-items-center bg-primary-black-darker/20 p-1">
          <section
            ref={modelRef}
            className="relative h-full max-h-[74%] w-full max-w-[560px] rounded-md bg-white p-10 lg:max-h-[90%]"
          >
            <button
              className="absolute right-5 top-4 aspect-square h-8 w-8 rounded border font-bold text-primary-black-darker hover:bg-grey-lighter"
              onClick={() => setOpen(!open)}
            >
              X
            </button>
            <section className="flex h-full flex-col gap-4">
              <div>
                <h3 className="text-2xl capitalize">{props.name}</h3>

                <div className="my-3 flex gap-4">
                  <div className="flex flex-col items-center">
                    <p className="text-sm font-medium">{props.played}</p>
                    <p className="text-xs font-medium">Played</p>
                  </div>
                  <div className="flex flex-col items-center border-l border-grey-light pl-4">
                    <p className="text-sm font-medium">{props.wins}</p>
                    <p className="text-xs font-medium">Won</p>
                  </div>
                  <div className="flex flex-col items-center border-l border-grey-light pl-4">
                    <p className="text-sm font-medium">{props.losses}</p>
                    <p className="text-xs font-medium">Lost</p>
                  </div>
                  <div className="flex flex-col items-center border-l border-grey-light pl-4">
                    <p className="text-sm font-medium">{props.draws}</p>
                    <p className="text-xs font-medium">Draw</p>
                  </div>
                </div>
                <h4 className="max-w-[90%] text-xl font-medium">
                  Squad Information for{" "}
                  <span className="capitalize">{props.tournamentName}</span>
                </h4>
              </div>
              <div className="flex flex-1 flex-wrap gap-4 overflow-y-auto">
                {isFetching && (
                  <div className="flex h-full w-full flex-col items-center justify-center gap-2">
                    <Spinner />
                    <p>Loading .....</p>
                  </div>
                )}
                {!isFetching && data?.length < 1 && (
                  <div className="flex h-full w-full flex-col items-center justify-center gap-3">
                    <h4 className="text-2xl font-semibold">No Squad Found</h4>
                  </div>
                )}
                {data &&
                  data?.map((player: any) => (
                    <div className="w-full max-w-[143px]" key={player._id}>
                      <PlayerBadge
                        appearance={player?.apps}
                        assist={player?.assists}
                        avatar={player?.avatar}
                        goals={player?.goals}
                        playerName={player?.name}
                        playerWing={player?.position}
                      />
                    </div>
                  ))}
                {data &&
                  data?.map((player: any) => (
                    <div className="w-full max-w-[143px]" key={player._id}>
                      <PlayerBadge
                        appearance={player?.apps}
                        assist={player?.assists}
                        avatar={player?.avatar}
                        goals={player?.goals}
                        playerName={player?.name}
                        playerWing={player?.position}
                      />
                    </div>
                  ))}
              </div>
              <div className="bg-white py-2 lg:py-4">
                <Link
                  to={`/profiles/team/${props.name?.replace(/ /g, "-")}`}
                  className="rounded-md bg-primary-black-darker p-3 font-medium text-white"
                >
                  View team profile
                </Link>
              </div>
            </section>
          </section>
        </section>
      )}
    </>
  );
}
