import type { ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LaArrowLeft, LaInfo } from "../../components/svg-icons";
import { TeamCardSkeleton } from "../../components/ui/skeletons";
import { useGetEditionQuery } from "../../redux/services/tournamentService";
import { TournamentCard } from "../../components/ui";
import HelmetComponent from "../../components/HelmetComponent";

export interface CompetitionEditionProps {}

export default function CompetitionEdition(): ReactElement {
  const { editionId } = useParams();
  const { data: edition, isLoading } = useGetEditionQuery({ id: editionId });
  const navigate = useNavigate();
  const tournaments = edition?.tournaments as Tournament[];
  const tournamentCards = tournaments?.map((tournament) => (
    <TournamentCard
      key={tournament?._id}
      avatar={tournament?.avatar}
      banner={tournament?.banner}
      id={tournament?._id}
      location={tournament?.location}
      name={tournament?.name}
      teams={tournament?.teams?.length}
    />
  ));

  // open graph data
  const ogData = {
    title: "Leagues Arena - Competition Edition",
    description: "View the edition of a competition on Leagues Arena",
    url: `https://${window.location.host}/competition-editions/${editionId}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex w-full flex-col items-center justify-center px-4 pb-10 lg:px-0">
        <div className="w-full pb-24 pt-4 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:px-0">
            <button
              aria-label="back"
              className="text-xl lg:hidden"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft />
            </button>
            <h3 className="text-xl font-bold">League of champions editions</h3>
          </div>
          <div className="mt-4 flex items-center gap-2 bg-[#FFB8001A] px-2 py-1 lg:mt-[77px] lg:w-[326px] ">
            <LaInfo className="text-2xl" stroke={"#FFB800"} />
            <p className="text-sm text-[#FFB800]">
              Select a completed competition to create a new edition
            </p>
          </div>

          <div className="mt-6 flex w-full grid-cols-3 flex-col justify-between gap-4 lg:grid lg:flex-row lg:gap-y-10">
            {isLoading ? (
              <>
                <TeamCardSkeleton />
                <TeamCardSkeleton />
                <TeamCardSkeleton />
              </>
            ) : (
              tournamentCards
            )}
          </div>
        </div>
      </div>
    </>
  );
}
