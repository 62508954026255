import type { ReactElement } from "react";
import { LaArrowLeft, LaInfo, LaLink, LaPlusIcon } from "../../components/svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  useGetTrainingQuery,
  useJoinTrainingMutation,
} from "../../redux/services/trainingService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import moment from "moment";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { copyToClipboard } from "../../utils/copyToClipboard";
import PlayerBadgeXs from "../../components/ui/cards/PlayerBadgeXs";
import PlayerBadge from "../../components/ui/cards/PlayerBadge";
import { calcPercent } from "../../utils/calculatePercent";
import HelmetComponent from "../../components/HelmetComponent";

export interface TrainingProps {}

export default function Training(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [_, handleCopy] = useCopyToClipboard();
  const { trainingId } = useParams();
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const [joinTraining] = useJoinTrainingMutation();
  const { data: training } = useGetTrainingQuery({ trainingId });
  const organizerId = training?.organizerId as string;
  const isOwner = organizerId === myId;
  const isPast = new Date() > new Date(training?.date || Date.now());
  const noOfPlayers = training?.players.length || 0;
  const totalPercent = training?.players.reduce((acc, curr) => acc + curr.rating, 0) || 0;
  const players = [...(training?.players || [])];
  const topThreePlayers = players
    .filter((player) => player?.rating > 0)
    .sort((a, b) => b?.rating - a?.rating)
    .splice(0, 3);

  function handleJoinTraining() {
    if (isOwner) {
      navigate(`/training/players?trainingId=${trainingId}`);
      return;
    }
    joinTraining({ trainingId, playerId: myId })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        console.log(err);

        dispatch(setToastError(true));
      });
  }

  // open graph data
  const ogData = {
    title: "Leagues Arena - Training",
    description: "View a training session on Leagues Arena",
    url: `https://${window.location.host}/training/${trainingId}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full pb-24 lg:max-w-[87%] lg:pb-0">
          <div className="hidden flex-row-reverse items-center py-4 lg:mt-14 lg:flex lg:justify-between">
            <button
              className="flex items-center gap-2 text-xl text-primary-blue-darker"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft stroke="#0045f6" />{" "}
              <span className="hidden lg:block">Back</span>
            </button>
            <h3 className="text-xl font-bold">Training Session</h3>
          </div>
          <div className="flex flex-col items-center justify-center gap-4 bg-primary-blue-darker p-4 lg:rounded-[8px_8px_0_0] lg:py-11">
            <button
              className="flex h-6 w-6 items-center justify-center self-start rounded-full bg-white lg:hidden"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft stroke="#0045f6" />{" "}
            </button>
            <h3 className="text-center text-2xl font-bold text-white">
              Open training session
            </h3>
            <div className="rounded bg-[#336CFF] p-2">
              <p className="text-center font-bold capitalize text-white">
                {training?.sessionName} · {training?.venue}{" "}
              </p>
            </div>
            <p className="text-center font-bold text-white">
              {moment(training?.date).format("ll")}
            </p>
            <p className="text-center font-bold text-white">
              {moment(training?.date).format("dddd")}{" "}
              {moment(training?.time).format("LT")}
            </p>
            <div className="flex w-full lg:hidden">
              <button
                className="flex w-full items-center justify-center gap-2 rounded border-r border-r-grey bg-white text-primary-blue-darker disabled:cursor-not-allowed disabled:opacity-80"
                disabled={isPast}
                onClick={handleJoinTraining}
              >
                <LaPlusIcon stroke="#0045f6" />
                {isOwner ? "Add players" : "Join training"}
              </button>
              <button
                className="flex items-center justify-center gap-2 rounded bg-white p-4 font-medium text-primary-blue-darker disabled:cursor-not-allowed disabled:opacity-80"
                disabled={isPast}
                onClick={() =>
                  copyToClipboard(
                    `${window.location.host}${window.location.pathname}`,
                    handleCopy,
                    dispatch
                  )
                }
              >
                <LaLink />
              </button>
            </div>
          </div>
          <div className="hidden items-center justify-end gap-4 py-6 lg:flex">
            <button
              className="flex items-center gap-1 rounded border border-primary-blue-darker p-3 font-medium text-primary-blue-darker hover:bg-primary-blue-lighter disabled:cursor-not-allowed disabled:bg-grey-lighter disabled:opacity-50"
              onClick={handleJoinTraining}
              disabled={isPast}
            >
              <LaPlusIcon stroke="#0045f6" />
              {isOwner ? "Add players" : "Join training"}
            </button>

            <button
              className="flex items-center gap-1 rounded border  bg-primary-blue-darker p-3 font-medium text-white hover:bg-primary-blue-light disabled:cursor-not-allowed disabled:opacity-50"
              disabled={isPast}
              onClick={() =>
                copyToClipboard(
                  `${window.location.host}${window.location.pathname}`,
                  handleCopy,
                  dispatch
                )
              }
            >
              <LaLink stroke="#fff" />
              Copy Link
            </button>
          </div>

          {isPast && !!topThreePlayers.length && (
            <div className="my-6 px-4 lg:px-0">
              <div className="rounded-lg border border-grey-lighter bg-grey-lighter p-2 shadow">
                <h4 className="mb-2 text-center lg:text-xl">
                  Top 3 votes for Man of the Match
                </h4>
                <div className="grid grid-cols-3">
                  {topThreePlayers?.map((player) => {
                    const trainingPlayer = player.player;
                    return (
                      <div
                        className="flex flex-col items-center justify-center gap-2 border border-grey-lighter p-4 py-2 text-center odd:bg-white even:bg-grey-lighter lg:flex-row lg:gap-4"
                        key={trainingPlayer?._id}
                      >
                        <Link
                          to={`/profiles/player/${trainingPlayer?.name}`}
                          className="w-[70px] lg:hidden"
                        >
                          <PlayerBadgeXs
                            appearance={trainingPlayer?.apps}
                            assist={trainingPlayer?.assists}
                            avatar={trainingPlayer?.avatar}
                            designation={trainingPlayer?.designation}
                            goals={trainingPlayer?.goals}
                            playerName={trainingPlayer?.name}
                            playerWing={trainingPlayer?.position}
                          />
                        </Link>

                        <Link
                          to={`/profiles/player/${trainingPlayer?.name}`}
                          className="hidden w-[143px] lg:block"
                        >
                          <PlayerBadge
                            appearance={trainingPlayer?.apps}
                            assist={trainingPlayer?.assists}
                            avatar={trainingPlayer?.avatar}
                            designation={trainingPlayer?.designation}
                            goals={trainingPlayer?.goals}
                            playerName={trainingPlayer?.name}
                            playerWing={trainingPlayer?.position}
                          />
                        </Link>
                        <div>
                          <p className="capitalize text-grey">{player?.player?.name}</p>
                          <p className="font-bold text-grey">
                            {calcPercent(totalPercent, player?.rating || 0)}%
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {isPast ? (
            <div className="px-4 lg:px-0">
              <h4 className="text-sm font-semibold lg:text-xl">
                Training session players{" "}
                <span className="text-xs lg:text-base">({noOfPlayers})</span>
              </h4>
            </div>
          ) : (
            <div className="flex items-center justify-center lg:justify-normal">
              <div className="mt-4 flex max-w-max items-center justify-center gap-2 bg-[#FFB8001A] px-4 py-1">
                <LaInfo className="text-2xl" />
                <p className="text-sm">
                  {" "}
                  {noOfPlayers > 1
                    ? `${noOfPlayers} players have`
                    : `${noOfPlayers} player`}{" "}
                  joined the training session
                </p>
              </div>
            </div>
          )}

          <div className="grid grid-cols-3 p-4 lg:grid-cols-5 lg:px-0">
            {training?.players?.map((player) => {
              const trainingPlayer = player.player;
              return (
                <div
                  className="flex flex-col items-center justify-center gap-2 border border-grey-lighter p-4 text-center odd:bg-white even:bg-grey-lighter"
                  key={player?.player?._id}
                >
                  <Link
                    to={`/profiles/player/${trainingPlayer?.name}`}
                    className="w-[70px] lg:hidden"
                  >
                    <PlayerBadgeXs
                      appearance={trainingPlayer?.apps}
                      assist={trainingPlayer?.assists}
                      avatar={trainingPlayer?.avatar}
                      designation={trainingPlayer?.designation}
                      goals={trainingPlayer?.goals}
                      playerName={trainingPlayer?.name}
                      playerWing={trainingPlayer?.position}
                    />
                  </Link>

                  <Link
                    to={`/profiles/player/${trainingPlayer?.name}`}
                    className="hidden w-[143px] lg:block"
                  >
                    <PlayerBadge
                      appearance={trainingPlayer?.apps}
                      assist={trainingPlayer?.assists}
                      avatar={trainingPlayer?.avatar}
                      designation={trainingPlayer?.designation}
                      goals={trainingPlayer?.goals}
                      playerName={trainingPlayer?.name}
                      playerWing={trainingPlayer?.position}
                    />
                  </Link>

                  <span className="text-grey">{player?.player?.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
