import { capitalize } from '../../../utils/capitalize'
import { LaLocation, LaPlayers } from '../../svg-icons'
import { Link } from 'react-router-dom'

type Props = {
  avatar: string
  name: string
  location: string
  teams?: number
  banner: string
  id?: string
}

function CompetitionsCard({ avatar, name, location, teams, banner, id }: Props) {
  return (
    <Link to={`/competitions/${id}`} className='relative cursor-pointer w-full'>
      <img src={banner} alt="tournament banner" crossOrigin='anonymous' className='rounded-lg h-[243px] w-full' />
      <div className='flex justify-between absolute bottom-0 bg-white w-full rounded-[0_0_8px_8px] shadow-lg px-6 py-3 border border-grey-light'>
        <div className='flex flex-col gap-1'>
          <img src={avatar} alt="tournament avatar" crossOrigin='anonymous' className='w-8 h-8 rounded-full absolute border border-white -top-4' />
          <p className='text-base'>{capitalize(name)}</p>
          <p className='text-xs font-medium flex items-center gap-1'><span className='text-lg'><LaLocation /></span>{location}</p>
          <p className='text-xs font-medium flex items-center gap-1'><span className='text-lg'><LaPlayers /></span>{teams === undefined || teams < 2 ? `${teams} team` : `${teams} teams`}</p>
        </div>
      </div>
    </Link >
  )
}

export default CompetitionsCard