import type { ReactElement } from "react";
import PlayerBadge from "../ui/cards/PlayerBadge";
import {
  LaCaretDown,
  LaLocation,
  LaNumberOne,
  LaNumberThree,
  LaNumberTwo,
} from "../svg-icons";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { cn } from "../../utils/cn";
import React from "react";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { Link } from "react-router-dom";

export interface PlayerOfTheWeekProps {
  topSessions: {
    _id: string;
    players: { player: TPlayer; checkedIn: boolean }[];
    venue: string;
  }[];
}

export default function PlayerOfTheWeek({
  topSessions,
}: PlayerOfTheWeekProps): ReactElement {
  const role = useAppSelector(
    (state: RootState) => state.auth?.role
  )?.toLowerCase();
  const isOrganizer = role === "organizer";
  const sessionNames = topSessions?.map((session) => session.venue);
  const [dropdown, setDropDown] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const dropdownEl = React.useRef(null);
  useOnClickOutside(dropdownEl, () => setDropDown(false));

  function handleSelection(e: React.MouseEvent<HTMLButtonElement>) {
    const index = +e.currentTarget.dataset.index!;
    setDropDown((prev) => !prev);
    setIndex(index);
  }

  const rate: {
    [key: number]: ReactElement;
  } = {
    1: <LaNumberOne className="text-xl lg:text-4xl" />,
    2: <LaNumberTwo className="text-xl lg:text-4xl" />,
    3: <LaNumberThree className="text-xl lg:text-4xl" />,
  };
  // todo: fix top players of the week
  // avaoid backdated dates fieldk
  const topThree = topSessions?.[index]?.players.map((player, i) => {
    const trainingPlayer = player.player;
    return (
      <Link
        to={`/profiles/player/${trainingPlayer?.name}`}
        className="flex flex-col items-center gap-4 lg:flex-row lg:gap-6"
        key={`${trainingPlayer?._id}-${index}`}
      >
        {rate[i + 1]}
        <div className="w-[143px]">
          <PlayerBadge
            appearance={trainingPlayer?.apps}
            assist={trainingPlayer?.assists}
            avatar={trainingPlayer?.avatar}
            goals={trainingPlayer?.goals}
            playerName={trainingPlayer?.name}
            playerWing={trainingPlayer?.position}
          />
        </div>
      </Link>
    );
  });

  return (
    <div className="flex flex-col overflow-x-scroll">
      <div className="flex items-center justify-between">
        <h3 className="font-medium lg:text-xl">Player of the Week</h3>
        <div className="relative w-[180px] lg:w-[250px]">
          <button
            className={cn(
              "flex w-full items-center gap-2 rounded bg-grey-lighter px-2 py-1"
            )}
            onClick={() => setDropDown(!dropdown)}
          >
            <LaLocation />
            <span className="w-[220px] truncate text-left">
              {sessionNames[index]}
            </span>
            <LaCaretDown />
          </button>
          {dropdown && (
            <div
              className={cn(
                "absolute left-0 top-[calc(100%+16px)] z-40 flex w-full flex-col rounded border border-grey-light bg-white shadow"
              )}
              ref={dropdownEl}
            >
              {sessionNames.map((name, i) => (
                <button
                  className={cn(
                    "truncate px-2 py-1 text-left hover:bg-primary-blue-lighter",
                    {
                      "hover:bg-primary-black-lighter": isOrganizer,
                    }
                  )}
                  data-index={i}
                  onClick={handleSelection}
                  key={name}
                >
                  {name}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="h__modern__scroll__bar mb-6 mt-3 flex items-center justify-between gap-4 overflow-x-scroll lg:overflow-hidden">
        {topThree}
      </div>
    </div>
  );
}
