/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from "react";
import { useFetchTeamPlayersInTournamentQuery } from "../../redux/services/teamService";
import { Spinner } from "../ui";
import PlayerBadge from "../ui/cards/PlayerBadge";
import { Link } from "react-router-dom";
import { LaClose } from "../svg-icons";

interface SquadInfoModalProps {
  teamId: string;
  tournamentId: string;
  setClickedTeamData: React.Dispatch<
    React.SetStateAction<Tournament["teams"][0] | undefined>
  >;
  teamName: string;
  tournamentName: string;
  wins: number | string;
  played: number | string;
  losses: number | string;
  draws: number | string;
}

export default function SquadInfoModal({
  teamId,
  tournamentId,
  setClickedTeamData,
  teamName,
  tournamentName,
  wins,
  played,
  losses,
  draws,
}: SquadInfoModalProps) {
  const { isFetching, data } = useFetchTeamPlayersInTournamentQuery({
    teamId,
    tournamentId,
  });
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <section className="fixed bottom-0 left-0 right-0 top-0 z-40 flex h-screen place-content-center place-items-center bg-primary-black-darker/20 p-1">
      <section
        ref={modalRef}
        className="relative h-full max-h-[74%] w-full max-w-[560px] rounded-md bg-white p-10 lg:max-h-[90%]"
      >
        <button
          className="absolute right-5 top-4 flex aspect-square h-8 w-8 items-center justify-center rounded border font-bold text-primary-black-darker hover:bg-grey-lighter"
          onClick={() => setClickedTeamData(undefined)}
        >
          <LaClose />
        </button>
        <section className="flex h-full flex-col gap-4">
          <div>
            <h3 className="text-2xl capitalize">{teamName}</h3>

            <div className="my-3 flex gap-4">
              <div className="flex flex-col items-center">
                <p className="text-sm font-medium">{played}</p>
                <p className="text-xs font-medium">Played</p>
              </div>
              <div className="flex flex-col items-center border-l border-grey-light pl-4">
                <p className="text-sm font-medium">{wins}</p>
                <p className="text-xs font-medium">Won</p>
              </div>
              <div className="flex flex-col items-center border-l border-grey-light pl-4">
                <p className="text-sm font-medium">{losses}</p>
                <p className="text-xs font-medium">Lost</p>
              </div>
              <div className="flex flex-col items-center border-l border-grey-light pl-4">
                <p className="text-sm font-medium">{draws}</p>
                <p className="text-xs font-medium">Draw</p>
              </div>
            </div>
            <h4 className="max-w-[90%] text-xl font-medium">
              Squad Information for{" "}
              <span className="capitalize">{tournamentName}</span>
            </h4>
          </div>
          <div className="flex flex-1 flex-wrap gap-4 overflow-y-auto">
            {isFetching && (
              <div className="flex h-full w-full flex-col items-center justify-center gap-2">
                <Spinner />
                <p>Loading .....</p>
              </div>
            )}
            {!isFetching && data?.length < 1 && (
              <div className="flex h-full w-full flex-col items-center justify-center gap-3">
                <h4 className="text-2xl font-semibold">No Squad Found</h4>
              </div>
            )}
            {data &&
              data?.map((player: any) => (
                <div className="w-full max-w-[143px]" key={player._id}>
                  <PlayerBadge
                    appearance={player?.apps}
                    assist={player?.assists}
                    avatar={player?.avatar}
                    goals={player?.goals}
                    playerName={player?.name}
                    playerWing={player?.position}
                  />
                </div>
              ))}
          </div>
          <div className="bg-white py-2 lg:py-4">
            <Link
              to={`/profiles/team/${teamName?.replace(/ /g, "-")}`}
              className="rounded-md bg-primary-black-darker p-3 font-medium text-white"
            >
              View team profile
            </Link>
          </div>
        </section>
      </section>
    </section>
  );
}
