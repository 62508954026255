import * as React from "react";

function LaProfilePlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M15.417 16.75h-3.334M13.75 18.417v-3.334M10.133 9.558a1.515 1.515 0 00-.275 0A3.683 3.683 0 016.3 5.867a3.69 3.69 0 013.692-3.7c2.041 0 3.7 1.658 3.7 3.7 0 2-1.584 3.625-3.559 3.691zM9.992 18.675c-1.517 0-3.025-.383-4.175-1.15-2.017-1.35-2.017-3.55 0-4.892 2.291-1.533 6.05-1.533 8.341 0"
        stroke="#1A1A1A"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaProfilePlus;
