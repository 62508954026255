import type { ReactElement } from "react";
import { LaCheck } from "../svg-icons";
import otherFeatures from "../../assets/data/other-features";
import React from "react";
import { cn } from "../../utils/cn";

export interface OtherFeaturesProps {}

export default function OtherFeatures({}: OtherFeaturesProps): ReactElement {
  const [showAll, setShowALl] = React.useState(false);
  return (
    <div className="flex  w-full flex-col items-center  px-4 md:px-8 lg:px-20">
      <h3 className="text-center text-2xl font-bold lg:text-4xl">
        Other features
      </h3>
      <div
        className={cn("h-[400px] w-full overflow-hidden", {
          "h-auto w-full overflow-auto": showAll,
        })}
      >
        <table className="mt-12 w-full overflow-hidden lg:mt-16">
          <thead>
            <tr>
              <th className="border border-grey-light p-2 text-left lg:px-6 lg:py-4">
                Feature
              </th>
              <th className="border border-grey-light p-2 text-left lg:px-6 lg:py-4">
                Free
              </th>
              <th className="border border-grey-light p-2 text-left lg:px-6 lg:py-4">
                Pro
              </th>
              <th className="border border-grey-light p-2 text-left lg:px-6 lg:py-4">
                Enterprise
              </th>
            </tr>
          </thead>
          <tbody>
            {otherFeatures.map((feature, index) => (
              <tr key={`${feature.title} ${index}`}>
                <td
                  className={cn(
                    "border border-grey-light p-2 font-medium lg:px-6 lg:py-4",
                    {
                      "font-bold": feature.isCategory,
                    }
                  )}
                >
                  {feature.title}
                </td>
                <td className="border border-grey-light p-2 lg:px-6 lg:py-4">
                  {feature.isCategory ? (
                    ""
                  ) : feature.isFree ? (
                    <LaCheck stroke="#0045F6" strokeWidth={3} />
                  ) : (
                    "-"
                  )}
                </td>
                <td className="border border-grey-light p-2 lg:px-6 lg:py-4">
                  {feature.isCategory ? (
                    ""
                  ) : feature.isPro ? (
                    <LaCheck stroke="#0045F6" strokeWidth={3} />
                  ) : (
                    "-"
                  )}
                </td>
                <td className="border border-grey-light p-2 lg:px-6 lg:py-4">
                  {feature.isCategory ? (
                    ""
                  ) : feature.isEnterprise ? (
                    <LaCheck stroke="#0045F6" strokeWidth={3} />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        className="mt-8 rounded-full border border-primary-blue-darker px-20 py-3 font-bold text-primary-blue-darker hover:bg-primary-blue-lighter"
        onClick={() => setShowALl((prev) => !prev)}
      >
        {showAll ? "See less" : "See all"}
      </button>
    </div>
  );
}
