import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LaArrowLeft } from "../../components/svg-icons";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import PlayerBadge from "../../components/ui/cards/PlayerBadge";
import { TeamCard } from "../../components/tournament";
import { useTransferPlayerMutation } from "../../redux/services/teamService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useState } from "react";
import { ConfirmPopup } from "../../components/modals";
import { capitalize } from "../../utils/capitalize";
import { Button } from "../../components/ui";
import HelmetComponent from "../../components/HelmetComponent";

export default function TransferPlayer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const { tournamentId } = useParams();
  const [searchParam] = useSearchParams("");
  const teamId = searchParam.get("team") || "";
  const playerId = searchParam.get("player") || "";
  const { data: tournament } = useGetTournamentQuery({
    id: tournamentId as string,
  });
  const selectedTeamObj = tournament?.teams.find((teamObj) => {
    const team = teamObj.team as Team;
    return team._id === teamId;
  });
  const selectedTeam = selectedTeamObj?.team as Team;
  const players = selectedTeamObj?.players as TPlayer[];
  const player = players?.find((player) => player._id === playerId) as TPlayer;
  const [toTeam, setToTeam] = useState<Team | null>(null);
  const [transferPlayer] = useTransferPlayerMutation();

  // open graph data
  const ogData = {
    title: "Leagues Arena - Transfer Player",
    description: "Transfer a player to another team on Leagues Arena",
    url: `https://${window.location.host}/competitions/${tournamentId}/transfer`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  const handleTransfer = (toTeamId: string) => {
    transferPlayer({ playerId, fromTeamId: teamId, tournamentId, toTeamId })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setSuccessModal(true);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(false));
      });
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex w-full flex-col items-center pb-24">
        <div className="w-full pb-20 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 px-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Player Transfer</h3>
          </div>
          <p className="self-start text-2xl">
            <span className="capitalize">{selectedTeam?.name}</span> Squad in{" "}
            <span className="capitalize">{tournament?.name}</span>
          </p>
          {player ? (
            <>
              <div className="mt-6">
                <PlayerBadge
                  key={player?._id}
                  appearance={player?.apps}
                  assist={player?.assists}
                  avatar={player?.avatar}
                  playerName={player?.name}
                  goals={player?.goals}
                  playerWing={player?.designation}
                />
              </div>
              <div className="mt-6 flex flex-col gap-4">
                <p className="text-2xl">Transfer To</p>
                <div className="mt-4 grid w-full gap-4 lg:grid-cols-3">
                  {tournament?.teams.map((teamObj) => {
                    const team = teamObj.team as Team;
                    return (
                      <TeamCard
                        key={team._id}
                        avatar={team?.avatar}
                        name={team.name}
                        color={team?.color}
                        draws={team.draws}
                        wins={team.wins}
                        losses={team.losses}
                        played={team.played}
                        players={teamObj?.players.length}
                        id={team._id}
                        members={teamObj?.players as string[]}
                        isTransfer
                        disabled={team._id === teamId}
                        handleTransfer={() => {
                          setToTeam(team);
                          setConfirmModal(true);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <div className="flex min-h-[400px] flex-col items-center justify-center gap-2 py-4">
              <p className="text-2xl">Something went wrong</p>
              <Button
                role="organizer"
                intent={"primary"}
                onClick={() => navigate(-1)}
              >
                <LaArrowLeft stroke="#fff" />
                Go Back
              </Button>
            </div>
          )}
        </div>
      </div>
      {confirmModal && (
        <ConfirmPopup
          heading="Transfer player"
          description={`Are you sure you want to transfer ${capitalize(
            player?.name
          )} Magudo from ${capitalize(selectedTeam?.name)} to ${capitalize(
            toTeam?.name
          )}?`}
          type="warning"
          closeIcon
          handleClose={() => {
            setConfirmModal(false);
          }}
          warning="The involved parties would be notified"
          buttons={[
            {
              class: "primary",
              handleClick: () => {
                setConfirmModal(false);
                handleTransfer(toTeam?._id as string);
              },
              text: "Yes, transfer",
              role: "organizer",
            },
            {
              class: "tertiary",
              text: "No, Cancel",
              handleClick: () => setConfirmModal(false),
            },
          ]}
        />
      )}
      {successModal && (
        <ConfirmPopup
          heading="Success"
          description={`${capitalize(
            player?.name
          )} has been transferred from ${capitalize(
            tournament?.name
          )} to ${capitalize(toTeam?.name)}. This team will be notified`}
          type="success"
          buttons={[
            {
              class: "primary",
              text: "Done",
              handleClick: () => {
                setSuccessModal(false);
                navigate(-1);
              },
            },
          ]}
        />
      )}
    </>
  );
}
