function LaGalleryEdit({ stroke = "#fff" }: { stroke?: string; }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 33 32" fill="none">
      <path
        d="M12.5 13.333A2.667 2.667 0 1012.5 8a2.667 2.667 0 000 5.333z"
        stroke={stroke}
        strokeWidth={3}
      />
      <path
        d="M17.833 2.667H12.5c-6.667 0-9.334 2.666-9.334 9.333v8c0 6.667 2.667 9.333 9.334 9.333h8c6.666 0 9.333-2.666 9.333-9.333v-6.667"
        stroke={stroke}
        strokeWidth={3}
      />
      <path
        d="M26.02 3.453l-4.84 4.84c-.187.187-.373.547-.4.814l-.267 1.853c-.093.667.374 1.133 1.04 1.04l1.854-.267c.253-.04.626-.213.813-.4l4.84-4.84c.84-.84 1.227-1.8 0-3.026-1.24-1.254-2.2-.854-3.04-.014zM25.327 4.147a4.379 4.379 0 003.026 3.026M4.06 25.267l6.573-4.414c1.054-.706 2.574-.626 3.52.187l.44.387c1.04.893 2.72.893 3.76 0l5.547-4.76c1.04-.894 2.72-.894 3.76 0l2.173 1.866"
        stroke={stroke}
        strokeWidth={3}
      />
    </svg>
  );
}

export default LaGalleryEdit
