function LaHelp({ stroke = " #1A1A1A" }: { stroke?: string }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" >
      <g
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M14.167 15.359h-3.334l-3.708 2.466a.83.83 0 01-1.292-.691v-1.775c-2.5 0-4.166-1.667-4.166-4.167v-5c0-2.5 1.666-4.167 4.166-4.167h8.334c2.5 0 4.166 1.667 4.166 4.167v5c0 2.5-1.666 4.167-4.166 4.167z"
          strokeMiterlimit={10}
        />
        <path d="M10 9.467v-.175c0-.567.35-.867.7-1.109.342-.233.683-.533.683-1.083A1.38 1.38 0 0010 5.717 1.38 1.38 0 008.617 7.1M9.996 11.459h.008" />
      </g>
    </svg>
  );
}

export default LaHelp;
