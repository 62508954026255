import React from "react";
import FeedCard from "../ui/cards/FeedCard";
import { useGetPostsQuery } from "../../redux/services/postService";
import { Spinner } from "../ui";
import { useAppSelector } from "../../redux/app/hooks";
import { useInView } from "react-intersection-observer";
import { RootState } from "../../redux/app/store";

function Feeds() {
  const { ref, inView } = useInView({ threshold: 0 });
  const [canIncrementPage, setCanIncrementPage] = React.useState(false);
  const [postList, setPostList] = React.useState<Post[]>([]);
  const { auth } = useAppSelector((state: RootState) => state);
  const updatedPost = useAppSelector((state: RootState) => state.post.sentPost);
  const myId = auth.myId;
  const [page, setPage] = React.useState(1);
  const {
    data: feeds = [],
    isLoading,
    isFetching,
  } = useGetPostsQuery({ page: page });

  //Handle scroll pagination
  React.useEffect(() => {
    let ignore = false;

    // Skip incrementing page if previous ops is pending.
    if (isFetching || isLoading) return;

    // Increment fetch page if criterial is meet.
    if (!ignore && inView && canIncrementPage) {
      setPage(page + 1);
      setCanIncrementPage(false);
    }

    return () => {
      ignore = true;
    };
  }, [inView, isLoading, isFetching]);

  // Process paginated data.
  React.useEffect(() => {
    let ignore = false;

    if (isFetching || isLoading) return; // Cancel on pending process

    // Cancel update on empty feeds array
    if (!feeds) return; // escapes TS undefined error.
    if (feeds?.length < 1 || !feeds?.length) {
      setCanIncrementPage(false);
      return;
    }

    // Update feed list
    if (!ignore && !updatedPost) {
      setPostList([...postList, ...feeds]);
      if (!(feeds?.length > 1)) {
        // Prevents fetching next page if new feed is empty
        setCanIncrementPage(false);
        return;
      }
      setCanIncrementPage(true);
    } else {
      setPostList([...feeds]);
    }

    return () => {
      ignore = true;
    };
  }, [isLoading, isFetching, feeds]);

  // Handle WS post data.
  // TODO: append socket data to list
  /**
   * This should handle updating new posts
   * to the list without page refresh
   * when a user creates or updates a post
   */

  const feedCards = postList.map((feed, idx) => {
    const liked = feed?.likers?.some((liker) => liker === myId);
    return (
      <FeedCard
        key={`${feed?.id}-${idx}`}
        avatar={feed?.creator?.avatar}
        username={feed?.creator?.name}
        role={feed?.creator?.role}
        note={feed?.note}
        image={feed?.image}
        likeCount={feed?.likeCount}
        timeStamp={feed.createdAt}
        liked={liked}
        id={feed._id}
        authorId={feed?.author?._id}
      />
    );
  });

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-5 overflow-hidden lg:max-w-lg">
          {feedCards}

          {/* inView */}
          {!isLoading && !isFetching && (feedCards?.length ?? 0) > 1 && (
            <div
              ref={ref}
              className="my-8 flex items-center justify-center text-center text-lg font-medium"
            />
          )}

          {/* Load spinner */}
          {(isLoading || isFetching) && (
            <div className="mb-8 mt-4 flex items-center justify-center">
              <Spinner />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default React.memo(Feeds);
