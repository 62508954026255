import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { LaClose, LaPlusIcon } from "../svg-icons";
import { useGetUserTournamentsQuery } from "../../redux/services/tournamentService";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { Button, Spinner } from "../ui";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import useOnClickOutside from "../../hooks/useClickedOutside";

type Props = {
  setSelectedCompetition: React.Dispatch<React.SetStateAction<string>>;
  setCompetitionTeams?: React.Dispatch<
    React.SetStateAction<Tournament["teams"]>
  >;
  setSelectedTeams?: React.Dispatch<React.SetStateAction<string[]>>;
  setMaxTeam: React.Dispatch<React.SetStateAction<number>>;
  selectedTeams?: string[];
  shouldDisable?: boolean;
};

export default function InviteForm({
  setSelectedCompetition,
  setCompetitionTeams,
  setSelectedTeams,
  selectedTeams,
  shouldDisable,
  setMaxTeam,
}: Props) {
  const { myId } = useAppSelector((state: RootState) => state.auth);
  const insideEl = React.useRef(null);
  const navigate = useNavigate();
  const { data: competitions, isLoading: competitionLoading } =
    useGetUserTournamentsQuery({ userId: myId });

  const competitionList = competitions?.map((competition, index) => {
    const teamData = competition.teams.map((team) => team);
    const teams = competition.teams.map((team) => team.team as string);
    const newSelectedTeams = selectedTeams
      ? selectedTeams.filter((team) => !teams.includes(team))
      : [];
    const added =
      selectedTeams && selectedTeams.some((team) => teams.includes(team));

    return (
      <Button
        key={index}
        role="organizer"
        intent="tertiary"
        className="flex cursor-pointer items-center justify-between capitalize"
        disabled={shouldDisable && added}
        onClick={() => {
          setSelectedTeams && setSelectedTeams(newSelectedTeams);
          setCompetitionTeams && setCompetitionTeams(teamData);
          setSelectedCompetition(competition._id);
          setMaxTeam(Math.abs(competition?.maxTeam));
        }}
      >
        <span className="flex items-center gap-2">
          <span className="inline-block h-6 w-6 rounded-full border-2 border-primary-black-darker" />
          <span>{competition?.name}</span>
        </span>

        {shouldDisable && added && (
          <span className="rounded bg-green px-2 py-1 text-white">Added</span>
        )}
      </Button>
    );
  });

  const handleClose = () => {
    setSelectedTeams && setSelectedTeams([]);
    setCompetitionTeams && setCompetitionTeams([]);
    setSelectedCompetition("none");
  };
  useOnClickOutside(insideEl, handleClose);

  return (
    <div className="fixed inset-0 right-auto z-[60] flex w-full items-end justify-end bg-black/10 lg:left-auto lg:right-0 lg:w-[82%]">
      <div
        className="flex w-full flex-col rounded border-t border-grey-light bg-white py-6 shadow lg:rounded-[16px_16px_0_0]  lg:py-10"
        ref={insideEl}
      >
        <div className="flex items-center justify-between border-b border-grey-light px-4 pb-4 lg:px-20">
          <h3 className="font-medium lg:text-2xl">
            Register teams to competition
          </h3>
          <button onClick={handleClose}>
            <LaClose />
          </button>
        </div>
        <div className="px-4 lg:px-20">
          <p className="my-4">Select from your list of active competition.</p>

          <div className="modern__scroll-bar mt-6 flex max-h-[250px] w-full flex-col overflow-y-scroll rounded border border-grey-light lg:max-h-[300px] ">
            {competitionLoading ? (
              <div className="flex w-full items-center justify-center py-2">
                <Spinner />
              </div>
            ) : (
              <>
                {competitionList}
                {(competitionList?.length ?? 0) === 0 && (
                  <EmptyStateAuto
                    description="You dont have any competition"
                    title="Nothing to see here"
                  />
                )}
              </>
            )}
          </div>

          <div className="flex flex-col">
            {competitionList ? (
              <p className="mt-4 flex items-center gap-2 text-primary-blue-darker">
                <span>Cant find?</span>{" "}
                <Link to="/competitions/create" className="font-bold">
                  + Create a new competition
                </Link>
              </p>
            ) : (
              <Button
                role="organizer"
                intent={"primary"}
                className="mt-6 items-center gap-1 lg:flex"
                onClick={() => navigate(`/competitions/create`)}
              >
                <LaPlusIcon stroke="#fff" /> New Competition
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
