import { leaguesArenaApi } from "./leaguesArenaApiSlice";

const ratingApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlayersToRate: builder.query<
      | {
          canRate: true;
          players: {
            player: TPlayer;
            checkedIn: boolean;
            hasRated: true;
            rating: number;
          }[];
          training: TTraining;
        }
      | undefined,
      void
    >({
      query: () => ({
        url: `/ratings`,
        method: "GET",
      }),
      providesTags: ["Rating"],
    }),
    ratePlayer: builder.mutation({
      query: ({ player, training }) => ({
        url: `/ratings`,
        method: "POST",
        body: { player, training },
      }),
      invalidatesTags: ["Rating", "Training"],
    }),
  }),
});
export const { useGetPlayersToRateQuery, useRatePlayerMutation } = ratingApi;
