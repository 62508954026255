import type { ReactElement } from "react";
import { LaStarWhite } from "../../svg-icons";

export interface GoldStarProps {}

export default function GoldStar({}: GoldStarProps): ReactElement {
  return (
    <span className="flex h-5 w-5 items-center justify-center rounded-full bg-[#FBBE28]">
      <LaStarWhite className="h-4 w-4" />
    </span>
  );
}
