import React from "react";
import CompletedCard from "../ui/cards/CompletedCard";
import MatchTable from "./MatchTable";
import MatchBracket from "./MatchBracket";
import { useParams, useSearchParams } from "react-router-dom";
import SeasonFixtures from "./SeasonFixtures";
import KnockoutFixtures from "./KnockoutFixtures";
import { Button, ButtonLink, Spinner } from "../ui";
import {
  useGenerateFixturesMutation,
  useGetTournamentMatchesQuery,
} from "../../redux/services/matchService";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { cn } from "../../utils/cn";

type Props = {
  tournament?: Tournament;
} & (
  | {
      format: "knockout";
      knockoutFixtures: { round: number; matches: KnockoutMatch[] }[];
    }
  | {
      format: "season";
      seasonFixtures: Match[];
    }
);

function TournamentMatches(props: Props) {
  const [tabParams] = useSearchParams();
  const activeTab = (tabParams.get("subTab") || "completed") as
    | "completed"
    | "fixtures"
    | "table"
    | "bracket";

  const [clickedIndex, setClickedIndex] = React.useState(Number);
  const { tournamentId } = useParams();
  const isKnockout = props.tournament?.format === "knockout";
  const { data: completedMatches, isLoading } = useGetTournamentMatchesQuery(
    { id: tournamentId, status: "completed" },
    { skip: activeTab !== "completed" }
  );
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [generateFixtures, { isLoading: isGeneratingFixtures }] =
    useGenerateFixturesMutation();

  const completedCards = completedMatches?.map((match, i) => {
    const [teamA, teamB] = match?.teamData ?? [];
    const matchCompletedTime = new Date(match.timeCompleted);
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - matchCompletedTime.getTime();
    const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
    const canEdit =
      timeDifference < twentyFourHoursInMillis || !match.timeCompleted;

    return (
      <CompletedCard
        key={match?._id}
        // Todo: make sure  the name on this match is the proper match round name
        round={match?.name}
        id={match?._id}
        venue={match?.location}
        date={match?.date}
        teamA={teamA}
        teamB={teamB}
        index={i}
        setClickedIndex={setClickedIndex}
        isOpen={clickedIndex === i}
        canEdit={canEdit}
      />
    );
  });

  function handleRegenerate() {
    generateFixtures({ tournamentId })
      .unwrap()
      .then(() => {
        console.log("done");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className="flex items-center gap-4 border-b border-grey-light px-4 py-4 text-base font-medium lg:px-0">
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=matches&subTab=fixtures`}
          intent="secondary"
          role={role}
          className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
            "border-grey-light text-grey": activeTab !== "fixtures",
          })}
        >
          Fixtures
        </ButtonLink>

        {!isKnockout && (
          <ButtonLink
            to={`/competitions/${tournamentId}?tab=matches&subTab=table`}
            intent="secondary"
            role={role}
            className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
              "border-grey-light text-grey": activeTab !== "table",
            })}
          >
            Table
          </ButtonLink>
        )}

        {isKnockout && (
          <ButtonLink
            to={`/competitions/${tournamentId}?tab=matches&subTab=bracket`}
            intent="secondary"
            role={role}
            className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
              "border-grey-light text-grey": activeTab !== "bracket",
            })}
          >
            Bracket
          </ButtonLink>
        )}
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=matches&subTab=completed`}
          intent="secondary"
          role={role}
          className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
            "border-grey-light text-grey": activeTab !== "completed",
          })}
        >
          Completed
        </ButtonLink>
      </div>

      {}
      {activeTab === "completed" && (
        <>
          {isLoading ? (
            <div className="flex w-full items-center justify-center py-4">
              <Spinner />
            </div>
          ) : (
            <>
              {!completedMatches?.length && (
                <EmptyStateAuto
                  title="Nothing to see here"
                  description="This tournament does not have any completed match yet. when it does it'll appear here"
                />
              )}
              <div className="flex flex-col gap-4 p-4 lg:px-0">
                {completedCards}
              </div>
            </>
          )}
        </>
      )}

      {activeTab === "fixtures" && (
        <div className="flex flex-col gap-4 py-4 lg:px-0">
          {props.format === "season" ? (
            <SeasonFixtures
              fixtures={props.seasonFixtures}
              tournamentAuthorId={props.tournament?.author}
            />
          ) : (
            <KnockoutFixtures
              fixtures={props.knockoutFixtures}
              tournamentAuthorId={props.tournament?.author}
            />
          )}
        </div>
      )}
      {activeTab === "table" && <MatchTable teams={props.tournament?.teams} />}
      {activeTab === "bracket" && props.format === "knockout" && (
        <MatchBracket
          fixtures={props.knockoutFixtures}
          tournamentComplete={!props.tournament?.ongoing}
        />
      )}
    </>
  );
}

export default TournamentMatches;
