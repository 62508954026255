import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const postApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query<Post[] | undefined, { page?: number }>({
      query: ({ page = 1 }) => ({
        method: "GET",
        url: `/posts/`,
        params: {
          page,
        },
      }),
      providesTags: ["Posts"],
      transformResponse: (response: { data: Post[] }) => response.data,
    }),
    getPost: builder.query<Post | undefined, { id?: string }>({
      query: ({ id }) => ({
        url: `/posts/${id}`,
        method: "GET",
      }),
      providesTags: ["Posts"],
      transformResponse: (response: { data: Post }) => response.data,
    }),
    createPost: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: `/posts/`,
        body,
      }),
      invalidatesTags: ["Posts"],
    }),
    updatePost: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/posts/${id}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Posts"],
    }),
    deletePost: builder.mutation({
      query: ({ id }) => ({
        url: `/posts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Posts"],
    }),
    getTournamentPosts: builder.query<
      Post[] | undefined,
      { tournamentId: string; page?: number }
    >({
      query: ({
        tournamentId,
        page,
      }: {
        tournamentId: string;
        page: number;
      }) => ({
        url: `/posts/tournamentPosts/${tournamentId}`,
        method: "GET",
        params: { page },
      }),
      transformResponse: (response: { data: Post[] }) => response.data,
      providesTags: ["Posts"],
    }),
  }),
});

export const {
  useCreatePostMutation,
  useGetPostsQuery,
  useGetPostQuery,
  useUpdatePostMutation,
  useGetTournamentPostsQuery,
  useDeletePostMutation,
} = postApi;
