import type { ReactElement } from "react";
import { PlanCard } from "../ui/cards";
import plans from "../../assets/data/plans";

interface TeamOwnerPlansProps {
  isYearly: boolean;
}

export default function TeamOwnerPlans({
  isYearly,
}: TeamOwnerPlansProps): ReactElement {
  return (
    <div className="grid gap-8 lg:grid-cols-3">
      {plans.teamOwners.map((plan) => {
        const price = isYearly ? plan.yearlyPrice : plan.monthlyPrice;
        const discount = isYearly ? plan.discount : undefined;
        return (
          <PlanCard
            buttonText={plan.buttonText}
            features={plan.features}
            isYearly={isYearly}
            planType="team"
            price={price! || plan.price!}
            type={plan.name}
            description={plan.description}
            discount={discount}
            discountedPrice={plan.discountedPrice}
            duration={plan.duration}
            key={plan.name}
            popular={plan.isPopular}
          />
        );
      })}
    </div>
  );
}
