import la404 from "../../assets/icons/404.svg";
import { Link } from "react-router-dom";
import HelmetComponent from "../../components/HelmetComponent";

function NotFound() {
  // open graph data
  const ogData = {
    title: "Leagues Arena - 404",
    description: "Page not found on Leagues Arena",
    url: `https://${window.location.host}/404`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };
  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="cloud__bg flex min-h-screen flex-col items-center justify-center gap-3 bg-white px-4 py-20">
        <img src={la404} alt="404 image" />
        <h3 className="text-center text-2xl  text-primary-blue-dark  md:text-5xl">
          Ouch! something is missing
        </h3>
        <p className="text-center text-base text-primary-blue-dark md:text-2xl">
          Sorry, we can’t find the page you are looking for
        </p>

        <Link
          to="/home"
          className=" mt-10 rounded bg-primary-blue-dark px-20 py-4 text-base text-white hover:bg-primary-blue-light hover:text-black md:text-2xl"
        >
          Go to home page
        </Link>
      </div>
    </>
  );
}

export default NotFound;
