import { NavBar } from "../../components/navigations";
import { IoIosCheckmark } from "react-icons/io";
import { ReButton } from "../../components/ui/buttons/ReButton";
import compImg from "../../assets/images/comp.png";
import trainImg from "../../assets/images/train.png";
import rectImg from "../../assets/images/rect.png";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import Partners from "../../components/new-landing-page/Partners";
import { useGetTournamentsQuery } from "../../redux/services/tournamentService";
import {
  Discoverable,
  DiscoverTournamentsCard,
} from "../../components/discover";
import svgPng from "../../assets/images/SVG.png";
import ccp from "../../assets/images/brand/ccp.png";
import ttp from "../../assets/images/brand/ttp.png";
import shk from "../../assets/images/shk.png";
import mage_trophy from "../../assets/images/mage_trophy.png";
import { useGetSpotLightQuery } from "../../redux/services/spotLightService";
import {
  MediaCardSkeleton,
  PostMediaCard,
} from "../../components/ui/cards/MediaCards";

import ball from "../../assets/images/feat/ball.svg";
import boot from "../../assets/images/feat/boot.svg";
import cap from "../../assets/images/feat/cap.svg";
import cup from "../../assets/images/feat/cup.svg";
import field from "../../assets/images/feat/field.svg";
import step from "../../assets/images/feat/step.svg";
import tennis from "../../assets/images/feat/tennis.svg";
import wis from "../../assets/images/feat/wis.svg";
import live from "../../assets/images/feat/live.png";
import whistle from "../../assets/images/Whistle.png";

import epty1 from "../../assets/images/ld/epty1.svg";
import fball1 from "../../assets/images/ld/fball1.svg";
import field1 from "../../assets/images/ld/field1.svg";
import gold1 from "../../assets/images/ld/gold1.svg";
import tomGLogo from "../../assets/partners/tom_g_fitness.png";
import LeaguesArenaTraining from "../../components/new-landing-page/LeaguesArenaTraining";
import DropDownButton from "../../components/ui/dropdown-button/DropDownButton";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../../components/ui/DropDownMenu";
import SpotLightCard from "../../components/spotlight/SpotLightCard";

export default function CompetitionLandingIndex() {
  const { data, isLoading } = useGetSpotLightQuery({});
  const { data: tournaments = [], isLoading: tournamentLoading } =
    useGetTournamentsQuery({ count: 6 });
  const navigate = useNavigate();
  const tournamentCards = tournaments.map((tournament) => (
    <div className="min-w-[304px] max-w-[304px]" key={tournament._id}>
      <DiscoverTournamentsCard
        key={tournament?._id}
        tournamentId={tournament?._id}
        avatar={tournament?.avatar}
        banner={tournament?.banner}
        location={tournament.location}
        teams={tournament.teams.length}
        name={tournament.name}
        registrationFee={tournament.registrationFee}
      />
    </div>
  ));

  return (
    <>
      <NavBar />
      <section className="mx-auto flex h-screen max-h-[698px] max-w-screen-xl flex-col items-center justify-center border-l border-r border-gray-800/20">
        <div className="relative mx-6 mb-[76px]">
          <div className="absolute -top-[23%] left-0 h-[85px] w-[85px]  lg:-top-[20%] lg:left-[10%]">
            <img src={epty1} className="object-contain object-center" />
            <img
              src={whistle}
              className="absolute left-6 top-[30%] h-[30px] w-[30px] object-contain object-center"
            />
          </div>
          <img
            src={fball1}
            className="absolute -bottom-[14%] -right-4 h-[85px] w-[85px] object-contain object-center lg:bottom-[0] lg:right-[10%]"
          />
          <img
            src={field1}
            className="t-0 absolute -right-4 -top-[20%] h-[85px] w-[85px] object-contain object-center lg:-top-[20%] lg:right-[10%]"
          />
          <img
            src={gold1}
            className="absolute -bottom-[14%] left-0 h-[85px] w-[85px] object-contain object-center lg:bottom-[0] lg:left-[10%]"
          />
          <h2 className="relative z-10 text-center text-4xl font-black uppercase md:text-6xl">
            <span className="block bg-gradient-to-r from-[#0045F6] via-[#4F33F7B2]/70 to-[#4F33F7] bg-clip-text text-transparent">
              Bringing
            </span>
            <span className="inline-block bg-gradient-to-r from-[#0045F6] via-[#4F33F7B2]/70 to-[#4F33F7] bg-clip-text text-transparent">
              African sports online{" "}
            </span>
          </h2>
          <p className="relative z-10 mx-auto mb-[30px] mt-[10px] max-w-[534px] text-center text-[19px] text-[#1A1A1A]">
            Making African sports easy to access and enjoy with digital tools.
          </p>
          <div className="relative z-10 mb-[21px] flex items-center justify-center gap-6">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <ReButton>Get Started - it's free</ReButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="bg-white p-2">
                <DropdownMenuItem
                  className=" rounded-md hover:bg-primary-blue-dark hover:text-white"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  Leagues Competition
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="rounded-md hover:bg-primary-blue-dark hover:text-white"
                  onClick={() => {
                    window.location.href = "https://training.leaguesarena.com";
                  }}
                >
                  Leagues Training
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <ReButton variant={"outline"}>
              <a href="https://calendly.com/leaguesarena/astound-innovation-hub">
                Book a Demo
              </a>
            </ReButton>
          </div>
          <div className="relative z-10 flex items-center justify-center gap-3">
            <IoIosCheckmark size={18} />
            <span className="inline-block bg-gradient-to-r from-[#0045F6] via-[#4F33F7B2]/70 to-[#4F33F7] bg-clip-text text-[15px] text-transparent">
              No credit card required
            </span>
          </div>
        </div>
        <Partners />
      </section>
      <hr />

      <section className="mt-[100px]">
        <img
          src={compImg}
          className="mx-auto h-[65.1px] w-[259px] object-contain object-center"
        />
        <p className="mx-auto mt-[15px] max-w-[774px] text-center">
          Check out the latest competitions on Leagues Arena
        </p>
        <section className="mx-auto mt-[54px] flex max-w-screen-2xl flex-col-reverse flex-wrap items-center gap-[57px] px-3 md:flex-row md:items-stretch">
          <div className="flex-1 space-y-8">
            <div>
              <div className="hidden items-center justify-end lg:flex">
                {/* <h3 className="text-xl font-black">Upcoming Competition</h3> */}
                <Link
                  to={"/discover/competitions"}
                  className="text-primary-blue-dark"
                >
                  View All
                </Link>
              </div>
              <div className="mt-[30px] hidden flex-wrap items-start justify-start gap-8 lg:flex">
                {tournamentCards}
                <div className="flex min-h-[180px] w-[304px] flex-col items-center justify-center gap-3 rounded-lg border border-grey-light p-4 lg:h-[231px]">
                  <img src={mage_trophy} className="h-[74px] w-[74px]" />
                  <p className="mx-auto w-[190px] text-center">
                    Create your competition on the Go!
                  </p>
                  <ReButton
                    onClick={() => {
                      navigate("/competitions/create");
                    }}
                  >
                    Create Competition
                  </ReButton>
                </div>
              </div>

              <div className="w-full max-w-[90vw] lg:hidden">
                {!!tournamentCards.length && (
                  <Discoverable
                    title="Competition"
                    link="/discover/competitions"
                  >
                    {tournamentCards}
                    <div className="flex min-h-[180px] w-[304px] flex-col items-center justify-center gap-3 rounded-lg border border-grey-light p-4 lg:h-[231px]">
                      <img src={mage_trophy} className="h-[74px] w-[74px]" />
                      <p className="mx-auto w-[190px] text-center">
                        Create your competition on the Go!
                      </p>
                      <ReButton>Create Competition</ReButton>
                    </div>
                  </Discoverable>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="my-[88px] ">
        <div className="relative mb-[53px]">
          <h3 className="text-center text-[40px] font-black">Spotlights</h3>
          <span className="absolute -bottom-1 left-1/2 mx-auto h-[4px] w-full max-w-[135px] -translate-x-1/2 bg-[#FFC102]" />
        </div>

        <section className="relative grid w-full grid-cols-3 justify-center gap-2 px-4 md:grid-cols-4 md:gap-4 lg:px-16">
          {isLoading &&
            Array.from(Array(6)).map((_, idx) => (
              <MediaCardSkeleton key={idx} />
            ))}
          {data?.spotLights?.map((spt: SpotLight, idx: number) => (
            <Link to={`/home?index=${idx}`}>
              <SpotLightCard spotlight={spt} key={spt._id} />
            </Link>
          ))}
        </section>

        <div className="mx-auto flex max-w-screen-lg flex-wrap items-stretch justify-between"></div>
      </section>

      <section className="mt-[100px]">
        <img
          src={trainImg}
          className="mx-auto h-[54.87px] w-[236px] object-contain object-center"
        />
        <p className="mx-auto mt-[15px] max-w-[774px] text-center">
          Check out individual stats and ranking on the leaderboard
        </p>
        <LeaguesArenaTraining />
      </section>

      <section className="my-[88px]">
        <div className="relative mb-[53px]">
          <h3 className="text-center text-[40px] font-black">Our Apps</h3>
          <span className="absolute -bottom-1 left-1/2 mx-auto h-[4px] w-full max-w-[135px] -translate-x-1/2 bg-[#FFC102]" />
        </div>

        <div className="mx-auto flex max-w-screen-lg flex-wrap items-stretch justify-center gap-3 md:justify-between">
          <div className="flex h-[384px] w-[306px] flex-col rounded-[16px] border border-gray-300 bg-white shadow">
            <div className="relative grid flex-1 place-items-center bg-[#F0F0F0]">
              <img
                src={ccp}
                className="h-[123.16px] w-[162px] object-contain object-center"
              />
              <img src={svgPng} className="absolute right-4 top-3" />
            </div>
            <div className="space-y-[12px] p-[20px] text-center">
              <h4 className="text-[19px] font-bold text-[#1A1A1A]">
                Competition
              </h4>
              <p>Manage competitions and stats seamlessly.</p>
              <ReButton
                variant={"secondary"}
                size={"lg"}
                onClick={() => {
                  navigate("/competitions/create");
                }}
              >
                Create Competition
              </ReButton>
            </div>
          </div>

          <div className="flex h-[384px] w-[306px] flex-col rounded-[16px] border border-gray-300 bg-white shadow">
            <div className="relative grid flex-1 place-items-center bg-[#EDF2FF]">
              <img
                src={ttp}
                className="h-[123.16px] w-[162px] object-contain object-center"
              />
              <img src={svgPng} className="absolute right-4 top-3" />
            </div>
            <div className="space-y-[12px] p-[20px] text-center">
              <h4 className="text-[19px] font-bold text-[#1A1A1A]">Training</h4>
              <p>Monitor your progress and climb up the leaderboard.</p>
              <ReButton
                size={"lg"}
                onClick={() => {
                  window.location.href = "https://training.leaguesarena.com";
                }}
              >
                Join Training
              </ReButton>
            </div>
          </div>

          <div className="flex h-[384px] w-[306px] flex-col rounded-[16px] border border-gray-300 bg-white shadow">
            <div className="relative grid flex-1 place-items-center overflow-hidden bg-[#EDF2FF]">
              <img
                src={shk}
                className="h-full w-full object-cover object-center"
              />
              <img src={svgPng} className="absolute right-4 top-3" />
            </div>
            <div className="space-y-[12px] p-[20px] text-center">
              <h4 className="text-[19px] font-bold text-[#1A1A1A]">Bookings</h4>
              <p>Find and book available Turfs across your area.</p>
              <ReButton variant={"soon"} size={"lg"}>
                Coming Soon
              </ReButton>
            </div>
          </div>
        </div>
      </section>

      <section className="my-[88px]">
        <div className="relative mb-[53px]">
          <h3 className="text-center text-[40px] font-black">
            Show The World How It's Done.
          </h3>
          <span className="absolute -bottom-1 left-1/2 mx-auto h-[4px] w-full max-w-[135px] -translate-x-1/2 bg-[#FFC102]" />
        </div>

        <div className="grid grid-cols-2 items-stretch justify-center gap-[10px] px-3 md:flex md:flex-wrap md:gap-[33px]">
          {ft.map((f) => (
            <div
              key={f.title}
              className="flex max-w-[304.52px] flex-col gap-[10px] rounded p-[30px] shadow"
            >
              <img
                src={f.img}
                className="aspect-auto h-[57.11px] self-start object-contain"
              />
              <p className="text-[19.56px] font-bold text-[#1A1A1A]">
                {f.title}
              </p>
              <p className="text-[13px] text-[#445D65]">{f.des}</p>
              <ReButton
                variant={"outline"}
                onClick={() => {
                  if (f.external) {
                    window.location.href = f.lk;
                  } else {
                    navigate(f.lk);
                  }
                }}
              >
                Discover
              </ReButton>
            </div>
          ))}
        </div>
      </section>

      <section className="relative grid h-[759px] max-w-[100vw] items-center justify-center overflow-x-hidden">
        <img
          src={rectImg}
          className="absolute h-full w-full object-contain object-center"
        />

        <div className="z-10 mx-auto max-w-screen-xl px-2">
          <h4 className="mb-[3px] text-center">Client Testimonals</h4>
          <h3 className="mb-[23px] text-center text-[30px] font-black text-[#1A1A1A]">
            Why teams love Leagues Arena
          </h3>
          <section className="relative h-[380px] max-w-[794px] rounded-lg border border-[#ECECEC] bg-white p-[43px] text-center md:aspect-video">
            <div className="flex justify-center">
              <img src={tomGLogo} alt="tom G logo" className="" />
            </div>
            <p className="my-[21px]">
              The Leagues Arena cup could be the first ever in Africa, which is
              really exciting. It’s amazing to think that such a special event
              has never happened here before!”.
            </p>
            <h3 className="text-[24px] font-black">Tom George</h3>
            <p>CEO, TOM G FITNESS</p>
          </section>
        </div>
      </section>

      <Footer />
    </>
  );
}

const ft: {
  img: string;
  title: string;
  des: string;
  lk: string;
  external?: boolean;
}[] = [
  {
    img: wis,
    title: "Teams",
    des: "check out the best teams in the country. ",
    lk: "/discover/teams",
  },
  {
    img: boot,
    title: "Player",
    des: "Check out the best players in the country.",
    lk: "/discover/players",
  },
  {
    img: step,
    title: "Top 3 Players",
    des: "Check out the top players on our leaderboard.",
    lk: "https://training.leaguesarena.com/leaderboard?team=CMT",
    external: true,
  },
  {
    img: live,
    title: "Live Update",
    des: "check out real time updates on ongoing competitions.",
    lk: "/discover/competitions",
  },
  {
    img: ball,
    title: "Fixtures",
    des: "Check out all the fixtures for the ongoing competitions.",
    lk: "/discover/competitions",
  },
  {
    img: field,
    title: "Venues",
    des: "Check available turfs for booking your next game.",
    lk: "",
  },
  {
    img: cap,
    title: "Post",
    des: "Upload photos/videos to feature yourself on the spotlight.",
    lk: "/home",
  },
  {
    img: cup,
    title: "Discover",
    des: "See what's happening in Leagues arena competition now.",
    lk: "/discover",
  },
  {
    img: tennis,
    title: "AI Generated Scorecard",
    des: "Check out full match details with our leagues Arena generated scorecard ",
    lk: "",
  },
];
