import React from "react";
import { LaGreenGood } from "../../svg-icons";
import { useAppDispatch } from "../../../redux/app/hooks";
import { setToastCopied } from "../../../redux/features/modal/displayModalSlice";

function ToastCopied() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setToastCopied(false));
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg__shadow fixed right-4 top-4 z-[60] flex items-center gap-2 rounded bg-[#3C8505] p-6 py-2 text-2xl font-medium text-white md:bottom-10 md:right-10 md:top-auto">
      <LaGreenGood className="text-white" fill="#fff" />
      <p>Copied</p>
    </div>
  );
}

export default ToastCopied;
