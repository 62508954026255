import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../select";
import { buttonVariants } from "../ui/buttons/ReButton";

type Props = {
  options: { label: string; value: string }[];
};
export function TeamSelect(props: Props) {
  return (
    <Select defaultValue={"CMT"}>
      <SelectTrigger className="w-[180px]">
        <SelectValue
          placeholder="Team"
          className={buttonVariants({ variant: "outline" })}
        />
      </SelectTrigger>
      <SelectContent>
        {props.options.map((option) => (
          <SelectItem
            className="group rounded-md from-[#0045F6] to-[#4F33F7] hover:bg-gradient-to-br"
            value={option.value}
            key={option.value + option.label}
          >
            <span className="group-hover:text-white">{option.label}</span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
