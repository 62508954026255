import { useNavigate, useParams } from "react-router-dom";
import { LaArrowLeft } from "../../components/svg-icons";
import { ScoreCard } from "../../components/ui/cards";
import { useGetTournamentMatchesQuery } from "../../redux/services/matchService";
import { Spinner } from "../../components/ui";
import HelmetComponent from "../../components/HelmetComponent";

function Scores() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: matches = [], isLoading: matchesLoading } = useGetTournamentMatchesQuery({
    id,
  });

  const scoresCards = matches.map((match) => {
    return <ScoreCard match={match} key={match._id} />;
  });

  // open graph data
  const ogData = {
    title: "Leagues Arena - Enter Scores",
    description: "Enter scores for the matches",
    url: `https://${window.location.host}/competitions/scores${id}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {matchesLoading ? (
        <div className="flex min-h-screen items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center pb-10">
          <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:px-0 lg:pb-0">
            <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
              <button
                aria-label="back"
                className="flex items-center gap-2 text-xl"
                onClick={() => navigate(-1)}
              >
                <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
              </button>
              <h3 className="text-xl font-bold">Enter Scores</h3>
            </div>

            <div className="mt-4 flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-5">
              {scoresCards}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Scores;
