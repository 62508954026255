import { useLocation, Navigate } from "react-router";
import jwtDecode from "jwt-decode";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setIsBoarded,
  setIsLoggedin,
  setMyId,
} from "../../redux/features/auth/authSlice";
import { setUsername } from "../../redux/features/user/userSlice";
import { setToastError } from "../../redux/features/modal/displayModalSlice";

function GoogleAuth() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const token = location.search.split("=")[1];
  try {
    const userInfo: Token = jwtDecode(token) ?? {};
    localStorage.setItem("token", token);
    dispatch(setIsLoggedin(true));
    dispatch(setIsBoarded(userInfo.onBoarded));
    dispatch(setMyId(userInfo._id));
    dispatch(setUsername(userInfo.username));
    return <Navigate to="/account/select-profile" />;
  } catch (error) {
    console.log(error);
    dispatch(setToastError(true));
  }

  return <Navigate to="/account/login" />;
}

export default GoogleAuth;
