import React from "react";
import { useReadGroupTableQuery } from "../../redux/services/tournamentService";
import { groupByGroupIndex } from "../../utils/groupStage";
import { alphabets } from "../../utils/groupStage";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { Spinner } from "../ui";

interface Props extends React.PropsWithChildren {
  tournamentId: string;
}

export default function GroupStageTab(props: Props) {
  const { data, isLoading } = useReadGroupTableQuery({
    tournamentId: props.tournamentId,
  });

  const [groupedData, setGroupedData] = React.useState<{
    [groupIndex: number]: GroupedData[];
  }>({});

  React.useEffect(() => {
    if (!isLoading) {
      setGroupedData(groupByGroupIndex(data?.group as GroupedData[]));
    }
  }, [isLoading, data]);

  if (isLoading)
    return (
      <section className="flex flex-wrap justify-center gap-4 p-4">
        <Spinner />
      </section>
    );

  if (!isLoading && !data?.group?.length) {
    return (
      <EmptyStateAuto
        title="Nothing to see here"
        description="This tournament does not have any group stage yet. when it does it'll appear here"
      />
    );
  }

  return (
    <section className="flex flex-wrap gap-4 p-4">
      {groupedData && !isLoading && (
        <>
          {Object.entries(groupedData)?.map((group, i) => (
            <GroupTable group={group} key={i} />
          ))}
        </>
      )}
    </section>
  );
}

function GroupTable({ group }: { group: [string, GroupedData[]] }) {
  const [groupIndex, groupData] = group;

  return (
    <div className="w-full max-w-[390px] overflow-hidden">
      <div className="mb-1 font-medium capitalize">
        Group {alphabets[+groupIndex]}
      </div>
      <div className="rounded-md border border-primary-black-darker/30">
        <div className="grid grid-cols-9 justify-items-center border-b border-primary-black-darker/30 bg-primary-black-light/20 p-2">
          <p className="col-span-3 justify-self-start font-semibold">Teams</p>
          <p className="font-semibold">P</p>
          <p className="font-semibold">W</p>
          <p className="font-semibold">D</p>
          <p className="font-semibold">L</p>
          <p className="font-semibold">GD</p>
          <p className="font-semibold">PTS</p>
        </div>
        <div className="divide-prim/50 divide-y px-3 py-1">
          {groupData
            ?.sort((a, b) => {
              const aPoints =
                (a.teams.find((d) => d.team === a.teamData._id)?.wins ?? 0) *
                  3 +
                (a.teams.find((d) => d.team === a.teamData._id)?.draws ?? 0);
              const bPoints =
                (b.teams.find((d) => d.team === b.teamData._id)?.wins ?? 0) *
                  3 +
                (b.teams.find((d) => d.team === b.teamData._id)?.draws ?? 0);

              const aGoalDifference =
                (a.teams.find((d) => d.team === a.teamData._id)?.goalsScored ??
                  0) -
                (a.teams.find((d) => d.team === a.teamData._id)?.goalsAgainst ??
                  0);
              const bGoalDifference =
                (b.teams.find((d) => d.team === b.teamData._id)?.goalsScored ??
                  0) -
                (b.teams.find((d) => d.team === b.teamData._id)?.goalsAgainst ??
                  0);
              if (aPoints === bPoints) {
                return bGoalDifference - aGoalDifference; // Sort by goal difference if points are equal
              }

              return bPoints - aPoints; // Sort by points first
            })
            ?.map((team, idx) => {
              const ts = team.teams.find((d) => d.team === team.teamData._id);
              return (
                <div
                  key={team?.teamData?._id}
                  className="grid grid-cols-9 justify-items-center py-2"
                >
                  <div className="col-span-3 flex items-center gap-2 justify-self-start">
                    <p>{idx + 1}</p>
                    <img
                      src={team.teamData.avatar}
                      alt={team.teamName}
                      className="h-6 w-6 rounded-full object-center"
                    />
                    <p className="max-w-[100px] flex-1 break-words text-sm font-medium capitalize ">
                      {team.teamName}
                    </p>
                  </div>
                  <p>
                    {(ts?.wins ?? 0) + (ts?.draws ?? 0) + (ts?.losses ?? 0)}
                  </p>
                  <p>{ts?.wins ?? 0}</p>
                  <p>{ts?.draws ?? 0}</p>
                  <p>{ts?.losses ?? 0}</p>
                  <p>{(ts?.goalsScored ?? 0) - (ts?.goalsAgainst ?? 0)}</p>
                  <p>{(ts?.wins ?? 0) * 3 + (ts?.draws ?? 0)}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
