import React from 'react'
import { LaEmail, LaKey, LaProfile } from '../svg-icons'

type Props = {
  setActiveTab: React.Dispatch<React.SetStateAction<'edit-profile' | 'email-notifications' | 'change-password' | 'settings'>>
  activeTab: string
}

function SettingsTab({ setActiveTab }: Props) {
  return (
    <>
      <h3 className="mt-4 lg:mt-16 text-2xl font-medium lg:hidden">
        Settings
      </h3>

      <div className="mt-6 flex flex-col gap-2 rounded border border-[#D8D8D8] py-2 lg:hidden">
        <button
          className="flex items-center gap-2 p-3"
          type="button"
          onClick={() => setActiveTab("edit-profile")}
          data-display="edit-profile"
        >
          <LaProfile />
          Edit Profile
        </button>
        <button
          className="flex items-center gap-2 p-3"
          type="button"
          onClick={() => setActiveTab("email-notifications")}
          data-display="email-settings"
        >
          <LaEmail />
          Email Notification
        </button>
        <button
          className="flex items-center gap-2 p-3"
          type="button"
          onClick={() => setActiveTab("change-password")}
          data-display="password"
        >
          <LaKey />
          Change Password
        </button>
      </div>

    </>)
}

export default SettingsTab