import React from "react";
import { cn } from "../../../utils/cn";
import { VariantProps, cva } from "class-variance-authority";

type Ref = HTMLButtonElement;

export interface ButtonVariants
  extends VariantProps<ReturnType<typeof buttonVariants>> {}

type SVGComponent = React.ComponentType<React.SVGAttributes<SVGSVGElement>>;

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  intent: ButtonVariants["intent"];
  size?: ButtonVariants["size"];
  leadingIcon?: SVGComponent;
  trailingIcon?: SVGComponent;
  role: string;
}

const primary: {
  [key: string]: string[];
} = {
  player: [
    "bg-primary-blue-darker",
    "text-white",
    "border-transparent",
    "hover:bg-primary-blue-light",
  ],
  organizer: [
    "bg-primary-black-darker",
    "text-white",
    "border-transparent",
    "hover:bg-primary-black-light",
  ],
  team: [
    "bg-secondary-blue-600",
    "text-white",
    "border-transparent",
    "hover:bg-secondary-blue-500",
  ],
};
const secondary: {
  [key: string]: string[];
} = {
  player: [
    "bg-transparent",
    "border border-primary-blue-darker",
    "text-primary-blue-darker",
    "hover:bg-primary-blue-lighter",
  ],
  organizer: [
    "bg-transparent",
    "border border-primary-black-darker",
    "text-primary-black-darker",
    "hover:bg-primary-black-lighter",
  ],
  team: [
    "bg-transparent",
    "border border-secondary-blue-600",
    "text-secondary-blue-600",
    "hover:bg-secondary-blue-200",
  ],
};

const tertiary: {
  [key: string]: string[];
} = {
  player: [
    "bg-white",
    "text-primary-blue-darker",
    "hover:bg-primary-blue-lighter",
  ],
  organizer: [
    "bg-white",
    "text-primary-black-darker",
    "hover:bg-primary-black-lighter",
  ],
  team: [
    "bg-secondary-blue-100",
    "text-secondary-blue-600",
    "hover:bg-secondary-blue-200",
  ],
};

function buttonVariants(role: string) {
  return cva("font-medium rounded flex items-center gap-2 justify-center", {
    variants: {
      intent: {
        primary: primary[role],
        secondary: secondary[role],
        tertiary: tertiary[role],
        soft: [
          "grey-lighter",
          "text-primary-black-darker",
          "border-grey-light",
          "hover:bg-grey-light",
        ],
      },
      size: {
        small: ["text-sm", "py-2", "px-2"],
        medium: ["text-base", "py-3", "px-4"],
        large: ["text-2xl", "py-4", "px-4"],
      },
    },
    compoundVariants: [
      {
        intent: "primary",
        size: "medium",
        className: "",
      },
    ],
    defaultVariants: {
      intent: "primary",
      size: "medium",
    },
  });
}

const Button = React.forwardRef<Ref, IButtonProps>((props, ref) => {
  const {
    intent,
    size,
    role,
    children,
    className,
    leadingIcon: LeadingIcon,
    trailingIcon: TrailingIcon,
    ...rest
  } = props;

  return (
    <button
      ref={ref}
      className={cn(
        "disabled disabled:cursor-not-allowed disabled:opacity-50",
        buttonVariants(role)({
          intent,
          size,
          className,
        })
      )}
      {...rest}
    >
      {LeadingIcon && <LeadingIcon />}
      {children}
      {TrailingIcon && <TrailingIcon />}
    </button>
  );
});

Button.displayName = "Button";
export default Button;
