import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LaCheck, LaInfo } from "../svg-icons";
import { cn } from "../../utils/cn";

type FixturesNavigationProps = {
  draft: number;
};

function FixturesNavigation({ draft }: FixturesNavigationProps) {
  const stages = [
    {
      condition: draft === 0,
      stage: 1,
    },
    {
      condition: draft === 1,
      stage: 2,
    },

    {
      condition: draft === 2,
      stage: 3,
    },
  ];
  const currentStage = stages.find((stage) => stage.condition);
  const stage = currentStage?.stage || 1;

  return (
    <div className="h__modern__scroll__bar mb-[36px] mt-4 rounded-lg border border-dotted border-grey-light bg-white bg-[url(/src/assets/images/football-effect.webp)] pb-4 pt-8">
      <div className="px-4">
        <h3 className="text-xs">Follow this guide:</h3>
        <p className="text-sm">
          Three features have been generated. Click “Next” to move to the next
          generated feature, click “Previous” to go back to the previous
          generated feature. To accept a feature, click on “Accept & Publish”
        </p>
      </div>
      <div className="h__modern__scroll__bar overflow-x-scroll">
        <div className="grid min-w-max grid-cols-3 py-4 text-xs">
          <div className="flex w-full items-center">
            <div className="h-[2px]  flex-1 bg-primary-black-darker" />
            <button
              className={cn(
                "z-10 flex min-w-max items-center justify-center gap-2 rounded-full p-2 text-white lg:p-3",
                {
                  "bg-grey": stage > 1,
                  "bg-primary-black-darker": stage === 1,
                }
              )}
            >
              Draft 1{" "}
              <span className="rounded-full border border-white p-1">
                <LaCheck stroke="#fff" />
              </span>
            </button>
            <div
              className={cn("h-[2px] flex-1 bg-primary-black-lighter", {
                "bg-primary-black-darker": stage > 1,
              })}
            />
          </div>

          <div className="flex min-w-max flex-col items-center justify-center">
            <div className="flex w-full items-center">
              <div
                className={cn("h-[2px] flex-1", {
                  "bg-primary-black-darker": stage >= 2,
                  "bg-primary-black-lighter": stage < 2,
                })}
              />
              <button
                className={cn(
                  "z-10 flex min-w-max items-center justify-center gap-2  rounded-full p-2 lg:p-3",
                  {
                    "bg-primary-black-darker text-white": stage === 2,
                    "bg-grey text-white": stage > 2,
                    "border border-grey text-grey": stage < 2,
                  }
                )}
              >
                Draft 2
                {stage > 1 && (
                  <span className="rounded-full border border-white p-1">
                    <LaCheck stroke="#fff" />
                  </span>
                )}
              </button>
              <div
                className={`flex-1  ${
                  stage > 2
                    ? "bg-primary-black-darker"
                    : "bg-primary-black-lighter"
                } h-[2px]`}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="flex w-full items-center">
              <div
                className={`flex-1  ${
                  stage > 2
                    ? "bg-primary-black-darker"
                    : "bg-primary-black-lighter"
                } h-[2px]`}
              />
              <button
                className={cn(
                  "z-10 flex min-w-max items-center justify-center gap-2  rounded-full p-2 lg:p-3",
                  {
                    "bg-primary-black-darker text-white": stage === 3,
                    "bg-grey text-white": stage > 3,
                    "border border-grey text-grey": stage < 3,
                  }
                )}
              >
                Draft 3{" "}
                {stage > 2 && (
                  <span className="rounded-full border border-white p-1">
                    <LaCheck stroke="#fff" />
                  </span>
                )}
              </button>
              <div
                className={`flex-1  ${
                  stage > 3
                    ? "bg-primary-black-darker"
                    : "bg-primary-black-lighter"
                } h-[2px]`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FixturesNavigation;
