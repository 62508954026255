export function getMediaType(url: string): string {
  const extension = url.split(".").pop()?.toLowerCase();

  if (extension && ["png", "jpg", "jpeg", "gif", "bmp"].includes(extension)) {
    return "image";
  }

  if (extension && ["mp4", "webm", "ogg", "mov", "avi"].includes(extension)) {
    return "video";
  }

  return "image";
}
