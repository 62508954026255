import teamSlideOne from "../../assets/images/team-slide-one.webp";
import teamSlideOneM from "../../assets/images/team-slide-one-m.webp";
import teamSlideTwo from "../../assets/images/team-slide-two.webp";
import teamSlideTwoM from "../../assets/images/team-slide-two-m.webp";
import teamSlideThree from "../../assets/images/team-slide-three-m-copy.jpg";
import teamSlideThreeM from "../../assets/images/team-slide-three-m.webp";

export function TeamSlideOne() {
  return (
    <div className="relative h-full w-full">
      <img
        src={teamSlideOne}
        alt="onboarding"
        className="hidden h-full w-full object-fill lg:block"
      />
      <img
        src={teamSlideOneM}
        alt="onboarding"
        className="h-full w-full object-fill lg:hidden"
      />
    </div>
  );
}

export function TeamSlideTwo() {
  return (
    <div className="relative h-full w-full">
      <img
        src={teamSlideTwo}
        alt="onboarding"
        className="hidden h-full w-full object-fill lg:block"
      />
      <img
        src={teamSlideTwoM}
        alt="onboarding"
        className="h-full w-full object-fill lg:hidden"
      />
    </div>
  );
}

export function TeamSlideThree() {
  return (
    <div className="relative h-full w-full">
      <img
        src={teamSlideThree}
        alt="onboarding"
        className="hidden h-full w-full object-fill lg:block"
      />

      <img
        src={teamSlideThreeM}
        alt="onboarding"
        className="h-full w-full object-fill lg:hidden"
      />
    </div>
  );
}
