import React, { PropsWithChildren } from "react";
import { LaPostIcon } from "../../svg-icons";
import { useAppSelector } from "../../../redux/app/hooks";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../hooks/useClickedOutside";
import { RootState } from "../../../redux/app/store";

import useHandleActionButton from "../../../hooks/useHandleActionButton";
import { BsTrophyFill } from "react-icons/bs";

function FloatingButton({ children }: PropsWithChildren) {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const isOrganizer = role === "organizer";
  const [displayMenu, setDisplayMenu] = React.useState(false);

  const buttonsContEl = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(buttonsContEl, () => setDisplayMenu(false));

  const { handleClick } = useHandleActionButton();

  function handleDisplayMenu() {
    setDisplayMenu(!displayMenu);
  }

  return (
    <>
      {displayMenu && (
        <div className="fixed left-0 top-0 z-30 flex h-screen w-full items-end justify-end bg-[#ffffffed] px-4 pb-20 lg:hidden">
          <div
            className="relative bottom-[10%] right-[18px] flex flex-col items-end gap-6 font-medium"
            ref={buttonsContEl}
          >
            <button
              className="flex items-center gap-4 text-right"
              data-item={`${
                isOrganizer ? "competition_spotlight" : "spotlight"
              }`}
              onClick={handleClick}
            >
              <p>
                <span>Post Spotlight</span>
              </p>

              <span className="flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-lg">
                <LaPostIcon />
              </span>
            </button>

            {isOrganizer && (
              <button
                className=" flex items-center gap-4 text-right"
                onClick={handleClick}
                data-item="create competition"
              >
                <span className="mr-10">Create Competition</span>
                <span className="relative flex items-center">
                  <span className="absolute -right-[18px]  flex h-16 w-16 items-center justify-center rounded-full bg-primary-black-darker">
                    <BsTrophyFill className="h-7 w-7 text-white" />
                  </span>
                </span>
              </button>
            )}
          </div>
        </div>
      )}
      <button onClick={handleDisplayMenu}>{children}</button>
    </>
  );
}

export default FloatingButton;
