import { Spinner } from "../ui";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import ManageTeams from "./ManageTeams";

type TournamentTeamsTabProps = {
  tournament?: Tournament;
  tournamentLoading?: boolean;
};
function TournamentTeamsTab({
  tournament,
  tournamentLoading,
}: TournamentTeamsTabProps) {
  const { myId: userId, role } = useAppSelector(
    (state: RootState) => state.auth
  );
  const isTournamentOwner =
    tournament?.author === userId && role === "organizer";
  return (
    <div className="flex flex-col gap-4 px-4 lg:px-0">
      {tournamentLoading ? (
        <div className="my-2 flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        tournament && (
          <ManageTeams
            teamData={tournament?.teams}
            tournamentId={tournament?._id}
            tournamentName={tournament?.name}
            isTournamentOwner={isTournamentOwner}
          />
        )
      )}
    </div>
  );
}
export default TournamentTeamsTab;
