import React from "react";
import { useUpdateFixturesMutation } from "../../redux/services/matchService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { LaClose } from "../svg-icons";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { useForm } from "react-hook-form";
import { cn } from "../../utils/cn";

type Props = {
  matchData: {
    location?: string;
    id?: string;
    date?: string;
    time?: any;
    teamData: [
      { shortName: string; avatar: string },
      { shortName: string; avatar: string }
    ];
  };
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

type TFormValues = {
  location: string;
  time: Date;
  date: string;
};

function EditFixtureModal({ matchData, setIsEditing }: Props) {
  const dispatch = useAppDispatch();
  const [updateFixtures] = useUpdateFixturesMutation();
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    formState: { errors },
  } = useForm<TFormValues>({
    defaultValues: {
      location: matchData.location,
    },
  });
  const [teamA, teamB] = matchData.teamData;

  function onSubmit(data: TFormValues) {
    const time = new Date(data.time);
    if (time < new Date()) {
      return setError("time", {
        type: "manual",
        message: "Time cannot be in the past",
      });
    }

    updateFixtures({ id: matchData?.id, ...data })
      .then((res) => {
        console.log(res);
        dispatch(setToastSuccess(true));
        setIsEditing(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="absolute bottom-0 left-0 top-0 w-full justify-center bg-black/20 lg:flex">
      <div className="flex h-screen w-full items-center justify-center">
        <div className="fixed bottom-0 left-0 z-[60] flex w-full flex-col items-center rounded border-t border-grey-light bg-white shadow lg:relative lg:bottom-auto lg:flex lg:w-[90%] lg:rounded-2xl">
          <div className="w-full lg:w-[60%] lg:py-10">
            <div className="flex items-center justify-between border-b border-grey-light px-4 py-5 lg:flex-col lg:border-0">
              <h3 className="text-center font-medium lg:text-4xl">
                Edit match details
              </h3>
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="right-10 top-10 lg:absolute"
              >
                <LaClose />
              </button>
            </div>

            <div className="mt-5 flex justify-between px-4">
              <div className="flex w-[100px] flex-col items-center gap-3">
                <img
                  src={teamA?.avatar}
                  alt="team logo"
                  className="h-10 w-10 rounded-full"
                />
                <h3 className="text-center uppercase">{teamA?.shortName}</h3>
              </div>
              <div className="flex flex-col items-center gap-4">
                <h3 className="font-medium">Match {1}</h3>
                <p className="font-medium">VS</p>
              </div>
              <div className="flex w-[100px] flex-col items-center gap-3">
                <img
                  src={teamB?.avatar}
                  alt="team logo"
                  className="h-10 w-10 rounded-full"
                />
                <h3 className="text-center uppercase">{teamB?.shortName}</h3>
              </div>
            </div>

            <form
              className="mt-6 flex flex-col gap-4 px-4 lg:gap-2"
              onSubmit={handleSubmit(onSubmit)}
            >
              <fieldset>
                <label htmlFor="date">
                  Date<span className="text-red">*</span>
                </label>
                <input
                  type="date"
                  id="date"
                  className={cn(
                    "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-primary-black-darker focus:border-opacity-30 lg:text-xl",
                    {
                      "error border-red focus:border-red": errors.date,
                    }
                  )}
                  {...register("date", {
                    required: "please select a date for the training",
                    maxLength: 230,
                    min: {
                      value: new Date().toISOString().split("T")[0],
                      message: "Date cannot be in the past",
                    },
                  })}
                  min={new Date().toISOString().split("T")[0]}
                />
                {errors.date?.message && (
                  <p className="text-xs italic text-red">
                    {errors.date.message}
                  </p>
                )}
              </fieldset>
              <fieldset className="flex flex-col gap-2">
                <label htmlFor="time" className="text-sm md:text-base">
                  Time
                  <span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  id="time"
                  hidden
                  {...register("time", {
                    required: "Time is required",
                  })}
                />
                {/* @ts-ignore */}
                <MobileTimePicker
                  openTo="hours"
                  className={`w-full bg-white`}
                  sx={{
                    width: "100%",
                    border: errors?.time?.message && "1px solid red",
                    borderRadius: "4px",
                  }}
                  ampm={false}
                  onChange={(time) => {
                    // @ts-ignore
                    setValue("time", time["$d"]);
                  }}
                />
                {errors.time?.message && (
                  <p className="text-xs italic text-red">
                    {errors.time.message}
                  </p>
                )}
              </fieldset>

              <fieldset className="flex flex-col gap-2">
                <label htmlFor="location">
                  Venue<span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  id="location"
                  className={cn(
                    "mt-3 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-primary-black-darker focus:border-opacity-30 lg:text-xl",
                    {
                      "error border-red focus:border-red": errors.location,
                    }
                  )}
                  {...register("location", {
                    required: "City park opp diamond bank wuse 2 ",
                    maxLength: 230,
                  })}
                  placeholder="Enter a venue"
                />
                {errors.location?.message && (
                  <p className="text-xs italic text-red">
                    {errors.location.message}
                  </p>
                )}
              </fieldset>

              <fieldset className="mt-[145px] flex flex-col pb-6 lg:mt-4">
                <button
                  className="rounded bg-primary-black-darker py-4 font-medium text-white lg:text-2xl"
                  type="submit"
                >
                  Save
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFixtureModal;
