import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const authApi = leaguesArenaApi.injectEndpoints({
  // reducerPath: "authApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: `${baseUrl}/api/auth`,
  //   prepareHeaders: (headers) => {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       headers.set("authorization", `Bearer ${token}`);
  //     }
  //     headers.set("Content-Type", "application/json");
  //     return headers;
  //   },
  // }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: "/auth/register",
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: email,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ resetToken, data }) => ({
        url: `/auth/reset-password/${resetToken}`,
        method: "POST",
        body: data,
      }),
    }),
    finalizeAccount: builder.mutation({
      query: ({ resetToken, data }) => ({
        url: `/auth/final-acct/${resetToken}`,
        method: "POST",
        body: data,
      }),
    }),
    resendFinalizedAcctToken: builder.mutation({
      query: ({ token }) => ({
        url: "/auth/final-acct-reset",
        method: "POST",
        body: { token },
      }),
    }),
    updatePassword: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: "/auth/update-password",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    googleLogin: builder.query<{ url: string }, string | void>({
      query: () => ({
        method: "GET",
        url: "/auth/google",
      }),
      transformResponse: (response: { url: string }) => response,
    }),
    verifyToken: builder.query({
      query: ({ token }) => ({
        url: `/auth/verify-token/${token}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
  useGoogleLoginQuery,
  useFinalizeAccountMutation,
  useResendFinalizedAcctTokenMutation,
  useVerifyTokenQuery,
} = authApi;
