import { LaArrowRight, LaLocation, LaPlayers, LaTag } from "../svg-icons";
import { Link } from "react-router-dom";
import { capitalize } from "../../utils/capitalize";
import formatToNaira from "../../utils/formatPrice";

type Props = {
  avatar: string;
  name: string;
  location: string;
  teams?: number;
  banner: string;
  registrationFee: string;
  tournamentId: string;
};

function DiscoverTournamentsCard({
  avatar,
  name,
  location,
  teams,
  banner,
  tournamentId,
  registrationFee,
}: Props) {
  const priceTag = registrationFee && formatToNaira(registrationFee);
  return (
    <Link to={`/competitions/${tournamentId}`}>
      <div className="relative w-full cursor-pointer">
        <img
          src={banner}
          alt="tournament banner"
          crossOrigin="anonymous"
          className="h-[180px] w-full rounded-lg lg:h-[231px]"
        />
        <div className="absolute bottom-0 flex w-full justify-between rounded-[0_0_8px_8px] border border-grey-light bg-white px-6 py-3 shadow-lg">
          <div className="flex w-full flex-col gap-1">
            <img
              src={avatar}
              alt="tournament avatar"
              crossOrigin="anonymous"
              className="absolute -top-4 h-8 w-8 rounded-full border border-white"
            />
            <div className="flex w-full items-end justify-between">
              <p className="text-base">{capitalize(name)}</p>
              <p className="flex items-center gap-1 bg-green-light px-1 text-green-dark">
                <LaTag />
                {tournamentId === "669ad1b6687ea94a8a1c1dd2"
                  ? formatToNaira("259600")
                  : priceTag
                  ? priceTag
                  : "Free"}
              </p>
            </div>

            <p className="flex items-center gap-1 text-xs font-medium">
              <span className="text-lg">
                <LaLocation />
              </span>
              {location}
            </p>
            <p className="flex items-center gap-1 text-xs font-medium">
              <span className="text-lg">
                <LaPlayers />
              </span>
              {teams === undefined || teams < 2
                ? `${teams} team`
                : `${teams} teams`}
            </p>
          </div>
        </div>
        <p className="absolute bottom-4 right-4 flex items-center gap-2 text-xs">
          View <LaArrowRight />
        </p>
      </div>
    </Link>
  );
}

export default DiscoverTournamentsCard;
