import type { ReactElement } from "react";
import { cn } from "../../../utils/cn";

interface ToggleLargeProps {
  leftText: string;
  rightText: string;
  handleChange: () => void;
  on: boolean;
}

export default function ToggleLarge({
  leftText,
  rightText,
  handleChange,
  on,
}: ToggleLargeProps): ReactElement {
  return (
    <>
      <p
        className={cn("select-none lg:text-2xl", {
          "text-primary-blue-darker": !on,
        })}
      >
        {leftText}
      </p>
      <label className="relative flex h-6 w-[50px] items-center px-1  lg:h-12 lg:w-[105px]">
        <input
          type="checkbox"
          name=""
          id=""
          onChange={handleChange}
          hidden
          className="input__toggle"
        />
        <span
          className={cn(
            "absolute z-50 h-4 w-4 rounded-full bg-primary-blue-darker duration-500 lg:h-10 lg:w-10",
            {
              "translate-x-[calc(100%+10px)] lg:translate-x-[calc(100%+16px)]":
                on,
            }
          )}
        />
        <span className="absolute bottom-0 left-0 right-0 top-0 cursor-pointer rounded-full bg-primary-blue-lighter duration-500" />
      </label>
      <p
        className={cn("select-none lg:text-2xl", {
          "text-primary-blue-darker": on,
        })}
      >
        {rightText}
      </p>
    </>
  );
}
