import type { ReactElement } from "react";
import { ToggleLarge } from "../ui";
import OrganizerPlans from "./OrganizerPlans";
import TeamOwnerPlans from "./TeamOwnerPlans";
import PlayerPlans from "./PlayerPlans";

export interface PricingPlanProps {
  planType: string;
  isYearly: boolean;
  setIsYearlyParams: (
    params: URLSearchParams | ((params: URLSearchParams) => URLSearchParams)
  ) => void;
}

export default function PricingPlan({
  planType,
  isYearly,
  setIsYearlyParams,
}: PricingPlanProps): ReactElement {
  // const [isYearlyParams, setIsYearlyParams] = useSearchParams();
  // const isYearlyString = isYearlyParams.get("isYearly") || "false";
  // const isYearly = isYearlyString === "true";

  const handleChange = () => {
    setIsYearlyParams(() => {
      const newIsYearly = !isYearly;
      const newParams = `planType=${planType}&isYearly=${newIsYearly}`;
      return new URLSearchParams(newParams);
    });
  };

  return (
    <div className="w-full px-4 pb-12 md:px-8 lg:px-20 lg:pb-24">
      <div className="flex w-full items-center justify-end gap-2 pb-8">
        <ToggleLarge
          handleChange={handleChange}
          leftText="Monthly"
          rightText="Yearly"
          on={isYearly}
        />
      </div>

      {planType === "organizer" && <OrganizerPlans isYearly={isYearly} />}
      {planType === "team" && <TeamOwnerPlans isYearly={isYearly} />}
      {planType === "player" && <PlayerPlans isYearly={isYearly} />}
    </div>
  );
}
