import * as React from "react";

function LaGallery(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={54} height={54} fill="none" {...props}>
      <g
        stroke="#BBB"
        strokeWidth={3.375}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.25 49.5h13.5C45 49.5 49.5 45 49.5 33.75v-13.5C49.5 9 45 4.5 33.75 4.5h-13.5C9 4.5 4.5 9 4.5 20.25v13.5C4.5 45 9 49.5 20.25 49.5z" />
        <path d="M20.25 22.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM6.008 42.64L17.1 35.191c1.778-1.193 4.343-1.058 5.94.315l.743.652c1.755 1.508 4.59 1.508 6.345 0l9.36-8.032c1.755-1.508 4.59-1.508 6.345 0l3.667 3.15" />
      </g>
    </svg>
  );
}

export default LaGallery;
