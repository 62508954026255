import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Password, PhoneCode, Select, Spinner } from "../ui";
import { useRegisterMutation } from "../../redux/services/authService";
import { validatePassword } from "../../utils/validatePassword";
import { useAppDispatch } from "../../redux/app/hooks";
import { setIsLoggedin, setMyId } from "../../redux/features/auth/authSlice";
import jwtDecode from "jwt-decode";
import { Country } from "country-state-city";
import PhoneCodeDropdown from "../ui/phone-code-dropdown/PhoneCodeDropdown";
import socket from "../../socket";
import { cn } from "../../utils/cn";

interface SignInFormType {
  email: string;
  password: string;
  confirmPassword: string;
  gender: string;
  phone: string;
  fullName: string;
}

function SignupForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [registerUser, { isLoading }] = useRegisterMutation();
  const [inputError, setInputError] = useState<InputError<SignInFormType>>({
    email: "",
    fullName: "",
    phone: "",
    gender: "",
    password: "",
    confirmPassword: "",
    clearError: function (value) {
      setInputError((prev) => ({ ...prev, [value]: "" }));
    },
  });

  const countryNamesAndFlags = Country.getAllCountries().map((country) => {
    let code = country.phonecode.includes("-")
      ? `+${country.phonecode.split("-")[1]}`
      : `+${country.phonecode}`;

    code = code.includes("and") ? code.split("and")[0] : code;

    return {
      name: country.name,
      flag: country.flag,
      code,
    };
  });

  const [selectedCode, setSelectedCode] = useState(countryNamesAndFlags[160].code);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (inputError.email) {
      const emailInput = document.getElementById("email");
      if (!emailInput) return;
      const position = emailInput.getBoundingClientRect();
      window.scrollTo({
        top: Math.abs(position.top),
        left: 0,
        behavior: "smooth",
      });
    }
  }, [inputError]);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formElements = e.currentTarget.elements as FormElements<keyof SignInFormType>;
    if (checkEmptyFields(formElements)) {
      setInputError((prev) => ({ ...prev, gender: "Required" }));
      return;
    }
    if (!validatePassword(formElements.password.value)) {
      setInputError((prev) => ({ ...prev, password: "weak" }));
      return;
    }
    if (!matchPasswords(formElements)) {
      setInputError((prev) => ({
        ...prev,
        password: "passwords do not match",
        confirmPassword: "passwords do not match",
      }));
      return;
    }
    registerUser({
      email: formElements.email.value,
      phone: `${formElements.phone.value}`,
      phonecode: selectedCode,
      fullName: formElements.fullName.value,
      password: formElements.password.value,
      gender: formElements.gender.value,
    })
      .unwrap()
      .then((res) => {
        // saves the jwt token and redirects the user to the onboarding page
        const userInfo: Token = jwtDecode(res.token);
        localStorage.setItem("token", res.token);
        dispatch(setIsLoggedin(true));
        // dispatch(setIsBoarded(userInfo.onBoarded));
        dispatch(setMyId(userInfo._id));
        // Setup socket notification sys.
        const claimedId = userInfo._id;
        if (socket.connected) {
          socket.disconnect();
          socket.auth = { claimedId };
          socket.connect();
        }
        navigate("/account/select-profile");
      })
      .catch((err) => {
        // checks if the email already exists
        console.log(err);
        const existingField = err?.data?.message?.includes("email") ? "email" : "phone";
        if (err.status === 409) {
          setInputError((prev) => ({
            ...prev,
            [existingField]: `${existingField} Already Exists`,
          }));
          return;
        }

        if (err.status === 400) {
          const errors =
            err.data
              .map((e: any) => ({ [e.path[1]]: e.message }))
              .reduce((acc: any, obj: any) => {
                Object.keys(obj).forEach((key) => {
                  acc[key] = obj[key];
                });
                return acc;
              }, {}) || [];
          setInputError((prev) => ({ ...prev, ...errors }));
          return;
        }
      });
  }
  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    inputError.clearError(name);
  }

  function checkEmptyFields(formData: FormElements<keyof SignInFormType>) {
    // checks for gender field
    return formData.gender.value === "";
  }

  function matchPasswords(formData: FormElements<keyof SignInFormType>) {
    return formData.password.value === formData.confirmPassword.value;
  }

  return (
    <div className="mt-7 w-full lg:mt-16">
      <form
        action=""
        method="POST"
        className="flex flex-col gap-3 md:gap-6"
        onSubmit={handleSubmit}
      >
        <fieldset>
          <label htmlFor="email" className="text-sm md:text-base">
            Email<span className="text-red">*</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email address"
            required
            className={`mt-2 w-full rounded border border-grey-light px-4 py-3 text-base lg:text-xl ${
              inputError?.email
                ? "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
                : "focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30"
            }`}
            onInput={handleInput}
          />
          {inputError?.email && (
            <p className="mt-2 text-xs text-red">{inputError?.email}</p>
          )}
        </fieldset>
        <fieldset>
          <label htmlFor="fullName" className="text-sm md:text-base">
            Fullname<span className="text-red">*</span>
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            placeholder="Enter your Full Name"
            required
            className={`mt-2 w-full rounded border border-grey-light px-4 py-3 text-base lg:text-xl ${
              inputError?.fullName
                ? "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
                : "focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30"
            }`}
          />
        </fieldset>
        <fieldset className="flex flex-col justify-between gap-3 lg:flex-row">
          <fieldset className="lg:w-[65%]">
            <label htmlFor="phone" className="text-sm md:text-base">
              Phone Number<span className="text-red">*</span>
            </label>
            <div
              className={cn("relative mt-2 flex rounded border border-grey-light", {
                "border-red focus:border-4 focus:border-red focus:border-opacity-30":
                  inputError?.phone,
              })}
            >
              <PhoneCode
                selectedCode={selectedCode}
                setIsDropdownOpen={setIsDropdownOpen}
              />
              <PhoneCodeDropdown
                countryNamesAndFlags={countryNamesAndFlags}
                setSelectedCode={setSelectedCode}
                isDropdownOpen={isDropdownOpen}
                setIsDropdownOpen={setIsDropdownOpen}
                phonecodeName="phonecode"
              />
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Enter your phone number"
                pattern="[0-9]{3,}"
                required
                className={`w-full rounded px-4 py-3  text-base lg:text-xl`}
                onInput={handleInput}
              />
            </div>
            {inputError?.phone && (
              <p className="mt-2 text-xs capitalize text-red">{inputError?.phone}</p>
            )}
          </fieldset>
          <fieldset className="flex flex-col gap-2 lg:w-[35%]">
            <label htmlFor="gender" className="text-sm md:text-base">
              Gender<span className="text-red">*</span>
            </label>
            <Select
              options={["male", "female", "i rather not say"]}
              placeholder="Select gender"
              name="gender"
              id="gender"
              error={inputError.gender}
              clearError={inputError.clearError}
            />
            {inputError?.gender && <p className="text-xs text-red">Required</p>}
          </fieldset>
        </fieldset>
        <fieldset>
          <Password label="Password" error={inputError} name="password" />
          <p
            className={`mt-2 text-xs font-medium lg:text-sm ${
              inputError?.password === "weak" && "text-red"
            }`}
          >
            Minimum of 8 characters, with upper, lowercase, a number and a symbol
          </p>
        </fieldset>
        <fieldset>
          <Password label="Confirm Password" error={inputError} name="confirmPassword" />
          {inputError?.confirmPassword && (
            <p className="mt-2 text-red">Passwords do not match</p>
          )}
        </fieldset>

        <fieldset className="mt-8">
          <button
            className={`flex w-full  items-center justify-center rounded  py-4 text-base font-medium hover:bg-primary-blue-light hover:text-black lg:text-2xl ${
              isLoading
                ? "bg-primary-blue-light text-black"
                : "bg-primary-blue-dark text-white"
            }`}
          >
            {isLoading ? <Spinner /> : "Create Account"}
          </button>
        </fieldset>
        <fieldset>
          <p className="max-w-md text-center text-sm font-medium lg:max-w-none">
            By signing up, you’re agreeing to Leagues arena{" "}
            <Link to="/privacy-policy" className="text-primary-blue-dark">
              Terms of service
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" className="text-primary-blue-dark">
              Privacy Policy.
            </Link>
          </p>
        </fieldset>
        <fieldset>
          <p className="text-center text-sm font-medium">
            Already have an account?{" "}
            <a href="/account/login" className="text-primary-blue-dark">
              Login
            </a>
          </p>
        </fieldset>
      </form>
    </div>
  );
}

export default SignupForm;
