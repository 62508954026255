import type { ReactElement } from "react";
import { LaArrowRight } from "../../svg-icons";
import { Link } from "react-router-dom";

export interface EditionCardProps {
  name: string;
  noOfEditions: number;
  id: string;
}

export default function EditionCard({
  name,
  noOfEditions,
  id,
}: EditionCardProps): ReactElement {
  return (
    <div
      className={
        "rounded-lg bg-[url('/src/assets/images/edition-banner.png')] px-4 py-5 text-white"
      }
    >
      <h3 className="max-w-[280px] truncate text-2xl font-medium text-white">
        {name}
      </h3>
      <div className="mt-2 flex items-center justify-between">
        <p className="flex items-center gap-2 text-sm lg:text-base">
          Number of editions:
          <span className="text-base font-bold">{noOfEditions}</span>
        </p>
        <Link
          to={`/competition-editions/${id}`}
          className="flex items-center gap-2 text-sm"
        >
          View
          <LaArrowRight stroke="#fff" />
        </Link>
      </div>
    </div>
  );
}
