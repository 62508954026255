export const capitalize = (sentence: string | undefined) => {
  const prepositionsAndConjunctions = [
    "and",
    "or",
    "but",
    "for",
    "nor",
    "on",
    "in",
    "to",
    "at",
    "by",
    "up",
    "of",
    "as",
    "but",
    "it",
    "the",
    "a",
  ];

  const words = sentence?.split(" ");

  const capitalizedWords = words?.map((word, index) => {
    if (
      index === 0 ||
      !prepositionsAndConjunctions.includes(word.toLowerCase())
    ) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word.toLowerCase();
  });

  return capitalizedWords?.join(" ");
};
