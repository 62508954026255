function Search() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_128_4)">
        <path
          d="M18.3333 18.3333L16.6667 16.6667M9.58334 17.5C10.623 17.5 11.6524 17.2952 12.6129 16.8974C13.5734 16.4995 14.4461 15.9164 15.1813 15.1813C15.9164 14.4461 16.4995 13.5734 16.8974 12.6129C17.2952 11.6524 17.5 10.623 17.5 9.58332C17.5 8.54369 17.2952 7.51424 16.8974 6.55375C16.4995 5.59325 15.9164 4.72052 15.1813 3.98539C14.4461 3.25026 13.5734 2.66713 12.6129 2.26928C11.6524 1.87143 10.623 1.66666 9.58334 1.66666C7.48371 1.66666 5.47007 2.50073 3.98541 3.98539C2.50075 5.47006 1.66667 7.48369 1.66667 9.58332C1.66667 11.683 2.50075 13.6966 3.98541 15.1813C5.47007 16.6659 7.48371 17.5 9.58334 17.5V17.5Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_128_4">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Search;
