/* eslint-disable quotes */
import { Spinner } from "../ui";
import Pills from "../ui/pills/Pills";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { copyToClipboard } from "../../utils/copyToClipboard";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { capitalize } from "../../utils/capitalize";
import Button from "../ui/buttons/Button";
import { RootState } from "../../redux/app/store";

interface UserInfoProps {
  organizer?: TOrganizer;
  isLoading: boolean;
  isPrivate: boolean;
}
function UserInfo({ organizer, isLoading, isPrivate }: UserInfoProps) {
  const navigate = useNavigate();
  const [_, handleCopy] = useCopyToClipboard();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: RootState) => state.auth.role);

  const avatar = organizer?.avatar;

  return (
    <div className="mt-4 flex flex-col gap-4 px-4 lg:mt-6 lg:px-0">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex w-full flex-col gap-2 lg:flex-row  lg:justify-between lg:gap-4">
          <div className="flex flex-col gap-2 lg:w-[50%] lg:flex-row lg:gap-4">
            <img
              src={avatar}
              alt=""
              crossOrigin="anonymous"
              className="h-[64px] w-[64px] rounded-full object-cover"
            />
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <p className="text-base font-bold md:text-xl">
                    {capitalize(organizer?.name ?? "")}
                  </p>{" "}
                  <Pills $userRole={"organizer"}>
                    <p className="text-xs font-medium capitalize md:text-sm">
                      {"organization"}
                    </p>
                  </Pills>
                </div>
              </div>
              <div>
                <h3 className="hidden font-medium lg:block">Bio</h3>
                <p className="text-sm text-primary-black-darker md:mt-3 md:text-base">
                  {organizer?.bio}
                </p>
              </div>
              <div className="hidden w-full flex-col items-start lg:flex">
                <div className="mt-4 flex rounded border border-grey-light bg-grey-lighter px-2 py-4 text-sm">
                  <div className="flex flex-col items-center gap-2 border-r border-grey-light px-6">
                    <p>{organizer?.competitionsCreated}</p>
                    <p className="text-grey">Hosted</p>
                  </div>
                  <div className="flex flex-col items-center gap-2 border-r border-grey-light px-6">
                    <p>{organizer?.teams}</p>
                    <p className="text-grey">Teams</p>
                  </div>
                  <div className="flex flex-col items-center gap-2 px-6">
                    <p>{organizer?.players}</p>
                    <p className="text-grey"> Players</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 lg:items-start">
            {isPrivate && (
              <Button
                intent="primary"
                role={role}
                onClick={() => navigate("/profiles/organizer/edit")}
                className="py-3"
              >
                Edit Profile
              </Button>
            )}
            <Button
              intent={"secondary"}
              role={role || "organizer"}
              onClick={() =>
                copyToClipboard(
                  `${window.location.host}${window.location.pathname}`,
                  handleCopy,
                  dispatch
                )
              }
              className="py-3"
            >
              Share
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInfo;
