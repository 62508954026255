import { useAppDispatch } from "../../redux/app/hooks";
import { MediaCardSkeleton } from "../ui/cards/MediaCards";
import { useGetTournamentSpotlightQuery } from "../../redux/services/spotLightService";
import { setModalToDisplay } from "../../redux/features/modal/displayModalSlice";
import SpotLightCard from "../spotlight/SpotLightCard";
import { Link } from "react-router-dom";

export default function TournamentSpotlightGrid({
  isPrivate,
  tournamentId,
}: {
  isPrivate: boolean;
  tournamentId: string;
}) {
  const { data: tournamentData, isLoading: tournamentLoading } =
    useGetTournamentSpotlightQuery({
      tournamentId: tournamentId,
    });
  const dispatch = useAppDispatch();

  return (
    <div className="w-full">
      {isPrivate && (
        <div className="flex items-center gap-4  px-4 py-4 text-base font-medium lg:px-0">
          <button
            className="rounded-full border border-grey-light px-3 py-1 hover:bg-grey-lighter"
            onClick={() => {
              localStorage.setItem("tournamentId", tournamentId);
              dispatch(setModalToDisplay("spotLight"));
            }}
          >
            Create Post
          </button>
        </div>
      )}
      <div className="my-4">
        <section className="relative grid w-full grid-cols-3 justify-center gap-2 px-4 md:grid-cols-4 md:gap-4 lg:px-0">
          {tournamentLoading &&
            Array.from(Array(9)).map((_, idx) => (
              <MediaCardSkeleton key={idx} />
            ))}

          {!tournamentLoading &&
            tournamentData?.spotlights?.map((spt: SpotLight, idx: number) => (
              <Link to={`/spotlight/tournament/${tournamentId}?index=${idx}`}>
                <SpotLightCard spotlight={spt} key={spt._id} />
              </Link>
            ))}
        </section>
      </div>
    </div>
  );
}
