import React, { forwardRef } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useNavigate } from "react-router-dom";
import {
  useGetMyPlayerQuery,
  useUpdatePlayerMutation,
} from "../../redux/services/playerService";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { RootState } from "../../redux/app/store";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { LaGalleryEdit } from "../svg-icons";
import { Button, Input, RegisteredSelect, Spinner, Textarea } from "../ui";
import PositionSelect from "../ui/select/PositionSelect";
import { cn } from "../../utils/cn";
import { positions } from "../../assets/data/positions";

const EditPlayerFormSchema = z.object({
  name: z
    .string()
    .min(3, { message: "Player name is required" })
    .refine((value) => !/\s/.test(value), {
      message: "Player name cannot contain spaces",
    }),
  email: z.string().email(),
  phone: z.string().max(15),
  bio: z.string().optional(),
  location: z.string().min(3).max(50),
  dob: z.string(),
  position: z.string().min(2),
  secondaryPosition: z.string().min(2),
  favFoot: z.string(),
  jerseyNumb: z.string(),
  weight: z.string(),
  height: z.string(),
  favClub: z.string(),
  avatar: z.any(),
});

export type TEditPlayerForm = z.infer<typeof EditPlayerFormSchema> & {
  avatar: FileList;
};

export interface EditPlayerFormProps {}

export default forwardRef<HTMLDivElement, EditPlayerFormProps>(
  function EditPlayerForm({}, ref) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: player, isLoading: isPlayerLoading } = useGetMyPlayerQuery();

    const [previewAvatar, setPreviewAvatar] = React.useState(player?.avatar);
    const {
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
    } = useForm<TEditPlayerForm>({
      defaultValues: {
        location: player?.location,
      },
      resolver: zodResolver(EditPlayerFormSchema),
    });
    const watchAvatar = watch("avatar");
    const watchLocation = watch("location");
    const watchFavFoot = watch("favFoot");
    const [updatePlayer, { isLoading }] = useUpdatePlayerMutation();
    const role = useAppSelector(
      (state: RootState) => state.auth.role
    )?.toLowerCase() as TRole;

    const onSubmit = (data: TEditPlayerForm) => {
      const form = new FormData();

      const position = positions.find((pos) => pos.name === data.position);
      const secondaryPosition = positions.find(
        (pos) => pos.name === data.secondaryPosition
      );

      console.log(position);
      console.log(secondaryPosition);

      form.append("name", data.name);
      form.append("location", data.location);
      data.bio && form.append("bio", data.bio);
      data.avatar[0] && form.append("avatar", data.avatar[0]);
      form.append("position", `${position?.short} ${position?.name}`);
      form.append(
        "secondaryPosition",
        `${secondaryPosition?.short} ${secondaryPosition?.name}`
      );
      form.append("favFoot", data.favFoot);
      form.append("email", data.email);
      form.append("phone", data.phone);
      form.append("height", data.height);
      form.append("weight", data.weight);
      form.append("dob", data.dob);
      form.append("favClub", data.favClub);
      form.append("jerseyNumb", data.jerseyNumb);

      updatePlayer(form)
        .unwrap()
        .then((resp) => {
          dispatch(setToastSuccess(true));
          navigate(`/profiles/player/${resp?.data?.name ?? data.name}`);
        })
        .catch((err: any) => {
          if (err?.data?.message?.includes("authorized")) {
            return dispatch(setToastErrorText("Switch role to edit profile"));
          }
          dispatch(setToastError(true));
        });
    };

    React.useEffect(() => {
      if (watchAvatar?.length) {
        const reader = new FileReader();
        reader.readAsDataURL(watchAvatar?.[0]);
        reader.onloadend = () => {
          setPreviewAvatar(reader?.result as string);
        };
      }
    });

    return (
      <div className="mt-6 flex flex-col items-center justify-center" ref={ref}>
        {isPlayerLoading ? (
          <Spinner />
        ) : (
          <div className="flex w-full flex-col gap-2 lg:items-center">
            <h3 className="lg:hidden">Profile photo</h3>
            <form
              className="flex flex-col gap-6 lg:w-[75%] lg:flex-row lg:gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <fieldset>
                <label
                  htmlFor="avatar"
                  className="relative flex w-[75px] cursor-pointer items-center justify-center"
                >
                  <input
                    type="file"
                    className="hidden"
                    {...register("avatar")}
                    accept="image/*"
                    aria-label="file-input"
                    id="avatar"
                  />
                  <div className="absolute z-50 flex flex-col items-center justify-center">
                    <span className="text-2xl">
                      <LaGalleryEdit
                        stroke={errors.avatar ? "#E71D36" : "#fff"}
                      />
                    </span>
                    <p
                      className={cn("text-[0.75rem] text-white", {
                        "text-red": errors.avatar,
                      })}
                    >
                      Add Photo
                    </p>
                  </div>
                  <img
                    src={previewAvatar || player?.avatar}
                    alt="avatar"
                    className="h-[75px] w-[75px] rounded-full"
                  />
                  <div className="absolute h-[75px] w-[75px] rounded-full bg-[#00000050]"></div>
                </label>
                {errors.avatar && (
                  <p className="mt-2 text-sm text-red">
                    {errors.avatar.message as string}
                  </p>
                )}
              </fieldset>

              <fieldset className="flex w-full flex-col gap-6">
                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="name" className="text-sm md:text-base">
                    Full Name
                  </label>
                  <Input
                    type="text"
                    role={role}
                    register={register}
                    name="name"
                    placeholder="@username"
                    defaultValue={player?.name}
                  />

                  {errors.name && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.name?.message}
                    </span>
                  )}
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="email" className="text-sm md:text-base">
                    Email
                  </label>
                  <Input
                    type="text"
                    role={role}
                    register={register}
                    name="email"
                    readOnly
                    placeholder="e.g leagues@gmail.com"
                    defaultValue={player?.email}
                  />

                  {errors.email && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.email?.message}
                    </span>
                  )}
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="phone" className="text-sm md:text-base">
                    Phone Number
                  </label>
                  <Input
                    type="text"
                    role={role}
                    register={register}
                    name="phone"
                    readOnly
                    placeholder="e.g 081111111"
                    defaultValue={player?.phone}
                  />

                  {errors.phone && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.phone?.message}
                    </span>
                  )}
                </fieldset>

                <fieldset className="flex items-center justify-between gap-6">
                  <fieldset className="flex flex-1 flex-col items-stretch gap-2">
                    <label htmlFor="location" className="text-sm md:text-base">
                      Location
                      <span className="text-red"> *</span>
                    </label>
                    <RegisteredSelect
                      placeholder="Select a location"
                      name="location"
                      id="location"
                      register={register}
                      setValue={setValue}
                      value={watchLocation}
                      error={errors.location?.message}
                      fit={false}
                      required="Please select a location"
                    />
                    {errors.location && (
                      <span className="text-xs italic text-red">
                        {errors.location.message}
                      </span>
                    )}
                  </fieldset>

                  <fieldset className="flex flex-1 flex-col gap-2">
                    <label htmlFor="dob" className="text-sm md:text-base">
                      Date of Birth
                    </label>
                    <Input
                      id="dob"
                      type="date"
                      register={register}
                      defaultValue={player?.dob}
                      className={cn(
                        "px-4 py-3 text-xl placeholder:text-grey focus:border-4  focus:border-opacity-30",
                        {
                          "border-red focus:border-red focus:border-opacity-30":
                            errors.dob,
                        }
                      )}
                      role={role}
                      {...register("dob", {
                        required: "Please select Date of birth",
                        min: {
                          value: new Date().toISOString().slice(0, 16),
                          message: "Please select a valid date",
                        },
                      })}
                      name={"dob"}
                      min={new Date().toISOString().slice(0, 16)}
                    />
                    {errors.dob && (
                      <span className="text-xs italic text-red">
                        {errors.dob?.message}
                      </span>
                    )}
                  </fieldset>
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="position" className="text-sm font-medium">
                    Primary Position
                    <span className="text-red"> *</span>
                  </label>
                  <PositionSelect
                    register={register}
                    errors={errors}
                    registerOpt="position"
                    setValue={setValue}
                    id="position"
                    value={player?.position}
                    placeholder="Select position"
                  />
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label
                    htmlFor="secondaryPosition"
                    className="text-sm font-medium"
                  >
                    Secondary Position
                    <span className="text-red"> *</span>
                  </label>
                  <PositionSelect
                    registerOpt="secondaryPosition"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    id="secondaryPosition"
                    value={player?.secondaryPosition}
                    placeholder="Select position"
                  />
                </fieldset>

                <fieldset className="flex items-center justify-between gap-6">
                  <fieldset className="flex flex-1 flex-col items-stretch gap-2">
                    <label htmlFor="location" className="text-sm md:text-base">
                      Favorite Foot
                    </label>
                    <RegisteredSelect
                      placeholder="eg. Left"
                      name="favFoot"
                      id="favFoot"
                      register={register}
                      setValue={setValue}
                      value={watchFavFoot}
                      error={errors.favFoot?.message}
                      options={["Right", "Left"]}
                      fit={false}
                      required="Select a favorite foot"
                    />

                    {errors.favFoot && (
                      <span className="text-xs italic text-red">
                        {errors.favFoot.message}
                      </span>
                    )}
                  </fieldset>

                  <fieldset className="flex flex-1 flex-col gap-2">
                    <label
                      htmlFor="jerseyNumb"
                      className="text-sm md:text-base"
                    >
                      Jersey Number
                    </label>
                    <Input
                      type="number"
                      role={role}
                      register={register}
                      placeholder="e.g 22"
                      defaultValue={player?.jerseyNumb}
                      className={cn(
                        "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                        {
                          "border-red focus:border-red focus:border-opacity-30":
                            errors.jerseyNumb,
                        }
                      )}
                      {...register("jerseyNumb", {
                        required: "Please enter a jersey number",
                      })}
                    />

                    {errors.jerseyNumb && (
                      <span className="mt-2 text-sm italic text-red">
                        {errors.jerseyNumb?.message}
                      </span>
                    )}
                  </fieldset>
                </fieldset>

                <fieldset className="flex items-center justify-between gap-6">
                  <fieldset className="flex flex-1 flex-col gap-2">
                    <label htmlFor="name" className="text-sm md:text-base">
                      Weight (Kg)
                    </label>
                    <Input
                      type="number"
                      role={role}
                      register={register}
                      placeholder="e.g 120"
                      defaultValue={player?.weight}
                      className={cn(
                        "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                        {
                          "border-red focus:border-red focus:border-opacity-30":
                            errors.weight,
                        }
                      )}
                      {...register("weight", {
                        required: "Please enter a jersey number",
                      })}
                    />

                    {errors.weight && (
                      <span className="mt-2 text-sm italic text-red">
                        {errors.weight?.message}
                      </span>
                    )}
                  </fieldset>

                  <fieldset className="flex flex-1 flex-col gap-2">
                    <label htmlFor="name" className="text-sm md:text-base">
                      Height (cm)
                    </label>
                    <Input
                      type="number"
                      role={role}
                      register={register}
                      placeholder="7"
                      defaultValue={player?.height}
                      className={cn(
                        "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                        {
                          "border-red focus:border-red focus:border-opacity-30":
                            errors.height,
                        }
                      )}
                      {...register("height", {
                        required: "Please enter a jersey number",
                      })}
                    />

                    {errors.height && (
                      <span className="mt-2 text-sm italic text-red">
                        {errors.height?.message}
                      </span>
                    )}
                  </fieldset>
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="name" className="text-sm md:text-base">
                    Favorite Club
                  </label>
                  <Input
                    type="text"
                    role={role}
                    register={register}
                    name="favClub"
                    placeholder="e.g Bendel Insurance"
                    defaultValue={player?.favClub}
                  />

                  {errors.favClub && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.favClub?.message}
                    </span>
                  )}
                </fieldset>

                <fieldset>
                  <label htmlFor="bio" className="text-sm md:text-base">
                    Bio <span className="text-yellow">(Optional)</span>
                  </label>
                  <Textarea
                    register={register}
                    role={role}
                    name="bio"
                    placeholder="Write anything..."
                    defaultValue={player?.bio}
                    className={cn(
                      "mt-2 h-32 w-full resize-none rounded border border-grey-light px-4 py-3 text-base focus:border-primary-blue-dark focus:border-opacity-30 lg:text-xl",
                      {
                        "error border-red focus:border-red": errors.bio,
                      }
                    )}
                  />
                  {errors.bio && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.bio?.message}
                    </span>
                  )}
                </fieldset>

                <Button
                  className="flex items-center justify-center rounded  p-4 text-base md:mt-6 md:text-2xl"
                  role={role}
                  intent="primary"
                >
                  {isLoading ? (
                    <span className="animate-spin">
                      <Spinner />
                    </span>
                  ) : (
                    "Update Profile"
                  )}
                </Button>
              </fieldset>
            </form>
          </div>
        )}
      </div>
    );
  }
);
