import * as React from "react";

function LaDocumentUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={20} fill="none" {...props}>
      <path
        stroke={props.stroke || "#0045F6"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8 14.167v-5l-1.667 1.667M8 9.167l1.667 1.667"
      />
      <path
        stroke={props.stroke || "#0045F6"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.833 8.334V12.5c0 4.167-1.666 5.834-5.833 5.834H8c-4.167 0-5.833-1.667-5.833-5.834v-5c0-4.166 1.666-5.833 5.833-5.833h4.167"
      />
      <path
        stroke={props.stroke || "#0045F6"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18.833 8.334H15.5C13 8.334 12.167 7.5 12.167 5V1.667l6.666 6.667z"
      />
    </svg>
  );
}

export default LaDocumentUpload;
