import React from "react";
import { Button, GoldStar, RegisteredSelect } from "../ui";
import { useAppSelector } from "../../redux/app/hooks";
import Input from "../ui/input/Input";
import { RootState } from "../../redux/app/store";
import Textarea from "../ui/textarea/Textarea";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { cn } from "../../utils/cn";
import { RestartTournamentFormValues } from "../../pages/tournament/RestartTournament";
import tournamentCategories from "../../assets/data/tournament-categories";

export type RestartTournamentFormProps = {
  register: UseFormRegister<RestartTournamentFormValues>;
  errors: FieldErrors<RestartTournamentFormValues>;
  setError: UseFormSetError<RestartTournamentFormValues>;
  handleSubmit: UseFormHandleSubmit<RestartTournamentFormValues>;
  watchFormat: string;
  watchFixtureType: string;
  watchCategory: string;
  setValue: UseFormSetValue<RestartTournamentFormValues>;
  watchLocation: string;
  watchNumberOfTeams: string;
  prevData: Tournament;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
};

function RestartTournamentForm({
  register,
  errors,
  handleSubmit,
  watchFormat,
  watchFixtureType,
  watchLocation,
  watchCategory,
  watchNumberOfTeams,
  setCurrentStep,
  setValue,
  prevData,
}: RestartTournamentFormProps) {
  const homeAndAway = (
    <span className="flex items-center gap-2">
      home & away
      <GoldStar />
    </span>
  );

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase();

  const onSubmit: SubmitHandler<RestartTournamentFormValues> = (_data) => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <section className="my-6 min-h-[50vh] w-full md:my-12">
      <div className="mx-auto flex max-w-2xl flex-col items-center gap-2 px-2">
        {/* Create Tournament */}
        <div className="mb-6 flex flex-col gap-2">
          <h2 className="text-center text-[28px] font-medium text-primary-black-darker md:text-4xl">
            Tournament Information
          </h2>
        </div>
        <form
          className="flex w-full flex-col items-start gap-6 px-2 pb-20 lg:pb-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset className="flex w-full flex-col items-stretch text-grey-light">
            <label htmlFor="name">
              Competition Name<span className="text-bold text-red">*</span>
            </label>
            <Input
              type="text"
              register={register}
              role={role}
              readOnly
              {...register("name", {
                required: "Please enter a name for your competition",
                maxLength: 30,
              })}
              id="name"
              placeholder="e.g. League of champions"
              defaultValue={prevData.name}
              className={cn(
                "bg-grey-lighter px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors?.name,
                }
              )}
            />
            {errors.name && <p className="text-red">{errors.name.message}</p>}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="edition">
              What edition is this?{" "}
              <span className="text-bold text-red">*</span>
            </label>
            <Input
              type="text"
              register={register}
              role={role}
              {...register("edition", {
                required: "Please enter required edition",
                maxLength: { value: 2, message: "Max three digits" },
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              id="edition"
              placeholder="e.g. 2; 3"
              defaultValue={prevData.edition}
              className={cn(
                "bg-grey-lighter px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors?.edition,
                }
              )}
            />
            {errors.edition && (
              <p className="text-red">{errors.edition.message}</p>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="description">
              About this event<span className="text-bold text-red"> *</span>
            </label>
            <Textarea
              register={register}
              role={role}
              placeholder="Insert text"
              {...register("description", {
                maxLength: 150,
              })}
              defaultValue={prevData.description}
            />
            <span className="text-[12px] font-medium leading-none text-zinc-900">
              150 characters max
            </span>
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="category">
              Category
              <span className="text-bold text-red"> *</span>
            </label>
            <RegisteredSelect
              placeholder="E.g under 18"
              name="category"
              required="Please select a category"
              register={register}
              id="category"
              value={watchCategory || prevData.category}
              options={tournamentCategories}
              fit={true}
              setValue={setValue}
              error={errors.category?.message}
            />
            {errors.category && (
              <span className="text-red">{errors.category.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="format">
              Competition Format<span className="text-bold text-red"> *</span>
            </label>
            <RegisteredSelect
              id="format"
              value={watchFormat || prevData.format}
              register={register}
              name="format"
              placeholder="Choose a Competition Format"
              options={["season", "knockout"]}
              error={errors.format?.message}
              setValue={setValue}
              required="Please select a competition format"
            />
            {errors.format?.message && (
              <p className="text-xs italic text-red">
                {errors.format?.message}
              </p>
            )}
          </fieldset>

          {watchFormat === "season" ||
            (prevData.format === "season" && (
              <>
                <fieldset className="flex w-full flex-col items-stretch">
                  <label htmlFor="maxTeam">
                    Max. number of teams
                    <span className="text-bold text-red">*</span>
                  </label>
                  <Input
                    type="number"
                    register={register}
                    role={role}
                    {...register("maxTeam", {
                      required:
                        "Please enter the number of teams to participate",
                      min: 4,
                      max: 32,
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Please enter a valid number",
                      },
                    })}
                    id="maxTeam"
                    placeholder="Enter the number of teams to participate"
                    defaultValue={prevData.maxTeam}
                    className={cn(
                      "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                      {
                        "border-red focus:border-red focus:border-opacity-30":
                          errors.maxTeam,
                      }
                    )}
                  />
                  <span className="text-[12px] font-medium leading-none text-zinc-900">
                    Minimum 4 teams
                  </span>
                  {errors.maxTeam?.message && (
                    <span className="text-red">{errors.maxTeam.message}</span>
                  )}
                </fieldset>

                <fieldset className="flex w-full flex-col items-stretch">
                  <label htmlFor="fixture-type">
                    Fixture Type
                    <span className="text-bold text-red"> *</span>
                  </label>
                  <RegisteredSelect
                    placeholder="select fixture type"
                    name="fixtureType"
                    required="Please select a fixture type"
                    register={register}
                    id="fixture-type"
                    value={watchFixtureType || prevData.fixtureType}
                    options={["single fixture", homeAndAway]}
                    fit={true}
                    setValue={setValue}
                    error={errors.fixtureType?.message}
                  />
                  {errors.fixtureType && (
                    <span className="text-red">
                      {errors.fixtureType.message}
                    </span>
                  )}
                </fieldset>
              </>
            ))}

          {watchFormat === "knockout" ||
            (prevData.format === "knockout" && (
              <fieldset className="flex w-full flex-col items-stretch">
                <label htmlFor="number-of-teams">
                  Number of Teams
                  <span className="text-bold text-red">*</span>
                </label>
                <RegisteredSelect
                  placeholder="Select number of teams"
                  name="numberOfTeams"
                  id="number-of-teams"
                  value={watchNumberOfTeams || `${prevData.numberOfTeams}`}
                  error={errors.numberOfTeams?.message}
                  options={["4", "8", "16", "32"]}
                  register={register}
                  required="Please select the number of teams"
                  setValue={setValue}
                  fit={true}
                />
                {errors.numberOfTeams && (
                  <span className="text-red">
                    {errors.numberOfTeams.message}
                  </span>
                )}
              </fieldset>
            ))}

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="noOfPlayers">
              Maximum number of players
              <span className="text-bold text-red">*</span>
            </label>
            <Input
              type="number"
              role={role}
              placeholder="Enter maximum number of players"
              register={register}
              defaultValue={prevData.noOfPlayers}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors.noOfPlayers,
                }
              )}
              {...register("maxTeam", {
                required: "Please enter the number of players to participate",
              })}
            />
            {errors.noOfPlayers?.message && (
              <span className="text-xs italic text-red">
                {errors.noOfPlayers.message}
              </span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="datetime">
              Date & Time<span className="text-bold text-red"> *</span>
            </label>
            <Input
              id="datetime"
              type="datetime-local"
              register={register}
              defaultValue={prevData.datetime}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4  focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors.datetime,
                }
              )}
              role={role}
              {...register("datetime", {
                required: "Please select start data and time",
                min: {
                  value: new Date().toISOString().slice(0, 16),
                  message: "Please select a valid date and time",
                },
              })}
              name={"datetime"}
              min={new Date().toISOString().slice(0, 16)}
            />
            {errors.datetime && (
              <span className="text-xs italic text-red">
                {errors.datetime?.message}
              </span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="location" className="text-sm md:text-base">
              Location
              <span className="text-red"> *</span>
            </label>
            <RegisteredSelect
              placeholder="Select a location"
              name="location"
              id="location"
              register={register}
              setValue={setValue}
              required="Please select a location"
              value={watchLocation || prevData.location}
              error={errors.location?.message}
              fit={false}
            />
            {errors.location && (
              <span className="text-red">{errors.location.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="venue">
              Venue<span className="text-bold text-red"> *</span>
            </label>
            <Input
              register={register}
              role={role}
              id="venue"
              type="text"
              {...register("venue", {
                required: "Please enter the venue",
                maxLength: 50,
              })}
              placeholder="e.g. city park"
              defaultValue={prevData.venue}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors.venue,
                }
              )}
            />
            {errors.venue && (
              <span className="text-red">{errors.venue.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="registration-fee">
              Registration fee
              <span className="text-yellow"> (Leave blank if free)</span>
            </label>
            <Input
              id="registration-fee"
              role={role}
              register={register}
              type="text"
              placeholder="e.g. ₦30,000"
              {...register("registrationFee", {
                min: { value: 1, message: "Minimum ₦1" },
                maxLength: 30,
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.registrationFee,
                }
              )}
            />
            {errors.registrationFee && (
              <p className="text-xs italic text-red">
                {errors.registrationFee.message}
              </p>
            )}
          </fieldset>

          <Button
            type="submit"
            role={role}
            intent={"primary"}
            className={cn(
              "mt-6 flex w-full  items-center justify-center rounded  p-4 text-base text-white  md:mt-14 md:text-2xl"
            )}
          >
            Proceed
          </Button>
        </form>
      </div>
    </section>
  );
}

export default RestartTournamentForm;
