import React from "react";
import CompetitionCard from "../ui/cards/CompetitionCard";
import { CompetitionCardSkeleton } from "../ui/skeletons";
TeamCompetitions;
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import TabButton from "./TabButton";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { Button } from "../ui";
import { cn } from "../../utils/cn";

type Props = {
  competitions?: Tournament[];
  loadingCompetitions: boolean;
  isTeamOwner: boolean;
};

function TeamCompetitions({
  competitions,
  loadingCompetitions,
  isTeamOwner,
}: Props) {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase();
  const [activeTab, setActiveTab] = React.useState<"Invites" | "Joined">(
    "Joined"
  );

  const joinedCompetitions = competitions
    ?.filter((competition) => competition?.status === "joined")
    ?.map((competition, idx) => (
      <CompetitionCard
        key={idx}
        avatar={competition?.avatar}
        bgAvatar={competition?.banner}
        competitionName={competition?.name}
        id={competition?._id}
        location={competition?.location}
        // status={"joined"}
        teamCount={competition?.teams?.length}
      />
    ));
  const invitedCompetitions = competitions
    ?.filter((competition) => competition?.status === "invited")
    ?.map((competition, idx) => (
      <CompetitionCard
        key={idx}
        avatar={competition?.avatar}
        bgAvatar={competition?.banner}
        competitionName={competition?.name}
        id={competition?._id}
        location={competition?.location}
        // status={"joined"}
        teamCount={competition?.teams?.length}
      />
    ));

  return (
    <>
      {isTeamOwner && (
        <div className="flex w-full items-center gap-4 border-b border-grey-light px-4 py-4 text-base font-medium lg:px-0">
          <Button
            intent="secondary"
            role={role}
            className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
              "border-grey-light text-grey": activeTab !== "Invites",
            })}
            type="button"
            onClick={() => setActiveTab("Invites")}
          >
            Invites
          </Button>
          <Button
            intent="secondary"
            role={role}
            className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
              "border-grey-light text-grey": activeTab !== "Joined",
            })}
            type="button"
            onClick={() => setActiveTab("Joined")}
          >
            Joined
          </Button>
        </div>
      )}
      {loadingCompetitions ? (
        <div className="my-6 flex flex-col gap-4 p-4 lg:grid lg:grid-cols-3 lg:gap-x-5 lg:gap-y-10 lg:px-0">
          <CompetitionCardSkeleton />
          <CompetitionCardSkeleton />
          <CompetitionCardSkeleton />
        </div>
      ) : (
        <>
          {activeTab === "Invites" && (
            <>
              {(invitedCompetitions?.length ?? 0) === 0 && (
                <EmptyStateAuto
                  title="Nothing to see heree"
                  description="This team does not have any tournament invites. When it does it'll appear here"
                />
              )}
              <div className="flex flex-col gap-4 p-4 lg:grid lg:grid-cols-3 lg:gap-x-5 lg:gap-y-10 lg:px-0">
                {invitedCompetitions}
              </div>
            </>
          )}
          {activeTab === "Joined" && (
            <>
              {(joinedCompetitions?.length ?? 0) === 0 && (
                <EmptyStateAuto
                  title="Nothing to see here"
                  description="This team does not have any participating tournament. When it does it'll appear here"
                />
              )}
              <div className="flex flex-col gap-4 p-4 lg:grid lg:grid-cols-3 lg:gap-x-5 lg:gap-y-10 lg:px-0">
                {joinedCompetitions}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default TeamCompetitions;
