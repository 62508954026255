// import logo from "../../../assets/images/LA-LOGO-TRANSPARENT-BLUE.svg";
import logo from "../../../assets/images/logo.svg";
import useImagePreloader from "../../../hooks/useImagePreloader";

function AutoPreLoader() {
  const { imagesPreloaded } = useImagePreloader([logo]);
  if (!imagesPreloaded) {
    return <></>;
  }
  return (
    <div className="relative flex h-full w-full items-center justify-center">
      <div className="preloader__div flex h-full w-full items-center justify-center"></div>
      <img
        src={logo}
        alt="leagues arena"
        className="absolute  h-[80%] w-[80%] object-contain"
      />
    </div>
  );
}

export default AutoPreLoader;
