import { Link } from "react-router-dom";
import tImage from "../assets/images/brand/logo_light.png";
import pb from "../assets/images/powered-by.png";
import astoundLogo from "../assets/images/brand/Asset 2.png";

function Footer() {
  return (
    // <div
    //   className={`footer  flex w-full flex-col  bg-primary-blue-dark bg-[url(/src/assets/images/eclipse-mobile.webp)] bg-[185%_-35%] bg-no-repeat px-4 py-10 text-white md:flex-row md:gap-12 md:bg-[url(/src/assets/images/eclipse-desktop.webp)] lg:bg-[140%_120%]`}
    // >
    //   <div className="logo">
    //     <Link to="/">
    //       {/* <span className="text-[28px] font-bold lg:text-5xl">ACG</span> */}
    //       <img
    //         src="/logo-white.svg"
    //         alt="leagues arena"
    //         className="w-[94px] object-contain md:w-[147px] lg:w-[180px]"
    //       />
    //     </Link>
    //   </div>
    //   <div className="footer__sitemanps">
    //     <h4 className="mt-7 uppercase md:mt-0">SITEMAP</h4>
    //     <ul className="mt-3 flex flex-col gap-5 text-sm md:mt-6 md:text-base">
    //       <li>
    //         <Link className="cursor-pointer" to="/contact">
    //           Contact us
    //         </Link>
    //       </li>
    //       <li>
    //         <Link className="cursor-pointer" to="/">
    //           Dashboard
    //         </Link>
    //       </li>
    //       <li>
    //         {/* <a href="/faqs">FAQ</a> */}
    //         <Link className="cursor-pointer" to="/faqs">
    //           FAQ
    //         </Link>
    //       </li>
    //       <li>
    //         <Link className="cursor-pointer" to="/terms-of-service">
    //           Terms of Service
    //         </Link>
    //       </li>
    //       <li>
    //         <Link className="cursor-pointer" to="/privacy-policy">
    //           Privacy policy
    //         </Link>
    //       </li>
    //     </ul>
    //   </div>
    //   <div className="footer__socials">
    //     <div>
    //       <div>
    //         <h4 className="mt-7 uppercase md:mt-0">SOCIALS</h4>
    //         <ul className="mt-3 flex flex-col gap-5 text-sm md:mt-6 md:text-base">
    //           <li>
    //             <Link to="https://www.instagram.com/leaguesarena/">
    //               Instagram
    //             </Link>
    //           </li>
    //           <li>
    //             <Link to="https://twitter.com/leaguesarena">Twitter</Link>
    //           </li>
    //         </ul>
    //       </div>

    //       <div className="footer__head-officehidden mt-10 hidden md:block lg:hidden">
    //         <h4 className="uppercase">HEAD OFFICE</h4>
    //         <p className="mt-6 md:max-w-[300px]">
    //           Suite 302, Ammah Plaza, Ahmadu Bello Way, Near NAF Conference
    //           Center, <br />
    //           Abuja.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="footer__head-office md:hidden lg:block">
    //     <h4 className="mt-7 uppercase md:mt-0">HEAD OFFICE</h4>
    //     <p className="mt-3 text-sm md:mt-6 md:max-w-[300px] md:text-base">
    //       Suite 302, Ammah Plaza, Ahmadu Bello Way, Near NAF Conference Center,{" "}
    //       <br />
    //       Abuja.
    //     </p>
    //   </div>
    // </div>
    <footer className="mt-[66px] bg-[#00237B] px-3 py-6">
      <section className="mx-auto my-[100px] flex max-w-screen-xl flex-col flex-wrap items-start justify-between gap-x-[30px] gap-y-[50px] md:flex-row">
        <section className="flex max-w-[308px] flex-col text-white">
          <img src={tImage} className="mb-4 h-[50px] w-[100px] object-cover" />
          <p>
            Leagues Arena is transforming African football by digitalizing
            grassroots programs, enhancing accessibility, visibility, and
            opportunities for aspiring players and teams.
          </p>

          <div className="mt-8 flex items-center gap-2">
            <span>Powered by</span>
            <img
              src={astoundLogo}
              alt="astound logo"
              className="h-[40px] w-[71px]"
            />
          </div>
          {/* <img
            src={pb}
            className="mb-8 mt-7 h-[32.42px] w-[202px] object-contain object-center"
          /> */}
          <span className="mt-8 hidden text-sm lg:block">
            © 2024 Leagues Arena. All rights reserved.
          </span>
        </section>

        <section className="text-white">
          <h3 className="mb-6 font-extrabold">SITEMAPS</h3>
          <div className="flex flex-col gap-4">
            <Link to={"/contact"} className="font-medium">
              Contact Us
            </Link>
            <Link to={"/home"} className="font-medium">
              Dashboard
            </Link>
            <Link to={"/faq"} className="font-medium">
              FAQ
            </Link>
            <Link to={"/terms-of-service"} className="font-medium">
              Terms of Service
            </Link>
            <Link to={"/privacy-policy"} className="font-medium">
              Privacy Policy
            </Link>
          </div>
        </section>

        <section className="text-white">
          <h3 className="mb-6 font-extrabold">SOCIALS</h3>
          <div className="flex flex-col gap-4">
            <Link
              to={"https://www.instagram.com/leaguesarena/"}
              className="font-medium"
            >
              Instagram
            </Link>
            <Link
              to={"https://twitter.com/leaguesarena"}
              className="font-medium"
            >
              Twitter
            </Link>
            <Link to={"/#"} className="font-medium">
              Gmail
            </Link>
          </div>
        </section>

        <section className="text-white">
          <h3 className="mb-6 font-extrabold">HEAD OFFICE</h3>
          <div className="max-w-[308px]">
            <p>
              Suit 302 Ammah Plaza, Amadu Bello Way, Near NAF Conference Center,
              Abuja.
            </p>
          </div>
        </section>

        <span className="mt-4 text-sm text-white lg:hidden">
          © 2024 Leagues Arena. All rights reserved.
        </span>
      </section>
    </footer>
  );
}

export default Footer;
