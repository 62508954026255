import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import useOnClickOutside from "../../hooks/useClickedOutside";
import {
  PrivateBtnGroup,
  PublicOrganizerBtnGroup,
  PublicTeamBtnGroup,
} from ".";
import { InviteForm } from "../discover";
import { Button } from "../ui";
import { setToastCopied } from "../../redux/features/modal/displayModalSlice";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { AddTeamsToCompetition } from "../tournament";
import { defaultProfileImages } from "../../utils/defaultProfileImages";
import PreviewSpotLightModal from "../../components/modals/PreviewSpotLightModal";

type TeamProfileProps = {
  team?: Team;
  isLoadingTeam?: boolean;
};

export default function TeamProfile(props: TeamProfileProps) {
  const dispatch = useAppDispatch();
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const visitorId = useAppSelector((state: RootState) => state.auth.myId);

  const insideEl = React.useRef(null);
  const [selectedCompetition, setSelectedCompetition] = React.useState("none");
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);
  const [competitionTeams, setCompetitionTeams] = React.useState<
    Tournament["teams"]
  >([]);
  const [maxTeam, setMaxTeam] = React.useState<number>(0);

  const isPlayer = role === "player";
  const isOrganizer = role === "organizer";
  const canEditTeam = visitorId === props.team?.author && role === "team";
  const [_, copyToClipboard] = useCopyToClipboard();
  const modalToDisplay = useAppSelector(
    (state: RootState) => state.displayModal.modalToDisplay
  );

  useOnClickOutside(insideEl, () => {});

  function shareLink(link: string) {
    copyToClipboard(link).then((hasCopied) => {
      dispatch(setToastCopied(hasCopied));
    });
  }

  return (
    <>
      <section className="flex flex-col items-center justify-center gap-3 text-center lg:flex-row lg:items-start lg:justify-between">
        <div className="flex flex-col items-center justify-center gap-3 lg:flex-row lg:justify-start lg:gap-4">
          <img
            src={props.team?.avatar ?? defaultProfileImages["team"]}
            alt={props.team?.name}
            className="aspect-square w-[70px] overflow-hidden rounded-full object-cover"
          />
          <div className="flex flex-col items-center gap-3 lg:items-start">
            <h3 className="text-base font-bold capitalize">
              {props.team?.name}
            </h3>
            <p className="text-sm text-grey">
              Short name:{" "}
              <strong className="ml-1 font-medium uppercase text-black">
                {props.team?.shortName}
              </strong>
            </p>
            {/* Team Stats */}
            <section className="item-center my-3 mb-6 flex w-full justify-center px-4 lg:px-0">
              <div className="inline-flex flex-1 flex-col items-center justify-center gap-2 border-r-2 px-6 last:border-r-0">
                <p className="text-[20px] font-medium text-primary-black-light">
                  {props.team?.played}
                </p>
                <p className="text-base text-grey">Played</p>
              </div>
              <div className="inline-flex flex-1 flex-col items-center justify-center gap-2 border-r-2 px-6 last:border-r-0">
                <p className="text-[20px] font-medium text-primary-black-light">
                  {props.team?.wins}
                </p>
                <p className="text-base text-grey">Won</p>
              </div>
              <div className="inline-flex flex-1 flex-col items-center justify-center gap-2 border-r-2 px-6 last:border-r-0">
                <p className="text-[20px] font-medium text-primary-black-light">
                  {props.team?.losses}
                </p>
                <p className="text-base text-grey">Lost</p>
              </div>
              <div className="inline-flex flex-1 flex-col items-center justify-center gap-2 border-r-2 px-6 last:border-r-0">
                <p className="text-[20px] font-medium text-primary-black-light">
                  {props.team?.draws}
                </p>
                <p className="text-base text-grey">Drawn</p>
              </div>
            </section>
          </div>
        </div>
        <section className="flex justify-center gap-3 px-4 lg:px-0">
          {!props.isLoadingTeam && (
            <>
              {canEditTeam && <PrivateBtnGroup />}
              {isOrganizer && (
                <PublicOrganizerBtnGroup
                  setSelectedTeams={setSelectedTeams}
                  setSelectedCompetition={setSelectedCompetition}
                  team={props.team}
                  selectedTeams={selectedTeams}
                />
              )}
              {isPlayer && <PublicTeamBtnGroup team={props.team} />}
              {!isOrganizer && (
                <Button
                  intent={"secondary"}
                  role={role}
                  onClick={() =>
                    shareLink(
                      `${window.location.host}${window.location.pathname}`
                    )
                  }
                  type="button"
                >
                  Share
                </Button>
              )}
            </>
          )}
        </section>
      </section>
      {!selectedCompetition && (
        <InviteForm
          setSelectedCompetition={setSelectedCompetition}
          setCompetitionTeams={setCompetitionTeams}
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
          shouldDisable={true}
          setMaxTeam={setMaxTeam}
        />
      )}
      {selectedTeams.length > 0 && selectedCompetition.length > 4 && (
        <AddTeamsToCompetition
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
          setCompetitionTeams={setCompetitionTeams}
          setSelectedCompetition={setSelectedCompetition}
          selectedCompetition={selectedCompetition}
          shouldScroll={true}
          maxTeam={maxTeam}
          noOfTeamsJoined={competitionTeams.length}
        />
      )}
    </>
  );
}
