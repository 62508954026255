import { defaultProfileImages } from "../../utils/defaultProfileImages";

export const profiles: {
  [key: string]: string;
  role: TRole;
  desc: string;
  image: string;
  btn: string;
}[] = [
  {
    role: "organizer",
    desc: "Manage and host competitions",
    image: defaultProfileImages["organizer"],
    btn: "Add profile",
  },
  {
    role: "player",
    desc: "Accelerate your career as a sports professional",
    image: defaultProfileImages["player"],
    btn: "Add profile",
  },
  {
    role: "team",
    desc: "Join competitions with your squad",
    image: defaultProfileImages["team"],
    btn: "Add profile",
  },
];
