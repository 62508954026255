// import logo from "../../../assets/images/LA-LOGO-TRANSPARENT-BLUE.svg";
import logo from "../../../assets/images/logo.svg";
import useImagePreloader from "../../../hooks/useImagePreloader";

function PreLoader() {
  const { imagesPreloaded } = useImagePreloader([logo]);
  if (!imagesPreloaded) {
    return <></>;
  }
  return (
    <div
      className="flex min-h-screen  items-center justify-center"
      aria-label="preloader"
    >
      <div className="preloader__div flex h-[100px] w-[100px] items-center justify-center  lg:h-[400px] lg:w-[400px]"></div>
      <img
        src={logo}
        alt="leagues arena"
        className="absolute h-[70px] w-[70px] object-contain lg:h-[320px] lg:w-[320px]"
      />
    </div>
  );
}

export default PreLoader;
