import React from "react";
import { NavLink } from "react-router-dom";
import { cn } from "../../../utils/cn";
import {
  LaFeed,
  LaFeedback,
  LaHelp,
  LaProfile,
  LaSettings,
  LaTrophyLarge,
} from "../../svg-icons";
import LaNotification from "../../svg-icons/LaNotification";
import { strokes } from "../../../utils/stroke";
import LaWalletCLosed from "../../svg-icons/LaWalletCLosed";

const icons = (
  isActive: boolean,
  role: TRole
): {
  [key: string]: JSX.Element;
} => {
  const stroke = isActive && strokes[role] ? strokes[role] : "#1a1a1a";

  return {
    feed: <LaFeed stroke={stroke} />,
    discover: <LaTrophyLarge stroke={stroke} />,
    notifications: <LaNotification stroke={stroke} />,
    profile: <LaProfile stroke={stroke} />,
    help: <LaHelp stroke={stroke} />,
    settings: <LaSettings stroke={stroke} />,
    feedback: <LaFeedback stroke={stroke} />,
    wallet: <LaWalletCLosed stroke={stroke} strokeWidth={4} />,
  };
};

type Ref = HTMLAnchorElement;

interface IStyledNavLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className: string;
  to: string;
  role: TRole;
  icon?: string;
}

const StyledNavLink = React.forwardRef<Ref, IStyledNavLinkProps>(
  ({ className, role, to, children, icon }, ref) => {
    return (
      <NavLink
        className={({ isActive }) => {
          return cn(
            "black__svg flex items-center gap-2 font-medium text-grey",
            {
              "text-primary-blue-dark": role === "player" && isActive,
              "text-primary-black-darker": role === "organizer" && isActive,
              "text-secondary-blue-600": role === "team" && isActive,
            },
            className
          );
        }}
        to={to}
        ref={ref}
      >
        {({ isActive }) => (
          <>
            {icon && (
              <span className="text-2xl">{icons(isActive, role)[icon]}</span>
            )}
            {children}
          </>
        )}
      </NavLink>
    );
  }
);

export default StyledNavLink;
