import React from "react";
import { LaArrowLeft } from "../../components/svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetTournamentsQuery } from "../../redux/services/tournamentService";
import CompetitionsCard from "../../components/ui/cards/CompetitionsCard";
import Search from "../../components/search/Search";
import { useInView } from "react-intersection-observer";
import { TList, usePaginateFetch } from "../../hooks/usePaginateFetch";
import HelmetComponent from "../../components/HelmetComponent";

function Competitions() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const size = React.useRef<number>(6);
  const { ref, inView } = useInView({ delay: 600, threshold: 1 });
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetTournamentsQuery({
    orderBy: "desc",
    page: page,
    count: size.current,
  });
  const [list, setList] = React.useState<TList>([]);

  usePaginateFetch({
    inView,
    isFetching,
    isLoading,
    page,
    setPage,
    list,
    setList,
    data,
  });

  const tournaments = list as Tournament[];
  const tournamentCardDeck = tournaments.map((t, idx) => (
    <CompetitionsCard
      key={`${t?._id}-${idx}_t`}
      avatar={t?.avatar}
      banner={t?.banner}
      location={t.location}
      teams={t?.teams.length}
      name={t?.name}
      id={t?._id}
    />
  ));
  // open graph data
  const ogData = {
    title: "Leagues Arena - Competitions",
    description: "Discover the latest competitions on Leagues Arena",
    url: `https://${window.location.host}/competitions`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex flex-col items-center justify-center pb-10">
        <div className="relative w-full px-4 lg:max-w-[87%] lg:px-0 lg:pb-0">
          <div className="relative w-full lg:max-w-[100%] lg:pb-0">
            <div className="flex items-center gap-4 py-4">
              <button
                className="text-xl lg:hidden"
                onClick={() => navigate(-1)}
                aria-label="back"
              >
                <LaArrowLeft />
              </button>
              <h3 className="text-xl font-bold lg:mt-6">Competitions</h3>
            </div>

            <div className="w-full lg:my-4 lg:max-w-md">
              <Search placeholder="Search for Competitions " />
            </div>

            <div className="mt-4 flex grid-cols-3 flex-col gap-3 lg:mt-6  lg:grid">
              {tournamentCardDeck}
            </div>
          </div>
          {!isLoading && !isFetching && tournamentCardDeck.length > 2 && (
            <div ref={ref} className="pb-24 pt-8" />
          )}
          {isLoading && (
            <div
              className="flex items-center justify-center gap-3 pb-24 pt-8"
              aria-label="animate-pulse"
            >
              <span className="h-4 w-4 animate-pulse rounded-full bg-primary-blue-dark" />
              <span className="h-4 w-4 animate-pulse rounded-full bg-primary-blue-dark/60" />
              <span className="h-4 w-4 animate-pulse rounded-full bg-primary-blue-dark/40" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Competitions;
