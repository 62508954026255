export default function LaGoogle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 29 29"
      {...props}
    >
      <path
        fill="#4285F4"
        d="M26.82 14.7915C26.82 13.8815 26.7383 13.0065 26.5867 12.1665H14.5V17.1307H21.4067C21.1092 18.7348 20.205 20.094 18.8458 21.004V24.224H22.9933C25.42 21.9898 26.82 18.6998 26.82 14.7915Z"
      />
      <path
        stroke="#0045F6"
        d="M26.82 14.7915C26.82 13.8815 26.7383 13.0065 26.5867 12.1665H14.5V17.1307H21.4067C21.1092 18.7348 20.205 20.094 18.8458 21.004V24.224H22.9933C25.42 21.9898 26.82 18.6998 26.82 14.7915Z"
      />
      <path
        fill="#34A853"
        d="M14.5 27.3335C17.965 27.3335 20.87 26.1843 22.9933 24.2243L18.8458 21.0043C17.6966 21.7743 16.2266 22.2293 14.5 22.2293C11.1574 22.2293 8.32828 19.9718 7.31912 16.9385H3.03162V20.2635C5.14328 24.4576 9.48328 27.3335 14.5 27.3335Z"
      />
      <path
        stroke="#0045F6"
        d="M14.5 27.3335C17.965 27.3335 20.87 26.1843 22.9933 24.2243L18.8458 21.0043C17.6966 21.7743 16.2266 22.2293 14.5 22.2293C11.1574 22.2293 8.32828 19.9718 7.31912 16.9385H3.03162V20.2635C5.14328 24.4576 9.48328 27.3335 14.5 27.3335Z"
      />
      <path
        fill="#FBBC05"
        d="M7.31913 16.9385C7.06246 16.1685 6.91663 15.346 6.91663 14.5002C6.91663 13.6543 7.06246 12.8318 7.31913 12.0618V8.73682H3.03163C2.13329 10.5252 1.66583 12.4989 1.66663 14.5002C1.66663 16.571 2.16246 18.531 3.03163 20.2635L7.31913 16.9385Z"
      />
      <path
        fill="#EA4335"
        d="M14.5 6.77067C16.3841 6.77067 18.0758 7.41817 19.4058 8.68984L23.0866 5.009C20.8641 2.93817 17.9591 1.6665 14.5 1.6665C9.48328 1.6665 5.14328 4.54234 3.03162 8.7365L7.31912 12.0615C8.32828 9.02817 11.1574 6.77067 14.5 6.77067Z"
      />
    </svg>
  );
}
