import { forwardRef } from "react";
import { cn } from "../../../utils/cn";

export interface Props {
  role: string;
  className?: string;
}

export default forwardRef<HTMLDivElement, Props>(function (
  { role, className },
  ref
) {
  return (
    <div className="flex items-center justify-center gap-3 pt-8" ref={ref}>
      <span
        className={cn(
          "h-4 w-4 animate-pulse rounded-full",
          {
            "bg-primary-blue-dark": role === "player",
            "bg-primary-black-dark": role === "organizer",
            "bg-secondary-blue-600": role === "team",
          },
          className
        )}
      />
      <span
        className={cn(
          "h-4 w-4 animate-pulse rounded-full",
          {
            "bg-primary-blue-dark/60": role === "player",
            "bg-primary-black-dark/60": role === "organizer",
            "bg-secondary-blue-600/60": role === "team",
          },
          className
        )}
      />
      <span
        className={cn(
          "h-4 w-4 animate-pulse rounded-full",
          {
            "bg-primary-blue-dark/40": role === "player",
            "bg-primary-black-dark/40": role === "organizer",
            "bg-secondary-blue-600/40": role === "team",
          },
          className
        )}
      />
    </div>
  );
});
