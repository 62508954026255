import { useNavigate } from "react-router-dom";
import { LaArrowDown, LaArrowLeft, LaArrowUp } from "../../components/svg-icons";
import { Button, Spinner } from "../../components/ui";
import LaWalletCLosed from "../../components/svg-icons/LaWalletCLosed";
import formatToNaira from "../../utils/formatPrice";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { cn } from "../../utils/cn";
import { useState } from "react";
import { ConfirmPopup, WithdrawModal } from "../../components/modals";
import { useGetWalletQuery } from "../../redux/services/walletService";
import { capitalize } from "../../utils/capitalize";
import LaSpinner from "../../components/svg-icons/LaSpinner";
import HelmetComponent from "../../components/HelmetComponent";

export default function Wallet() {
  const navigate = useNavigate();
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const notAllowed = ["team", "player"];
  const initialDisplay = notAllowed.includes(role) ? "not-allowed" : "";
  const [modalToDisplay, setModalToDisplay] = useState(initialDisplay);
  const { data: wallet, isLoading } = useGetWalletQuery();

  // open graph data

  const ogData = {
    title: "Leagues Arena - Wallet",
    description: "View your wallet on Leagues Arena",
    url: `https://${window.location.host}/wallet`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex w-full flex-col items-center pb-24">
        <div className="w-full px-4 pb-20 lg:max-w-[87%] lg:px-0 lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Wallet</h3>
          </div>
          <div className="mt-5 flex items-center justify-between lg:mt-10">
            <div className="flex items-center gap-2">
              <LaWalletCLosed className="text-5xl" />
              <div>
                <p className="">Balance</p>
                <p className="text-2xl lg:text-4xl">
                  {isLoading ? (
                    <LaSpinner className="animate-spin text-sm" stroke="#000" />
                  ) : (
                    formatToNaira(wallet?.balance ?? 0)
                  )}
                </p>
              </div>
            </div>
            <Button
              intent={"primary"}
              role={role}
              className="disabled:cursor-not-allowed disabled:bg-primary-black-light lg:min-w-[200px]"
              onClick={() => setModalToDisplay("withdraw")}
              disabled={wallet?.balance === 0}
            >
              Withdraw
            </Button>
          </div>
          <div className="flex flex-col gap-6">
            <div className="mt-14 flex max-w-2xl flex-col gap-4 p-4 shadow lg:mt-10 lg:p-6">
              <h3 className="text-xl">Transactions</h3>

              {isLoading ? (
                <div className="flex w-full items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                wallet?.transactions?.map((transaction) => {
                  return (
                    <div
                      key={transaction.date}
                      className="flex items-center justify-between"
                    >
                      <div className="flex items-center gap-3">
                        <div
                          className={cn("h-2 w-2 rounded-full bg-red", {
                            "bg-green-dark": transaction.type === "deposit",
                          })}
                        />
                        <div>
                          <p className="">{transaction.description}</p>
                          <p className="text-sm text-primary-black-lighter">
                            {transaction.date}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="flex flex-col">
                          <p className="">
                            {transaction.type === "deposit" ? "+" : "-"}
                            {formatToNaira(transaction.amount)}
                          </p>
                          <p
                            className={cn("text-sm", {
                              "text-green-dark": transaction.status === "completed",
                              "text-yellow": transaction.status === "pending",
                            })}
                          >
                            {transaction.status}
                          </p>
                        </div>
                        {transaction.type === "deposit" ? (
                          <LaArrowUp className="" />
                        ) : (
                          <LaArrowDown className="text-red" />
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      {modalToDisplay === "withdraw" && (
        <WithdrawModal setModalToDisplay={setModalToDisplay} />
      )}
      {modalToDisplay === "success" && (
        <ConfirmPopup
          heading="Success!"
          description="Your withdrawal request has been received and is being processed"
          type="success"
          buttons={[
            {
              text: "Done",
              class: "primary",
              handleClick: () => setModalToDisplay(""),
            },
          ]}
        />
      )}
      {modalToDisplay === "not-allowed" && (
        <ConfirmPopup
          heading={`${capitalize(role)}s are not allowed to perform this action`}
          type="warning"
          description={"Only organizers can view wallet"}
          buttons={[
            {
              text: "Go back",
              handleClick: () => navigate(-1),
              class: "primary",
            },
          ]}
        />
      )}
    </>
  );
}
