import { useNavigate } from "react-router-dom";
import { LaArrowLeft, LaCopy } from "../../components/svg-icons";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import Input from "../../components/ui/input/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { RootState } from "../../redux/app/store";
import Button from "../../components/ui/buttons/Button";
import LaSpinner from "../../components/svg-icons/LaSpinner";
import { useCreateTrainingMutation } from "../../redux/services/trainingService";
import { cn } from "../../utils/cn";
import { MobileTimePicker } from "@mui/x-date-pickers";
import {
  setModalToDisplay,
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import React from "react";
import { ConfirmPopup } from "../../components/modals";
import { copyToClipboard } from "../../utils/copyToClipboard";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import HelmetComponent from "../../components/HelmetComponent";

type TFormValues = {
  sessionName: string;
  venue: string;
  date: Date;
  time: Date;
};

export default function CreateTraining() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const modalToDisplay = useAppSelector(
    (state: RootState) => state.displayModal.modalToDisplay
  );
  const role = useAppSelector((state: RootState) => state.auth.role);
  const {
    register,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm<TFormValues>({});

  const [createTraining, { isLoading }] = useCreateTrainingMutation();
  const [trainingId, setTrainingId] = React.useState<string>("");
  const [_, handleCopy] = useCopyToClipboard();

  // open graph data
  const ogData = {
    title: "Leagues Arena - Create Training",
    description: "Create a training session on Leagues Arena",
    url: `https://${window.location.host}/training/create`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  const onSubmit: SubmitHandler<TFormValues> = (data) => {
    const date = new Date(data.date);
    date.setHours(data.time.getHours());
    date.setMinutes(data.time.getMinutes());
    date.setSeconds(data.time.getSeconds());
    date.setMilliseconds(data.time.getMilliseconds());

    data.date = date;
    data.time = date;

    if (date < new Date()) {
      return setError("time", {
        type: "manual",
        message: "Time cannot be in the past",
      });
    }

    console.log(data);

    createTraining(data)
      .unwrap()
      .then((res) => {
        console.log(res);
        setTrainingId(res?.data?._id);
        dispatch(setModalToDisplay("training-created"));
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        dispatch(setToastError(true));
        console.log(err);
      });
  };

  function handleCopyToClipboard() {
    copyToClipboard(
      `${window.location.host}/training/${trainingId}`,
      handleCopy,
      dispatch
    );
    dispatch(setModalToDisplay(""));
    navigate(`/training/${trainingId}`);
  }

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Training Session</h3>
          </div>

          <div className="flex w-full flex-col gap-2 lg:mt-6 lg:items-center">
            <form
              className="flex flex-col gap-6 lg:w-[75%] lg:flex-row lg:gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <fieldset className="flex w-full flex-col gap-4 lg:gap-6">
                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="name" className="text-sm md:text-base">
                    Session name<span className="text-red">*</span>
                  </label>
                  <Input
                    role={role}
                    register={register}
                    type="text"
                    placeholder="eg. Arena Scrimmage"
                    className="px-4 py-3 text-xl placeholder:text-grey"
                    {...register("sessionName", {
                      required: "Session name is required",
                      maxLength: 30,
                    })}
                  />
                  {errors.sessionName?.message && (
                    <p className="text-xs italic text-red">
                      {errors.sessionName.message}
                    </p>
                  )}
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="venue" className="text-sm md:text-base">
                    Training venue<span className="text-red">*</span>
                  </label>
                  <Input
                    type="text"
                    placeholder="eg. Astro Turf, Wuse 2"
                    className="px-4 py-3 text-xl placeholder:text-grey"
                    {...register("venue", {
                      required: "Please enter a training venue",
                      maxLength: 230,
                    })}
                    role={role}
                    register={register}
                  />
                  {errors.venue?.message && (
                    <p className="text-xs italic text-red">{errors.venue.message}</p>
                  )}
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="date" className="text-sm md:text-base">
                    Date<span className="text-red">*</span>
                  </label>
                  <Input
                    type="date"
                    placeholder=""
                    className="px-4 py-3 text-xl placeholder:text-grey"
                    {...register("date", {
                      required: "please select a date for the training",
                      maxLength: 230,
                      min: {
                        value: new Date().toISOString().split("T")[0],
                        message: "Date cannot be in the past",
                      },
                    })}
                    role={role}
                    register={register}
                    min={new Date().toISOString().split("T")[0]}
                  />
                  {errors.date?.message && (
                    <p className="text-xs italic text-red">{errors.date.message}</p>
                  )}
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="time" className="text-sm md:text-base">
                    Time
                    <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    id="time"
                    hidden
                    {...register("time", {
                      required: "Time is required",
                    })}
                  />
                  {/* @ts-ignore */}
                  <MobileTimePicker
                    openTo="hours"
                    className={`w-full bg-white`}
                    sx={{
                      width: "100%",
                      border: errors?.time?.message && "1px solid red",
                      borderRadius: "4px",
                    }}
                    ampm={false}
                    onChange={(time) => {
                      // @ts-ignore
                      setValue("time", time["$d"]);
                    }}
                  />
                  {errors.time?.message && (
                    <p className="text-xs italic text-red">{errors.time.message}</p>
                  )}
                </fieldset>

                <fieldset className="mt-14 flex flex-col">
                  <Button
                    role={role}
                    intent={"primary"}
                    aria-label="create-training"
                    className={cn(
                      "flex items-center justify-center gap-4 py-4 text-base font-medium",
                      {
                        "shake border-red bg-red text-white":
                          Object.keys(errors).length > 0,
                      }
                    )}
                    type="submit"
                  >
                    <span>Create Training</span>
                    {isLoading && (
                      <span className="animate-spin">
                        <LaSpinner />
                      </span>
                    )}
                  </Button>
                </fieldset>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {modalToDisplay === "training-created" && (
        <ConfirmPopup
          type="success"
          heading="Training session created successfully"
          description="Copy link and share with players to RSVP for the training session"
          buttons={[
            {
              text: "Copy link",
              icon: <LaCopy />,
              class: "primary",
              handleClick: handleCopyToClipboard,
            },
          ]}
        />
      )}
    </>
  );
}
