import type { ReactElement } from "react";
import { LaCheck, LaClose, LaDelete, LaInfo } from "../svg-icons";
import { useAppSelector } from "../../redux/app/hooks";
import { cn } from "../../utils/cn";
import { RootState } from "../../redux/app/store";
import React from "react";
import { Button } from "../ui";

export interface ConfirmPopupProps {
  heading: string;
  description: string;
  type: string;
  warning?: string;
  buttons: {
    text: string;
    icon?: JSX.Element;
    rIcon?: JSX.Element;
    role?: TRole;
    class: "primary" | "secondary" | "tertiary" | "soft";
    handleClick: () => void;
  }[];
  handleClose?: () => void;
  closeIcon?: boolean;
}

export default function ConfirmPopup({
  heading,
  description,
  warning,
  buttons,
  type,
  handleClose,
  closeIcon,
}: ConfirmPopupProps): ReactElement {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;

  const icons: {
    [key: string]: JSX.Element;
  } = {
    warning: <LaInfo className="text-2xl" stroke="#FFB800" />,
    success: <LaCheck stroke="#3C8505" strokeWidth={3} />,
    error: <LaCheck stroke="#3C8505" strokeWidth={3} />,
    danger: <LaDelete />,
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="absolute bottom-0 right-0 top-0 z-[100] min-h-screen w-[100vw] justify-center bg-black/20 lg:flex">
      <div className="flex h-screen w-full items-center justify-center px-4 lg:px-0">
        <div className="relative flex w-full flex-col rounded-xl bg-white p-4 shadow lg:w-[28%] lg:p-6">
          <div
            className={cn(
              "flex h-14 w-14 items-center justify-center rounded-full border-4 border-[#3C85051A] bg-[#3C85051A] ",
              {
                "border-[#FFB8001A] bg-[#FFB8001A]": type === "warning",
                "border-[#E71D361A] bg-[#E71D361A]":
                  type === "error" || type === "danger",
              }
            )}
          >
            <div
              className={cn(
                "flex h-12 w-12 items-center justify-center rounded-full border-4 border-[#3C850533]",
                {
                  "border-[#FFB80033]": type === "warning",
                  "border-[#E71D3633]": type === "error" || type === "danger",
                }
              )}
            >
              <div
                className={cn(
                  "flex h-6 w-6 items-center justify-center rounded-full border-2",
                  {
                    "border-[#3C8505]": type === "success",
                    "border-[#FFB800]": type === "warning",
                    "border-[#E71D36]": type === "error",
                  }
                )}
              >
                {icons[type]}
              </div>
            </div>
          </div>
          <h4 className="mt-4 text-xl">{heading}</h4>
          <div className="mt-1 text-grey">
            {description?.split("\n").map((description) => (
              <p className="mt-1 text-grey" key={description}>
                {description}
              </p>
            ))}
          </div>
          {warning && (
            <div className="my-2 flex items-center gap-2 bg-[#ffbb00]/10 px-3 py-2 text-sm text-[#ffbb00]">
              <LaInfo stroke="#ffbb00" className="text-2xl" />
              <p className="">{warning}</p>
            </div>
          )}
          <div className="mt-6 flex w-full flex-col gap-4">
            {buttons.map((button) => (
              <Button
                role={role || button.role}
                intent={button.class}
                key={button.text}
                className={cn(
                  "flex items-center justify-center gap-2 rounded-lg font-medium leading-[21px]",
                  {
                    "bg-red hover:bg-red/90":
                      type === "danger" && button.class === "primary",
                    "border border-red text-red hover:bg-red-lighter":
                      type === "danger" && button.class === "secondary",
                  }
                )}
                onClick={button.handleClick}
              >
                {button.icon}
                {button.text}
                {button.rIcon}
              </Button>
            ))}
          </div>
          {closeIcon && handleClose && (
            <button className="absolute right-8 top-8" onClick={handleClose}>
              <LaClose />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
