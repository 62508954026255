import type { ReactElement } from "react";
import { LaArrowLeft, LaInfo } from "../../components/svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import PlayerBadgeXs from "../../components/ui/cards/PlayerBadgeXs";
import PlayerBadge from "../../components/ui/cards/PlayerBadge";
import { setModalToDisplay } from "../../redux/features/modal/displayModalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import React from "react";
import { RootState } from "../../redux/app/store";
import { VotePlayer } from "../../components/modals";
import { useGetPlayersToRateQuery } from "../../redux/services/ratingService";
import EmptyStateAuto from "../../components/ui/EmptyStateAuto";
import HelmetComponent from "../../components/HelmetComponent";

export interface TeamTrainingVoteProps {}

export default function (): ReactElement {
  const dispatch = useAppDispatch();
  const modalToDisplay = useAppSelector(
    (state: RootState) => state.displayModal.modalToDisplay
  );
  const { trainingId } = useParams();
  const navigate = useNavigate();
  const { data: playersToRate } = useGetPlayersToRateQuery();
  const players = playersToRate?.players?.map((player) => player.player);
  const noOfPlayers = players?.length || 0;
  const [playerObj, setPlayerObj] = React.useState<TTraining["players"][0] | undefined>();
  const canRate = playersToRate?.canRate;
  const total = playersToRate?.players?.reduce((acc, curr) => {
    return acc + curr.rating;
  }, 0);
  const [totalRates, setTotalRates] = React.useState<number>(0);

  // open graph data

  const ogData = {
    title: "Leagues Arena - Team Training Vote",
    description: "Vote for the best player in the training session",
    url: `https://${window.location.host}/training/vote`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  function handleDisplayModal(playerObj: TTraining["players"][0]) {
    setPlayerObj(playerObj);
    setTotalRates(total || 0);
    dispatch(setModalToDisplay("rate-player"));
  }

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {canRate ? (
        <div className="flex flex-col items-center justify-center pb-10">
          <div className="w-full px-4 py-4 pb-24 lg:max-w-[87%] lg:px-0 lg:pb-0">
            <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
              <button
                className="flex items-center gap-2 text-xl"
                onClick={() => navigate(-1)}
              >
                <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
              </button>
              <h3 className="text-xl font-bold">Vote Players</h3>
            </div>

            <h3 className="mt-4 text-2xl font-bold lg:mt-10">
              Who deserves the spotlight? Select a player to vote
            </h3>
            <div className="mt-4 flex max-w-max items-center gap-2 bg-[#FFB8001A] px-4 py-1">
              <LaInfo className="text-2xl" />
              <p className="text-sm">
                {noOfPlayers > 1
                  ? `${noOfPlayers} players have`
                  : `${noOfPlayers} player`}{" "}
                joined the training session
              </p>
            </div>

            <div className="mt-4 grid grid-cols-3 lg:grid-cols-5">
              {playersToRate?.players?.map((playerObj) => {
                const player = playerObj.player;
                return (
                  <button
                    className="flex flex-col items-center justify-center gap-2 border border-grey-lighter p-4 text-center odd:bg-white even:bg-grey-lighter"
                    key={player?._id}
                    onClick={() => handleDisplayModal(playerObj)}
                  >
                    <div className="w-[70px] lg:hidden">
                      <PlayerBadgeXs
                        appearance={player?.apps}
                        assist={player?.assists}
                        avatar={player?.avatar}
                        designation={player?.designation}
                        goals={player?.goals}
                        playerName={player?.name}
                        playerWing={player?.position}
                      />
                    </div>

                    <div className="hidden w-[143px] lg:block">
                      <PlayerBadge
                        appearance={player?.apps}
                        assist={player?.assists}
                        avatar={player?.avatar}
                        designation={player?.designation}
                        goals={player?.goals}
                        playerName={player?.name}
                        playerWing={player?.position}
                      />
                    </div>

                    <span className="text-grey">{player?.name}</span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full items-center justify-center">
          <div className="w-full  px-4 lg:max-w-[87%] lg:px-0">
            <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
              <button
                className="flex items-center gap-2 text-xl"
                onClick={() => navigate(-1)}
              >
                <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
              </button>
              <h3 className="text-xl font-bold">Vote Players</h3>
            </div>

            <EmptyStateAuto
              description="You dont have any players to rate when you do they will appear here"
              title="Nothing to see here"
            />
          </div>
        </div>
      )}
      {modalToDisplay === "rate-player" && (
        <VotePlayer
          playerObj={playerObj}
          trainingId={trainingId}
          canRate={canRate}
          totalRates={totalRates}
        />
      )}
    </>
  );
}
