import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import StyledNavLink from "../ui/navlink/StyledNavLink";
import { cn } from "../../utils/cn";
import { useGetMeQuery } from "../../redux/services/userService";
import { Button, FloatingButton } from "../ui";
import { LaElementPlus, LaPlusLarge } from "../svg-icons";
import { PlusIcon } from "@radix-ui/react-icons";

function BottomNav() {
  const { data: user } = useGetMeQuery();
  const profiles = user?.profiles;
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const currentProfile = profiles?.find((profile) => profile.role === role);
  const name = currentProfile?.name?.toLowerCase()?.replace(" ", "-");
  const ping = useAppSelector((state: RootState) => state.wsNotification.ping);
  const isBoarded = useAppSelector((state: RootState) => state.auth.isBoarded);
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const plan = currentProfile?.plan?.name ?? "free";

  return (
    <div className="fixed bottom-0 right-0 z-50 flex w-full justify-evenly border-t border-grey-light bg-white py-4 shadow lg:hidden">
      <StyledNavLink
        to="/home"
        className="flex flex-col items-center gap-2"
        role={role}
        icon={"feed"}
      >
        <p className="text-xs font-medium">Home</p>
      </StyledNavLink>
      <StyledNavLink
        to={`/discover`}
        className=" flex flex-col items-center gap-2 text-xs font-medium"
        role={role}
        icon="discover"
      >
        Discover
      </StyledNavLink>

      {isLoggedIn && isBoarded && (
        <FloatingButton>
          <Button
            role={role}
            intent={"primary"}
            className="h-[50px] w-[50px] rounded-full p-0"
          >
            <PlusIcon className="h-8 w-8 text-white" />
          </Button>
        </FloatingButton>
      )}

      <StyledNavLink
        to={isLoggedIn && isBoarded ? "/notifications" : "/account/login"}
        className="relative flex flex-col items-center justify-between gap-2"
        role={role}
        icon="notifications"
      >
        <p className="text-xs font-medium">Notifications</p>
        {ping && (
          <>
            <div className="dot absolute right-3 top-0 h-2 w-2 rounded-full bg-red" />
            <div className="dot absolute right-3 top-0 h-2 w-2 animate-ping rounded-full bg-red" />
          </>
        )}
      </StyledNavLink>
      <StyledNavLink
        to={
          isLoggedIn && isBoarded
            ? `/profiles/${role}/${name}`
            : "/account/login"
        }
        className="relative flex flex-col items-center gap-2 text-xs font-medium"
        role={role}
        icon="profile"
      >
        Profile
        <p
          className={cn(
            "absolute -right-4 top-0 rounded bg-primary-blue-lighter px-1 text-[6px] uppercase text-primary-blue-darker",
            {
              "bg-[#FFF7E6] text-[#FBBE28]": plan !== "free",
            }
          )}
        >
          {plan}
        </p>
      </StyledNavLink>
    </div>
  );
}

export default BottomNav;
