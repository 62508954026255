import React from "react";
import Input from "../ui/input/Input";
import Textarea from "../ui/textarea/Textarea";
import {
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  set,
} from "react-hook-form";
import { CreateTournamentFormValues } from "../../pages/create-tournament/CreateTournament";
import { cn } from "../../utils/cn";
import RegisteredPhoneInput from "../ui/input/RegisteredPhoneInput";
// import { Spinner } from "../ui";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { LaDelete, LaPlusIcon, LaPlusLarge } from "../svg-icons";
import { Button } from "../ui";

type TournamentContactInfoProps = {
  errors: FieldErrors<CreateTournamentFormValues>;
  register: UseFormRegister<CreateTournamentFormValues>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: UseFormHandleSubmit<CreateTournamentFormValues>;
  setValue: UseFormSetValue<any>;
  fields: FieldArrayWithId<CreateTournamentFormValues, "coOrganizers", "id">[];
  append: UseFieldArrayAppend<CreateTournamentFormValues, "coOrganizers">;
  remove: UseFieldArrayRemove;
};

function TournamentContactInfo({
  register,
  errors,
  setCurrentStep,
  handleSubmit,
  setValue,
  fields,
  append,
  remove,
}: TournamentContactInfoProps) {
  const role = useAppSelector((state: RootState) => state.auth.role);

  function handleNextView() {
    setCurrentStep((prev) => prev + 1);
  }

  return (
    <section className="my-6 min-h-[50vh] w-full md:my-12">
      <div className="mx-auto flex max-w-2xl flex-col items-center gap-2 px-2">
        {/* Create Tournament */}
        <div className="mb-6 flex flex-col gap-2">
          <h2 className="text-center text-[28px] font-medium text-primary-black-darker md:text-4xl">
            Contact Information
          </h2>

          <p className="text-center text-base text-grey md:text-xl">
            How can people reach you?
          </p>
        </div>
        <form
          className="flex w-full flex-col items-start gap-6 px-2"
          onSubmit={handleSubmit(handleNextView)}
        >
          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="contact-name">
              Contact Person<span className="text-bold text-red"> *</span>
            </label>
            <Input
              role={role}
              register={register}
              {...register("contactName", {
                required: "Please enter your name",
                maxLength: 30,
              })}
              id="contact-name"
              type="text"
              placeholder="Enter contact name"
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4  focus:border-opacity-30",
                {
                  "border-red focus:border-4 focus:border-red focus:border-opacity-30":
                    errors?.contactName,
                }
              )}
            />
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="phone-number" className="text-sm md:text-base">
              Mobile<span className="text-red">*</span>
            </label>
            <RegisteredPhoneInput
              name="phoneNumber"
              error={errors.phoneNumber?.message}
              phonecodeName={"phonecode"}
              register={register}
              required="Phone number is required"
              id="phone-number"
              setValue={setValue}
            />
            {errors.phoneNumber && (
              <span className="text-red">{errors.phoneNumber?.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="contact-email">
              Contact email<span className="text-bold text-red"> *</span>
            </label>
            <Input
              role={role}
              register={register}
              {...register("contactEmail", {
                required: "Email is required",
                maxLength: 30,
              })}
              type="email"
              placeholder="Enter your email address"
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey-light focus:border-4  focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.contactEmail,
                }
              )}
            />
            {errors.contactEmail && (
              <span className="text-red">{errors.contactEmail?.message}</span>
            )}
          </fieldset>
          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="description">
              Contact address<span className="text-bold text-red"> *</span>
            </label>
            <Textarea
              role={role}
              register={register}
              {...register("contactAddress", {
                required: "Address is required",
              })}
              placeholder="Enter your address"
            />
            {errors.contactAddress && (
              <span className="text-red">{errors.contactAddress?.message}</span>
            )}
          </fieldset>

          {fields.length > 0 && (
            <ul className="flex w-full flex-col">
              {fields.map((item, index) => (
                <li key={item.id} className="flex flex-col gap-2">
                  <fieldset className="flex flex-col gap-2">
                    <label htmlFor={item.id}>
                      Co-organiser Name
                      <span className="text-bold text-red"> *</span>
                    </label>
                    <div className="flex w-full">
                      <Input
                        role={role}
                        register={register}
                        {...register(`coOrganizers.${index}.name`, {
                          required: "Please enter co-organizer name",
                          maxLength: 30,
                        })}
                        id={item.id}
                        type="text"
                        placeholder="Enter contact name"
                        className={cn(
                          "mt-0 w-full rounded-[4px_0_0_4px] px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                          {
                            "border-red focus:border-4 focus:border-red focus:border-opacity-30":
                              errors?.coOrganizers,
                          }
                        )}
                      />
                      <Button
                        role={role}
                        intent={"secondary"}
                        type="button"
                        onClick={() => remove(index)}
                        className={cn("rounded-[0_4px_4px_0] border-l-0", {
                          "border-red  ": errors?.coOrganizers,
                        })}
                      >
                        <LaDelete />
                      </Button>
                    </div>
                  </fieldset>
                  <fieldset className="flex flex-col gap-2">
                    <label htmlFor={item.id}>
                      Co-organiser Phone
                      <span className="text-bold text-red"> *</span>
                    </label>
                    <div className="flex w-full">
                      <div className="w-full">
                        <RegisteredPhoneInput
                          name={`coOrganizers.${index}.phone`}
                          divClass="mt-0"
                          inputClass={cn("rounded-none", {
                            "border-red  ": errors?.coOrganizers,
                          })}
                          error={errors.coOrganizers?.message}
                          phonecodeName={`coOrganizers.${index}.phoneCode`}
                          register={register}
                          required="Phone number is required"
                          id={item.id}
                          setValue={setValue}
                        />
                      </div>
                      <Button
                        role={role}
                        intent={"secondary"}
                        type="button"
                        onClick={() => remove(index)}
                        className={cn("rounded-[0_4px_4px_0] border-l-0", {
                          "border-red  ": errors?.coOrganizers,
                        })}
                      >
                        <LaDelete />
                      </Button>
                    </div>
                  </fieldset>
                </li>
              ))}
            </ul>
          )}
          <fieldset className="flex flex-col gap-2">
            <p>
              Do you have co-organizers? Add their numbers for updates like live
              scores, stats, etc.
            </p>

            <Button
              role={role}
              intent="tertiary"
              type="button"
              className="self-end bg-primary-black-lighter py-2"
              onClick={() => append({ name: "", phone: "", phoneCode: "+234" })}
            >
              <LaPlusIcon stroke="#000" />
              Add co-organisers
            </Button>
          </fieldset>

          <fieldset className="mt-6 w-full gap-4 pb-24 md:mt-14">
            <button className="flex w-full  items-center justify-center rounded bg-primary-black-darker p-4 text-base text-white hover:bg-primary-black-lighter hover:text-black disabled:bg-grey-light disabled:text-grey md:text-2xl">
              Proceed
            </button>
          </fieldset>
        </form>
      </div>
    </section>
  );
}

export default TournamentContactInfo;
