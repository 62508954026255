import type { ReactElement } from "react";
import { LaArrowLeft, LaPlusIcon } from "../../components/svg-icons";
import PlayerBadgeXs from "../../components/ui/cards/PlayerBadgeXs";
import { PlayerCard } from "../../components/ui/cards";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchBar from "../../components/ui/search/SearchBar";
import { useAppDispatch } from "../../redux/app/hooks";
import { useJoinTrainingMutation } from "../../redux/services/trainingService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import React from "react";
import useDebounce from "../../hooks/useDebounce";
import { useSearchQuery } from "../../redux/services/searchService";
import { Spinner } from "../../components/ui";
import { cn } from "../../utils/cn";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { useGetPlayersQuery } from "../../redux/services/playerService";
import HelmetComponent from "../../components/HelmetComponent";

export interface TeamTrainingPlayersProps {}

export default function TeamTrainingPlayers(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: players } = useGetPlayersQuery({
    page: 1,
    count: 10,
  });
  const [addPlayerToTraining] = useJoinTrainingMutation();
  const [paramsTrainingId] = useSearchParams();
  const trainingId = paramsTrainingId.get("trainingId") || "";
  const [searchText, setSearchText] = React.useState<string>("");
  const debouncedValue = useDebounce(searchText, 1000); // Delay of 1s
  const {
    data: result,
    isLoading,
    isFetching,
  } = useSearchQuery(
    { kwd: debouncedValue, seg: "player" },
    {
      skip: !debouncedValue,
    }
  );

  const playersData = result as TPlayer[];
  const [selected, setSelected] = React.useState<string[]>([]);
  const insideRef = React.useRef<HTMLDivElement>(null);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Team Training Players",
    description: "Add players to the training session",
    url: `https://${window.location.host}/training/players`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  useOnClickOutside(insideRef, () => {
    setSearchText("");
  });

  function handleAddPlayer(playerId?: string) {
    if (!trainingId || !playerId) return dispatch(setToastError(true));
    addPlayerToTraining({ trainingId, playerId })
      .unwrap()
      .then(() => {
        setSelected((prev) => [...prev, playerId]);
        dispatch(setToastSuccess("Player added to training"));
      })
      .catch(() => {
        dispatch(setToastError("Error adding player to training"));
      });
  }

  const playerList = players?.map((player, index) => {
    return (
      <PlayerCard
        id={player?._id}
        username={player?.name}
        avatar={player?.avatar}
        appearance={player?.apps}
        assist={player?.assists}
        goals={player?.goals}
        playerWing={player?.position}
        redCards={player?.redCards}
        yellowCards={player?.yellowCards}
        key={`${player?._id}${index}`}
        action="Add player"
        handleClick={handleAddPlayer}
        added={selected.includes(player._id)}
      />
    );
  });

  const playerListDesktop = players?.map((player) => {
    const added = selected.includes(player._id);
    return (
      <ul
        key={player._id}
        className="grid grid-cols-6 border-b border-grey-light py-3 text-center"
      >
        <li>
          <div className="flex gap-3">
            <div className="w-[70px]">
              <PlayerBadgeXs
                appearance={player.apps}
                avatar={player?.avatar}
                assist={player.assists}
                goals={player.goals}
                playerName={player.name}
                playerWing={player.position}
              />
            </div>
            <p className="capitalize">{player?.name}</p>
          </div>
        </li>
        <li>{player.goals}</li>
        <li>{player.assists}</li>
        <li>{player.yellowCards}</li>
        <li>{player.assists}</li>
        <li className="flex items-start justify-center">
          <button
            className={cn(
              "rounded bg-[#E6EDFE] p-2 text-xs font-medium text-primary-blue-darker disabled:cursor-not-allowed disabled:opacity-50",
              {
                "bg-grey-light text-primary-black-darker": added,
              }
            )}
            disabled={added}
            onClick={() => handleAddPlayer(player._id)}
          >
            <span className="flex items-center gap-1 ">
              <LaPlusIcon strokeWidth={2} stroke={added ? "#1a1a1a" : "#0045f6"} />{" "}
              {added ? "Added" : "Add player"}
            </span>
          </button>
        </li>
      </ul>
    );
  });

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full px-4 py-4 pb-24 lg:max-w-[87%] lg:px-0 lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Players</h3>
          </div>

          <div className="relative my-4 w-full lg:max-w-md">
            <SearchBar setSearchText={setSearchText} />
            <div
              className="absolute top-[calc(100%+1rem)] z-40 w-full bg-white shadow"
              ref={insideRef}
            >
              {isLoading || isFetching ? (
                <div className="flex w-full items-center justify-center py-2">
                  <Spinner />
                </div>
              ) : (
                <>
                  {searchText &&
                    playersData?.map((player) => (
                      <PlayerCard
                        action="Add player"
                        handleClick={handleAddPlayer}
                        avatar={player?.avatar}
                        appearance={player?.apps}
                        assist={player?.assists}
                        goals={player?.goals}
                        id={player?._id}
                        playerWing={player?.position}
                        redCards={player?.redCards}
                        username={player?.name}
                        yellowCards={player?.yellowCards}
                        key={player?._id}
                        designation={player?.designation}
                        added={selected.includes(player._id)}
                      />
                    ))}
                  {playersData?.length === 0 && (
                    <p className="my-2 text-center text-sm">Not found</p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="hidden lg:block">
            <ul className="hidden grid-cols-6 items-center border-b border-grey-light py-3 text-center text-sm text-grey lg:grid ">
              <li>Player</li>
              <li>Goals</li>
              <li>Assists</li>
              <li>Red cards</li>
              <li>Yellow cards</li>
            </ul>
            <div>{playerListDesktop}</div>
          </div>
          <div className="flex flex-col rounded border border-grey-light lg:hidden lg:border-0">
            {playerList}
          </div>
        </div>
      </div>
    </>
  );
}
