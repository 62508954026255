import { leaguesArenaApi } from "./leaguesArenaApiSlice";
import type { User } from "../../@types/users";

export const userApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<User | undefined, undefined | void>({
      query: () => ({
        url: "users/me",
      }),
      transformResponse: (response: { data: User }) => response.data,
      providesTags: ["User"],
    }),

    getUser: builder.query<User | undefined, { username: string | undefined }>({
      query: ({ username }) => ({
        url: `/users/${username}`,
      }),
      transformResponse: (response: { data: User }) => response.data,
      providesTags: ["User"],
    }),

    updateMe: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: "/users/",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    onboardUser: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: "/users/onboard",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    sendInvites: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: "/users/invites",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    verifyExistingPhone: builder.query<
      | {
          data: User;
        }
      | undefined,
      { phone: string | undefined }
    >({
      query: ({ phone }) => ({
        url: `/users/verify-phone/${phone}`,
      }),
    }),
    sendEmail: builder.mutation({
      query: (body) => ({
        url: "/users/send-email",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdateMeMutation,
  useGetUserQuery,
  useSendInvitesMutation,
  useOnboardUserMutation,
  useVerifyExistingPhoneQuery,
  useSendEmailMutation,
} = userApi;
