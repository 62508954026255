import { cn } from "../../utils/cn";
import LaEmptyState from "../svg-icons/LaEmptyState";

type Props = {
  title: string;
  description: string;
  className?: string;
};

export default function EmptyStateAuto({
  title,
  description,
  className,
}: Props) {
  return (
    <div
      className={cn(
        "mb-10 mt-10 flex flex-col items-center justify-center lg:mt-24",
        className
      )}
    >
      <LaEmptyState />
      <h3 className="mt-8 text-center text-xl font-bold lg:text-2xl">
        {title}
      </h3>
      <p className="mt-2 w-[300px] text-center text-base font-medium text-[#767676]">
        {description}
      </p>
    </div>
  );
}
