function LaDelete() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 4.98333C14.725 4.70833 11.9333 4.56667 9.15 4.56667C7.5 4.56667 5.85 4.65 4.2 4.81667L2.5 4.98333"
        stroke="#E71D36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08333 4.14167L7.26667 3.05C7.4 2.25833 7.5 1.66667 8.90833 1.66667H11.0917C12.5 1.66667 12.6083 2.29167 12.7333 3.05833L12.9167 4.14167"
        stroke="#E71D36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7083 7.61667L15.1667 16.0083C15.075 17.3167 15 18.3333 12.675 18.3333H7.325C5 18.3333 4.925 17.3167 4.83333 16.0083L4.29167 7.61667"
        stroke="#E71D36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.60833 13.75H11.3833"
        stroke="#E71D36"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91667 10.4167H12.0833"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaDelete;
