function LaEmptyState(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={82} height={61} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.119 31.305A12.094 12.094 0 0029 32.998c0 6.628 5.373 12 12 12s12-5.372 12-12c0-.575-.04-1.14-.118-1.693H82v26.693a3 3 0 01-3 3H3a3 3 0 01-3-3V31.305h29.119z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54 31c0 7.18-5.82 13-13 13s-13-5.82-13-13c0-.233.006-.464.018-.693H0L9.56 2.039A3 3 0 0112.402 0h57.196a3 3 0 012.842 2.039L82 30.307H53.982c.012.23.018.46.018.693z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.098 31.954C52.098 37.502 47.129 43 41 43c-6.129 0-11.098-5.498-11.098-11.046 0-.18.006-1.358.016-1.535H9l8.161-19.843C17.513 9.634 18.49 9 19.587 9h42.826c1.098 0 2.074.634 2.426 1.575L73 30.42H52.082c.01.177.016 1.356.016 1.535z"
        fill="#F9F9F9"
      />
      <path
        clipRule="evenodd"
        d="M1.25 30.512V57c0 .967.784 1.75 1.75 1.75h76A1.75 1.75 0 0080.75 57V30.512L71.255 2.44a1.75 1.75 0 00-1.657-1.189H12.402a1.75 1.75 0 00-1.657 1.19L1.25 30.511z"
        stroke="#BBB"
        strokeWidth={2.5}
      />
      <path
        d="M14 30h12.745c1.877 0 1.877 1.319 1.877 2 0 6.627 5.496 12 12.276 12 6.78 0 12.276-5.373 12.276-12 0-.681 0-2 1.876-2H80M6.575 30H9 6.574z"
        stroke="#BBB"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaEmptyState;
