import type { ReactElement } from "react";

interface FormStepsProps {
  currentStep: number;
  hasNext: boolean;
  step: string;
  index: number;
}

export default function FormSteps({
  currentStep,
  index,
  hasNext,
}: // step,
FormStepsProps): ReactElement {
  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center gap-1">
          <div
            className={`flex h-10 w-10 cursor-pointer items-center justify-center rounded-3xl border-2 ${
              currentStep === index
                ? "pagination__step--active"
                : currentStep > index
                ? "pagination__step--prev"
                : "pagination__step--next"
            }`}
          >
            {index}
          </div>
          {/* <div
            className={`text-sm md:text-base ${
              currentStep >= index ? "text-primary-black-darker" : "text-grey"
            }`}
          >
            {step}
          </div> */}
        </div>
      </div>
      {hasNext && (
        <div
          className={`h-0.5 w-[4rem] lg:w-[7.5rem] ${
            currentStep > index ? "bg-primary-black-darker" : "bg-grey"
          }`}
        />
      )}
    </>
  );
}
