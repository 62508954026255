import { RxDotFilled } from "react-icons/rx";
import { PiChatCircleTextFill, PiHeartFill } from "react-icons/pi";
import CommentReply from "./CommentReply";
import React from "react";
import CommentInput from "./CommentInput";
import moment from "moment";

type Props = {
  comment: any;
  // misc: boolean; // TODO: remove this
};
export default function CommentViewBox(props: Props) {
  const [showReply, setShowReply] = React.useState(false);
  return (
    <section className="flex items-start gap-[9px]">
      <img
        src={props.comment?.author?.avatar}
        className="aspect-square h-[43.27px] w-[43.27px] rounded-full bg-black"
      />
      <div className="flex flex-1 flex-col gap-[4px]">
        <div className="flex flex-col space-y-[10px] rounded-lg bg-white px-3 py-2">
          <div className="flex flex-col space-y-[10px] rounded-lg bg-white px-3 py-2">
            <p className="text-sm text-[#101928]">{props.comment?.comment}</p>

            <div className="flex items-center space-x-3">
              <span className="inline-flex items-center justify-center gap-1 rounded-2xl border border-gray-300 bg-white px-1.5 py-0.5">
                <PiHeartFill size={19} fill="#E71D36" />
                <span className="leading-none text-[#0045F6]">
                  {props.comment?.likes?.length.toString()}
                </span>
              </span>
              <span className="inline-flex items-center justify-center gap-1 rounded-2xl border border-gray-300 bg-white px-1.5 py-0.5">
                <PiChatCircleTextFill size={19} fill="#0045F6" />
                <span className="leading-none text-[#0045F6]">
                  {props.comment?.comments}
                </span>
              </span>

              <button
                className="text-xs font-medium text-[#767676]"
                onClick={() => setShowReply(!showReply)}
              >
                Reply
              </button>
            </div>
          </div>

          {showReply && <CommentInput placeHolder="@alex" changeFace />}

          {/* {props.misc && <CommentViewBox misc={false} />} */}
          {/* <CommentReply /> */}
        </div>

        <div className="flex items-center gap-2">
          <p className="text-xs text-[#344054]">
            {props.comment?.author?.fullName}
          </p>
          <RxDotFilled size={12} color="#344054" />
          <p className="text-xs text-[#344054]">
            {moment(props.comment?.updatedAt).fromNow()}
          </p>
        </div>
      </div>
    </section>
  );
}
