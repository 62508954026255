import Input from "../ui/input/Input";
import { Spinner } from "../ui";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { cn } from "../../utils/cn";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { RestartTournamentFormValues } from "../../pages/tournament/RestartTournament";
import { useCreateEditionMutation } from "../../redux/services/tournamentService";
import { useNavigate } from "react-router-dom";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";

interface RestartTournamentRewardFormProps {
  register: UseFormRegister<RestartTournamentFormValues>;
  errors: FieldErrors<RestartTournamentFormValues>;
  handleSubmit: UseFormHandleSubmit<RestartTournamentFormValues>;
  tournamentId: string;
  prevData: Tournament;
}

function RestartTournamentRewardForm({
  register,
  errors,
  handleSubmit,
  tournamentId,
  prevData,
}: RestartTournamentRewardFormProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [createTournamentEdition, { isLoading: isSubmitting }] =
    useCreateEditionMutation();

  const onSubmit: SubmitHandler<RestartTournamentFormValues> = (data) => {
    const formData = new FormData();
    const newName = `${data.name} ${parseFloat(data.edition).toFixed(1)}`;

    for (const key in data) {
      if (key === "avatar" || key === "banner") {
        formData.append(key, data[key][0]);
      } else formData.append(key, data[key]);
    }
    formData.set("name", newName);
    formData.append("parentCompetitionId", tournamentId);

    console.log(Object.fromEntries(formData.entries()), newName);

    createTournamentEdition(formData)
      .unwrap()
      .then((res) => {
        setTimeout(() => {
          navigate(`/competition-editions/${res.data.edition._id}`);
        }, 1000);
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        dispatch(setToastError(true));
        console.log(err);
      });
  };

  return (
    <section className="my-6 min-h-[50vh] w-full md:my-12">
      <div className="mx-auto flex max-w-2xl flex-col items-center gap-2 px-2">
        {/* Create Tournament */}
        <div className="mb-6 flex flex-col gap-2">
          <h2 className="text-center text-[28px] font-medium text-primary-black-darker md:text-4xl">
            Review reward
          </h2>
        </div>
        <form
          className="flex w-full flex-col items-start gap-6 px-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="winner-prize" className="text-sm md:text-base">
              Winner
            </label>

            <Input
              role={role}
              id="winner-prize"
              type="text"
              maxLength={30}
              register={register}
              placeholder="₦150,000"
              defaultValue={prevData?.winnerPrize}
              {...register("winnerPrize", {
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey    focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.winnerPrize,
                }
              )}
            />
            {errors.winnerPrize && (
              <p className="text-xs italic text-red">
                {errors.winnerPrize.message}
              </p>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="runner-up-prize">Second position</label>
            <Input
              id="runner-up-prize"
              type="text"
              {...register("runnerUpPrize", {
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              placeholder="₦100,000"
              register={register}
              defaultValue={prevData?.runnerUpPrize}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.runnerUpPrize,
                }
              )}
              role={role}
            />
            {errors.runnerUpPrize && (
              <span className="text-xs text-red">
                {errors.runnerUpPrize.message}
              </span>
            )}
          </fieldset>
          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="description">Third position</label>
            <Input
              role={role}
              id="third-place-prize"
              type="text"
              {...register("thirdPlacePrize", {
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              register={register}
              placeholder="₦70,000"
              defaultValue={prevData?.thirdPlacePrize}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.thirdPlacePrize,
                }
              )}
            />
            {errors.thirdPlacePrize && (
              <span className="text-xs text-red">
                {errors.thirdPlacePrize.message}
              </span>
            )}
          </fieldset>
          <fieldset className="flex w-full flex-col gap-4 pb-20">
            <button
              type="submit"
              className={cn(
                "mt-6 flex w-full  items-center justify-center rounded bg-primary-black-darker p-4 text-base text-white hover:bg-primary-black-lighter hover:text-black disabled:bg-grey-light disabled:text-grey md:mt-14 md:text-2xl",
                {
                  "bg-primary-black-lighter text-black": isSubmitting,
                }
              )}
            >
              {isSubmitting ? <Spinner /> : "Create Competition"}
            </button>
          </fieldset>
        </form>
      </div>
    </section>
  );
}

export default RestartTournamentRewardForm;
