import * as React from "react";

function LaFirstPlace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={39} height={38} fill="none" {...props}>
      <circle
        cx={19.5}
        cy={19}
        r={19}
        fill="url(#prefix__paint0_linear_8977_39888)"
      />
      <path
        d="M24.504 28.031c-.263 0-.521-.064-.753-.186l-3.95-2.049a.37.37 0 00-.34 0l-3.95 2.049a1.617 1.617 0 01-1.99-.384 1.593 1.593 0 01-.357-1.3l.754-4.34a.357.357 0 00-.106-.32l-3.194-3.075a1.578 1.578 0 01.086-2.364c.231-.191.511-.316.81-.36l4.415-.632a.373.373 0 00.277-.199l1.975-3.95c.133-.267.34-.492.596-.65a1.634 1.634 0 011.707 0c.257.158.463.383.597.65l1.975 3.95a.367.367 0 00.276.199l4.416.632c.298.044.579.169.809.36.23.193.402.445.495.728a1.578 1.578 0 01-.409 1.636l-3.193 3.075a.36.36 0 00-.107.32l.755 4.34a1.581 1.581 0 01-.358 1.3 1.62 1.62 0 01-1.236.57z"
        fill="#470068"
      />
      <path
        d="M20.657 11.203l2.003 4.005a1 1 0 00.757.543l4.477.643c.186.027.36.104.504.224a.992.992 0 01.329.997.99.99 0 01-.275.473l-3.24 3.117a.991.991 0 00-.288.88l.764 4.402a.98.98 0 01-.4.972 1.012 1.012 0 01-1.06.074l-3.997-2.077a1.013 1.013 0 00-.938 0l-4.01 2.077a1.014 1.014 0 01-1.06-.074.981.981 0 01-.4-.972l.765-4.403a.981.981 0 00-.289-.879l-3.24-3.117a.99.99 0 01-.254-1.018.992.992 0 01.308-.453c.144-.119.318-.196.504-.223l4.477-.643a1.017 1.017 0 00.758-.543l2.002-4.005a.996.996 0 01.37-.405 1.014 1.014 0 011.433.405z"
        fill="url(#prefix__paint1_linear_8977_39888)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_8977_39888"
          x1={19.5}
          y1={0}
          x2={19.5}
          y2={38}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BD49D0" />
          <stop offset={0.5} stopColor="#9703B9" />
          <stop offset={1} stopColor="#852878" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_8977_39888"
          x1={19.759}
          y1={10.816}
          x2={19.759}
          y2={25.604}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.082} stopColor="#F7E547" />
          <stop offset={0.226} stopColor="#FEFCAF" />
          <stop offset={0.388} stopColor="#F8DC20" />
          <stop offset={0.59} stopColor="#E67A0B" />
          <stop offset={1} stopColor="#F8DC20" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LaFirstPlace;
