import { useNavigate } from "react-router-dom";
import { LaArrowLeft } from "../../components/svg-icons";
import { EditTeamForm } from "../../components/user-profiles";
import HelmetComponent from "../../components/HelmetComponent";

function EditTeam() {
  const navigate = useNavigate();
  // open graph data
  const ogData = {
    title: "Leagues Arena - Edit Team Profile",
    description: "Edit your team profile on Leagues Arena",
    url: `https://${window.location.host}/profiles/team/edit`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex min-h-screen flex-col items-center pb-10">
        <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Edit Team</h3>
          </div>

          <EditTeamForm />
        </div>
      </div>
    </>
  );
}

export default EditTeam;
