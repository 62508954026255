import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AvatarMenu, Burger } from "../ui";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import MobileMenu from "./MobileMenu";
import formatToNaira from "../../utils/formatPrice";
import { useGetWalletQuery } from "../../redux/services/walletService";
import { useGetMeQuery } from "../../redux/services/userService";

function NavMobile() {
  const [toggleNav, setToggleNav] = React.useState(false);
  const { data: user } = useGetMeQuery();
  const profiles = user?.profiles;
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const currentProfile = profiles?.find((profile) => profile?.role === role);
  const avatar = currentProfile?.avatar;
  const { data: wallet } = useGetWalletQuery();

  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const isBoarded = useAppSelector((state: RootState) => state.auth.isBoarded);
  const location = useLocation();
  useEffect(() => {
    // This effect runs when the location changes.
    setToggleNav(false);
  }, [location]);
  return (
    <>
      <nav
        className={`relative z-[90] flex w-full items-center justify-between  border-b border-grey-light px-4 py-4 md:border-0 md:px-6 md:py-6 lg:hidden`}
      >
        <Link to="/">
          <img
            src={"/logo.png"}
            alt="leagues arena"
            className="relative z-40 w-[94px] object-contain md:w-[99px]"
          />
        </Link>

        <div className="ml-4 flex items-center gap-2">
          {role === "organizer" && (
            <Link
              to="/wallet"
              className="rounded-full bg-green-light px-3 py-1 text-sm text-green-dark"
            >
              {formatToNaira(wallet?.balance ?? 0)}
            </Link>
          )}

          {isLoggedIn && isBoarded ? (
            <div className="flex items-center gap-2">
              <div className="flex flex-col gap-1 leading-none">
                <p className="text-xs font-medium">Viewing as</p>
                <p className="text-sm font-black capitalize">{role}</p>
              </div>
              <AvatarMenu
                avatar={avatar}
                handleToggle={() => setToggleNav(!toggleNav)}
              />
            </div>
          ) : (
            <Burger
              handleToggle={() => setToggleNav(!toggleNav)}
              toggleNav={toggleNav}
            />
          )}
        </div>
      </nav>

      <MobileMenu
        toggleNav={toggleNav}
        userProfile={role}
        setToggleNav={setToggleNav}
        user={currentProfile}
      />
    </>
  );
}

export default NavMobile;
