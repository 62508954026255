import FirstRound from "./FirstRound";
import SecondRound from "./SecondRound";
import ThirdRound from "./ThirdRound";
import FifthRound from "./FifthRound";
import FourthRound from "./FourthRound";
import groupedArray from "../../utils/groupedArray";
import EmptyStateAuto from "../ui/EmptyStateAuto";

type MatchBracketProps = {
  fixtures: { round: number; matches: KnockoutMatch[] }[];
  tournamentComplete?: boolean;
};

function MatchBracket({
  fixtures = [],
  tournamentComplete,
}: MatchBracketProps) {
  const stage: {
    [key: number]: string;
    1: string;
    2: string;
    4: string;
    8: string;
    16: string;
  } = {
    1: "Finals",
    2: "Semi-Finals",
    4: "Quarter-Finals",
    8: "Round of 16",
    16: "Round of 32",
  };

  const margin: {
    [key: number]: string;
  } = {
    1: "mt-10",
    2: "mt-[76px]",
    3: "mt-[145px]",
    4: "mt-[290px]",
    5: "mt-[590px]",
  };

  const gap: {
    [key: number]: string;
  } = {
    1: "gap-4",
    2: "gap-[86px]",
    3: "gap-[218px]",
    4: "gap-[126px]",
  };

  const brackets = [...fixtures]
    ?.sort((a, b) => a?.round - b?.round)
    ?.map((fixture, i) => {
      const matchStages = fixture?.matches?.length;

      return (
        <div
          className={`odd:bg-grey-lighter even:bg-white ${
            fixture.round === 1 && "pl-4"
          } pt-4`}
          key={i}
        >
          <h3 className="text-center text-sm font-medium text-grey">
            {matchStages && stage[matchStages]}
          </h3>
          <div
            className={`${margin[fixture.round]} flex flex-col items-start ${
              gap[fixture.round]
            } pb-6`}
          >
            {fixtures?.length === i + 1 ? (
              <FifthRound
                match={fixture.matches[0]}
                tournamentComplete={tournamentComplete}
              />
            ) : (
              groupedArray(fixture?.matches)?.map(
                (match: [KnockoutMatch, KnockoutMatch]) => {
                  const bracketComponent: { [key: number]: JSX.Element } = {
                    1: <FirstRound matches={match} key={match[0]._id} />,
                    2: <SecondRound matches={match} key={match[0]._id} />,
                    3: <ThirdRound matches={match} key={match[0]._id} />,
                    4: <FourthRound matches={match} key={match[0]._id} />,
                  };
                  return bracketComponent[fixture.round];
                }
              )
            )}
          </div>
        </div>
      );
    });

  return (
    <>
      {brackets?.length === 0 && (
        <EmptyStateAuto
          title="Nothing to see here"
          description="This tournament does not have any bracket yet. when it does it'll appear here"
        />
      )}
      <div className="bg-grey-lighter p-4 lg:px-0">
        <div className="flex overflow-x-scroll rounded border border-grey-light pr-4">
          {brackets}
        </div>
      </div>
    </>
  );
}

export default MatchBracket;
