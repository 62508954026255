import { Middleware, configureStore } from "@reduxjs/toolkit";
import displayModalReducer from "../features/modal/displayModalSlice.js";
import previewImageReducer from "../features/preview/previewImageSlice";
import tournamentReducer from "../features/tournament/tournamentSlice";
import checkboxReducer from "../features/checkbox/checkboxSlice";
import authReducer, {
  setCompetitionsLeftToCreate,
  setIsBoarded,
  setIsLoggedin,
  setMyUsername,
  setOrganizerId,
  setPlayerId,
  setPostsLeft,
  setProfile,
  setProfiles,
  updateRole,
} from "../features/auth/authSlice";
import notificationReducer from "../features/notification/notificationSlice";
import teamReducer from "../features/team/teamSlice";
import userReducer from "../features/user/userSlice";
import searchReducer from "../features/search/searchSlice";
import postReducer from "../features/post/postSlice";
import socketReducer from "../features/socket/socketSlice";
import resultReducer from "../features/result/resultSlice";
import { leaguesArenaApi } from "../services/leaguesArenaApiSlice";
import { leaguesTrainingApi } from "../services/leaguesTrainingApiSlice.js";
import jwtDecode from "jwt-decode";
import { UserToken } from "../../@types/token.js";

const apiMiddleware: Middleware = (store) => (next) => (action) => {
  if (
    // intercepts responses of the userService updateMe mutation and updates the local storage jwt
    action.type === "leaguesArenaApi/executeMutation/fulfilled" &&
    action.payload.token
  ) {
    const decoded: UserToken = jwtDecode(action.payload.token);

    localStorage.setItem("token", action.payload.token);
    store.dispatch(setPostsLeft(decoded.postsLeft));
    store.dispatch(
      setCompetitionsLeftToCreate(decoded.competitionsLeftToCreate)
    );
    store.dispatch(
      setProfile(decoded?.profiles?.find((p) => p?.role === decoded?.role))
    );
    store.dispatch(setProfiles(decoded?.profiles));
    store.dispatch(setMyUsername(decoded.username));
    store.dispatch(setIsLoggedin(true));
    store.dispatch(setIsBoarded(decoded.onBoarded));
    store.dispatch(updateRole(decoded.role));
    store.dispatch(setMyUsername(decoded.username));
  }

  // if (action.payload?.profiles) {
  //   const playerId = action.payload.profiles.find(
  //     (profile: TProfile) => profile.role === "player"
  //   )?.profile;
  //   const organizerId = action.payload.profiles.find(
  //     (profile: TProfile) => profile.role === "organizer"
  //   )?.profile;
  //   store.dispatch(setOrganizerId(organizerId));
  //   store.dispatch(setPlayerId(playerId));
  //   store.dispatch(setProfiles(action.payload.profiles));
  // }

  return next(action);
};

const store = configureStore({
  reducer: {
    displayModal: displayModalReducer,
    createTournament: tournamentReducer,
    previewImage: previewImageReducer,
    checkbox: checkboxReducer,
    auth: authReducer,
    notification: notificationReducer,
    team: teamReducer,
    user: userReducer,
    tournament: tournamentReducer,
    search: searchReducer,
    post: postReducer,
    wsNotification: socketReducer,
    result: resultReducer,
    [leaguesArenaApi.reducerPath]: leaguesArenaApi.reducer,
    [leaguesTrainingApi.reducerPath]: leaguesTrainingApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      leaguesArenaApi.middleware,
      leaguesTrainingApi.middleware,
      apiMiddleware
    );
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
