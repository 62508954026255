import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import * as Icon from "../svg-icons";
import {
  useGetMeQuery,
  useUpdateMeMutation,
} from "../../redux/services/userService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { RootState } from "../../redux/app/store";
import { Button } from "../ui";
import { strokes } from "../../utils/stroke";

type MobileMenuProps = {
  setToggleNav: React.Dispatch<React.SetStateAction<boolean>>;
  toggleNav: boolean;
  userProfile?: string;
  user?: TProfile;
};

function MobileMenu({ setToggleNav, toggleNav, userProfile }: MobileMenuProps) {
  const navigate = useNavigate();
  const { data: user } = useGetMeQuery();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const isOnBoarded = useAppSelector(
    (state: RootState) => state.auth.isBoarded
  );

  const profiles = user?.profiles;
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const [updateUser] = useUpdateMeMutation();
  const dispatch = useAppDispatch();
  const names:
    | {
        [key: string]: string;
      }
    | undefined = profiles?.reduce((acc, profile) => {
    const name = profile.name.toLowerCase().replace(/\s/g, "-");
    return { ...acc, [profile.role]: name };
  }, {});

  const profileCards = profiles?.map((profile) => {
    const isCurrent = profile.role?.toLowerCase() === role;
    return (
      <button
        onClick={() => handleSwitchProfile(profile?.role)}
        className="flex min-w-fit flex-col items-center gap-2 disabled:cursor-not-allowed"
        key={profile?.role}
        aria-label="switch-profile"
      >
        <img
          src={profile.avatar}
          alt="profile-avatar"
          className={`h-14 w-14 rounded-full text-sm`}
        />
        <Button
          // onClick={() => handleSwitchProfile(profile?.role)}
          disabled={isCurrent}
          role={role}
          intent="primary"
          className={`w-full rounded p-1 text-xs font-medium capitalize`}
        >
          {profile.role}
        </Button>
      </button>
    );
  });

  function handleSwitchProfile(role: string) {
    updateUser({ role })
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setToastSuccess(true));
        setToggleNav(false);
        navigate(`/profiles/${role}/${names?.[role]}`);
      })

      .catch((err) => {
        dispatch(setToastError(true));
        setToggleNav(false);
        console.log(err);
      });
  }

  return (
    <div
      className={`absolute bottom-0 right-0 top-0 z-[80] h-full min-h-full w-full bg-[#00000084] lg:hidden ${
        toggleNav ? "flex" : "hidden"
      } flex-col`}
    >
      <nav className="flex min-h-screen flex-col gap-10 bg-white px-4 py-24 text-2xl">
        {isLoggedIn && isOnBoarded ? (
          <>
            <div className="flex gap-6">
              {profileCards}

              <Link
                to={"/account/onboarding"}
                className="flex  flex-col items-center justify-between gap-2"
                onClick={() => setToggleNav(false)}
              >
                <Button
                  className="svg__icon flex h-14 w-14 items-center justify-center rounded-full"
                  role={userProfile?.toLowerCase() || role}
                  intent="secondary"
                >
                  <Icon.LaPlusLarge stroke={strokes[role]} />
                </Button>
                <p className="text-xs">Add Profile</p>
              </Link>
            </div>
            <NavLink to="/settings" onClick={() => setToggleNav(false)}>
              <span className="flex items-center gap-4">
                <Icon.LaSettings />
                Settings
              </span>
            </NavLink>
            <NavLink to="/faqs" onClick={() => setToggleNav(false)}>
              <span className="flex items-center gap-4">
                <Icon.LaHelp />
                Help
              </span>
            </NavLink>
            <NavLink className="cursor-pointer" to="/logout">
              <span className="flex items-center gap-4 text-red">
                <span className="text-2xl">
                  <Icon.LaLogout />
                </span>
                Logout
              </span>
            </NavLink>
          </>
        ) : (
          <>
            <NavLink to="/contact" onClick={() => setToggleNav(false)}>
              <span className="flex items-center gap-4">
                <Icon.LaPhone strokeWidth={2} />
                Contact Us
              </span>
            </NavLink>
            <NavLink to="/account/login" onClick={() => setToggleNav(false)}>
              <span className="flex items-center gap-4">
                <Icon.LaLogin />
                Login
              </span>
            </NavLink>
            <NavLink to="/account/signup" onClick={() => setToggleNav(false)}>
              <span className="flex items-center gap-4">
                <Icon.LaProfilePlus />
                Create Account
              </span>
            </NavLink>
          </>
        )}
      </nav>
    </div>
  );
}

export default MobileMenu;
