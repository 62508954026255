import { useSearchParams } from "react-router-dom";
import { profiles } from "../../assets/data/profiles";
import defaultProfilePic from "../../assets/images/defaultProfilePic.webp";

import {
  OrganizerForm,
  PlayerForm,
  TeamForm,
  UserRoleModal,
} from "../../components/on-boarding";
import { Spinner } from "../../components/ui";
import useImagePreloader from "../../hooks/useImagePreloader";
import { useGetMeQuery } from "../../redux/services/userService";
import OnboardingLayout from "../../layouts/OnboardingLayout";
import {
  OrganizerSlideOne,
  OrganizerSlideTwo,
} from "../../components/on-boarding/OrganizerSlides";
import {
  PlayerSlideOne,
  PlayerSlideTwo,
} from "../../components/on-boarding/PlayerSlides";
import {
  TeamSlideOne,
  TeamSlideThree,
  TeamSlideTwo,
} from "../../components/on-boarding/TeamSlides";
import HelmetComponent from "../../components/HelmetComponent";

function Onboarding() {
  const [onBoardingParams, setOnBoardingParams] = useSearchParams();
  const { data: me } = useGetMeQuery();
  const roleParams = (onBoardingParams.get("role") as TRole) || me?.role;
  const stepParams = parseInt(
    onBoardingParams.get("step") || (roleParams && me?.profiles.length === 0) ? "2" : "1"
  );

  const onBoardingForms: {
    [key in TRole]: JSX.Element;
  } = {
    organizer: <OrganizerForm setOnBoardingParams={setOnBoardingParams} />,
    player: <PlayerForm setOnBoardingParams={setOnBoardingParams} />,
    team: <TeamForm setOnBoardingParams={setOnBoardingParams} />,
  };

  const { imagesPreloaded } = useImagePreloader([
    defaultProfilePic,
    ...profiles.map((profile) => profile.image),
  ]);

  if (!imagesPreloaded) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }
  const organizerSlides = [<OrganizerSlideOne />, <OrganizerSlideTwo />];
  const teamSlides = [<TeamSlideOne />, <TeamSlideTwo />, <TeamSlideThree />];
  const playerSlides = [<PlayerSlideOne />, <PlayerSlideTwo />];

  const slides: {
    [key in TRole]: JSX.Element[];
  } = {
    organizer: organizerSlides,
    player: playerSlides,
    team: teamSlides,
  };

  // open graph data
  const ogData = {
    title: "Leagues Arena - Onboarding",
    description: "Onboard on Leagues Arena",
    url: `https://${window.location.host}/account/onboarding`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {stepParams === 1 && (
        <UserRoleModal
          setOnBoardingParams={setOnBoardingParams}
          role={me?.role as TRole}
          profiles={me?.profiles}
        />
      )}

      {stepParams === 2 && roleParams && (
        <OnboardingLayout
          slides={slides[roleParams]}
          setOnBoardingParams={setOnBoardingParams}
          roleParams={roleParams}
        >
          {onBoardingForms[roleParams]}
        </OnboardingLayout>
      )}
    </>
  );
}
export default Onboarding;
