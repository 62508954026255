function LaPlayers({ stroke = "#1a1a1a" }: { stroke?: string; }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" >
      <g
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6.107 7.747a1.213 1.213 0 00-.22 0A2.947 2.947 0 013.04 4.793c0-1.633 1.32-2.96 2.96-2.96a2.957 2.957 0 01.107 5.913zM10.94 3.167A2.332 2.332 0 0113.273 5.5c0 1.26-1 2.287-2.246 2.333a.754.754 0 00-.174 0M2.773 10.207c-1.613 1.08-1.613 2.84 0 3.913 1.834 1.227 4.84 1.227 6.674 0 1.613-1.08 1.613-2.84 0-3.913-1.827-1.22-4.834-1.22-6.674 0zM12.227 13.833c.48-.1.933-.293 1.306-.58 1.04-.78 1.04-2.066 0-2.846a3.315 3.315 0 00-1.286-.574" />
      </g>
    </svg>
  );
}

export default LaPlayers;
