/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useParams } from "react-router-dom";
import { LaArrowLeft, LaPlusIcon } from "../../components/svg-icons";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import generateKnockoutFixtures from "../../utils/generateFixtures";
import React, { useEffect, useReducer, useState } from "react";
import { cn } from "../../utils/cn";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { Spinner } from "../../components/ui";
import { useSaveFixturesMutation } from "../../redux/services/matchService";
import {
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useAppDispatch } from "../../redux/app/hooks";
import { ConfirmPopup } from "../../components/modals";
import { AutoPreLoader } from "../../components/ui/spinner";
import MatchGraphic from "../../components/MatchGraphic";
import HelmetComponent from "../../components/HelmetComponent";

type TFixture = {
  teamData: {
    team: string;
    score: number;
    scorers: never[];
    assisters: never[];
    yellowCards: never[];
    redCards: never[];
  }[];
  date: Date;
  time: Date;
  location: string;
  tournament: string;
  round: number;
  name: string;
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "SET_KNOCKOUT_FIXTURES":
      return [...action.payload];
    case "RESET":
      return state?.map((fixture: TFixture) => {
        return fixture.teamData
          ? {
              ...fixture,
              teamData: [
                { ...fixture.teamData[0], team: null },
                { ...fixture.teamData[1], team: null },
              ],
            }
          : fixture;
      });
    default:
      return state?.map((fixture: TFixture, index: number) => {
        if (index + 1 === action.matchNo) {
          return {
            ...fixture,
            teamData: [
              action.index === 0
                ? { ...fixture.teamData[0], team: action.team?._id }
                : { ...fixture.teamData[0] },
              action.index === 1
                ? { ...fixture.teamData[1], team: action.team?._id }
                : { ...fixture.teamData[1] },
            ],
          };
        }
        return fixture;
      });
  }
}

export default function CreateFixtures() {
  const navigate = useNavigate();
  const { id } = useParams();
  const reduxDispatch = useAppDispatch();
  const { data: tournament, isLoading } = useGetTournamentQuery({ id });
  const [saveFixtures, { isLoading: isPublishing }] = useSaveFixturesMutation();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showGraphic, setShowGraphic] = useState(false);

  const hasAllPaid = tournament?.teams.every((team) => team?.registered);
  const round = Math.log2(
    tournament?.teams.length ? tournament?.teams.length / 2 : 2
  );
  const teams = tournament?.teams?.map((team) => team?.team) || [];
  const stages: {
    [key: number]: string;
  } = {
    1: "Finals",
    2: "Semi Finals",
    3: "Quarter Finals",
    4: "Round 16",
    5: "Round 32",
  };

  const knockoutFixtures = React.useMemo(() => {
    if (!hasAllPaid) return null;
    return tournament && generateKnockoutFixtures(tournament);
  }, [tournament]);

  const [state, dispatch] = useReducer(reducer, null);
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);
  console.log(
    state,
    tournament?.hasPaid,
    hasAllPaid,
    knockoutFixtures,
    "state"
  );

  useEffect(() => {
    if (knockoutFixtures && !state) {
      const fixtures = knockoutFixtures.map((fixture) => {
        return fixture.teamData
          ? {
              ...fixture,
              teamData: [
                {
                  team: null,
                  score: 0,
                  scorers: [],
                  assisters: [],
                  yellowCards: [],
                  redCards: [],
                },
                {
                  team: null,
                  score: 0,
                  scorers: [],
                  assisters: [],
                  yellowCards: [],
                  redCards: [],
                },
              ],
            }
          : fixture;
      });

      dispatch({ type: "SET_KNOCKOUT_FIXTURES", payload: fixtures });
    }
  }, [knockoutFixtures]);

  const handleResetFixtures = () => {
    dispatch({ type: "RESET" });
    setSelectedTeams([]);
  };

  const teamCards =
    state?.map((fixture: any, index: number) => {
      return (
        <SelectTeamCard
          key={index}
          fixture={fixture}
          matchNo={index + 1}
          teams={teams as Team[]}
          dispatch={dispatch}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
        />
      );
    }) || [];

  const handlePublishFixtures = () => {
    setShowConfirmPopup(false);
    saveFixtures({
      fixtures: state,
      tournamentId: id,
    })
      .unwrap()
      .then(() => {
        reduxDispatch(setToastSuccess(true));
        setShowGraphic(true);
        console.log("success");
      })
      .catch((err) => {
        reduxDispatch(
          setToastErrorText(err.data.message || "An error occurred")
        );
        console.log(err, "err");
      });
  };

  // open graph data
  const ogData = {
    title: "Leagues Arena - Create Fixtures",
    description: "Create fixtures for a tournament on Leagues Arena",
    url: `https://${window.location.host}/competitions/create-fixtures${id}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
            <button
              aria-label="back"
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Manual Fixtures Upload</h3>
          </div>

          <div className="mt-5 flex-col gap-4 lg:mt-10">
            {isLoading ? (
              <div className="flex w-full items-center justify-center">
                <Spinner />
              </div>
            ) : teamCards.length === 0 ? (
              <p className="text-center">Teams registration is not complete</p>
            ) : (
              <>
                <h3 className="text-xl font-bold">{stages[round]}</h3>
                <div className="mt-6 grid gap-4 lg:grid-cols-2 lg:gap-6">
                  {teamCards}
                </div>
              </>
            )}
          </div>

          <div className="fixed bottom-0 right-0 z-[80] grid w-full grid-cols-2 gap-4 border-t border-grey-light bg-white p-4 lg:max-w-[82%] lg:gap-8">
            <button
              className="w-full rounded bg-red py-3 text-white disabled:cursor-not-allowed disabled:opacity-50 lg:text-xl"
              onClick={handleResetFixtures}
              disabled={selectedTeams.length < 1}
            >
              Reset Fixtures
            </button>

            <button
              className="w-full rounded bg-primary-black-darker py-3 text-white disabled:cursor-not-allowed disabled:opacity-50 lg:text-xl"
              onClick={() => setShowConfirmPopup(true)}
              disabled={selectedTeams.length !== teams.length / 2}
            >
              Publish Fixtures
            </button>
          </div>
        </div>
      </div>
      {showConfirmPopup && (
        <ConfirmPopup
          heading="Publish This Fixture?"
          description="Fixtures are irreversible. Are you sure you want to publish this fixture?"
          type="warning"
          buttons={[
            {
              class: "primary",
              handleClick: handlePublishFixtures,
              text: "Yes, Publish",
              role: "organizer",
            },
          ]}
        />
      )}
      {isPublishing && (
        <div className="absolute bottom-0 right-0 top-0 z-[100] flex min-h-screen w-[100vw] items-center justify-center bg-black/20">
          <div className="flex flex-col items-center justify-center gap-4 bg-white p-8">
            <div className="h-[200px] w-[200px]">
              <AutoPreLoader />
            </div>
            <p className="font-bold">Publishing Fixtures</p>
          </div>
        </div>
      )}
      {showGraphic && (
        <section className="fixed bottom-0 left-0 right-0 top-0 z-[90] flex items-start justify-center bg-black/20">
          <div className="mt-4 rounded-lg bg-white p-4">
            <MatchGraphic
              open={showGraphic}
              fixture={state.slice(0, round).map((fixture: any) => {
                return {
                  ...fixture,
                  teamData: fixture.teamData.map((data: any) => {
                    return {
                      ...data,
                      // @ts-expect-error // ignore type
                      team: teams.find((t) => t?._id === data?.team),
                    };
                  }),
                };
              })}
              tournament={tournament!}
              loc={`/competitions/${id}?tab=matches&subTab=fixtures`}
            />
          </div>
        </section>
      )}
    </>
  );
}

interface SelectTeamCardProps {
  fixture: TFixture;
  matchNo: number;
  teams: Team[];
  dispatch: React.Dispatch<any>;
  selectedTeams: Team[];
  setSelectedTeams: React.Dispatch<React.SetStateAction<Team[]>>;
}
function SelectTeamCard({
  fixture,
  dispatch,
  matchNo,
  teams,
  selectedTeams,
  setSelectedTeams,
}: SelectTeamCardProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [showTeamA, setShowTeamA] = useState(false);
  const [showTeamB, setShowTeamB] = useState(false);

  const [teamA, teamB] = fixture?.teamData || [];

  const displayTeams = teams?.reduce((acc: any, team: any) => {
    acc[team._id] = team;
    return acc;
  }, {});

  useOnClickOutside(ref, () => {
    setShowTeamA(false);
    setShowTeamB(false);
  });

  return (
    fixture?.teamData && (
      <div className="rounded border border-grey-light p-3">
        <p className="text-xs">Match {matchNo}</p>

        <div className="mt-4 grid grid-cols-[1fr_50px_1fr] gap-3" ref={ref}>
          {displayTeams[teamA?.team] ? (
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2">
                <img
                  src={displayTeams[teamA?.team]?.avatar}
                  alt={""}
                  className="h-8 w-8 rounded-full"
                />
                <p className="capitalize">{displayTeams[teamA?.team]?.name}</p>
              </div>
            </div>
          ) : (
            <div className="relative w-full">
              <button
                className="flex items-center gap-4 rounded border border-grey-light px-4 py-2"
                onClick={() => {
                  setShowTeamB(false);
                  setShowTeamA(!showTeamA);
                }}
              >
                Team <LaPlusIcon className="text-3xl" />
              </button>
              {showTeamA && (
                <TeamDropDOwn
                  teams={teams}
                  showingTeamA={showTeamA}
                  dispatch={dispatch}
                  matchNo={matchNo}
                  index={0}
                  selectedTeams={selectedTeams}
                  setSelectedTeams={setSelectedTeams}
                />
              )}
            </div>
          )}
          <p className="flex items-center justify-center rounded bg-primary-black-lighter px-2 py-2 text-xs">
            VS
          </p>
          {displayTeams[teamB?.team] ? (
            <div className="flex items-center justify-end gap-2">
              <img
                src={displayTeams[teamB?.team]?.avatar}
                alt={""}
                className="h-8 w-8 rounded-full"
              />
              <p className="capitalize">{displayTeams[teamB?.team]?.name}</p>
            </div>
          ) : (
            <div className="relative flex w-full justify-end">
              <button
                className="flex items-center gap-4 rounded border border-grey-light px-4 py-2"
                onClick={() => {
                  setShowTeamA(false);
                  setShowTeamB(!showTeamB);
                }}
              >
                Team <LaPlusIcon className="text-3xl" />
              </button>
              {showTeamB && (
                <TeamDropDOwn
                  teams={teams}
                  showingTeamA={showTeamA}
                  dispatch={dispatch}
                  matchNo={matchNo}
                  index={1}
                  selectedTeams={selectedTeams}
                  setSelectedTeams={setSelectedTeams}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
}

interface TeamDropDOwnProps {
  teams: Team[];
  showingTeamA: boolean;
  dispatch: React.Dispatch<any>;
  matchNo: number;
  index: number;
  selectedTeams: Team[];
  setSelectedTeams: React.Dispatch<React.SetStateAction<Team[]>>;
}

function TeamDropDOwn({
  teams,
  showingTeamA,
  dispatch,
  matchNo,
  index,
  selectedTeams,
  setSelectedTeams,
}: TeamDropDOwnProps) {
  const handleSelectTeam = (team: Team) => {
    setSelectedTeams((prev) => [...prev, team]);
    dispatch({ team, matchNo, index });
  };

  return (
    <div
      className={cn(
        "absolute right-0 top-[calc(100%+8px)] z-[90] flex min-w-[200px] flex-col rounded border border-grey-light bg-white",
        {
          "left-0": showingTeamA,
        }
      )}
    >
      {teams?.map((team) => (
        <button
          className="flex items-center gap-2 px-4 py-2 hover:bg-grey-lighter disabled:cursor-not-allowed disabled:bg-grey-lighter disabled:opacity-50"
          onClick={() => handleSelectTeam(team)}
          key={team?._id}
          disabled={selectedTeams?.includes(team)}
        >
          <img
            src={team?.avatar}
            alt={team?.name}
            className="h-8 w-8 rounded-full"
          />
          <p className="capitalize">{team?.name}</p>
        </button>
      ))}
    </div>
  );
}
