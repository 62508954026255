import React from "react";

import { LaCloseRed } from "../../svg-icons";
import { useAppDispatch } from "../../../redux/app/hooks";
import { setToastError } from "../../../redux/features/modal/displayModalSlice";

function ToastError() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setToastError(false));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg__shadow fixed right-4 top-4 z-[90]  flex w-[90%] items-center gap-6 rounded bg-white p-6 text-2xl font-medium md:bottom-10 md:right-10 md:top-auto md:w-[450px]">
      <div className=" flex h-[48px] w-[48px] items-center justify-center rounded-full border-4 border-[#E71D36]">
        <LaCloseRed />
      </div>
      <div>
        <p>Error</p>
        <p className="text-base font-normal">Please try again</p>
      </div>
    </div>
  );
}

export default ToastError;
