import { Link } from "react-router-dom";
import { LaArrowRight } from "../svg-icons";

interface ExtrasTabProps {
  tournamentId?: string;
}

export default function ExtrasTab({ tournamentId }: ExtrasTabProps) {
  return (
    <div className="mt-6 grid gap-4 lg:grid-cols-3 lg:gap-8">
      <Link
        to={`/competitions/${tournamentId}/player-transfer`}
        className={
          "relative flex flex-col gap-2 rounded-lg bg-[url('/src/assets/images/edition-banner.png')] px-4 py-5 text-white"
        }
      >
        <h3 className="text-xl font-medium lg:text-2xl">Player Transfer</h3>
        <p className="text-sm font-medium">
          Transfer player from one team to another in this competition
        </p>
        <p className="absolute bottom-4 right-4 flex items-center gap-2 text-xs">
          View <LaArrowRight stroke="#fff" />
        </p>
      </Link>
    </div>
  );
}
