export default function LaWalletCLosed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.0833 23.2305H14.5833"
        stroke={props.stroke || "#111111"}
        strokeWidth={props.strokeWidth || "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16675 23.2285V13.6035C4.16675 9.35352 7.60425 5.91602 11.8543 5.91602H23.5626C27.8126 5.91602 31.2501 8.56184 31.2501 12.8118"
        stroke={props.stroke || "#111111"}
        strokeWidth={props.strokeWidth || "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.4167 25.4166C35.375 26.4166 34.8751 27.9583 35.2918 29.5416C35.8126 31.4791 37.7293 32.7083 39.7293 32.7083H41.6667V35.7292C41.6667 40.3333 37.9376 44.0625 33.3334 44.0625H12.5001C7.89591 44.0625 4.16675 40.3333 4.16675 35.7292V21.1458C4.16675 16.5417 7.89591 12.8125 12.5001 12.8125H33.3334C37.9167 12.8125 41.6667 16.5625 41.6667 21.1458V24.1666H39.4167C38.25 24.1666 37.1875 24.625 36.4167 25.4166Z"
        stroke={props.stroke || "#111111"}
        strokeWidth={props.strokeWidth || "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.8334 26.293V30.5847C45.8334 31.7513 44.875 32.7097 43.6875 32.7097H39.6667C37.4167 32.7097 35.3542 31.0639 35.1667 28.8139C35.0417 27.5014 35.5417 26.2722 36.4167 25.418C37.1875 24.6264 38.25 24.168 39.4167 24.168H43.6875C44.875 24.168 45.8334 25.1263 45.8334 26.293Z"
        stroke={props.stroke || "#111111"}
        strokeWidth={props.strokeWidth || "2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
