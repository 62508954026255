import { leaguesArenaApi } from "./leaguesArenaApiSlice";

const matchApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    generateFixtures: builder.mutation({
      query: (body) => ({
        url: "/matches/generate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Match", "Tournament"],
    }),
    getTournamentMatch: builder.query<Match | undefined, { id?: string }>({
      query: ({ id }) => ({
        url: `/matches/${id}`,
        method: "GET",
      }),
      providesTags: ["Match"],
      transformResponse: (response: { data: Match }) => response.data,
    }),
    getTournamentFixtures: builder.query<
      Fixture | undefined,
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `/matches/tournament-fixtures/${id}`,
        method: "GET",
      }),
      providesTags: ["Match"],
      transformResponse: (response: { data: Fixture }) => response.data,
    }),
    getTournamentMatches: builder.query<
      Match[] | undefined,
      { id: string | undefined; status?: "ongoing" | "completed" }
    >({
      query: ({ id, status }) => ({
        url: `/matches/tournament-matches/${id}`,
        method: "GET",
        params: {
          ...(status && { status }),
        },
      }),
      providesTags: ["Match"],
      transformResponse: (response: { data: Match[] }) => response.data,
    }),
    getUserMatches: builder.query<
      Match[] | undefined,
      { id: string | undefined; status: string; page: number; count: number }
    >({
      query: ({ id, status, page, count }) => ({
        url: `matches/user/${id}?status=${status}&page=${page}&count=${count}`,
        method: "GET",
      }),
      transformResponse: (response: { data: Match[] }) => response.data,
    }),
    getAllTeamMatches: builder.query<Match[] | undefined, NonNullable<unknown>>(
      {
        query: (args: {
          teamId: string;
          page?: number;
          count?: number;
          status: "ongoing" | "completed";
        }) => ({
          method: "GET",
          url: `/matches/team/${args.teamId}?page=${args.page}&count=${args.count}&status=${args.status}`,
        }),
        transformResponse: (response: { data: Match[]; message: string }) =>
          response.data,
      }
    ),
    getDateMatches: builder.query<
      Match[] | undefined,
      { date: string | undefined }
    >({
      query: ({ date }) => ({
        url: `/matches/?date=${date}`,
        method: "GET",
      }),
      transformResponse: (response: { data: Match[] }) => response.data,
    }),
    updateFixtures: builder.mutation({
      query: (body) => {
        return {
          method: "PUT",
          url: `/matches/${body.id}`,
          body,
        };
      },
      invalidatesTags: ["Match"],
    }),
    updateScore: builder.mutation({
      query: ({ id, body }) => {
        return {
          method: "PUT",
          url: `/matches/update-score/${id}`,
          body,
        };
      },
      invalidatesTags: ["Match", "Tournament"],
    }),
    saveFixtures: builder.mutation({
      query: (body) => ({
        url: "/matches/save-fixtures",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Match", "Tournament"],
    }),
  }),
});

export const {
  useGenerateFixturesMutation,
  useGetTournamentMatchesQuery,
  useUpdateFixturesMutation,
  useGetTournamentMatchQuery,
  useUpdateScoreMutation,
  useGetAllTeamMatchesQuery,
  useGetUserMatchesQuery,
  useGetDateMatchesQuery,
  useGetTournamentFixturesQuery,
  useSaveFixturesMutation,
} = matchApi;
