import { useParams } from "react-router-dom";
import expired from "../../assets/images/expired.png";
import { useResendFinalizedAcctTokenMutation } from "../../redux/services/authService";
import { Button } from "../ui";
import { setToastSuccess } from "../../redux/features/modal/displayModalSlice";
import { useAppDispatch } from "../../redux/app/hooks";
import { useState } from "react";
import { EmailSent } from "../modals";
export default function ResendResetLink() {
  const dispatch = useAppDispatch();
  const [resendAcctToken] = useResendFinalizedAcctTokenMutation();
  const { resetToken } = useParams();
  const [displayModal, setDisplayModal] = useState(false);
  const [newResetToken, setNewResetToken] = useState(resetToken);

  function handleExpiredToken() {
    resendAcctToken({ token: newResetToken })
      .unwrap()
      .then((res) => {
        dispatch(setToastSuccess(true));
        setNewResetToken(res.newAcctToken);
        setDisplayModal(true);
        console.log(res);
      })
      .catch((err) => {
        console.log("error");
        console.log(err);
      });
  }

  return (
    <>
      <div className="flex min-h-screen flex-col items-center justify-center gap-3 bg-white px-4 py-20">
        <img src={expired} alt="404 image" className="lg:w-1/2" />
        <h3 className="text-center text-2xl  text-primary-blue-dark  md:text-5xl">
          Link Expired
        </h3>

        <Button
          intent={"primary"}
          role={"player"}
          className=" mt-10 rounded bg-primary-blue-dark px-20 py-4 text-base text-white hover:bg-primary-blue-light hover:text-black md:text-2xl"
          onClick={handleExpiredToken}
        >
          Resend Link
        </Button>
      </div>
      {displayModal && (
        <EmailSent medium="sms" closeModal={() => setDisplayModal(false)} />
      )}
    </>
  );
}
