import { SigninCarousel, SignupForm } from "../../components/accounts";
import { SocialBtn, OrDivider, Spinner } from "../../components/ui";
import carouselData from "../../assets/data/carousel";
import { useGoogleLoginQuery } from "../../redux/services/authService";
import useImagePreloader from "../../hooks/useImagePreloader";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { Navigate } from "react-router-dom";
import { NavBar } from "../../components/navigations";
import { LaGoogle } from "../../components/svg-icons";
import HelmetComponent from "../../components/HelmetComponent";

function SignUp() {
  const { data: query = { url: "" }, isLoading } = useGoogleLoginQuery();
  const { isLoggedIn, role, isBoarded } = useAppSelector(
    (state: RootState) => state.auth
  );

  function handleCLick() {
    window.location.replace(query.url);
  }
  const { imagesPreloaded } = useImagePreloader(carouselData.map((item) => item.src));

  if (isLoggedIn && isBoarded && !role) return <Navigate to="/" />;

  // open graph data
  const ogData = {
    title: "Leagues Arena - Sign Up",
    description: "Create an account on Leagues Arena",
    url: `https://${window.location.host}/account/signup`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {!imagesPreloaded && isLoading ? (
        <div className="flex h-screen w-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <NavBar />

          <div className="flex w-full flex-col items-center justify-center px-4 py-12 pt-8 md:py-16 lg:flex-row lg:items-stretch lg:justify-between lg:px-20 lg:py-20">
            <div className="left flex w-full flex-col items-center md:w-[65%] lg:w-[43%]">
              <h1 className="text-3xl font-medium md:text-5xl">Create an Account</h1>
              <div className="mb-8 mt-8 flex w-full flex-col gap-6 md:mt-14 lg:mb-16">
                <SocialBtn
                  text={"Signup using Google"}
                  icon={<LaGoogle />}
                  handleClick={handleCLick}
                />
              </div>
              <OrDivider />
              <SignupForm />
            </div>
            <div className="right hidden w-[50%] lg:block">
              <SigninCarousel />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SignUp;
