import moment from "moment";
import React from "react";
import { capitalize } from "../../../utils/capitalize";
import { LaCaretDown, LaCaretUp, LaEditPencil } from "../../svg-icons";
import countReducer from "../../../utils/countReducer";
import { Link } from "react-router-dom";

type CompletedCardProps = {
  round?: string;
  teamA: TeamData;
  teamB: TeamData;
  venue: string;
  date: string | null;
  setClickedIndex: React.Dispatch<React.SetStateAction<any>>;
  isOpen: boolean;
  index: number;
  id: string;
  canEdit: boolean;
};

function CompletedCard({
  round,
  teamA,
  teamB,
  venue,
  date,
  index,
  setClickedIndex,
  isOpen,
  id,
  canEdit,
}: CompletedCardProps) {
  // const [collapsed, setCollapsed] = React.useState(true)
  const [clickedTarget, setClickedTarget] = React.useState<any>();
  const teamAYellowCard = teamA?.yellowCards?.length;
  const teamBYellowCard = teamB?.yellowCards?.length;
  const teamARedCard = teamA?.redCards?.length;
  const teamBRedCard = teamB?.redCards?.length;

  const teamAYellowCardPercent =
    (teamAYellowCard / (teamAYellowCard + teamBYellowCard)) * 100;
  const teamBYellowCardPercent =
    (teamBYellowCard / (teamBYellowCard + teamAYellowCard)) * 100;

  const teamARedCardPercent =
    (teamARedCard / (teamARedCard + teamBRedCard)) * 100;
  const teamBRedCardPercent =
    (teamBRedCard / (teamBRedCard + teamARedCard)) * 100;

  // convert the yellow card or red card user object array to key value pair with each object containing the user, and the number of yellow cards
  const teamAYellowCards = countReducer(teamA?.yellowCards);
  const teamBYellowCards = countReducer(teamB?.yellowCards);
  const teamARedCards = countReducer(teamA?.redCards);
  const teamBRedCards = countReducer(teamB?.redCards);

  function handleClick(e: React.MouseEvent) {
    const target = e.currentTarget as HTMLDivElement;
    const targetIndex = parseInt(target.dataset.index ?? "0", 10);
    if (clickedTarget === targetIndex && isOpen) {
      setClickedIndex(null);
      return;
    }
    setClickedTarget(targetIndex);
    setClickedIndex(targetIndex);
  }

  return (
    <div
      className="relative flex cursor-pointer flex-col items-center rounded border border-grey-light p-3 shadow"
      onClick={(e) => handleClick(e)}
      data-index={index}
      role="button"
      aria-label="dropdown"
    >
      <p className="text-xs font-medium">
        Match {round} <span>·</span> {moment(date).format("ll")}
      </p>
      <div className="flex w-full items-center lg:my-4 lg:pr-20">
        <span className="hidden text-2xl lg:mr-12 lg:block">
          {isOpen ? <LaCaretUp /> : <LaCaretDown />}
        </span>

        <div className="grid w-full grid-cols-3 items-center justify-between">
          <Link
            to={`/profiles/team/${teamA?.team?.name.replace(/\s/g, "-")}`}
            className="flex items-center gap-2 "
          >
            <img
              src={teamA?.team?.avatar}
              alt=""
              className="h-8 w-8 rounded-full"
            />
            <p className="text-center">{capitalize(teamA?.team?.name)}</p>
          </Link>

          <div className="flex items-center justify-center gap-6">
            <div
              className={`h-[8px] w-[8px] rounded-full ${
                teamA?.score > teamB?.score
                  ? "bg-green"
                  : teamA?.score === teamB?.score
                  ? "bg-primary-blue-darker"
                  : "bg-red"
              }`}
            />
            <div className="flex items-center justify-center gap-1 text-base font-medium">
              <p aria-label="team-a-score">{teamA?.score}</p>
              <p>:</p>
              <p aria-label="team-b-score">{teamB?.score}</p>
            </div>
            <div
              className={`h-2 w-2 rounded-full ${
                teamB?.score > teamA?.score
                  ? "bg-green"
                  : teamB?.score === teamA?.score
                  ? "bg-primary-blue-darker"
                  : "bg-red"
              }`}
            />
          </div>

          <Link
            to={`/profiles/team/${teamB?.team?.name.replace(/\s/g, "-")}`}
            className="flex items-center justify-end gap-2"
          >
            <p className="text-center">{capitalize(teamB?.team?.name)}</p>
            <img
              src={teamB?.team?.avatar}
              alt="team avatar"
              className="h-8 w-8 rounded-full"
            />
          </Link>
        </div>
      </div>

      <p className="text-center text-sm font-medium text-grey">{venue}</p>

      {isOpen && (
        <div className="mt-2 flex w-full flex-col items-center bg-grey-lighter p-4">
          <div className="w-full lg:max-w-md">
            <div className="flex flex-col items-center">
              <h3 className="border-b border-grey-light px-10 pb-2 text-sm font-medium">
                Match Summary
              </h3>
              <h3 className=" px-10 pt-2 text-sm font-medium text-grey">
                Goals
              </h3>
            </div>
            {/* Goals */}
            <div className="flex w-full justify-between text-xs lg:text-sm">
              <div className="flex flex-col gap-2">
                {teamA?.scorers?.map((scorer, i) => (
                  <Link
                    to={`/profiles/player/${scorer?.player?.name}`}
                    key={i}
                    className="flex items-center gap-2"
                  >
                    <p className="capitalize">{scorer?.player?.name}</p>
                    <p className="capitalize">{scorer?.goals}</p>
                  </Link>
                ))}
              </div>
              <div className="flex flex-col gap-2">
                {teamB?.scorers?.map((scorer, i) => (
                  <Link
                    to={`/profiles/player/${scorer?.player?.name}`}
                    key={i}
                    className="flex flex-row-reverse items-center gap-2"
                  >
                    <p className="capitalize">{scorer?.player?.name}</p>
                    <p className="capitalize">{scorer?.goals}</p>
                  </Link>
                ))}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <h3 className=" px-10 pt-2 text-sm font-medium text-grey">
                Assists
              </h3>
            </div>
            {/* Assists */}
            <div className="flex items-center justify-between text-xs lg:text-sm">
              <div className="flex flex-col gap-2">
                {teamA?.assisters?.map((assister, i) => (
                  <Link
                    to={`/profiles/player/${assister?.player?.name}`}
                    key={i}
                    className="flex items-center gap-2"
                  >
                    <p className="capitalize">{assister?.player?.name}</p>
                    <p className="capitalize">{assister?.assists}</p>
                  </Link>
                ))}
              </div>

              <div className="flex flex-col gap-2">
                {teamB?.assisters?.map((assister, i) => (
                  <Link
                    to={`/profiles/player/${assister?.player?.name}`}
                    key={i}
                    className="flex items-center gap-2"
                  >
                    <p className="capitalize">{assister?.player?.name}</p>
                    <p className="capitalize">{assister?.assists}</p>
                  </Link>
                ))}
              </div>
            </div>

            <div className="flex flex-col items-center">
              <h3 className=" px-10 pt-2 text-sm font-medium text-grey">
                Own Goals
              </h3>
            </div>
            {/* Assists */}
            <div className="flex items-center justify-between text-xs lg:text-sm">
              <div className="flex flex-col gap-2">
                {teamA?.ownGoals?.map((scorer, i) => (
                  <Link
                    to={`/profiles/player/${scorer?.player?.name}`}
                    key={i}
                    className="flex items-center gap-2"
                  >
                    <p className="capitalize">{scorer?.player?.name}</p>
                    <p className="capitalize">{scorer?.ownGoals}</p>
                  </Link>
                ))}
              </div>

              <div className="flex flex-col gap-2">
                {teamB?.ownGoals?.map((scorer, i) => (
                  <Link
                    to={`/profiles/player/${scorer?.player?.name}`}
                    key={i}
                    className="flex items-center gap-2"
                  >
                    <p className="capitalize">{scorer?.player?.name}</p>
                    <p className="capitalize">{scorer?.ownGoals}</p>
                  </Link>
                ))}
              </div>
            </div>
            {/* Yellow Cards */}

            <div className="mt-4 flex w-full flex-col gap-4">
              <aside className="">
                <p className="text-center text-sm font-medium text-gray-600">
                  Yellow card
                </p>
                <div className="mt-2 flex">
                  <div
                    className={`h-2  ${
                      teamAYellowCardPercent > teamBYellowCardPercent
                        ? "bg-yellow"
                        : "bg-gray-200"
                    } rounded-[999px_0_0_999px]`}
                    style={{
                      width: `${teamAYellowCardPercent}%`,
                    }}
                  />
                  <div
                    className={`h-2 ${
                      teamBYellowCardPercent >= teamAYellowCardPercent
                        ? "bg-yellow"
                        : "bg-gray-200"
                    } rounded-[0_999px_999px_0]`}
                    style={{
                      width: `${teamBYellowCardPercent}%`,
                    }}
                  />
                </div>
                <div className="mt-1 flex justify-between text-xs font-medium lg:text-sm">
                  <div className="flex flex-col gap-1">
                    {teamAYellowCards?.map((yellowCardObj, i) => (
                      <Link
                        to={`/profiles/player/${yellowCardObj?.player?.name}`}
                        className="flex items-center gap-2 capitalize"
                        key={i}
                      >
                        <span>{yellowCardObj?.player?.name}</span>
                        <span>{yellowCardObj?.cards}</span>
                      </Link>
                    ))}
                  </div>
                  <div className="flex flex-col gap-1">
                    {teamBYellowCards?.map((yellowCardObj, i) => (
                      <Link
                        to={`/profiles/player/${yellowCardObj?.player?.name}`}
                        className="flex flex-row-reverse items-center gap-2 capitalize"
                        key={i}
                      >
                        <span>{yellowCardObj?.player?.name}</span>
                        <span>{yellowCardObj?.cards}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              </aside>
              {/* Red Cards stats */}
              <aside className="w-full">
                <p className="my-1 text-center text-sm font-medium text-gray-600">
                  Red card
                </p>
                <div className="mt-2 flex">
                  <div
                    className={`h-2  ${
                      teamARedCardPercent >= teamBRedCardPercent
                        ? "bg-red"
                        : "bg-gray-200"
                    } rounded-[999px_0_0_999px]`}
                    style={{
                      width: `${teamARedCardPercent}%`,
                    }}
                  />
                  <div
                    className={`h-2 ${
                      teamBRedCardPercent > teamARedCardPercent
                        ? "bg-red"
                        : "bg-gray-200"
                    } rounded-[0_999px_999px_0]`}
                    style={{
                      width: `${teamBRedCardPercent}%`,
                    }}
                  />
                </div>
                <div className="mt-1 flex justify-between text-xs font-medium lg:text-sm">
                  <div className="flex flex-col gap-1">
                    {teamARedCards?.map((redCardObj, i) => (
                      <Link
                        to={`/profiles/player/${redCardObj?.player?.name}`}
                        className="flex items-center gap-2 capitalize"
                        key={i}
                      >
                        <span>{redCardObj?.player?.name}</span>
                        <span>{redCardObj?.cards}</span>
                      </Link>
                    ))}
                  </div>
                  <div className="flex flex-col gap-1">
                    {teamBRedCards?.map((redCardObj, i) => (
                      <Link
                        to={`/profiles/player/${redCardObj?.player?.name}`}
                        className="flex flex-row-reverse items-center gap-2 capitalize"
                        key={i}
                      >
                        <span>{redCardObj?.player?.name}</span>
                        <span>{redCardObj?.cards}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      )}
      {canEdit && (
        <Link
          to={`/competitions/scores/edit/${id}`}
          className="absolute right-4 top-2 flex items-center gap-2 rounded-full bg-grey-lighter px-2 py-1 text-xs font-medium lg:top-4 lg:text-sm"
        >
          <LaEditPencil /> Edit
        </Link>
      )}
    </div>
  );
}

export default CompletedCard;
