import { useQueryPlayerTeamsQuery } from "../../redux/services/playerService";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { TeamCard } from "../tournament";
import { TeamCardSkeleton } from "../ui/skeletons";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import React from "react";

type PlayerTeamsProps = {
  playerId?: string;
};

function PlayerTeams({ playerId }: PlayerTeamsProps) {
  const visitingPlayer = useAppSelector(
    (state: RootState) => state.auth.playerId
  );
  const { data, isLoading } = useQueryPlayerTeamsQuery({ playerId: playerId });
  const [selectedCompetition, setSelectedCompetition] =
    React.useState<string>("none");
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full grid-cols-3 flex-col items-center gap-4 px-4 pt-4 lg:grid lg:gap-x-6 lg:gap-y-10 lg:px-0">
          <TeamCardSkeleton />
          <TeamCardSkeleton />
          <TeamCardSkeleton />
        </div>
      ) : (
        <>
          <div className="flex w-full flex-col items-center px-4 pb-10 pt-4 lg:px-0 lg:pb-0">
            {!isLoading &&
              (data?.length ?? 0) < 1 &&
              playerId === visitingPlayer && (
                <>
                  <EmptyStateAuto
                    title="You do not have a team"
                    description="Discover or create a team to continue"
                  />
                </>
              )}

            {!isLoading &&
              (data?.length ?? 0) < 1 &&
              playerId !== visitingPlayer && (
                <>
                  <EmptyStateAuto
                    title="Nothing to see here"
                    description="This player does not have a team"
                  />
                </>
              )}

            {!isLoading && (data?.length ?? 0) > 0 && (
              <div className="flex w-full flex-col gap-4 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:gap-y-10">
                {data?.map((team) => {
                  return (
                    <TeamCard
                      key={`${team._id}`}
                      color={team?.color}
                      avatar={team?.avatar}
                      draws={team?.draws}
                      name={team?.name}
                      id={team._id}
                      players={team?.members?.length}
                      wins={team?.wins}
                      losses={team?.losses}
                      played={team.played}
                      setSelectedCompetition={setSelectedCompetition}
                      selectedTeams={selectedTeams}
                      setSelectedTeams={setSelectedTeams}
                      selectedCompetition={selectedCompetition}
                      statusText={" "}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default PlayerTeams;
