import TraineeBadge from "../TraineeBadge";
import { cn } from "../../utils/cn";
import { SessionStat, Trainee, TrainingSession } from "../../@types/training";
import starGold from "../../assets/icons/star-gold.svg";
import starSilver from "../../assets/icons/star-silver.svg";
import starBronze from "../../assets/icons/star-bronze.svg";

export default function TopThree({
  data,
}: {
  data: (SessionStat & { trainee: Trainee; Session: TrainingSession })[];
}) {
  // re arranging the positions
  const updatedData = [data[1], data[0], data[2]];

  const topThrees = updatedData.map((data, index) => {
    const stars = [starSilver, starGold, starBronze];
    const badgeStyles = ["silver__badge", "gold__badge", "bronze__badge"];
    const ranks = [2, 1, 3];
    const star = stars[index];
    const badgeStyle = badgeStyles[index];
    const rank = ranks[index];
    // console.log(data)
    return (
      <div
        key={data?.id ?? index}
        className={cn("z-40", {
          "scale-150 lg:scale-125": index === 1,
        })}
      >
        <TraineeBadge
          games={data?.session}
          mvp={data?.mMatch}
          playerName={data?.trainee?.name}
          goals={data?.goals}
          assists={data?.assists}
          playerWing="N/A"
          rank={rank}
          avatar={
            data?.trainee?.name == "Laju"
              ? "https://res.cloudinary.com/dzdezmcu0/image/upload/v1722435218/image_t2lokc.jpg"
              : data?.trainee?.name === "Aye"
              ? "https://res.cloudinary.com/dzdezmcu0/image/upload/v1718271288/WhatsApp_Image_2024-06-13_at_10.28.52_4399b2ab_mmxo1u.jpg"
              : "https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076677/Frame_6180_qxstfl.png"
          }
          star={star}
          badgeStyle={badgeStyle}
        />
      </div>
    );
  });

  return (
    <div
      className="flex items-center justify-center gap-6 rounded-3xl bg-[url('/assets/arena.webp')] bg-cover bg-no-repeat py-16"
      // style={{
      //   backgroundImage: `url(${arena.src})`,
      // }}
    >
      {topThrees}
    </div>
  );
}
