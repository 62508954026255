import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkedItems: {},
};

const checkboxSlice = createSlice({
  name: "checkbox",
  initialState,
  reducers: {
    /* == checked items is set to an object with
    key (id) value pairs of checked or unchecked boolean values == */
    setCheckedItems(state, action) {
      state.checkedItems = action.payload;
    },
  },
});

export const { setCheckedItems } = checkboxSlice.actions;
export default checkboxSlice.reducer;
