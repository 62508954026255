import type { ReactElement } from "react";
import { Search as SearchIcon } from "../../svg-icons";
import React from "react";

export interface SearchBarProps {
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar({
  setSearchText,
}: SearchBarProps): ReactElement {
  return (
    <section className="relative flex w-full flex-col justify-center">
      <div className="absolute left-4">
        <SearchIcon />
      </div>
      <input
        type="text"
        name="search"
        aria-label="search"
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search for Players, Competitions or Team"
        className="h-full flex-1 rounded border border-primary-black-darker px-4 py-2 pl-12 outline-none focus:border-2 focus:border-primary-black-darker focus:border-opacity-50"
      />
    </section>
  );
}
