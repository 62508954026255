import { leaguesArenaApi } from "./leaguesArenaApiSlice";

type TSearchQuery = TPlayer[] | Team[] | Tournament[] | undefined;

const searchService = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<TSearchQuery, { kwd: string; seg: string }>({
      query: ({ kwd, seg }) => ({
        url: `search?seg=${seg}&kwd=${kwd}`,
      }),
      transformResponse: (response: { data: TSearchQuery }) =>
        response.data && response.data,
    }),
  }),
});

export const { useSearchQuery } = searchService;
