import * as React from "react";

function LaNumberTwo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 38" fill="none" {...props}>
      <path
        d="M25.193 33.926H4.834v-4.04l9.609-10.24c1.319-1.441 2.291-2.7 2.917-3.774.639-1.074.958-2.094.958-3.06 0-1.319-.333-2.352-1-3.1-.666-.762-1.618-1.143-2.855-1.143-1.333 0-2.387.463-3.162 1.388-.762.91-1.142 2.114-1.142 3.61H4.243c0-1.809.428-3.46 1.285-4.957.87-1.496 2.094-2.665 3.672-3.508 1.577-.857 3.366-1.286 5.365-1.286 3.06 0 5.433.735 7.12 2.204 1.7 1.468 2.55 3.542 2.55 6.221 0 1.47-.382 2.965-1.143 4.488-.762 1.523-2.067 3.298-3.917 5.324l-6.752 7.12h12.77v4.753z"
        fill="#D9EAEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.674 33.927a3.481 3.481 0 01-3.481 3.481H4.834a3.481 3.481 0 01-3.481-3.481v-4.04c0-.884.337-1.736.942-2.381l9.593-10.225c1.227-1.342 2.014-2.388 2.463-3.16l.017-.028c.41-.69.469-1.1.469-1.28 0-.372-.048-.59-.082-.696a.78.78 0 00-.016-.046 1.657 1.657 0 00-.276-.02c-.26 0-.37.045-.391.054a.153.153 0 00-.034.02.44.44 0 00-.067.067c-.114.138-.33.483-.33 1.376a3.481 3.481 0 01-3.482 3.482H4.243A3.481 3.481 0 01.76 13.568c0-2.384.572-4.638 1.746-6.687l.012-.02c1.204-2.07 2.907-3.687 5.03-4.823C9.689.878 12.056.336 14.565.336c3.587 0 6.88.863 9.4 3.055 2.61 2.258 3.75 5.377 3.75 8.851 0 2.09-.544 4.116-1.51 6.045-.965 1.93-2.507 3.977-4.458 6.114l-.046.05-1.178 1.241h4.67a3.481 3.481 0 013.481 3.482v4.753zm-16.251-4.753l6.752-7.12c1.85-2.026 3.155-3.8 3.917-5.324.761-1.523 1.142-3.019 1.142-4.488 0-2.679-.85-4.753-2.55-6.221-1.686-1.47-4.06-2.204-7.12-2.204-1.998 0-3.787.429-5.364 1.286-1.578.843-2.802 2.012-3.672 3.508a9.618 9.618 0 00-.688 1.476 10.047 10.047 0 00-.597 3.481h5.916c0-1.496.38-2.7 1.142-3.61.775-.925 1.83-1.388 3.162-1.388 1.237 0 2.19.381 2.856 1.143.666.748 1 1.781 1 3.1 0 .966-.32 1.986-.96 3.06-.625 1.075-1.597 2.333-2.917 3.774l-9.608 10.24v4.04h20.359v-4.753h-12.77z"
        fill="#81A5B0"
      />
    </svg>
  );
}

export default LaNumberTwo;
