import * as React from "react";

function LaThrdPlace(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={39} height={38} fill="none" {...props}>
      <circle
        cx={19.5}
        cy={19}
        r={19}
        fill="url(#prefix__paint0_linear_8977_39860)"
      />
      <path
        d="M20.395 11.561l1.997 4.003a.99.99 0 00.76.547l4.486.642c.824.125 1.156 1.126.554 1.689l-3.248 3.111a.969.969 0 00-.286.876l.761 4.394c.143.813-.729 1.423-1.458 1.048l-3.993-2.064a.99.99 0 00-.936 0l-4.009 2.08c-.745.375-1.6-.235-1.458-1.048l.76-4.394a.943.943 0 00-.284-.876l-3.233-3.112a.983.983 0 01.554-1.689l4.485-.64a.99.99 0 00.76-.548l1.998-4.003c.348-.767 1.426-.767 1.79-.016z"
        fill="url(#prefix__paint1_linear_8977_39860)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_8977_39860"
          x1={19.5}
          y1={0}
          x2={19.5}
          y2={38}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#616184" />
          <stop offset={0.484} stopColor="#403C55" />
          <stop offset={1} stopColor="#40303A" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_8977_39860"
          x1={19.492}
          y1={11.166}
          x2={19.492}
          y2={25.959}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.082} stopColor="#EDB966" />
          <stop offset={0.226} stopColor="#DDDCD7" />
          <stop offset={0.388} stopColor="#E9B87D" />
          <stop offset={0.59} stopColor="#853A27" />
          <stop offset={1} stopColor="#D3954C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LaThrdPlace;
