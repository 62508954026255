import * as React from "react";

function LaGreenGood(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={17} height={16} fill="none" {...props}>
      <path
        d="M13.712 1.24c.58-.653 1.522-.653 2.103 0 .573.645.58 1.687.02 2.342L7.924 14.714a.544.544 0 01-.036.046c-.581.653-1.522.653-2.103 0L.54 8.856c-.58-.653-.58-1.712 0-2.366.58-.653 1.522-.653 2.102 0l4.15 4.67 6.882-9.87a.555.555 0 01.04-.05z"
        fill={props.fill || "#3C8505"}
      />
    </svg>
  );
}

export default LaGreenGood;
