import React from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { capitalize } from "../../utils/capitalize";
import PlayerInfo from "../../components/user-profiles/PlayerInfo";
import { PreLoader } from "../../components/ui/spinner";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { setToastCopied } from "../../redux/features/modal/displayModalSlice";
import { useGetPlayerQuery } from "../../redux/services/playerService";
import Button from "../../components/ui/buttons/Button";
import { cn } from "../../utils/cn";
import { AddPlayersToTeam, TeamCompetitionsForm } from "../../components/team";
import { LaArrowLeft } from "../../components/svg-icons";
import HelmetComponent from "../../components/HelmetComponent";
import PlayerProfileBadge from "../../components/user-profiles/PlayerProfileBadge";
import { calculateAgeFromDob } from "../../utils/dob";
import PlayerTeams from "../../components/user-profiles/PlayerTeams";
import PreviewSpotLightModal from "../../components/modals/PreviewSpotLightModal";
import SpotlightGrid from "../../components/user-profiles/SpotlightGrid";

type TPlayerTabs = "info" | "teams" | "posts";

function PlayerProfile() {
  const ID_LENGTH = 24;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { name } = useParams();
  const { data: player, isLoading } = useGetPlayerQuery({ name });
  const [tabParams] = useSearchParams();
  const activeTab = (tabParams.get("tab") || "info") as TPlayerTabs;
  const viewerId = useAppSelector((state: RootState) => state.auth.myId);
  const [selectedPlayers, setSelectedPlayers] = React.useState<string[]>([]);
  const [selectedCompetitionForTeamId, setSelectedCompetitionForTeamId] =
    React.useState<string>("none");

  const [competitionPlayersForTeam, setCompetitionPlayersForTeam] =
    React.useState<string[]>([]);
  const [selectedCompetitionForTeam, setSelectedCompetitionForTeam] =
    React.useState<SelectedCompetition | null>(null);

  const role = useAppSelector((state: RootState) => state.auth.role);
  const isTeamMg = role === "team";
  const isPrivate = viewerId === player?.user && role === "player";

  const hasPendingReq =
    Boolean(player?.pendingRequests?.find((oId) => oId === viewerId)) &&
    role === "team";
  const hasPendingInvites = Boolean(
    player?.pendingInvites?.find((oid) => oid === viewerId)
  );

  const isInTournament =
    player?._id && competitionPlayersForTeam?.includes(player._id);

  const cantInvite = hasPendingInvites || hasPendingReq || isInTournament;

  const avatar = player?.avatar;

  const copyToClipboard = useCopyToClipboard()[1];

  // open graph data
  const ogData = {
    title: "Leagues Arena - Player Profile",
    description: `View ${player?.name} profile on Leagues Arena`,
    url: `https://${window.location.host}/profiles/player/${name}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  function shareLink(link: string) {
    copyToClipboard(link).then((hasCopied) => {
      dispatch(setToastCopied(hasCopied));
    });
  }

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {!isLoading ? (
        <div className="flex flex-col items-center justify-center">
          <div className="flex w-full flex-col items-center pb-24 lg:max-w-[87%]">
            <div className="flex w-full items-center gap-4 px-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
              <button
                className="flex items-center gap-2 text-xl"
                onClick={() => navigate(-1)}
              >
                <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
              </button>
              <h3 className="text-xl font-bold">Player Profile</h3>
            </div>

            <div className="flex w-full justify-between">
              <div className="flex w-full flex-col items-center justify-center lg:mt-10 lg:w-auto lg:flex-row lg:justify-start lg:gap-6">
                <h3 className="mb-6 mt-4 text-xl font-bold capitalize lg:hidden">
                  {capitalize(player?.name)}
                </h3>
                {/* badge */}
                <div className="min-w-fit">
                  <PlayerProfileBadge
                    avatar={avatar}
                    playerName={player?.name}
                    appearance={player?.apps}
                    assist={player?.assists}
                    goals={player?.goals}
                    playerWing={player?.position?.split(" ")[0]}
                    favFoot={player?.favFoot}
                    age={calculateAgeFromDob(player?.dob)}
                    height={player?.height}
                    jerseyNumb={player?.jerseyNumb}
                    weight={player?.weight}
                  />
                </div>
                <div className="hidden max-w-lg flex-col gap-4 lg:flex">
                  <h3 className="text-2xl font-medium capitalize">
                    {player?.name}
                  </h3>
                  <div>
                    <h3 className="font-medium">Bio</h3>
                    <p>{player?.bio}</p>
                  </div>
                  <div className="flex min-w-max rounded border border-grey-light bg-grey-lighter px-2 py-4 text-sm">
                    <div className="flex flex-col items-center gap-2 border-r border-grey-light px-6">
                      <p>{player?.apps}</p>
                      <p className="text-grey">Appearances</p>
                    </div>
                    <div className="flex flex-col items-center gap-2 border-r border-grey-light px-6">
                      <p>0</p>
                      <p className="text-grey">MVP</p>
                    </div>
                    <div className="flex flex-col items-center gap-2 border-r border-grey-light px-6">
                      <p>{player?.goals}</p>
                      <p className="text-grey">Goals</p>
                    </div>
                    <div className="flex flex-col items-center gap-2 border-r border-grey-light px-6">
                      <p>{player?.assists}</p>
                      <p className="text-grey">Assists</p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 border-r border-grey-light px-6">
                      <p>{player?.yellowCards}</p>
                      <p className="text-center text-grey"> Yellow cards</p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2 px-6">
                      <p>{player?.redCards}</p>
                      <p className="text-center text-grey">Red cards</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="absolute right-0 top-0 hidden min-w-max items-center gap-4 lg:flex">
                  {isPrivate && (
                    <Button
                      role={role}
                      intent={"primary"}
                      type="button"
                      onClick={() => navigate("/profiles/player/edit")}
                    >
                      Edit Profile
                    </Button>
                  )}

                  {isTeamMg && !cantInvite && (
                    <Button
                      intent={"primary"}
                      role={role}
                      type="button"
                      disabled={selectedPlayers.includes(player?._id as string)}
                      onClick={() => {
                        setSelectedPlayers((prev) => [
                          ...prev,
                          player?._id as string,
                        ]);

                        selectedCompetitionForTeamId.length < ID_LENGTH &&
                          setSelectedCompetitionForTeamId("");
                      }}
                    >
                      Add player
                    </Button>
                  )}
                  {isInTournament && (
                    <Button
                      role={role}
                      intent={"soft"}
                      className="cursor-pointer rounded bg-green p-3 text-sm font-medium text-white"
                      disabled
                    >
                      Teammate
                    </Button>
                  )}

                  <Button
                    role={role}
                    intent={"secondary"}
                    className="cursor-pointer rounded p-3 text-sm font-medium"
                    onClick={() =>
                      shareLink(
                        `${window.location.host}${window.location.pathname}`
                      )
                    }
                  >
                    Share
                  </Button>
                </div>
              </div>
            </div>

            {/* Mobile-only view */}
            <div className="mt-4 flex items-center gap-4 lg:hidden">
              {isPrivate && (
                <Button
                  role={role}
                  intent={"primary"}
                  type="button"
                  onClick={() => navigate("/profiles/player/edit")}
                >
                  Edit Profile
                </Button>
              )}

              <Button
                role={role}
                intent={"secondary"}
                onClick={() =>
                  shareLink(
                    `${window.location.host}${window.location.pathname}`
                  )
                }
              >
                Share
              </Button>

              {isInTournament && (
                <Button
                  role={role}
                  intent={"soft"}
                  className="cursor-pointer rounded bg-green p-3 text-sm font-medium text-white"
                  disabled
                >
                  Teammate
                </Button>
              )}
            </div>
            {/* End mobile-only view */}

            {/* tab */}
            <div className="mt-4 grid w-full grid-cols-3 border-b border-grey-light text-center lg:flex lg:gap-6">
              <Button
                intent="secondary"
                role={role}
                className={cn(
                  "rounded-none border-0 py-4 capitalize hover:bg-white",
                  {
                    "text-primary-black-darker": activeTab !== "info",
                    "border-b-2": activeTab === "info",
                  }
                )}
                type="button"
                onClick={() => navigate(`/profiles/player/${name}?tab=info`)}
              >
                {isPrivate && "My "}
                Info
              </Button>
              <Button
                intent="secondary"
                role={role}
                className={cn(
                  "rounded-none border-0 py-4 capitalize hover:bg-white",
                  {
                    "text-primary-black-darker": activeTab !== "teams",
                    "border-b-2": activeTab === "teams",
                  }
                )}
                type="button"
                onClick={() => navigate(`/profiles/player/${name}?tab=teams`)}
              >
                Teams
              </Button>

              <Button
                intent="secondary"
                role={role}
                className={cn(
                  "rounded-none border-0 py-4 capitalize hover:bg-white",
                  {
                    "text-primary-black-darker": activeTab !== "posts",
                    "border-b-2": activeTab === "posts",
                  }
                )}
                type="button"
                onClick={() => navigate(`/profiles/player/${name}?tab=posts`)}
              >
                Posts
              </Button>
            </div>

            {/* active tabs */}

            {activeTab === "teams" && <PlayerTeams playerId={player?._id} />}
            {activeTab === "info" && (
              <PlayerInfo
                bio={player?.bio}
                location={player?.location}
                age={calculateAgeFromDob(player?.dob)}
              />
            )}
            {activeTab === "posts" && player?.user && (
              <SpotlightGrid
                profile="player"
                isPrivate={isPrivate}
                userId={player?.user as string}
              />
            )}
          </div>
        </div>
      ) : (
        <PreLoader />
      )}
      {!selectedCompetitionForTeamId && (
        <TeamCompetitionsForm
          setSelectedCompetitionForTeamId={setSelectedCompetitionForTeamId}
          setSelectedPlayers={setSelectedPlayers}
          setCompetitionPlayersForTeam={setCompetitionPlayersForTeam}
          selectedPlayers={selectedPlayers}
          setSelectedCompetitionForTeam={setSelectedCompetitionForTeam}
        />
      )}

      {selectedPlayers.length > 0 &&
        selectedCompetitionForTeamId.length === ID_LENGTH && (
          <AddPlayersToTeam
            setSelectedPlayers={setSelectedPlayers}
            selectedPlayers={selectedPlayers}
            selectedCompetitionForTeamId={selectedCompetitionForTeamId}
            setCompetitionPlayersForTeam={setCompetitionPlayersForTeam}
            setSelectedCompetitionForTeamId={setSelectedCompetitionForTeamId}
            selectedCompetitionForTeam={selectedCompetitionForTeam}
          />
        )}

      {/* {modalToDisplay === "spotLight" && <PreviewSpotLightModal />} */}
    </>
  );
}
export default PlayerProfile;
