function LaShare({ stroke = "#1a1a1a" }: { stroke?: string; }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
      <g
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M18 8a3 3 0 100-6 3 3 0 000 6zM6 15a3 3 0 100-6 3 3 0 000 6zM18 22a3 3 0 100-6 3 3 0 000 6zM8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98" />
      </g>
    </svg>
  );
}

export default LaShare;
