import * as React from "react";

function LaDocumentDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} fill="none" {...props}>
      <g
        stroke="#1A1A1A"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 7.334v4l1.333-1.333M6 11.333L4.667 10" />
        <path d="M14.667 6.667v3.334c0 3.333-1.334 4.666-4.667 4.666H6c-3.333 0-4.667-1.333-4.667-4.666V6c0-3.334 1.334-4.667 4.667-4.667h3.333" />
        <path d="M14.667 6.667H12c-2 0-2.667-.666-2.667-2.666V1.334l5.334 5.333z" />
      </g>
    </svg>
  );
}

export default LaDocumentDownload;
