import React from "react";
import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { CiHeart } from "react-icons/ci";
import { IoChatbubbleOutline } from "react-icons/io5";
import { SlShareAlt } from "react-icons/sl";
import useEmblaCarousel from "embla-carousel-react";
import { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import { cn } from "../../../utils/cn";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import ReactPlayer from "react-player";
import { getMediaType } from "../../../utils/getMediaType";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import { setToastCopied } from "../../../redux/features/modal/displayModalSlice";
import { useReactToSpotlightMutation } from "../../../redux/services/spotLightService";
import { RootState } from "../../../mocks/store";
import LaHeart from "../../svg-icons/LaHeart";
import postShare from "../../../assets/icons/postshare.svg";
import comment from "../../../assets/icons/comment.svg";

export function PostMediaCard({
  redirect = false,
  ...props
}: React.PropsWithChildren<{
  type: "image" | "video";
  redirect?: boolean;
  data: SpotLight;
}>) {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const dispatch = useAppDispatch();
  const copyToClipboard = useCopyToClipboard()[1];
  const [reactToSpotlight, { isLoading: isReacting }] =
    useReactToSpotlightMutation();

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  function shareLink(link: string) {
    copyToClipboard(link).then((hasCopied) => {
      dispatch(setToastCopied(hasCopied));
    });
  }

  const ratio = props.type === "image" ? 4 / 5 : 16 / 9;

  return (
    <AspectRatio.Root
      ratio={ratio}
      ref={emblaRef}
      className="relative z-0 w-full justify-self-center overflow-hidden rounded-2xl bg-black"
    >
      <div className="flex h-full w-full gap-2">
        {/* {props.data.urls.slice(0, 2).map((media, idx) => {
          const mediaType = getMediaType(media);
          if (mediaType === "video") {
            return <ReactPlayer url={media} key={media + idx} controls />;
          }
          return (
            <>
              <img
                key={media + idx}
                alt={media}
                className="h-full w-[100%] object-cover object-center"
                src={media}
              />
            </>
          );
        })} */}
        {props.data.urls.map((media, idx) => {
          const mediaType = getMediaType(media);
          if (mediaType === "video") {
            return <ReactPlayer url={media} key={media + idx} controls />;
          }
          return (
            <>
              <img
                key={media + idx}
                alt={media}
                className="h-full w-full object-cover object-center"
                src={media}
              />
            </>
          );
        })}
      </div>

      <aside className="absolute bottom-0 right-0 m-4 flex flex-col gap-1">
        <button
          disabled={isReacting}
          className="flex flex-col items-center justify-center gap-[2px] rounded-[20.22px] bg-[#5F5F5FB2]/70 p-[7px]"
          onClick={() => {
            reactToSpotlight({ id: props.data?._id })
              .unwrap()
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          <LaHeart fillPath="red" strokeFill="red" />
          {/* <CiHeart size={29} className="text-white" /> */}
          <p className="text-[12px] font-semibold text-white">
            {props.data.likes?.length}
          </p>
        </button>

        <Link
          to={`/profiles/${role}/posts/${props.data._id}`}
          className="flex flex-col items-center justify-center gap-[2px] rounded-[20.22px] bg-[#5F5F5FB2]/70 p-[7px]"
        >
          <img src={comment} />
          <p className="text-[12px] font-semibold text-white">
            {props.data.comments}
          </p>
        </Link>

        <button
          onClick={() =>
            shareLink(
              `${window.location.host}/profiles/${role}/posts/${props.data._id}`
            )
          }
          className="flex flex-col items-center justify-center gap-[2px] rounded-[20.22px] bg-[#5F5F5FB2]/70 p-[7px]"
        >
          <img src={postShare} />
          <p className="text-[12px] font-semibold text-white">
            {props.data.shares}
          </p>
        </button>
      </aside>

      <p className="absolute bottom-4 left-4 line-clamp-1 max-w-[80%] text-white">
        {props.data?.description}
      </p>

      {redirect && (
        <Link
          to={`/profiles/${role}/posts/${props.data._id}`}
          className="absolute right-4 top-4"
        >
          <FiExternalLink size={20} color="white" />
        </Link>
      )}

      {/* Counter */}
      {/* <span className="absolute left-3 top-3 grid aspect-square h-8 place-items-center rounded-full border border-white leading-none text-white">
        3
      </span> */}

      {/* Top Slides */}
      <div className="absolute left-1/2 top-3 flex -translate-x-1/2 items-center justify-center gap-3">
        {scrollSnaps.map((_, idx) => (
          <button
            onClick={() => onDotButtonClick(idx)}
            className={cn(
              "h-1 w-12 max-w-[43px] flex-1 rounded-full bg-white opacity-50",
              idx === selectedIndex && "opacity-100"
            )}
            key={idx}
          />
        ))}
      </div>

      {/* Bottom slides */}
      <div className="absolute bottom-1 left-1/2 flex -translate-x-1/2 items-center justify-center gap-3">
        {scrollSnaps.map((_, idx) => (
          <button
            onClick={() => onDotButtonClick(idx)}
            className={cn(
              "h-[6px] w-[6px] rounded-full bg-white opacity-50",
              selectedIndex === idx && "opacity-100"
            )}
            key={idx}
          />
        ))}
      </div>
    </AspectRatio.Root>
  );
}

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};
const useDotButton = (
  emblaApi: EmblaCarouselType | undefined
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

  const onDotButtonClick = React.useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = React.useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = React.useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  React.useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

export function MediaCardSkeleton() {
  return (
    <AspectRatio.Root
      ratio={1 / 1}
      className="relative z-0 w-full animate-pulse justify-self-center overflow-hidden bg-black lg:rounded-2xl"
    ></AspectRatio.Root>
  );
}
