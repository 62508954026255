import React from "react";
import { Toggle, ToggleButton } from "../toggle/Toggle";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";

type ToggleBtnProps = {
  state?: boolean;
  name?: string;
  onChange?: (e: { target: { name: string; value: boolean } }) => void;
  handlePosts?: (value: boolean) => void;
  handleSchedules?: (value: boolean) => void;
};

/**
 * @description Accepts a boolean state value and an optional function from parent.
 * Updates display state based on passed state value.
 * Exec function if present.
 * @note function availability isn't checked
 * @params {
 *   state: boolean
 *   name: string (name of the input field to also be used as the id)
 * }
 * @returns null
 */
function ToggleBtn(props: ToggleBtnProps) {
  const [toggled, setToggled] = React.useState(props?.state || false);
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase();

  function handleClick() {
    setToggled(!toggled);
    props.onChange &&
      props?.onChange({
        target: { name: props?.name ?? "", value: !toggled },
      });
    props.handlePosts && props.handlePosts(!toggled);
    props.handleSchedules && props.handleSchedules(!toggled);
  }

  return (
    <>
      {/* <input
        type="checkbox"
        name={props.name}
        id={props.name}
        defaultChecked={toggled}
        onChange={(e) => handleChange(e)}
        hidden
      /> */}
      <ToggleButton
        onClick={handleClick}
        type="button"
        $toggled={toggled}
        $userRole={role}
      >
        {toggled ? (
          <span className="ml-1 mr-2 text-lg font-light uppercase text-black">
            ON
          </span>
        ) : (
          <span className="ml-2 mr-1 text-lg font-light uppercase text-black">
            OFF
          </span>
        )}
        <Toggle $toggled={toggled} $userRole={role} />
      </ToggleButton>
    </>
  );
}

export default ToggleBtn;
