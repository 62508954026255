import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const teamApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeam: builder.query<Team | undefined, { id?: string }>({
      query: ({ id }: { id?: string }) => ({
        method: "GET",
        url: `/teams/team/${id}`,
      }),
      transformResponse: (response: { data: Team }) => response.data,
      providesTags: ["Team"],
    }),

    getTeamByName: builder.query<Team | undefined, { name?: string }>({
      query: ({ name }: { name?: string }) => ({
        method: "GET",
        url: `/teams/name/${name}`,
      }),
      transformResponse: (response: { data: Team }) => response.data,
      providesTags: ["Team"],
    }),

    getTeams: builder.query<
      Team[] | undefined,
      {
        role?: "player" | "organizer";
        count?: number;
        page?: number;
        order?: "desc";
      }
    >({
      query: (args) => ({
        method: "GET",
        url: "/teams/",
        params: {
          role: args.role,
          count: args.count,
          page: args.page,
          order: args.order,
        },
      }),
      transformResponse: (response: { data: Team[] }) => response.data,
      providesTags: ["Team"],
    }),
    getUserTeams: builder.query<Team[] | undefined, { id: string | undefined }>(
      {
        query: ({ id }) => ({
          method: "GET",
          url: `/teams/user/${id}`,
        }),
        transformResponse: (response: { data: Team[] }) => response.data,
        providesTags: ["Team"],
      }
    ),
    getMyTeam: builder.query<Team | undefined, void>({
      query: () => ({
        method: "GET",
        url: `/teams/me`,
      }),
      transformResponse: (response: { data: Team }) => response.data,
      providesTags: ["Team"],
    }),

    updateTeam: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: "/teams/",
        body,
      }),
      invalidatesTags: ["Team", "User"],
    }),
    acceptRequests: builder.mutation({
      query: (body: { userId?: string }) => ({
        method: "PUT",
        url: "/teams/requests/accept",
        body,
      }),
      invalidatesTags: ["Team"],
    }),
    rejectRequests: builder.mutation({
      query: (body: { userId?: string }) => ({
        method: "PUT",
        url: "/teams/requests/decline",
        body,
      }),
      invalidatesTags: ["Team"],
    }),
    ejectPlayer: builder.mutation({
      query: (body: { victimId: string }) => ({
        method: "DELETE",
        url: "/teams/member",
        body,
      }),
      invalidatesTags: ["Team", "User"],
    }),
    searchTeams: builder.query<
      Team[] | undefined,
      {
        role?: "player" | "organizer";
        count?: number;
        page?: number;
        order?: "desc";
      }
    >({
      query: (args) => {
        return {
          method: "GET",
          url: `/teams/`,
          params: {
            ...args,
            sort: "-wins",
          },
        };
      },
      providesTags: ["Team"],
      transformResponse: (response: { data: Team[] }) => response.data,
    }),
    nameExist: builder.query({
      query: ({ name }) => ({
        method: "GET",
        url: `/teams/${name}`,
      }),
    }),
    createTeam: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/teams/",
        body,
      }),
      invalidatesTags: ["Team", "User", "Tournament"],
    }),
    requestToJoin: builder.mutation({
      query: (body: { teamId?: string }) => ({
        method: "PUT",
        url: "/teams/requests",
        body,
      }),
      invalidatesTags: ["Team", "User"],
    }),
    inviteToTeam: builder.mutation({
      query: ({ userId }: { userId?: string }) => ({
        method: "PUT",
        url: "/teams/invites",
        body: { userId },
      }),
      invalidatesTags: ["Team", "Player", "User"],
    }),

    getAuthorsTeam: builder.query<Team | undefined, { id?: string }>({
      query: ({ id }) => ({
        url: `/teams/author/${id}`,
        method: "GET",
      }),
      transformResponse: (response: { data: Team }) => response.data,
      providesTags: ["Team"],
    }),

    acceptInvites: builder.mutation({
      query: (body: { teamId?: string }) => ({
        method: "PUT",
        url: "/teams/invites/accept",
        body,
      }),
      invalidatesTags: ["Team"],
    }),

    rejectInvites: builder.mutation({
      query: (body: { teamId?: string }) => ({
        method: "PUT",
        url: "/teams/invites/decline",
        body,
      }),
      invalidatesTags: ["Team"],
    }),
    processPlayerRequest: builder.mutation({
      query: (pload: {
        body: { userId: string };
        param: "accept" | "decline";
      }) => ({
        method: "PUT",
        url: `/teams/request/${pload.param}`,
        body: pload.body,
      }),
      invalidatesTags: ["User", "Player"],
    }),
    createTeams: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/teams/batch",
        body,
      }),
      invalidatesTags: ["Team", "User"],
    }),
    addPlayers: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/teams//add-players",
        body,
      }),
      invalidatesTags: ["Team", "User", "Tournament"],
    }),
    fetchTeamPlayersInTournament: builder.query({
      query: ({ teamId, tournamentId }) => ({
        url: `/teams/player-in-team/${teamId}?tournamentId=${tournamentId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),
    transferPlayer: builder.mutation({
      query: (body) => ({
        url: `/teams/transfer-player`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Team", "Tournament"],
    }),
  }),
});

export const {
  useGetTeamQuery,
  useUpdateTeamMutation,
  useAcceptRequestsMutation,
  useRejectRequestsMutation,
  useEjectPlayerMutation,
  useSearchTeamsQuery,
  useGetUserTeamsQuery,
  useNameExistQuery,
  useCreateTeamMutation,
  useRequestToJoinMutation,
  useInviteToTeamMutation,
  useGetAuthorsTeamQuery,
  useAcceptInvitesMutation,
  useRejectInvitesMutation,
  useGetTeamsQuery,
  useGetTeamByNameQuery,
  useProcessPlayerRequestMutation,
  useGetMyTeamQuery,
  useCreateTeamsMutation,
  useAddPlayersMutation,
  useFetchTeamPlayersInTournamentQuery,
  useTransferPlayerMutation,
} = teamApi;
