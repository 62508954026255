import { LaArrowRight, LaLocation, LaPlayers } from "../../svg-icons";
import { Link } from "react-router-dom";
import { capitalize } from "../../../utils/capitalize";

type Props = {
  avatar: string;
  name: string;
  location: string;
  teams: number;
  id: string;
  banner: string;
};

function TournamentCard({ avatar, name, location, teams, id, banner }: Props) {
  return (
    <Link to={`/competitions/${id}`} className="relative w-full cursor-pointer">
      <img
        src={banner}
        alt="tournament banner"
        crossOrigin="anonymous"
        className="h-[246px] w-full rounded-lg"
      />
      <div className="absolute bottom-0 flex w-full flex-col justify-between gap-2 rounded-[0_0_8px_8px] border border-grey-light bg-white px-6 py-3 shadow">
        <img
          src={avatar}
          alt="tournament avatar"
          crossOrigin="anonymous"
          className="absolute -top-4 h-8 w-8 rounded-full border border-white"
        />
        <p className="text-base">{capitalize(name)}</p>
        <p className="flex items-center gap-1 text-xs font-medium">
          <span className="text-lg">
            <LaLocation />
          </span>
          {location}
        </p>
        <p className="flex items-center gap-1 text-xs font-medium">
          <span className="text-lg">
            <LaPlayers />
          </span>
          {teams < 2 ? `${teams} team` : `${teams} teams`}
        </p>

        <p className="absolute bottom-4 right-4 flex items-center gap-2 text-xs">
          View <LaArrowRight />
        </p>

        {/*<button type="button" className="absolute right-4" data-target="parent">
          <LaMoreVertical />
        </button>*/}
      </div>
    </Link>
  );
}

export default TournamentCard;
