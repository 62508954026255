import { LaLocation, LaPlayers } from "../../svg-icons";

export default function CompetitionCardSkeleton() {
  return (
    <div className="relative h-[250px] overflow-hidden rounded-lg border border-gray-300 lg:max-w-[358px]">
      <section className="relative h-[60%] bg-gray-200 bg-cover bg-center bg-no-repeat">
        <div className="absolute -bottom-4 left-6 h-8 w-8 animate-pulse overflow-hidden rounded-full border border-white bg-gray-400 delay-100" />
      </section>
      <section className="flex h-[40%] animate-pulse flex-col justify-end gap-2 px-6 py-3">
        <div className="h-4 w-full rounded-md bg-black/70" />
        <div className="flex items-center gap-2 text-sm capitalize">
          <LaLocation />
          <div className="h-3 w-2/3 rounded-md bg-black/40" />
        </div>
        <div className="flex items-center gap-2">
          <LaPlayers /> <div className="h-3 w-1/2 rounded-md bg-black/40" />
        </div>
      </section>
    </div>
  );
}
