import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/app/store";
import { useAppSelector } from "../../redux/app/hooks";
import Button from "../ui/buttons/Button";

interface Props {}

export default function PrivateBtnGroup({}: Props) {
  const navigate = useNavigate();
  const role = useAppSelector((state: RootState) => state.auth.role);

  return (
    <>
      <Button
        role={role}
        intent="primary"
        type="button"
        onClick={() => navigate("/profiles/team/edit")}
      >
        Edit team
      </Button>
    </>
  );
}
