import React from "react";

function LaClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        stroke={props.stroke || "#1a1a1a"}
        d="M8.001 8l6.991 6.99m-13.981 0L8 8l-6.99 6.99zM14.992 1.01L8 8l6.992-6.99zM8 8L1.01 1.01 8 8z"
        strokeWidth={props.strokeWidth || 2}
      />
    </svg>
  );
}

export default LaClose;
