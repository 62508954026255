import React from "react";
import { LaCaretDownSmall } from "../../svg-icons";

interface PhoneCodeProps {
  selectedCode: string;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  darkMode?: boolean;
}
function PhoneCode({
  selectedCode,
  setIsDropdownOpen,
  darkMode,
}: PhoneCodeProps) {
  function handleIsDrop() {
    setIsDropdownOpen((prev) => !prev);
  }

  return (
    <button
      className={`flex cursor-pointer items-center justify-center rounded-[4px_0_0_4px] ${
        darkMode ? "border-black" : "border-grey-light"
      }  pl-4 pr-2`}
      type="button"
      onClick={handleIsDrop}
    >
      <span className="flex items-center gap-2">
        <span
          className={`${
            darkMode ? "text-primary-black-darker" : "text-primary-blue-dark"
          }`}
        >
          {selectedCode}
        </span>
        <LaCaretDownSmall />
      </span>
    </button>
  );
}

export default PhoneCode;
