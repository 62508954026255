import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const baseUrl = import.meta.env.VITE_API_BASE_URL;
const tabs = ["Organizer", "Team", "Player", "Competitions"] as const;
type Tabs = (typeof tabs)[number];

type IntialState = {
  lastTab: Tabs;
  lastTerm: string;
  fetchStatus: "idle" | "fetching";
  searchResult: any;
};

const initialState: IntialState = {
  lastTab: "Player",
  lastTerm: "",
  fetchStatus: "idle",
  searchResult: {},
};

export const fetchSearchResult = createAsyncThunk(
  "search/results",
  async (params: { seg: Tabs; kwd: string }) => {
    const response = await fetch(
      `${baseUrl}/api/search?seg=${params.seg.toLowerCase()}&kwd=${params.kwd}`,
      {
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });

    return response;
  }
);

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setLastTab: (state, action) => {
      state.lastTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchResult.pending, (state) => {
      if (state.fetchStatus === "idle") {
        state.fetchStatus = "fetching";
      }
    });
    builder.addCase(fetchSearchResult.fulfilled, (state, action) => {
      if (state.fetchStatus === "fetching") {
        state.searchResult = action.payload;
        state.fetchStatus = "idle";
      }
    });
    builder.addCase(fetchSearchResult.rejected, (state, action) => {
      if (state.fetchStatus === "fetching") {
        state.fetchStatus = "idle";
        console.log(action.payload);
        state.searchResult = action.payload;
      }
    });
  },
});

export const { setLastTab } = searchSlice.actions;
export default searchSlice.reducer;
