import { Navigate, useSearchParams } from "react-router-dom";
import OnboardingLayout from "../../layouts/OnboardingLayout";
import { SplashPlayer, SplashTeam } from "../../components/on-boarding";
import {
  PlayerSlideOne,
  PlayerSlideTwo,
} from "../../components/on-boarding/PlayerSlides";
import {
  TeamSlideOne,
  TeamSlideThree,
  TeamSlideTwo,
} from "../../components/on-boarding/TeamSlides";
import HelmetComponent from "../../components/HelmetComponent";

function SplashIntro() {
  const [searchParams, setSearchParams] = useSearchParams();
  const roleParams = searchParams.get("role") as TRole;

  const splashIntroScreens: {
    [key: string]: JSX.Element;
  } = {
    team: <SplashTeam />,
    player: <SplashPlayer />,
  };

  const playerSlides = [<PlayerSlideOne />, <PlayerSlideTwo />];
  const teamSlides = [<TeamSlideOne />, <TeamSlideTwo />, <TeamSlideThree />];

  const slides: {
    [key: string]: JSX.Element[];
  } = {
    player: playerSlides,
    team: teamSlides,
  };

  // open graph data
  const ogData = {
    title: "Leagues Arena - Accept Invite",
    description: "Get started with Leagues Arena",
    url: `https://${window.location.host}/account/accept-invite`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {!roleParams ? (
        <Navigate to="/account/login" />
      ) : (
        <>
          <OnboardingLayout
            slides={slides[roleParams]}
            setOnBoardingParams={setSearchParams}
            roleParams={roleParams}
          >
            {splashIntroScreens[roleParams]}
          </OnboardingLayout>
        </>
      )}
    </>
  );
}

export default SplashIntro;
