import React from "react";
import { useGetTournamentsQuery } from "../../redux/services/tournamentService";
import {
  DiscoverTournamentsCard,
  InviteForm,
  Discoverable,
  PlayerOfTheWeek,
} from "../../components/discover";
import {
  useSearchTeamsQuery,
  useGetAuthorsTeamQuery,
} from "../../redux/services/teamService";
import { Spinner } from "../../components/ui";
import Search from "../../components/search/Search";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { useGetTopTrainingSessionsQuery } from "../../redux/services/trainingService";
import { useGetPlayersQuery } from "../../redux/services/playerService";
import { AddTeamsToCompetition } from "../../components/tournament";
import { cn } from "../../utils/cn";
import { PlayerCardDeck, TeamCardDeck } from "../../components/ui/cards";
import { AddPlayersToTeam, TeamCompetitionsForm } from "../../components/team";
import HelmetComponent from "../../components/HelmetComponent";

function Discover() {
  const ID_LENGTH = 24;
  const [selectedPlayers, setSelectedPlayers] = React.useState<string[]>([]);
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const { data: tournaments = [], isLoading: tournamentLoading } =
    useGetTournamentsQuery({});
  const { data: authorTeam } = useGetAuthorsTeamQuery({ id: myId });
  const { data: teams = [], isLoading: loadingTeams } = useSearchTeamsQuery({});
  const { data: players = [], isLoading: loadingPlayers } = useGetPlayersQuery(
    {}
  );

  const { data: topSessions = [], isLoading: LoadingSessions } =
    useGetTopTrainingSessionsQuery();

  const [selectedCompetition, setSelectedCompetition] =
    React.useState<string>("none");
  const [selectedCompetitionForTeamId, setSelectedCompetitionForTeamId] =
    React.useState<string>("none");

  const [selectedCompetitionForTeam, setSelectedCompetitionForTeam] =
    React.useState<SelectedCompetition | null>(null);

  const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);
  const [maxTeam, setMaxTeam] = React.useState<number>(0);
  const [competitionTeams, setCompetitionTeams] = React.useState<
    Tournament["teams"]
  >([]);
  const [competitionPlayersForTeam, setCompetitionPlayersForTeam] =
    React.useState<string[]>([]);

  const tournamentCards = tournaments.map((tournament) => (
    <div
      className="min-w-[290px] lg:w-[360px] lg:min-w-[360px]"
      key={tournament._id}
    >
      <DiscoverTournamentsCard
        key={tournament?._id}
        tournamentId={tournament?._id}
        avatar={tournament?.avatar}
        banner={tournament?.banner}
        location={tournament.location}
        teams={tournament.teams.length}
        name={tournament.name}
        registrationFee={tournament.registrationFee}
      />
    </div>
  ));
  // open graph data
  const ogData = {
    title: "Leagues Arena - Discover",
    description:
      "Discover the latest competitions, players and teams on Leagues Arena",
    url: `https://${window.location.host}/discover`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {tournamentLoading ||
      loadingTeams ||
      loadingPlayers ||
      LoadingSessions ? (
        <div
          className="flex min-h-screen items-center justify-center"
          aria-label="loading"
        >
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center pb-10">
          <div
            className={cn("w-full px-4 pb-24 lg:max-w-[87%] lg:pb-0", {
              "pb-40 lg:pb-40": selectedTeams.length,
            })}
          >
            <h3 className="mt-16 hidden text-2xl font-medium lg:block">
              Bragging Rights
            </h3>

            <div className="mb-6 mt-4 w-full lg:max-w-md">
              <Search placeholder="Search for Competitions, Players, Teams and Organizers" />
            </div>

            {!!topSessions?.length && (
              <PlayerOfTheWeek topSessions={topSessions} />
            )}

            {!!tournamentCards.length && (
              <Discoverable
                title="Competitions"
                link="/discover/competitions"
              >
                {tournamentCards}
              </Discoverable>
            )}

            {!!players.length && (
              <Discoverable title="Players" link="/discover/players">
                <PlayerCardDeck
                  players={players}
                  myId={myId}
                  authorTeam={authorTeam}
                  setSelectedPlayers={setSelectedPlayers}
                  selectedPlayers={selectedPlayers}
                  setSelectedCompetitionForTeam={
                    setSelectedCompetitionForTeamId
                  }
                  selectedCompetitionForTeam={selectedCompetitionForTeamId}
                  competitionPlayersForTeam={competitionPlayersForTeam}
                />
              </Discoverable>
            )}

            {!!teams.length && (
              <Discoverable title="Teams" link="/discover/teams">
                <TeamCardDeck
                  teams={teams}
                  selectedCompetition={selectedCompetition}
                  selectedTeams={selectedTeams}
                  setSelectedCompetition={setSelectedCompetition}
                  setSelectedTeams={setSelectedTeams}
                  competitionTeams={competitionTeams}
                />
              </Discoverable>
            )}

            {!selectedCompetition && (
              <InviteForm
                setSelectedCompetition={setSelectedCompetition}
                setCompetitionTeams={setCompetitionTeams}
                setSelectedTeams={setSelectedTeams}
                selectedTeams={selectedTeams}
                setMaxTeam={setMaxTeam}
              />
            )}
            {selectedTeams.length > 0 &&
              selectedCompetition.length === ID_LENGTH && (
                <AddTeamsToCompetition
                  setSelectedTeams={setSelectedTeams}
                  selectedTeams={selectedTeams}
                  shouldScroll={true}
                  setCompetitionTeams={setCompetitionTeams}
                  setSelectedCompetition={setSelectedCompetition}
                  selectedCompetition={selectedCompetition}
                  maxTeam={maxTeam}
                  noOfTeamsJoined={competitionTeams.length}
                />
              )}

            {!selectedCompetitionForTeamId && (
              <TeamCompetitionsForm
                setSelectedCompetitionForTeamId={
                  setSelectedCompetitionForTeamId
                }
                setCompetitionPlayersForTeam={setCompetitionPlayersForTeam}
                setSelectedPlayers={setSelectedPlayers}
                selectedPlayers={selectedPlayers}
                setSelectedCompetitionForTeam={setSelectedCompetitionForTeam}
              />
            )}

            {selectedPlayers.length > 0 &&
              selectedCompetitionForTeamId.length === ID_LENGTH && (
                <AddPlayersToTeam
                  setCompetitionPlayersForTeam={setCompetitionPlayersForTeam}
                  setSelectedPlayers={setSelectedPlayers}
                  selectedPlayers={selectedPlayers}
                  selectedCompetitionForTeamId={selectedCompetitionForTeamId}
                  setSelectedCompetitionForTeamId={
                    setSelectedCompetitionForTeamId
                  }
                  selectedCompetitionForTeam={selectedCompetitionForTeam}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
}

export default Discover;
