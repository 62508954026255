import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  useGetWalletQuery,
  useSendWithdrawalRequestMutation,
} from "../../redux/services/walletService";
import formatToNaira from "../../utils/formatPrice";
import { LaClose } from "../svg-icons";
import { Button, Input } from "../ui";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "../../utils/cn";
import { setToastErrorText } from "../../redux/features/modal/displayModalSlice";

const schema = z.object({
  accountNumber: z.string(),
  accountName: z.string(),
  bankName: z.string(),
});

type TForm = z.infer<typeof schema>;

export default function WithdrawModal({
  setModalToDisplay,
}: {
  setModalToDisplay: React.Dispatch<React.SetStateAction<string>>;
}) {
  const dispatch = useAppDispatch();
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const { data: wallet } = useGetWalletQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TForm>({
    resolver: zodResolver(schema),
  });
  const [sendWithdrawalRequest] = useSendWithdrawalRequestMutation();

  const onSubmit = (data: TForm) => {
    sendWithdrawalRequest({
      ...data,
      amount: wallet?.balance ?? 0,
    })
      .unwrap()
      .then(() => {
        setModalToDisplay("success");
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastErrorText(err?.data?.message));
      });
  };

  return (
    <div className="absolute bottom-0 right-0 top-0 z-[100] min-h-screen w-[100vw] justify-center bg-black/20 lg:flex">
      <div className="flex h-screen w-full items-center justify-center px-4 lg:px-0">
        <div className="relative flex w-full flex-col rounded-xl bg-white p-4 py-8 shadow lg:w-[28%] lg:p-6 lg:py-10">
          <h3 className="text-xl font-bold">
            Withdraw {formatToNaira(wallet?.balance ?? 0)}
          </h3>
          <form
            className="mt-6 flex flex-col gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <fieldset className="flex flex-col gap-2">
              <label htmlFor="accountNumber" className="text-sm">
                Account number
                <span className="text-red">*</span>
              </label>
              <Input
                {...register("accountNumber")}
                role={role}
                type="text"
                id="accountNumber"
                className={cn("mt-0", {
                  "border-red": errors.accountNumber,
                })}
                placeholder="Account number"
              />
              {errors.accountNumber && (
                <p className="text-xs italic text-red">
                  {errors.accountNumber.message}
                </p>
              )}
            </fieldset>
            <fieldset className="flex flex-col gap-2">
              <label htmlFor="accountName" className="text-sm">
                Account name
                <span className="text-red">*</span>
              </label>
              <Input
                {...register("accountName")}
                role={role}
                type="text"
                id="accountName"
                className={cn("mt-0", {
                  "border-red": errors.accountName,
                })}
                placeholder="Account name"
              />
              {errors.accountName && (
                <p className="text-xs italic text-red">
                  {errors.accountName.message}
                </p>
              )}
            </fieldset>
            <fieldset className="flex flex-col gap-2">
              <label htmlFor="bankName" className="text-sm">
                Bank name
                <span className="text-red">*</span>
              </label>
              <Input
                {...register("bankName")}
                role={role}
                type="text"
                id="bankName"
                className={cn("mt-0", {
                  "border-red": errors.bankName,
                })}
                placeholder="Bank name"
              />
              {errors.bankName && (
                <p className="text-xs italic text-red">
                  {errors.bankName.message}
                </p>
              )}
            </fieldset>
            <fieldset className="mt-8 flex flex-col gap-2">
              <Button intent={"primary"} role={role}>
                Proceed
              </Button>
              <Button intent={"tertiary"} role={role}>
                Cancel
              </Button>
            </fieldset>
          </form>
          <button
            className="absolute right-4 top-4 lg:right-6 lg:top-6"
            onClick={() => setModalToDisplay("")}
          >
            <LaClose />
          </button>
        </div>
      </div>
    </div>
  );
}
