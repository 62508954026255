import { RiUserSharedFill } from "react-icons/ri";
import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const spotLightApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpotLight: builder.query({
      query: ({ page = 1, limit = 10 }: { page?: number; limit?: number }) => ({
        url: "/spotlight",
        method: "GET",
        params: { page, limit },
      }),
      providesTags: ["Spotlight"],
    }),
    getUserSpotlight: builder.query({
      query: ({ userid, profile }: { userid: string; profile: string }) => ({
        url: `/spotlight/user/${userid}`,
        params: {
          profile,
        },
        method: "GET",
      }),
      providesTags: ["Spotlight"],
    }),
    getTournamentSpotlight: builder.query({
      query: ({ tournamentId }: { tournamentId: string }) => ({
        url: `/spotlight/tournament/${tournamentId}`,
        method: "GET",
      }),
      providesTags: ["Spotlight"],
    }),
    getSingleSpotlight: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `/spotlight/${id}`,
        method: "GET",
      }),
      providesTags: ["Spotlight"],
      transformResponse: (data: SpotLight) => data,
    }),

    reactToSpotlight: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `/spotlight/${id}`,
        method: "PATCH",
      }),
      // invalidatesTags: ["Spotlight"],
    }),
    updateShareCount: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `/spotlight/${id}/share`,
        method: "PATCH",
      }),
    }),
    reactToSpotlightComment: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: `/spotlight/comment/${id}`,
        method: "PATCH",
      }),
    }),
    getSpotlightComments: builder.query({
      query: ({ id }: { id: string }) => ({
        url: `/spotlight/${id}/comment`,
        method: "GET",
      }),
      providesTags: ["SpotlightComment"],
    }),
    createSpotLight: builder.mutation({
      query: (data: FormData) => ({
        url: "/spotlight",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Spotlight"],
    }),
    createSpotlightComment: builder.mutation({
      query: ({ data, id }: { data: { comment: string }; id: string }) => ({
        url: `/spotlight/${id}/comment`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SpotlightComment", "Spotlight"],
    }),
    updateSpotLight: builder.mutation({
      query: (data) => ({
        url: "/spotlight",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Spotlight"],
    }),
    deleteSpotLight: builder.mutation({
      query: (id) => ({
        url: `/spotlight/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Spotlight"],
    }),
  }),
});

export const {
  useGetSpotLightQuery,
  useCreateSpotLightMutation,
  useGetSingleSpotlightQuery,
  useGetSpotlightCommentsQuery,
  useGetUserSpotlightQuery,
  useReactToSpotlightCommentMutation,
  useReactToSpotlightMutation,
  useCreateSpotlightCommentMutation,
  useUpdateSpotLightMutation,
  useUpdateShareCountMutation,
  useGetTournamentSpotlightQuery,
} = spotLightApi;
