import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  username: "",
  wallet: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
  },
});

export const { setUserId, setUsername, setWallet } = userSlice.actions;
export default userSlice.reducer;
