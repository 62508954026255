import { GeneratedSeasonFixtures } from "../../utils/generateFixtures";

export default function GeneratedSeasons({
  fixtures,
}: {
  fixtures: GeneratedSeasonFixtures;
}) {
  console.log(fixtures);

  return (
    <div className="grid w-full gap-4  lg:grid-cols-2">
      {fixtures?.map((fixture, i) => {
        const team1 = fixture?.teamData?.[0].team as unknown as Team;
        const team2 = fixture?.teamData?.[1].team as unknown as Team;
        return (
          <div key={i} className="rounded border border-grey-light p-4 lg:p-6">
            <h3 className="text-sm">Match {i + 1}</h3>
            <div className="mt-2 grid grid-cols-3 items-center justify-between">
              <div className="flex items-center gap-2">
                <img
                  src={team1?.avatar}
                  alt={team1?.name ? team1?.name : "TBD"}
                  className="h-8 w-8 rounded-full"
                />
                <p className="capitalize">
                  {team1?.name ? team1?.name : "TBD"}
                </p>
              </div>
              <div className="flex items-center justify-center">
                <p className="rounded bg-grey-lighter p-2">Vs</p>
              </div>
              <div className="flex items-center gap-2 justify-self-end">
                <p className="capitalize">
                  {team2?.name ? team2?.name : "TBD"}
                </p>
                <img
                  src={team2?.avatar}
                  alt={team2?.name ? team2?.name : "TBD"}
                  className="h-8 w-8 rounded-full"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
