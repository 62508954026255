import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    id: "",
    createdAt: "",
    loser: "",
    outcome: "",
    tournament:"",
    winner: "",
    updatedAt: "",
  }
};

const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    setResuldId: (state, action)=>{
      state.data.id = action.payload;
    },
    setResult: (state, action)=>{
      state.data = action.payload
    }
  },
});

export const { setResult, setResuldId } = resultSlice.actions;
export default resultSlice.reducer;
