import carousel from "../../assets/data/carousel";
import Slider from "../swiper/Slider";

function SigninCarousel() {
  const carouselSlides = carousel.map((item) => (
    <div className="h-full w-full">
      <img
        src={item.src}
        alt={item.alt}
        className="h-full w-full rounded-2xl object-cover"
      />
      <p className="absolute bottom-16 text-center text-4xl font-medium text-white">
        {item.text}
      </p>
    </div>
  ));
  return <Slider slides={carouselSlides} />;
}

export default SigninCarousel;
