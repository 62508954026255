import React from "react";
import { LaEye, LaEyeClosed } from "../../svg-icons";
import Input from "../input/Input";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { cn } from "../../../utils/cn";

interface PasswordInputProps {
  name: string;
  placeholder: string;
  error: {
    password?: string;
    error?: string;
    clearError: (key: string) => void;
    confirmPassword?: string;
  };
}

/**
 * @example props={
 *   name: "password",
 *   placeholder: "Enter a password",
 * }
 * @property {string} placeholder - Placeholder text to display within the input field.
 * @property {string} name - The name of the input field.
 */
function PasswordInput(props: PasswordInputProps) {
  const [showPassword, setShowPassword] = React.useState(false);
  const type = showPassword ? "text" : "password";
  const role = useAppSelector((state: RootState) => state.auth.role) || "";

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <fieldset className="relative">
      <Input
        type={type}
        name={props.name}
        id={props.name}
        required
        className={cn(
          "mt-2 border px-4 py-3 focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30",
          {
            "error border-red focus:border-red":
              props.error.password || props.error.confirmPassword,
          }
        )}
        placeholder={props.placeholder}
        role={role}
      />
      <button
        type="button"
        className="absolute right-3 top-[38%] cursor-pointer"
        onClick={handleShowPassword}
      >
        {showPassword ? <LaEye /> : <LaEyeClosed />}
      </button>
    </fieldset>
  );
}

export default PasswordInput;
