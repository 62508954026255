import { useAppDispatch } from "../redux/app/hooks";
import { setModalToDisplay } from "../redux/features/modal/displayModalSlice";

export default function useHandleActionButton() {
  const dispatch = useAppDispatch();

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const route: {
      [key: string]: () => void;
    } = {
      "create competition": () => {
        dispatch(setModalToDisplay("create-competition"));
      },
      competition_spotlight: () => {
        dispatch(setModalToDisplay("competition-spotlight"));
      },
      spotlight: () => {
        dispatch(setModalToDisplay("spotLight"));
      },
    };
    route[e.currentTarget.dataset.item!]();
  }
  return { handleClick };
}
