import HelmetComponent from "../../components/HelmetComponent";

function FanProfile() {
  // open graph data
  const ogData = {
    title: "Leagues Arena - Fan Profile",
    description: "Fan Profile on Leagues Arena",
    url: `https://${window.location.host}/profiles/fan/:name`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076677/Frame_6181_k3886h.png",
    siteName: "Leagues Arena",
  };
  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex h-screen w-full flex-col items-center justify-center">
        <div className="flex h-[120px] w-[120px] items-center justify-center rounded-full bg-[#E9FCF4] lg:h-[160px] lg:w-[160px]">
          <img
            src={`https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076677/Frame_6181_k3886h.png`}
            alt={`fan`}
            className="h-full w-full rounded-full"
          />
        </div>
        <h3 className="mt-6 text-4xl text-primary-blue-dark">Fan Profile</h3>
        <p className="mt-2 text-center text-base text-primary-black-light">
          Coming Soon!!!
        </p>
      </div>
    </>
  );
}

export default FanProfile;
