import p1 from "./WhatsApp Image 2024-01-24 at 11.09 1.png";
import p2 from "./WhatsApp Image 2024-01-24 at 11.09 10.png";
import p3 from "./WhatsApp Image 2024-01-24 at 11.09 11.png";
import p4 from "./WhatsApp Image 2024-01-24 at 11.09 12.png";
import p5 from "./WhatsApp Image 2024-01-24 at 11.09 13.png";
import p6 from "./WhatsApp Image 2024-01-24 at 11.09 3.png";
import p7 from "./WhatsApp Image 2024-01-24 at 11.09 5.png";
import p8 from "./WhatsApp Image 2024-01-24 at 11.09 7.png";
import p9 from "./WhatsApp Image 2024-01-24 at 11.39 1.png";
import p0 from "./WhatsApp Image 2024-01-24 at 15.27 1.png";
import p11 from "./tom_g_fitness.png";
import p12 from "./cmt-logo.jpg";

const partners = [p12, p1, p0, p2, p3, p4, p5, p6, p7, p8, p9, p11];
export default partners;
