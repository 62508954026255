import {
  useGetMeQuery,
  useUpdateMeMutation,
} from "../../redux/services/userService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { ToggleBtn } from "../ui";

type Props = {};

function EmailNotifications({}: Props) {
  const { data: user } = useGetMeQuery();
  const [updateUser] = useUpdateMeMutation();
  const dispatch = useAppDispatch();

  function handlePosts(value: Boolean) {
    updateUser({ allowEmailNotificationForPosts: value })
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  function handleSchedules(value: Boolean) {
    updateUser({ allowEmailNotificationForSchedules: value })
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }
  return (
    <div className="flex flex-col  lg:w-[75%]">
      <div className="flex items-center justify-between border-b border-[#D8D8D8]">
        <div className="py-2">
          <h3 className="text-xl">Competitions</h3>
          <p className="mt-2 text-base text-[#767676]">
            Get ongoing competition notification via email.
          </p>
        </div>
        <ToggleBtn
          handlePosts={(e: boolean) => handlePosts(e)}
          state={user?.allowEmailNotificationForPosts}
        />
      </div>
      <div className="flex items-center justify-between border-b border-[#D8D8D8]">
        <div className="py-2">
          <h3 className="text-xl">Training Sessions</h3>
          <p className="mt-2 text-base text-[#767676]">
            Get training session notifications via emai
          </p>
        </div>
        <ToggleBtn
          handleSchedules={handleSchedules}
          state={user?.allowEmailNotificationForSchedules}
        />
      </div>
    </div>
  );
}

export default EmailNotifications;
