import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as z from "zod";
import { LaClose } from "../../components/svg-icons";
import LaSpinner from "../../components/svg-icons/LaSpinner";
import { RegisteredSelect } from "../../components/ui";
import Button from "../../components/ui/buttons/Button";
import FileLabel from "../../components/ui/buttons/FileLabel";
import Textarea from "../../components/ui/textarea/Textarea";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  setModalToDisplay,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { setSentPost } from "../../redux/features/post/postSlice";
import { useGetMyOrganizerQuery } from "../../redux/services/organizerService";
import { useCreatePostMutation } from "../../redux/services/postService";
import { useGetUserTournamentsQuery } from "../../redux/services/tournamentService";
import { cn } from "../../utils/cn";
import HelmetComponent from "../../components/HelmetComponent";

const CreatePostSchema = z.object({
  tournament: z.string().min(3, { message: "Competition is required" }),
  note: z.string().min(3, { message: "Write something..." }),
  image: z.any(),
});

export type TCreatePost = z.infer<typeof CreatePostSchema>;

type Props = {};

function CreatePost({}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: RootState) => state.auth.role);
  const { data: organizer } = useGetMyOrganizerQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<TCreatePost>({
    resolver: zodResolver(CreatePostSchema),
  });

  const postsLeft = organizer?.postsLeft;
  const [createPost, { isLoading: isCreatingPost }] = useCreatePostMutation();
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const { data: myTournament = [] } = useGetUserTournamentsQuery({
    userId: myId,
  });
  const [selectedId, setSelectedId] = React.useState("");
  const watchImage = watch("image");
  const watchTournament = watch("tournament");
  const fileName = watchImage && watchImage[0]?.name;
  const profiles = useAppSelector((state: RootState) => state.auth.profiles);
  const currentProfile = profiles?.find((profile) => profile.role === role);

  function handleCreatePost(data: TCreatePost) {
    const formData = new FormData();
    formData.append("note", data.note);
    formData.append("tournament", selectedId);
    data.image[0] && formData.append("image", data.image[0]);
    currentProfile?.name && formData.append("creatorName", currentProfile?.name);
    currentProfile?.avatar && formData.append("creatorAvatar", currentProfile?.avatar);
    role && formData.append("creatorRole", role);

    if (postsLeft === 0) {
      return dispatch(setModalToDisplay("enterprise-modal"));
    }

    createPost(formData)
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        dispatch(setSentPost(true));
        navigate(-1);
      })
      .catch((err) => {
        dispatch(setToastErrorText(err.data.message));
        dispatch(setSentPost(false));
      });
  }

  // open graph data
  const ogData = {
    title: "Leagues Arena - Create Post",
    description: "Create a post on Leagues Arena",
    url: `https://${window.location.host}/post/create`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10 lg:pb-32">
        <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <span className="text-base lg:text-sm">
                <LaClose />
              </span>
              <span className="hidden lg:block">Cancel</span>
            </button>
            <h3 className="text-xl font-bold">Create post</h3>
          </div>

          <div className="mt-6 flex flex-col lg:mt-10">
            <div className="flex w-full flex-col gap-2 lg:items-center">
              <h3 className="lg:hidden">Team photo</h3>
              <form
                className="flex flex-col gap-6 lg:w-[75%] lg:gap-4"
                onSubmit={handleSubmit(handleCreatePost)}
              >
                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="note" className="text-sm md:text-base">
                    What's happening?
                  </label>
                  <Textarea
                    role={role}
                    register={register}
                    name="note"
                    id="note"
                    className={cn("border-opacity-30 px-4 py-3 capitalize", {
                      "error border-red focus:border-4 focus:border-red focus:border-opacity-30":
                        errors.note,
                    })}
                    placeholder="Write something..."
                  />
                  {errors.note && (
                    <span className="text-sm italic text-red">{errors.note.message}</span>
                  )}
                </fieldset>

                <button type="button" className="flex items-center gap-3">
                  <FileLabel
                    htmlFor="image"
                    className={`font-base cursor-pointer rounded-md border p-3 text-lg font-medium leading-[19px] `}
                    $userRole={role}
                  >
                    Upload File
                    <input type="file" id="image" hidden {...register("image")} />
                  </FileLabel>
                  <span className="text-gray-300">{fileName}</span>
                  {errors.image && (
                    <span className="text-sm italic text-red">
                      {errors.image.message as string}
                    </span>
                  )}
                </button>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="tournament" className="text-sm md:text-base">
                    Competition
                  </label>
                  <RegisteredSelect
                    register={register}
                    setValue={setValue}
                    required="please select a competition"
                    placeholder="Select preferred competition"
                    name="tournament"
                    id="tournament"
                    value={watchTournament}
                    options={myTournament}
                    error={errors.tournament?.message}
                    setSelectedId={setSelectedId}
                  />
                  {errors.tournament && (
                    <span className="text-sm italic text-red">
                      {errors.tournament.message}
                    </span>
                  )}
                </fieldset>

                <fieldset className="mt-[145px] flex flex-col lg:mt-6">
                  <Button
                    type="submit"
                    disabled={isCreatingPost || (postsLeft || 0) < 1}
                    aria-label="share-post"
                    className="flex items-center justify-center gap-2 disabled:cursor-not-allowed disabled:opacity-50 lg:py-4 lg:text-2xl"
                    role={role}
                    intent={"primary"}
                  >
                    <span>Share Post</span>
                    {isCreatingPost && (
                      <span className="animate-spin">
                        <LaSpinner />
                      </span>
                    )}
                  </Button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePost;
