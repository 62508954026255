import React from "react";
import { ButtonLink, Spinner } from "../ui";
import { cn } from "../../utils/cn";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetTournamentMatchesQuery } from "../../redux/services/matchService";
import { RootState } from "../../redux/app/store";
import { useAppSelector } from "../../redux/app/hooks";
import GroupStageTab from "../tabs/GroupStageTab";
import KnockoutFixtures from "./KnockoutFixtures";
import SeasonFixtures from "./SeasonFixtures";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import CompletedCard from "../ui/cards/CompletedCard";
import MatchBracket from "./MatchBracket";

function GroupStageTournamentMatches({
  fixtures,
  author,
  tournament,
}: {
  fixtures: Fixture;
  author: string;
  tournament: Tournament;
}) {
  const [tabParams] = useSearchParams();
  const activeTab = (tabParams.get("subTab") || "completed") as
    | "completed"
    | "fixtures"
    | "table"
    | "bracket";

  const [clickedIndex, setClickedIndex] = React.useState(Number);
  const { tournamentId } = useParams();
  const { data: completedMatches, isLoading } = useGetTournamentMatchesQuery(
    { id: tournamentId, status: "completed" },
    { skip: activeTab !== "completed" }
  );
  const completedCards = completedMatches?.map((match, i) => {
    const [teamA, teamB] = match?.teamData ?? [];
    const matchCompletedTime = new Date(match.timeCompleted);
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - matchCompletedTime.getTime();
    const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
    const canEdit =
      timeDifference < twentyFourHoursInMillis || !match.timeCompleted;

    return (
      <CompletedCard
        key={match?._id}
        // Todo: make sure  the name on this match is the proper match round name
        round={match?.name}
        id={match?._id}
        venue={match?.location}
        date={match?.date}
        teamA={teamA}
        teamB={teamB}
        index={i}
        setClickedIndex={setClickedIndex}
        isOpen={clickedIndex === i}
        canEdit={canEdit}
      />
    );
  });
  const role = useAppSelector((state: RootState) => state.auth.role);
  return (
    <>
      <div className="flex items-center gap-4 overflow-x-auto border-b border-grey-light px-2 py-4 text-base font-medium lg:px-0">
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=matches&subTab=fixtures`}
          intent="secondary"
          role={role}
          className={cn("rounded-full border-2 px-4 py-2 capitalize ", {
            "border-grey-light text-grey": activeTab !== "fixtures",
          })}
        >
          Fixtures
        </ButtonLink>

        <ButtonLink
          to={`/competitions/${tournamentId}?tab=matches&subTab=table`}
          intent="secondary"
          role={role}
          className={cn(
            "min-w-fit rounded-full border-2 px-4 py-2 capitalize ",
            {
              " border-grey-light text-grey": activeTab !== "table",
            }
          )}
        >
          Group Stage
        </ButtonLink>

        <ButtonLink
          to={`/competitions/${tournamentId}?tab=matches&subTab=bracket`}
          intent="secondary"
          role={role}
          className={cn(
            "min-w-fit rounded-full border-2 px-4 py-2 capitalize ",
            {
              "border-grey-light text-grey": activeTab !== "bracket",
            }
          )}
        >
          Knockout Stage
        </ButtonLink>
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=matches&subTab=completed`}
          intent="secondary"
          role={role}
          className={cn(
            "min-w-fit rounded-full border-2 px-4 py-2 capitalize ",
            {
              "border-grey-light text-grey": activeTab !== "completed",
            }
          )}
        >
          Completed
        </ButtonLink>
      </div>

      {activeTab === "fixtures" && (
        <div className="flex flex-col gap-4 py-4 lg:px-0">
          {tournament.stage === "group" && (
            <SeasonFixtures
              fixtures={fixtures[1] as Match[]}
              tournamentAuthorId={author}
            />
          )}

          {tournament.stage === "knockout" && (
            <KnockoutFixtures
              fixtures={
                fixtures[1] as {
                  round: number;
                  matches: KnockoutMatch[];
                }[]
              }
              tournamentAuthorId={author}
            />
          )}
        </div>
      )}
      {activeTab === "table" && (
        <GroupStageTab tournamentId={tournamentId as string} />
      )}
      {activeTab === "bracket" &&
        (tournament.stage === "knockout" && fixtures[1].length > 0 ? (
          <MatchBracket
            fixtures={
              fixtures[1] as {
                round: number;
                matches: KnockoutMatch[];
              }[]
            }
            tournamentComplete={!tournament?.ongoing}
          />
        ) : (
          <EmptyStateAuto
            title="Nothing to see here"
            description="This tournament has not entered knockout stage. when it does the bracket will appear here"
          />
        ))}

      {activeTab === "completed" && (
        <>
          {isLoading ? (
            <div className="flex w-full items-center justify-center py-4">
              <Spinner />
            </div>
          ) : (
            <>
              {!completedMatches?.length && (
                <EmptyStateAuto
                  title="Nothing to see here"
                  description="This tournament does not have any completed match yet. when it does it'll appear here"
                />
              )}
              <div className="flex flex-col gap-4 p-4 lg:px-0">
                {completedCards}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default GroupStageTournamentMatches;
