import type { ReactElement } from "react";
import { Link } from "react-router-dom";
import { LaCalendar, LaClock, LaPlayers } from "../../svg-icons";
import moment from "moment";

export interface TrainingCardProps {
  trainingId: string;
  name: string;
  location: string;
  date: string;
  time: string;
  noOfPlayers: number;
}

export default function TrainingCard({
  trainingId,
  name,
  location,
  date,
  time,
  noOfPlayers,
}: TrainingCardProps): ReactElement {
  const randomColors = [
    "#7F1AE5",
    "#6AC37F",
    "#D05B5B",
    "#3E67D9",
    "#F4BF42",
    "#FF6F91",
    "#4BC4E4",
    "#AA86F2",
    "#FF8D1A",
    "#63A690",
    "#E14848",
    "#489DE1",
    "#FFD633",
    "#FF5C7F",
    "#39E6A8",
    "#B760FF",
  ];
  const color = randomColors[Math.floor(Math.random() * randomColors.length)];
  return (
    <Link to={`/training/${trainingId}`} className="flex">
      <div
        className="w-2 rounded-[8px_0px_0px_8px]"
        style={{ backgroundColor: color }}
      />

      <div className="flex w-full flex-col items-center justify-center rounded-[0_8px_8px_0] border-b border-r border-t border-grey-light p-4 pt-1">
        <p className="text-sm font-medium">
          {name} · {location}
        </p>
        <div className="mt-1 h-[1px] w-[150px] bg-grey-light" />
        <div className="mt-2 flex w-full items-center justify-between">
          <div className="text-xs">
            <div className="flex items-center gap-1">
              <LaCalendar /> <p>{moment(date).format("ll")}</p>
            </div>
            <div className="flex items-center gap-1">
              <LaClock />
              <p>
                {moment(date)?.format("dddd")} {moment(time).format("LT")}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-1 capitalize">
            <LaPlayers />{" "}
            <p className="text-xs">
              {noOfPlayers < 2
                ? `${noOfPlayers} Player`
                : `${noOfPlayers} Players`}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}
