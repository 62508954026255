/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrl = import.meta.env.VITE_API_TRAINING_URL;

export const leaguesTrainingApi = createApi({
  reducerPath: "leaguesTrainingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `http://localhost:3001/api`,
  }),

  tagTypes: [],
  endpoints: (builder) => ({
    getTopPlayers: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const { useGetTopPlayersQuery } = leaguesTrainingApi;
