function formatToNaira(number: string | number) {
  // Check if the input is a valid number
  if (isNaN(+number)) {
    return "Invalid number";
  }

  return Number(number).toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  });
}

export default formatToNaira;
