import Input from "../ui/input/Input";
import { Spinner } from "../ui";
import { CreateTournamentFormValues } from "../../pages/create-tournament/CreateTournament";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import { cn } from "../../utils/cn";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";

interface TournamentPricingProps {
  register: UseFormRegister<CreateTournamentFormValues>;
  errors: FieldErrors<CreateTournamentFormValues>;
  handleSubmit: UseFormHandleSubmit<CreateTournamentFormValues>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setError: UseFormSetError<CreateTournamentFormValues>;
}

function TournamentPricing({
  register,
  errors,
  handleSubmit,
  setCurrentStep,
  setError,
}: TournamentPricingProps) {
  const role = useAppSelector((state: RootState) => state.auth.role);

  function handleNextView(data: CreateTournamentFormValues) {
    if (
      +data.winnerPrize < +data.runnerUpPrize ||
      +data.runnerUpPrize < +data.thirdPlacePrize
    ) {
      return setError("winnerPrize", {
        type: "manual",
        message: "Prize money must be in descending order",
      });
    }

    setCurrentStep((prev) => prev + 1);
  }

  return (
    <section className="my-6 min-h-[50vh] w-full md:my-12">
      <div className="mx-auto flex max-w-2xl flex-col items-center gap-2 px-2">
        {/* Create Tournament */}
        <div className="mb-6 flex flex-col gap-2">
          <h2 className="text-center text-[28px] font-medium text-primary-black-darker md:text-4xl">
            Rewards
          </h2>

          <p className="text-center text-base text-grey md:text-xl">
            Enter rewards to be won in your competition
          </p>
        </div>
        <form
          className="flex w-full flex-col items-start gap-6 px-2"
          onSubmit={handleSubmit(handleNextView)}
        >
          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="winner-prize" className="text-sm md:text-base">
              Winner
            </label>

            <Input
              role={role}
              id="winner-prize"
              type="text"
              maxLength={30}
              register={register}
              placeholder="₦150,000"
              {...register("winnerPrize", {
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey    focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.winnerPrize,
                }
              )}
            />
            {errors.winnerPrize && (
              <p className="text-xs italic text-red">
                {errors.winnerPrize.message}
              </p>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="runner-up-prize">Second position</label>
            <Input
              id="runner-up-prize"
              type="text"
              {...register("runnerUpPrize", {
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              placeholder="₦100,000"
              register={register}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.runnerUpPrize,
                }
              )}
              role={role}
            />
            {errors.runnerUpPrize && (
              <span className="text-xs text-red">
                {errors.runnerUpPrize.message}
              </span>
            )}
          </fieldset>
          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="description">Third position</label>
            <Input
              role={role}
              id="third-place-prize"
              type="text"
              {...register("thirdPlacePrize", {
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              register={register}
              placeholder="₦70,000"
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.thirdPlacePrize,
                }
              )}
            />
            {errors.thirdPlacePrize && (
              <span className="text-xs text-red">
                {errors.thirdPlacePrize.message}
              </span>
            )}
          </fieldset>
          <fieldset className="grid w-full grid-cols-2 flex-col gap-4 pb-20 lg:gap-6">
            <button
              className={cn(
                "mt-16 flex w-full  items-center justify-center rounded bg-primary-black-darker p-4 text-base text-white hover:bg-primary-black-lighter hover:text-black disabled:bg-grey-light disabled:text-grey md:mt-14 md:text-2xl"
              )}
            >
              Skip
            </button>
            <button
              className={cn(
                "mt-16 flex w-full  items-center justify-center rounded bg-primary-black-darker p-4 text-base text-white hover:bg-primary-black-lighter hover:text-black disabled:bg-grey-light disabled:text-grey md:mt-14 md:text-2xl"
              )}
            >
              Proceed
            </button>
          </fieldset>
        </form>
      </div>
    </section>
  );
}

export default TournamentPricing;
