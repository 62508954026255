import type { ReactElement } from "react";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { useAppSelector } from "../../redux/app/hooks";
import { cn } from "../../utils/cn";
import Input from "../ui/input/Input";
import { RootState } from "../../redux/app/store";
import { Button } from "../ui";
import RegisteredPhoneInput from "../ui/input/RegisteredPhoneInput";
import { RestartTournamentFormValues } from "../../pages/tournament/RestartTournament";

interface RestartTournamentContactFormProps {
  register: UseFormRegister<RestartTournamentFormValues>;
  errors: FieldErrors<RestartTournamentFormValues>;
  handleSubmit: UseFormHandleSubmit<RestartTournamentFormValues>;
  tournamentId: string;
  prevData: Tournament;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setValue: UseFormSetValue<RestartTournamentFormValues>;
}

export default function RestartTournamentContactForm({
  register,
  handleSubmit,
  prevData,
  errors,
  setCurrentStep,
  setValue,
}: RestartTournamentContactFormProps): ReactElement {
  const role = useAppSelector((state: RootState) => state.auth?.role);

  const onSubmit: SubmitHandler<RestartTournamentFormValues> = (_data) => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <section className="my-6 min-h-[50vh] w-full md:my-12">
      <div className="mx-auto flex max-w-2xl flex-col items-center gap-2 px-2">
        {/* Create Tournament */}
        <div className="mb-6 flex flex-col gap-2">
          <h2 className="text-center text-[28px] font-medium text-primary-black-darker md:text-4xl">
            Review contact information
          </h2>
        </div>
        <form
          className="flex w-full flex-col items-start gap-6 px-2 pb-20 lg:pb-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="name">
              Competition Name<span className="text-bold text-red">*</span>
            </label>
            <Input
              type="text"
              {...register("contactName", {
                required: "Please enter a contact name for the tournament",
                maxLength: 30,
              })}
              id="contactName"
              placeholder="Enter contact name"
              role={role}
              register={register}
              defaultValue={prevData?.contactName}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors?.contactName,
                }
              )}
            />
            {errors.contactName && (
              <p className="text-red">{errors.contactName.message}</p>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="phone-number" className="text-sm md:text-base">
              Mobile<span className="text-red">*</span>
            </label>
            <RegisteredPhoneInput
              name="phoneNumber"
              error={errors.phoneNumber?.message}
              register={register}
              required="Phone number is required"
              id="phone-number"
              value={prevData?.phoneNumber}
              phonecodeName="phoneCode"
              setValue={setValue}
            />
            {errors.phoneNumber && (
              <span className="text-red">{errors.phoneNumber?.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="phone-number-2" className="text-sm md:text-base">
              Telephone <span className="text-red">(Optional)</span>
            </label>
            <RegisteredPhoneInput
              name="phoneNumber2"
              error={errors.phoneNumber2?.message}
              register={register}
              id="phone-number-2"
              phonecodeName="phoneCode2"
              value={prevData.phoneNumber2}
              setValue={setValue}
            />
            {errors.phoneNumber2 && (
              <span className="text-red">{errors.phoneNumber2?.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="contactEmail">
              Contact email<span className="text-bold text-red">*</span>
            </label>
            <Input
              type="text"
              role={role}
              register={register}
              {...register("contactEmail", {
                required: "Enter your email address",
                maxLength: {
                  value: 50,
                  message: "Email address is too long",
                },
              })}
              id="contactEmail"
              placeholder="Enter your email address"
              defaultValue={prevData?.contactEmail}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors?.contactEmail,
                }
              )}
            />
            {errors.contactEmail && (
              <p className="text-red">{errors.contactEmail.message}</p>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch">
            <label htmlFor="contactAddress">
              Contact address<span className="text-bold text-red">*</span>
            </label>
            <Input
              type="text"
              role={role}
              register={register}
              {...register("contactAddress", {
                required: "Enter your address",
              })}
              id="contactAddress"
              placeholder="Enter your address"
              defaultValue={prevData?.contactAddress}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4  focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors?.contactAddress,
                }
              )}
            />
            {errors.contactAddress && (
              <p className="text-red">{errors.contactAddress.message}</p>
            )}
          </fieldset>

          <Button
            role={role}
            intent={"primary"}
            type="submit"
            className={cn(
              "mt-6 flex w-full  items-center justify-center rounded  p-4 text-base  md:mt-14 md:text-2xl"
            )}
          >
            Proceed
          </Button>
        </form>
      </div>
    </section>
  );
}
