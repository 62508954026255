import { useRef, useState } from "react";
import formatToNaira from "../../utils/formatPrice";
import { LaInfo } from "../svg-icons";
import useOnClickOutside from "../../hooks/useClickedOutside";

export default function RegistrationFeeInfo({
  totalFee,
}: {
  totalFee: number;
}) {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const tooltipEl = useRef(null);

  useOnClickOutside(tooltipEl, () => {
    setDisplayTooltip(false);
  });

  return (
    <div className="relative">
      <p
        onMouseOver={() => setDisplayTooltip(true)}
        onMouseOut={() => setDisplayTooltip(false)}
        onTouchStart={() => setDisplayTooltip(!displayTooltip)}
        className="flex cursor-pointer items-center gap-2"
        title="We apply a fixed fee of ₦800 per player per max no. of players in a team. This service fee will be added to the team registration fee set by you."
        ref={tooltipEl}
      >
        Team will pay {formatToNaira(totalFee)} after addition of processing fee
        <LaInfo className="text-xl" />
      </p>
      {displayTooltip && (
        <p className="absolute bottom-10 right-10 max-w-sm rounded bg-white p-4 shadow lg:p-6">
          We apply a fixed fee of{" "}
          <span className="text-[#FF6600]">#800.00</span> per player per max no.
          of players in a team. This service fee will be added to the team
          registration fee set by you.
        </p>
      )}
    </div>
  );
}
