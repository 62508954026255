import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { ReButton } from "../ui/buttons/ReButton";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../ui/navigation-menu";

import MobileMenu from "../new-landing-page/MobileMenu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/DropDownMenu";

function Navbar() {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative z-[90] flex w-full items-center justify-between  px-4 py-[27px] md:px-8 lg:px-20 lg:py-5">
        <div className="logo">
          <Link to="/">
            <img
              src="/logo.png"
              alt="leagues arena"
              className="relative z-50 w-[94px] object-contain md:w-[99px]"
            />
          </Link>
        </div>

        <ul className="items-center gap-16 font-medium xs:hidden lg:flex">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "text-primary-blue-dark" : ""
              }
            >
              Home
            </NavLink>
          </li>

          <li>
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="text-base">
                    Products
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="flex flex-col gap-2 bg-white p-2">
                      <li className="flex-1">
                        <NavigationMenuLink asChild>
                          <ReButton
                            variant={"primary"}
                            onClick={() => {
                              navigate("/home");
                            }}
                          >
                            Leagues Competition
                          </ReButton>
                        </NavigationMenuLink>
                      </li>
                      <li className="flex-1">
                        <NavigationMenuLink asChild className="w-full">
                          <ReButton
                            variant={"primary"}
                            onClick={() => {
                              window.location.href =
                                "https://training.leaguesarena.com";
                            }}
                          >
                            Leagues Training
                          </ReButton>
                        </NavigationMenuLink>
                      </li>
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
          </li>

          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "text-primary-blue-dark" : ""
              }
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
        <ul className="hidden gap-8 md:flex">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className="hidden rounded-lg bg-primary-blue-dark p-3 px-5 text-white hover:bg-primary-blue-light lg:block">
                Get Started
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-white p-2">
              <DropdownMenuItem
                className=" rounded-md hover:bg-primary-blue-dark hover:text-white"
                onClick={() => {
                  navigate("/home");
                }}
              >
                Leagues Competition
              </DropdownMenuItem>
              <DropdownMenuItem
                className="rounded-md hover:bg-primary-blue-dark hover:text-white"
                onClick={() => {
                  window.location.href = "https://training.leaguesarena.com";
                }}
              >
                Leagues Training
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </ul>
        <MobileMenu />
      </div>
      {/* <MobileMenu toggleNav={toggleNav} setToggleNav={setToggleNav} /> */}
    </>
  );
}

export default Navbar;
