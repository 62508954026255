import { Link } from "react-router-dom";
import callToAction from "../../assets/images/call-to-action.webp";

function CallToAction() {
  return (
    <div className="mt-20 flex w-full  flex-col-reverse items-center bg-primary-blue-lighter px-4 py-[40px] md:px-8 md:py-[60px] lg:flex-row lg:px-20 lg:py-20">
      <div className="left flex flex-col items-center lg:w-1/2 lg:items-start">
        <p className="mt-6 text-center text-2xl font-medium leading-[29px] md:w-[620px] md:text-[40px] md:leading-[48px] lg:mt-0 lg:w-full lg:text-left">
          Join the number one community for sports fans and sportsmen.{" "}
        </p>
        <Link
          className={`mt-6 w-fit rounded bg-primary-blue-dark p-4 text-2xl text-white hover:bg-primary-blue-light  hover:text-black`}
          to="/account/signup"
        >
          Get started for free
        </Link>
      </div>
      <div className="right lg:w-1/2">
        <div className="h-[375px] w-full md:w-[620px] lg:w-full">
          <img
            src={callToAction}
            alt="call-to-action"
            className="h-full w-full rounded object-cover md:rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
