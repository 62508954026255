import React from "react";
import { LaCaretDown, LaCaretUp } from "../../svg-icons";
import locationData from "../../../assets/data/locationData";
import useOnClickOutside from "../../../hooks/useClickedOutside";
import { useAppSelector } from "../../../redux/app/hooks";
import Input from "../input/Input";
import SelectDropdown from "../select-dropdown/SelectDropdown";
import { RootState } from "../../../redux/app/store";
import {
  UseFormRegister,
  UseFormRegisterReturn,
  UseFormSetValue,
} from "react-hook-form";
import { cn } from "../../../utils/cn";

interface RegisteredSelectProps {
  name: string;
  id: string;
  placeholder: string;
  value?: string;
  error?: string;
  setSelectedId?: (id: string) => void;
  fit?: boolean;
  options?: any[];
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  required: string;
}

function RegisteredSelect({
  name,
  register,
  placeholder,
  setValue,
  fit,
  options = locationData,
  setSelectedId,
  error,
  value,
  required,
}: RegisteredSelectProps) {
  const [flip, setFlip] = React.useState(false);
  const clickedEl = React.useRef(null);

  useOnClickOutside(clickedEl, () => setFlip(false));
  function handleClick() {
    setFlip(!flip);
  }

  const role = useAppSelector((state: RootState) => state.auth.role);

  function handleSelect(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const target = e.target as HTMLElement;
    setSelectedId && setSelectedId(e.currentTarget.dataset.id || "");
    setValue(name, target?.textContent || "", { shouldValidate: true });
  }

  return (
    <fieldset
      className="relative cursor-pointer"
      onClick={handleClick}
      ref={clickedEl}
    >
      <Input
        role={role}
        type="text"
        register={register}
        {...register(name, { required: required })}
        placeholder={placeholder}
        readOnly
        aria-label="select"
        defaultValue={value}
        className={cn(
          "cursor-pointer border border-grey-light px-4 py-3 capitalize",
          {
            "error border-red focus:border-4 focus:border-red focus:border-opacity-30":
              error,
          }
        )}
      />

      {flip ? (
        <span className="absolute right-3 top-[45%]">
          <LaCaretUp stroke="#1A1A1A" />
        </span>
      ) : (
        <span className="absolute right-3 top-[45%]">
          <LaCaretDown stroke="#1A1A1A" />
        </span>
      )}
      <div
        className={`${
          !flip && "hidden"
        } modern__scroll-bar absolute z-30 mt-2 ${
          fit === false && "max-h-[200px]"
        } w-full overflow-y-scroll rounded border border-grey-light bg-white`}
      >
        {options.map((option: any, i: number) => {
          return (
            <SelectDropdown
              role={role}
              isActive={value === option}
              key={`${i}` + option?.toString()}
              onClick={handleSelect}
              data-id={option?._id}
              type="button"
              className="w-full text-left"
            >
              {option.name ? option.name : option}
            </SelectDropdown>
          );
        })}
      </div>
    </fieldset>
  );
}

export default RegisteredSelect;
