import React from "react";
import InfoCard from "./InfoCard";
import { useGetTournamentPostsQuery } from "../../redux/services/postService";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { useInView } from "react-intersection-observer";
import { setSentPost } from "../../redux/features/post/postSlice";
import { ButtonLink, Spinner } from "../ui";
import FeedCard from "../ui/cards/FeedCard";
import TournamentMatches from "./TournamentMatches";
import TournamentTeamsTab from "./TournamentTeamsTab";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { cn } from "../../utils/cn";
import ExtrasTab from "./ExtrasTab";
import GroupStageTab from "../tabs/GroupStageTab";
import GroupStageTournamentMatches from "./GroupStageTournamentMatches";
import TournamentPlayers from "./TournamentPlayers";
import UserPosts from "../user-profiles/SpotlightGrid";
import TournamentSpotlightGrid from "./TournamentSpotlightsGrid";

type Props = {
  tournament?: Tournament;
  fixtures: Fixture;
  tournamentLoading?: boolean;
  isTournamentOwner?: boolean;
};
function TournamentTab({
  tournament,
  tournamentLoading,
  fixtures,
  isTournamentOwner,
}: Props) {
  const { tournamentId } = useParams();
  const [tabParams] = useSearchParams();
  const activeTab = tabParams.get("tab") || ("info" as TTournamentTabs);

  const myId = useAppSelector((state: RootState) => state.auth._id);
  const role = useAppSelector((state: RootState) => state.auth.role);

  return (
    <div className="">
      <div
        className={`grid ${
          isTournamentOwner ? "grid-cols-6" : "grid-cols-5"
        }  border-b border-grey-light text-center lg:flex lg:gap-6`}
      >
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=info`}
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "info",
            "text-primary-black-darker": activeTab !== "info",
          })}
        >
          Info
        </ButtonLink>

        {/* <ButtonLink
          to={`/competitions/${tournamentId}?tab=posts`}
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "posts",
            "text-primary-black-darker": activeTab !== "posts",
          })}
        >
          Posts
        </ButtonLink> */}
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=teams`}
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "teams",
            "text-primary-black-darker": activeTab !== "teams",
          })}
        >
          Teams
          <sup className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-blue-lighter/50 text-center">
            {tournament?.teams?.length ?? 0}
          </sup>
        </ButtonLink>
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=matches`}
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "matches",
            "text-primary-black-darker": activeTab !== "matches",
          })}
        >
          Matches
        </ButtonLink>
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=players`}
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "players",
            "text-primary-black-darker": activeTab !== "players",
          })}
        >
          Players
        </ButtonLink>
        <ButtonLink
          to={`/competitions/${tournamentId}?tab=posts`}
          intent="secondary"
          role={role}
          className={cn("rounded-none border-0 py-4 hover:bg-white", {
            "border-b-2": activeTab === "posts",
            "text-primary-black-darker": activeTab !== "posts",
          })}
        >
          Posts
        </ButtonLink>
        {isTournamentOwner && (
          <ButtonLink
            to={`/competitions/${tournamentId}?tab=extras`}
            intent="secondary"
            role={role}
            className={cn("rounded-none border-0 py-4 hover:bg-white", {
              "border-b-2": activeTab === "extras",
              "text-primary-black-darker": activeTab !== "extras",
            })}
          >
            Extras
          </ButtonLink>
        )}
      </div>

      {activeTab === "posts" && (
        <TournamentSpotlightGrid
          tournamentId={tournamentId ?? ""}
          isPrivate={isTournamentOwner ?? false}
        />
      )}
      {activeTab === "teams" && (
        <TournamentTeamsTab
          tournament={tournament}
          tournamentLoading={tournamentLoading}
        />
      )}
      {activeTab === "matches" && (
        <>
          {fixtures[0] === "season" && (
            <TournamentMatches
              tournament={tournament}
              format={fixtures[0]}
              seasonFixtures={fixtures[1]}
            />
          )}
          {fixtures[0] === "knockout" && (
            <TournamentMatches
              tournament={tournament}
              format={fixtures[0]}
              knockoutFixtures={fixtures[1]}
            />
          )}
          {fixtures[0] === "Group stage + Knockouts" && tournament && (
            <GroupStageTournamentMatches
              fixtures={fixtures}
              author={tournament.author}
              tournament={tournament}
            />
          )}
        </>
      )}
      {activeTab === "info" && <InfoCard tournament={tournament} />}
      {activeTab === "players" && tournament && (
        <TournamentPlayers tournamentId={tournament._id} />
      )}
      {activeTab === "extras" && isTournamentOwner && (
        <ExtrasTab tournamentId={tournamentId} />
      )}
    </div>
  );
}

export default TournamentTab;
