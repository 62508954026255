import { FormSteps } from "../ui";

interface TournamentPaginationProps {
  currentStep: number;
  formSteps: string[];
}
function TournamentPagination({
  currentStep,
  formSteps,
}: TournamentPaginationProps) {
  return (
    <div className="flex flex-col px-4">
      <div className="mt-8 flex items-center">
        {formSteps.map((step, index) => (
          <FormSteps
            key={index}
            currentStep={currentStep}
            hasNext={index < formSteps.length - 1}
            index={index + 1}
            step={step}
          />
        ))}
      </div>
      <div className="flex">
        {formSteps.map((step, index) => (
          <div
            key={index}
            className={`${
              index > 0 && "sm:ml-[2.5rem] lg:ml-[5.8rem]"
            } text-center text-sm md:text-base ${
              currentStep >= index + 1
                ? "text-primary-black-darker"
                : "text-grey"
            }`}
          >
            {step}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TournamentPagination;
