import { Link } from "react-router-dom";
import { ScoreDot } from "../ui";

type Props = {
  matches: [KnockoutMatch, KnockoutMatch];
};

function SecondRound({ matches }: Props) {
  const [matchA, matchB] = matches;
  const [teamA, teamB] = matchA.teams;
  const [teamC, teamD] = matchB.teams;
  const teamAData = matchA?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamA?._id
  );
  const teamBData = matchA?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamB?._id
  );
  const teamCData = matchB?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamC?._id
  );
  const teamDData = matchB?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamD?._id
  );

  console.log({ matches });

  return (
    <div className="flex items-center">
      <div className="flex flex-col items-start gap-20">
        <div className="flex items-center">
          <div className="h-1 w-10 border-l-0 border-t border-dashed border-primary-black-darker" />
          <div className="w-full min-w-[200px] rounded border border-grey-light bg-white px-2 py-1 shadow">
            {teamA ? (
              <div className="flex items-center justify-between border-b border-grey-light">
                <Link
                  to={`/profiles/team/${teamA?.name?.replace(/\s/, "-")}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={teamA?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamA?.name}</p>
                </Link>
                <ScoreDot
                  teamAScore={teamAData?.score}
                  teamBScore={teamBData?.score}
                  teamAPenalties={teamAData?.penalties}
                  teamBPenalties={teamBData?.penalties}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 border-b border-grey-light py-1">
                <div className="rounded-full bg-grey p-2"></div>
                <div className="flex items-center">
                  <div className="w-4 border-2 border-t border-grey"></div>
                </div>
              </div>
            )}
            {teamB ? (
              <div className="flex items-center justify-between">
                <Link
                  to={`/profiles/team/${teamB?.name?.replace(/\s/, "-")}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={teamB?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamB?.name}</p>
                </Link>
                <ScoreDot
                  teamAScore={teamBData?.score}
                  teamBScore={teamAData?.score}
                  teamAPenalties={teamBData?.penalties}
                  teamBPenalties={teamAData?.penalties}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 py-1">
                <div className="rounded-full bg-grey p-2"></div>
                <div className="flex items-center">
                  <div className="w-4 border-2 border-t border-grey"></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center ">
          <div className="h-1 w-10 border-l-0 border-t border-dashed border-primary-black-darker" />
          <div className="w-full min-w-[200px] rounded border border-grey-light bg-white px-2 py-1 shadow">
            {teamC ? (
              <div className="flex items-center justify-between border-b border-grey-light">
                <Link
                  to={`/profiles/team/${teamC?.name?.replace(/\s/, "-")}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={teamC?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamC?.name}</p>
                </Link>

                <ScoreDot
                  teamAScore={teamCData?.score}
                  teamBScore={teamDData?.score}
                  teamAPenalties={teamCData?.penalties}
                  teamBPenalties={teamDData?.penalties}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 border-b border-grey-light py-1">
                <div className="rounded-full bg-grey p-2"></div>
                <div className="flex items-center">
                  <div className="w-4 border-2 border-t border-grey"></div>
                </div>
              </div>
            )}
            {teamD ? (
              <div className="flex items-center justify-between">
                <Link
                  to={`/profiles/team/${teamD?.name?.replace(/\s/, "-")}`}
                  className="flex items-center gap-2"
                >
                  <img
                    src={teamD?.avatar}
                    alt=""
                    className="h-4 w-4 rounded-full"
                  />
                  <p className="uppercase">{teamD?.name}</p>
                </Link>
                <ScoreDot
                  teamAScore={teamDData?.score}
                  teamBScore={teamCData?.score}
                  teamAPenalties={teamDData?.penalties}
                  teamBPenalties={teamCData?.penalties}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2 py-1">
                <div className="rounded-full bg-grey p-2"></div>
                <div className="flex items-center">
                  <div className="w-4 border-2 border-t border-grey"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[136px] w-10 border border-l-0 border-dashed border-primary-black-darker" />
    </div>
  );
}

export default SecondRound;
