import { useLocation, useParams } from "react-router-dom";
import { useGetTournamentSpotlightQuery } from "../../redux/services/spotLightService";
import { MediaCardSkeleton } from "../../components/ui/cards/MediaCards";
import SpotLightFeedsCard from "../../components/spotlight/SpotLightFeedsCard";
import { useEffect, useRef } from "react";
export default function TournamentSpotlight() {
  const { tournamentId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get("index");
  const { data, isLoading } = useGetTournamentSpotlightQuery({
    tournamentId: tournamentId as string,
  });
  const spotlightRefs = useRef<(HTMLDivElement | null)[]>([]);
  useEffect(() => {
    if (index && spotlightRefs.current[parseInt(index)]) {
      spotlightRefs.current[parseInt(index)]?.scrollIntoView({
        behavior: "smooth",
        inline: "end",
      });
    }
  }, [index, data]);
  return (
    <div>
      <section className="flex snap-y snap-mandatory flex-col gap-1 overflow-y-scroll pb-20 lg:mt-10 lg:gap-4 lg:px-8">
        {isLoading &&
          Array.from(
            Array(8).map((_, idx) => (
              <div key={idx} className="w-full lg:w-[50%]">
                <MediaCardSkeleton />
              </div>
            ))
          )}
        {/* Spotlights */}
        {data?.spotlights.map((spotlight: SpotLight, idx: number) => (
          <div
            key={`${spotlight._id}_${idx}`}
            ref={(el) => (spotlightRefs.current[idx] = el)}
            className="h-[calc(100dvh-80px)] w-full snap-start bg-black lg:w-[50%] lg:rounded-3xl"
          >
            <SpotLightFeedsCard data={spotlight} />
          </div>
        ))}
      </section>
    </div>
  );
}
