import {
  useFinalizeAccountMutation,
  useResendFinalizedAcctTokenMutation,
  useVerifyTokenQuery,
} from "../../redux/services/authService";
import React from "react";
import { validatePassword } from "../../utils/validatePassword";
import { useAppDispatch } from "../../redux/app/hooks";
import { setNotification } from "../../redux/features/notification/notificationSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Password, Spinner } from "../../components/ui";
import { setToastSuccess } from "../../redux/features/modal/displayModalSlice";
import ResendResetLink from "../../components/accounts/ResendResetLink";
import HelmetComponent from "../../components/HelmetComponent";

interface FormElements extends HTMLFormControlsCollection {
  newPassword: HTMLInputElement;
}

export default function FinalizeAccount() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const [tokenState, setTokenState] = React.useState<{
    expired: boolean;
    userId?: string;
    message: string;
  }>({
    expired: false,
    userId: undefined,
    message: "",
  });
  const [finalizeAccount] = useFinalizeAccountMutation();
  const [resendAcctToken] = useResendFinalizedAcctTokenMutation();
  const { data: isTokenExpred, isLoading: fetchingToken } = useVerifyTokenQuery({
    token: resetToken,
  });
  const isExpired = isTokenExpred?.isTokenExpred ?? false;

  const [inputError, setInputError] = React.useState<
    InputError<{ error: string; password: string }>
  >({
    error: "",
    password: "",
    clearError: function (value) {
      setInputError((prev) => ({ ...prev, [value]: "" }));
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const elements = (e.target as HTMLFormElement).elements as FormElements;
    if (!validatePassword(elements?.newPassword?.value)) {
      setInputError((prev) => ({ ...prev, password: "weak" }));
      return;
    }

    finalizeAccount({
      resetToken,
      data: { password: elements.newPassword.value },
    })
      .unwrap()
      .then(() => {
        dispatch(setNotification("Complete Profile"));
        navigate("/account/login");
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 400) {
          return setInputError((prev) => ({
            ...prev,
            password: "Invalid Password",
          }));
        }
        if (err.status === 498) {
          // Token expired
          setTokenState({
            expired: true,
            userId: err?.data?.userId,
            message: err?.data?.message,
          });
          return setInputError((prev) => ({
            ...prev,
            error: err?.data?.message,
          }));
        }
        if (err.status === 401) {
          return setInputError((prev) => ({
            ...prev,
            error: "Invalid or Expired Reset Link",
          }));
        }
      });
  }

  function handleExpiredToken() {
    resendAcctToken({ token: resetToken })
      .unwrap()
      .then((res) => {
        dispatch(setToastSuccess(true));
        setTokenState((prev) => ({
          ...prev,
          expired: false,
          message: res?.message,
        }));
        console.log(res);
      })
      .catch((err) => {
        console.log("error");
        console.log(err);
      });
  }

  // open graph data
  const ogData = {
    title: "Leagues Arena - Finalize Account",
    description: "Finalize your account on Leagues Arena",
    url: `https://${window.location.host}/account/final-profile/${resetToken}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      {fetchingToken ? (
        <div className="flex min-h-screen items-center justify-center">
          <Spinner />
        </div>
      ) : isExpired ? (
        <ResendResetLink />
      ) : (
        <main className="flex min-h-screen items-center justify-center bg-gradient-to-b from-[#00000033] to-white px-4">
          <div className="relative flex flex-col items-center rounded-xl bg-white p-4 shadow lg:w-[50%] lg:p-6 lg:px-20 lg:py-10">
            <h3 className="mt-3 text-center  text-xl font-bold capitalize lg:text-4xl">
              Create new password
            </h3>
            {/* <p className="mt-2 text-center text-grey"> */}
            {/*   Your newly generated password should be to log into your new */}
            {/*   account */}
            {/* </p> */}

            {!tokenState.expired && tokenState.message && (
              <div className="mt-3 w-full rounded-md bg-primary-blue-lighter p-4 text-center">
                <p className="text-lg font-medium text-primary-blue-darker">
                  {tokenState?.message || "Success"}
                </p>
              </div>
            )}

            <form action="" className="mt-12 w-full" onSubmit={handleSubmit}>
              <fieldset>
                <Password label="New password" error={inputError} name="newPassword" />
                {inputError?.error && (
                  <p className="mt-2 text-sm text-red">{inputError.error}</p>
                )}
                <p
                  className={`mt-2 text-xs font-medium lg:text-sm ${
                    inputError?.password === "weak" && "text-red"
                  }`}
                >
                  Minimum of 8 characters, with upper, lowercase, a number and a symbol
                </p>
              </fieldset>
              <fieldset className="mt-9 flex flex-col items-center">
                <button
                  disabled={tokenState.expired}
                  className="w-full rounded bg-primary-blue-dark py-4 font-medium text-white disabled:bg-gray-500 lg:text-2xl"
                >
                  Setup Profile
                </button>
              </fieldset>
            </form>
            {tokenState.expired && (
              <button
                onClick={handleExpiredToken}
                className="mt-5 w-full rounded border border-primary-blue-dark bg-primary-blue-lighter py-4 font-medium text-primary-blue-dark lg:text-2xl"
              >
                Resend Token
              </button>
            )}
          </div>
        </main>
      )}
    </>
  );
}
