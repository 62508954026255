import { useFetchNotificationsQuery } from "../../redux/services/notificationServices";
import { UpdateSkeleton } from "../../components/ui/skeletons";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { setPing, setSignals } from "../../redux/features/socket/socketSlice";
import { RootState } from "../../redux/app/store";
import React from "react";
import { ActionNotification, PopNotification } from "../../components/updates";
import EmptyState from "../../components/ui/EmptyState";
import HelmetComponent from "../../components/HelmetComponent";

export default function Updates() {
  const dispatch = useAppDispatch();
  const {
    data = [],
    isLoading,
    isFetching,
  } = useFetchNotificationsQuery("", {
    refetchOnMountOrArgChange: true,
  });
  const ping = useAppSelector((state: RootState) => state.wsNotification.ping);
  const received = useAppSelector((state: RootState) => state.wsNotification.received);
  const emitted = useAppSelector((state: RootState) => state.wsNotification.emitted);

  React.useEffect(() => {
    if (ping && !received) {
      dispatch(setPing(false));
      dispatch(
        setSignals({
          emitted: emitted,
          received,
          count: 0,
        })
      );
    }
    ping && !received && dispatch(setPing(false));
  }, [ping, received, dispatch]);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Notifications",
    description: "Discover the latest notifications on Leagues Arena",
    url: `https://${window.location.host}/notifications`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10 lg:pb-32">
        <section className="w-full p-4 pb-[90px] lg:max-w-[87%]">
          <h3 className="mb-4 text-xl font-bold capitalize lg:mt-14 lg:text-2xl">
            Notifications
          </h3>
          <div className="flex flex-col gap-4">
            {isLoading || isFetching ? (
              <>
                <UpdateSkeleton />
                <UpdateSkeleton />
                <UpdateSkeleton />
                <UpdateSkeleton />
                <UpdateSkeleton />
              </>
            ) : (
              <>
                {data.map((notificationResponse) => {
                  if (notificationResponse.type.includes("join"))
                    /** Notification Card with out a link or interactive action */
                    return (
                      <ActionNotification
                        notificationResponse={notificationResponse}
                        key={notificationResponse._id}
                      />
                    );
                  /** Notification Card with interactivity/link */
                  return (
                    <PopNotification
                      notificationResponse={notificationResponse}
                      key={notificationResponse._id}
                    />
                  );
                })}
                {data.length === 0 && <EmptyState />}
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
