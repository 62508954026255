import * as React from "react";

function LaNumberThree(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 29 38" fill="none" {...props}>
      <path
        d="M10.832 16.464h3.141c1.496 0 2.605-.374 3.326-1.122.72-.748 1.08-1.74 1.08-2.978 0-1.197-.36-2.129-1.08-2.795-.708-.666-1.687-1-2.938-1-1.129 0-2.074.313-2.835.939-.762.612-1.143 1.414-1.143 2.407H4.488c0-1.55.415-2.938 1.244-4.162.843-1.237 2.013-2.203 3.509-2.896 1.51-.694 3.168-1.04 4.977-1.04 3.142 0 5.603.754 7.385 2.264 1.781 1.496 2.672 3.563 2.672 6.201 0 1.36-.415 2.611-1.244 3.754-.83 1.142-1.918 2.02-3.264 2.631 1.673.598 2.917 1.496 3.733 2.693.83 1.197 1.244 2.61 1.244 4.243 0 2.638-.965 4.753-2.896 6.344-1.918 1.591-4.461 2.387-7.63 2.387-2.964 0-5.392-.782-7.282-2.346-1.877-1.564-2.815-3.631-2.815-6.201h5.895c0 1.115.415 2.026 1.244 2.733.844.707 1.877 1.06 3.101 1.06 1.4 0 2.496-.366 3.284-1.1.803-.749 1.204-1.735 1.204-2.959 0-2.964-1.632-4.447-4.896-4.447h-3.121v-4.61z"
        fill="#FFD5AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.517 15.397h-3.03a3.481 3.481 0 01-3.48-3.481c0-2.211.603-4.285 1.843-6.115l.005-.007C4.087 3.985 5.769 2.63 7.776 1.7l.011-.005C9.792.774 11.953.336 14.218.336c3.677 0 7.032.886 9.63 3.085 2.676 2.25 3.909 5.352 3.909 8.862 0 2.124-.665 4.086-1.91 5.8-.08.11-.162.22-.246.327.274.306.53.631.768.978 1.276 1.846 1.857 3.968 1.857 6.216 0 3.611-1.38 6.735-4.16 9.027-2.695 2.235-6.095 3.185-9.848 3.185-3.6 0-6.864-.963-9.502-3.145l-.01-.008C1.99 32.4.64 29.33.64 25.788a3.481 3.481 0 013.482-3.482h3.453a3.474 3.474 0 01-.223-1.23v-4.611c0-.372.058-.731.166-1.068zm5.757 9.16c.144.382.224.797.224 1.23 0 .03 0 .052.002.068a.474.474 0 00.01.008c.145.119.361.237.851.237.402 0 .64-.053.765-.094a.394.394 0 00.145-.072m-1.997-1.377h.68c.864 0 1.217.149 1.313.2l.008.02c.032.088.092.314.092.745 0 .23-.036.327-.044.346a.102.102 0 01-.011.021.296.296 0 01-.04.045m6.576 6.014c1.93-1.591 2.896-3.706 2.896-6.344 0-1.632-.414-3.046-1.244-4.243a6.054 6.054 0 00-.438-.567c-.8-.917-1.898-1.626-3.295-2.126 1.26-.572 2.292-1.377 3.1-2.413l.164-.218c.83-1.143 1.244-2.394 1.244-3.754 0-2.638-.89-4.705-2.672-6.201-1.782-1.51-4.243-2.265-7.385-2.265-1.808 0-3.468.347-4.977 1.04-1.496.694-2.666 1.66-3.509 2.897a7.31 7.31 0 00-.41.68c-.556 1.051-.834 2.212-.834 3.482h5.895c0-.993.381-1.795 1.143-2.407.761-.626 1.706-.939 2.835-.939 1.251 0 2.23.334 2.938 1 .72.666 1.08 1.598 1.08 2.795 0 1.237-.36 2.23-1.08 2.978-.721.748-1.83 1.122-3.325 1.122h-3.142v4.61h3.121c3.264 0 4.896 1.483 4.896 4.447 0 1.224-.401 2.21-1.204 2.958-.788.735-1.883 1.102-3.284 1.102-1.224 0-2.257-.354-3.1-1.061-.83-.707-1.245-1.618-1.245-2.733H4.121c0 1.286.235 2.447.705 3.481.47 1.032 1.172 1.939 2.11 2.72 1.89 1.564 4.318 2.346 7.282 2.346 3.169 0 5.712-.796 7.63-2.387zm-8.15-18.965c.088-.272.143-.56.16-.857.093-.038.245-.074.503-.074.278 0 .44.032.52.055.009.054.017.138.017.258 0 .281-.04.428-.061.485a.344.344 0 01-.014.032.264.264 0 01-.01.004c-.11.037-.37.098-.84.098h-.275z"
        fill="#C28044"
      />
    </svg>
  );
}

export default LaNumberThree;
