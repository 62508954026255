import React, { type ReactElement, useRef } from "react";
import { Button } from "../ui";
import formatToNaira from "../../utils/formatPrice";
import { LaClose, LaPlusIcon } from "../svg-icons";
import { useAddPlayersMutation } from "../../redux/services/teamService";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import LaSpinner from "../svg-icons/LaSpinner";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup } from "../modals";

export interface AddPlayersToTeamProps {
  selectedPlayers: string[];
  setSelectedPlayers: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCompetitionForTeamId: string;
  setCompetitionPlayersForTeam: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCompetitionForTeamId: React.Dispatch<React.SetStateAction<string>>;
  selectedCompetitionForTeam: SelectedCompetition | null;
}

export default function AddPlayersToTeam({
  selectedPlayers,
  selectedCompetitionForTeamId,
  setSelectedPlayers,
  setCompetitionPlayersForTeam,
  setSelectedCompetitionForTeamId,
  selectedCompetitionForTeam,
}: AddPlayersToTeamProps): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const price = selectedPlayers.length * 800;
  const ref = useRef(null);
  const teamProfile = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "team");
  const teamId = teamProfile?.profile;
  const teamName = teamProfile?.name;
  const [addPlayers, { isLoading }] = useAddPlayersMutation();
  const [modalToDisplay, setModalToDisplay] = React.useState<
    "incomplete" | "success" | ""
  >("");

  const noOfPlayersAdded = selectedCompetitionForTeam?.team.players.length ?? 0;
  const requiredPlayers = selectedCompetitionForTeam?.noOfPlayers ?? 0;

  function handleClick() {
    if (noOfPlayersAdded + selectedPlayers.length < requiredPlayers)
      return setModalToDisplay("incomplete");
    return handleAddPlayers();
  }

  function handleAddPlayers() {
    addPlayers({
      players: selectedPlayers,
      teamId,
      tournamentId: selectedCompetitionForTeamId,
      paymentStatus: "pending",
    })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setModalToDisplay("success");
      })
      .catch((err: any) => {
        dispatch(setToastErrorText(err.data.message));
      });
  }

  return (
    <>
      <div
        className="fixed bottom-0 right-0 z-[60] flex w-full flex-col rounded border-t border-grey-light bg-white px-4 py-6 shadow lg:w-[82%] lg:rounded-2xl lg:px-20 lg:py-10"
        ref={ref}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <h3 className="flex items-center gap-2 font-medium lg:text-2xl">
              Register
              <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-blue-lighter text-xs">
                {selectedPlayers.length}
              </span>
              players
            </h3>
          </div>
          <button
            onClick={() => {
              setSelectedPlayers([]);
              setCompetitionPlayersForTeam([]);
              setSelectedCompetitionForTeamId("none");
            }}
          >
            <LaClose />
          </button>
        </div>
        <Button
          role="organizer"
          intent={"primary"}
          onClick={handleClick}
          className="mt-4 items-center gap-1 lg:flex"
        >
          Register Players
          {isLoading && (
            <span className="animate-spin">
              <LaSpinner />
            </span>
          )}
        </Button>
      </div>

      {modalToDisplay === "incomplete" && (
        <ConfirmPopup
          heading={"Incomplete players"}
          type="warning"
          description={`${
            noOfPlayersAdded + selectedPlayers.length
          } out of ${requiredPlayers} remaining players will be added. Do you want to proceed?`}
          warning="Players should be complete"
          buttons={[
            {
              text: "Add more teams",
              handleClick: () => setModalToDisplay(""),
              class: "primary",
              icon: <LaPlusIcon stroke="#fff" />,
            },
            {
              text: "Yes, I want to proceed",
              handleClick: () => {
                setModalToDisplay("");
                handleAddPlayers();
              },
              class: "tertiary",
            },
          ]}
        />
      )}
      {modalToDisplay === "success" && (
        <ConfirmPopup
          heading={"Success!"}
          type="success"
          description={`Your Player profiles are ready. They will be notified via contact number you provided.`}
          buttons={[
            {
              text: "Got it",
              handleClick: () => {
                setSelectedPlayers([]);
                setCompetitionPlayersForTeam([]);
                setSelectedCompetitionForTeamId("none");

                navigate(`/competitions/${selectedCompetitionForTeamId}`);
              },
              class: "primary",
            },
          ]}
        />
      )}
    </>
  );
}
