import React from "react";
import { Link } from "react-router-dom";
import { cn } from "../../../utils/cn";

type Ref = HTMLAnchorElement;

interface ILinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  className: string;
  to: string;
  role: string;
}

const TextLink = React.forwardRef<Ref, ILinkProps>(
  ({ className, to, children, role }, ref) => (
    <Link
      to={to}
      className={cn(
        {
          "text-primary-blue-darker": role === "player",
          "text-primary-black-darker": role === "organizer",
          "text-secondary-blue-600": role === "team",
        },
        className
      )}
      ref={ref}
    >
      {children}
    </Link>
  )
);

export default TextLink;
