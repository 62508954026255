import React from "react";
import StyledNavLink from "../ui/navlink/StyledNavLink";
import { LaCheck, LaLogout, LaNavigation, LaPlusIcon } from "../svg-icons";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetMeQuery,
  useUpdateMeMutation,
} from "../../redux/services/userService";
import { capitalize } from "../../utils/capitalize";
import Pills from "../ui/pills/Pills";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import useOnClickOutside from "../../hooks/useClickedOutside";
import NewDropDownButton from "../ui/dropdown-button";
import formatToNaira from "../../utils/formatPrice";
import { useGetWalletQuery } from "../../redux/services/walletService";

function SideNav() {
  const navigate = useNavigate();
  const { data: user } = useGetMeQuery();
  const ping = useAppSelector((state: RootState) => state.wsNotification.ping);
  const incomingCount = useAppSelector(
    (state: RootState) => state.wsNotification.incomingCount
  );
  const { isBoarded, isLoggedIn } = useAppSelector(
    (state: RootState) => state.auth
  );
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;

  const profiles = user?.profiles;
  const [updateUser] = useUpdateMeMutation();
  const { data: wallet } = useGetWalletQuery();
  const dispatch = useAppDispatch();
  const [showProfileDropDown, setShowProfileDropDown] = React.useState(false);
  const profilesEl = React.useRef<HTMLDivElement>(null);

  const currentProfile = profiles?.find((profile) => profile.role === role);
  const avatar = currentProfile?.avatar;
  const name = currentProfile?.name?.toLowerCase()?.replace(/\s/g, "-");

  const names:
    | {
        [key: string]: string;
      }
    | undefined = profiles?.reduce((acc, profile) => {
    const name = profile.name.toLowerCase().replace(/\s/g, "-");
    return { ...acc, [profile.role]: name };
  }, {});

  useOnClickOutside(profilesEl, () => setShowProfileDropDown(false));

  function handleSwitchProfile(role: string) {
    updateUser({ role })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setShowProfileDropDown(false);
        navigate(`/profiles/${role}/${names?.[role]}`);
      })
      .catch((err) => {
        dispatch(setToastError(true));
        console.log(err);
      });
  }

  const profileList = profiles?.map((profile) => {
    return (
      <button
        className="flex items-center justify-between p-3 text-xs hover:bg-[#0000000b]"
        key={profile.role}
        onClick={() => handleSwitchProfile(profile.role)}
        aria-label={`switch-profile-${profile.role}`}
      >
        <span className="flex items-center gap-2">
          <img
            src={profile.avatar}
            alt="avatar"
            className="h-5 w-5 rounded-full"
          />
          <Pills className={`rounded p-1 capitalize`} $userRole={profile.role}>
            {profile.role}
          </Pills>
        </span>
        <span
          className={`flex h-4 w-4 items-center justify-center rounded ${
            profile.role === role && "bg-primary-black-darker"
          }`}
        >
          <span className="text-xs">
            <LaCheck stroke="#fff" />
          </span>
        </span>
      </button>
    );
  });

  return (
    <section className="h__modern__scroll__bar fixed left-0 hidden h-full min-h-screen w-[18%] flex-col overflow-y-scroll border-r border-grey-light lg:flex">
      <div className="flex-1 flex-col justify-start">
        <Link to={"/"} className="block px-6 py-6">
          <img
            src={"/logo.png"}
            alt="leagues arena"
            className="relative z-40 w-[94px] object-contain md:w-[99px]"
          />
        </Link>
        {isLoggedIn && isBoarded && (
          <div className="px-6 py-2 pb-4">
            <div
              className="relative flex cursor-pointer items-center justify-between rounded-lg border border-grey-light px-3 py-4 shadow"
              onClick={() => setShowProfileDropDown(!showProfileDropDown)}
              aria-label="profile-dropdown"
              role="button"
            >
              {showProfileDropDown && (
                <div
                  className="shadow-[0_2px_8px_0_#8C8C8C26 absolute left-0 top-[calc(100%+16px)] z-40 flex w-full flex-col rounded-lg border border-grey-light bg-white text-xs font-medium"
                  ref={profilesEl}
                >
                  {profileList}
                  <Link
                    to="/account/onboarding"
                    className="flex items-center justify-between p-3 hover:bg-[#0000000b]"
                  >
                    <span className="flex items-center gap-2">
                      <span className="flex h-5 w-5 items-center justify-center rounded-full border border-grey">
                        <LaPlusIcon />
                      </span>
                      <p className="">Add new profile</p>
                    </span>
                  </Link>
                  <Link
                    to="/logout"
                    className="flex items-center gap-4 p-3 text-red hover:bg-[#0000000b]"
                  >
                    <span className="flex items-center gap-2">
                      <span className="text-xl">
                        <LaLogout />
                      </span>
                      <span>Logout</span>
                    </span>
                  </Link>
                </div>
              )}
              <div className="flex items-center gap-2">
                <img
                  src={avatar}
                  alt="avatar"
                  className="h-8 w-8 rounded-full bg-grey-light"
                />
                <div>
                  <p className="text-sm font-medium capitalize">Viewing as</p>
                  <div className="flex items-center gap-1 rounded">
                    <p className="text-xs font-medium text-grey">
                      {capitalize(role)}
                    </p>
                  </div>
                </div>
              </div>
              <LaNavigation />
            </div>
          </div>
        )}

        <StyledNavLink
          to="/home"
          className="flex items-center gap-2 px-6 py-2"
          role={role}
          icon={"feed"}
        >
          Home
        </StyledNavLink>

        <StyledNavLink
          to="/discover"
          className="flex items-center gap-2 px-6 py-2 text-base font-medium"
          role={role}
          icon="discover"
        >
          Discover
        </StyledNavLink>
        {isLoggedIn && isBoarded && (
          <>
            <StyledNavLink
              to="/notifications"
              className="flex items-center gap-2 px-6 py-2 text-base font-medium"
              role={role}
              icon="notifications"
            >
              Notifications
              {ping && incomingCount > 0 && (
                <span className="aspect-square h-5 w-5 animate-pulse rounded-full bg-[#E71D36]/10 text-center text-sm text-[#E71D36]">
                  {incomingCount}
                </span>
              )}
            </StyledNavLink>
            {role === "organizer" && (
              <StyledNavLink
                to="/wallet"
                className="relative flex items-center gap-2 px-6 py-2 text-base font-medium"
                role={role}
                icon="wallet"
              >
                Wallet
                <span className="absolute right-6 rounded-full bg-green-light px-3 py-1 text-sm font-medium text-green-dark">
                  {formatToNaira(wallet?.balance ?? 0)}
                </span>
              </StyledNavLink>
            )}
          </>
        )}
        <StyledNavLink
          to={
            isLoggedIn && isBoarded
              ? `/profiles/${role}/${name}`
              : "/account/login"
          }
          className="flex items-center gap-2 px-6 py-2 text-base font-medium"
          role={role}
          icon="profile"
        >
          Profile
        </StyledNavLink>
        {isLoggedIn && isBoarded && <NewDropDownButton />}
      </div>
      <div className="flex flex-col justify-end border-t border-grey-light pb-6 pt-4">
        <StyledNavLink
          to="/faqs"
          className="flex items-center gap-2 px-6 py-2 text-base font-medium"
          role={role}
          icon="help"
        >
          Help
        </StyledNavLink>
        {isLoggedIn && isBoarded && (
          <StyledNavLink
            to="/settings"
            className="flex items-center gap-2 px-6 py-2 text-base font-medium"
            role={role}
            icon="settings"
          >
            Settings
          </StyledNavLink>
        )}
        <StyledNavLink
          to="/feedback"
          className="flex items-center gap-2 px-6 py-2 text-base font-medium"
          role={role}
          icon="feedback"
        >
          Feedback
        </StyledNavLink>

        {(!isLoggedIn || !isBoarded) && (
          <div className="my-6 flex flex-col gap-[14px] px-6">
            <Link
              to={"/account/login"}
              className="block rounded border border-primary-blue-dark py-2 text-center text-base font-medium text-primary-blue-dark hover:bg-primary-blue-lighter "
            >
              Login
            </Link>
            <Link
              to={"/account/signup"}
              className="block rounded bg-primary-blue-dark py-2 text-center text-base font-medium text-white hover:bg-primary-blue-lighter hover:text-primary-blue-darker"
            >
              Signup
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}

export default SideNav;
