export default function reduceFixtures(
  data: {
    round: number;
    matches: KnockoutMatch[];
  }[]
): KnockoutMatch[] {
  return data?.reduce((acc: any, curr) => {
    if (curr.matches) {
      return [...acc, ...curr.matches];
    }
    return [...acc, curr];
  }, []);
}
