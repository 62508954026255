import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { setToastCopied } from "../redux/features/modal/displayModalSlice";

export function copyToClipboard(
  link: string,
  copyToClipboard: (text: string) => Promise<boolean>,
  dispatch: Dispatch<AnyAction>
) {
  copyToClipboard(link).then((hasCopied) => {
    dispatch(setToastCopied(hasCopied));
  });
}
