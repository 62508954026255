import React, { useState } from "react";
import { PlusSquare } from "../../svg-icons";
import useOnClickOutside from "../../../hooks/useClickedOutside";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import Button from "../buttons/Button";
import BorderContainer from "./BorderContainer";
import useHandleActionButton from "../../../hooks/useHandleActionButton";

function NewDropDownButton() {
  const [isDrop, setIsDrop] = useState(false);
  const clickedEl = React.useRef(null);
  const menus: {
    [key: string]: string[];
  } = {
    // organizer: ["create post", "create competition", "create training"],
    organizer: ["create competition", "spotlight"],
    player: ["spotlight"],
    team: ["spotlight"],
  };
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const menu = menus[role] || [];
  useOnClickOutside(clickedEl, () => setIsDrop(false));

  const { handleClick } = useHandleActionButton();

  function handleDrop() {
    setIsDrop(!isDrop);
  }

  const menuList = menu?.map((item, index) => (
    <Button
      role={role}
      intent={"tertiary"}
      className={`block cursor-pointer px-3 py-2 text-left capitalize`}
      key={index}
      onClick={handleClick}
      disabled={item.toLowerCase() === "create training"}
      data-index={index}
      data-item={
        item === "spotlight" && role === "organizer"
          ? "competition_spotlight"
          : item
      }
      type="button"
    >
      {item}
    </Button>
  ));

  return (
    <div className="relative mx-6 my-4 flex flex-col" ref={clickedEl}>
      <Button
        role={role}
        intent="primary"
        leadingIcon={PlusSquare}
        arai-label="create"
        onClick={handleDrop}
        className="py-4"
        disabled={!menuList?.length}
      >
        Create
      </Button>
      {!!menuList?.length && (
        <BorderContainer
          role={role}
          className={`absolute left-0 right-0 top-[calc(100%+14px)] flex min-h-[80px] w-full flex-col overflow-hidden rounded border border-grey-light bg-white text-sm font-medium md:text-base ${
            !isDrop && "hidden"
          } z-40 text-sm lg:text-base`}
        >
          {menuList}
        </BorderContainer>
      )}
    </div>
  );
}

export default NewDropDownButton;
