import { LaClose, LaLogout } from "../../components/svg-icons";
import { useNavigate } from "react-router-dom";
import socket from "../../socket";
import { useAppDispatch } from "../../redux/app/hooks";
import { setIsLoggedin } from "../../redux/features/auth/authSlice";
import HelmetComponent from "../../components/HelmetComponent";

function Logout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function handleLogout() {
    socket.disconnect();
    localStorage.removeItem("token");
    dispatch(setIsLoggedin(false));
    window.location.replace("/home");
  }

  // open graph data
  const ogData = {
    title: "Leagues Arena - Logout",
    description: "Log out of Leagues Arena",
    url: `https://${window.location.host}/logout`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex min-h-screen items-center justify-center bg-gradient-to-b from-[#00000033] to-white px-4">
        <div className="relative rounded-xl bg-white p-4 shadow lg:p-6">
          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-red-light-600">
            <LaLogout />
          </div>
          <h3 className="mt-3 text-xl">Log out</h3>
          <p className="pr-6 text-grey">
            Are you sure you want to log out your account?
          </p>

          <div className="mt-6 flex flex-col gap-3 lg:grid lg:grid-cols-2 lg:gap-3">
            <button
              className="rounded-lg bg-red py-2 font-medium text-white"
              type="button"
              onClick={handleLogout}
            >
              Log out
            </button>
            <button
              className="rounded-lg border border-grey-light py-2 font-medium"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
          <button
            aria-label="close"
            className="absolute right-4 top-4"
            type="button"
            onClick={() => navigate(-1)}
          >
            <LaClose stroke="#667085" />
          </button>
        </div>
      </div>
    </>
  );
}

export default Logout;
