import React from "react";
import { UseFormRegister } from "react-hook-form";
import { cn } from "../../../utils/cn";

type Ref = HTMLInputElement;
interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  role: string;
  register?: UseFormRegister<any>;
  name: string;
}

const Input = React.forwardRef<Ref, IInputProps>(
  ({ className, role, register, name, ...props }, ref) => {
    return (
      <input
        ref={ref}
        id={name}
        {...(register && {
          ...register(name),
        })}
        {...(!register && {
          name,
        })}
        className={cn(
          "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-opacity-30 lg:text-xl",
          {
            "focus:border-primary-blue-darker": role === "player",
            "focus:border-primary-black-darker": role === "organizer",
            "focus:border-secondary-blue-600": role === "team",
          },
          className
        )}
        {...props}
      />
    );
  }
);

export default Input;
