import { TeamCard } from ".";

type Props = {
  teams: Team[];
  selectedTeams: string[];
  setSelectedTeams: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCompetition: string;
  setSelectedCompetition: React.Dispatch<React.SetStateAction<string>>;
  competitionTeams: Tournament["teams"];
};

export default function TeamCardDeck({
  teams,
  setSelectedTeams,
  selectedTeams,
  setSelectedCompetition,
  selectedCompetition,
  competitionTeams,
}: Props) {
  return (
    <>
      {teams?.map((team, index) => {
        const members = team.members as string[];

        return (
          <TeamCard
            key={`${team._id}-${index}`}
            color={team?.color}
            avatar={team?.avatar}
            draws={team.draws}
            name={team.name}
            wins={team.wins}
            losses={team.losses}
            played={team.played}
            players={team.members.length}
            members={members}
            id={team._id}
            setSelectedTeams={setSelectedTeams}
            selectedTeams={selectedTeams}
            setSelectedCompetition={setSelectedCompetition}
            selectedCompetition={selectedCompetition}
            competitionTeams={competitionTeams}
          />
        );
      })}
    </>
  );
}
