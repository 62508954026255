import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../ui";
import { InfoCard } from "../tournament";
import {
  useGetTournamentQuery,
  useRegisterForTournamentMutation,
} from "../../redux/services/tournamentService";
import formatToNaira from "../../utils/formatPrice";
import { useGetTeamByNameQuery } from "../../redux/services/teamService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useState } from "react";
import { ConfirmPopup } from "../modals";

export default function SplashTeam() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const team = searchParams.get("team") || "";
  const tournamentId = searchParams.get("tournamentId") || "";
  const code = searchParams.get("code") || "";
  const [dispayModal, setDisplayModal] = useState(false);
  const { data } = useGetTournamentQuery({ id: tournamentId });
  const [registerForTournament] = useRegisterForTournamentMutation();
  const { data: myTeam } = useGetTeamByNameQuery(
    {
      name: team.replace("-", " "),
    },
    {
      skip: !team,
    }
  );

  function handleAccept() {
    if (!myTeam) {
      return dispatch(setToastErrorText("Team not found"));
    }
    if (!data?.registrationFee) {
      return registerForTournament({
        teamId: myTeam?._id,
        tournamentId: tournamentId as string,
      })
        .unwrap()
        .then(() => {
          setDisplayModal(true);
          dispatch(setToastSuccess(true));
        })
        .catch((err) => {
          dispatch(setToastError(true));
          console.log(err);
        });
    }
    data?.registrationFee &&
      navigate(
        `/payment?tournamentId=${tournamentId}&team=${team}&code=${code}`
      );
  }

  return (
    <>
      <div className="flex h-full w-full flex-col items-center px-4">
        <div className="flex max-w-lg flex-col gap-2 pb-20">
          <h2 className="text-3xl capitalize lg:text-[40px]">
            {team.replace(/-/g, " ")}
          </h2>
          <p className="my-2 font-medium">
            🎉🎉 Congratulations, you have been invited to{" "}
            <span className="font-bold capitalize">
              {data?.name}! competition
            </span>
          </p>

          <p className="mt-2 text-xl lg:text-2xl">
            {data?.registrationFee
              ? "You are required to pay a registration fee for your team to proceed."
              : "Registration is free"}
          </p>

          <InfoCard tournament={data} />

          <Button
            intent={"primary"}
            role={"team"}
            className="mt-4 w-full"
            onClick={handleAccept}
          >
            Register for {formatToNaira(data?.registrationFee || 0)}
          </Button>
        </div>
      </div>
      {dispayModal && (
        <ConfirmPopup
          heading="Payment is pending verification"
          description="You have registered for the competition. Add players or proceed to competition"
          type="warning"
          buttons={[
            {
              class: "primary",
              text: "Add players",
              role: "team",
              handleClick() {
                code
                  ? navigate(`/account/final-profile/${code}`)
                  : navigate(`/discover/players`);
                setDisplayModal(false);
              },
            },

            {
              class: "secondary",
              role: "team",
              handleClick() {
                code
                  ? navigate(`/account/final-profile/${code}`)
                  : navigate(`/competitions/${tournamentId}`);
                setDisplayModal(false);
              },
              text: "Proceed to competition",
            },
          ]}
        />
      )}
    </>
  );
}
