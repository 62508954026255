function LaCalendar({ stroke = "#1A1A1A" }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
      <g strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M8 2v3M16 2v3"
          stroke={stroke}
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M3.5 9.09h17M21 8.5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5z"
          stroke={stroke}
          strokeWidth={1.5}
          strokeMiterlimit={10}
        />
        <path
          d="M15.695 13.7h.009M15.695 16.7h.009M11.995 13.7h.01M11.995 16.7h.01M8.294 13.7h.01M8.294 16.7h.01"
          stroke={stroke}
          strokeWidth={2}
        />
      </g>
    </svg>
  );
}

export default LaCalendar;
// "#292D32"
