import { Link } from "react-router-dom";
import EmptyStateAuto from "../ui/EmptyStateAuto";

type Props = {
  teams?: Tournament["teams"];
};

function MatchTable({ teams }: Props) {
  const tableBody = teams?.map((teamData, idx) => {
    const points = teamData?.wins * 3 + teamData?.draws;
    const team = teamData?.team as Team;

    return (
      <div
        className="grid grid-cols-2  rounded border-b border-grey-light px-4 py-3"
        key={idx}
      >
        <Link
          to={`/profiles/team/${team?.name?.replace(/\s/g, "-")}`}
          className="flex items-center gap-2 lg:gap-3"
        >
          <p className="font-bold">{idx + 1}</p>
          <img src={team?.avatar} alt="" className="h-6 w-6 rounded-full " />
          <p className="max-w-[100px] truncate text-sm font-medium lg:max-w-none">
            {team?.name}
          </p>
        </Link>

        <div className="grid grid-cols-4 rounded text-center text-sm font-medium">
          <p>{points}</p>
          <p>{teamData?.wins}</p>
          <p>{teamData?.losses}</p>
          <p>{teamData?.draws}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="p-4 lg:px-0">
      <div className="rounded border border-grey-light">
        {/* T-Head */}
        <div className="grid grid-cols-2 rounded bg-grey-lighter px-4 py-3">
          <div>
            <h3 className="text-sm font-medium">Teams</h3>
          </div>
          <ul className="grid grid-cols-4 rounded text-center text-sm font-medium">
            <li>PTS</li>
            <li>W</li>
            <li>L</li>
            <li>D</li>
          </ul>
        </div>
        {/* T-Body */}
        {tableBody}
      </div>
      {tableBody?.length === 0 && (
        <EmptyStateAuto
          title="Nothing to see here"
          description="This tournament does not have any table yet. when it does it'll appear here"
        />
      )}
    </div>
  );
}

export default MatchTable;
