const otherFeatures = [
  {
    title: "Discover",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: true,
  },
  {
    title: "Discover Ongoing Competitions",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Discover players",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Discover teams",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Tournament",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: true,
  },
  {
    title: "Knockout",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Single fixture",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Contact address",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Location",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Venue",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Contact mail",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Contact phone no",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Completion guide",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Season",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Private competition",
    isFree: false,
    isPro: true,
    isEnterprise: true,
  },
  {
    title: "Infographic scorecards",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Download image on post",
    isFree: false,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Home & Away",
    isFree: false,
    isPro: false,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Matches Feed",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: true,
  },
  {
    title: "Fixtures",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Bracket",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Completed Matches",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Table",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Upcoming matches",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "View match calendar & History ",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Account",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: true,
  },
  {
    title: "In app notification",
    isFree: true,
    isPro: true,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Dedicated account manager",
    isFree: false,
    isPro: false,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "White-labelling & branding options",
    isFree: false,
    isPro: false,
    isEnterprise: true,
    isCategory: false,
  },
  {
    title: "Custom integration & API access",
    isFree: false,
    isPro: false,
    isEnterprise: true,
    isCategory: false,
  },
];

export default otherFeatures;
