import * as React from "react";

function LaLink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={20} height={20} fill="none" {...props}>
      <g
        stroke={props.stroke || "#0045f6"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.883 9.117a4.783 4.783 0 010 6.775c-1.875 1.867-4.908 1.875-6.775 0-1.866-1.875-1.875-4.908 0-6.775" />
        <path d="M8.825 11.173a5.01 5.01 0 010-7.075c1.95-1.958 5.117-1.95 7.075 0 1.958 1.95 1.95 5.117 0 7.075" />
      </g>
    </svg>
  );
}

export default LaLink;
