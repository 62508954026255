import React from "react";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import BorderButton from "../../components/ui/buttons/BorderButton";
import TextLink from "../../components/ui/text/TextLink";
import HelmetComponent from "../../components/HelmetComponent";

function Feedback() {
  const [selected, setSelected] = React.useState(false);
  const role = useAppSelector((state: RootState) => state.auth.role)?.toLowerCase();
  const [_selectedValue, setSelectedValue] = React.useState("");

  // open graph data
  const ogData = {
    title: "Leagues Arena - Feedback",
    description: "Give feedback on Leagues Arena",
    url: `https://${window.location.host}/feedback`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex w-full flex-col items-center px-4 pb-[120px] pt-[60px] md:w-auto md:px-20 lg:px-[217px]">
        <h1 className="text-[28px] md:text-[40px]">Feedback</h1>
        {selected ? (
          <p className="mb-[24px] mt-6 max-w-[55ch] text-center text-base text-grey md:text-xl">
            Did you accomplish the goal of your visit to our site?
          </p>
        ) : (
          <p className="mb-[24px] mt-6 max-w-[55ch] text-center text-base text-grey md:text-xl">
            How satisfied were you with the part of the Leagues Arena product you were
            using today?
          </p>
        )}

        <div className="flex w-full flex-col items-stretch gap-4">
          {selected ? (
            <>
              <BorderButton
                type="button"
                className="flex cursor-pointer items-center justify-center gap-2 rounded border  p-3 text-sm capitalize  md:text-base"
                $userRole={role}
              >
                yes
              </BorderButton>
              <BorderButton
                type="button"
                className="flex cursor-pointer items-center justify-center gap-2 rounded border  p-3 text-sm capitalize  md:text-base"
                $userRole={role}
              >
                no
              </BorderButton>
            </>
          ) : (
            <>
              <BorderButton
                type="button"
                className="flex cursor-pointer items-center justify-center gap-2 rounded border  p-3 text-sm capitalize  md:text-base"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  setSelectedValue(event.currentTarget.innerText);
                  setSelected(true);
                }}
                $userRole={role}
              >
                very unsatisfied
              </BorderButton>
              <BorderButton
                type="button"
                className="flex cursor-pointer items-center justify-center gap-2 rounded border  p-3 text-sm capitalize  md:text-base"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  setSelectedValue(event.currentTarget.innerText);
                  setSelected(true);
                }}
                $userRole={role}
              >
                unsatisfied
              </BorderButton>
              <BorderButton
                type="button"
                className="flex cursor-pointer items-center justify-center gap-2 rounded border  p-3 text-sm capitalize  md:text-base"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  setSelectedValue(event.currentTarget.innerText);
                  setSelected(true);
                }}
                $userRole={role}
              >
                satisfied
              </BorderButton>
              <BorderButton
                type="button"
                className="flex cursor-pointer items-center justify-center gap-2 rounded border  p-3 text-sm capitalize  md:text-base"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  setSelectedValue(event.currentTarget.innerText);
                  setSelected(true);
                }}
                $userRole={role}
              >
                very satisfied
              </BorderButton>
            </>
          )}
          <TextLink to="/contact" className="w-full text-center " role={role}>
            Contact Support
          </TextLink>
        </div>
      </div>
    </>
  );
}

export default Feedback;
