function SocialBtn(props: {
  icon: JSX.Element;
  text: string;
  handleClick: () => void;
}) {
  return (
    <button
      onClick={props.handleClick}
      aria-label={"social-login"}
      className="flex w-full items-center justify-center gap-4 rounded border border-primary-blue-dark py-4 text-primary-blue-dark hover:bg-primary-blue-lighter"
    >
      <span className="text-2xl">{props.icon}</span>
      <span className="text-base font-medium md:text-2xl">{props.text}</span>
    </button>
  );
}

export default SocialBtn;
