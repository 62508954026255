import { useDispatch } from "react-redux";
import { LaCaretDown, LaClose } from "../svg-icons";
import { setModalToDisplay } from "../../redux/features/modal/displayModalSlice";
import { Link } from "react-router-dom";
import { Button, Spinner } from "../ui";
import React from "react";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { useGetUserTournamentsQuery } from "../../redux/services/tournamentService";
import useOnClickOutside from "../../hooks/useClickedOutside";

export default function SpotLightCompetitionListModal() {
  const dispatch = useDispatch();
  const insideEl = React.useRef(null);

  const { myId } = useAppSelector((state: RootState) => state.auth);
  const [showDropDown, setShowDropDown] = React.useState(false);

  const { data: competitions, isLoading: competitionLoading } =
    useGetUserTournamentsQuery({ userId: myId });

  useOnClickOutside(insideEl, () => dispatch(setModalToDisplay("none")));

  const competitionList = competitions?.map((competition, index) => {
    return (
      <button
        key={index}
        className="rounded border border-grey-light px-4 py-3 capitalize hover:bg-primary-black-lighter"
        onClick={() => {
          localStorage.setItem("tournamentId", competition._id);
          dispatch(setModalToDisplay("spotLight"));
        }}
      >
        <span>{competition?.name}</span>
      </button>
    );
  });
  return (
    <div className="fixed inset-0 left-auto z-[60] flex items-end justify-end bg-black/10 lg:w-[82%]">
      <div
        className="flex w-full flex-col rounded border-t border-grey-light bg-white shadow lg:rounded-[16px_16px_0_0]"
        ref={insideEl}
      >
        <div className="flex items-center justify-between border-b border-grey-light p-4 lg:px-20 lg:py-10">
          <h3 className="font-medium lg:text-2xl">Post Spotlight</h3>
          <button
            onClick={() => {
              dispatch(setModalToDisplay("none"));
            }}
          >
            <LaClose />
          </button>
        </div>
        <div className="p-4 lg:px-20 lg:py-10">
          <p>Choose the competition you will like post a spotlight to</p>
          <div className="my-6 flex flex-col gap-4">
            <Button
              role="organizer"
              intent="tertiary"
              className="flex cursor-pointer items-center justify-between border border-grey-light capitalize"
              onClick={() => setShowDropDown(!showDropDown)}
            >
              Competitions
              <LaCaretDown />
            </Button>
            {showDropDown && (
              <div className="flex max-h-[300px] flex-col gap-2 overflow-y-scroll bg-grey-lighter p-4 lg:p-6">
                {competitionLoading ? (
                  <div className="flex items-center justify-center py-4">
                    <Spinner />
                  </div>
                ) : competitionList?.length ? (
                  competitionList
                ) : (
                  <p className="text-center">No Competitions found</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
