import React from "react";
import { useResetPasswordMutation } from "../../redux/services/authService";
import { validatePassword } from "../../utils/validatePassword";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Password } from "../../components/ui";
import HelmetComponent from "../../components/HelmetComponent";

interface FormElements extends HTMLFormControlsCollection {
  newPassword: HTMLInputElement;
}

function ResetPassword() {
  const navigate = useNavigate();
  const { resetToken } = useParams();

  const [resetPassword] = useResetPasswordMutation();

  const [inputError, setInputError] = React.useState<
    InputError<{ error: string; password: string }>
  >({
    error: "",
    password: "",
    clearError: function (value) {
      setInputError((prev) => ({ ...prev, [value]: "" }));
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const elements = (e.target as HTMLFormElement).elements as FormElements;
    if (!validatePassword(elements?.newPassword?.value)) {
      setInputError((prev) => ({ ...prev, password: "weak" }));
      return;
    }
    console.log("res");

    resetPassword({
      resetToken,
      data: { password: elements.newPassword.value },
    })
      .unwrap()
      .then(() => {
        navigate("/account/login");
      })
      .catch((err) => {
        if (err.status === 401) {
          return setInputError((prev) => ({
            ...prev,
            error: "Invalid or Expired Reset Link",
          }));
        }
        if (err.status === 400) {
          return setInputError((prev) => ({
            ...prev,
            password: "Invalid Password",
          }));
        }
      });
  }

  // open graph data

  const ogData = {
    title: "Leagues Arena - Reset Password",
    description: "Reset your password on Leagues Arena",
    url: `https://${window.location.host}/account/reset-password/${resetToken}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex min-h-screen items-center justify-center bg-gradient-to-b from-[#00000033] to-white px-4">
        <div className="relative flex flex-col items-center rounded-xl bg-white p-4 shadow lg:w-[50%] lg:p-6 lg:px-20 lg:py-10">
          <h3 className="mt-3 text-center  text-xl font-bold lg:text-4xl">
            Reset password?
          </h3>
          <p className="mt-2 text-center text-grey">
            Your new password must be different from previously used passwords
          </p>

          <form action="" className="mt-14 w-full" onSubmit={handleSubmit}>
            <fieldset>
              <Password label="New password" error={inputError} name="newPassword" />
              {inputError?.error && (
                <p className="mt-2 text-sm text-red">{inputError.error}</p>
              )}
              <p
                className={`mt-2 text-xs font-medium lg:text-sm ${
                  inputError?.password === "weak" && "text-red"
                }`}
              >
                Minimum of 8 characters, with upper, lowercase, a number and a symbol
              </p>
            </fieldset>
            <fieldset className="mt-12 flex flex-col items-center">
              <button className="w-full rounded bg-primary-blue-dark py-4 font-medium text-white lg:text-2xl">
                Reset Password
              </button>
              <p className="mt-4 text-sm">
                Remember password?{" "}
                <Link to="/account/login" className="text-primary-blue-dark">
                  login
                </Link>
              </p>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
