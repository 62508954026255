import React from "react";
import { Link } from "react-router-dom";
import { LaArrowRight } from "../svg-icons";

type Props = {
  children: React.ReactNode;
  forwardedRef: React.Ref<HTMLDivElement>;
  link: string;
};

const ScrollContainer = ({ children, forwardedRef, link }: Props) => {
  return (
    <div
      className="h__modern__scroll__bar mb-6 mt-3 flex w-full items-center gap-4 overflow-x-scroll"
      ref={forwardedRef}
    >
      {children}
      <Link
        to={link}
        className="ml-4 flex h-[40px] w-[40px] min-w-[40px] items-center justify-center rounded-full border border-primary-black-darker text-xl"
      >
        <LaArrowRight stroke="#1a1a1a" />
      </Link>
    </div>
  );
};

export default ScrollContainer;
