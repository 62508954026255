import type { ReactElement } from "react";
import { RootState } from "../../redux/app/store";
import { useGetPlayerTrainingsQuery } from "../../redux/services/trainingService";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { TrainingCard } from "../ui/cards";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { useGetPlayersToRateQuery } from "../../redux/services/ratingService";
import React from "react";
import { setModalToDisplay } from "../../redux/features/modal/displayModalSlice";
import { Spinner } from "../ui";

export interface PlayerTrainingsProps {
  isPrivate: boolean;
}

export default function PlayerTrainings({
  isPrivate,
}: PlayerTrainingsProps): ReactElement {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state: RootState) => state.auth.myId);

  const { data: playerTrainings = [], isLoading } = useGetPlayerTrainingsQuery({
    userId,
  });
  const { data: playersToRate } = useGetPlayersToRateQuery();

  React.useEffect(() => {
    if (playersToRate?.canRate) {
      dispatch(setModalToDisplay("prompt-rating"));
    }
  }, [playersToRate]);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center py-6">
          <Spinner />
        </div>
      ) : (
        <>
          {!playerTrainings?.length && (
            <EmptyStateAuto
              title="Nothing to see here"
              description={`${
                isPrivate ? "You have" : "This organizer has"
              } not requested to join a team. when ${
                isPrivate ? "you" : "they"
              } do it'll appear here`}
            />
          )}

          <div className="mt-6 flex w-full grid-cols-3 flex-col gap-4 px-4 lg:grid lg:px-0">
            {playerTrainings?.map((training) => (
              <TrainingCard
                date={training?.date}
                location={training?.venue}
                name={training?.sessionName}
                noOfPlayers={training?.players?.length}
                time={training?.time}
                trainingId={training?._id}
                key={training?._id}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}
