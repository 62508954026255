import { Navigate, useNavigate } from "react-router-dom";
import {
  LaArrowLeft,
  LaDocumentUpload,
  LaGallery,
  LaSoccerBall,
} from "../../components/svg-icons";
import { useActionData } from "react-router-dom";
import { capitalize } from "../../utils/capitalize";
import moment from "moment";
import countReducer from "../../utils/countReducer";
import React from "react";
//import html2canvas from "html2canvas";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useUpdateScoreMutation } from "../../redux/services/matchService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { dataURItoBlob } from "../../utils/dataURIToBlob";
import { useCreatePostMutation } from "../../redux/services/postService";
import { setSentPost } from "../../redux/features/post/postSlice";

import * as html2Image from "html-to-image";
import { Spinner } from "../../components/ui";
import { RootState } from "../../redux/app/store";
import LaSpinner from "../../components/svg-icons/LaSpinner";
import HelmetComponent from "../../components/HelmetComponent";

type TeamData = {
  teamA: {
    team: Team;
  };
  teamB: {
    team: Team;
  };
  score: number;
  scorers: TPlayer[];
  redCards: TPlayer[];
  yellowCards: TPlayer[];
  assisters: TPlayer[];
  penalties: number;
};

interface Scorer {
  [scorerId: string]: number;
}

function reduceAndCountUserId(array: string[]) {
  return array.reduce((acc: Scorer, curr) => {
    acc[curr] ? acc[curr]++ : (acc[curr] = 1);
    return acc;
  }, {});
}

export default function GenInfoGraphic() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = React.useRef<HTMLDivElement>(null);
  const actionData = useActionData() as {
    matchData: Match;
    teamAData: TeamData;
    teamBData: TeamData;
  } & Record<string, { teamA: string; teamB: string }>;

  if (!actionData) return <Navigate to="/" />;

  const { matchData, teamAData, teamBData } = actionData || {
    matchData: undefined,
    teamAData: undefined,
    teamBData: undefined,
  };

  const scorersA = countReducer(teamAData?.scorers) || [];
  const scorersB = countReducer(teamBData?.scorers) || [];
  const assistersA = countReducer(teamAData?.assisters) || [];
  const assistersB = countReducer(teamBData?.assisters) || [];
  const teamAYellowCards = countReducer(teamAData?.yellowCards) || [];
  const teamBYellowCards = countReducer(teamBData?.yellowCards) || [];
  const teamARedCards = countReducer(teamAData?.redCards) || [];
  const teamBRedCards = countReducer(teamBData?.redCards) || [];

  const teamAYellowCard = teamAData?.yellowCards?.length;
  const teamBYellowCard = teamBData?.yellowCards?.length;
  const teamARedCard = teamAData?.redCards?.length;
  const teamBRedCard = teamBData?.redCards?.length;

  const teamAYellowCardPercent =
    (teamAYellowCard / (teamAYellowCard + teamBYellowCard)) * 100;
  const teamBYellowCardPercent =
    (teamBYellowCard / (teamBYellowCard + teamAYellowCard)) * 100;

  const teamARedCardPercent =
    (teamARedCard / (teamARedCard + teamBRedCard)) * 100;
  const teamBRedCardPercent =
    (teamBRedCard / (teamBRedCard + teamARedCard)) * 100;

  const [previewImage, setPreviewImage] = React.useState("");

  const zone = React.useRef<HTMLDivElement>(null);
  const [dropText, setDropText] = React.useState<string>("or Drop files here");
  // const infographicEl = React.useRef<HTMLDivElement>(null);
  const [infographicImage, setInfographicImage] = React.useState("");
  const [updateScore, { isLoading: updatingScore }] = useUpdateScoreMutation();
  const [createPost] = useCreatePostMutation();
  const profiles = useAppSelector((state: RootState) => state.auth.profiles);
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;

  const currentProfile = profiles?.find((profile) => profile.role === role);

  function fileDragEnter(event: React.DragEvent<HTMLInputElement>): void {
    event.preventDefault();
    event.stopPropagation();
  }

  function fileDragLeave(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    event.stopPropagation();
    zone.current &&
      zone.current.classList.remove("border-primary-black-darker");
    setDropText("or Drop files here");
  }

  function fileDragOver(event: React.DragEvent<HTMLDivElement>): void {
    event.stopPropagation();
    event.preventDefault();
    zone.current && zone.current.classList.add("border-primary-black-darker");
    setDropText("Release to upload");
  }

  function fileDrop(event: React.DragEvent<HTMLDivElement>): void {
    event.stopPropagation();
    event.preventDefault();

    const data = event.dataTransfer.files[0];
    handlePreview(data);
  }

  function handlePreview(data: any) {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = () => {
      setPreviewImage(reader?.result as React.SetStateAction<string>);
    };
  }

  const setImage = React.useCallback(() => {
    if (ref.current === null) return;

    html2Image
      .toJpeg(ref.current, {
        cacheBust: true,
        canvasHeight: 720,
        canvasWidth: 1280,
      })
      .then((dataUri) => {
        // const link = document.createElement("a");
        // link.download = "generatedimg.jpg";
        // link.href = dataUri;
        // link.click();
        setInfographicImage(dataUri);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  function handleCreatePost() {
    const formData = new FormData();
    const image = dataURItoBlob(infographicImage);
    formData.append("image", image);
    formData.append("tournament", matchData.tournament._id);
    currentProfile?.name &&
      formData.append("creatorName", currentProfile?.name);
    currentProfile?.avatar &&
      formData.append("creatorAvatar", currentProfile?.avatar);
    role && formData.append("creatorRole", role);

    createPost(formData)
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        dispatch(setSentPost(true));
      })
      .catch(() => {
        dispatch(setToastError(true));
        dispatch(setSentPost(false));
      });
  }

  function handleSubmitScore(act: string) {
    console.log(teamBData, matchData);

    const teamAScorers =
      reduceAndCountUserId(teamAData?.scorers?.map((scorer) => scorer?._id)) ||
      [];
    const teamBScorers = reduceAndCountUserId(
      teamBData?.scorers.map((scorer) => scorer._id) || []
    );
    const teamAAssisters = reduceAndCountUserId(
      teamAData?.assisters.map((assister) => assister._id) || []
    );
    const teamBAssisters = reduceAndCountUserId(
      teamBData?.assisters.map((assister) => assister._id) || []
    );
    const redCardsTeamA = teamARedCards?.map((redCard) => redCard.player._id);
    const redCardsTeamB = teamBRedCards?.map((redCard) => redCard.player._id);
    const yellowCardsTeamA = teamAYellowCards.map(
      (yellowCard) => yellowCard.player._id
    );
    const yellowCardsTeamB = teamBYellowCards.map(
      (yellowCard) => yellowCard.player._id
    );

    const teamData = [
      {
        teamId: teamAData?.teamA?.team._id,
        score: +teamAData?.score,
        scorers: teamAScorers,
        assisters: teamAAssisters,
        redCards: redCardsTeamA,
        yellowCards: yellowCardsTeamA,
        penalties: teamAData?.penalties,
        shots: parseInt(actionData.shots.teamA),
        corners: parseInt(actionData.corners.teamA),
        shotOnTarget: parseInt(actionData["shots-on-target"].teamA),
        fouls: parseInt(actionData.fouls.teamA),
        offside: parseInt(actionData.offside.teamA),
        possession: parseInt(actionData.possession.teamA),
        passingAccuracy: parseInt(actionData["passing-accuracy"].teamA),
        tackles: parseInt(actionData.tackles.teamA),
        playerXg: parseInt(actionData["player-xg"].teamA),
        playerXa: parseInt(actionData["player-xa"].teamA),
        tacklesWon: parseInt(actionData["tackles-won"].teamA),
        interceptions: parseInt(actionData["interceptions"].teamA),
        clearances: parseInt(actionData.clearances.teamA),
        aerialDuelsWon: parseInt(actionData["aerial-duels-won"].teamA),
        passCompletionRate: parseInt(actionData["pass-completion-rate"].teamA),
        cleanSheetGK: parseInt(actionData["clean-sheets-gk"].teamA),
        savePercentageGK: parseInt(actionData["save-percentage-gk"].teamA),
        totalSavesGK: parseInt(actionData["total-saves-gk"].teamA),
        distributionAccuracyGK: parseInt(
          actionData["distribution-accuracy-gk"].teamA
        ),
        sweeperKeeperActionsGK: parseInt(
          actionData["sweeper-keeper-actions-gk"].teamA
        ),
        penaltySavesGK: parseInt(actionData["penalty-saves-gk"].teamA),
        interceptionsGK: parseInt(actionData["interceptions-gk"].teamA),
      },
      {
        teamId: teamBData?.teamB?.team._id,
        score: +teamBData?.score,
        scorers: teamBScorers,
        assisters: teamBAssisters,
        redCards: redCardsTeamB,
        yellowCards: yellowCardsTeamB,
        penalties: teamBData?.penalties,
        shots: parseInt(actionData.shots.teamB),
        corners: parseInt(actionData.corners.teamB),
        shotOnTarget: parseInt(actionData["shots-on-target"].teamB),
        fouls: parseInt(actionData.fouls.teamB),
        offside: parseInt(actionData.offside.teamB),
        possession: parseInt(actionData.possession.teamB),
        passingAccuracy: parseInt(actionData["passing-accuracy"].teamB),
        tackles: parseInt(actionData.tackles.teamB),
        playerXg: parseInt(actionData["player-xg"].teamB),
        playerXa: parseInt(actionData["player-xa"].teamB),
        tacklesWon: parseInt(actionData["tackles-won"].teamB),
        interceptions: parseInt(actionData["interceptions"].teamB),
        clearances: parseInt(actionData.clearances.teamB),
        aerialDuelsWon: parseInt(actionData["aerial-duels-won"].teamB),
        passCompletionRate: parseInt(actionData["pass-completion-rate"].teamB),
        cleanSheetGK: parseInt(actionData["clean-sheets-gk"].teamB),
        savePercentageGK: parseInt(actionData["save-percentage-gk"].teamB),
        totalSavesGK: parseInt(actionData["total-saves-gk"].teamB),
        distributionAccuracyGK: parseInt(
          actionData["distribution-accuracy-gk"].teamB
        ),
        sweeperKeeperActionsGK: parseInt(
          actionData["sweeper-keeper-actions-gk"].teamB
        ),
        penaltySavesGK: parseInt(actionData["penalty-saves-gk"].teamB),
        interceptionsGK: parseInt(actionData["interceptions-gk"].teamB),
      },
    ];

    updateScore({ body: { teamData }, id: matchData._id })
      .unwrap()
      .then(async (res) => {
        console.log(res);
        act === "create" && handleCreatePost();
        dispatch(setToastSuccess(true));
        navigate(
          `/competitions/${matchData.tournament._id}?tab=matches&subTab=fixtures`
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setImage();
  }, [previewImage]);

  // open graph data
  const ogData = {
    title: "Leagues Arena - AI Generated Scorecards",
    description: "AI Generated Scorecards",
    url: `https://${window.location.host}/competitions/scores/${matchData.tournament._id}/g`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex flex-col items-center justify-center overflow-hidden pb-10">
        <div className="flex w-full flex-col items-center px-4 pb-[8rem] lg:max-w-[87%] lg:px-0 lg:pb-0">
          <div className="flex w-full items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
            <button
              className="flex items-center gap-2 text-xl lg:text-2xl"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold lg:text-2xl">
              AI Generated Scorecards
            </h3>
          </div>
          {!infographicImage && (
            <div className="my-4 flex w-full items-center justify-center">
              <Spinner />
            </div>
          )}
          <div className="absolute mt-4 w-full min-w-[560px] max-w-[700px] translate-x-[-2000px] select-none overflow-hidden overflow-x-visible rounded-md border border-gray-200 px-2 py-3">
            <div
              ref={ref}
              style={{ backgroundImage: `url(${previewImage})` }}
              className="relative z-0 flex aspect-[16/9] w-full select-none items-center justify-center overflow-hidden bg-grey-lighter bg-cover bg-no-repeat"
            >
              '{!previewImage && <LaGallery />}
              {/* <img
                src=""
                className="bg-red-300 absolute bottom-0 left-0 right-0 top-0 z-[1] aspect-[2/1] w-full object-cover object-center"
              /> */}
              <section className="absolute bottom-1 left-0 right-0 z-[2]">
                <section className="relative mx-auto h-full min-h-[3rem] max-w-[90%] rounded-lg">
                  <div className="flex w-full flex-col items-center justify-center">
                    <div className="absolute top-[-16px] z-20 w-[148px]  rounded-full bg-white px-4 py-1 shadow lg:w-[175px] lg:px-8">
                      <p className=" text-center text-[6px] font-medium uppercase text-[#49586A] lg:text-[8px]">
                        {matchData?.tournament?.name} ·
                        {matchData?.tournament?.venue}
                      </p>
                    </div>
                  </div>
                  {/* <div className="absolute left-1/2 right-1/2 z-10 mx-auto flex min-h-[1rem] w-[180px] max-w-[50%] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white uppercase text-black">
                  <p className="whitespace-nowrap px-2 py-2.5 text-center text-[9px] leading-none">
                    {matchData?.tournament?.name} ·
                    {matchData?.tournament?.venue}
                  </p>
                </div> */}

                  <section className="overflow-hidden rounded-xl bg-gradient-to-t from-white from-55%">
                    <section className="relative z-[1] h-16 w-full overflow-hidden rounded-b-[inherit] bg-gradient-to-br from-[#3BAEFF66] to-[#9D3BFFa6]">
                      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] bg-gradient-to-br from-[#3BAEFF66] to-[#9D3BFFa6] mix-blend-multiply"></div>
                      <div className="right__rect absolute right-0 " />

                      <div className="z-10 mx-auto flex w-[95%] items-end justify-between gap-2 py-2 text-white">
                        <div className="flex flex-1 flex-col items-center gap-2 whitespace-nowrap">
                          <img
                            src={teamAData?.teamA?.team?.avatar}
                            alt=""
                            className="h-7 w-7 rounded-full"
                          />
                          <p className="text-center text-[10px] font-extrabold uppercase text-white lg:text-xs">
                            {capitalize(teamAData?.teamA?.team?.name)}
                          </p>
                        </div>
                        <div className="flex flex-1 items-center justify-center gap-2 whitespace-nowrap text-2xl font-black md:text-4xl">
                          <span className="leading-none">
                            {+teamAData?.score}
                          </span>
                          <span className="leading-none">-</span>
                          <span className="leading-none">
                            {+teamBData?.score}
                          </span>
                        </div>
                        <div className="flex flex-1 flex-col items-center gap-2 whitespace-nowrap">
                          <img
                            src={teamBData?.teamB?.team?.avatar}
                            alt=""
                            className="h-7 w-7 rounded-full"
                          />
                          <p className="text-center text-[10px] font-extrabold uppercase text-white lg:text-xs">
                            {capitalize(teamBData?.teamB?.team?.name)}
                          </p>
                        </div>
                      </div>
                    </section>

                    <div className="relative z-10">
                      <section className="mx-auto flex w-full items-center justify-between px-4 py-1 text-[8px] font-semibold uppercase">
                        <div className="grid grid-cols-2 gap-x-2 gap-y-1 leading-none">
                          {scorersA.map((scorer) => (
                            <div className="flex flex-row items-center gap-[1px]">
                              <span>{scorer?.player?.name}</span>
                              <LaSoccerBall />
                              <span>
                                {scorer.cards > 0 && `+${scorer.cards}`}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="grid grid-cols-2 gap-x-2 gap-y-1 leading-none">
                          {scorersB.map((scorer) => (
                            <div className="flex flex-row-reverse items-center gap-[1px]">
                              <span>{scorer?.player?.name}</span>
                              <LaSoccerBall />
                              <span>
                                {scorer.cards > 0 && `+${scorer.cards}`}
                              </span>
                            </div>
                          ))}
                        </div>
                      </section>
                      <div className="text-center text-[7px]">
                        visit leaguesarena.com for full stats
                      </div>
                      <div className="center__rect absolute bottom-0 left-[40%] -z-[1] bg-blend-screen" />
                    </div>
                    <div className="left__rect absolute bottom-0 left-0 h-full rounded-[12px_0_0_12px] bg-blend-multiply" />
                  </section>
                </section>
              </section>
            </div>
          </div>

          <div className="mt-6  flex w-full flex-col gap-4 lg:w-[560px]">
            <img src={infographicImage} alt="" className="w-full" />
            <div
              className="flex items-center gap-4 rounded-lg border-dashed border-grey-light lg:border lg:p-4"
              onDragEnter={(event: React.DragEvent<HTMLInputElement>) =>
                fileDragEnter(event)
              }
              onDragLeave={(event: React.DragEvent<HTMLDivElement>) =>
                fileDragLeave(event)
              }
              onDragOver={(event: React.DragEvent<HTMLDivElement>) =>
                fileDragOver(event)
              }
              onDrop={(event: React.DragEvent<HTMLDivElement>) =>
                fileDrop(event)
              }
              ref={zone}
            >
              <label
                htmlFor="cameraFileInput"
                className="flex cursor-pointer items-center gap-2 rounded border border-primary-black-darker p-3 text-sm lg:hidden"
              >
                <span className="btn">Take a photo</span>
                <input
                  id="cameraFileInput"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    // @ts-ignore
                    handlePreview(e.target.files[0])
                  }
                />
              </label>

              <input type="file" id="image" name="image" hidden />
              <label className="flex cursor-pointer items-center gap-2 rounded border border-primary-black-darker p-3 text-sm">
                <LaDocumentUpload stroke="#111111" />
                <span className="hidden lg:inline-block">Browser pictures</span>
                <span className="lg:hidden">Browser Gallery</span>
                <input
                  type="file"
                  aria-label="file-input"
                  hidden
                  accept="image/*"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    // @ts-ignore
                    handlePreview(e.target.files[0])
                  }
                />
              </label>

              <p className="hidden text-grey lg:block">{dropText}</p>
            </div>
          </div>

          <div className="mt-6 flex w-full flex-col items-center gap-4 rounded bg-grey-lighter px-3 pb-8 pt-4 shadow  lg:px-0">
            <p className="text-xs font-medium">
              Match {matchData?.name} <span>·</span>{" "}
              {moment(matchData?.date).format("ll")}
            </p>
            <div className="grid w-full grid-cols-3 items-center justify-between lg:px-20">
              <div className="flex items-center gap-2 ">
                <img
                  src={teamAData?.teamA?.team?.avatar}
                  alt=""
                  className="h-8 w-8 rounded-full"
                />
                <p className="hidden lg:block">
                  {capitalize(teamAData?.teamA?.team?.name)}
                </p>
                <p className="uppercase lg:hidden">
                  {capitalize(teamAData?.teamA?.team?.shortName)}
                </p>
                <div
                  className={`h-2 w-2 rounded-full ${
                    teamAData?.score > teamBData?.score
                      ? "bg-green"
                      : teamBData?.score === teamAData?.score
                      ? "bg-primary-blue-darker"
                      : "bg-red"
                  }`}
                />
              </div>

              <p className="text-center">
                {moment(matchData?.time).format("HH:mm a")}
              </p>

              <div className="flex items-center justify-end gap-2">
                <div
                  className={`h-2 w-2 rounded-full ${
                    teamBData?.score > teamAData?.score
                      ? "bg-green"
                      : teamBData?.score === teamAData?.score
                      ? "bg-primary-blue-darker"
                      : "bg-red"
                  }`}
                />
                <p className="hidden lg:block">
                  {capitalize(teamBData?.teamB?.team?.name)}
                </p>
                <p className="uppercase lg:hidden">
                  {capitalize(teamBData?.teamB?.team?.shortName)}
                </p>
                <img
                  src={teamBData?.teamB?.team?.avatar}
                  alt="team avatar"
                  className="h-8 w-8 rounded-full"
                />
              </div>
            </div>

            <div className="mt-2 flex w-full flex-col items-center">
              <div className="w-full space-y-4 lg:max-w-md">
                <div className="flex flex-col items-center">
                  <p className="border-b border-grey-light px-8 pb-3 text-center text-sm font-medium text-grey">
                    {matchData?.location}
                  </p>
                  <h3 className="mt-3 text-sm font-medium">Match Summary</h3>
                  <h3 className="mt-4 text-sm font-medium text-grey">Goals</h3>
                </div>
                {/* Goals */}
                <div className="flex w-full justify-between text-xs lg:text-sm">
                  <div className="flex flex-col gap-2">
                    {scorersA?.map((scorer, i) => (
                      <div key={i} className="flex items-center gap-2">
                        <p className="capitalize">{scorer?.player?.name}</p>
                        <p className="capitalize">{scorer?.cards}</p>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col gap-2">
                    {scorersB?.map((scorer, i) => (
                      <div
                        key={i}
                        className="flex flex-row-reverse items-center gap-2"
                      >
                        <p className="capitalize">{scorer?.player?.name}</p>
                        <p className="capitalize">{scorer?.cards}</p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col items-center">
                  <h3 className=" px-10 pt-2 text-sm font-medium text-grey">
                    Assists
                  </h3>
                </div>
                {/* Assists */}
                <div className="flex items-center justify-between text-xs lg:text-sm">
                  <div className="flex flex-col gap-2">
                    {assistersA?.map((assister, i) => (
                      <div key={i} className="flex items-center gap-2">
                        <p className="capitalize">{assister?.player?.name}</p>
                        <p className="capitalize">{assister?.cards}</p>
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-col gap-2">
                    {assistersB?.map((assister, i) => (
                      <div key={i} className="flex items-center gap-2">
                        <p className="capitalize">{assister?.player?.name}</p>
                        <p className="capitalize">{assister?.cards}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {/* Yellow Cards */}

                <div className="mt-4 flex w-full flex-col gap-4">
                  <aside className="">
                    <p className="text-center text-sm font-medium text-gray-600">
                      Yellow card
                    </p>
                    <div className="mt-2 flex">
                      <div
                        className={`h-2  ${
                          teamAYellowCardPercent > teamBYellowCardPercent
                            ? "bg-yellow"
                            : "bg-gray-200"
                        } rounded-[999px_0_0_999px]`}
                        style={{
                          width: `${teamAYellowCardPercent}%`,
                        }}
                      />
                      <div
                        className={`h-2 ${
                          teamBYellowCardPercent >= teamAYellowCardPercent
                            ? "bg-yellow"
                            : "bg-gray-200"
                        } rounded-[0_999px_999px_0]`}
                        style={{
                          width: `${teamBYellowCardPercent}%`,
                        }}
                      />
                    </div>
                    <div className="mt-1 flex justify-between text-xs font-medium lg:text-sm">
                      <div className="flex flex-col gap-1">
                        {teamAYellowCards?.map((yellowCardObj, i) => (
                          <p
                            className="flex items-center gap-2 capitalize"
                            key={i}
                          >
                            <span>{yellowCardObj?.player?.name}</span>
                            <span>{yellowCardObj?.cards}</span>
                          </p>
                        ))}
                      </div>
                      <div className="flex flex-col gap-1">
                        {teamBYellowCards?.map((yellowCardObj, i) => (
                          <p
                            className="flex flex-row-reverse items-center gap-2 capitalize"
                            key={i}
                          >
                            <span>{yellowCardObj?.player?.name}</span>
                            <span>{yellowCardObj?.cards}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </aside>
                  {/* Red Cards stats */}
                  <aside className="w-full">
                    <p className="my-1 text-center text-sm font-medium text-gray-600">
                      Red card
                    </p>
                    <div className="mt-2 flex">
                      <div
                        className={`h-2  ${
                          teamARedCardPercent >= teamBRedCardPercent
                            ? "bg-red"
                            : "bg-gray-200"
                        } rounded-[999px_0_0_999px]`}
                        style={{
                          width: `${teamARedCardPercent}%`,
                        }}
                      />
                      <div
                        className={`h-2 ${
                          teamBRedCardPercent > teamARedCardPercent
                            ? "bg-red"
                            : "bg-gray-200"
                        } rounded-[0_999px_999px_0]`}
                        style={{
                          width: `${teamBRedCardPercent}%`,
                        }}
                      />
                    </div>
                    <div className="mt-1 flex justify-between text-xs font-medium lg:text-sm">
                      <div className="flex flex-col gap-1">
                        {teamARedCards?.map((redCardObj, i) => (
                          <p
                            className="flex items-center gap-2 capitalize"
                            key={i}
                          >
                            <span>{redCardObj?.player?.name}</span>
                            <span>{redCardObj?.cards}</span>
                          </p>
                        ))}
                      </div>
                      <div className="flex flex-col gap-1">
                        {teamBRedCards?.map((redCardObj, i) => (
                          <p
                            className="flex flex-row-reverse items-center gap-2 capitalize"
                            key={i}
                          >
                            <span>{redCardObj?.player?.name}</span>
                            <span>{redCardObj?.cards}</span>
                          </p>
                        ))}
                      </div>
                    </div>
                  </aside>
                </div>

                {/* Shots */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Shots
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.shots.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.shots?.teamB}
                    </p>
                  </div>
                </section>
                {/* Corners */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Corners
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.corners?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.corners?.teamB}
                    </p>
                  </div>
                </section>
                {/* Shots on Target */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Shots on Target
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["shots-on-target"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["shots-on-target"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Fouls */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Fouls
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.fouls?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.fouls?.teamB}
                    </p>
                  </div>
                </section>
                {/* Offside */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Offside
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.offside?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.offside?.teamB}
                    </p>
                  </div>
                </section>
                {/* Possession */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Possession
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.possession?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.possession?.teamB}
                    </p>
                  </div>
                </section>
                {/* Passing Accuracy */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Passing Accuracy
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["passing-accuracy"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["passing-accuracy"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Tackles */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Tackles
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.tackles?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.tackles?.teamB}
                    </p>
                  </div>
                </section>
                {/* Player xG (Expected Goals) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Player xG (Expected Goals)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["player-xg"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["player-xg"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Player xA (Expected Assists) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Player xA (Expected Assists)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["player-xa"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["player-xa"]?.teamA}
                    </p>
                  </div>
                </section>
                {/* Tackles Won */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Tackles Won
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["tackles-won"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["tackles-won"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Interceptions */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Interceptions
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.interceptions?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.interceptions?.teamB}
                    </p>
                  </div>
                </section>
                {/* Clearance */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Clearance
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData.clearances?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData.clearances?.teamB}
                    </p>
                  </div>
                </section>
                {/* Aerial Won */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Aerial Won
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["aerial-won"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["aerial-won"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Pass Completion Rate */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Pass Completion Rate
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["pass-completion-rate"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["pass-completion-rate"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Clean Sheets (GK) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Clean Sheets (GK)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["clean-sheets-gk"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["clean-sheets-gk"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Save Percentage (GK) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Save Percentage (GK)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["save-percentage-gk"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["save-percentage-gk"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Total Saves (GK) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Total Saves (GK)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["total-saves"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["total-saves"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Distribution Accuracy (GK) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Distribution Accuracy (GK)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["distribution-accuracy-gk"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["distribution-accuracy-gk"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Sweeper Keeper Actions (GK) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Sweeper Keeper Actions (GK)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["sweeper-keeper-actions-gk"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["sweeper-keeper-actions-gk"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Penalty Saves (GK) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Penalty Saves (GK)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["penalty-saves-gk"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["penalty-saves-gk"]?.teamB}
                    </p>
                  </div>
                </section>
                {/* Interceptions (GK) */}
                <section className="flex flex-col items-center gap-4 border-b border-grey-light">
                  <h3 className="text-center text-sm font-medium text-grey">
                    Interceptions (GK)
                  </h3>
                  <div className="flex w-full items-center justify-between gap-4">
                    <p className="text-sm capitalize">
                      {actionData["interceptions-gk"]?.teamA}
                    </p>
                    <p className="text-sm capitalize">
                      {actionData["interceptions-gk"]?.teamB}
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="fixed bottom-0 z-[80] grid w-full grid-cols-2 gap-4 border-t border-grey-light bg-white px-3 py-4 shadow lg:static lg:mt-6 lg:flex lg:items-center lg:justify-center lg:border-0 lg:px-0 lg:shadow-none">
            <button
              className="flex items-center justify-center gap-4 rounded border border-primary-black-darker py-4 font-medium disabled:cursor-not-allowed lg:w-[175px]"
              aria-label="skip"
              onClick={() => handleSubmitScore("skip")}
              disabled={updatingScore}
            >
              <span>Skip</span>
              {updatingScore && <LaSpinner className="animate-spin" />}
            </button>
            <button
              className="flex items-center justify-center gap-4 rounded bg-primary-black-darker py-4 font-medium text-white disabled:cursor-not-allowed disabled:bg-grey-light disabled:text-grey lg:w-[175px]"
              disabled={updatingScore || !previewImage}
              onClick={() => handleSubmitScore("create")}
              aria-label="create-post"
            >
              <span>Create post</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
