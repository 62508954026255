import type { ReactElement } from "react";
import { CreateTournamentFormValues } from "../../pages/create-tournament/CreateTournament";
import tournamentCategories from "../../assets/data/tournament-categories";
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { cn } from "../../utils/cn";
import Input from "../ui/input/Input";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import Textarea from "../ui/textarea/Textarea";
import { RegisteredSelect } from "../ui";
import { useSearchTournamentsQuery } from "../../redux/services/tournamentService";
import { setToastError } from "../../redux/features/modal/displayModalSlice";
import { LaInfo } from "../svg-icons";

interface SetupTournamentFormProps {
  errors: FieldErrors<CreateTournamentFormValues>;
  formTitle: string;
  register: UseFormRegister<CreateTournamentFormValues>;
  watchFormat: string;
  watchFixtureType: string;
  setValue: UseFormSetValue<CreateTournamentFormValues>;
  watchLocation: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: UseFormHandleSubmit<CreateTournamentFormValues>;
  watchName: string;
  watchMaxTeam: string;
  watchCategory: string;
  setError: UseFormSetError<CreateTournamentFormValues>;
}

export default function SetupTournamentForm({
  register,
  setCurrentStep,
  formTitle,
  watchFormat,
  watchFixtureType,
  watchCategory,
  errors,
  watchMaxTeam,
  watchLocation,
  setValue,
  handleSubmit,
  watchName,
  setError,
}: SetupTournamentFormProps): ReactElement {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: RootState) => state.auth.role);
  const { data: tournament } = useSearchTournamentsQuery(
    { name: watchName },
    {
      skip: !watchName,
    }
  );

  const errorsArray = Object.values(errors).map((error) => error?.message) as string[];

  function handleNextView(data: CreateTournamentFormValues) {
    const isValidKnockoutTeamNumber = (number: number) =>
      (number & (number - 1)) === 0 && number !== 0;
    const errors = [
      {
        error: tournament?.name === data.name,
        message: "Tournament name already exists",
        name: "name",
      },
      {
        error: data.format === "knockout" && !isValidKnockoutTeamNumber(+data.maxTeam),
        message: "Please enter a valid number for knockout competition",
        name: "maxTeam",
      },
    ];

    const error = errors.find((error) => error.error);
    if (error) {
      setError(error.name, {
        type: "custom",
        message: error.message,
      });

      dispatch(setToastError(true));
      return;
    }

    setCurrentStep((prev) => prev + 1);
  }

  return (
    <section className="my-6 min-h-[50vh] w-full md:my-12">
      <div className="mx-auto flex max-w-2xl flex-col items-center gap-2 px-2">
        {/* Create Tournament */}
        <div className="mb-6 flex flex-col gap-2">
          <h2 className="text-center text-[28px] font-medium text-primary-black-darker md:text-4xl">
            {formTitle}
          </h2>
          <p className="text-center text-base text-grey md:text-xl">
            Setup your competition for teams to compete.
          </p>
        </div>
        <form
          className="flex w-full flex-col items-start gap-6 px-2"
          onSubmit={handleSubmit(handleNextView)}
        >
          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="name">
              Competition Name<span className="text-bold text-red">*</span>
            </label>
            <Input
              type="text"
              autoComplete="off"
              register={register}
              placeholder="e.g. League of champions"
              role={role}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30": errors?.name,
                }
              )}
              {...register("name", {
                required: "Competition name is required",
              })}
            />
            {errors.name && (
              <p className="text-xs italic text-red">{errors.name.message}</p>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="description">About this event</label>
            <Textarea
              role={role}
              placeholder="Write something... "
              register={register}
              {...register("description", {
                maxLength: {
                  value: 150,
                  message: "Description cannot exceed 150 characters",
                },
              })}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors?.description,
                }
              )}
            />
            <span className="text-[12px] font-medium leading-none text-zinc-900">
              150 characters max
            </span>

            {errors.description && (
              <p className="text-xs italic text-red">{errors.description.message}</p>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="category" className="text-sm md:text-base">
              Category
              <span className="text-red"> *</span>
            </label>
            <RegisteredSelect
              placeholder="E.g under 18"
              name="category"
              id="category"
              register={register}
              setValue={setValue}
              options={tournamentCategories}
              value={watchCategory}
              error={errors.category?.message}
              fit={false}
              required="Please select a location"
            />
            {errors.category && (
              <span className="text-xs italic text-red">{errors.category.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="format">
              Competition Format<span className="text-bold text-red"> *</span>
            </label>
            <RegisteredSelect
              id="format"
              value={watchFormat}
              register={register}
              name="format"
              placeholder="Choose a Competition Format"
              options={["Group stage + Knockouts", "season", "knockout"]}
              error={errors.format?.message}
              setValue={setValue}
              required="Please select a competition format"
            />
            {errors.format?.message && (
              <p className="text-xs italic text-red">{errors.format?.message}</p>
            )}
          </fieldset>

          {(watchFormat === "season" || watchFormat === "Group stage + Knockouts") && (
            <>
              <fieldset className="flex w-full flex-col items-stretch gap-2">
                <label htmlFor="maxTeam">
                  Max. number of teams
                  <span className="text-bold text-red">*</span>
                </label>
                <Input
                  type="number"
                  role={role}
                  placeholder="Enter the number of teams to participate"
                  register={register}
                  className={cn(
                    "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                    {
                      "border-red focus:border-red focus:border-opacity-30":
                        errors.maxTeam,
                    }
                  )}
                  {...register("maxTeam", {
                    required: "Please enter the number of teams to participate",
                    min: { value: 4, message: "Minimum 4 teams" },
                    max: { value: 32, message: "Maximum 32 teams" },
                  })}
                />
                <span className="text-[12px] font-medium leading-none text-zinc-900">
                  Minimum 4 teams
                </span>
                {errors.maxTeam?.message && (
                  <span className="text-xs italic text-red">
                    {errors.maxTeam.message}
                  </span>
                )}
              </fieldset>

              <fieldset className="flex w-full flex-col items-stretch gap-2">
                <label htmlFor="fixture-type">
                  Fixture Type
                  <span className="text-bold text-red"> *</span>
                </label>
                <RegisteredSelect
                  placeholder="select fixture type"
                  name="fixtureType"
                  register={register}
                  id="fixture-type"
                  value={watchFixtureType}
                  options={["single fixture", "home & away"]}
                  fit={true}
                  setValue={setValue}
                  required="Please select a fixture type"
                  error={errors.fixtureType?.message}
                />
                {errors.fixtureType && (
                  <span className="text-xs italic text-red">
                    {errors.fixtureType.message}
                  </span>
                )}
              </fieldset>
            </>
          )}

          {watchFormat === "knockout" && (
            <fieldset className="flex w-full flex-col items-stretch gap-2">
              <label htmlFor="number-of-teams">
                Number of Teams
                <span className="text-bold text-red">*</span>
              </label>
              <RegisteredSelect
                placeholder="Select number of teams"
                name="maxTeam"
                id="number-of-teams"
                register={register}
                value={watchMaxTeam}
                error={errors.maxTeam?.message}
                options={["4", "8", "16", "32"]}
                setValue={setValue}
                required="Please select the number of teams"
                fit={true}
              />
              {errors.maxTeam && (
                <span className="text-xs italic text-red">{errors.maxTeam.message}</span>
              )}
            </fieldset>
          )}

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="noOfPlayers">
              Maximum number of players per team
              <span className="text-bold text-red">*</span>
            </label>
            <Input
              type="number"
              role={role}
              placeholder="Enter maximum number of players"
              register={register}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30":
                    errors.noOfPlayers,
                }
              )}
              {...register("noOfPlayers", {
                required: "Please enter the number of players to participate",
                min: { value: 1, message: "Minimum 1 player" },
              })}
            />
            {errors.noOfPlayers?.message && (
              <span className="text-xs italic text-red">
                {errors.noOfPlayers.message}
              </span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="datetime">
              Date & Time<span className="text-bold text-red"> *</span>
            </label>
            <Input
              id="datetime"
              type="datetime-local"
              register={register}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4  focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30": errors.datetime,
                }
              )}
              role={role}
              {...register("datetime", {
                required: "Please select start data and time",
                min: {
                  value: new Date().toISOString().slice(0, 16),
                  message: "Please select a valid date and time",
                },
              })}
              name={"datetime"}
              min={new Date().toISOString().slice(0, 16)}
            />
            {errors.datetime && (
              <span className="text-xs italic text-red">{errors.datetime?.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="location" className="text-sm md:text-base">
              Location
              <span className="text-red"> *</span>
            </label>
            <RegisteredSelect
              placeholder="Select a location"
              name="location"
              id="location"
              register={register}
              setValue={setValue}
              value={watchLocation}
              error={errors.location?.message}
              fit={false}
              required="Please select a location"
            />
            {errors.location && (
              <span className="text-xs italic text-red">{errors.location.message}</span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="venue">
              Venue<span className="text-bold text-red"> *</span>
            </label>
            <Input
              id="venue"
              type="text"
              placeholder="e.g. city park"
              register={register}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4  focus:border-opacity-30",
                {
                  "border-red focus:border-red focus:border-opacity-30": errors.venue,
                }
              )}
              role={role}
              {...register("venue", {
                required: "Please enter the venue",
                maxLength: 50,
              })}
              name={"venue"}
            />
            {errors.venue && (
              <span className="text-xs italic text-red">{errors.venue.message}</span>
            )}
          </fieldset>

          {/* <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="registration-fee">
              Registration fee
              <span className=""> (optional blank if free)</span>
            </label>
            <Input
              id="registration-fee"
              role={role}
              register={register}
              type="text"
              placeholder="e.g. ₦30,000"
              {...register("registrationFee", {
                min: { value: 1, message: "Minimum ₦1" },
                maxLength: 30,
                pattern: { value: /^[0-9]+$/, message: "Only numbers allowed" },
              })}
              className={cn(
                "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                {
                  "border-red focus:border-red": errors?.registrationFee,
                }
              )}
            />
            {errors.registrationFee && (
              <p className="text-xs italic text-red">
                {errors.registrationFee.message}
              </p>
            )}
          </fieldset> */}

          {!!errorsArray.length && (
            <div className="mt-4 flex w-full items-center gap-2 bg-[#E71D361A] px-2 py-1">
              <LaInfo className="text-2xl" stroke="#E71D36" />
              <div className="text-sm">
                <p className="font-bold text-red">Important</p>
                {errorsArray.map((error) => (
                  <p className="text-sm italic text-red" key={error}>
                    {error}
                  </p>
                ))}
              </div>
            </div>
          )}

          <fieldset className="flex w-full flex-col pb-20">
            <button
              className={cn(
                "mt-16 flex w-full  items-center justify-center rounded bg-primary-black-darker p-4 text-base text-white hover:bg-primary-black-lighter hover:text-black disabled:bg-grey-light disabled:text-grey md:mt-14 md:text-2xl"
              )}
            >
              Proceed
            </button>
          </fieldset>
        </form>
      </div>
    </section>
  );
}
