export const positions = [
  {
    short: "GK",
    name: "Goal Keeper",
  },
  {
    short: "CB",
    name: "Center Back",
  },
  {
    short: "LB",
    name: "Left Back",
  },
  {
    short: "LWB",
    name: "Left Wing Back",
  },
  {
    short: "RB",
    name: "Right Back",
  },
  {
    short: "RWB",
    name: "Right Wing Back",
  },
  {
    short: "CDM",
    name: "Center Defending Midfield",
  },
  {
    short: "CM",
    name: "Center Midfield",
  },
  {
    short: "CAM",
    name: "Center  Attacking Midfield",
  },
  {
    short: "LM",
    name: "Left Midfield",
  },
  {
    short: "RM",
    name: "Right Midfield",
  },
  {
    short: "LW",
    name: "Left Wing",
  },
  {
    short: "RW",
    name: "Right Wing",
  },
  {
    short: "SS",
    name: "Supporting Striker",
  },
  {
    short: "CF",
    name: "Center Forward",
  },
  {
    short: "ST",
    name: "Striker",
  },
];
