import React from "react";
import { RootState } from "../../redux/app/store";
import { useAppSelector } from "../../redux/app/hooks";
import { LaInfo } from "../svg-icons";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui";
import { cn } from "../../utils/cn";

type Props = {
  id: string;
  name: string;
  statusText?:
    | "attention"
    | "joined"
    | "requested"
    | "admin"
    | "invited"
    | string;
  setSelectedTeams?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCompetition?: React.Dispatch<React.SetStateAction<string>>;
  selectedCompetition?: string;
  selectedTeams?: string[];
};

export default function StatusInfo({
  id,
  name,
  statusText,
  setSelectedTeams,
  selectedCompetition,
  setSelectedCompetition,
  selectedTeams,
}: Props) {
  const ID_LENGTH = 24;

  const navigate = useNavigate();

  const role = useAppSelector((state: RootState) => state.auth.role);
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const isOnBoarded = useAppSelector(
    (state: RootState) => state.auth.isBoarded
  );
  const disabledStates = ["requested", "joined", "admin", "invited", "added"];
  const disabled = disabledStates.includes(statusText ?? "");

  const actions: {
    [key: string]: () => void;
  } = {
    add: () => {
      setSelectedCompetition &&
        selectedCompetition &&
        selectedCompetition.length < ID_LENGTH &&
        setSelectedCompetition("");
      setSelectedTeams && setSelectedTeams((prev) => [...prev, id]);
    },
    unselect: () => {
      if (selectedTeams?.length === 1) {
        setSelectedCompetition && setSelectedCompetition("none");
      }
      setSelectedTeams &&
        setSelectedTeams((prev) => prev.filter((teamId) => teamId !== id));
    },

    attention: () => {
      navigate(`/profiles/team/${name?.replace(/\s/g, "-")}`);
    },
  };
  // function sendRequest() {
  //   requestToJointeamFn({ teamId: id })
  //     .unwrap()
  //     .then((resp) => {
  //       console.log("resp from sendRequest", resp);
  //     })
  //     .catch((err) => {
  //       console.log("error from sendRequest", err.data.message);
  //       dispatch(setToastErrorText(err.data.message));
  //     });
  // }

  function handleAction() {
    if (!isLoggedIn || !isOnBoarded) return navigate("/account/login");
    statusText && actions[statusText]();
  }

  return (
    <>
      {statusText && (
        <Button
          onClick={handleAction}
          role={role}
          intent="secondary"
          disabled={disabled}
          className={cn("rounded px-2 py-1 text-sm capitalize", {
            "border-0 bg-[#3C8505] text-white hover:bg-[#3C8505aa]":
              statusText === "joined" || statusText === "registered",
            "border-0 bg-[#FFB800] text-primary-black-darker hover:bg-[#ffb800aa]":
              statusText === "requested" ||
              statusText === "invited" ||
              statusText === "pending verification",
            "border-0 bg-[#FF6600] text-primary-black-darker hover:bg-[#FF6600aa]":
              statusText === "attention",
          })}
        >
          {statusText}
          {statusText === "attention" && <LaInfo />}
        </Button>
      )}
    </>
  );
}
