import {
  LaEditPencil,
  LaFirstPlace,
  LaSecPlace,
  LaTag,
  LaThrdPlace,
} from "../svg-icons";
import { toSentenceCase } from "../../utils/toSentenceCase";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import formatToNaira from "../../utils/formatPrice";
import { Link } from "react-router-dom";
import moment from "moment";

type InfoCardProps = {
  tournament?: Tournament;
};

function InfoCard({ tournament }: InfoCardProps) {
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const userRole = useAppSelector((state: RootState) => state.auth.role);
  const isTournamentOwner =
    tournament?.author === myId && userRole === "organizer";
  const hasPrizes =
    tournament?.winnerPrize ||
    tournament?.runnerUpPrize ||
    tournament?.thirdPlacePrize;
  const winningPrizes = [
    {
      prize: tournament?.winnerPrize || "0",
      icon: <LaFirstPlace />,
    },
    {
      prize: tournament?.runnerUpPrize || "0",
      icon: <LaSecPlace />,
    },
    {
      prize: tournament?.thirdPlacePrize || "0",
      icon: <LaThrdPlace />,
    },
  ];

  console.log(tournament);

  return (
    <div className="p-4 lg:px-0">
      <div className="lg:max-w-sm">
        <h3 className="text-base font-medium">About this competition</h3>
        <p className="mt-2">{toSentenceCase(tournament?.description)}</p>
      </div>

      {
        <div className="mt-12 flex flex-col items-start gap-2">
          <h3 className="text-base font-medium">Registration Fee </h3>

          <p className="flex items-center gap-1 bg-green-light px-1 text-green-dark">
            <LaTag />
            {tournament?._id === "669ad1b6687ea94a8a1c1dd2"
              ? formatToNaira("259600")
              : formatToNaira(tournament?.registrationFee ?? "0")}
          </p>
        </div>
      }

      {hasPrizes && (
        <div className="mt-12 flex max-w-max flex-col items-start gap-4 lg:gap-6">
          <div className="flex w-full items-center justify-between">
            <h2 className="text-base font-medium">Rewards</h2>

            {isTournamentOwner && (
              <Link
                to={`/competitions/${tournament._id}/edit?p=3`}
                className="text-lg"
                aria-label="edit-button"
              >
                <LaEditPencil />
              </Link>
            )}
          </div>
          <div className="lg:min-w-md grid w-full grid-cols-3 justify-between gap-6 rounded-lg bg-grey-lighter p-3 lg:w-auto lg:p-6">
            {winningPrizes.map((prize, i) => (
              <div className="flex flex-col items-center gap-2" key={i}>
                {prize.icon}
                <p className="text-center lg:text-2xl">
                  {formatToNaira(prize.prize)}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Tournament Info */}
      <section className="mt-12 w-full lg:max-w-md">
        <div className="flex items-center justify-between">
          <h3 className="font-medium">Tournament Information</h3>
          {isTournamentOwner && (
            <Link
              to={`/competitions/${tournament._id}/edit`}
              className="text-lg"
              aria-label="edit-button"
            >
              <LaEditPencil />
            </Link>
          )}
        </div>

        <div className="mt-2 grid grid-cols-2 gap-y-2 border border-grey-light p-3 shadow lg:gap-x-10">
          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Category name</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.category}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Maximum number of players</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.noOfPlayers}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Competition Format</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.format}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Start Date and Time</h3>
            <p className="text-base text-primary-black-darker">
              {moment(tournament?.datetime).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Number of Teams</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.maxTeam}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Location</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.location}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Venue</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.venue}
            </p>
          </div>

          {tournament?.fixtureType && (
            <div className="flex flex-col">
              <h3 className="text-sm text-grey">Fixture Type</h3>
              <p className="text-base text-primary-black-darker">
                {tournament?.fixtureType}
              </p>
            </div>
          )}
        </div>
      </section>

      {/* Contact info section */}
      <div className="mt-12 w-full lg:max-w-md">
        <div className="flex items-center justify-between">
          <h3 className="font-medium">Contact information</h3>
          {isTournamentOwner && (
            <Link
              to={`/competitions/${tournament._id}/edit?p=2`}
              className="text-lg"
              aria-label="edit-button"
            >
              <LaEditPencil />
            </Link>
          )}
        </div>

        <div className="mt-2 grid grid-cols-2 gap-y-2 rounded border border-grey-light p-3 shadow lg:gap-x-10">
          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Contact name</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.contactName}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Email</h3>
            <p className="break-words text-base text-primary-black-darker">
              {tournament?.contactEmail}
            </p>
          </div>

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Mobile</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.phoneNumber}
            </p>
          </div>

          {tournament?.phoneNumber2 && (
            <div className="flex flex-col">
              <h3 className="text-sm text-grey">Telephone</h3>
              <p className="text-base text-primary-black-darker">
                {tournament?.phoneNumber2}
              </p>
            </div>
          )}

          <div className="flex flex-col">
            <h3 className="text-sm text-grey">Address</h3>
            <p className="text-base text-primary-black-darker">
              {tournament?.contactAddress}
            </p>
          </div>
        </div>
      </div>

      {/* Current Teams */}
      <section className="mt-12 w-full lg:max-w-md">
        <div className="flex items-center justify-between">
          <h3 className="font-medium">Current Registered Team</h3>
        </div>

        <aside className="mt-2 max-h-48 overflow-y-auto rounded border border-grey-light p-3 shadow">
          {tournament?.teams?.map((teamData: any) => (
            <div
              className="flex items-center gap-2 py-2"
              key={teamData?.team?._id}
            >
              <img
                src={teamData?.team?.avatar!}
                alt=""
                className="aspect-square h-10 w-10 rounded-full object-cover"
              />
              <p className="flex-1">{teamData?.team?.name}</p>
            </div>
          ))}
        </aside>
      </section>
    </div>
  );
}

export default InfoCard;
