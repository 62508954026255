import team from "../assets/icons/team.png";

export const defaultProfileImages: {
  [key: string]: string;
  organizer: string;
  player: string;
  fan: string;
} = {
  organizer:
    "https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076676/Frame_6182_tio2ub.png",
  player:
    "https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076677/Frame_6180_qxstfl.png",
  team,
  fan: "https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076677/Frame_6181_k3886h.png",
};
