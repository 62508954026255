import React from "react";
import { Link } from "react-router-dom";
import { LaEditPencil, LaLocation } from "../../components/svg-icons";
import moment from "moment";
import { EditFixtureModal } from "../../components/modals";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import EmptyStateAuto from "../ui/EmptyStateAuto";

type Props = {
  fixtures: { round: number; matches: KnockoutMatch[] }[];
  tournamentAuthorId?: string;
};

function KnockoutFixtures({ fixtures = [], tournamentAuthorId }: Props) {
  const [isEditing, setIsEditing] = React.useState(false);
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const isTournamentAuthor = myId === tournamentAuthorId;
  const isCompleted = !fixtures
    .map((fixture) =>
      fixture?.matches?.every((match) => match?.status === "completed")
    )
    .includes(false);

  const stage: {
    [key: number]: string;
    1: string;
    2: string;
    4: string;
    8: string;
    16: string;
  } = {
    1: "Finals",
    2: "Semi-Finals",
    4: "Quarter-Finals",
    8: "Round 16",
    16: "Round 32",
  };

  const [matchData, setMatchData] = React.useState<{
    location?: string;
    id?: string;
    date?: string;
    time?: any;
    teamData: [
      { shortName: string; avatar: string },
      { shortName: string; avatar: string }
    ];
  }>({
    location: "",
    id: "",
    date: "",
    time: "",
    teamData: [
      { shortName: "", avatar: "" },
      { shortName: "", avatar: "" },
    ],
  });

  function handleEditFixtures(match: any) {
    setIsEditing(true);
    setMatchData((prev) => ({
      ...prev,
      id: match._id,
      location: match.location,
      date: match.date,
      time: match.time,
      teamData: match.teamData,
    }));
  }

  const fixtureCardGroup = [...fixtures]
    ?.sort((a, b) => a?.round - b?.round)
    ?.map((fixture, i) => {
      const matchStage = fixture?.matches?.length;

      return (
        <div key={i}>
          <div className="border-b border-grey-light py-3 text-sm font-medium uppercase">
            <h3>{matchStage && stage[matchStage]}</h3>
          </div>
          <div className="mt-4 flex flex-col gap-4 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-5">
            {fixture?.matches?.map((match) => {
              const [teamA, teamB] = match.teams;
              if (match.status === "ongoing")
                return (
                  <div
                    className="rounded border border-grey-light"
                    key={match._id}
                  >
                    <div className="flex items-center justify-between border-b border-grey-light p-3 text-xs">
                      <p className="flex items-center gap-1">
                        Match <span>{match.name}</span>
                        <span>·</span>
                        <span>
                          {moment(match?.date).format("ll")} -{" "}
                          {moment(match?.time).format("HH:mm a")}
                        </span>
                      </p>
                      {isTournamentAuthor && match.status === "ongoing" && (
                        <button
                          className="flex items-center gap-1 rounded-full bg-grey-lighter px-3 py-1 text-primary-black-darker"
                          type="button"
                          onClick={() => handleEditFixtures(match)}
                        >
                          <span className="text-base">
                            <LaEditPencil />
                          </span>
                          Edit
                        </button>
                      )}
                    </div>
                    <div className="flex items-center justify-between px-3 py-4 font-medium capitalize">
                      {teamA ? (
                        <Link
                          to={`/profiles/team/${teamA?.name?.replace(
                            /\s/,
                            "-"
                          )}`}
                          className="flex items-center gap-2"
                        >
                          <img
                            src={teamA?.avatar}
                            alt=""
                            className="h-5 w-5 rounded-full"
                          />
                          <p className="uppercase">{teamA?.shortName}</p>
                        </Link>
                      ) : (
                        <div className="flex items-center gap-2">
                          <img
                            src={""}
                            alt=""
                            className="h-5 w-5 rounded-full bg-grey"
                          />
                          <p className="max-w-[60px]">
                            Match {match?.refMatch1[0]?.name} Winner
                          </p>
                        </div>
                      )}
                      {isTournamentAuthor ? (
                        <Link
                          to={`/competitions/scores/edit/${match._id}`}
                          className="rounded bg-grey-lighter px-3 py-1 font-bold text-primary-black-darker"
                        >
                          Enter Scores
                        </Link>
                      ) : (
                        <div className="text-sm">
                          {match.time ? (
                            <div className="flex flex-col items-center justify-center">
                              <p className="uppercase">
                                {moment(match?.time).format("HH:mm a")}
                              </p>
                              <p>{moment(match?.date).format("ll")}</p>
                            </div>
                          ) : (
                            <p>TBD</p>
                          )}
                        </div>
                      )}
                      {teamB ? (
                        <Link
                          to={`/profiles/team/${teamB?.name?.replace(
                            /\s/,
                            "-"
                          )}`}
                          className="flex flex-row-reverse items-center gap-2"
                        >
                          <img
                            src={teamB?.avatar}
                            alt=""
                            className="h-5 w-5 rounded-full"
                          />
                          <p className="uppercase">{teamB?.shortName}</p>
                        </Link>
                      ) : (
                        <div className="flex items-center gap-2">
                          <img
                            src={""}
                            alt=""
                            className="h-5 w-5 rounded-full bg-grey"
                          />
                          <p className="max-w-[60px]">
                            Match {match?.refMatch2[0]?.name} Winner
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-2 px-3 pb-3 text-xs font-medium">
                      <LaLocation />
                      <p>{match.location}</p>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      );
    });

  return (
    <>
      {fixtureCardGroup?.length === 0 && (
        <EmptyStateAuto
          title="Nothing to see here"
          description="This tournament does not have any fixture yet. when it does it'll appear here"
        />
      )}

      {isCompleted ? (
        <EmptyStateAuto
          title="Matches Completed"
          description="All matches in this tournament have been completed"
        />
      ) : (
        <div className="flex flex-col gap-4 px-4 lg:px-0">
          {fixtureCardGroup}
        </div>
      )}
      {isEditing && (
        <EditFixtureModal matchData={matchData} setIsEditing={setIsEditing} />
      )}
    </>
  );
}

export default KnockoutFixtures;
