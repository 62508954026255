export default function UpdateSkeleton() {
  return (
    <div
      className="animate-pulse border-b border-gray-500 pb-2"
      aria-label="skeleton"
    >
      <div className="mb-2 h-5 w-full rounded bg-gray-400" />
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start gap-4">
          <div className="h-4 w-20 rounded bg-gray-400" />
          <div className="h-4 w-14 rounded bg-gray-400" />
          <div className="h-4 w-16 rounded bg-gray-400" />
        </div>
      </div>
    </div>
  );
}
