function LaPlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        d="M5.5 10h10M10.5 15V5"
        stroke={props.stroke || "#000"}
        strokeWidth={props.strokeWidth || 2}
      />
    </svg>
  );
}

export default LaPlusIcon;
