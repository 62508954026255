import * as React from "react";

function LaNumberOne(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 38" fill="none" {...props}>
      <path
        d="M17.154 33.926H11.26V11.2l-7.038 2.183V8.59l12.301-4.406h.632v29.742z"
        fill="#FFEF9C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.636 33.927a3.481 3.481 0 01-3.482 3.481H11.26a3.481 3.481 0 01-3.481-3.481v-18l-2.525.783A3.481 3.481 0 01.74 13.385V8.59c0-1.47.923-2.782 2.307-3.278L15.348.907a3.48 3.48 0 011.174-.204h.632a3.481 3.481 0 013.482 3.482v29.742zm-9.377-22.725v22.725h5.895V4.185h-.632L4.222 8.59v4.794l7.037-2.183z"
        fill="#E89B05"
      />
    </svg>
  );
}

export default LaNumberOne;
