import type { ReactElement } from "react";
import PlayerBadge from "../ui/cards/PlayerBadge";
import { LaClose } from "../svg-icons";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setModalToDisplay,
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useRatePlayerMutation } from "../../redux/services/ratingService";
import { cn } from "../../utils/cn";
import LaSpinner from "../svg-icons/LaSpinner";
import useOnClickOutside from "../../hooks/useClickedOutside";
import React from "react";
import { useNavigate } from "react-router-dom";
import { calcPercent } from "../../utils/calculatePercent";

export interface VotePlayerProps {
  playerObj?: TTraining["players"][0];
  trainingId?: string;
  canRate?: boolean;
  totalRates?: number;
}

export default function VotePlayer({
  playerObj,
  trainingId,
  canRate,
  totalRates,
}: VotePlayerProps): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [votePlayer, { isLoading: isVoting }] = useRatePlayerMutation();
  const [votePercent, setVotePercent] = React.useState(0);
  const rounded = votePercent < 100 ? "rounded-[4px_0_0_4px]" : "rounded";
  const insideEl = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(insideEl, () => dispatch(setModalToDisplay("")));
  const player = playerObj?.player;

  console.log(votePercent);

  function handleClose() {
    dispatch(setModalToDisplay(""));
    navigate(-1);
  }

  function handleVotePlayer() {
    votePlayer({ player: player?._id!, training: trainingId })
      .unwrap()
      .then((res) => {
        console.log(res);
        setVotePercent(
          calcPercent(playerObj?.rating || 0 + 1, totalRates || 0)
        );
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        dispatch(setToastError(true));
        console.log(err, "errk");
      });
  }

  return (
    <div className="absolute bottom-0 right-0 top-0 min-h-screen w-[100vw] justify-center bg-black/20 lg:flex">
      <div className="flex h-screen w-full items-center justify-center px-4 lg:px-0">
        <div
          className="relative z-[60] flex w-full flex-col items-center justify-center rounded-xl bg-white shadow lg:w-[73%] lg:py-16"
          ref={insideEl}
        >
          <div className="flex w-full flex-col items-center justify-center px-4 pb-4 lg:w-[628px] lg:p-0">
            <h3 className="mt-20 w-[280px] text-center text-xl font-bold lg:w-[560px] lg:text-4xl lg:font-normal">
              🏆 Vote as Man of the Match 🏆
            </h3>

            <div className="mt-3 w-[173px] lg:mt-6">
              <PlayerBadge
                appearance={player?.apps!}
                avatar={player?.avatar!}
                assist={player?.assists!}
                goals={player?.goals!}
                playerName={player?.name!}
                playerWing={player?.position!}
              />
            </div>
            <p className="mt-3 text-base capitalize lg:mt-6 lg:text-2xl">
              {player?.name}
            </p>
            {!canRate ? (
              <div className="relative mt-6 w-full rounded bg-primary-blue-lighter lg:mt-14">
                <div
                  className={cn(
                    `flex items-center bg-primary-blue-light px-1 py-2 lg:py-3 ${rounded}`,
                    {
                      "justify-center": votePercent > 30,
                    }
                  )}
                  style={{ width: `${votePercent}%` }}
                >
                  <p className="text-sm font-medium capitalize lg:text-2xl">
                    {player?.name}
                  </p>
                </div>
                <p className="absolute right-4 top-[10px] text-xs font-bold lg:top-3 lg:text-2xl lg:font-normal">
                  {votePercent}%
                </p>
              </div>
            ) : (
              <button
                className="mt-6 flex w-full items-center justify-center gap-2 rounded bg-primary-blue-darker py-4 text-base font-medium leading-3 text-white hover:bg-primary-blue-light lg:mt-14 lg:gap-4 lg:text-2xl"
                onClick={handleVotePlayer}
                disabled={isVoting}
              >
                Vote player{" "}
                {isVoting && (
                  <span className="animate-spin">
                    <LaSpinner />
                  </span>
                )}
              </button>
            )}
          </div>
          <button
            className="absolute right-6 top-6 lg:right-10 lg:top-10"
            onClick={handleClose}
          >
            <LaClose />
          </button>
        </div>
      </div>
    </div>
  );
}
