const faq = [
  {
    question: "How do I organize competitions?",
    answer:
      "After creating profile, as a sport personnel you can switch to organizers profile on the players profile dashboard, and click on the create button, there you will see the option to create a competition.",
  },
  {
    question: "Is it free to join?",
    answer:
      "Yes, it is absolutely free to join Leagues Arena. Register by clicking the get started button.",
  },
  {
    question: "What sports can be organized on Leagues Arena?",
    answer:
      "We support all sports including football, lawn tennis, basketball, e-sport, golf, cycling badminton, boxing, table tennis, and paintball.",
  },
  {
    question: "Can I have more than one account?",
    answer:
      "Yes, you can have more than one account with different registered email addresses. However, you can only have one account per email address.",
  },
  {
    question: "Can I invite my friends to join Leagues Arena?",
    answer:
      "Absolutely! Leagues Arena encourages users to invite their friends and build a thriving sports community together. Share your unique referral link or send invitations through email or social media to bring your friends on board.",
  },
];

export default faq;
