import React from "react";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { Feeds, HomeMatches } from "../../components/home";
import { Button } from "../../components/ui";
import { cn } from "../../utils/cn";
import HelmetComponent from "../../components/HelmetComponent";
import SpotlightFeed from "../../components/home/SpotlightFeed";

export default function Home() {
  const [currentTab, setCurrentTab] = React.useState<
    "events" | "feed" | "spotlight"
  >("spotlight");
  const role = useAppSelector((state: RootState) => state.auth.role);
  // Open Graph Data
  const ogData = {
    title: "Leagues Arena - Feeds, Matches and Scores",
    description:
      "Discover the latest feeds, matches and scores on Leagues Arena",
    url: `https://${window.location.host}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      {
        <section className="flex flex-col items-center justify-center">
          <section className="h-full w-full pb-[90px] lg:max-w-[87%] lg:pb-0">
            <h3 className="mt-16 hidden text-2xl font-medium lg:block">Home</h3>
            {/* Tabs */}
            <section className="flex w-full items-center border-b border-gray-400 lg:gap-6">
              <Button
                role={role}
                intent="secondary"
                className={cn(
                  "w-full rounded-none border-0 py-4 text-center font-semibold text-primary-black-darker hover:bg-white lg:w-auto lg:px-3",
                  {
                    "border-b-2": currentTab === "spotlight",
                  }
                )}
                onClick={() => setCurrentTab("spotlight")}
              >
                Spotlight
              </Button>
              {/* <Button
                role={role}
                intent="secondary"
                className={cn(
                  "w-full rounded-none border-0 py-4 text-center font-semibold text-primary-black-darker hover:bg-white lg:w-auto lg:px-3",
                  {
                    "border-b-2": currentTab === "feed",
                  }
                )}
                onClick={() => setCurrentTab("feed")}
              >
                Feeds
              </Button> */}
              <Button
                role={role}
                intent="secondary"
                className={cn(
                  "w-full rounded-none border-0 py-4 text-center font-semibold text-primary-black-darker hover:bg-white lg:w-auto lg:px-3",
                  {
                    "border-b-2": currentTab === "events",
                  }
                )}
                onClick={() => setCurrentTab("events")}
              >
                Matches and Scores
              </Button>
            </section>
            {/* Tab Contents */}
            <section className="">
              {currentTab === "feed" && (
                <div className="flex flex-col gap-5 p-4 lg:px-0 lg:py-6">
                  <Feeds />
                </div>
              )}
              {currentTab === "spotlight" && <SpotlightFeed />}
              {currentTab === "events" && <HomeMatches />}
            </section>
          </section>
        </section>
      }
    </>
  );
}
