import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { LoginForm, SigninCarousel } from "../../components/accounts";
import { OrDivider, SocialBtn, Spinner } from "../../components/ui";
import carouselData from "../../assets/data/carousel";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { setModalToDisplay } from "../../redux/features/modal/displayModalSlice";
import useImagePreloader from "../../hooks/useImagePreloader";
import { RootState } from "../../redux/app/store";
import { useGoogleLoginQuery } from "../../redux/services/authService";
import { NavBar } from "../../components/navigations";
import { LaGoogle, LaEnvelope, LaWhatsapp } from "../../components/svg-icons";
import HelmetComponent from "../../components/HelmetComponent";

function Login() {
  const dispatch = useAppDispatch();
  const { resetToken } = useParams();
  const { data: query = { url: "" }, isLoading } = useGoogleLoginQuery();
  const { isLoggedIn, isBoarded, role } = useAppSelector(
    (state: RootState) => state.auth
  );
  const { imagesPreloaded } = useImagePreloader(
    carouselData.map((item) => item.src)
  );
  const [loginOption, setLoginOption] = React.useState<
    "email address" | "phone number"
  >("phone number");

  React.useEffect(() => {
    if (resetToken) {
      dispatch(setModalToDisplay("reset-password"));
    }
    return;
  }, []);

  function handleGoogleLogin() {
    window.location.replace(query?.url);
  }

  function handleLoginOption() {
    loginOption === "email address"
      ? setLoginOption("phone number")
      : setLoginOption("email address");
  }
  // open graph data
  const ogData = {
    title: "Leagues Arena - Login",
    description: "Login to Leagues Arena",
    url: `https://${window.location.host}/account/login`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };
  return (
    <>
      <HelmetComponent ogData={ogData} />
      {isLoggedIn && isBoarded && role ? (
        <Navigate to="/home" />
      ) : !imagesPreloaded && isLoading ? (
        <div className="flex h-screen w-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <NavBar />
          <div className="relative flex w-full flex-col items-center justify-center px-4 py-12 pt-8 md:py-16 lg:flex-row lg:items-stretch lg:justify-between lg:px-20 lg:py-20">
            <div className="left flex w-full flex-col items-center md:w-[65%] lg:w-[40%]">
              <h1 className="text-3xl font-medium md:text-5xl">
                {/* Welcome back! */}
                Sign In
              </h1>
              <LoginForm loginOption={loginOption} />
              <div className="my-8">
                <OrDivider />
              </div>
              <div className="flex w-full flex-col gap-6 text-inherit">
                <SocialBtn
                  text="Login using Google"
                  icon={<LaGoogle />}
                  handleClick={handleGoogleLogin}
                />
                <SocialBtn
                  text={`Login using ${
                    loginOption === "email address"
                      ? "Phone number"
                      : "Email addresss"
                  }`}
                  icon={
                    loginOption === "email address" ? (
                      <LaWhatsapp className="text-green" />
                    ) : (
                      <LaEnvelope className="text-primary-black-darker" />
                    )
                  }
                  handleClick={handleLoginOption}
                />
              </div>
            </div>
            <div className="right hidden w-[50%] lg:block">
              <SigninCarousel />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Login;
