import React from "react";
import { LaCaretDown, LaCaretUp } from "../../svg-icons";
import moment from "moment";
import countReducer from "../../../utils/countReducer";
import { capitalize } from "../../../utils/capitalize";
import { Link } from "react-router-dom";

type Props = {
  match: Match;
};

export default function MatchCard({ match }: Props) {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [teamA, teamB] = match?.teamData || [];

  const teamAYellowCard = teamA?.yellowCards?.length;
  const teamBYellowCard = teamB?.yellowCards?.length;
  const teamARedCard = teamA?.redCards?.length;
  const teamBRedCard = teamB?.redCards?.length;

  const teamAYellowCardPercent =
    (teamAYellowCard / (teamAYellowCard + teamBYellowCard)) * 100;
  const teamBYellowCardPercent =
    (teamBYellowCard / (teamBYellowCard + teamAYellowCard)) * 100;

  const teamARedCardPercent =
    (teamARedCard / (teamARedCard + teamBRedCard)) * 100;
  const teamBRedCardPercent =
    (teamBRedCard / (teamBRedCard + teamARedCard)) * 100;

  // convert the yellow card or red card user object array to key value pair with each object containing the user, and the number of yellow cards
  const teamAYellowCards = countReducer(teamA?.yellowCards);
  const teamBYellowCards = countReducer(teamB?.yellowCards);
  const teamARedCards = countReducer(teamA?.redCards);
  const teamBRedCards = countReducer(teamB?.redCards);

  const isCompleted = match.status === "completed";

  return (
    <aside
      aria-label="match-card"
      onClick={() => isCompleted && setCollapsed(!collapsed)}
      className={`flex flex-col items-center gap-3 rounded border border-gray-300 px-4 py-3 ${
        isCompleted && "cursor-pointer"
      }`}
    >
      <p className="text-center text-xs font-medium">
        Match {match.name} ·{" "}
        {match.date ? moment(match.date).format("ll") : "TBD"}
      </p>
      <div
        className={`flex w-full items-center lg:pr-20 ${
          !isCompleted && "lg:pl-20"
        }`}
      >
        {isCompleted && (
          <span className="hidden text-2xl lg:mr-12 lg:block">
            {collapsed ? <LaCaretUp /> : <LaCaretDown />}
          </span>
        )}
        <div className={`grid w-full grid-cols-3 items-center justify-between`}>
          <Link
            to={
              `/profiles/team/${teamA?.team?.name?.replace(/\s/g, "-")}` || "#"
            }
            className="flex items-center gap-2"
          >
            <img
              src={teamA?.team?.avatar}
              alt=""
              className="h-8 w-8 overflow-hidden rounded-full"
            />
            <p className="hidden text-center lg:block">
              {capitalize(teamA?.team?.name)}
            </p>
            <p className="text-center uppercase lg:hidden">
              {teamA?.team?.shortName}
            </p>
          </Link>
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="uppercase">
              {isCompleted ? (
                <p className="font-medium">
                  {teamA.score} : {teamB.score}
                </p>
              ) : (
                <p>
                  {match.time ? moment(match.time).format("HH:mm a") : "TBD"}
                </p>
              )}
            </div>
          </div>
          <Link
            to={
              `/profiles/team/${teamB?.team?.name?.replace(/\s/g, "-")}` || "#"
            }
            className="flex items-center justify-end gap-2"
          >
            <p className="hidden text-center lg:block">
              {capitalize(teamB?.team?.name)}
            </p>
            <p className="text-center uppercase lg:hidden">
              {teamB?.team?.shortName}
            </p>
            <img
              src={teamB?.team?.avatar}
              alt=""
              className="h-8 w-8 overflow-hidden rounded-full"
            />
          </Link>
        </div>
      </div>
      <p
        className={`text-center text-sm font-medium text-grey ${
          collapsed && "border-b border-grey-light px-3 pb-3"
        }`}
      >
        {match.location}
      </p>
      {/* Collapsable section */}
      <div
        className={`${
          collapsed ? "block" : "hidden"
        } flex w-full items-center justify-center bg-grey-lighter`}
      >
        <section className={`w-full px-4 pb-8 lg:w-[37%] lg:px-0`}>
          <h4 className="text-center text-sm font-medium">Match Summary</h4>
          {/* Goals */}
          <p className="my-1 mt-4 text-center text-sm font-medium text-grey">
            Goals
          </p>
          {/* Goals */}
          <div className="flex w-full justify-between text-xs lg:text-sm">
            <div className="flex flex-col gap-2">
              {teamA.scorers?.map((scorer, i) => (
                <div key={i} className="flex items-center gap-2">
                  <p className="capitalize">{scorer?.player?.name}</p>
                  <p className="capitalize">{scorer?.goals}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-2">
              {teamB?.scorers?.map((scorer, i) => (
                <div
                  key={i}
                  className="flex flex-row-reverse items-center gap-2"
                >
                  <p className="capitalize">{scorer?.player?.name}</p>
                  <p className="capitalize">{scorer?.goals}</p>
                </div>
              ))}
            </div>
          </div>
          {/* Assists */}
          <p className="my-1 text-center text-sm font-medium text-grey">
            Assists
          </p>
          <div className="flex items-center justify-between text-xs lg:text-sm">
            <div className="flex flex-col gap-2">
              {teamA.assisters?.map((assister, i) => (
                <div key={i} className="flex items-center gap-2">
                  <p className="capitalize">{assister?.player?.name}</p>
                  <p className="capitalize">{assister?.assists}</p>
                </div>
              ))}
            </div>

            <div className="flex flex-col gap-2">
              {teamB?.assisters?.map((assister, i) => (
                <div
                  key={i}
                  className="flex flex-row-reverse items-center gap-2 text-xs lg:text-sm"
                >
                  <p className="capitalize">{assister?.player?.name}</p>
                  <p className="capitalize">{assister?.assists}</p>
                </div>
              ))}
            </div>
          </div>
          {/* Cards stats */}
          <div className="mt-4 flex flex-col gap-4">
            <aside className="">
              <p className="text-center text-sm font-medium text-gray-600">
                Yellow card
              </p>
              <div className="mt-2 flex">
                <div
                  className={`h-2  ${
                    teamAYellowCardPercent > teamBYellowCardPercent
                      ? "bg-yellow"
                      : "bg-gray-200"
                  } rounded-[999px_0_0_999px]`}
                  style={{
                    width: `${teamAYellowCardPercent}%`,
                  }}
                />
                <div
                  className={`h-2 ${
                    teamBYellowCardPercent >= teamAYellowCardPercent
                      ? "bg-yellow"
                      : "bg-gray-200"
                  } rounded-[0_999px_999px_0]`}
                  style={{
                    width: `${teamBYellowCardPercent}%`,
                  }}
                />
              </div>
              <div className="mt-1 flex justify-between text-xs font-medium lg:text-sm">
                <div className="flex flex-col gap-1">
                  {teamAYellowCards?.map((yellowCardObj, i) => (
                    <p className="flex items-center gap-2 capitalize" key={i}>
                      <span>{yellowCardObj?.player?.name}</span>
                      <span>{yellowCardObj?.cards}</span>
                    </p>
                  ))}
                </div>
                <div className="flex flex-col gap-1">
                  {teamBYellowCards?.map((yellowCardObj, i) => (
                    <p
                      className="flex flex-row-reverse items-center gap-2 capitalize"
                      key={i}
                    >
                      <span>{yellowCardObj?.player?.name}</span>
                      <span>{yellowCardObj?.cards}</span>
                    </p>
                  ))}
                </div>
              </div>
            </aside>
            {/* Red Cards stats */}
            <aside className="w-full">
              <p className="my-1 text-center text-sm font-medium text-gray-600">
                Red card
              </p>
              <div className="mt-2 flex">
                <div
                  className={`h-2  ${
                    teamARedCardPercent >= teamBRedCardPercent
                      ? "bg-red"
                      : "bg-gray-200"
                  } rounded-[999px_0_0_999px]`}
                  style={{
                    width: `${teamARedCardPercent}%`,
                  }}
                />
                <div
                  className={`h-2 ${
                    teamBRedCardPercent > teamARedCardPercent
                      ? "bg-red"
                      : "bg-gray-200"
                  } rounded-[0_999px_999px_0]`}
                  style={{
                    width: `${teamBRedCardPercent}%`,
                  }}
                />
              </div>
              <div className="mt-1 flex justify-between text-xs font-medium lg:text-sm">
                <div className="flex flex-col gap-1">
                  {teamARedCards?.map((redCardObj, i) => (
                    <p className="flex items-center gap-2 capitalize" key={i}>
                      <span>{redCardObj?.player.name}</span>
                      <span>{redCardObj?.cards}</span>
                    </p>
                  ))}
                </div>
                <div className="flex flex-col gap-1">
                  {teamBRedCards?.map((redCardObj, i) => (
                    <p
                      className="flex flex-row-reverse items-center gap-2 capitalize"
                      key={i}
                    >
                      <span>{redCardObj?.player?.name}</span>
                      <span>{redCardObj?.cards}</span>
                    </p>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </section>
      </div>
    </aside>
  );
}
