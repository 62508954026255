import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const userApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlayer: builder.query<TPlayer | undefined, { name: string | undefined }>(
      {
        query: ({ name }) => ({
          url: `/players/${name}`,
        }),
        transformResponse: (response: { data: TPlayer }) => response.data,
        providesTags: ["Player"],
      }
    ),
    getMyPlayer: builder.query<TPlayer | undefined, void>({
      query: () => ({
        url: `/players/me`,
      }),
      transformResponse: (response: { data: TPlayer }) => response.data,
      providesTags: ["Player"],
    }),
    getPlayers: builder.query<
      TPlayer[] | undefined,
      {
        count?: number;
        page?: number;
        orderBy?: string;
      }
    >({
      query: ({ count, page, orderBy }) => ({
        url: "/players",
        params: {
          count,
          page,
          orderBy,
        },
      }),
      transformResponse: (response: { data: TPlayer[] }) => response.data,
      providesTags: ["Player"],
    }),
    updatePlayer: builder.mutation({
      query: (body) => ({
        method: "PUT",
        url: "/players",
        body,
      }),
      invalidatesTags: ["Player", "User"],
    }),
    processTeamInvite: builder.mutation({
      query: (payload: {
        body: { teamId: string };
        param: "accept" | "decline";
      }) => ({
        method: "PUT",
        url: `/players/invite/${payload.param}`,
        body: payload.body,
      }),
      invalidatesTags: ["Team", "Player", "User"],
    }),
    requestTeamMembership: builder.mutation({
      query: (body: { teamId?: string }) => ({
        method: "PUT",
        url: "/players/request",
        body,
      }),
      invalidatesTags: ["Team", "User", "Player"],
    }),
    queryPlayerTeams: builder.query({
      query: ({ playerId }) => ({
        url: `/players/teams`,
        params: { playerId },
      }),
      transformResponse: (response: { team: Team[] }) => response.team,
      providesTags: ["Player", "Team"],
    }),
    createPlayers: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "/players/batch",
        body,
      }),
      invalidatesTags: ["Player", "Tournament", "Match"],
    }),
  }),
});

export const {
  useGetPlayerQuery,
  useGetPlayersQuery,
  useUpdatePlayerMutation,
  useProcessTeamInviteMutation,
  useRequestTeamMembershipMutation,
  useQueryPlayerTeamsQuery,
  useGetMyPlayerQuery,
  useCreatePlayersMutation,
} = userApi;
