import { css, styled } from "styled-components";

interface BorderBottomProps {
  $userRole?: string | undefined;
  $isActive: boolean;
}

const BorderBottom = styled.button<BorderBottomProps>`
  color: #767676;

  ${(props) =>
    props.$isActive &&
    `
  border-bottom: 2px solid #0045f6;
  `}

  ${(props) =>
    props.$isActive &&
    props.$userRole === "organizer" &&
    css`
      border-bottom: 2px solid #1a1a1a;
      color: #1a1a1a;
    `}
  ${(props) =>
    props.$isActive &&
    props.$userRole === "player" &&
    css`
      border-bottom: 2px solid #0045f6;
      color: #0045f6;
    `}
`;

export default BorderBottom;
