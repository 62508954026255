import { Link } from "react-router-dom";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";

type Props = {
  avatar: { path: string };
  username?: string;
  name?: string;
  position?: string;
  draws?: number;
  played?: number;
  wins?: number;
  losses?: number;
  _id?: string;
  tournamentStats?: { played: string; draw: string; wins: string };
};

export default function SearchResult({ data }: { data: Props }) {
  const { lastTab } = useAppSelector((state: RootState) => state.search);
  let route = "/";

  if (lastTab === "Organizer") {
    route = `/profiles/organizer/${data?.name?.replace(/\s/g, "-")}`;
  } else if (lastTab === "Player") {
    route = `/profiles/player/${data?.name?.replace(/\s/g, "-")}`;
  } else if (lastTab === "Competitions") {
    route = `/competitions/${data._id}`;
  } else if (lastTab === "Team") {
    route = `/profiles/team/${data?.name?.replace(/\s/g, "-")}`;
  }

  return (
    <Link to={route} className={`w-full`}>
      <div className="flex items-center gap-2">
        <div className="h-[48px] w-[48px]">
          <img
            src={`${data?.avatar}`}
            className="h-full w-full rounded-full object-cover"
            crossOrigin="anonymous"
          />
        </div>
        {/* Removed className="hidden md:block" to make ?.name visible on mobile */}
        <p className="hidden md:block">{data.name}</p>
      </div>
      <div className="ml-2 flex flex-col justify-center gap-y-1 ">
        <p className="md:hidden">{data?.name}</p>

        <div className="flex gap-x-5">
          <div className="flex flex-col items-center text-grey">
            <p className="text-sm font-medium">Competitions</p>
            <div className="flex items-center gap-2 text-[10px] font-bold">
              <p>P {data?.tournamentStats?.played}</p>
              <p>W {data?.tournamentStats?.wins}</p>
            </div>
          </div>
          <div className="flex flex-col items-center text-grey">
            <p className="text-sm font-medium">Matches</p>
            <div className="flex items-center gap-2 text-[10px] font-bold">
              <p>P{data?.played}</p>
              <p>W{data?.wins}</p>
              <p>D{data?.draws}</p>
              <p>L{data?.losses}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
