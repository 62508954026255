/* eslint-disable @typescript-eslint/no-unused-vars */ import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
// import { combinedApi } from "./combinedApi";
const baseUrl = import.meta.env.VITE_API_BASE_URL;

export const leaguesArenaApi = createApi({
  reducerPath: "leaguesArenaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Tournament",
    "Posts",
    "User",
    "Team",
    "TournamentRequests",
    "Result",
    "Schedule",
    "Notification",
    "Match",
    "ProSubscription",
    "Rating",
    "Training",
    "Player",
    "Organizer",
    "Wallet",
    "Spotlight",
    "SpotlightComment",
  ],
  endpoints: (_builder) => ({}),
});
