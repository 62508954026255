import React from "react";
import { RootState } from "../redux/app/store";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import { setSignals } from "../redux/features/socket/socketSlice";

export default function RingPlayer() {
  const dispatch = useAppDispatch();
  const aRef = React.useRef<HTMLAudioElement>(null);
  const { emitted, received: received, incomingCount } = useAppSelector(
    (state: RootState) => state.wsNotification
  );

  React.useEffect(() => {
    if (emitted || received) {
      aRef.current?.play();
    } else {
      aRef.current?.pause();
    }
  }, [emitted, received, aRef]);

  return (
    <audio
      src="/assets/whistle.WAV"
      className="sr-only"
      ref={aRef}
      onEnded={() => {
        dispatch(
          setSignals({ emitted: false, received: false, count: incomingCount })
        )
      }
      }
      preload="auto"
    />
  );
}
