import { useState, type ReactElement, useRef } from "react";
import { LaClose, LaPlusIcon } from "../svg-icons";
import { Button } from "../ui";
import { CreateTeamsForCompetitionForm } from "../../pages/tournament/CreateTeamsFormForCompetition";
import { useCreateTeamsMutation } from "../../redux/services/teamService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup } from "../modals";
import LaSpinner from "../svg-icons/LaSpinner";

export interface CreateTeamsForCompetitionProps {
  teamsInfo: CreateTeamsForCompetitionForm[];
  setTeamsInfo: React.Dispatch<
    React.SetStateAction<CreateTeamsForCompetitionForm[]>
  >;
  selectedCompetition: string;
  setSelectedCompetition: React.Dispatch<React.SetStateAction<string>>;
  maxTeam: number;
  noOfJoinedTeams: number;
}

export default function CreateTeamsForCompetition({
  teamsInfo,
  setTeamsInfo,
  setSelectedCompetition,
  selectedCompetition,
  maxTeam,
  noOfJoinedTeams,
}: CreateTeamsForCompetitionProps): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [createTeams, { isLoading }] = useCreateTeamsMutation();
  const [modalToDisplay, setModalToDisplay] = useState<
    "success" | "incomplete" | ""
  >("");

  function handleClick() {
    if (noOfJoinedTeams + teamsInfo.length < maxTeam)
      return setModalToDisplay("incomplete");
    return handleCreateTeams();
  }

  const handleCreateTeams = () => {
    createTeams({
      teams: teamsInfo,
      tournamentId: selectedCompetition,
      paymentStatus: "pending",
    })
      .unwrap()
      .then(() => {
        setModalToDisplay("success");
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        console.log(err);

        if (err.data.message.includes("E11000")) {
          const match = err.data.message.match(/name: "(.*?)"/);
          const name = match ? match[1] : "Team";
          return dispatch(setToastErrorText(`${name} already exists`));
        }
        dispatch(setToastErrorText(err.data.message));
      });
  };

  function handleClose() {
    setTeamsInfo([]);
    setSelectedCompetition("none");
  }
  return (
    <>
      <div className="fixed inset-0 left-auto top-auto z-[90] flex w-full flex-col rounded border-t border-grey-light bg-white px-4 py-6 shadow lg:max-w-[82%] lg:rounded-[16px_16px_0_0] lg:px-20 lg:py-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <h3 className="flex items-center gap-2 font-medium lg:text-2xl">
              Add
              <span className="flex h-5 w-5 items-center justify-center rounded-full bg-primary-blue-lighter text-xs">
                {teamsInfo.length}
              </span>
              teams
            </h3>
          </div>
          <button onClick={handleClose}>
            <LaClose />
          </button>
        </div>
        <Button
          role="organizer"
          intent={"primary"}
          className="mt-4 items-center gap-1 lg:flex"
          onClick={handleClick}
        >
          Send Invitation
          {isLoading && <LaSpinner className="animate-spin" />}
        </Button>
      </div>
      {modalToDisplay === "incomplete" && (
        <ConfirmPopup
          heading={"Incomplete team"}
          type="warning"
          description={`${
            noOfJoinedTeams + teamsInfo.length
          } out of ${maxTeam} teams will be registered. Do you want to proceed?`}
          warning="Teams must be complete to generate fixtures."
          buttons={[
            {
              text: "Add more teams",
              handleClick: () => setModalToDisplay(""),
              class: "primary",
              icon: <LaPlusIcon stroke="#fff" />,
            },
            {
              text: "Yes, I want to proceed",
              handleClick: () => {
                setModalToDisplay("");
                handleCreateTeams();
              },
              class: "tertiary",
            },
          ]}
        />
      )}
      {modalToDisplay === "success" && (
        <ConfirmPopup
          heading={"Success!"}
          type="success"
          description={`Your Teams profiles are ready. They will be notified via contact number you provided.`}
          buttons={[
            {
              text: "Got it",
              handleClick: () => {
                setSelectedCompetition("none");
                setTeamsInfo([]);
                navigate(`/competitions/${selectedCompetition}`);
              },
              class: "primary",
            },
          ]}
        />
      )}
    </>
  );
}
