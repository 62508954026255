import { LaClose } from "../svg-icons";
import { Link } from "react-router-dom";
import React from "react";
import useOnClickOutside from "../../hooks/useClickedOutside";
import { Input, Button, Spinner } from "../ui";
import { cn } from "../../utils/cn";
import { useForm, SubmitHandler } from "react-hook-form";
import { useUpdateTournamentMutation } from "../../redux/services/tournamentService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useNavigate } from "react-router-dom";

interface Props {
  setFixtureModal: React.Dispatch<React.SetStateAction<boolean>>;
  tournament?: Tournament;
}

export default function CreateFixturesModal({
  setFixtureModal,
  tournament,
}: Props) {
  const insideEl = React.useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Record<"numb_of_groups" | "team_per_group", number>>({});
  const [updateTournament, { isLoading: isUpdating }] =
    useUpdateTournamentMutation();
  const tournamentId = tournament?._id;
  const noOfRegisteredTeams =
    tournament?.teams.filter((team) => team.registered).length || 0;
  const max = Math.floor(noOfRegisteredTeams / 2);

  useOnClickOutside(insideEl, () => setFixtureModal(false));

  const onSubmit: SubmitHandler<
    Record<"numb_of_groups" | "team_per_group", number>
  > = (data) => {
    const formData = new FormData();
    formData.set("numb_of_groups", data.numb_of_groups.toString());
    formData.set("team_per_group", data.team_per_group.toString());

    updateTournament({ tournamentId: tournamentId as string, body: formData })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          navigate(`/competitions/${tournamentId}/publish-group`);
        }, 1000);
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  };

  if (tournament?.stage !== "knockout") {
    return (
      <div className="fixed inset-0 left-auto z-[60] flex w-full items-end justify-end bg-black/10 lg:w-[82%]">
        <div
          className="flex w-full flex-col rounded border-t border-grey-light bg-white shadow lg:rounded-[16px_16px_0_0]"
          ref={insideEl}
        >
          <div className="flex items-center justify-between border-b border-grey-light p-4 lg:px-20 lg:py-10">
            <h3 className="font-medium lg:text-2xl">Group Fixture creation</h3>
            <button
              onClick={() => {
                setFixtureModal(false);
              }}
            >
              <LaClose />
            </button>
          </div>
          <div className="p-4 lg:px-20 lg:py-10">
            <p>How would you like to create your fixtures?</p>
            <form
              className="my-6 flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <fieldset className="flex w-full flex-col items-stretch gap-2">
                <label htmlFor="numb_of_groups">
                  Number of groups to create
                  <span className="text-bold text-red">*</span>
                </label>
                <Input
                  type="number"
                  register={register}
                  role={"organizer"}
                  {...register("numb_of_groups", {
                    required: "Enter a number",
                    min: {
                      value: 1,
                      message: "Minimum number of groups is 1",
                    },
                    max: {
                      value: max,
                      message: `Maximum number of groups for your tournament is ${max}`,
                    },
                  })}
                  id="numb_of_groups"
                  className={cn(
                    "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                    {
                      "border-red focus:border-red focus:border-opacity-30":
                        errors?.numb_of_groups,
                    }
                  )}
                />
                {errors.numb_of_groups && (
                  <p className="text-red">{errors.numb_of_groups.message}</p>
                )}
              </fieldset>

              <fieldset className="flex w-full flex-col items-stretch gap-2">
                <label htmlFor="team_per_group">
                  Number of teams per group
                  <span className="text-bold text-red">*</span>
                </label>
                <Input
                  type="number"
                  register={register}
                  role={"organizer"}
                  {...register("team_per_group", {
                    required: "Enter a number",
                    min: {
                      value: 3,
                      message: "Minimum number of teams per group is 3",
                    },
                    max: {
                      value: noOfRegisteredTeams,
                      message: `Maximum number of teams per group for your tournament is ${noOfRegisteredTeams}`,
                    },
                  })}
                  id="team_per_group"
                  className={cn(
                    "px-4 py-3 text-xl placeholder:text-grey focus:border-4 focus:border-opacity-30",
                    {
                      "border-red focus:border-red focus:border-opacity-30":
                        errors?.team_per_group,
                    }
                  )}
                />
                {errors.team_per_group && (
                  <p className="text-red">{errors.team_per_group.message}</p>
                )}
              </fieldset>

              <Button
                disabled={
                  Boolean(!watch("numb_of_groups")) ||
                  Boolean(!watch("team_per_group"))
                }
                aria-disabled={
                  Boolean(!watch("numb_of_groups")) ||
                  Boolean(!watch("team_per_group"))
                }
                type="submit"
                role={"organizer"}
                intent={"primary"}
                className={cn(
                  "mt-6 flex w-full  items-center justify-center rounded  p-4 text-base text-white  md:mt-14 md:text-2xl"
                )}
              >
                {isUpdating ? (
                  <>
                    <Spinner />
                    <span className="ml-3">Please wait...</span>
                  </>
                ) : (
                  "Continue"
                )}
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 left-auto z-[60] flex items-end justify-end bg-black/10 lg:w-[82%]">
      <div
        className="flex w-full flex-col rounded border-t border-grey-light bg-white shadow lg:rounded-[16px_16px_0_0]"
        ref={insideEl}
      >
        <div className="flex items-center justify-between border-b border-grey-light p-4 lg:px-20 lg:py-10">
          <h3 className="font-medium lg:text-2xl">Fixture creation</h3>
          <button
            onClick={() => {
              setFixtureModal(false);
            }}
          >
            <LaClose />
          </button>
        </div>
        <div className="p-4 lg:px-20 lg:py-10">
          <p>How would you like to create your fixtures?</p>
          <div className="my-6 flex flex-col gap-4">
            <Link
              to={`/competitions/create-fixtures/${tournamentId}`}
              className="rounded border border-grey-light px-4 py-3 font-medium capitalize hover:bg-primary-black-lighter"
            >
              Upload manually
            </Link>
            <Link
              to={`/competitions/setup-fixtures/${tournamentId}`}
              className="rounded border border-grey-light px-4 py-3 font-medium capitalize hover:bg-primary-black-lighter"
            >
              Generate randomly
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
