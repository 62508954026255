import playerSlideOne from "../../assets/images/player-slide-one.webp";
import playerSlideOneM from "../../assets/images/player-slide-one-m.webp";
import playerSlideTwo from "../../assets/images/player-slide-two.webp";
import playerSlideTwoM from "../../assets/images/player-slide-two-m.webp";

export function PlayerSlideOne() {
  return (
    <div className="relative h-full w-full">
      <img
        src={playerSlideOne}
        alt="onboarding"
        className="hidden h-full w-full object-fill lg:block"
      />
      <img
        src={playerSlideOneM}
        alt="onboarding"
        className="h-full w-full object-fill lg:hidden"
      />
    </div>
  );
}

export function PlayerSlideTwo() {
  return (
    <div className="relative h-full w-full">
      <img
        src={playerSlideTwo}
        alt="onboarding"
        className="hidden h-full w-full object-fill lg:block"
      />
      <img
        src={playerSlideTwoM}
        alt="onboarding"
        className="h-full w-full object-fill lg:hidden"
      />
    </div>
  );
}
