import type { ReactElement } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { cn } from "../../utils/cn";
import { useOnboardUserMutation } from "../../redux/services/userService";
import { RegisteredSelect, Spinner } from "../ui";
import React from "react";
import { LaArrowRight, LaGalleryEdit } from "../svg-icons";
import PositionSelect from "../ui/select/PositionSelect";
import { UserToken } from "../../@types/token";
import jwtDecode from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import socket from "../../socket";
import { setToastError } from "../../redux/features/modal/displayModalSlice";
import { ConfirmPopup } from "../modals";
import { SetURLSearchParams, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";

const PlayerFormSchema = z.object({
  name: z
    .string()
    .min(3, { message: "Player name is required" })
    .max(50, { message: "Player name is too long" })
    .refine((value) => !/\s/.test(value), {
      message: "Player name cannot contain spaces",
    }),
  bio: z.string().max(250).optional(),
  location: z.string().min(3).max(50),
  position: z.string().min(2),
  avatar: z.any(),
});

export type TPlayerForm = z.infer<typeof PlayerFormSchema> & {
  avatar: FileList;
};

export interface PlayerFormProps {
  setOnBoardingParams: SetURLSearchParams;
}

export default function PlayerForm({
  setOnBoardingParams,
}: PlayerFormProps): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [previewAvatar, setPreviewAvatar] = React.useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TPlayerForm>({
    resolver: zodResolver(PlayerFormSchema),
  });
  const watchAvatar = watch("avatar");
  const watchLocation = watch("location");
  const [onboardUser, { isLoading }] = useOnboardUserMutation();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const [showConfirmPopup, setShowConfirmPopup] = React.useState(false);

  const onSubmit = (data: TPlayerForm) => {
    const form = new FormData();
    const position =
      data.position && data.position?.split(" ")[0].replace(/\(|\)/g, "");

    form.append("name", data.name);
    form.append("location", data.location);
    data.bio && form.append("bio", data.bio);
    data.avatar[0] && form.append("avatar", data.avatar[0]);
    form.append("role", "player");
    form.append("position", position);

    onboardUser(form)
      .unwrap()
      .then((res) => {
        /* Almost not neccessary but needed just incase */
        // Update notification sys with current user details.
        localStorage.setItem("token", res.token);
        const decoded: UserToken = jwtDecode(res?.token) || {};
        if (!socket.connected && isLoggedIn) {
          socket.disconnect();
          const claimedId = decoded?._id;
          socket.auth = { claimedId };
          socket.connect();
        }
        setOnBoardingParams({
          step: "2",
          role: "player",
        });

        setShowConfirmPopup(true);
      })
      .catch((_err: unknown) => {
        console.log(_err);
        dispatch(setToastError(true));
      });
  };

  React.useEffect(() => {
    if (watchAvatar?.length) {
      const reader = new FileReader();
      reader.readAsDataURL(watchAvatar?.[0]);
      reader.onloadend = () => {
        setPreviewAvatar(reader?.result as string);
      };
    }
  });

  return (
    <>
      <div className="flex w-full flex-col items-center rounded bg-inherit px-4 md:rounded-2xl lg:px-10">
        <h2 className="text-2xl md:text-[2.5rem]">Almost there!</h2>
        <p className="mt-3 text-center text-base md:mx-20 md:mt-4 md:text-[1.5rem] md:leading-9 lg:mx-0">
          Fill in these details and Upload a picture so we can setup your
          profile.
        </p>
        <form
          className="mt-4 flex flex-col items-stretch gap-6 lg:mt-6 lg:w-[90%]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset>
            <label htmlFor="profilePhoto" className="text-sm md:text-base">
              Profile photo
            </label>
            <div className="relative mt-2 flex h-[70px] w-[70px] items-center justify-center lg:h-[100px] lg:w-[100px]">
              <img
                src={previewAvatar}
                alt=""
                className="h-full w-full rounded-full"
              />
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-full bg-[#0000004d]">
                <label className="absolute flex cursor-pointer flex-col items-center justify-center ">
                  <span className="text-2xl">
                    <LaGalleryEdit
                      stroke={errors.avatar ? "#E71D36" : "#fff"}
                    />
                  </span>{" "}
                  <input
                    type="file"
                    className="hidden"
                    {...register("avatar")}
                  />
                  <span
                    className={cn("text-[0.75rem] text-white lg:mt-2", {
                      "text-red": errors.avatar,
                    })}
                  >
                    Add Photo
                  </span>
                </label>
              </div>
            </div>
            {errors.avatar && (
              <p className="mt-2 text-sm text-red">
                {errors.avatar.message as string}
              </p>
            )}
          </fieldset>
          <fieldset>
            <label htmlFor="name" className="text-sm md:text-base">
              Player Name <span className="text-red">*</span>
            </label>
            <input
              type="text"
              id="name"
              {...register("name")}
              placeholder="Enter your player name"
              className={cn(
                "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30 lg:text-xl",
                {
                  "error border-red focus:border-red": errors.name,
                }
              )}
            />
            {errors.name && (
              <span className="mt-2 text-sm italic text-red">
                {errors.name?.message}
              </span>
            )}
          </fieldset>

          <fieldset>
            <label htmlFor="bio" className="text-sm md:text-base">
              Bio <span className="text-yellow">(Optional)</span>
            </label>
            <textarea
              id="bio"
              placeholder="Write anything..."
              {...register("bio")}
              className={cn(
                "mt-2 h-32 w-full resize-none rounded border border-grey-light px-4 py-3 text-base focus:border-primary-blue-dark focus:border-opacity-30 lg:text-xl",
                {
                  "error border-red focus:border-red": errors.bio,
                }
              )}
            />
            {errors.bio && (
              <span className="mt-2 text-sm italic text-red">
                {errors.bio?.message}
              </span>
            )}
          </fieldset>

          <fieldset className="flex w-full flex-col items-stretch gap-2">
            <label htmlFor="location" className="text-sm md:text-base">
              Location
              <span className="text-red"> *</span>
            </label>
            <RegisteredSelect
              placeholder="Select a location"
              name="location"
              id="location"
              register={register}
              setValue={setValue}
              value={watchLocation}
              error={errors.location?.message}
              fit={false}
              required="Please select a location"
            />
            {errors.location && (
              <span className="text-xs italic text-red">
                {errors.location.message}
              </span>
            )}
          </fieldset>

          <fieldset className="flex flex-col gap-2">
            <label htmlFor="position" className="text-sm font-medium">
              Position
            </label>
            <PositionSelect
              register={register}
              errors={errors}
              setValue={setValue}
              id="position"
              registerOpt="position"
              placeholder="Select position"
            />
          </fieldset>

          <button className="flex items-center justify-center rounded bg-primary-blue-dark p-4 text-base text-white hover:bg-primary-blue-light hover:text-black md:mt-6 md:text-2xl">
            {isLoading ? (
              <span className="animate-spin">
                <Spinner />
              </span>
            ) : (
              "Finish"
            )}
          </button>
        </form>
      </div>
      {showConfirmPopup && (
        <ConfirmPopup
          type="success"
          heading="Success"
          description="Your player profile is ready. You can join teams and training sessions"
          buttons={[
            {
              text: "Discover teams",
              class: "tertiary",
              handleClick: () => navigate("/discover/teams"),
              rIcon: <LaArrowRight stroke="#0045f6" />,
            },

            {
              text: "Go to home page",
              class: "primary",
              handleClick: () => navigate("/home"),
            },
          ]}
        />
      )}
    </>
  );
}
