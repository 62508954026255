import React, { useState } from "react";
import { LaCaretDown, LaCaretUp } from "../../svg-icons";

interface SelectProps {
  name: string;
  id: string;
  label?: string;
  placeholder: string;
  error?: string;
  clearError?: (name: string) => void;
  handleChange?: () => void;
  options: string[];
}

/**
 * @param {*} props
 * @returns
 * @example props {
 *  name: string,
 *  id: string,
 *  label: string,
 *  placeholder: string,
 *  handleChange: (e) => void,
 *  options: [string]
 */
function Select(props: SelectProps) {
  const [flip, setFlip] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  function handleClick() {
    setFlip(!flip);
  }

  function handleSelect(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    props?.clearError && props?.clearError(props.name);
    setSelectedOption(e.currentTarget.textContent || "");
  }

  return (
    <fieldset className="relative cursor-pointer" onClick={handleClick}>
      <input
        type="text"
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        readOnly
        required
        defaultValue={selectedOption}
        className={`w-full cursor-pointer rounded border border-grey-light px-4 py-3 text-base capitalize lg:text-xl  ${
          props?.error
            ? "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
            : "focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30"
        }`}
      />
      {flip ? (
        <span className="absolute right-3 top-[40%] bg-white">
          <LaCaretUp stroke="#1A1A1A" />
        </span>
      ) : (
        <span className="absolute right-3 top-[40%] bg-white">
          <LaCaretDown stroke="#1A1A1A" />
        </span>
      )}
      <ul
        className={`${
          !flip && "hidden"
        } absolute z-30 mt-2 w-full rounded border border-grey-light bg-white `}
      >
        {props.options.map((option, i) => (
          <li
            className={`flex justify-between border-primary-blue-dark p-2 capitalize hover:border-l-2 hover:bg-primary-blue-lighter ${
              selectedOption === option &&
              "bg-[url('/src/assets/icons/check.svg')]"
            } bg-[95%] bg-no-repeat`}
            key={i}
            onClick={handleSelect}
          >
            {option}
          </li>
        ))}
      </ul>
    </fieldset>
  );
}

export default Select;

