import React from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import TournamentPagination from "../../components/create-tournament/TournamentPagination";
import tournamentDefault from "../../assets/images/tournamentDefault.webp";
import banner from "../../assets/images/tournament-banner.webp";
import useImagePreloader from "../../hooks/useImagePreloader";
import { Spinner } from "../../components/ui";

import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { useCreateTournamentMutation } from "../../redux/services/tournamentService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { LaArrowLeft, LaClose, LaPlusIcon } from "../../components/svg-icons";
import { RootState } from "../../redux/app/store";
import {
  SetupTournamentForm,
  TournamentBanner,
  TournamentContactInfo,
  TournamentPricing,
  TournamentRegistrationFeeForm,
} from "../../components/create-tournament";
import checkIcon from "../../assets/icons/tick-white.svg";
import { ConfirmPopup } from "../../components/modals";
import { useUpdateMeMutation } from "../../redux/services/userService";
import HelmetComponent from "../../components/HelmetComponent";

export type CreateTournamentFormValues = {
  [key: string]: any;
  name: string;
  maxTeam: string;
  noOfPlayers: string;
  category: string;
  location: string;
  // avatar: FileList;
  venue: string;
  fixtureType: string;
  banner: FileList;
  description: string;
  format: string;
  phonecode: string;
  phoneNumber: string;
  contactEmail: string;
  registrationFee: string;
  winnerPrize: string;
  runnerUpPrize: string;
  thirdPlacePrize: string;
  contactAddress: string;
  datetime: string;
  coOrganizers: { name: string; phone: string; phoneCode: string }[];
  tournamentType: string;
  registrationType: string;
};

function CreateTournament() {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const profile = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "organizer")?.profile;

  const [updateUser] = useUpdateMeMutation();

  const [createTournament, { isLoading: isCreatingTournament }] =
    useCreateTournamentMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [tournamentId, setTournamentId] = React.useState<string>("");
  const [displayModal, setDisplayModal] = React.useState<boolean>(false);

  const formSteps = ["Setup", "Contact Info", "Reward", "Registration Fee"];
  const [isSuccess, setIsSuccess] = React.useState(false);
  const { imagesPreloaded } = useImagePreloader([
    banner,
    tournamentDefault,
    checkIcon,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    setError,
  } = useForm<CreateTournamentFormValues>({
    defaultValues: {
      fixtureType: "single fixture",
      format: "",
      maxTeam: "",
      location: "",
      venue: "",
      name: "",
      datetime: "",
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "coOrganizers",
  });
  const watchName = watch("name");
  const watchFixtureType = watch("fixtureType");
  const watchFormat = watch("format");
  const watchLocation = watch("location");
  const watchMaxTeam = watch("maxTeam");
  const watchBanner = watch("banner");
  // const watchAvatar = watch("avatar");
  const watchCategory = watch("category");
  const watchRegistrationFee = watch("registrationFee");
  const watchNoOfPlayers = watch("noOfPlayers");
  const watchRegistrationType = watch("registrationType");

  const FEE_PER_PLAYER = 800;
  const FREE_FEE_PER_PLAYER = 200;

  const laFreeCut =
    (+watchNoOfPlayers || 0) * (+watchMaxTeam || 0) * FREE_FEE_PER_PLAYER;

  const totalFee =
    (+watchRegistrationFee || 0) + (+watchNoOfPlayers || 0) * FEE_PER_PLAYER;

  const handleFormSubmit: SubmitHandler<CreateTournamentFormValues> = function (
    data
  ) {
    const isPaid = data.registrationType === "paid";
    if (isPaid) {
      data.registrationFee = `${totalFee}`;
    }

    const formData = new FormData();

    for (const key in data) {
      // if (key === "banner" || key === "avatar") {
      //   formData.append(key, data[key][0]);
      //   continue;
      // }
      if (key === "coOrganizers") {
        const coOrganizers = JSON.stringify(data[key]);
        formData.append(key, coOrganizers);
        continue;
      } else formData.append(key, data[key]);
    }

    // false if the tournament is free otherwise it is true
    formData.append("hasPaid", `${isPaid}`);
    createTournament(formData)
      .unwrap()
      .then((res) => {
        setTournamentId(res?.data?._id);
        isPaid
          ? setIsSuccess(true)
          : navigate(`/payment?role=organizer&tournamentId=${res?.data?._id}`);
      })
      .catch((err) => {
        dispatch(setToastError(true));
        console.log({ mana: err });
      });
  };

  React.useEffect(() => {
    if (role !== "organizer") {
      setDisplayModal(true);
    }
  }, [role, profile]);

  function handleSwitchProfile() {
    if (!profile) return navigate("/account/onboarding?role=organizer&step=2");
    updateUser({ role: "organizer" })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setDisplayModal(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  if (!isLoggedIn) {
    return <Navigate to="/account/login" />;
  }

  if (!imagesPreloaded) {
    return (
      <div
        className="flex h-[100vh] w-full items-center justify-center"
        aria-label="pre-loading"
      >
        <Spinner />
      </div>
    );
  }

  // open graph data
  const ogData = {
    title: "Leagues Arena - Create Tournament",
    description: "Create a tournament on Leagues Arena",
    url: `https://${window.location.host}/competitions/create`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <main className="flex flex-col items-center overflow-x-hidden">
        <TournamentBanner
          register={register}
          watchBanner={watchBanner}
          // watchAvatar={watchAvatar}
          errors={errors}
        />

        <div className="flex w-full max-w-5xl items-center justify-between px-4">
          <button
            aria-label="back"
            type="button"
            onClick={() => {
              setCurrentStep((prev) => prev - 1);
            }}
          >
            {currentStep > 1 && <LaArrowLeft size="md" />}
          </button>
          <button type="button" onClick={() => navigate(-1)} aria-label="close">
            <LaClose />
          </button>
        </div>

        <TournamentPagination currentStep={currentStep} formSteps={formSteps} />

        {currentStep === 1 && (
          <SetupTournamentForm
            watchFormat={watchFormat}
            watchFixtureType={watchFixtureType}
            formTitle="Create a Competition"
            setValue={setValue}
            register={register}
            watchLocation={watchLocation}
            watchMaxTeam={watchMaxTeam}
            setCurrentStep={setCurrentStep}
            watchName={watchName}
            watchCategory={watchCategory}
            errors={errors}
            setError={setError}
            handleSubmit={handleSubmit}
          />
        )}
        {currentStep === 2 && (
          <TournamentContactInfo
            errors={errors}
            register={register}
            setCurrentStep={setCurrentStep}
            handleSubmit={handleSubmit}
            setValue={setValue}
            fields={fields}
            append={append}
            remove={remove}
          />
        )}
        {currentStep === 3 && (
          <TournamentPricing
            handleSubmit={handleSubmit}
            register={register}
            errors={errors}
            setCurrentStep={setCurrentStep}
            setError={setError}
          />
        )}

        {currentStep === 4 && (
          <TournamentRegistrationFeeForm
            errors={errors}
            handleSubmit={handleSubmit}
            register={register}
            watchRegistrationFee={watchRegistrationFee}
            watchNoOfPlayers={watchNoOfPlayers}
            onSubmit={handleFormSubmit}
            isLoading={isCreatingTournament}
            watchRegistrationType={watchRegistrationType}
            watchMaxTeam={watchMaxTeam}
            laFreeCut={laFreeCut}
            totalFee={totalFee}
          />
        )}
        {isSuccess && (
          <ConfirmPopup
            heading="Success!"
            type="success"
            description="Tournament has been successfully created, proceed to invite teams to your tournament, or copy link and share to finish the set up"
            buttons={[
              {
                text: "Invite teams",
                icon: <LaPlusIcon stroke="#fff" strokeWidth={2} />,
                class: "primary",
                handleClick: () => navigate(`/discover/create-teams`),
              },
            ]}
            closeIcon={true}
            handleClose={() => navigate(`/competitions/${tournamentId}`)}
          />
        )}
      </main>
      {displayModal && (
        <ConfirmPopup
          heading={
            profile ? "Switch your profile" : "Create an organizer profile"
          }
          type="warning"
          description={`You need to ${
            profile ? "switch your profile" : "create an organizer profile"
          } to perform this action`}
          buttons={[
            {
              text: "Proceed",
              handleClick: handleSwitchProfile,
              class: "primary",
            },
            {
              text: "Cancel",
              handleClick: () => {
                setDisplayModal(false);
                navigate(-1);
              },
              class: "secondary",
            },
          ]}
        />
      )}
    </>
  );
}

export default CreateTournament;
