function LaLike({
  stroke = "#1a1a1a",
  fill = "none",
}: {
  stroke?: string;
  fill?: string;
}) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill={fill}>
      <path
        d="M12.62 20.81c-.34.12-.9.12-1.24 0C8.48 19.82 2 15.69 2 8.69 2 5.6 4.49 3.1 7.56 3.1c1.82 0 3.43.88 4.44 2.24a5.53 5.53 0 014.44-2.24C19.51 3.1 22 5.6 22 8.69c0 7-6.48 11.13-9.38 12.12z"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaLike;
