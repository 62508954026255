import { useParams } from "react-router-dom";
import { FeedCard } from "../../components/ui/cards";
import { useGetPostQuery } from "../../redux/services/postService";
import { RootState } from "../../redux/app/store";
import { useAppSelector } from "../../redux/app/hooks";
import PostSkeleton from "../../components/ui/skeletons/PostSkeleton";
import HelmetComponent from "../../components/HelmetComponent";

export default function Post() {
  const { postId } = useParams();
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const { data: post, isLoading } = useGetPostQuery({ id: postId });
  const liked = post?.likers?.some((liker) => liker === myId);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Post",
    description: "View a post on Leagues Arena",
    url: `https://${window.location.host}/post/${postId}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:p-0">
          <h3 className="my-4 text-xl font-bold lg:mb-6 lg:mt-14">Post</h3>
          {isLoading ? (
            <PostSkeleton />
          ) : (
            <>
              {post && (
                <FeedCard
                  role={post?.creator?.role}
                  key={post?._id}
                  avatar={post?.creator?.avatar}
                  username={post?.creator?.name}
                  timeStamp={post?.createdAt}
                  note={post?.note}
                  image={post?.image}
                  likeCount={post?.likeCount}
                  liked={liked}
                  id={post?._id}
                  authorId={post?.author._id}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
