import { string } from "zod";

export default function LaHeart(
  props: React.SVGProps<SVGSVGElement> & {
    fillPath: string;
    strokeFill: string;
  }
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={29}
      fill="none"
      {...props}
    >
      <path
        fill={props.fillPath ?? "none"}
        stroke={props.strokeFill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.678}
        d="M17.118 27.118c-.476.168-1.26.168-1.736 0C11.322 25.732 2.25 19.95 2.25 10.15c0-4.326 3.486-7.826 7.784-7.826A7.722 7.722 0 0 1 16.25 5.46a7.742 7.742 0 0 1 6.216-3.136c4.298 0 7.784 3.5 7.784 7.826 0 9.8-9.072 15.582-13.132 16.968Z"
      />
    </svg>
  );
}
