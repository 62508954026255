import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { setToastErrorText } from "../../redux/features/modal/displayModalSlice";
import { useProcessTeamInviteMutation } from "../../redux/services/playerService";
import Button from "../ui/buttons/Button";
import LaSpinner from "../svg-icons/LaSpinner";

interface Props {
  team?: Team;
}

export default function PublicTeamBtnGroup({ team }: Props) {
  const dispatch = useAppDispatch();
  const role = useAppSelector((state: RootState) => state.auth.role);
  const playerId = useAppSelector((state: RootState) => state.auth.playerId);

  const hasJoinedTeam = Boolean(
    // @ts-ignore
    team?.members.find((oId) => oId._id === playerId)
  );
  const hasPendingInvite = Boolean(
    // @ts-ignore

    team?.invites.find((oId) => oId!._id === playerId)
  );

  // Player :: Accepting or declining invites sent by THIS team manager
  const [processTeamInvite, { isLoading: sendingInvite }] =
    useProcessTeamInviteMutation();

  function acknowledgeTeamInvite(action: "accept" | "decline") {
    processTeamInvite({
      body: {
        teamId: team!._id,
      },
      param: action,
    })
      .unwrap()
      .then((res) => {
        console.log(`res: ${res}`);
      })
      .catch((err) => {
        dispatch(setToastErrorText(err.data.error));
      });
  }

  return (
    <>
      {hasJoinedTeam && (
        <Button
          className="cursor-pointer rounded bg-green p-3 text-sm font-medium text-white"
          role={role}
          intent={"soft"}
        >
          Joined
        </Button>
      )}

      {hasPendingInvite && (
        <div className="flex items-center gap-4">
          <Button
            role={role}
            intent={"primary"}
            disabled={sendingInvite}
            onClick={() => acknowledgeTeamInvite("accept")}
          >
            Accept{" "}
            {sendingInvite && (
              <span className="animate-pulse">
                <LaSpinner />
              </span>
            )}
          </Button>
          <Button
            role={role}
            intent={"secondary"}
            className="border-red text-red hover:bg-red-lighter"
            disabled={sendingInvite}
            onClick={() => acknowledgeTeamInvite("decline")}
          >
            Decline{" "}
            {sendingInvite && (
              <span className="animate-pulse">
                <LaSpinner />
              </span>
            )}
          </Button>
        </div>
      )}
    </>
  );
}
