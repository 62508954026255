import BorderButton from "../../components/ui/buttons/BorderButton";
import { LaPlusIcon, LaProfileDefault } from "../../components/svg-icons";
import { profiles } from "../../assets/data/profiles";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  useGetMeQuery,
  useUpdateMeMutation,
} from "../../redux/services/userService";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { updateRole } from "../../redux/features/auth/authSlice";
import { RootState } from "../../redux/app/store";
import { Spinner } from "../../components/ui";
import useImagePreloader from "../../hooks/useImagePreloader";
import HelmetComponent from "../../components/HelmetComponent";

function SelectProfile() {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateProfile] = useUpdateMeMutation();
  const { data: me, isLoading: meLoading } = useGetMeQuery();

  const { isBoarded } = useAppSelector((state: RootState) => state.auth);
  const { imagesPreloaded } = useImagePreloader([
    ...profiles.map((profile) => profile.image),
  ]);
  const fromLink = useAppSelector((state: RootState) => state.auth.fromLink);
  const navigateTo = fromLink ? fromLink : "/home";

  if (!isLoggedIn) {
    return <Navigate to="/account/login" />;
  }

  if (meLoading || !imagesPreloaded) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!me?.onBoarded && me?.onBoarded !== undefined) {
    return <Navigate to="/account/onboarding?" />;
  }

  function handleAddProfile(profile: { role: string }) {
    updateProfile({ role: profile.role })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        dispatch(updateRole(profile.role));
        navigate(navigateTo);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  const profilesCards = me?.profiles?.map((profile) => {
    const profileColors: { [key: string]: string } = {
      player: "#E9F1FC",
      organizer: "#FFF4E5",
      fan: "#E9FCF4",
    };

    const styles = {
      backgroundColor: profileColors[profile.role],
    };

    return (
      <div
        className="flex max-w-[150px] flex-col items-center justify-between gap-2 lg:max-w-[200px]"
        key={profile.role}
      >
        <div
          style={styles}
          className="flex h-[120px] w-[120px] items-center justify-center rounded-full lg:h-[160px] lg:w-[160px]"
        >
          <img
            src={profile.avatar}
            alt={profile.role}
            className="h-full w-full rounded-full"
          />
        </div>
        <p className="capitalize lg:text-2xl">{profile.role}</p>
        <BorderButton
          className={`mt-2 flex items-center gap-2 rounded p-3 text-sm font-medium text-primary-blue-dark disabled:cursor-not-allowed disabled:border-grey-light disabled:text-grey-light disabled:hover:bg-white lg:text-base`}
          onClick={() => handleAddProfile({ role: profile.role })}
        >
          <span className="text-2xl">
            <LaPlusIcon stroke="#0045F6" />
          </span>
          Select Profile
        </BorderButton>
      </div>
    );
  });

  if (isBoarded !== undefined && !isBoarded)
    return <Navigate to={"/account/onboarding"} />;

  // open graph data
  const ogData = {
    title: "Leagues Arena - Select Profile",
    description: "Select Profile on Leagues Arena",
    url: `https://${window.location.host}/account/select-profile`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1689076677/Frame_6181_k3886h.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex min-h-screen flex-col items-center justify-center">
        <div className="bg__shadow px my-14 flex w-full flex-col items-center rounded py-7 md:px-24 md:py-10 lg:my-52 lg:max-w-[90%] lg:rounded-2xl lg:px-48 lg:py-20">
          <h3 className="text-2xl font-medium lg:text-4xl">Profiles</h3>
          <p className="mt-4 font-normal lg:text-2xl">
            Log in to preferred account
          </p>

          <div className="mt-8 flex flex-wrap justify-center  gap-14 lg:mt-14 lg:gap-24">
            {profilesCards}
            {profilesCards && profilesCards?.length < 3 && (
              <Link
                to={"/account/onboarding"}
                className="flex  flex-col items-center justify-between gap-2"
              >
                <div className="rounded-ful relative flex h-[120px] w-[120px] items-center justify-center lg:h-[160px] lg:w-[160px]">
                  <span className="text-[4rem] lg:text-[6rem]">
                    <LaProfileDefault />
                  </span>
                  <div className="absolute flex h-full w-full items-center justify-center rounded-full bg-[#000000cc] text-4xl">
                    <LaPlusIcon stroke="#fff" />
                  </div>
                </div>
                <p className="capitalize lg:text-2xl">New Profile</p>
                <button
                  className={`mt-2 flex items-center gap-2 rounded border border-primary-blue-dark p-3 text-sm font-medium text-primary-blue-dark disabled:cursor-not-allowed`}
                >
                  <span className="text-2xl">
                    <LaPlusIcon stroke="#0045F6" />
                  </span>
                  Add Profile
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectProfile;
