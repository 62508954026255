import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { LaClose } from "../svg-icons";

type PlayerBadgePlainProps = {
  avatar?: string;
  playerName?: string;
  playerWing?: string;
  appearance?: number;
  assist?: number;
  goals?: number;
  age?: number;
  favFoot?: string;
  height?: number;
  jerseyNumb?: number;
  weight?: number;
  deleteMode?: boolean;
  designation?: string;
};

export default function PlayerProfileBadge({
  avatar,
  playerName,
  playerWing,
  appearance,
  assist,
  goals,
  age,
  favFoot,
  height,
  designation,
  deleteMode,
  // jerseyNumb,
  weight,
}: PlayerBadgePlainProps) {
  const userRole = useAppSelector((state: RootState) => state.auth.role);
  return (
    <section
      className={`relative h-[248px] w-[169px] overflow-hidden transition-all duration-500 ${
        deleteMode && deleteMode === true ? "sepia" : "sepia-0"
      }`}
      id="player-player-badge"
    >
      {userRole === "team" && designation && (
        <span
          className={`absolute -left-0 top-[25%] z-30 block w-full -translate-x-9 -translate-y-9 -rotate-45 bg-gray-100 p-[2px] text-center text-sm font-medium capitalize ${
            designation === "admin"
              ? " bg-primary-blue-dark text-white"
              : designation === "invited"
              ? "bg-yellow text-black"
              : designation === "attention"
              ? "bg-orange-600 text-black"
              : designation === "teammate"
              ? "bg-green text-white"
              : null
          }`}
        >
          {designation}
        </span>
      )}
      {userRole === "team" && designation && (
        <span
          className={`absolute -left-0 top-[25%] z-30 block w-full -translate-x-9 -translate-y-9 -rotate-45 bg-gray-100 p-[2px] text-center text-sm font-medium capitalize ${
            designation === "admin"
              ? " bg-primary-blue-dark text-white"
              : designation === "invited"
              ? "bg-yellow text-black"
              : designation === "attention"
              ? "bg-orange-600 text-black"
              : designation === "teammate"
              ? "bg-green text-white"
              : null
          }`}
        >
          {designation}
        </span>
      )}

      <img
        src={avatar}
        alt="player avatar"
        crossOrigin="anonymous"
        className="absolute left-0 top-0 -z-10 h-[40%] w-full object-cover"
      />
      <section className="flex h-full w-full flex-col items-center bg-gradient-to-t from-[#0037c5] from-[50%] via-[#0037c5]  to-[#00000033] to-[80%] px-1 py-[13px] text-white">
        <div className="relative flex w-full items-center justify-center">
          <img
            src={avatar}
            alt=""
            crossOrigin="anonymous"
            className="h-[48px] w-[48px] overflow-hidden rounded-full border border-white bg-gray-500 object-contain"
          />
          <p className="absolute right-[10%] top-1/2 -translate-y-1/2 text-center text-xl font-medium uppercase text-white">
            {playerWing}
          </p>
        </div>
        <h3 className="text-center text-xl font-medium capitalize">
          {playerName}
        </h3>
        <div className="my-[5px] mb-1 h-[1px] w-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent" />
        <section className="flex w-full items-start justify-center gap-[2px] text-xs leading-none">
          <section className="flex flex-col items-center gap-[5px] space-y-1 text-center">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[9px] font-medium">Apps</p>
              <p className="text-[11px] font-bold">{appearance}</p>
            </div>
            <div className="flex flex-col gap-[3px]">
              <p className="text-[9px] font-medium">Goals</p>
              <p className="text-[11px] font-bold">{goals}</p>
            </div>
            <div className="flex flex-col gap-[3px]">
              <p className="text-[9px] font-medium">Assist</p>
              <p className="text-[11px] font-bold">{assist}</p>
            </div>
            <div className="flex flex-col gap-[3px]">
              <p className="text-[9px] font-medium">MVP</p>
              <p className="text-[11px] font-bold">0</p>
            </div>
          </section>

          <div className="mx-2 h-full w-[1px] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-gray-100 to-transparent" />

          <section className="flex flex-col items-center gap-[5px] space-y-1 text-center">
            <div className="flex flex-col gap-[3px]">
              <p className="text-[9px] font-medium">Age</p>
              <p className="text-[11px] font-bold">{age}</p>
            </div>
            <div className="flex flex-col gap-[3px]">
              <p className="text-[9px] font-medium">Height</p>
              <p className="text-[11px] font-bold">{height}cm</p>
            </div>
            <div className="flex flex-col gap-[3px]">
              <p className="text-[9px] font-medium">Weight</p>
              <p className="text-[11px] font-bold">{weight}kg</p>
            </div>
            <div className="flex flex-col gap-[3px]">
              <img
                src="/assets/foot.png"
                alt="root"
                className="h-[12.5px] w-[12.5px] object-contain object-center"
              />
              <p className="text-[11px] font-bold uppercase">
                {favFoot ? favFoot[0] : ""}
              </p>
            </div>
          </section>
        </section>
      </section>
      {/* Stripes */}
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-[60px] right-0"
      />
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-[42px] right-0"
      />
      <img
        src="/assets/badge-strip.png"
        className="absolute bottom-[24px] right-0"
      />

      {deleteMode && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-10 grid place-content-center place-items-center">
          <button
            type="button"
            className="grid aspect-square h-8 w-8 items-center justify-center rounded-full bg-white"
          >
            <LaClose />
          </button>
        </div>
      )}
    </section>
  );
}
