import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import * as Icons from "../svg-icons";
import {
  setToastError,
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import {
  useAcceptTournamentInviteMutation,
  useCanJoinTournamentMutation,
  useSendTournamentRequestMutation,
} from "../../redux/services/tournamentService";
import { Button, Spinner } from "../ui";
import { Link, useNavigate } from "react-router-dom";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { RootState } from "../../redux/app/store";
import LaSpinner from "../svg-icons/LaSpinner";
import { cn } from "../../utils/cn";

type Props = {
  tournamentId?: string;
  registrationFee: string;
  type: "request" | "invite";
  setShowRequestToJoin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowInviteToJoin: React.Dispatch<React.SetStateAction<boolean>>;
  myTeam?: Team;
  myTeamLoading: boolean;
};

function RequestToJoinCompetitionModal(props: Props) {
  const MAX_PLAYERS = 11;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectAll, setSelectAll] = React.useState(false);
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const profiles = useAppSelector((state: RootState) => state.auth.profiles);
  const currentProfile = profiles?.find((profile) => profile.role === role);
  const plan = currentProfile?.plan;

  const [formData, setFormData] = React.useState(() => {
    const members = props.myTeam?.members as TPlayer[];
    return members?.map((member) => ({ ...member, isChecked: false }));
  });

  const [requestToJoin, { isLoading: requestToJoinLoading }] =
    useSendTournamentRequestMutation();
  const [canJoinTournament] = useCanJoinTournamentMutation();

  const [acceptInvite, { isLoading: acceptInviteLoading }] =
    useAcceptTournamentInviteMutation();
  const hasTeam = !!props.myTeam?._id;
  const hasTeamMembers = props.myTeam?.members?.length;
  const [erroredPlayers, setErroredPlayers] = React.useState<string[]>([]);
  const selectedPlayers = formData?.filter((player) => player.isChecked);

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.target;
    setFormData((prev) => {
      return prev?.map((item) =>
        item?.name === name ? { ...item, isChecked: checked } : item
      );
    });
  }
  const formFields = formData?.map((user) => {
    return (
      <fieldset
        key={user.name}
        className={cn("rounded odd:bg-grey-lighter even:bg-white", {
          "border border-red": erroredPlayers.includes(user.name),
        })}
      >
        <input
          type="checkbox"
          name={user.name}
          checked={user.isChecked}
          id={user.name}
          value={user._id}
          onChange={(e) => handleFormChange(e)}
          hidden
          className="check-card__input"
          disabled={selectedPlayers.length >= MAX_PLAYERS && !user.isChecked}
          aria-label={user.name}
        />
        <label
          htmlFor={user.name}
          className="flex cursor-pointer items-center justify-between p-2"
        >
          <div className="flex items-center gap-4">
            <div
              className={`check__box flex h-5 w-5 items-center justify-center rounded ${
                user.isChecked
                  ? "bg-primary-blue-darker"
                  : "border-2 border-grey-light"
              }`}
            >
              <Icons.LaCheck stroke="#fff" />
            </div>
            <img
              src={user.avatar}
              alt="avatar"
              className="h-12 w-12 rounded-full"
            />
            <p>@{user.name}</p>
          </div>
          <p className="rounded border border-grey-light px-3 py-[2px]">
            {user.position}
          </p>
        </label>
      </fieldset>
    );
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    const players = Object.values(data) as string[];

    if (!players.length || !props.tournamentId) {
      dispatch(setToastError(true));
      return;
    }

    canJoinTournament({ tournamentId: props.tournamentId, players })
      .unwrap()
      .then(() => {
        if (props.registrationFee) {
          navigate(
            `/competition/${props.tournamentId}/checkout/?players=${players}&type=${props.type}`
          );
          return;
        }

        if (props.type === "request") {
          requestToJoin({
            tournamentId: props.tournamentId!,
            players,
          })
            .unwrap()
            .then((res) => {
              console.log(res);
              dispatch(setToastSuccess(true));
            })
            .catch((err) => {
              console.log(err, "err");
              dispatch(setToastErrorText(err?.data?.message));
            })
            .finally(() => {
              props.setShowRequestToJoin(false);
              props.setShowInviteToJoin(false);
            });
        } else {
          acceptInvite({ tournamentId: props.tournamentId, players })
            .unwrap()
            .then((res) => {
              console.log(res, "res");
              dispatch(setToastSuccess(true));
            })
            .catch((err) => {
              console.log(err, "err");
              dispatch(setToastErrorText(err?.data?.message));
            })
            .finally(() => {
              props.setShowRequestToJoin(false);
              props.setShowInviteToJoin(false);
            });
        }
      })
      .catch((err) => {
        console.log(err, "can join");
        setErroredPlayers(
          err?.data?.data.map((player: { name: string }) => player.name)
        );
        dispatch(setToastErrorText(err?.data?.message));
      });
  }

  React.useLayoutEffect(() => {
    if (formData?.every((item) => item.isChecked)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [formData, selectAll]);

  React.useEffect(() => {
    if (props.myTeam) {
      const members = props.myTeam.members as TPlayer[];
      const updatedFormData = members.map((member) => ({
        ...member,
        isChecked: false,
      }));
      setFormData(updatedFormData);
    }
  }, [props.myTeam]);

  if (props.myTeamLoading) {
    return (
      <div className="grid h-24 place-content-center place-items-center rounded-md bg-white">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="absolute bottom-0 left-0 top-0 min-h-screen w-full bg-black/20">
      <div className="flex min-h-screen w-full items-center justify-center py-10">
        <div className="fixed bottom-0 right-0 top-0 z-[60] flex w-full flex-col items-center justify-center rounded border-t border-grey-light bg-white shadow lg:relative lg:bottom-auto lg:w-[90%] lg:rounded-lg">
          <div className="my-16 flex h-full w-full flex-col items-center lg:w-[60%] lg:py-10">
            <h3 className="text-2xl font-medium lg:text-4xl">
              Select your squad
            </h3>

            {hasTeamMembers ? (
              <>
                {plan?.name === "free" &&
                selectedPlayers?.length < MAX_PLAYERS ? (
                  <div className="mt-4 flex items-center gap-2 bg-[#FFB8001A] px-2 py-1 lg:w-[400px] ">
                    <Icons.LaInfo className="text-2xl" />
                    <div className="text-sm">
                      <p>
                        Max. number of players to join competition is{" "}
                        {MAX_PLAYERS}
                      </p>
                      <Link
                        to={"/premium/checkout?planType=team"}
                        className="font-medium text-primary-blue-darker"
                      >
                        Upgrade
                      </Link>
                      <p> your team profile to add more players.</p>
                    </div>
                  </div>
                ) : (
                  selectedPlayers?.length >= MAX_PLAYERS && (
                    <div className="mt-4 flex items-center gap-2 bg-red-lighter px-2 py-1">
                      <Icons.LaInfo className="text-2xl" stroke="#f00" />
                      <div className="text-red">
                        <p className="font-bold">Important: </p>
                        <p>
                          You have reached your limit. Deselect a player to add
                          new player or
                        </p>
                        <Link
                          to={"/premium/checkout?planType=team"}
                          className="font-medium text-primary-blue-darker"
                        >
                          upgrade your team profile.
                        </Link>
                      </div>
                    </div>
                  )
                )}
                <form
                  action=""
                  className="flex h-full w-full flex-col justify-between px-4 lg:px-0"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <fieldset className="mt-2 rounded border border-grey-light">
                    {formFields}
                  </fieldset>

                  <fieldset className="flex flex-col lg:mt-6">
                    <Button
                      role={role}
                      type="submit"
                      intent="primary"
                      disabled={requestToJoinLoading || acceptInviteLoading}
                    >
                      Join Competition
                      {requestToJoinLoading ||
                        (acceptInviteLoading && (
                          <span className="animate-spin">
                            <LaSpinner />
                          </span>
                        ))}
                    </Button>
                  </fieldset>
                </form>
              </>
            ) : (
              <>
                {hasTeam ? (
                  <EmptyStateAuto
                    title="Your team has no players"
                    description="Add players to continue"
                    className="lg:mt-10"
                  />
                ) : (
                  <>
                    <EmptyStateAuto
                      title="You do not have a team"
                      className="lg:mt-10"
                      description='Click "Create Your Team" to get started'
                    />
                    <Link
                      to={`/teams/create`}
                      className=" flex items-center justify-center gap-2 rounded border border-primary-blue-dark p-3 font-medium text-primary-blue-dark"
                      type="button"
                    >
                      <Icons.LaPlusIcon /> Create Your Team
                    </Link>
                  </>
                )}
              </>
            )}
          </div>
          <button
            className="fixed right-4 top-5 z-[70] lg:absolute lg:right-10 lg:top-10"
            onClick={() => {
              props.setShowRequestToJoin(false);
              props.setShowInviteToJoin(false);
            }}
          >
            <Icons.LaClose />
          </button>
        </div>
      </div>
    </div>
  );
}

export default RequestToJoinCompetitionModal;
