import { LaCheck, LaClose } from "../svg-icons";
import { Button } from "../ui";

interface TeamCardProps {
  teams: {
    name?: string;
    avatar?: string;
  }[];
  disabled?: boolean;
  setTeamAPenalty: React.Dispatch<React.SetStateAction<number>>;
  setTeamBPenalty: React.Dispatch<React.SetStateAction<number>>;
  setIsATie: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PenaltyKickModal({
  teams,
  setTeamAPenalty,
  setTeamBPenalty,
  disabled,
  setIsATie,
}: TeamCardProps) {
  const teamCards = teams.map((team, index) => (
    <>
      <label
        className="flex cursor-pointer items-center justify-between rounded border border-grey-light  px-3 py-4"
        htmlFor={`${team?.name}${index}`}
      >
        <input
          type="radio"
          name="winner"
          id={`${team?.name}${index}`}
          className="peer"
          hidden
          onChange={() => {
            if (index === 0) {
              setTeamAPenalty(1);
              setTeamBPenalty(0);
            } else {
              setTeamAPenalty(0);
              setTeamBPenalty(1);
            }
          }}
        />

        <div className="flex items-center gap-2">
          <img src={team?.avatar} alt="" className="h-10 w-10 rounded-full" />
          <p className="text-sm font-medium uppercase">{team?.name}</p>
        </div>
        <div className="hidden h-6 w-6 items-center justify-center rounded-full border peer-checked:flex peer-checked:border-primary-blue-darker">
          <LaCheck stroke="#0045F6" strokeWidth={2} />
        </div>
      </label>
    </>
  ));

  return (
    <div className="fixed bottom-0 right-0 z-[60] flex w-full flex-col rounded border-t border-grey-light bg-white px-4 py-4 shadow lg:w-[82%] lg:rounded-2xl lg:px-20 lg:py-10">
      <div className="flex items-center justify-between">
        <h3 className="font-medium lg:text-2xl">
          Select winner after penalty kick
        </h3>
        <button onClick={() => setIsATie(false)}>
          <LaClose />
        </button>
      </div>
      <div className="mt-6 flex grid-cols-2 flex-col gap-4 lg:grid">
        {teamCards}
      </div>

      <div className="mt-6 flex flex-col">
        <Button
          role="organizer"
          intent={"primary"}
          className="items-center gap-1 lg:flex"
          disabled={disabled}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
