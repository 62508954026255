import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import type { UserToken } from "../../../@types/token";

type Info = Omit<UserToken, "iat">;

interface InitialState extends Info {
  isLoggedIn: boolean;
  myId: string;
  playerId: string;
  organizerId: string;
  profile: { role: string; plan: string };
  isBoarded: boolean;
  fromLink: string;
  postsLeft: number;
  competitionsLeftToCreate: number;
  remainingTeamsToJoin: number;
}

const token = localStorage.getItem("token"); //|| jsCookie.get("token");
let info: InitialState | {} = {};

if (token) {
  const decodedToken = jwtDecode(token) as UserToken;
  const hasExpired = decodedToken?.exp
    ? decodedToken.exp * 1000 < Date.now()
    : true;
  info = {
    ...decodedToken,
    profile: decodedToken?.profiles?.find(
      (p) => p?.role === decodedToken?.role
    ),
    isLoggedIn: !hasExpired,
    myId: decodedToken._id,
    isBoarded:
      !hasExpired && decodedToken.onBoarded ? decodedToken.onBoarded : false,
    fromLink: "",
  };

  // expired = decodedToken?.exp ? decodedToken.exp * 1000 < Date.now() : true;
  // myInfo = !expired ? (jwtDecode(token) as MyInfo) : {};
  // if (!expired && decodedToken.onBoarded) {
  //   isBoarded = decodedToken.onBoarded;
  // }  -- TODO: remove this
}

// const initialState: InitialState = {
//   isLoggedIn: !expired,
//   myId: myInfo?._id ?? "",
//   role: myInfo?.role ?? "",
//   username: myInfo?.username ?? "",
//   isBoarded: isBoarded,
//   fromLink: "",
// };
// Caution: ...(info as IntialState) was type-casted manually to suppress `ts type overlap`.
const initialState: InitialState = { ...(info as InitialState) };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoggedin: (state, action) => {
      state.isLoggedIn = action.payload;
      // localStorage.setItem("login", `${action.payload}`);
    },
    setIsBoarded: (state, action) => {
      state.isBoarded = action.payload;
    },
    setMyId: (state, action) => {
      state.myId = action.payload;
    },
    setPlayerId: (state, action) => {
      state.playerId = action.payload;
    },
    setOrganizerId: (state, action) => {
      state.organizerId = action.payload;
    },
    updateRole: (state, action) => {
      state.role = action.payload;
    },
    setMyUsername: (state, action) => {
      state.username = action.payload;
    },
    setFromLink: (state, action) => {
      state.fromLink = action.payload;
    },
    setProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setPostsLeft: (state, action) => {
      state.postsLeft = action.payload;
    },
    setCompetitionsLeftToCreate: (state, action) => {
      state.competitionsLeftToCreate = action.payload;
    },
    setRemainingTeamsToJoin: (state, action) => {
      state.remainingTeamsToJoin = action.payload;
    },
  },
});

export const {
  setIsLoggedin,
  updateRole,
  setMyId,
  setPlayerId,
  setOrganizerId,
  setMyUsername,
  setFromLink,
  setIsBoarded,
  setProfile,
  setProfiles,
  setPostsLeft,
  setCompetitionsLeftToCreate,
  setRemainingTeamsToJoin,
} = authSlice.actions;
export default authSlice.reducer;
