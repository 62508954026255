export default function TeamCardSkeleton() {
  return (
    <div className="relative h-[160px] w-full min-w-[325px] max-w-[400px] overflow-hidden rounded-md bg-gray-300 duration-300">
      <div className="absolute bottom-0 left-0 h-[84%] w-full rounded-t-md bg-black/70 px-6">
        <div className="relative -top-5 h-10 w-10 rounded-full bg-white" />
        <div className="flex h-[inherit] flex-col gap-3">
          <div className="h-4 animate-pulse rounded-md bg-white delay-150" />
          <div className="h-4 w-full animate-pulse rounded-md bg-white" />
          <div className="items-cente flex animate-pulse justify-between gap-3 delay-100">
            <div className="h-4 w-full rounded-md bg-white" />
            <div className="h-4 w-full rounded-md bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
}
