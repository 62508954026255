import { Navigate, useNavigate, useParams } from "react-router-dom";
import * as Icons from "../../components/svg-icons";
import { Spinner } from "../../components/ui";
import { useAppSelector } from "../../redux/app/hooks";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import {
  EditTournamentBanner,
  RestartTournamentContactForm,
  RestartTournamentForm,
  RestartTournamentRewardForm,
} from "../../components/create-tournament";
import { RootState } from "../../redux/app/store";
import { useForm } from "react-hook-form";
import React from "react";
import HelmetComponent from "../../components/HelmetComponent";

export type RestartTournamentFormValues = {
  [key: string]: any;
  name: string;
  edition: string;
  numberOfTeams: string;
  format: string;
  maxTeam: number;
  location: string;
  avatar: FileList;
  venue: string;
  banner: FileList;
  description: string;
  fixtureType: string;
  contactName: string;
  contactEmail: string;
  phoneNumber: string;
  phoneNumber2: string;
  contactAddress: string;
  registrationFee: string;
  winnerPrize: string;
  runnerUpPrize: string;
  thirdPlacePrize: string;
  datetime: string;
  category: string;
  noOfPlayers: string;
};

function RestartTournament() {
  const { tournamentId } = useParams();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state: RootState) => state.auth.isLoggedIn);
  const userId = useAppSelector((state: RootState) => state.auth?.myId);
  const [currentStep, setCurrentStep] = React.useState(1);
  const {
    data: prevData,
    isLoading: isLoadingPrev,
    isError: isTournamentError,
  } = useGetTournamentQuery({
    id: tournamentId,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm<RestartTournamentFormValues>({});

  const watchFormat = watch("format");
  const watchLocation = watch("location");
  const watchNumberOfTeams = watch("numberOfTeams");
  const watchFixtureType = watch("fixtureType");
  const watchBanner = watch("banner");
  const watchCategory = watch("category");

  // open graph data
  const ogData = {
    title: "Leagues Arena - Restart Tournament",
    description: "Restart your tournament on Leagues Arena",
    url: `https://${window.location.host}/competitions/${tournamentId}/restart`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  if (!isLoggedIn) {
    return <Navigate to="/account/login" />;
  }

  if (isLoadingPrev) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }
  if (isTournamentError || prevData?.author !== userId) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <main className="flex flex-col items-center overflow-x-hidden">
        <EditTournamentBanner
          register={register}
          errors={errors}
          watchBanner={watchBanner}
          prevBanner={prevData.banner}
        />

        <div className="hidden w-full max-w-5xl items-center justify-between px-4 lg:flex">
          <button type="button" onClick={() => navigate(-1)} aria-label="close">
            <Icons.LaClose />
          </button>
        </div>

        {currentStep === 1 && (
          <RestartTournamentForm
            errors={errors}
            handleSubmit={handleSubmit}
            register={register}
            setError={setError}
            setValue={setValue}
            watchFormat={watchFormat}
            watchFixtureType={watchFixtureType}
            watchLocation={watchLocation}
            watchNumberOfTeams={watchNumberOfTeams}
            watchCategory={watchCategory}
            prevData={prevData}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === 2 && (
          <RestartTournamentContactForm
            errors={errors}
            handleSubmit={handleSubmit}
            register={register}
            tournamentId={tournamentId ?? ""}
            setCurrentStep={setCurrentStep}
            prevData={prevData}
            setValue={setValue}
          />
        )}
        {currentStep === 3 && (
          <RestartTournamentRewardForm
            errors={errors}
            handleSubmit={handleSubmit}
            register={register}
            tournamentId={tournamentId ?? ""}
            prevData={prevData}
          />
        )}
      </main>
    </>
  );
}

export default RestartTournament;
