function LaUpdates({
  stroke = "#767676",
}: {
  stroke?: string;
}) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M22.75 12c0 5.52-4.48 10-10 10s-10-4.48-10-10 4.48-10 10-10 10 4.48 10 10z" />
        <path d="M16.46 15.18l-3.1-1.85c-.54-.32-.98-1.09-.98-1.72v-4.1" />
      </g>
    </svg>
  );
}

export default LaUpdates;
