import React from "react";
import { PasswordInput } from "../ui";
import TextLink from "../ui/text/TextLink";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { useUpdatePasswordMutation } from "../../redux/services/authService";
import { validatePassword } from "../../utils/validatePassword";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import Button from "../ui/buttons/Button";
import LaSpinner from "../svg-icons/LaSpinner";

type Props = {};

function ChangePassword({}: Props) {
  const dispatch = useAppDispatch();
  const [updatePassword, { isLoading: updatingPassword }] =
    useUpdatePasswordMutation();
  const role = useAppSelector((state: RootState) => state.auth.role);

  const [inputError, setInputError] = React.useState<
    InputError<{
      error: string;
      password: string;
    }>
  >({
    error: "",
    password: "",
    clearError: function (value) {
      setInputError((prev) => ({ ...prev, [value]: "" }));
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    const isValidPassword = validatePassword(data.newPassword.toString());

    if (!isValidPassword) {
      setInputError((prev) => ({ ...prev, password: "Weak password" }));
      return;
    }

    updatePassword(data)
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setToastSuccess(true));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }
  return (
    <form className="flex flex-col gap-6  lg:w-[75%]" onSubmit={handleSubmit}>
      <fieldset className="flex flex-col gap-3">
        <fieldset className="flex flex-col">
          <label
            htmlFor="currentPassword"
            className="text-sm font-medium md:text-base"
          >
            Old Password
          </label>
          <PasswordInput
            name="currentPassword"
            error={inputError}
            placeholder="Enter your current password"
          />
        </fieldset>
        <fieldset className="flex flex-col">
          <label
            htmlFor="newPassword"
            className="text-sm font-medium md:text-base"
          >
            New Password
          </label>
          <PasswordInput
            name="newPassword"
            error={inputError}
            placeholder="Enter a new password"
          />
        </fieldset>
        <p
          className={`mt-2 text-xs font-medium lg:text-sm ${
            inputError?.password && "text-red"
          }`}
        >
          {inputError?.password
            ? inputError?.password
            : "Minimum of 8 characters, with upper, lowercase, a number and a symbol"}
        </p>
      </fieldset>

      <fieldset className="flex flex-col items-center">
        <Button
          intent={"primary"}
          role={role}
          className="flex w-full items-center justify-center gap-4 py-4 text-base font-medium text-white"
        >
          <span>Change Password</span>
          {updatingPassword && (
            <span className="animate-spin">
              <LaSpinner />
            </span>
          )}
        </Button>

        <TextLink
          to="/account/forgot-password"
          className="mt-3 text-sm font-medium  md:mt-6"
          role={role}
        >
          Forgot password?
        </TextLink>
      </fieldset>
    </form>
  );
}

export default ChangePassword;
