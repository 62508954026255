import React from "react";
import Footer from "../../components/Footer";
import { NavBar } from "../../components/navigations";
import { ControlledSelect } from "../../components/ui";
import HelmetComponent from "../../components/HelmetComponent";

type FormData = {
  firstName: string;
  lastName: string;
  location: string;
  body: string;
  email: string;
};
export default function Contact() {
  const [wordCount, setWordCount] = React.useState(0);
  const [formData, setFormData] = React.useState<FormData>({
    firstName: "",
    lastName: "",
    location: "",
    body: "",
    email: "",
  });

  // open graph data
  const ogData = {
    title: "Leagues Arena - Contact Us",
    description: "Contact Leagues Arena for inquiries or assistance",
    url: `https://${window.location.host}/contact`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <NavBar />
      <main className="my-10 flex max-w-[1280px] flex-col items-start gap-4 px-4 pt-1 md:mx-auto md:my-[100px] md:grid md:grid-cols-2 md:gap-6 md:px-4">
        <div>
          <h2 className="mb-3 text-left text-[28px] font-bold leading-[33.6px] md:mb-4 md:text-5xl md:leading-[57.6px]">
            How can we help you?
          </h2>

          <h3 className="text-base font-normal leading-6 md:text-2xl md:leading-9">
            For any inquiries or assistance regarding Leagues Arena, feel free to contact
            us. We're dedicated to transforming the sports experience and promoting
            community among players, fans, and organizers.
          </h3>

          <div className="my-8 flex min-h-[184px] max-w-[628px] flex-col items-start gap-4 rounded-[4px] bg-[#CCDAFD]/20 pl-2 pr-4 md:rounded-2xl md:p-4">
            <div className="flex h-[40px] w-full flex-row items-center gap-2 border-b border-[#BBBBBB] p-2">
              <span className="mr-2 h-2 w-2 rounded-full bg-black" />
              <p>Building a profile</p>{" "}
            </div>
            <div className="flex h-[40px] w-full flex-row items-center gap-2 border-b border-[#BBBBBB] p-2">
              <span className="mr-2 h-2 w-2 rounded-full bg-black" />
              <p>Hosting and participating in tournaments</p>
            </div>
            <div className="flex min-h-[40px] w-full flex-row items-center gap-2 border-b border-[#BBBBBB] p-2">
              <span className="mr-2 h-2 w-2 rounded-full bg-black" />
              <p>Sharing tournament updates on your timeline</p>
            </div>
          </div>

          <aside>
            <h3 className="mb-4 text-2xl font-bold leading-9">Contact Information</h3>
            <div className="mb-2 flex items-start gap-2 text-sm md:text-base">
              <div className="font-bold">Phone:</div>
              <div className="flex flex-col gap-1">
                <p>+234-(0)813-7050-299</p>
                <p>+234-(0)816-2695-684</p>
              </div>
            </div>
            <div className="flex items-start gap-2 text-sm md:text-base">
              <div className="font-bold">Email:</div>
              <p>leaguesarena@gmail.com</p>
            </div>
          </aside>
        </div>

        <form className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-col items-stretch justify-center gap-4 lg:flex-row lg:items-center">
            <fieldset>
              <label htmlFor="firstname" className="text-sm md:text-base">
                First Name<span className="text-red">*</span>
              </label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Dave"
                required
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))
                }
                className="mt-2 w-full rounded border border-grey-light px-4 py-3 text-base lg:text-xl"
              />
            </fieldset>

            <fieldset>
              <label htmlFor="lastname" className="text-sm md:text-base">
                Last Name<span className="text-red">*</span>
              </label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Santan"
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))
                }
                required
                className="mt-2 w-full rounded border border-grey-light px-4 py-3 text-base lg:text-xl"
              />
            </fieldset>
          </div>

          <fieldset>
            <label htmlFor="email" className="text-sm md:text-base">
              Email<span className="text-red">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email address"
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
              required
              className="mt-2 w-full rounded border border-grey-light px-4 py-3 text-base lg:text-xl"
            />
          </fieldset>

          <fieldset className="flex flex-col gap-2">
            <label htmlFor="location" className="text-sm md:text-base">
              Location
              <span className="text-red">*</span>
            </label>
            <ControlledSelect
              placeholder="Select a location"
              name="location"
              id="location"
              fit={false}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  location: e.target.value,
                }))
              }
              value=""
            />
          </fieldset>
          <fieldset>
            <label htmlFor="message" className="text-sm md:text-base">
              Message<span className="text-red">*</span>
            </label>
            <textarea
              id="message"
              name="message"
              className="mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-primary-blue-dark focus:outline-none lg:text-xl"
              rows={6}
              placeholder="How lan leagues arena help you better?"
              maxLength={500}
              onChange={(e) => {
                setWordCount(e.target.value?.length);
                setFormData((prevState) => ({
                  ...prevState,
                  body: e.target.value,
                }));
              }}
            />
            <span className="flex w-full justify-end text-xs">
              {wordCount}
              /500
            </span>
          </fieldset>

          <a
            href={`mailto:leaguesarena@gmail.com?subject=${`${encodeURIComponent(
              formData.firstName
            )} ${encodeURIComponent(formData.lastName)}`}&body=${encodeURIComponent(
              formData.body
            )}`}
            className="w-full rounded bg-primary-blue-dark  py-4 text-center text-base font-medium  text-white hover:bg-primary-blue-lighter hover:text-black lg:text-2xl"
          >
            Send us a mail
          </a>
          {/* <button
            type="submit"
            className="w-full rounded  bg-primary-blue-dark py-4 text-base font-medium  text-white hover:bg-primary-blue-lighter hover:text-black lg:text-2xl"
          >
            Send Message
          </button> */}
        </form>
      </main>
      <Footer />
    </>
  );
}
