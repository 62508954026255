import { useGetTeamTournamentsQuery } from "../../redux/services/tournamentService";
import { LaClose, LaPlusIcon } from "../svg-icons";
import { Button, ButtonLink, Spinner } from "../ui";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { Link } from "react-router-dom";

interface Props {
  setSelectedCompetitionForTeamId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedPlayers?: React.Dispatch<React.SetStateAction<string[]>>;
  setCompetitionPlayersForTeam?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedPlayers?: string[];
  setSelectedCompetitionForTeam: React.Dispatch<
    React.SetStateAction<SelectedCompetition | null>
  >;
}

export default function TeamCompetitionsForm({
  setSelectedCompetitionForTeamId,
  setSelectedPlayers,
  setCompetitionPlayersForTeam,
  selectedPlayers,
  setSelectedCompetitionForTeam,
}: Props) {
  const teamId = useAppSelector(
    (state: RootState) => state.auth.profiles
  )?.find((profile) => profile.role === "team")?.profile;

  const { data: competitions, isLoading: competitionLoading } =
    useGetTeamTournamentsQuery({
      teamId,
    });

  const competitionList = competitions?.map((competition, index) => {
    const teamPlayers = competition?.teams.find((team) => team.team === teamId)
      ?.players as string[];
    const newSelectedPlayers = selectedPlayers
      ? selectedPlayers.filter((player) => !teamPlayers.includes(player))
      : [];

    const teams = competition.teams.map((team) => team.team as string);

    return (
      <Button
        key={index}
        role="organizer"
        intent="tertiary"
        className="flex cursor-pointer items-center justify-between capitalize"
        onClick={() => {
          setSelectedCompetitionForTeamId(competition._id);
          setSelectedCompetitionForTeam({
            id: competition._id,
            name: competition.name,
            maxTeam: competition.maxTeam,
            noOfPlayers: competition.noOfPlayers,
            teams,
            team: {
              id: teamId!,
              players: teamPlayers,
            },
          });
          setCompetitionPlayersForTeam &&
            setCompetitionPlayersForTeam(teamPlayers);
          setSelectedPlayers && setSelectedPlayers(newSelectedPlayers);
        }}
      >
        <span className="flex items-center gap-2">
          <span className="inline-block h-6 w-6 rounded-full border-2 border-primary-black-darker" />
          <span>{competition?.name}</span>
        </span>
      </Button>
    );
  });

  return (
    <div className="fixed bottom-0 right-0 z-[60] flex w-full flex-col rounded border-t border-grey-light bg-white py-4 shadow lg:w-[82%] lg:rounded-2xl lg:py-10">
      <div className="flex items-center justify-between border-b border-grey-light px-4 pb-4 lg:px-20">
        <h3 className="font-medium lg:text-2xl">Register players to...</h3>
        <button
          onClick={() => {
            setSelectedCompetitionForTeamId("none");
            setSelectedPlayers && setSelectedPlayers([]);
            setCompetitionPlayersForTeam && setCompetitionPlayersForTeam([]);
          }}
        >
          <LaClose />
        </button>
      </div>
      <div className="px-4 lg:px-20">
        <p className="my-4">Select from your list of active competition.</p>

        <div className="modern__scroll-bar mt-6 flex max-h-[250px] w-full flex-col overflow-y-scroll rounded border border-grey-light ">
          {competitionLoading ? (
            <div className="flex w-full items-center justify-center py-2">
              <Spinner />
            </div>
          ) : (
            <>
              {competitionList}
              {(competitionList?.length ?? 0) === 0 && (
                <EmptyStateAuto
                  description="You dont have any competition"
                  title="Nothing to see here"
                />
              )}
            </>
          )}
        </div>

        <div className="flex flex-col">
          {competitionList ? (
            <p className="mt-4 flex items-center gap-2 text-primary-blue-darker">
              <span>Cant find?</span>{" "}
              <Link to="/discover/competitions" className="font-bold">
                + Register for a new competition
              </Link>
            </p>
          ) : (
            <ButtonLink
              intent={"primary"}
              role="team"
              className="mt-6 items-center gap-1 lg:flex"
              to="/discover/competitions"
            >
              <LaPlusIcon stroke="#fff" /> Join Competition
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
}
