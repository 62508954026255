import "./burger.css";

function Burger({
  handleToggle,
  toggleNav,
}: {
  handleToggle: () => void;
  toggleNav: boolean;
}) {
  return (
    <button
      className="burger-menu z-40 lg:hidden"
      onClick={() => handleToggle()}
      role="button"
      aria-label="toggle"
    >
      <div className="relative flex h-[24px] w-[24px] cursor-pointer flex-col items-center justify-center">
        <div
          className={`${
            toggleNav && "top-bar"
          } top absolute left-0 top-[4px] h-[2px] w-[10px] bg-black`}
        ></div>
        <div
          className={`${
            toggleNav && "center-bar"
          } center h-[2px] w-[20px] bg-black`}
        ></div>
        <div
          className={`${
            toggleNav && "bottom-bar"
          } bottom absolute bottom-[4px] right-0 h-[2px] w-[10px] bg-black`}
        ></div>
      </div>
    </button>
  );
}

export default Burger;
