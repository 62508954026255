import { Link } from "react-router-dom";
import { ScoreDot } from "../ui";

type Props = {
  matches: [KnockoutMatch, KnockoutMatch];
};

function FirstRound({ matches }: Props) {
  console.log({ start: matches });
  const [matchA, matchB] = matches;
  const [teamA, teamB] = matchA.teams || [];
  const [teamC, teamD] = matchB.teams || [];
  const teamAData = matchA?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamA?._id
  );
  const teamBData = matchA?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamB?._id
  );
  const teamCData = matchB?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamC?._id
  );
  const teamDData = matchB?.teamData.find(
    (teamData: { team: string }) => teamData?.team === teamD?._id
  );

  return (
    <div className="flex items-center">
      <div className="flex flex-col items-start gap-2">
        <div className="w-full min-w-[200px] rounded border border-grey-light bg-white px-2 py-1 shadow">
          {teamA ? (
            <div className="flex items-center justify-between border-b border-grey-light">
              <Link
                to={`/profiles/team/${teamA?.name?.replace(/\s/, "-")}`}
                className="flex items-center gap-2"
              >
                <img
                  src={teamA?.avatar}
                  alt=""
                  className="h-4 w-4 rounded-full"
                />
                <p className="uppercase">{teamA?.name}</p>
              </Link>
              <ScoreDot
                teamAScore={teamAData?.score}
                teamBScore={teamBData?.score}
                teamAPenalties={teamAData?.penalties}
                teamBPenalties={teamBData?.penalties}
              />
            </div>
          ) : (
            <div className="flex items-center gap-2 border-b border-grey-light">
              <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
              <p className="uppercase">{matchA?.refMatch1[0]?.name}</p>
            </div>
          )}
          {teamB ? (
            <div className="flex items-center justify-between">
              <Link
                to={`/profiles/team/${teamB?.name?.replace(/\s/, "-")}`}
                className="flex items-center gap-2"
              >
                <img
                  src={teamB?.avatar}
                  alt=""
                  className="h-4 w-4 rounded-full"
                />
                <p className="uppercase">{teamB?.name}</p>
              </Link>
              <ScoreDot
                teamAScore={teamBData?.score}
                teamBScore={teamAData?.score}
                teamAPenalties={teamBData?.penalties}
                teamBPenalties={teamAData?.penalties}
              />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
              <p className="uppercase">{matchA?.refMatch2[0]?.name}</p>
            </div>
          )}
        </div>
        <div className="w-full min-w-[200px] rounded border border-grey-light bg-white px-2 py-1 shadow">
          {teamC ? (
            <div className="flex items-center justify-between border-b border-grey-light">
              <Link
                to={`/profiles/team/${teamC?.name?.replace(/\s/, "-")}`}
                className="flex items-center gap-2"
              >
                <img
                  src={teamC?.avatar}
                  alt=""
                  className="h-4 w-4 rounded-full"
                />
                <p className="uppercase">{teamC?.name}</p>
              </Link>
              <ScoreDot
                teamAScore={teamCData?.score}
                teamBScore={teamDData?.score}
                teamAPenalties={teamCData?.penalties}
                teamBPenalties={teamDData?.penalties}
              />
            </div>
          ) : (
            <div className="flex items-center gap-2 border-b border-grey-light">
              <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
              <p className="uppercase">{matchB?.refMatch1[0]?.name}</p>
            </div>
          )}
          {teamD ? (
            <div className="flex items-center justify-between">
              <Link
                to={`/profiles/team/${teamD?.name?.replace(/\s/, "-")}`}
                className="flex items-center gap-2"
              >
                <img
                  src={teamD?.avatar}
                  alt=""
                  className="h-4 w-4 rounded-full"
                />
                <p className="uppercase">{teamD?.name}</p>
              </Link>
              <ScoreDot
                teamAScore={teamDData?.score}
                teamBScore={teamCData?.score}
                teamAPenalties={teamDData?.penalties}
                teamBPenalties={teamCData?.penalties}
              />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <img src={""} alt="" className="h-5 w-5 rounded-full bg-grey" />
              <p className="uppercase">{matchB?.refMatch2[0]?.name}</p>
            </div>
          )}
        </div>
      </div>
      <div className="h-16 w-16 border border-l-0 border-dashed border-primary-black-darker" />
    </div>
  );
}

export default FirstRound;
