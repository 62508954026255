import TabHeading from "./tab-heading";
import { Link } from "react-router-dom";
import { NewTabIcon } from "../icons";
import TopThree from "./top-three";
import { SessionStat, Trainee, TrainingSession } from "../../@types/training";

export default function TopPlayers({
  data,
}: {
  data: (SessionStat & { trainee: Trainee; Session: TrainingSession })[];
}) {
  return (
    <div className="w-full rounded-2xl bg-white px-3 pt-8 shadow lg:w-[49%] lg:rounded-[32px] lg:px-5 lg:pb-6 lg:pt-12">
      <TabHeading
        title={"Top 3 Players"}
        description={"All-Time bests of the Game"}
        // tabs={["Week", "Month", "Year"]}
        tabs={["General"]}
      />
      <div className="my-8 lg:px-5">
        <TopThree data={data} />
      </div>
      <div className="flex justify-end border-t border-[#D9D9D9] py-3">
        <a
          href={`https://training.leaguesarena.com/leaderboard?team=CMT`}
          className="flex items-center gap-2 text-primary-blue-darker"
        >
          View History
          <NewTabIcon />
        </a>
      </div>
    </div>
  );
}
