// Import Swiper React components
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./swiper.css";

// import required modules
import { Autoplay } from "swiper";
import { Ref } from "react";
import { useMediaQuery } from "@mui/material";

export default function Slider(props: {
  slides: JSX.Element[];
  swiperRef?: Ref<SwiperRef>;
  handleSlideChange?: () => void;
}) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Swiper
      spaceBetween={0}
      ref={props?.swiperRef}
      centeredSlides={true}
      loop={!isMobile}
      onSlideChange={props.handleSlideChange && props.handleSlideChange}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      className="mySwiper"
    >
      {props.slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
}
