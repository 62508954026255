import React, { forwardRef } from "react";
import * as z from "zod";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import {
  useGetMyTeamQuery,
  useUpdateTeamMutation,
} from "../../redux/services/teamService";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RootState } from "../../redux/app/store";
import { setToastError } from "../../redux/features/modal/displayModalSlice";
import { LaGalleryEdit } from "../svg-icons";
import { Button, Input, Spinner } from "../ui";
import LaSpinner from "../svg-icons/LaSpinner";
import { cn } from "../../utils/cn";

const TeamFormSchema = z.object({
  name: z.string().min(3).max(50),
  shortName: z.string().min(2).max(4),
  color: z.string().min(3).max(8),
  avatar: z.any(),
});

type TTeamForm = z.infer<typeof TeamFormSchema>;

export interface EditTeamFormProps {}

export default forwardRef<HTMLDivElement, EditTeamFormProps>(
  function EditTeamForm({}, ref) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data: myTeam, isLoading } = useGetMyTeamQuery();
    const myId = useAppSelector((state: RootState) => state.auth.myId);

    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm<TTeamForm>({
      resolver: zodResolver(TeamFormSchema),
    });

    const [previewAvatar, setPreviewAvatar] = React.useState<string>(
      myTeam?.avatar || ""
    );

    const role = useAppSelector((state: RootState) => state.auth.role);
    const [updateTeamFn, { isLoading: isUpdating }] = useUpdateTeamMutation();
    const watchAvatar = watch("avatar");

    const colorList = [
      "#bada55",
      "#521DE7",
      "#FF6000",
      "#FFC043",
      "#05944F",
      "#B71375",
      "#E11900",
    ];

    const onSubmit: SubmitHandler<TTeamForm> = (data) => {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("shortName", data.shortName);
      formData.append("color", data.color);
      data.avatar[0] && formData.append("avatar", data.avatar[0]);

      updateTeamFn(formData)
        .unwrap()
        .then((res: { data: Team }) => {
          navigate(`/profiles/team/${res?.data.name}`, { replace: true });
        })
        .catch((err: any) => {
          console.log("update team eror: ", err);
          dispatch(setToastError(true));
        });
    };

    React.useEffect(() => {
      if (watchAvatar?.length) {
        const reader = new FileReader();
        reader.readAsDataURL(watchAvatar?.[0]);
        reader.onloadend = () => {
          setPreviewAvatar(reader?.result as React.SetStateAction<string>);
        };
      }
    });

    if (myTeam?.author?._id !== myId && !isLoading) {
      <Navigate to="404" />;
    }

    return (
      <div className="mt-6 flex flex-col items-center justify-center" ref={ref}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="flex w-full flex-col gap-2 lg:items-center">
            <h3 className="lg:hidden">Team photo</h3>
            <form
              className="flex flex-col gap-6 lg:w-[75%] lg:flex-row lg:gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <fieldset>
                <label
                  htmlFor="avatar"
                  className="relative flex w-[75px] cursor-pointer items-center justify-center"
                >
                  <input
                    type="file"
                    className="hidden"
                    {...register("avatar")}
                    accept="image/*"
                    aria-label="file-input"
                    id="avatar"
                  />
                  <div className="absolute z-50 flex flex-col items-center justify-center">
                    <span className="text-2xl">
                      <LaGalleryEdit
                        stroke={errors.avatar ? "#E71D36" : "#fff"}
                      />
                    </span>
                    <p
                      className={cn("text-[0.75rem] text-white", {
                        "text-red": errors.avatar,
                      })}
                    >
                      Add Photo
                    </p>
                  </div>
                  <img
                    src={previewAvatar || myTeam?.avatar}
                    alt="avatar"
                    className="h-[75px] w-[75px] rounded-full"
                  />
                  <div className="absolute h-[75px] w-[75px] rounded-full bg-[#00000050]"></div>
                </label>
                {errors.avatar && (
                  <p className="mt-2 text-sm text-red">
                    {errors.avatar.message as string}
                  </p>
                )}
              </fieldset>

              <fieldset className="flex w-full flex-col gap-6">
                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="name" className="text-sm md:text-base">
                    Team name
                  </label>
                  <Input
                    role={role}
                    type="text"
                    register={register}
                    name={"name"}
                    placeholder="e.g. League of champions"
                    defaultValue={myTeam?.name}
                    className={cn(
                      "mt-2 w-full rounded border  px-4 py-3 text-base focus:border-4  focus:border-opacity-30 lg:text-xl",
                      {
                        "error border-red focus:border-red": errors.name,
                      }
                    )}
                  />

                  {errors.name && (
                    <span className="mt-2 text-sm italic text-red">
                      {errors.name?.message}
                    </span>
                  )}
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <label htmlFor="shortName" className="text-sm md:text-base">
                    Team short name
                  </label>
                  <Input
                    role={role}
                    type="text"
                    register={register}
                    name={"shortName"}
                    placeholder="e.g. AFC"
                    defaultValue={myTeam?.shortName}
                    className={cn(
                      "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4  focus:border-opacity-30 lg:text-xl",
                      {
                        "error border-red focus:border-red": errors.shortName,
                      }
                    )}
                  />
                  <p className="text-xs">Maximum 4 characters</p>
                  {errors.shortName && (
                    <p className="mt-2 text-sm italic text-red">
                      {errors.shortName?.message}
                    </p>
                  )}
                </fieldset>

                <fieldset className="flex flex-col gap-2">
                  <p className="text-sm">Pick a color for your team</p>
                  <fieldset className="flex items-center gap-4">
                    {colorList.map((color) => {
                      return (
                        <div key={color}>
                          <input
                            type="radio"
                            id={color}
                            {...register("color")}
                            aria-label={color}
                            hidden
                            className="color__input"
                            value={color}
                            defaultChecked={color === myTeam?.color}
                          />
                          <label
                            htmlFor={color}
                            className={`color__label block h-6 w-6 cursor-pointer rounded-full`}
                            style={{ backgroundColor: color }}
                          ></label>
                        </div>
                      );
                    })}
                    {errors.color && (
                      <p className="mt-2 text-sm italic text-red">
                        {errors.color?.message}
                      </p>
                    )}
                  </fieldset>
                </fieldset>

                <fieldset className="mt-10 flex flex-col">
                  <Button
                    role={role}
                    intent="primary"
                    className={`mb-6 mt-4 min-w-[160px] md:text-2xl`}
                    disabled={isUpdating}
                    onClick={() => {}}
                  >
                    Save Changes
                    {isUpdating && (
                      <span className="animate-spin">
                        <LaSpinner />
                      </span>
                    )}
                  </Button>
                </fieldset>
              </fieldset>
            </form>
          </div>
        )}
      </div>
    );
  }
);
