import randomizeArray from "./randomizeArray";

export function generateSeasonFixtures(tournament: Tournament) {
  const teams = tournament.teams.map((teamData) => teamData.team);
  const numTeams = teams.length;

  // an array to store all matches
  const firstMatches = [];
  const secondMatches = [];

  let matchNo = 0;
  // Generate matches
  for (let i = 0; i < numTeams - 1; i++) {
    for (let j = i + 1; j < numTeams; j++) {
      const [team1, team2] = [teams[i], teams[j]] as [Team, Team];

      matchNo++;
      const match = {
        tournament: tournament._id,
        name: `${matchNo}`,
        date: `${new Date(tournament.datetime)}`,
        time: `${new Date(tournament.datetime)}`,
        location: tournament.venue,
        teamData: [
          {
            team: team1._id,
            score: 0,
            scorers: [],
            assisters: [],
            yellowCards: [],
            redCards: [],
          },
          {
            team: team2._id,
            score: 0,
            scorers: [],
            assisters: [],
            yellowCards: [],
            redCards: [],
          },
        ],
      };

      firstMatches.push(match);
    }
  }

  if (tournament.fixtureType === "home & away") {
    for (let i = 0; i < numTeams - 1; i++) {
      for (let j = i + 1; j < numTeams; j++) {
        const [team1, team2] = [teams[j], teams[i]] as [Team, Team];

        matchNo++;
        const match = {
          tournament: tournament._id,
          location: tournament.venue,
          name: `${matchNo}`,
          date: `${new Date(tournament.datetime)}`,
          time: `${new Date(tournament.datetime)}`,
          teamData: [
            {
              team: team1._id,
              score: 0,
              scorers: [],
              assisters: [],
              yellowCards: [],
              redCards: [],
            },
            {
              team: team2._id,
              score: 0,
              scorers: [],
              assisters: [],
              yellowCards: [],
              redCards: [],
            },
          ],
        };

        secondMatches.push(match);
      }
    }
  }

  return [...randomizeArray(firstMatches), ...randomizeArray(secondMatches)];
}

export default function generateKnockoutFixtures(tournament: Tournament) {
  const teamIds = tournament.teams
    .slice(0, tournament.teams.length / 2)
    .map((teamData) => {
      const team = teamData.team as Team;
      return team._id;
    });
  console.log(teamIds);
  const numTeams = teamIds.length / 2;

  const rounds = Math.log2(numTeams);
  const matches = generateMatches(
    teamIds as string[],
    tournament._id,
    tournament.venue,
    tournament.datetime
  );

  let previousRoundMatches = [...matches];
  let matchNo = matches.length;
  for (let i = 0; i < rounds; i++) {
    const currentRoundMatches = [];
    for (let j = 0; j < previousRoundMatches.length; j += 2) {
      const match = {
        tournament: tournament._id,
        date: new Date(tournament.datetime),
        time: new Date(tournament.datetime),
        name: `${matchNo}`,
        round: i + 2,
        location: tournament.venue,
      };
      matchNo++;
      currentRoundMatches.push(match);

      // @ts-expect-error //allow reassignment
      matches.push(match);
    }
    // @ts-expect-error //allow reassignment
    previousRoundMatches = currentRoundMatches;
  }
  return matches;
}

function generateMatches(
  teamIds: string[],
  tournamentId: string,
  venue: string,
  startDate: string
) {
  const numTeams = teamIds.length;
  const matches = [];

  const shuffledIds = randomizeArray(teamIds);

  let matchesNo = 0;
  // Generate matches between the teams
  for (let i = 0; i < numTeams; i += 2) {
    const team1 = shuffledIds[i];
    const team2 = shuffledIds[i + 1];
    const match = {
      teamData: [
        {
          team: team1,
          score: 0,
          scorers: [],
          assisters: [],
          yellowCards: [],

          redCards: [],
        },
        {
          team: team2,
          score: 0,
          scorers: [],
          assisters: [],
          yellowCards: [],
          redCards: [],
        },
      ],

      date: new Date(startDate),
      time: new Date(startDate),
      location: venue,
      tournament: tournamentId,
      round: 1,
      name: `${matchesNo++}`,
    };
    matches.push(match);
  }

  return matches;
}
export type GeneratedKnockoutFixtures = ReturnType<
  typeof generateKnockoutFixtures
>;
export type GeneratedSeasonFixtures = ReturnType<typeof generateSeasonFixtures>;
