import { useSearchParams } from "react-router-dom";
import { ButtonLink } from "../ui";

export default function SplashPlayer() {
  const [searchParams] = useSearchParams();
  const team = searchParams.get("team") || "";
  const tournament = searchParams.get("tournament") || "";
  const player = searchParams.get("player") || "";
  const code = searchParams.get("code") || "";
  const link = code
    ? `/account/final-profile/${code}`
    : `/profiles/player/${player.replace(/\s/g, "-")}`;

  return (
    <div className="flex h-full w-full flex-col items-center justify-center px-4">
      <div className="flex max-w-lg flex-col items-center justify-center gap-2">
        <h2 className="text-3xl capitalize lg:text-[40px]">{player}</h2>
        <p className="text-center font-medium">You have been invited</p>{" "}
        <p className="text-center text-xl lg:text-2xl">
          Congratulations, you have been invited to participate in the{" "}
          <span className="font-bold capitalize">
            {tournament.replace(/-/g, " ")}
          </span>{" "}
          representing the team:{" "}
          <span className="font-bold capitalize">
            {team.replace(/-/g, " ")}
          </span>
        </p>
        <ButtonLink
          role="player"
          to={link}
          intent={"primary"}
          className="mt-4 w-full"
        >
          Continue
        </ButtonLink>
      </div>
    </div>
  );
}
