import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
  modalToDisplay: "",
  toastSuccess: false,
  toastError: false,
  toastCopied: false,
  toastErrorText: "",
};

const displayModalSlice = createSlice({
  name: "displayModal",
  initialState,
  reducers: {
    setDisplayModal(state, action) {
      state.value = action.payload;
    },
    setModalToDisplay(state, action) {
      state.modalToDisplay = action.payload;
    },
    setToastSuccess(state, action) {
      state.toastSuccess = action.payload;
    },
    setToastError(state, action) {
      state.toastError = action.payload;
    },
    setToastCopied(state, action) {
      state.toastCopied = action.payload;
    },
    setToastErrorText(state, action) {
      state.toastErrorText = action.payload;
    },
  },
});

export const {
  setDisplayModal,
  setModalToDisplay,
  setToastError,
  setToastSuccess,
  setToastCopied,
  setToastErrorText,
} = displayModalSlice.actions;
export default displayModalSlice.reducer;
