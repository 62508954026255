type PlayerInfoProps = {
  bio?: string;
  location?: string;
  age?: string | number;
};

function PlayerInfo({ bio, location, age }: PlayerInfoProps) {
  return (
    <div className="flex w-full  flex-col gap-2 p-4">
      <div className="flex gap-2">
        <h3 className="text-base font-medium">Age:</h3>
        <p className="">{age ? age : "Nil"}</p>
      </div>

      <div className="flex gap-2">
        <h3 className="text-base font-medium">Location:</h3>
        <p className="">{location}</p>
      </div>
      <div className="flex gap-2">
        <h3 className="text-base font-medium">Bio:</h3>
        <p className="">{bio}</p>
      </div>
    </div>
  );
}

export default PlayerInfo;
