import React from "react";
import { SearchSkeleton } from "../ui/skeletons";
import SearchResult from "./SearchResult";
import { Search as SearchIcon } from "../svg-icons";
import { RootState } from "../../redux/app/store";
import { useAppSelector, useAppDispatch } from "../../redux/app/hooks";
import {
  setLastTab,
  fetchSearchResult,
} from "../../redux/features/search/searchSlice";
import useOnClickOutside from "../../hooks/useClickedOutside";
import useDebounce from "../../hooks/useDebounce";
import Button from "../ui/buttons/Button";
import { cn } from "../../utils/cn";
import { Input } from "../ui";

const tabs = ["Organizer", "Team", "Player", "Competitions"] as const;
type Tabs = (typeof tabs)[number];

type SearchProps = {
  placeholder?: string;
};

export default function Search({
  placeholder = "Search for Players, Competitions or Team",
}: SearchProps) {
  const dispatch = useAppDispatch();
  const searchRef = React.useRef<HTMLElement>(null);
  const { lastTab, fetchStatus, searchResult } = useAppSelector(
    (state: RootState) => state.search
  );
  const [tabState, setTabState] = React.useState<Tabs>(lastTab);
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [showTabs, setShowTabs] = React.useState<boolean>(false);
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;

  useOnClickOutside(searchRef, () => {
    if (searchRef.current?.focus) return;
    setShowResults(false);
    setShowTabs(false);
  });
  const deBounced = useDebounce(searchInput, 900);

  React.useEffect(() => {
    if (fetchStatus !== "fetching" && deBounced.trim().length > 0) {
      // @ts-ignore
      dispatch(fetchSearchResult({ kwd: deBounced, seg: tabState }));
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [dispatch, deBounced, tabState]);

  return (
    <section
      role="button"
      className="relative lg:w-[500px]"
      ref={searchRef}
      onFocus={() => setShowTabs(true)}
    >
      <div className="absolute left-4 top-4 z-30">
        <SearchIcon />
      </div>

      <Input
        role={role}
        type="text"
        name="search"
        aria-label="search"
        placeholder={placeholder}
        className="relative mt-0 flex-1 pl-11 outline-none"
        onFocus={() => setShowTabs(true)}
        onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
        onKeyDown={(e) => {
          if (e?.code === "Enter") {
            // @ts-ignore
            dispatch(fetchSearchResult({ kwd: deBounced, seg: tabState }));
            setShowResults(true);
          }
        }}
      />
      {/* Results */}
      <section className="absolute left-0 right-0 top-[calc(100%+24px)] z-30 overflow-hidden rounded bg-white shadow-lg">
        {showTabs && (
          <header className="grid w-full grid-cols-4 items-center">
            {tabs.map((tab, idx) => (
              <Button
                intent={"secondary"}
                role={role}
                key={`tb-${idx}`}
                className={cn(
                  "inline-block rounded-none border-0 px-1 py-3 leading-tight text-primary-black-darker transition-all duration-500",
                  {
                    "border-b-2": tabState === tabs[idx],
                  }
                )}
                onClick={() => {
                  setTabState(tabs[idx]);
                  dispatch(setLastTab(tabs[idx]));
                }}
              >
                {tab}
              </Button>
            ))}
          </header>
        )}
        {showResults && (
          <section className="mb-4 mt-3 flex max-h-[40vh] min-h-[3rem] flex-col gap-2 overflow-x-hidden rounded-md border border-black/50 p-2">
            {fetchStatus === "fetching" ? (
              <>
                <SearchSkeleton />
                <SearchSkeleton />
                <SearchSkeleton />
              </>
            ) : (
              <>
                {searchResult?.length > 0 ? (
                  <>
                    {searchResult?.map((data: any, idx: string) => (
                      <SearchResult key={`${idx}-${data?._id}-s`} data={data} />
                    ))}
                  </>
                ) : (
                  <>
                    <div className="text-center">Not Found</div>
                  </>
                )}
              </>
            )}
          </section>
        )}
      </section>
    </section>
  );
}
