import React from "react";

import { LaCloseRed } from "../../svg-icons";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { setToastErrorText } from "../../../redux/features/modal/displayModalSlice";
import { Button } from "..";
import { useNavigate } from "react-router-dom";

function ToastErrorText() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const role = useAppSelector((state: RootState) => state.auth.role);
  const toastErrorText = useAppSelector(
    (state: RootState) => state.displayModal.toastErrorText
  );
  const actions = ["upgrade", "contact"];
  const action = actions.find((action) =>
    toastErrorText.toLowerCase().includes(action)
  );

  const actionsToTake: {
    [key: string]: string;
  } = {
    upgrade: `/premium/checkout?planType=${role}`,
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setToastErrorText(""));
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg__shadow fixed right-4 top-4 z-[90]  flex w-[90%] items-center gap-6 rounded bg-white p-6 text-2xl font-medium md:bottom-10 md:right-10 md:top-auto md:w-[450px]">
      <div className=" flex h-[48px] w-[48px] items-center justify-center rounded-full border-4 border-[#E71D36]">
        <LaCloseRed />
      </div>
      <div className="max-w-xs">
        <p>Error</p>
        <div className="flex items-center justify-between gap-2">
          <p className="text-base font-normal">{toastErrorText}</p>
          {action && (
            <Button
              intent="secondary"
              role={role}
              className="py-1 capitalize"
              onClick={() => navigate(actionsToTake[action] || "")}
            >
              {action}{" "}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ToastErrorText;
