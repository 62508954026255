import { Link, useNavigate, useParams } from "react-router-dom";
import { LaArrowLeft } from "../../components/svg-icons";
import Search from "../../components/ui/Search";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import { useRef, useState } from "react";
import useOnClickOutside from "../../hooks/useClickedOutside";
import EmptyStateAuto from "../../components/ui/EmptyStateAuto";
import PlayerBadge from "../../components/ui/cards/PlayerBadge";
import HelmetComponent from "../../components/HelmetComponent";

export default function PlayerTransfer() {
  const navigate = useNavigate();
  const { tournamentId } = useParams();
  const { data: tournament } = useGetTournamentQuery({
    id: tournamentId as string,
  });
  const tournamentTeams = tournament?.teams
    ?.filter((teamData) => teamData?.registered)
    ?.map((team) => team.team) as Team[];
  const [teams, setTeams] = useState(tournamentTeams);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const selectedPlayers = (tournament?.teams?.find((teamObj) => {
    const team = teamObj.team as Team;
    return team._id === selectedTeam?._id;
  })?.players || []) as TPlayer[];
  const searchRef = useRef<HTMLInputElement>(null);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Player Transfer",
    description: "Transfer players to another team",
    url: `https://${window.location.host}/competitions/${tournamentId}/player-transfer`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  useOnClickOutside(searchRef, () => {
    setTeams([]);
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setTeams([]);
    const newTeams = tournamentTeams?.filter((team) => {
      return team?.name?.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setTeams(newTeams);
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />

      <div className="flex w-full flex-col items-center pb-24">
        <div className="w-full pb-20 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 px-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Player Transfer</h3>
          </div>

          <h3 className="mt-4 text-2xl">
            Find in <span className="capitalize">{tournament?.name}</span>
          </h3>
          <div className="relative mt-2 flex max-w-lg flex-col gap-3" ref={searchRef}>
            <Search
              placeholder="Search for teams"
              handleChange={handleSearch}
              searchRef={searchRef}
            />
            <div className="absolute top-[calc(100%+16px)] z-50 flex max-h-[300px] w-full flex-col  overflow-y-scroll bg-white shadow">
              {teams?.map((team) => (
                <button
                  className="flex items-center gap-2 border-b border-grey-light px-4 py-3"
                  key={team._id}
                  onClick={() => {
                    setSelectedTeam(team);
                    setTeams([]);
                  }}
                >
                  <img
                    src={team.avatar}
                    alt="team avatar"
                    className="h-8 w-8 rounded-full"
                  />
                  <p className="capitalize">{team.name}</p>
                </button>
              ))}
            </div>
          </div>

          <div className="mt-6 flex flex-col items-center justify-center">
            {!selectedTeam ? (
              <EmptyStateAuto
                title="Nothing to see here"
                description="search for team to see players"
              />
            ) : (
              <>
                <p className="self-start text-2xl">
                  <span className="capitalize">{selectedTeam?.name}</span> Squad in{" "}
                  <span className="capitalize">{tournament?.name}</span>
                </p>

                {!selectedPlayers.length && (
                  <EmptyStateAuto
                    title="Nothing to see here"
                    description="This team does not have any player yet."
                  />
                )}
                <div className="mt-4 grid w-full grid-cols-3 gap-4 lg:grid-cols-6">
                  {selectedPlayers.map((player) => (
                    <Link
                      to={`/competitions/${tournament?._id}/transfer?team=${selectedTeam?._id}&player=${player._id}`}
                      key={player._id}
                      className="w-full"
                    >
                      <PlayerBadge
                        key={player._id}
                        appearance={player.apps}
                        assist={player.assists}
                        avatar={player.avatar}
                        playerName={player.name}
                        goals={player.goals}
                        playerWing={player.designation}
                      />
                    </Link>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}