import React from "react";
import { LaArrowLeft, LaPlusIcon } from "../../components/svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useGetTeamsQuery } from "../../redux/services/teamService";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { TeamCardSkeleton } from "../../components/ui/skeletons";
import Search from "../../components/search/Search";
import { useInView } from "react-intersection-observer";
import { TeamCardDeck } from "../../components/ui/cards";
import { InviteForm } from "../../components/discover";
import MyTeamCardDeck from "../../components/ui/cards/MyTeamCardDeck";
import EmptyStateAuto from "../../components/ui/EmptyStateAuto";
import { LoadingDots } from "../../components/ui";
import { useQueryPlayerTeamsQuery } from "../../redux/services/playerService";
import { TList, usePaginateFetch } from "../../hooks/usePaginateFetch";
import { AddTeamsToCompetition } from "../../components/tournament";
import { cn } from "../../utils/cn";
import HelmetComponent from "../../components/HelmetComponent";

function Teams() {
  const ID_LENGTH = 24;
  const navigate = useNavigate();
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const [page, setPage] = React.useState<number>(1);
  const size = React.useRef<number>(8);
  const { ref, inView } = useInView({ delay: 600, threshold: 1 });
  const [list, setList] = React.useState<TList>([]);
  const [selectedCompetition, setSelectedCompetition] =
    React.useState<string>("none");
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>([]);
  const [competitionTeams, setCompetitionTeams] = React.useState<
    Tournament["teams"]
  >([]);
  const [maxTeam, setMaxTeam] = React.useState(0);

  const [activeTab, setActiveTab] = React.useState("discover");
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const playerId = useAppSelector((state: RootState) => state.auth.playerId);

  const { data: myTeams = [] } = useQueryPlayerTeamsQuery({
    playerId: playerId,
  });
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetTeamsQuery({
    count: size.current,
    page,
    order: "desc",
  });

  const isPlayer = role?.toLowerCase() === "player";

  usePaginateFetch({
    inView,
    isFetching,
    isLoading,
    page,
    list,
    setPage,
    setList,
    data,
  });
  // open graph data
  const ogData = {
    title: "Leagues Arena - Teams",
    description: "Discover the latest teams on Leagues Arena",
    url: `https://${window.location.host}/teams`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex w-full flex-col items-center justify-center pb-10">
        <div className="w-full lg:max-w-[87%]">
          <div
            className={cn("pb-24 pt-4 lg:pb-0", {
              "pb-40 lg:pb-40": selectedTeams.length,
            })}
          >
            <div className="flex items-center gap-4 px-4 py-4 lg:px-0">
              <button
                aria-label="back"
                className="text-xl lg:hidden"
                onClick={() => navigate(-1)}
              >
                <LaArrowLeft />
              </button>
              <h3 className="text-xl font-bold">Add teams</h3>
            </div>

            {isPlayer && (
              <div className="flex border-b border-grey-light text-center lg:gap-6 ">
                <button
                  className={`w-full py-4 capitalize lg:w-auto ${
                    activeTab === "discover" &&
                    "border-b-2 border-primary-blue-darker text-primary-blue-dark"
                  }`}
                  type="button"
                  onClick={() => setActiveTab("discover")}
                >
                  discover
                </button>
                <button
                  className={`relative w-full py-4 capitalize lg:w-auto ${
                    activeTab === "my teams" &&
                    "border-b-2 border-primary-blue-darker text-primary-blue-dark"
                  }`}
                  type="button"
                  onClick={() => setActiveTab("my teams")}
                  aria-label="my-teams"
                >
                  My Teams
                  <span className="absolute right-10 h-4 w-4 rounded-full bg-primary-blue-dark text-xs text-white lg:-right-6">
                    {myTeams?.length}
                  </span>
                </button>
              </div>
            )}

            {activeTab === "discover" && (
              <div className="px-4 py-4 lg:px-0">
                <div className="w-full lg:max-w-md">
                  <Search placeholder="Search for Teams" />
                </div>
                {role === "organizer" && (
                  <div className="mt-6 flex items-center gap-2">
                    <p>Can’t find a team?</p>
                    <Link
                      to={"/discover/create-teams"}
                      className="flex items-center gap-2 font-medium text-primary-blue-darker"
                    >
                      {" "}
                      <LaPlusIcon /> Add new team
                    </Link>
                  </div>
                )}

                <div className="mt-6 flex w-full grid-cols-3 flex-col justify-between gap-4 lg:grid lg:flex-row lg:gap-y-10">
                  {isLoading ? (
                    <>
                      <TeamCardSkeleton />
                      <TeamCardSkeleton />
                      <TeamCardSkeleton />
                      <TeamCardSkeleton />
                      <TeamCardSkeleton />
                      <TeamCardSkeleton />
                    </>
                  ) : (
                    <TeamCardDeck
                      teams={list as Team[]}
                      selectedTeams={selectedTeams}
                      setSelectedTeams={setSelectedTeams}
                      setSelectedCompetition={setSelectedCompetition}
                      selectedCompetition={selectedCompetition}
                      competitionTeams={competitionTeams}
                    />
                  )}
                </div>
                {!isLoading && !isFetching && list.length > 1 && (
                  <div ref={ref} className="py-4" />
                )}
                {isFetching && <LoadingDots role={role} />}
              </div>
            )}

            {activeTab === "my teams" && isPlayer && (
              <>
                {myTeams?.length === 0 ? (
                  <div className="pt-8">
                    <EmptyStateAuto
                      title="Nothing to see here"
                      description="You don't belong to any team. When you do it'll appear here"
                    />
                  </div>
                ) : (
                  <div className="mt-4 flex grid-cols-3 flex-col justify-between gap-4 px-4 lg:grid lg:flex-wrap lg:gap-y-10 lg:px-0">
                    <MyTeamCardDeck myId={myId} myTeams={myTeams} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {!selectedCompetition && (
        <InviteForm
          setSelectedCompetition={setSelectedCompetition}
          setCompetitionTeams={setCompetitionTeams}
          setSelectedTeams={setSelectedTeams}
          selectedTeams={selectedTeams}
          setMaxTeam={setMaxTeam}
        />
      )}
      {selectedTeams.length > 0 && selectedCompetition.length === ID_LENGTH && (
        <AddTeamsToCompetition
          setSelectedTeams={setSelectedTeams}
          setSelectedCompetition={setSelectedCompetition}
          setCompetitionTeams={setCompetitionTeams}
          selectedTeams={selectedTeams}
          shouldScroll={false}
          selectedCompetition={selectedCompetition}
          maxTeam={maxTeam}
          noOfTeamsJoined={selectedTeams.length}
        />
      )}
    </>
  );
}

export default Teams;
