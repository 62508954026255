function LaSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M22 22l-2-2m-8.5 1a9.5 9.5 0 100-19 9.5 9.5 0 000 19v0z"
        stroke={props.stroke || "#1a1a1a"}
        strokeWidth={props.strokeWidth || 2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaSearch;
