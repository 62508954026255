import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type WsType = {
  type:
    | "team_req_join"
    | "team_req_accept"
    | "team_req_decline"
    | "team_inv_join"
    | "team_inv_accept"
    | "team_inv_decline"
    | "team_rmv_player"
    | "tour_req_join"
    | "tour_req_accept"
    | "tour_req_decline"
    | "tour_inv_join"
    | "tour_inv_accept"
    | "tour_inv_decline"
    | "tour_rmv_team"
    | "tour_created"
    | null;
  emitted: boolean;
  received: boolean;
  incomingCount: number;
  ping: boolean;
};
const initialState: WsType = {
  type: null,
  emitted: false,
  received: false,
  incomingCount: 0,
  ping: false,
};

export const socketSlice = createSlice({
  name: "wsNotification",
  initialState,
  reducers: {
    updateWsType: (state, action) => {
      state.type = action.payload;
    },
    unsetWsType: (state, _) => {
      state.type = null;
    },
    updateEmitted: (state, action) => {
      state.emitted = action.payload;
    },
    setSignals: (
      state,
      action: PayloadAction<{
        emitted: boolean;
        received: boolean;
        count: number;
      }>
    ) => {
      state.emitted = action.payload.emitted;
      state.received = action.payload.received;
      state.incomingCount = action.payload.count;
    },
    setPing: (state, action: PayloadAction<boolean>) => {
      state.ping = action.payload;
    },
  },
});

export const { updateWsType, updateEmitted, setSignals, setPing } =
  socketSlice.actions;
export default socketSlice.reducer;
