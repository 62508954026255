import React from "react";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import Input from "./input/Input";
import { LaSearch } from "../svg-icons";
import useOnClickOutside from "../../hooks/useClickedOutside";

interface SearchProps {
  placeholder?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchRef: React.RefObject<HTMLElement>;
}

function Search({ placeholder, handleChange, searchRef }: SearchProps) {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const inputRef = React.useRef<HTMLInputElement>(null);
  useOnClickOutside(searchRef, () => {
    if (!inputRef.current) return;
    inputRef.current.value = "";
  });

  return (
    <section className="relative flex max-w-lg flex-col items-center justify-center">
      <LaSearch className="absolute left-4 z-30 font-bold" strokeWidth={3} />

      <Input
        ref={inputRef}
        role={role}
        type="search"
        name="search"
        aria-label="search"
        placeholder={placeholder}
        className="relative mt-0 flex-1 pl-11 outline-none"
        onChange={(e) => handleChange(e)}
      />
    </section>
  );
}

export default Search;
