import React from "react";
import { cn } from "../../../utils/cn";

type Ref = HTMLButtonElement;

interface SelectDropdownProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  role: string;
  isActive: boolean;
}

const SelectDropdown = React.forwardRef<Ref, SelectDropdownProps>(
  ({ children, className, isActive, role = "player", ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className={cn(
          "flex w-full cursor-pointer justify-between p-2 text-left capitalize hover:border-l-2",
          {
            "bg-[url('/src/assets/icons/check-black.svg')] bg-[95%_40%] bg-no-repeat":
              isActive,
            "hover:border-primary-blue-darker hover:bg-primary-blue-lighter":
              role === "player",
            "hover:border-secondary-blue-600 hover:bg-secondary-blue-600":
              role === "team",
            "hover:border-x-primary-black-darker hover:bg-primary-black-lighter":
              role === "organizer",
          },
          className
        )}
      >
        {children}
      </button>
    );
  }
);

export default SelectDropdown;
