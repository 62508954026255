import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LaArrowLeft, LaArrowRight } from "../../components/svg-icons";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import { useSaveFixturesMutation } from "../../redux/services/matchService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { Button, Spinner } from "../../components/ui";
import generateKnockoutFixtures, {
  generateSeasonFixtures,
} from "../../utils/generateFixtures";
import GeneratedSeasons from "../../components/tournament/GeneratedSeasons";
import GeneratedKnockouts from "../../components/tournament/GeneratedKnockouts";
import { cn } from "../../utils/cn";
import FixturesNavigation from "../../components/tournament/FixturesNavigation";
import MatchGraphic from "../../components/MatchGraphic";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import HelmetComponent from "../../components/HelmetComponent";

function GenerateFixtures() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { data: tournament } = useGetTournamentQuery({ id });
  const [saveFixtures, { isLoading: isPublishing }] = useSaveFixturesMutation();
  const noOfTeams =
    tournament?.teams.filter((team) => team?.registered)?.length || 0;
  const [draft, setDraft] = useState(0);
  const [show, setShow] = React.useState(false);
  useLockBodyScroll(show);

  // Returns a teamObject with team.id as key
  const teams = tournament?.teams.reduce((acc, teamData) => {
    const team = teamData.team as Team;
    acc[team._id] = team;
    return acc;
  }, {} as { [key: string]: Team });

  const hasAllPaid = tournament?.maxTeam === noOfTeams;
  const generatedFixtures = React.useMemo(() => {
    if (!hasAllPaid) return null;
    return (
      tournament &&
      [1, 2, 3].map((_) =>
        (tournament.format === "season"
          ? generateSeasonFixtures(tournament)
          : generateKnockoutFixtures(tournament)
        ).map((fixture) => {
          if (fixture?.teamData) {
            fixture?.teamData?.forEach((match) => {
              // @ts-expect-error //ignore this reassignment
              match.team = teams[match.team];
            });
          }
          return fixture;
        })
      )
    );
  }, [tournament]);

  const currentFixtures = generatedFixtures?.[draft];
  const renderedFixtures =
    tournament?.format === "knockout" ||
    (tournament?.format === "Group stage + Knockouts" &&
      tournament?.stage === "knockout")
      ? currentFixtures?.reduce((acc, fixture) => {
          // @ts-expect-error //ignore this reassignment
          if (acc[fixture?.round]) acc[fixture?.round].push(fixture);
          // @ts-expect-error //ignore this reassignment
          else acc[fixture?.round] = [fixture];
          return acc;
        }, {})
      : currentFixtures;

  function handleSaveFixtures() {
    const allowedTeamCounts = [4, 8, 16, 32];
    if (
      !allowedTeamCounts.includes(noOfTeams) &&
      tournament?.format !== "season"
    ) {
      dispatch(
        setToastErrorText(
          "Number of teams must be a power of 2 and less than 32"
        )
      );
    }

    saveFixtures({
      fixtures: currentFixtures?.map((fixture) => {
        return {
          ...fixture,
          teamData: fixture?.teamData?.map((teamData) => {
            // console.log(teamData.team);
            return {
              ...teamData,
              // @ts-expect-error //ignore this reassignment
              team: teamData.team?._id,
            };
          }),
        };
      }),

      tournamentId: id,
    })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        // navigate(`/competitions/${id}?tab=matches&subTab=fixtures`);
        setShow(true);
      })
      .catch((err) => {
        dispatch(setToastErrorText(err.data.message));
        console.log(err);
      });
  }

  // open graph data
  const ogData = {
    title: "Leagues Arena - Generate Fixtures",
    description: "Generate fixtures for your competition on Leagues Arena",
    url: `https://${window.location.host}/competitions/setup-fixtures${id}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full px-4 pb-24 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between">
            <button
              aria-label="back"
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Setup Fixtures</h3>
          </div>
          <FixturesNavigation draft={draft} />

          <div className="flex flex-col items-center lg:mt-16">
            {!renderedFixtures ? (
              !hasAllPaid ? (
                <p>Teams has not registered</p>
              ) : (
                <Spinner />
              )
            ) : (
              <>
                {tournament?.format === "season" ? (
                  // @ts-expect-error //ignore this reassignment
                  <GeneratedSeasons fixtures={renderedFixtures} />
                ) : (
                  <GeneratedKnockouts fixtures={renderedFixtures} />
                )}

                <div
                  className={cn(
                    "mt-10 flex w-full items-center justify-between gap-10",
                    {
                      "justify-start": draft > 1,
                      "justify-between": draft <= 1,
                      "justify-end": draft === 0,
                    }
                  )}
                >
                  {draft > 0 && (
                    <Button
                      intent={"primary"}
                      role="organizer"
                      className="justify-between "
                      onClick={() => {
                        setDraft(draft - 1);
                      }}
                    >
                      <LaArrowLeft stroke="#fff" /> Previous
                    </Button>
                  )}
                  {draft < 2 && (
                    <Button
                      intent={"primary"}
                      role="organizer"
                      className="justify-between"
                      onClick={() => {
                        setDraft(draft + 1);
                      }}
                    >
                      Next <LaArrowRight stroke="#fff" />
                    </Button>
                  )}
                </div>
                <Button
                  intent={"primary"}
                  role="organizer"
                  className="mt-5 w-full lg:mt-10"
                  onClick={handleSaveFixtures}
                  disabled={isPublishing}
                >
                  Publish Draft {draft + 1}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {show && (
        <section className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-start justify-center bg-black/20">
          <div className="mt-4 rounded-lg bg-white p-4">
            <MatchGraphic
              open={show}
              fixture={
                tournament?.format === "knockout" ||
                tournament?.format === "Group stage + Knockouts"
                  ? // @ts-expect-error // do not type
                    renderedFixtures?.[1]
                  : renderedFixtures
              }
              tournament={tournament!}
              loc={`/competitions/${id}?tab=matches&subTab=fixtures`}
            />
          </div>
        </section>
      )}
    </>
  );
}

export default GenerateFixtures;
