import styled, { css } from "styled-components";

const FileLabel = styled.label<{ $userRole: string }>`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #0045f6;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 4px;
  color: #0045f6;
  font-weight: 500;
  &:hover {
    background: #ccdafd;
  }
  ${(props) =>
    props.$userRole === "organizer" &&
    css`
      border: 1px solid #1a1a1a;
      color: #1a1a1a;
      &:hover {
        background: #e6e6e6;
      }
      &.black__svg svg path {
        stroke: #1a1a1a;
      }
    `};
`;

export default FileLabel;
