import { Box, CircularProgress } from "@mui/material";

function
  Spinner() {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress color="inherit" role="progressbar" aria-label="spinner" />
    </Box>
  );
}

export default Spinner;
