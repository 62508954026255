import type { ReactElement } from "react";
import LaStarWhite from "../svg-icons/LaStarWhite";
import { cn } from "../../utils/cn";
import { Link } from "react-router-dom";

export interface PricingHeroProps {
  planType: string;
  isYearly: boolean;
}

export default function PricingHero({
  planType,
  isYearly,
}: PricingHeroProps): ReactElement {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-12 lg:py-24">
      <div className="mb-3 flex h-9 w-9 items-center justify-center rounded-full bg-[#FBBE28]">
        <LaStarWhite />
      </div>
      <h1 className="text-center text-3xl font-bold  lg:text-[56px]">
        Plans that fits your play
      </h1>
      <p className="mt-4 text-center text-xl">
        Simple, transparent pricing that grows with you.
      </p>
      <div className="mt-10 flex rounded-lg border border-grey-light bg-grey-lighter p-1">
        <Link
          to={`/pricing?planType=player&isYearly=${isYearly}`}
          className={cn("px-3 py-2 lg:text-xl", {
            "bg-white shadow": planType === "player",
          })}
        >
          Player
        </Link>

        <Link
          to={`/pricing?planType=team&isYearly=${isYearly}`}
          className={cn("rounded px-3 py-2 lg:text-xl", {
            "bg-white shadow": planType === "team",
          })}
        >
          Team owner
        </Link>

        <Link
          to={`/pricing?planType=organizer&isYearly=${isYearly}`}
          className={cn("rounded px-3 py-2 lg:text-xl ", {
            "bg-white shadow": planType === "organizer",
          })}
        >
          Organizer
        </Link>
      </div>
    </div>
  );
}
