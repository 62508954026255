import PasswordInput from "../password-input/PasswordInput";

interface PasswordProps extends React.HTMLAttributes<HTMLFieldSetElement> {
  label: string;
  name?: string;
  error: {
    password?: string;
    error?: string;
    clearError: (key: string) => void;
    confirmPassword?: string;
  };
}

/**
 * 
 * @param {*} props 
 * @returns 
 * @example props {
 * label: "Password"
 }
 * @property {label} string - The label of the password field
 */
function Password(props: PasswordProps) {
  return (
    <fieldset className="relative">
      <label htmlFor={props.name} className="text-sm md:text-base">
        {props.label}
        <span className="text-red">*</span>
      </label>
      <PasswordInput
        name={props.name || "password"}
        placeholder="Enter a password"
        error={props.error}
      />
    </fieldset>
  );
}

export default Password;
