import { useAppSelector, useAppDispatch } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import {
  setToastCopied,
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { Button } from "../ui";
import React from "react";
import formatToNaira from "../../utils/formatPrice";
import { useNavigate } from "react-router-dom";
import { useRegisterForTournamentMutation } from "../../redux/services/tournamentService";
import LaSpinner from "../svg-icons/LaSpinner";
import { cn } from "../../utils/cn";
import { setFromLink } from "../../redux/features/auth/authSlice";

type Props = {
  tournament?: Tournament;
  setShowAcceptInvite: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRequestToJoin: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>;
  canJoinMoreTournaments: boolean;
  plan?: string;
  hasTeam: boolean;
};

export default function PublicPlayerOptions({ tournament }: Props) {
  const [_, copyToClipboard] = useCopyToClipboard();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const role = useAppSelector((state: RootState) => state.auth.role);
  const isTeam = role === "team";
  const profiles = useAppSelector((state: RootState) => state.auth.profiles);
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const currentProfile = profiles?.find(
    (profile) => profile.role?.toLowerCase() === role
  );
  const isPrivate = tournament?.tournamentType === "private";
  const [registerForTournament, { isLoading }] =
    useRegisterForTournamentMutation();

  const isInTournament = tournament?.teams.find((teamInfo) => {
    const team = teamInfo.team as Team;
    return team?.author === myId;
  });
  const isRegisterd = isInTournament?.registered;

  function shareLink(link: string) {
    copyToClipboard(link).then((hasCopied) => {
      dispatch(setToastCopied(hasCopied));
    });
  }

  function handleRegistration() {
    if (!isLoggedIn) {
      dispatch(setFromLink(window.location.pathname));
      return navigate("/account/login");
    }
    if (tournament?.registrationFee) {
      return navigate(`/payment?tournamentId=${tournament?._id}`);
    }
    registerForTournament({
      teamId: currentProfile?.profile,
      tournamentId: tournament?._id,
    })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        navigate(`/competitions/${tournament?._id}?tab=teams`);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  }

  return (
    <>
      <Button
        intent="secondary"
        role={role}
        onClick={() =>
          shareLink(`${window.location.host}${window.location.pathname}`)
        }
        className={cn(
          "hidden cursor-pointer rounded  p-3 text-sm font-medium lg:block",
          {
            "border border-primary-black-darker text-primary-black-darker":
              !role,
          }
        )}
      >
        Share
      </Button>

      {isTeam && isRegisterd && (
        <button
          className="cursor-default rounded bg-green p-3 text-sm font-medium text-white"
          disabled
        >
          Joined
        </button>
      )}
      {!isRegisterd && (
        <Button
          intent="primary"
          role={role}
          className={cn(
            "rounded p-3 text-sm font-medium text-white disabled:cursor-not-allowed disabled:opacity-50",
            {
              "border bg-primary-black-darker text-white": !role,
            }
          )}
          onClick={handleRegistration}
          disabled={
            (role && role !== "team") ||
            isLoading ||
            isPrivate ||
            !!isInTournament
          }
        >
          {isPrivate ? (
            "Private"
          ) : isInTournament ? (
            "Pending verification"
          ) : (
            <>
              Register for {formatToNaira(tournament?.registrationFee ?? 0)}
              {isLoading && <LaSpinner className="animate-spin" />}
            </>
          )}
        </Button>
      )}
    </>
  );
}
