import LaEmptyState from "../svg-icons/LaEmptyState";

export default function EmptyState() {
  return (
    <div className="flex h-[474px] w-full flex-col items-center justify-center lg:h-[500px] ">
      <LaEmptyState />
      <h3 className="mt-8 text-center text-xl font-bold lg:text-2xl">
        Nothing to see here
      </h3>
      <p className="mt-2 max-w-[300px] text-center text-base font-medium text-[#767676]">
        You do not have any activity yet. When you do they'll appear here
      </p>
    </div>
  );
}
