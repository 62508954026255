import React from "react";
import { LaCalendar } from "../../svg-icons";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";
import { arrayRange } from "../../../utils/arrayRange";
import Text from "../text/Text";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../mocks/store";
import { cn } from "../../../utils/cn";

type CalendarSectionProps = {
  date: string;
  setMatchDate: React.Dispatch<React.SetStateAction<string>>;
};

function CalendarSection({ date, setMatchDate }: CalendarSectionProps) {
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [newDate, setNewDate]: any = React.useState(date);
  const daysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const dateToDisplay = newDate !== undefined ? new Date(newDate) : null;
  const dayOfWeek = Number(dateToDisplay?.getDay());
  const dayOfMonth = Number(dateToDisplay?.getDate());
  const lastDayOfMonth = Number(
    moment(dateToDisplay).endOf("month").format("D")
  );
  const today = new Date();
  const prevMonth = moment(dateToDisplay).subtract(1, "month");
  const lastDayOfPrevMonth = Number(
    moment(prevMonth).endOf("month").format("D")
  );

  function handleShowCalendar() {
    setShowCalendar((prev) => !prev);
  }

  function handleSelection(newValue: any) {
    setNewDate(newValue);
    setShowCalendar(false);
    setMatchDate(new Date(newValue).toISOString());
  }

  const dateCard = daysOfTheWeek.map((weekDay, i) => {
    const prevMonthDays = arrayRange(
      lastDayOfPrevMonth - dayOfWeek + 1,
      lastDayOfPrevMonth,
      1
    );
    const day =
      dayOfMonth - dayOfWeek + i < 1
        ? prevMonthDays[i]
        : dayOfMonth - dayOfWeek + i > +lastDayOfMonth
        ? i - dayOfWeek
        : dayOfMonth - dayOfWeek + i;

    return (
      <button
        className="flex flex-col items-center"
        key={weekDay}
        onClick={() => handleSelection(dateToDisplay?.setDate(day))}
        aria-label="dates"
      >
        {/* ${ */}
        {/* dayOfWeek !== undefined && */}
        {/* daysOfTheWeek[dayOfWeek] === weekDay && */}
        {/* "text-primary-blue-dark" */}
        {/* }`} */}

        <Text
          role={role}
          className={cn("text-xs font-medium", {
            "text-primary-black-darker":
              dayOfWeek !== undefined && daysOfTheWeek[dayOfWeek] !== weekDay,
          })}
        >
          {today.getDate() === day ? "Today" : weekDay}
        </Text>
        <Text
          role={role}
          className={cn("font-bold", {
            "text-primary-black-darker":
              dayOfWeek !== undefined && daysOfTheWeek[dayOfWeek] !== weekDay,
          })}
        >
          {day < 10 ? `0${day}` : day}
        </Text>
      </button>
    );
  });

  return (
    <div className="relative flex items-center justify-between border-b border-grey-light px-4 py-4">
      {dateCard}
      <button
        aria-label="calendar-icon"
        className="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full border border-grey-light text-2xl"
        type="button"
        onClick={handleShowCalendar}
      >
        <LaCalendar />
      </button>
      {showCalendar && (
        <div className="absolute top-[100%] rounded border border-grey-light bg-white shadow">
          <DateCalendar
            defaultValue={dayjs(dateToDisplay)}
            onChange={(newValue) => handleSelection(newValue)}
          />
        </div>
      )}
    </div>
  );
}

export default CalendarSection;
