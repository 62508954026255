import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamId: "",
};

const teamSlice = createSlice({
  name: "team",
  initialState: initialState,
  reducers: {
    setTeamId: (state, action) => {
      state.teamId = action.payload;
    },
  },
});

export const { setTeamId } = teamSlice.actions;
export default teamSlice.reducer;
