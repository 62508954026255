import type { ReactElement } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { cn } from "../../utils/cn";
import { Button, Input, Spinner } from "../ui";
import React from "react";
import { LaArrowRight, LaGalleryEdit } from "../svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateTeamMutation } from "../../redux/services/teamService";
import {
  setToastError,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { ConfirmPopup } from "../modals";
import { SetURLSearchParams, useNavigate } from "react-router-dom";
import { RootState } from "../../redux/app/store";

const TeamFormSchema = z.object({
  name: z.string().min(3).max(50),
  shortName: z.string().min(2).max(4),
  color: z.string().min(3).max(8),
  avatar: z.any().refine((file) => {
    if (file.length) {
      return file[0].type.includes("image");
    }
    return false;
  }, "Please upload an image"),
});

type TTeamForm = z.infer<typeof TeamFormSchema>;

export default function TeamForm({
  setOnBoardingParams,
}: {
  setOnBoardingParams: SetURLSearchParams;
}): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [previewAvatar, setPreviewAvatar] = React.useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TTeamForm>({
    resolver: zodResolver(TeamFormSchema),
  });
  const watchAvatar = watch("avatar");
  const [createTeamFn, { isLoading }] = useCreateTeamMutation();
  const fromLink = useAppSelector((state: RootState) => state.auth.fromLink);

  const onSubmit = (data: TTeamForm) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("shortName", data.shortName);
    formData.append("color", data.color);
    data.avatar[0] && formData.append("avatar", data.avatar[0]);

    createTeamFn(formData)
      .unwrap()
      .then((res) => {
        dispatch(setToastSuccess(true));
        setOnBoardingParams({
          step: "2",
          role: "team",
        });
        console.log(res);

        navigate(
          fromLink
            ? fromLink
            : `/profiles/team/${res.data.name.replace(/\s/g, "-")}`
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(setToastError(true));
      });
  };

  const colors = [
    "#bada55",
    "#521DE7",
    "#FF6000",
    "#FFC043",
    "#05944F",
    "#B71375",
    "#E11900",
  ].map((color) => {
    return (
      <div key={color}>
        <input
          type="radio"
          id={color}
          {...register("color")}
          value={color}
          hidden
          className="color__input"
        />
        <label
          htmlFor={color}
          className={`color__label block h-6 w-6 cursor-pointer rounded-full`}
          style={{ backgroundColor: color }}
        ></label>
      </div>
    );
  });

  React.useEffect(() => {
    if (watchAvatar?.length) {
      const reader = new FileReader();
      reader.readAsDataURL(watchAvatar?.[0]);
      reader.onloadend = () => {
        setPreviewAvatar(reader?.result as string);
      };
    }
  });

  return (
    <>
      <div className="flex w-full flex-col items-center rounded px-4 md:rounded-2xl lg:px-10">
        <h2 className="text-2xl md:text-[2.5rem]">Set up your team profile</h2>
        <p className="mt-3 text-center text-base md:mx-20 md:mt-4 md:text-[1.5rem] md:leading-9 lg:mx-0">
          Fill in these details and Upload a picture so we can setup your
          profile.
        </p>
        <form
          className="mt-4 flex flex-col items-stretch gap-6 lg:mt-6 lg:w-[90%]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset>
            <label htmlFor="profilePhoto" className="text-sm md:text-base">
              Team photo
            </label>
            <div className="relative mt-2 flex h-[70px] w-[70px] items-center justify-center lg:h-[100px] lg:w-[100px]">
              <img
                src={previewAvatar}
                alt=""
                className="h-full w-full rounded-full"
              />
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-full bg-[#0000004d]">
                <label className="absolute flex cursor-pointer flex-col items-center justify-center ">
                  <span className="text-2xl">
                    <LaGalleryEdit
                      stroke={errors.avatar ? "#E71D36" : "#fff"}
                    />
                  </span>{" "}
                  <input
                    type="file"
                    className="hidden"
                    {...register("avatar")}
                  />
                  <span
                    className={cn("text-[0.75rem] text-white lg:mt-2", {
                      "text-red": errors.avatar,
                    })}
                  >
                    Add Photo
                  </span>
                </label>
              </div>
            </div>
            {errors.avatar && (
              <p className="mt-2 text-sm text-red">
                {errors.avatar.message as string}
              </p>
            )}
          </fieldset>
          <fieldset>
            <label htmlFor="name" className="text-sm md:text-base">
              Team Name<span className="text-red">*</span>
            </label>
            <Input
              role="team"
              type="text"
              id="name"
              register={register}
              name={"name"}
              placeholder="e.g. Arsenal FC"
              required
              className={cn(
                "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4 focus:border-opacity-30 lg:text-xl",
                {
                  "error border-red focus:border-red": errors.name,
                }
              )}
            />
            {errors.name && (
              <span className="mt-2 text-sm italic text-red">
                {errors.name?.message}
              </span>
            )}
          </fieldset>

          <fieldset>
            <label htmlFor="shortName" className="text-sm md:text-base">
              Team short name <span className="text-red">*</span>
            </label>
            <Input
              role="team"
              type="text"
              maxLength={4}
              register={register}
              name={"shortName"}
              required
              placeholder="e.g. AFC"
              className={cn(
                "mt-2 w-full rounded border border-grey-light px-4 py-3 text-base focus:border-4  focus:border-opacity-30 lg:text-xl",
                {
                  "error border-red focus:border-red": errors.shortName,
                }
              )}
            />
            <p className="text-xs">Maximum 4 characters</p>
            {errors.shortName && (
              <p className="mt-2 text-sm italic text-red">
                {errors.shortName?.message}
              </p>
            )}
          </fieldset>

          <fieldset className="flex flex-col gap-2">
            <p className="text-sm">
              Pick a color for your team <span className="text-red">*</span>
            </p>
            <fieldset className="flex items-center gap-4">
              {colors}
              {errors.color && (
                <p className="mt-2 text-sm italic text-red">
                  {errors.color?.message}
                </p>
              )}
            </fieldset>
          </fieldset>

          <Button
            intent="primary"
            role="team"
            className="flex items-center justify-center rounded p-4 text-base text-white md:mt-6 md:text-2xl"
          >
            {isLoading ? (
              <span className="animate-spin">
                <Spinner />
              </span>
            ) : (
              "Proceed to register players"
            )}
          </Button>
        </form>
      </div>
    </>
  );
}
