import moment from "moment";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";

type Props = {
  match: Match;
};

function ScoreCard({ match }: Props) {
  const [teamA, teamB] = match.teamData ?? [];
  // const past = moment().diff(moment(match.date), "seconds") > 0;
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const isTournamentAuthor = myId === match.tournament.author;

  const matchCompletedTime = new Date(match.timeCompleted);
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - matchCompletedTime.getTime();
  const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
  // const canEdit =
  // timeDifference < twentyFourHoursInMillis || !match.timeCompleted;
  const canEdit = true;

  return (
    <div className="flex flex-col items-center justify-between  rounded border border-grey-light pt-3">
      <div className="mt-3 grid w-full grid-cols-3 items-center justify-between px-3">
        <div className="flex items-center gap-2 ">
          <img
            src={teamA?.team?.avatar}
            alt="TA"
            className="h-8 w-8 rounded-full"
          />
          <p className="uppercase">{teamA?.team?.name}</p>
        </div>

        <div className="flex flex-col items-center gap-4">
          <p className="text-center text-xs font-medium">
            Match {match?.name} <span>·</span>{" "}
            {match?.date ? moment(match?.date).format("ll") : "TBD"}
          </p>
          {match.status === "completed" ? (
            <div className="flex items-center justify-center gap-9 font-medium">
              <div
                className={`h-[8px] w-[8px] rounded-full ${
                  teamA?.score > teamB?.score
                    ? "bg-green"
                    : teamA?.score === teamB?.score
                    ? "bg-primary-blue-darker"
                    : "bg-red"
                }`}
              />
              <div className="flex items-center justify-center gap-1 text-base font-medium">
                <p>{teamA?.score}</p>
                <p>:</p>
                <p>{teamB?.score}</p>
              </div>
              <div
                className={`h-2 w-2 rounded-full ${
                  teamB?.score > teamA?.score
                    ? "bg-green"
                    : teamB?.score === teamA?.score
                    ? "bg-primary-blue-darker"
                    : "bg-red"
                }`}
              />
            </div>
          ) : (
            <h3 className="mt-3 font-medium">
              {match?.time ? moment(match?.time).format("HH:mm a") : "TBD"}
            </h3>
          )}
        </div>

        <div className="flex items-center justify-end gap-2">
          <p className="uppercase">{teamB?.team?.name}</p>
          <img
            src={teamB?.team?.avatar}
            alt="TB"
            className="h-8 w-8 rounded-full"
          />
        </div>
      </div>

      <p className="my-3 px-4 text-center text-sm font-medium text-grey">
        {match?.location}
      </p>

      {/* {canEdit && past && isTournamentAuthor && ( */}
      {canEdit && isTournamentAuthor && (
        <Link
          to={`/competitions/scores/edit/${match?._id}`}
          className="block w-full bg-primary-black-lighter py-3 text-center text-base font-medium"
        >
          {match.timeCompleted ? "Edit Score" : "Enter Score"}
        </Link>
      )}
    </div>
  );
}

export default ScoreCard;
