function LaFeed({ stroke = "#767676" }: { stroke?: string }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" fill="none">
      <g
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.77 2.84l-5.39 4.2c-.9.7-1.63 2.19-1.63 3.32v7.41c0 2.32 1.89 4.22 4.21 4.22h11.58c2.32 0 4.21-1.9 4.21-4.21V10.5c0-1.21-.81-2.76-1.8-3.45l-6.18-4.33c-1.4-.98-3.65-.93-5 .12zM12.75 17.99v-3" />
      </g>
    </svg>
  );
}

export default LaFeed;
