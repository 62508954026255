import React from "react";
import { useGetTeamByNameQuery } from "../../redux/services/teamService";
import { useParams, useNavigate } from "react-router-dom";
import { LaArrowLeft } from "../../components/svg-icons";
import { Spinner } from "../../components/ui";
import { TeamActionTabs, TeamProfile } from "../../components/team";
import HelmetComponent from "../../components/HelmetComponent";

const Team: React.FC = () => {
  const navigate = useNavigate();
  const { teamName } = useParams();
  const { data: team, isLoading: isLoadingTeam } = useGetTeamByNameQuery({
    name: teamName?.replace(/-/g, " "),
  });

  // open graph data
  const ogData = {
    title: "Leagues Arena - Team Profile",
    description: `View the profile of ${teamName} on Leagues Arena`,
    url: `https://${window.location.host}/profiles/team/${teamName}`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  if (isLoadingTeam)
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full flex-1 pt-2 lg:max-w-[87%]">
          <div className="flex items-center gap-4 px-4 py-4 lg:mt-14 lg:flex-row-reverse lg:justify-between lg:px-0">
            <button
              className="flex items-center gap-2 text-xl"
              onClick={() => navigate(-1)}
            >
              <LaArrowLeft /> <span className="hidden lg:block">Back</span>{" "}
            </button>
            <h3 className="text-xl font-bold">Team Profile</h3>
          </div>

          <TeamProfile team={team} isLoadingTeam={isLoadingTeam} />

          <TeamActionTabs team={team} />
        </div>
      </div>
    </>
  );
};
export default Team;
