import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as Icons from "../../components/svg-icons";
import { Spinner } from "../../components/ui";
import { useAppSelector } from "../../redux/app/hooks";
import { useGetTournamentQuery } from "../../redux/services/tournamentService";
import {
  EditTournamentBanner,
  EditTournamentContactForm,
  EditTournamentForm,
  EditTournamentRewardForm,
} from "../../components/create-tournament";
import { RootState } from "../../redux/app/store";
import { useForm } from "react-hook-form";
import HelmetComponent from "../../components/HelmetComponent";

export type EditTournamentFormValues = {
  [key: string]: any;
  name: string;
  numberOfTeams: string;
  maxTeam: number;
  location: string;
  // avatar: FileList;
  venue: string;
  banner: FileList;
  description: string;
  fixtureType: string;
  contactName: string;
  contactEmail: string;
  phoneNumber: string;
  phoneNumber2: string;
  contactAddress: string;
  registrationFee: string;
  winnerPrize: string;
  runnerUpPrize: string;
  thirdPlacePrize: string;
  datetime: string;
  category: string;
  noOfPlayers: string;
};

function EditTournament() {
  const { tournamentId } = useParams();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const userId = useAppSelector((state: RootState) => state.auth?.myId);
  const [searchParams] = useSearchParams();
  const page = searchParams.get("p") || "1";
  const {
    data: prevData,
    isLoading: isLoadingPrev,
    isError: isTournamentError,
  } = useGetTournamentQuery({
    id: tournamentId,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm<EditTournamentFormValues>({});

  const watchLocation = watch("location");
  const watchNumberOfTeams = watch("numberOfTeams");
  const watchFixtureType = watch("fixtureType");
  const watchBanner = watch("banner");
  const watchMaxTeam = watch("maxTeam");

  const watchCategory = watch("category");
  const watchRegistrationFee = watch("registrationFee");
  const watchNoOfPlayers = watch("noOfPlayers");
  const FEE_PER_PLAYER = 800;
  const FREE_FEE_PER_PLAYER = 200;

  const laFreeCut =
    (+watchNoOfPlayers || +(prevData?.noOfPlayers ?? 0)) *
    (+watchMaxTeam || +(prevData?.maxTeam ?? 0)) *
    FREE_FEE_PER_PLAYER;

  const totalFee =
    (+watchRegistrationFee || +(prevData?.registrationFee ?? 0)) +
    (+watchNoOfPlayers || +(prevData?.noOfPlayers ?? 0)) * FEE_PER_PLAYER;

  if (!isLoggedIn) {
    return <Navigate to="/account/login" />;
  }

  if (isLoadingPrev) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }
  if (isTournamentError || prevData?.author !== userId) {
    return <Navigate to="/404" />;
  }

  // open graph data
  const ogData = {
    title: "Leagues Arena - Edit Tournament",
    description: `Edit your tournament ${prevData?.name} on Leagues Arena`,
    url: `https://${window.location.host}/competitions/${prevData?._id}/edit`,
    image: prevData?.banner,
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <main className="flex flex-col items-center overflow-x-hidden">
        <EditTournamentBanner
          register={register}
          errors={errors}
          watchBanner={watchBanner}
          prevBanner={prevData.banner}
        />

        <div className="hidden w-full max-w-5xl items-center justify-between px-4 lg:flex">
          <button type="button" onClick={() => navigate(-1)} aria-label="close">
            <Icons.LaClose />
          </button>
        </div>

        {page === "1" && (
          <EditTournamentForm
            errors={errors}
            handleSubmit={handleSubmit}
            register={register}
            setError={setError}
            setValue={setValue}
            watchFixtureType={watchFixtureType}
            watchLocation={watchLocation}
            watchNumberOfTeams={watchNumberOfTeams}
            prevData={prevData}
            watchCategory={watchCategory}
            totalFee={totalFee}
            laFreeCut={laFreeCut}
          />
        )}
        {page === "2" && (
          <EditTournamentContactForm
            errors={errors}
            handleSubmit={handleSubmit}
            register={register}
            tournamentId={tournamentId ?? ""}
            prevData={prevData}
            setValue={setValue}
          />
        )}
        {page === "3" && (
          <EditTournamentRewardForm
            errors={errors}
            handleSubmit={handleSubmit}
            register={register}
            tournamentId={tournamentId ?? ""}
            prevData={prevData}
          />
        )}
      </main>
    </>
  );
}

export default EditTournament;
