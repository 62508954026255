function LaCaretDownSmall({ stroke = "#1a1a1a" }: { stroke?: string }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 7" fill="none">
      <path
        d="M12.28.967L7.933 5.313a1.324 1.324 0 01-1.866 0L1.72.967"
        stroke={stroke}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaCaretDownSmall;
