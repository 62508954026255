import React from "react";
import { LaArrowLeft, LaPlusIcon } from "../../components/svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { PlayerCard } from "../../components/ui/cards";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import { useGetAuthorsTeamQuery } from "../../redux/services/teamService";
import PlayerBadgeXs from "../../components/ui/cards/PlayerBadgeXs";
import Search from "../../components/search/Search";
import { useInView } from "react-intersection-observer";
import EmptyStateAuto from "../../components/ui/EmptyStateAuto";
import { PaginatedPlayers } from "../../components/ui";
import { TList, usePaginateFetch } from "../../hooks/usePaginateFetch";
import { useGetPlayersQuery } from "../../redux/services/playerService";
import { AddPlayersToTeam, TeamCompetitionsForm } from "../../components/team";
import HelmetComponent from "../../components/HelmetComponent";

function Players() {
  const ID_LENGTH = 24;
  const navigate = useNavigate();
  const [page, setPage] = React.useState<number>(1);
  const size = React.useRef<number>(15);
  const { ref, inView } = useInView({ delay: 600, threshold: 1 });
  const [list, setList] = React.useState<TList>([]);
  const role = useAppSelector((state: RootState) => state.auth.role);
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const [activeTab, setActiveTab] = React.useState("discover");
  const [selectedPlayers, setSelectedPlayers] = React.useState<string[]>([]);
  const [competitionPlayersForTeam, setCompetitionPlayersForTeam] = React.useState<
    string[]
  >([]);

  const [selectedCompetitionForTeamId, setSelectedCompetitionForTeamId] =
    React.useState<string>("none");

  const [selectedCompetitionForTeam, setSelectedCompetitionForTeam] =
    React.useState<SelectedCompetition | null>(null);

  const {
    data: data = [],
    isLoading,
    isFetching,
  } = useGetPlayersQuery({
    page: page,
    count: size.current,
  });

  const { data: authorTeam } = useGetAuthorsTeamQuery({ id: myId });
  const isTeam = role?.toLowerCase() === "team";

  usePaginateFetch({
    inView,
    isFetching,
    isLoading,
    page,
    list,
    setPage,
    setList,
    data,
  });

  const members =
    authorTeam?.members?.map((player) => {
      return {
        ...(player as TPlayer),
        designation: "teammate",
      };
    }) ?? [];
  const invites =
    authorTeam?.invites?.map((user) => {
      return {
        ...(user as TPlayer),
        designation: "invited",
      };
    }) ?? [];

  const requests =
    authorTeam?.requests?.map((user) => {
      return {
        ...(user as TPlayer),
        designation: "requested",
      };
    }) ?? [];
  const myPlayers = [...members, ...requests, ...invites]?.sort(
    (playerA, playerB) => playerB.goals - playerA.goals
  );

  const myPlayerList = myPlayers?.map((player, index) => {
    return (
      <PlayerCard
        username={player?.name}
        avatar={player?.avatar}
        appearance={player?.apps}
        assist={player?.assists}
        goals={player?.goals}
        playerWing={player?.position}
        redCards={player?.redCards}
        yellowCards={player?.yellowCards}
        key={`${player?._id}${index}`}
        designation={player?.designation}
      />
    );
  });

  const myPlayerListDesktop = myPlayers?.map((player) => {
    return (
      <ul
        key={player._id}
        className="grid grid-cols-6 border-b border-grey-light py-3 text-center"
      >
        <li>
          <div className="flex gap-3">
            <PlayerBadgeXs
              appearance={player.apps}
              avatar={player?.avatar}
              assist={player?.assists}
              goals={player?.goals}
              playerName={player?.name}
              playerWing={player?.position}
              designation={player?.designation}
            />
            <p className="capitalize">{player?.name}</p>
          </div>
        </li>
        <li>{player.goals}</li>
        <li>{player.assists}</li>
        <li>{player.yellowCards}</li>
        <li>{player.assists}</li>
        <li className="flex flex-col items-center">
          <span
            className={`rounded  bg-gray-100 px-2 py-1 text-black ${
              player.designation === "teammate" && "bg-green-dark text-white"
            } 
                ${player.designation === "invited" && "bg-yellow"}
              `}
          >
            {player.designation}
          </span>
        </li>
      </ul>
    );
  });

  // open graph data
  const ogData = {
    title: "Leagues Arena - Players",
    description: "Discover the best players on Leagues Arena",
    url: `https://${window.location.host}/discover/players`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex flex-col items-center justify-center pb-10">
        <div className="w-full py-4 pb-24 lg:max-w-[87%] lg:pb-0">
          <div className="flex items-center gap-4 px-4 py-4 lg:px-0">
            <button
              className="text-xl lg:hidden"
              onClick={() => navigate(-1)}
              aria-label="back"
            >
              <LaArrowLeft />
            </button>
            <h3 className="text-xl font-bold">Find Players</h3>
          </div>

          {isTeam && (
            <div className="flex border-b border-grey-light text-center lg:gap-6">
              <button
                className={`w-full py-4 capitalize lg:w-auto ${
                  activeTab === "discover" &&
                  "border-b-2 border-primary-blue-darker text-primary-blue-dark"
                }`}
                type="button"
                onClick={() => setActiveTab("discover")}
              >
                discover
              </button>
              <button
                className={`w-full py-4 lg:w-auto ${
                  activeTab === "my players" &&
                  "border-b-2 border-primary-blue-darker text-primary-blue-dark"
                }`}
                type="button"
                onClick={() => setActiveTab("my players")}
              >
                My Players
              </button>
            </div>
          )}

          {activeTab === "discover" && (
            <>
              <div className="flex w-full flex-col justify-between gap-2 px-4 pb-6 pt-4 lg:flex-row lg:items-center lg:px-0">
                <Search placeholder="Search for Players " />
                {isTeam && (
                  <div className="flex items-center gap-2">
                    <p>Can’t find a player?</p>
                    <Link
                      to={"/discover/create-players"}
                      className="flex items-center gap-2 font-medium text-primary-blue-darker"
                    >
                      {" "}
                      <LaPlusIcon /> Add New Player
                    </Link>
                  </div>
                )}
              </div>
              <PaginatedPlayers
                isFetching={isFetching}
                isLoading={isLoading}
                myId={myId}
                players={list as TPlayer[]}
                refElement={ref}
                size={size}
                setSelectedPlayers={setSelectedPlayers}
                selectedPlayers={selectedPlayers}
                setSelectedCompetitionForTeam={setSelectedCompetitionForTeamId}
                selectedCompetitionForTeam={selectedCompetitionForTeamId}
                competitionPlayersForTeam={competitionPlayersForTeam}
              />
            </>
          )}
          {activeTab === "my players" && isTeam && (
            <>
              {myPlayerList.length === 0 ? (
                <div className="pt-10">
                  <EmptyStateAuto
                    title="Nothing to see here"
                    description="You do not have any players yet. When you do they'll appear here"
                  />
                </div>
              ) : (
                <div className="px-4 py-6 lg:px-0">
                  <div className="hidden lg:block">
                    <ul className="hidden grid-cols-6 items-center border-b border-grey-light py-3 text-center text-sm text-grey lg:grid ">
                      <li>Player</li>
                      <li>Goals</li>
                      <li>Assists</li>
                      <li>Red cards</li>
                      <li>Yellow cards</li>
                      <li>Tag</li>
                    </ul>
                    <div>{myPlayerListDesktop}</div>
                  </div>
                  <div className="flex flex-col rounded border border-grey-light lg:hidden lg:border-0">
                    {myPlayerList}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!selectedCompetitionForTeamId && (
        <TeamCompetitionsForm
          setSelectedCompetitionForTeamId={setSelectedCompetitionForTeamId}
          setCompetitionPlayersForTeam={setCompetitionPlayersForTeam}
          setSelectedPlayers={setSelectedPlayers}
          selectedPlayers={selectedPlayers}
          setSelectedCompetitionForTeam={setSelectedCompetitionForTeam}
        />
      )}

      {selectedPlayers.length > 0 &&
        selectedCompetitionForTeamId.length === ID_LENGTH && (
          <AddPlayersToTeam
            setCompetitionPlayersForTeam={setCompetitionPlayersForTeam}
            setSelectedPlayers={setSelectedPlayers}
            selectedPlayers={selectedPlayers}
            selectedCompetitionForTeamId={selectedCompetitionForTeamId}
            setSelectedCompetitionForTeamId={setSelectedCompetitionForTeamId}
            selectedCompetitionForTeam={selectedCompetitionForTeam}
          />
        )}
    </>
  );
}

export default Players;
