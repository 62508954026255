import React from "react";
import CareerInfo from "./CareerInfo";
import {
  useGetEditionsQuery,
  useGetUserTournamentsQuery,
} from "../../redux/services/tournamentService";
import { CompetitionCardSkeleton } from "../ui/skeletons";
import { PlayerTrainings } from ".";
import EmptyStateAuto from "../ui/EmptyStateAuto";
import { useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import Button from "../ui/buttons/Button";
import { cn } from "../../utils/cn";
import { EditionCard, TournamentCard } from "../ui";

type Props = {
  userId?: string;
};

function TournamentsInfoTab({ userId }: Props) {
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [activeTab, setActiveTab] = React.useState("tournament");
  const { data: tournaments = [], isLoading } = useGetUserTournamentsQuery({
    userId,
  });
  const { data: editions = [], isLoading: isEditionsLoading } =
    useGetEditionsQuery();
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const isPrivate = userId === myId;

  const editionTournaments = editions?.map((edition) => (
    <EditionCard
      key={edition._id}
      name={edition.name}
      noOfEditions={edition.tournaments?.length}
      id={edition._id}
    />
  ));

  const tournamentCards = tournaments?.map((tournament) => (
    <TournamentCard
      key={tournament?._id}
      avatar={tournament?.avatar}
      name={tournament?.name}
      location={tournament?.location}
      teams={tournament?.teams.length}
      id={tournament?._id}
      banner={tournament?.banner}
    />
  ));

  return (
    <div className="mt-4 pb-20">
      <div className="grid grid-cols-3 border-b border-grey-light text-center lg:flex lg:gap-4">
        <Button
          intent="secondary"
          role={role}
          className={cn(
            "rounded-none border-0 px-2  text-sm hover:bg-white lg:p-4 lg:text-base",
            {
              "border-b-2": activeTab === "tournament",
              "text-primary-black-darker": activeTab !== "tournament",
            }
          )}
          type="button"
          onClick={() => setActiveTab("tournament")}
        >
          {isPrivate ? "My " : ""}
          Competitions
        </Button>
        <Button
          intent="secondary"
          role={role}
          className={cn(
            "border-0 p-2 text-sm hover:bg-white lg:hidden lg:p-4 lg:text-base",
            {
              "text-primary-black-darker": activeTab !== "info",
              "border-b-2": activeTab === "info",
            }
          )}
          type="button"
          onClick={() => setActiveTab("info")}
        >
          {isPrivate ? "My " : ""}
          Info
        </Button>
        <Button
          intent="secondary"
          role={role}
          className={cn(
            "rounded-none border-0  p-2 text-sm hover:bg-white lg:p-4 lg:text-base",
            {
              "text-primary-black-darker": activeTab !== "trainings",
              "border-b-2": activeTab === "trainings",
            }
          )}
          type="button"
          onClick={() => setActiveTab("trainings")}
        >
          {isPrivate ? "My " : ""}
          Trainings
        </Button>
      </div>
      {activeTab === "tournament" && (
        <>
          {!!editionTournaments.length && (
            <div className="flex flex-col gap-4 px-4 pt-4 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-10 lg:px-0">
              {editionTournaments}
            </div>
          )}

          <div className="flex flex-col gap-4 p-4 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-10 lg:px-0">
            {isLoading || isEditionsLoading ? (
              <>
                <CompetitionCardSkeleton />
                <CompetitionCardSkeleton />
                <CompetitionCardSkeleton />
              </>
            ) : (
              <>{tournamentCards}</>
            )}
          </div>
          {tournamentCards?.length === 0 &&
            editionTournaments?.length === 0 && (
              <EmptyStateAuto
                title="Nothing to see here"
                description={`${
                  isPrivate ? "You have" : "This organizer has"
                } not created a tournament yet. When ${
                  isPrivate ? "you" : "they"
                } do it will appear here.`}
              />
            )}
        </>
      )}
      {activeTab === "info" && <CareerInfo />}
      {activeTab === "trainings" && (
        <div className="px-4 lg:px-0">
          <PlayerTrainings isPrivate={isPrivate} />
        </div>
      )}
    </div>
  );
}

export default TournamentsInfoTab;
