import { Link } from "react-router-dom";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { setToastCopied } from "../../redux/features/modal/displayModalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { Button } from "../ui";
import { RootState } from "../../redux/app/store";

type Props = {
  tournamentId: string;
  tournamentCompleted?: boolean;
};

export default function TournamentAuthorOptions({
  tournamentId,
  tournamentCompleted,
}: Props) {
  const dispatch = useAppDispatch();
  const [_, copyToClipboard] = useCopyToClipboard();
  const role = useAppSelector(
    (state: RootState) => state.auth.role
  )?.toLowerCase() as TRole;

  function shareLink(link: string) {
    copyToClipboard(link).then((hasCopied) => {
      dispatch(setToastCopied(hasCopied));
    });
  }
  return (
    <>
      <Button
        intent="secondary"
        role={role}
        onClick={() =>
          shareLink(`${window.location.host}${window.location.pathname}`)
        }
        className="hidden cursor-pointer rounded border border-primary-black-darker p-3 text-sm font-medium text-primary-black-darker lg:block"
      >
        Share
      </Button>
      <Link
        to={
          tournamentCompleted
            ? `/competitions/${tournamentId}/restart`
            : `/competitions/${tournamentId}/edit`
        }
        className="cursor-pointer rounded bg-primary-black-darker p-3 text-sm font-medium text-white"
      >
        {tournamentCompleted ? "Create a new edition" : "Edit Competition"}
      </Link>
    </>
  );
}
