import React from "react";

function LaStarWhite(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 2.91602L17.4247 9.85418L25.0833 10.9736L19.5416 16.3712L20.8495 23.9965L14 20.3944L7.15046 23.9965L8.45829 16.3712L2.91663 10.9736L10.5752 9.85418L14 2.91602Z"
        fill="white"
        stroke="white"
        strokeWidth="2.11111"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default LaStarWhite;
