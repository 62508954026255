import React from "react";
import { Link } from "react-router-dom";
import { useForgotPasswordMutation } from "../../redux/services/authService";
import { useAppDispatch } from "../../redux/app/hooks";
import {
  setToastErrorText,
  setToastSuccess,
} from "../../redux/features/modal/displayModalSlice";
import LaSpinner from "../../components/svg-icons/LaSpinner";
import { EmailSent } from "../../components/modals";
import HelmetComponent from "../../components/HelmetComponent";

interface FormElements extends HTMLFormControlsCollection {
  emailOrPhone: HTMLInputElement;
}

function ForgotPassword() {
  const dispatch = useAppDispatch();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [displayModal, setDisplayModal] = React.useState(false);

  // open graph data
  const ogData = {
    title: "Leagues Arena - Forgot Password",
    description: "Reset your password on Leagues Arena",
    url: `https://${window.location.host}/account/forgot-password`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  const [inputError, setInputError] = React.useState({
    error: "",
    clearError: function (value: string) {
      setInputError((prev) => ({ ...prev, [value]: "" }));
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const elements = (e.target as HTMLFormElement).elements as FormElements;
    forgotPassword({ emailOrPhone: elements?.emailOrPhone?.value })
      .unwrap()
      .then(() => {
        dispatch(setToastSuccess(true));
        setDisplayModal(true);
      })
      .catch((err: any) => {
        console.log(err);

        dispatch(setToastErrorText(err.data.message));

        if (err.status === 401 || err.status === 400) {
          return setInputError((prev) => ({
            ...prev,
            emailOrPhone: "User not found",
          }));
        }
      });
  }

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <div className="flex min-h-screen items-center justify-center bg-gradient-to-b from-[#00000033] to-white px-4">
        <div className="relative flex flex-col items-center rounded-xl bg-white p-4 shadow lg:w-[50%] lg:p-6 lg:px-20 lg:py-10">
          <h3 className="mt-3 text-xl font-bold lg:text-4xl">Forgot password?</h3>
          <p className="mt-2 text-grey">
            Enter the email address associated with your account
          </p>

          <form action="" className="mt-14 w-full" onSubmit={handleSubmit}>
            <fieldset>
              <label htmlFor="emailOrPhone">
                Email or phone<span className="text-red">*</span>
              </label>
              <input
                type="text"
                name="emailOrPhone"
                id="emailOrPhone"
                placeholder="Enter your email address or phone number"
                required
                className={`mt-2 w-full rounded border border-grey-light px-4 py-3 text-base lg:text-xl ${
                  inputError?.error
                    ? "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
                    : "focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30"
                }`}
                onInput={() => inputError.clearError("error")}
              />
            </fieldset>
            <fieldset className="mt-12 flex flex-col items-center">
              <button
                className="flex w-full items-center justify-center gap-2 rounded bg-primary-blue-dark py-4 text-center font-medium text-white hover:bg-primary-blue-lighter hover:text-black lg:text-2xl"
                type="submit"
              >
                Submit
                {isLoading && (
                  <span className="ml-4 animate-spin">
                    <LaSpinner />{" "}
                  </span>
                )}
              </button>
              <p className="mt-4 text-sm">
                Remember password?{" "}
                <Link to="/account/login" className="text-primary-blue-dark">
                  Login
                </Link>
              </p>
            </fieldset>
          </form>
        </div>
      </div>
      {displayModal && (
        <EmailSent medium="email" closeModal={() => setDisplayModal(false)} />
      )}
    </>
  );
}

export default ForgotPassword;
