import { LaLocation, LaPlayers } from "../../svg-icons";
import { Link } from "react-router-dom";

type Props = {
  location: string;
  teamCount: number;
  avatar: string;
  bgAvatar: string;
  competitionName: string;
  status?: "joined" | "attention" | "requested";
  id: string;
};

export default function CompetitionCard(props: Props) {
  return (
    <Link
      to={`/competitions/${props.id}`}
      className="relative h-[250px] overflow-hidden rounded-lg border border-gray-300 lg:max-w-[358px]"
    >
      {props.status && (
        <>
          <p
            className={`absolute -left-0 top-[25%] z-30 w-[136px] -translate-x-9 -translate-y-9 -rotate-45 ${
              props.status === "joined"
                ? "bg-green text-white"
                : props.status === "requested"
                ? "bg-yellow"
                : "bg-dark-orange"
            } py-1 text-center text-xs font-medium capitalize`}
          >
            {props.status}
          </p>
        </>
      )}
      <section
        className="relative h-[60%] bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${props.bgAvatar})`,
          backgroundSize: "cover",
        }}
      >
        <img
          src={props.avatar}
          alt={props.competitionName}
          className="absolute -bottom-4 left-6 h-8 w-8 overflow-hidden rounded-full border border-white bg-blue-200"
        />
      </section>
      <section className="flex h-[40%] flex-col justify-end gap-1 px-6 py-3">
        <h3 className="capitalize">{props.competitionName}</h3>
        <div className="flex items-center gap-2 text-sm capitalize">
          <LaLocation />
          {props.location}
        </div>
        <div className="flex items-center gap-2">
          <LaPlayers />{" "}
          <p className="text-sm font-semibold">{props.teamCount} Team(s)</p>
        </div>
      </section>
    </Link>
  );
}
