import type { psConfig } from "../@types/payment";

export function genConfig({
  email,
  amount,
}: Pick<psConfig, "email" | "amount">): psConfig {
  const config: psConfig = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: amount * 100,
    publicKey: import.meta.env.VITE_PS_PUBLIC,
  };
  return config;
}
