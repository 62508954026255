import { IoSend, IoReload } from "react-icons/io5";
import { useCreateSpotlightCommentMutation } from "../../../redux/services/spotLightService";
import React from "react";

type Props = {
  placeHolder?: string;
  changeFace?: boolean;
  spotlightId?: string;
};
export default function CommentInput({
  placeHolder = "Write a comment",
  changeFace = false,
  ...props
}: Props) {
  const [createComment, { isLoading: isCommenting }] =
    useCreateSpotlightCommentMutation();
  const [comment, setComment] = React.useState("");

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    const form = new FormData(ev.currentTarget);
    createComment({
      data: { comment: form.get("comment") as string },
      id: props.spotlightId!,
    })
      .unwrap()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.debug(err);
      });
    ev.currentTarget.reset();
    setComment("");
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="mt-[10px] flex items-center justify-between rounded-xl border border-grey bg-white px-[12px] py-[10px]"
    >
      <input
        type="text"
        name="comment"
        id="comment"
        value={comment}
        onChange={(ev) => setComment(ev?.target?.value)}
        placeholder={placeHolder}
        className="flex-1"
      />
      <button
        disabled={isCommenting}
        type="submit"
        className="grid h-[37.86px] w-[47.6px] place-items-center rounded-lg bg-primary-blue-dark text-white disabled:bg-slate-700"
      >
        {changeFace ? (
          isCommenting ? (
            <p className="text-white">Posting...</p>
          ) : (
            <p className="text-white">Post</p>
          )
        ) : isCommenting ? (
          <IoReload size={24} className="animate-spin" />
        ) : (
          <IoSend size={24} />
        )}
      </button>
    </form>
  );
}
