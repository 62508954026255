import React from "react";

export default function useCopyToClipboard(): [
  copiedText: string | null,
  copyToClipboard: (text: string) => Promise<boolean>
] {
  const [copiedText, setCopiedText] = React.useState<string | null>(null);

  const copyToClipboard = async (text: string) => {
    // Check DOM navigator's clipboard
    if (!navigator.clipboard) {
      console.warn("Clipboard not supported");
    }

    // Save to clipboard
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error: any) {
      console.warn("Unable to copy", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copyToClipboard];
}
