import { Helmet } from "react-helmet";

interface IOgData {
  title: string;
  description: string;
  url: string;
  image: string;
  siteName: string;
}

interface HelmetComponentProps {
  ogData: IOgData;
}

export default function HelmetComponent({ ogData }: HelmetComponentProps) {
  return (
    <Helmet>
      <title>{ogData.title}</title>
      <meta name="description" content={ogData.description} />
      <meta property="og:title" content={ogData.title} />
      <meta property="og:description" content={ogData.description} />
      <meta property="og:url" content={ogData.url} />
      <meta property="og:image" content={ogData.image} />
      <meta property="og:site_name" content={ogData.siteName} />
      <meta name="twitter:title" content={ogData.title} />
      <meta name="twitter:description" content={ogData.description} />
      <meta name="twitter:image" content={ogData.image} />
      <meta name="twitter:site" content="@leaguesarena" />
      <meta name="twitter:creator" content="@leaguesarena" />
    </Helmet>
  );
}
