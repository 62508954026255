import React from "react";

/**
 * Sets a delay timer for value/term before returning updated value
 * @param {*} value value/term for debounce lookup
 * @param {*} delay delay timer in milliseconds before updating value/term
 * @returns Recently updated value/search term after delay.
 */
function useDebounce(value: string, delay = 1000) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(
    // Updated debounce value after delay. Default of 1s
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler); // Clear timeout and restart to prevent debounced updates
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export default useDebounce;
