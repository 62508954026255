type Props = {};

function CareerInfo({}: Props) {
  return (
    <div className="flex flex-col gap-2 p-4">
      <h3 className="text-base font-medium">Career Summary</h3>
      <div className="mt-2 flex w-[70%] flex-col gap-2 text-sm font-normal">
        <div className="flex items-center justify-between">
          <p className="text-sm font-normal">Competitions hosted</p>
          <p>0</p>
        </div>
        <div className="flex items-center justify-between">
          <p>Teams</p>
          <p>0</p>
        </div>
        <div className="flex items-center justify-between">
          <p>Players</p>
          <p>0</p>
        </div>
      </div>
    </div>
  );
}

export default CareerInfo;
