import * as tournament_RTK from "../../redux/services/tournamentService";
import { useAppDispatch, useAppSelector } from "../../redux/app/hooks";
import { RootState } from "../../redux/app/store";
import {
  setToastCopied,
  setToastErrorText,
} from "../../redux/features/modal/displayModalSlice";
import Button from "../ui/buttons/Button";
import LaSpinner from "../svg-icons/LaSpinner";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";

interface Props {
  team?: Team;
  selectedTeams: string[];
  setSelectedTeams: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedCompetition: React.Dispatch<React.SetStateAction<string>>;
}

export default function PublicOrganizerBtnGroup({
  team,
  setSelectedTeams,
  selectedTeams,
  setSelectedCompetition,
}: Props) {
  const dispatch = useAppDispatch();
  const visitorId = useAppSelector((state: RootState) => state.auth?.myId);
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [
    acceptTournamentRequestFn,
    { isLoading: busyAcceptingTournamentRequest },
  ] = tournament_RTK.useAcceptTournamentRequestMutation();
  const [
    declineTournamentRequestFn,
    { isLoading: busyRejectingTournamentRequest },
  ] = tournament_RTK.useDeclineTournamentRequestMutation();

  const requestedTournament = team?.tournamentRequests?.find(
    (tournamentRequest) => tournamentRequest.author === visitorId
  );
  const [_, copyToClipboard] = useCopyToClipboard();

  function shareLink(link: string) {
    copyToClipboard(link).then((hasCopied) => {
      dispatch(setToastCopied(hasCopied));
    });
  }

  function acceptTournamentRequest() {
    const tournament = team?.tournamentRequests?.find(
      (tournamentRequest) => tournamentRequest.author === visitorId
    );

    if (team && tournament)
      acceptTournamentRequestFn({
        tournamentId: tournament._id,
        teamId: team?._id,
      })
        .unwrap()
        .then((resp) => resp)
        .catch((err) => {
          console.log("error accepting tournament request:", err);
          dispatch(setToastErrorText(err.data.message));
        });
  }

  function declineTournamentRequest() {
    const tournament = team?.tournamentRequests?.find(
      (tournamentRequest) => tournamentRequest.author === visitorId
    );

    if (team && tournament)
      declineTournamentRequestFn({
        tournamentId: tournament._id,
        teamId: team?._id,
      })
        .unwrap()
        .then((resp) => resp)
        .catch((err) => {
          console.log(err);
        });
  }

  if (requestedTournament) {
    return (
      <div className="rounded-xl bg-primary-black-darker px-4 py-3 lg:py-4">
        <h3 className="text-left font-bold text-white lg:max-w-[250px] lg:text-2xl">
          Requested to join {requestedTournament.name} tournament
        </h3>
        <div className="mt-4 flex gap-3 lg:mt-8 lg:gap-4">
          <Button
            role={role}
            className="bg-white py-2 text-xs lg:w-full lg:py-3 lg:text-base"
            intent={"secondary"}
            disabled={busyAcceptingTournamentRequest}
            onClick={() => acceptTournamentRequest()}
          >
            Accept
            {busyAcceptingTournamentRequest && (
              <span className="animate-spin">
                <LaSpinner stroke="#000" />
              </span>
            )}
          </Button>
          <Button
            role={role}
            intent={"secondary"}
            className="border-red py-2 text-xs text-red hover:bg-red-lighter lg:w-full lg:py-3 lg:text-base"
            disabled={busyRejectingTournamentRequest}
            onClick={() => declineTournamentRequest()}
          >
            Decline
            {busyRejectingTournamentRequest && (
              <span className="animate-spin">
                <LaSpinner stroke="#f00" />
              </span>
            )}
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Button
          onClick={() => {
            setSelectedCompetition("");
            setSelectedTeams((prev) => [...prev, team?._id as string]);
          }}
          role={role}
          intent={"primary"}
          disabled={
            busyAcceptingTournamentRequest ||
            busyRejectingTournamentRequest ||
            selectedTeams.includes(team?._id as string)
          }
        >
          Add Team
        </Button>
        <Button
          intent={"secondary"}
          role={role}
          onClick={() =>
            shareLink(`${window.location.host}${window.location.pathname}`)
          }
          type="button"
        >
          Share
        </Button>
      </>
    );
  }
}
