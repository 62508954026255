export default function LaEnvelope(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.9"
        d="M15.3493 8.5166L21.7301 14.8974L20.8245 15.8024L14.4437 9.42156L15.3493 8.5166ZM6.69782 8.54412L7.60278 9.44908L1.26678 15.7851L0.361816 14.8827L6.69782 8.54412Z"
        fill="currentColor"
      />
      <path
        d="M11 12.1363C10.04 12.1363 9.01601 11.7523 8.31201 10.9843L0.312012 3.04834L1.20801 2.15234L9.20801 10.1523C10.232 11.1763 11.832 11.1763 12.856 10.1523L20.856 2.15234L21.752 3.04834L13.688 11.0483C12.984 11.7523 11.96 12.1363 11 12.1363Z"
        fill="currentColor"
      />
      <path
        d="M19.9601 17.3197H2.04012C0.952117 17.3197 0.120117 16.4877 0.120117 15.3997V2.59969C0.120117 1.51169 0.952117 0.679688 2.04012 0.679688H19.9601C21.0481 0.679688 21.8801 1.51169 21.8801 2.59969V15.3997C21.8801 16.4877 21.0481 17.3197 19.9601 17.3197ZM2.04012 1.95969C1.65612 1.95969 1.40012 2.21569 1.40012 2.59969V15.3997C1.40012 15.7837 1.65612 16.0397 2.04012 16.0397H19.9601C20.3441 16.0397 20.6001 15.7837 20.6001 15.3997V2.59969C20.6001 2.21569 20.3441 1.95969 19.9601 1.95969H2.04012Z"
        fill="currentColor"
      />
    </svg>
  );
}
