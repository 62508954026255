import React from "react";
import { LaCaretDown, LaCaretUp } from "../../svg-icons";
import locationData from "../../../assets/data/locationData";
import useOnClickOutside from "../../../hooks/useClickedOutside";
import { useAppSelector } from "../../../redux/app/hooks";
import Input from "../input/Input";
import SelectDropdown from "../select-dropdown/SelectDropdown";
import { RootState } from "../../../redux/app/store";

interface ControlledSelectProps {
  name: string;
  id: string;
  placeholder: string;
  value?: string;
  error?: string;
  setSelectedId?: (id: string) => void;
  clearError?: (key: string) => void;
  handleFormChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (e: { target: { name: string; value: string } }) => void;
  fit?: boolean;
  options?: any[];
}

function ControlledSelect(props: ControlledSelectProps) {
  const inputEl: React.RefObject<HTMLInputElement> = React.useRef(null);
  const [flip, setFlip] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(props.value);

  const clickedEl = React.useRef(null);

  useOnClickOutside(clickedEl, () => setFlip(false));
  function handleClick() {
    setFlip(!flip);
  }

  const role = useAppSelector((state: RootState) => state.auth.role);

  function handleSelect(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const target = e.target as HTMLElement;
    props.setSelectedId &&
      props?.setSelectedId(e.currentTarget.dataset.id || "");
    if (props.clearError) {
      props?.clearError(props.name);
    }
    setSelectedOption(target?.textContent || "");
    props.onChange({
      target: { name: props.name, value: e.currentTarget.textContent || "" },
    });
  }

  React.useEffect(() => {
    if (inputEl.current) props.error && inputEl.current.focus();
  });

  return (
    <fieldset
      className="relative cursor-pointer"
      onClick={handleClick}
      ref={clickedEl}
    >
      <Input
        role={role}
        type="text"
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        readOnly
        required
        aria-label="select"
        onChange={props.handleFormChange}
        className={`cursor-pointer border-opacity-30 px-4 py-3 capitalize  ${
          props.error &&
          "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
        }`}
        ref={inputEl}
        value={selectedOption}
      />

      {flip ? (
        <span className="absolute right-3 top-[35%]">
          <LaCaretUp stroke="#1A1A1A" />
        </span>
      ) : (
        <span className="absolute right-3 top-[35%]">
          <LaCaretDown stroke="#1A1A1A" />
        </span>
      )}
      <div
        className={`${
          !flip && "hidden"
        } modern__scroll-bar absolute z-30 mt-2 ${
          props.fit === false && "max-h-[200px]"
        } w-full overflow-y-scroll rounded border border-grey-light bg-white`}
      >
        {(props?.options ?? locationData).map((option: any, i: number) => {
          return (
            <SelectDropdown
              role={role}
              isActive={selectedOption === option}
              key={`${i}` + option?.toString()}
              onClick={handleSelect}
              data-id={option?._id}
              type="button"
              className="w-full text-left"
            >
              {option.name ? option.name : option}
            </SelectDropdown>
          );
        })}
      </div>
    </fieldset>
  );
}

export default ControlledSelect;
