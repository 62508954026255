function LaCaretUp({ stroke = "#1A1A1A" }: { stroke?: string }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none">
      <path
        d="M4.08 15.301l6.52-6.52c.77-.77 2.03-.77 2.8 0l6.52 6.52"
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LaCaretUp;
