import React from "react";
import {
  LaDelete,
  LaDocumentDownload,
  LaLike,
  LaMoreHorizontal,
  LaShare,
} from "../../svg-icons";
import {
  useDeletePostMutation,
  useUpdatePostMutation,
} from "../../../redux/services/postService";
import moment, { MomentInput } from "moment";
import { Link } from "react-router-dom";
import { capitalize } from "../../../utils/capitalize";
import { copyToClipboard } from "../../../utils/copyToClipboard";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { RootState } from "../../../redux/app/store";
import {
  setToastError,
  setToastSuccess,
} from "../../../redux/features/modal/displayModalSlice";
import { setSentPost } from "../../../redux/features/post/postSlice";

interface FeedCardProps {
  avatar?: string;
  username?: string;
  note?: string;
  timeStamp?: string;
  likeCount?: number;
  liked?: boolean;
  id?: string;
  image?: string;
  video?: string;
  authorId?: string;
  role: string;
}

// Todo: accept avatar and username if conflict,
function FeedCard({
  timeStamp,
  avatar,
  image,
  username,
  liked,
  likeCount,
  note,
  id,
  authorId,
  role,
}: FeedCardProps) {
  const dispatch = useAppDispatch();
  const [isLiked, setIsLiked]: any = React.useState(liked); // Pass as prop
  const [likesCount, setLikesCount] = React.useState<number>(
    likeCount as number
  );
  const myId = useAppSelector((state: RootState) => state.auth.myId);
  const [_, handleCopy] = useCopyToClipboard();
  const [updatePost] = useUpdatePostMutation();
  const [deletePost] = useDeletePostMutation();
  const isAuthorsPost = authorId === myId;
  const [dropDown, setDropDown] = React.useState(false);

  const age = moment(timeStamp as MomentInput).fromNow();

  const handleLikeClick = () => {
    // Thread with caution.
    setIsLiked((prev: boolean) => !prev);
    !isLiked
      ? setLikesCount((prv: number) => prv + 1)
      : setLikesCount((prv: number) => prv - 1);

    updatePost({ id })
      .unwrap()
      .then((res) => res)
      .catch(() => {
        setIsLiked((prev: boolean) => !prev);
        isLiked
          ? setLikesCount((prv: number) => prv + 1)
          : setLikesCount((prv: number) => prv - 1);
      });
  };

  function downloadImage() {
    const link = document.createElement("a");
    link.href = image || "";
    link.download = "image";
    link.click();
  }

  function handleDeletePost() {
    deletePost({ id })
      .unwrap()
      .then((res) => {
        console.log(res);
        dispatch(setToastSuccess(true));
        dispatch(setSentPost(true));
      })
      .catch(() => {
        dispatch(setToastError(true));
      });
  }

  return (
    <div className="flex max-w-lg flex-col gap-3 rounded border border-grey-light px-2 py-4 shadow lg:rounded-2xl">
      <div className="relative flex items-center justify-between">
        <Link
          to={`/profiles/${role}/${username}`}
          className="flex items-center gap-3"
        >
          <img src={avatar} alt="" className="h-[40px] w-[40px] rounded-full" />
          <div>
            <p>{capitalize(username)}</p>
            <p className="text-sm font-medium">{age}</p>
          </div>
        </Link>

        {(image || isAuthorsPost) && (
          <button onClick={() => setDropDown((prev) => !prev)}>
            <LaMoreHorizontal />
          </button>
        )}

        {dropDown && (
          <div className="absolute right-0 top-[40px] flex w-[152px] flex-col rounded border border-grey-light bg-white shadow">
            <button
              className="flex items-center gap-2 p-2"
              onClick={downloadImage}
            >
              <LaDocumentDownload />
              <span>Save</span>
            </button>
            {isAuthorsPost && (
              <button
                className="flex items-center gap-2 p-2"
                onClick={handleDeletePost}
              >
                <LaDelete />
                <span>Delete</span>
              </button>
            )}
          </div>
        )}
      </div>

      <Link to={`/post/${id}`} className="flex flex-col gap-3">
        {note && (
          <pre
            className="whitespace-pre-wrap break-words text-left font-[inherit] md:text-base lg:text-base"
            aria-label="note"
          >
            {note}
          </pre>
        )}

        {image && (
          <img
            src={image}
            className="h-[280px] w-full rounded object-contain object-center lg:h-[380px]"
            alt="An image describing the users post"
          />
        )}
      </Link>
      <div className="flex items-center justify-start gap-3">
        <button
          className="flex items-center justify-center gap-2 text-lg"
          onClick={handleLikeClick}
        >
          <LaLike
            fill={isLiked ? "#ff0000" : "none"}
            stroke={isLiked ? "#ff0000" : "#1a1a1a"}
          />
          <span>{likesCount}</span>
        </button>
        <button
          className="text-lg"
          onClick={() =>
            copyToClipboard(
              `${window.location.host}${window.location.pathname}`,
              handleCopy,
              dispatch
            )
          }
        >
          <LaShare />
        </button>
      </div>
    </div>
  );
}

export default FeedCard;
