import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  tournamentInvitation: {
    tournamentId: "",
    notificationId: "",
  },
};

const tournamentSlice = createSlice({
  name: "tournament",
  initialState,
  reducers: {
    setTournament: (state, action) => {
      state.id = action.payload;
    },
    setTournamentInvitation: (state, action) => {
      state.tournamentInvitation = action.payload;
    },
  },
});

export const { setTournamentInvitation } = tournamentSlice.actions;
export default tournamentSlice.reducer;
