import React from "react";
import { Password, Spinner } from "../ui";
import socket from "../../socket";
import { useAppDispatch } from "../../redux/app/hooks";
import { setIsLoggedin, setMyId } from "../../redux/features/auth/authSlice";
import { useLoginMutation } from "../../redux/services/authService";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";

interface LoginFormProps {
  loginOption: "email address" | "phone number";
}

function LoginForm({ loginOption }: LoginFormProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loginUser, { isLoading }] = useLoginMutation();
  const [inputError, setInputError] = React.useState({
    error: "",
    clearError: function (value: string) {
      setInputError((prev) => ({ ...prev, [value]: "" }));
    },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    const target = e.target as HTMLFormElement;
    e.preventDefault();
    loginUser({
      emailOrPhone: target.emailOrPhone?.value,
      password: target.password?.value,
    })
      .unwrap()
      .then((res) => {
        const userInfo: Token = jwtDecode(res.token);
        localStorage.setItem("token", res.token);
        dispatch(setIsLoggedin(true));
        dispatch(setMyId(userInfo._id));
        // dispatch(setMyUsername(userInfo.username));
        // dispatch(setIsBoarded(userInfo.onBoarded));
        const claimedId = userInfo._id;
        if (!socket.connected) {
          socket.disconnect();
          socket.auth = { claimedId };
          socket.connect();
        }

        navigate("/account/select-profile", { replace: true });
      })
      .catch((err) => {
        if (err.status === 400 || err.status === 401) {
          setInputError((prev) => ({
            ...prev,
            error: "Invalid login credentials",
          }));
        }
      });
  }

  return (
    <div className="mt-7 w-full lg:mt-16">
      <form
        action=""
        className="flex flex-col gap-3 md:gap-6"
        onSubmit={handleSubmit}
      >
        <fieldset>
          <label htmlFor="emailOrPhone" className="text-sm md:text-base">
            {loginOption === "email address" ? "Email" : "Phone number"}
            <span className="text-red">*</span>
          </label>
          <input
            type="text"
            name="emailOrPhone"
            id="emailOrPhone"
            placeholder="Enter phone or email"
            required
            className={`mt-2 w-full rounded border border-grey-light px-4 py-3 text-base lg:text-xl ${
              inputError?.error
                ? "error border-red focus:border-4 focus:border-red focus:border-opacity-30"
                : "focus:border-4 focus:border-primary-blue-dark focus:border-opacity-30"
            }`}
            onInput={() => inputError.clearError("error")}
          />
        </fieldset>
        <fieldset>
          <Password label="Password" error={inputError} name="password" />
          {inputError?.error && (
            <p className="mt-2 text-red">{inputError?.error}</p>
          )}
          <fieldset className="mt-3 flex justify-between">
            <input
              type="checkbox"
              name="remember"
              id="remember"
              className="checkbox__input"
            />
            <label
              htmlFor="remember"
              className="checkbox__label cursor-pointer text-sm md:text-base"
            >
              Remember me
            </label>
            <Link
              to="/account/forgot-password"
              className="cursor-pointer text-sm text-primary-blue-dark md:text-base"
            >
              Forgot password?
            </Link>
          </fieldset>
        </fieldset>
        <fieldset className="mt-8">
          <button
            className={`flex w-full  items-center justify-center rounded  py-4 text-base font-medium hover:bg-primary-blue-light hover:text-black lg:text-2xl ${
              isLoading
                ? "bg-primary-blue-light text-black"
                : "bg-primary-blue-dark text-white"
            }`}
          >
            {isLoading ? <Spinner /> : "Login"}
          </button>
        </fieldset>
        <fieldset>
          <p className="text-center text-sm font-medium">
            Don’t have an account?{" "}
            <Link to="/account/signup" className="text-primary-blue-dark">
              Create an account
            </Link>
          </p>
        </fieldset>
      </form>
    </div>
  );
}

export default LoginForm;
