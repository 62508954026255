import { leaguesArenaApi } from "./leaguesArenaApiSlice";

export const notificationsApi = leaguesArenaApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchNotifications: builder.query<
      LANotification[] | undefined,
      NonNullable<unknown>
    >({
      query: () => ({
        url: "/notifications/all",
        method: "GET",
      }),
      transformResponse: (response: { data: LANotification[] }) =>
        response.data,
      providesTags: ["Notification"],
    }),
    deleteNotification: builder.mutation({
      query: ({ id }: { id: string }) => ({
        method: "DELETE",
        url: `/notifications/${id}`,
      }),
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const { useFetchNotificationsQuery, useDeleteNotificationMutation } =
  notificationsApi;
