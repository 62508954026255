export default function countReducer(array: TPlayer[]) {
  return array?.reduce(
    (acc: { player: TPlayer; cards: number }[], curr: TPlayer) => {
      const existingUser = acc.find(
        (item: { player: TPlayer; cards: number }) =>
          item.player._id === curr._id
      );

      if (existingUser) {
        existingUser.cards++;
      } else {
        acc.push({ player: curr, cards: 1 });
      }
      return acc;
    },
    []
  );
}
