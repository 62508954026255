export function calculateAgeFromDob(dob?: string) {
  if (!dob) {
    return 0;
  }

  const today = new Date();

  const birthDate = new Date(dob);

  let age = today.getFullYear() - birthDate.getFullYear();

  const month = today.getMonth();
  const day = today.getDate();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();

  if (month < birthMonth || (month === birthMonth && day < birthDay)) {
    age--;
  }

  return age < 0 ? 0 : age;
}
