export function groupByGroupIndex(data: GroupedData[]): {
  [groupIndex: number]: GroupedData[];
} {
  const groupedData: { [groupIndex: number]: GroupedData[] } = {};

  for (const item of data) {
    const { groupIndex } = item;

    if (!groupedData[groupIndex]) {
      groupedData[groupIndex] = [];
    }

    groupedData[groupIndex].push(item);
  }
  return groupedData;
}

export const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((char) => char);
