import { useState } from "react";
import PhoneCodeDropdown from "../phone-code-dropdown/PhoneCodeDropdown";
import { Country } from "country-state-city";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { cn } from "../../../utils/cn";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { Input } from "..";

interface PhoneNumberInputProps {
  register: UseFormRegister<any>;
  name: string;
  id: string;
  error?: string;
  required?: string;
  value?: string;
  phonecodeName: string;
  setValue: UseFormSetValue<any>;
  divClass?: string;
  inputClass?: string;
}

function RegisteredPhoneInput({
  name,
  id,
  register,
  error,
  value,
  phonecodeName,
  required,
  setValue,
  divClass = " ",
  inputClass = " ",
}: PhoneNumberInputProps) {
  const role = useAppSelector((state: RootState) => state.auth.role) as TRole;
  const countryNamesAndFlags = Country.getAllCountries().map((country) => {
    let code = country.phonecode.includes("-")
      ? `+${country.phonecode.split("-")[1]}`
      : `+${country.phonecode}`;

    code = code.includes("and") ? code.split("and")[0] : code;

    return {
      name: country.name,
      flag: country.flag,
      code,
    };
  });

  const nigeriaCode = countryNamesAndFlags[160].code;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  function handleIsDrop() {
    setIsDropdownOpen((prev) => !prev);
  }

  return (
    <div
      className={cn("relative mt-2 flex", {
        [divClass]: divClass,
      })}
    >
      <button type="button" onClick={handleIsDrop}>
        <Input
          role={role}
          className={cn(
            "mt-0 max-w-[100px] cursor-pointer rounded-[4px_0_0_4px] px-4 py-3 focus:border focus:border-opacity-30",
            {
              "border-red focus:border-red": error,
              [inputClass]: inputClass,
            }
          )}
          type="text"
          defaultValue={nigeriaCode}
          readOnly
          {...register(phonecodeName, {
            required,
          })}
        />
      </button>

      <PhoneCodeDropdown
        countryNamesAndFlags={countryNamesAndFlags}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        setValue={setValue}
        darkMode={role === "organizer"}
        phonecodeName={phonecodeName}
      />
      <Input
        role={role}
        type="tel"
        id={id}
        aria-label="phone-number"
        register={register}
        {...register(name, {
          required,
          minLength: {
            value: 3,
            message: "Phone number must be at least 7 digits",
          },
          maxLength: {
            value: 15,
            message: "Phone number must be at most 15 digits",
          },
          pattern: {
            value: /^\d/,
            message: "Please enter a valid phone number",
          },
        })}
        defaultValue={value}
        placeholder="Enter your phone number"
        className={cn(
          "mt-0 w-full rounded-[0_4px_4px_0] border border-l-0 px-4 py-3 text-xl focus:border focus:border-opacity-30",

          {
            "border-red focus:border-red": error,
            [inputClass]: inputClass,
          }
        )}
      />
    </div>
  );
}

export default RegisteredPhoneInput;
