import { TeamCard } from ".";

type Props = {
  myTeams?: Team[];
  myId: string;
};

export default function MyTeamCardDeck({ myTeams }: Props) {
  return (
    <>
      {myTeams?.map((team, index) => {
        const members = team.members as string[];

        return (
          <TeamCard
            key={`${team._id}-${index}`}
            color={team?.color}
            avatar={team.avatar}
            draws={team.draws}
            name={team.name}
            wins={team.wins}
            losses={team.losses}
            played={team.played}
            players={team.members.length}
            id={team._id}
            members={members}
          />
        );
      })}
    </>
  );
}
