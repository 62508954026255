import type { ReactElement } from "react";
import { NavBar } from "../../components/navigations";
import { OtherFeatures, PricingHero, PricingPlan } from "../../components/pricing";
import { CallToAction, FrequentlyAskedQuestions } from "../../components/landing";
import Footer from "../../components/Footer";
import { useSearchParams } from "react-router-dom";
import HelmetComponent from "../../components/HelmetComponent";

export interface PricingProps {}

export default function Pricing(): ReactElement {
  const [planTypeParam] = useSearchParams();
  const planType =
    planTypeParam.get("planType") || ("player" as "organizer" | "team" | "player");
  const [isYearlyParams, setIsYearlyParams] = useSearchParams();
  const isYearlyString = isYearlyParams.get("isYearly") || "false";
  const isYearly = isYearlyString === "true";

  // open graph data
  const ogData = {
    title: "Leagues Arena - Pricing",
    description: "Choose the best plan that suits your needs",
    url: `https://${window.location.host}/pricing`,
    image:
      "https://res.cloudinary.com/dzdezmcu0/image/upload/v1706198603/htsp7qyyckzconc1ojgd.png",
    siteName: "Leagues Arena",
  };

  return (
    <>
      <HelmetComponent ogData={ogData} />
      <NavBar />
      <div className="flex flex-col items-center justify-center">
        <PricingHero planType={planType} isYearly={isYearly} />
        <PricingPlan
          planType={planType}
          isYearly={isYearly}
          setIsYearlyParams={setIsYearlyParams}
        />
        <OtherFeatures />
        <FrequentlyAskedQuestions />
        <CallToAction />
        <Footer />
      </div>
    </>
  );
}
