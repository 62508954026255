function LaUpload({ stroke = "#1a1a1a" }: { stroke?: string }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
      <g
        stroke={stroke}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.32 6.5l2.56-2.56 2.56 2.56M11.88 14.18V4.01M4 12c0 4.42 3 8 8 8s8-3.58 8-8" />
      </g>
    </svg>
  );
}

export default LaUpload;
