import type { ReactElement } from "react";
import { LaCheck } from "../../svg-icons";
import { Link } from "react-router-dom";

export interface PlanCardProps {
  type: string;
  popular?: boolean;
  price: string;
  duration?: string;
  discount?: string;
  buttonText: string;
  features: string[];
  description?: string;
  discountedPrice?: string;
  planType: "team" | "organizer" | "player";
  isYearly: boolean;
}

export default function PlanCard({
  type,
  popular,
  price,
  duration,
  discount,
  buttonText,
  description,
  discountedPrice,
  planType,
  isYearly,
  features,
}: PlanCardProps): ReactElement {
  const routes: {
    [key: string]: string;
  } = {
    "free plan": "/account/login",
    "enterprise plan": "/contact",
    "pro plan": `/premium/checkout/?planType=${planType}&yearly=${isYearly}&planName=pro`,
    "grow big plan": `/premium/checkout/?planType=${planType}&yearly=${isYearly}&planName=enterprise`,
  };

  const route = routes[type] || "#";

  return (
    <div className="rounded-2xl border border-grey-lighter shadow lg:shadow-lg">
      <div className="flex flex-col border-b border-[#EAECF0] p-4 lg:p-8">
        <div className="flex items-center justify-between">
          <p className="text-2xl font-bold capitalize text-grey">{type}</p>
          {popular && (
            <p className="rounded-full border border-primary-blue-light bg-primary-blue-lighter px-3 py-1 text-sm font-medium capitalize text-primary-blue-darker">
              popular
            </p>
          )}
        </div>
        <div className="mt-4 flex gap-1">
          <p className="text-5xl font-bold lg:text-[56px]">{price}</p>
          {duration && (
            <p className="self-end font-bold capitalize text-grey">
              {duration}
            </p>
          )}
        </div>
        {discount && <p className="mt-4 text-red">SAVE {discount}</p>}
        {description && !discount && (
          <p className="mt-4 capitalize text-grey">
            {description} <b>{discountedPrice}</b>
          </p>
        )}
        <Link
          to={route}
          className="mt-8 rounded-lg bg-primary-blue-darker py-4 text-center font-medium capitalize text-white hover:bg-primary-blue-lighter hover:text-black lg:text-2xl"
        >
          {buttonText}
        </Link>
      </div>
      <div className="flex flex-col gap-4 p-4 lg:gap-6 lg:p-8">
        <p className="font-bold uppercase">FEATURES</p>
        {features.map((feature) => (
          <div className="flex flex-col gap-4 text-grey" key={feature}>
            <div className="flex items-center gap-3">
              <div className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-primary-blue-darker">
                <LaCheck stroke="#0045F6" strokeWidth={3} />
              </div>
              <p className="capitalize">{feature}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
