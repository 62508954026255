import React from "react";
import { cn } from "../../../utils/cn";

type Ref = HTMLDivElement;

interface IBorderContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  role: TRole;
  className?: string;
}

const BorderContainer = React.forwardRef<Ref, IBorderContainerProps>(
  ({ className, children, role }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(className, {
          "border border-primary-blue-darker text-primary-black-darker":
            role === "player",
          "border border-primary-black-darker text-primary-black-darker":
            role === "organizer",
          "text-secondary-600 border border-secondary-blue-600":
            role === "team",
        })}
      >
        {children}
      </div>
    );
  }
);

export default BorderContainer;
