export function NewTabIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.66602 7.33385L14.1327 1.86719"
        stroke="#0045F6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 4.53203V1.33203H11.4668"
        stroke="#0045F6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33398 1.33203H6.00065C2.66732 1.33203 1.33398 2.66536 1.33398 5.9987V9.9987C1.33398 13.332 2.66732 14.6654 6.00065 14.6654H10.0007C13.334 14.6654 14.6673 13.332 14.6673 9.9987V8.66536"
        stroke="#0045F6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowUpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.80078 8.72266L5.50051 13.0229L1.20025 8.72266"
        stroke={props.stroke || "currentColor"}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49805 1.49609L5.49374 12.6638"
        stroke={props.stroke || "currentColor"}
        strokeWidth="1.53771"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.20117 6.78125L8.50144 2.48098L12.8017 6.78125"
        stroke={props.stroke || "currentColor"}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50391 14.0078L8.50822 2.84011"
        stroke={props.stroke || "currentColor"}
        strokeWidth="1.53771"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
